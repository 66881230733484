import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Table, Card } from 'semantic-ui-react'

export const Root = styled.div`
  width: 100%;
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
  padding: 2rem;
  background-image: url('https://sfv1cdn1.sgp1.digitaloceanspaces.com/generic_bg2.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  background-size: cover;

  .field {
    position: relative;
  }
  /* .ui.label {
    position: absolute;
    top: calc(100% - 1.25rem);
    right: 0.5rem;
  } */

  .formBox {
    width: 100%;
    max-width: calc(min(calc(var(--vw, 1vw) * 50), 380px));

    @media ${stylevars.breakpoints.phone} {
      max-width: 100%;
    }
  }

  .signupSelectRolGrid {
    margin-bottom: 1rem;
  }
  .signupSelectRole {
    font-weight: bold;
    margin-bottom: 1rem !important;
  }

  .notCreatorCustomer {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(0,0,0,0.05);
    display: block;
    padding: 1rem;
    border-radius: 0.25rem;
  }
`

export const FormBox = styled('div')`
  .formBox {
    width: 100%;
    max-width: calc(min(calc(var(--vw, 1vw) * 50), 380px));

    @media ${stylevars.breakpoints.phone} {
      max-width: 100%;
    }
  }
`

export const StyledCard = styled(Card)`
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff;

  .subCard {
    background-color: #f9f9f9 !important;
    text-align: center;
  }

  .paypalSubscriptionButtons {
    width: 100%;
  }

  .membershipToggle {
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.5s ease-out;
    .membershipToggle_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    .field {
      margin: 0;
    }
    label {
      display: block;
      padding-left: 0.5rem;
      line-height: 1;
      padding-bottom: 0.25rem;
      font-size: 1.2rem;
      font-weight: bold;
      cursor: pointer;
    }

    .permonth {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.5);
    }

    > p {
      font-size: 0.8rem;
      padding-left: 2rem;
      color: rgba(0, 0, 0, 0.25);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.045);
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.075);
      border-radius: 1rem;
    }
  }

  .footnote {
    display: flex;
    justify-content: center;
    align-items: center;
    .note {
      width: 100%;
      cursor: pointer;
      padding: 1rem;
      background-color: ${stylevars.palette.info.main};
      border-radius: 0.3rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1;
    }
    .icon {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
`

export const StyledTable = styled('div')`
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 1rem;
  max-height: calc(var(--vh, 1vh) * 60);
  overflow-y: auto;
  .table {
    .packageTableHeader {
      padding: 0.5rem 1rem !important;
      .blank {
        /* width: 120px; */
        width: 30%;
      }
      &.featured {
        background-color: rgba(${stylevars.palette.primary.rgb}, 0.25) !important;
        width: 35%;
      }
      &.starter {
        background-color: rgba(0, 0, 0, 0.05) !important;
        width: 35%;
      }
    }
    .packageTableCell {
      padding: 0.5rem 1rem !important;
      &.featured {
        background-color: rgba(${stylevars.palette.primary.rgb}, 0.25) !important;
      }
      &.starter {
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
    }
    thead {
      tr {
        th {
          border: none;
          border-bottom: 2px solid rgba(0, 0, 0, 0.25);
        }
      }
    }
    tbody {
      tr {
        td {
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
`

export const WelcomeAboard = styled('div')`
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .title {
    text-align: center;
    margin: 0;
    margin-bottom: -4rem;
  }
  .container {
    width: 100%;
    max-width: 720px;
    background
  }
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 2rem));
    z-index: 2;
    width: 100%;
    max-width: 500px;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1);
  }
  .actions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    text-align: center;
  }
`