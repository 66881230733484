import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const TypesContainer = styled('div')`
  .description {
    display: inline-block;
  }
  .availability {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    margin-top: 1rem;
    font-size: 0.75rem;
    line-height: 1;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0.15);
    background-color: transparent;
    border-radius: 0.5rem;
    color: rgba(0,0,0,0.5);
    width: auto;
    min-width: auto;
    text-transform: uppercase;

    .icon {
      font-size: 1rem;
      margin-right: 0.25rem;
    }

    &.global {
      color: #fff;
      border-color: ${stylevars.palette.primary.main};
      background-color: ${stylevars.palette.primary.main};
    }
  }
`