import React from 'react'

import { DocumentContainer } from './Styles'

const PrivacyContent = () => {
  return (
    <DocumentContainer>
      <h1>Privacy Policy</h1>

      <p>{process.env.REACT_APP_PROJECT_NAME} respects and values your personal data privacy. We are committed to complying with the Philippine Republic Act No. 10173 or the "Data Privacy Act of 2012", and its Implementing Rules and Regulations. </p>

      <p>This Privacy Policy outlines how we collect, use, disclose, and retain your personal data. Personal data refers to any information from which your identity is apparent or can be reasonably and directly ascertained by us, or when put together with other information would directly and certainly you. We shall ensure compliance to the strictest standards of security and confidentiality with respect to all personal data we collect from you.</p>

      <h2 className='ordered'>USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES </h2>

      <p>We use cookies and similar tracking technologies on our website. We do this to help us improve our services and for you to have an excellent user experience. Cookies are small files which asks permission to be placed on your computer's hard drive. The following web traffic data are what we track and analyze:</p>

      <ul class='list list--roman'>

        <li>IP address</li>
        <li>Pages and internal links accessed on our site</li>
        <li>The date and time of website visit</li>
        <li>Geolocation</li>
        <li>Referring site or platform (if any)</li>
        <li>Operating system</li>
        <li>Web browser type</li>
      </ul>

      <p>When you first access our website, you will be given the opportunity to accept or reject the use of cookies on our websites in a pop-up box. You can also disable cookies through your browser configuration. However, by doing this, you may not be able to fully utilize the features of our website.</p>

      <h2 className='ordered'>COLLECTION OF PERSONAL DATA</h2>

      <p>We may collect and process personal data from you when you: (i) sign up to create an account on our website; (ii) contact our support team; (iii) upload content as a creator; or (iii) contact us for business purposes. We may collect your email address, complete legal name, age, gender, complete residence address, contact details, tax registration details, financial accounts or payment details, and other personal data we may need. You have the option not to disclose any personal data to us. However, when you sign up to create an account on our website, disclosure of personal data is necessary, otherwise, we will not be able to create your account and process any transaction in our website. </p>

      <h2 className='ordered'>USE OF PERSONAL DATA</h2>

      <p>We use the personal data we collect from you to: (i) facilitate the use of our services in the website such as account creation, profile display, customer support, gallery creation, product sale and purchase, product delivery, and other related functions; (ii) improve user experience; (iii) fraud protection and detection; (iv) further develop our services; (v) comply with applicable legal and regulatory requirements; (vi) respond to queries and complaints; (vii) marketing and customer profiling activities in connection with our service; and (viii) any other legal purpose to which your consent was secured. Rest assured, we will not process or use your personal data unless it is for a specific purpose and has legal basis for doing so under current laws.</p>

      <h2 className='ordered'>RETENTION AND SECURITY OF PERSONAL DATA</h2>

      <p>We will retain your personal data only for such period as necessary to serve the purpose for which it was collected, or as required under any contract or by applicable law. Thereafter, we will dispose your personal data in a secure manner that would prevent further processing, unauthorized access or disclosure to any other party. </p>

      <p>We exert all reasonable efforts to keep your personal data secure. We have implemented suitable and adequate physical and technical security measures, policies and procedures intended to reduce the risks of accidental destruction or loss, or the unauthorized disclosure or access to the personal data we collect. However, no method or measure of information systems security is absolutely secure. While we are putting all our efforts in safeguarding your personal data, we cannot guarantee its absolute security.</p>

      <h2 className='ordered'>DISCLOSURE OF PERSONAL DATA</h2>

      <p>We may share your personal data to: (i) third parties whose services we use to provide our services and manage our business affairs; (ii) our affiliates or subsidiaries only for the purposes we have collected your personal data; (iii) our assignees, transferees or successors-in-interest; (iv) other users of our service with regard to any information displayed on your public; (v) any regulatory, quasi-judicial or judicial authority, or as otherwise considered necessary or appropriate for our legitimate business purposes; or (vi) any court of law or administrative agency of the government pursuant to a lawful order issued in relation to a pending case or proceeding before such court or agency.</p>

      <h2 className='ordered'>PRIVACY POLICY CHANGES AND UPDATES</h2>

      <p>We reserve the right to amend this Privacy Policy at any time to comply with the latest laws and regulations or reflect changes in our business operations and environment. We will endeavor to notify you by email should we make any material change to our Privacy Policy for you to review the changes before they come into force. We encourage you to review this Privacy Policy in our website from time to time for the latest information.</p>

      <h2 className='ordered'>QUESTIONS AND CONCERNS</h2>

      <p>If you have any question, concern, complaint, or suggestion to this Privacy Policy, or if you wish to exercise your rights to access, rectification, object, portability or deletion in instances allowed under the law, you may contact us through: <a href="mailto:help@stylefolio.app">help@stylefolio.app</a></p>
    </DocumentContainer>
  )
}

export default React.memo(PrivacyContent)
