import React from 'react'

const AccountCouponsTableTemp = () => {
  return (
    <div>
      Coming soon...
    </div>
  )
}

export default AccountCouponsTableTemp
