import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import he from 'he'
import cn from 'classnames'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import { DashToolbar, PagePreloader } from '@Common'

// STYLES
import { CollectionsEditorContainer } from './CollectionsEditorStyles'
import { CollectionsForm } from '.'

const CollectionsEditor = (props) => {
  const dispatch = useDispatch()
  const { collectionId } = useParams()
  const history = useHistory()
  const { userGallery, userCollections } = useSelector(getUserState)
  const [ collectionData, setCollectionData ] = React.useState()
  const [ ready, setReady ] = React.useState(false)

  // React.useEffect(() => {
  //   if (collectionId) {
  //     // check if collection id is in user collections array
  //     const tempData = userCollections.filter(i => i.ID.toString() === collectionId.toString())
  //     if (tempData.length > 0) {
  //       setCollectionData(tempData[0])
  //     } else {
  //       // if collection id is not in user collections array, redirect to home
  //       history.push('/')
  //     }
  //   }
  // }, [userCollections, collectionId]) // eslint-disable-line

  React.useEffect(() => {
    // if (collectionData) {
      dispatch(uiSlice.actions.setPageTemplate('dash'))
      dispatch(uiSlice.actions.setCurrentPage(`collections`))
      dispatch(uiSlice.actions.setCurrentPageURL(`admin/collections`))
      dispatch(uiSlice.actions.setCurrentPageTitle(`new`))

      setReady(true)
    // }
  }, []) // eslint-disable-line

  const handleCancel = () => {
    history.push('/admin/collections')
  }

  return (
    <CollectionsEditorContainer>
      {ready ?
        <>
          <DashToolbar title={`New Collection`} showBack />
          <CollectionsForm mode='new' />
        </> : 
        <PagePreloader />
      }
    </CollectionsEditorContainer>
  )
}

export default React.memo(CollectionsEditor)
