import { set } from 'lodash'
import React from 'react'
import {
  consoleLogger,
} from '@Utils'

// COMPONENTS
import { ResultItem } from './'

// STYLES
import { Container } from './ResultGroupStyles'

const ResultGroup = (props) => {
  const {
    data,
    handleSearchModal
  } = props

  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    if (data) {
      consoleLogger('====================== data', data)
      setReady(true)
    }
  }, [data]) // eslint-disable-line

  return (
    <Container>
      {ready &&
        <>
          {data && data.results.length > 0 &&
            <>
              <h1 className='resultLabel'>{data.label}</h1>
              <div className='resultGrid'>
                {data.results.map(item => (
                  <ResultItem key={`${data.key}-${item.ID}`} type={data.key} item={item} handleSearchModal={handleSearchModal} />
                ))}
              </div>
            </>
          }
        </>
      }
    </Container>
  )
}

export default React.memo(ResultGroup)
