import { configureStore } from '@reduxjs/toolkit'
import userSlice from '@Slices/userSlice'
import postsSlice from '@Slices/postsSlice'
import uiSlice from '@Slices/uiSlice'
import gallerySlice from '@Slices/gallerySlice'
import vendorSlice from '@Slices/vendorSlice'
import cartSlice from '@Slices/cartSlice'

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    posts: postsSlice.reducer,
    ui: uiSlice.reducer,
    gallery: gallerySlice.reducer,
    vendor: vendorSlice.reducer,
    cart: cartSlice.reducer,
  },
})

export default store