import React from 'react'
import { Link, useParams, useHistory, useLocation } from "react-router-dom"
import axios from 'axios'
import { consoleLogger, setAuthorizationHeader, useSiteCurrency } from '@Utils'
import jwtDecode from 'jwt-decode'
import cn from 'classnames'
// import { Helmet } from 'react-helmet'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Grid, Button, Form as SUIForm, Message, Icon } from 'semantic-ui-react'
import {FormBox, StyledCard} from './Styles'

// VALIDATION SCHEMA
Yup.addMethod(Yup.string, 'nospecial', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    value = originalValue.matches(/[^a-zA-Z0-9]/)

    consoleLogger(originalValue, value)
    return value ? value.toString() : ''
  });
});
const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(5, "Must be at least 5 characters long")
    .max(16, "Must be shorter than 16 characters")
    .test('nospecial', 'Special characters are not allowed', (val) => {
      const pattern = /^[a-zA-Z0-9]+$/
      return (
        pattern.test(val)
      )
    })
    .required('Must enter a username'),
  password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(255, "Must be shorter than 255 characters")
    .required("Must enter a password")
})

const LoginForm = (props) => {
  const {
    className,
    to,
    redirect,
    customer,
    handleModal,
    ...restProps
  } = props
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { forexData } = useSelector(getUIState)
  const { authenticated, userAddresses } = useSelector(getUserState)
  const { path } = useParams()
  const currentPath = location.pathname
  const decodedPath = decodeURIComponent(path)
  const [ signupLink, setSignupLink ] = React.useState('/signup')

  const [ showPass, setShowPass ] = React.useState(false)
  const handleShowPass = () => {
    setShowPass(prev => !prev)
  }

  const [ selectedCurrency, setSelectedCurrency ] = useSiteCurrency()

  React.useEffect(() => {
    // dispatch(uiSlice.actions.setPageTemplate('store'))
    // dispatch(uiSlice.actions.setCurrentPage('login'))
    // dispatch(uiSlice.actions.setCurrentPageURL())
    // dispatch(uiSlice.actions.setCurrentPageTitle())

    consoleLogger("decodedPath ===================", decodedPath)
    if (customer) {
      setSignupLink(state => (`${state}/collector`))
    }
    if (handleModal) {
      if (path) {
        setSignupLink(state => (`${state}/redirect/${encodeURIComponent(path)}`))
      } else if (currentPath) {
        if (currentPath.indexOf('/signup') <= -1) {
          setSignupLink(state => (`${state}/redirect/${encodeURIComponent(currentPath)}`))
        }
      }
    }
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('LOGIN PAGE')
    if (authenticated) {
      if (!handleModal) {
        history.push('/admin')
      }
    }
  }, [authenticated]) // eslint-disable-line

  const handleSignupLink = (ev) => {
    ev.preventDefault()
    if (handleModal) {
      handleModal(false)
    }
    history.push(`/signup`)
  }

  const handleDifferentLoginLink = (ev) => {
    ev.preventDefault()
    if (handleModal) {
      handleModal(false)
    }
    if (customer) {
      history.push('/login')
    } else {
      history.push('/login/collector')
    }
  }

  const handleResetPasswordLink = (ev) => {
    ev.preventDefault()
    if (handleModal) {
      handleModal(false)
    }
    history.push('/resetpassword')
  }

  return (
    <FormBox className={cn('formBoxContainer', {
      [className] : className
    })}>
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          (async () => {
            dispatch(userSlice.actions.setAuthenticated(false))
            let token
            let userInfo
            let userId
            let userKey
            let valid = true
            let xredirect = redirect
            setSubmitting(true)
            // consoleLogger('LOGIN =====================', data)
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/jwt-auth/v1/token`,
              method: 'POST',
              data: data
            })
            .then(res => {
              (async() => {
                const decodedToken = jwtDecode(res.data.token)
                consoleLogger('JWT AUTH RESPONSE =====================', res.data, decodedToken)
                token = res.data.token
                setAuthorizationHeader(res.data.token)
                userId = decodedToken.data.user.id
                userKey = decodedToken.data.user_key

                // get user info
                await axios({
                  baseURL: `${process.env.REACT_APP_API_URL}`,
                  url: `/stylefolio/v1/getuser`,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                  method: 'GET',
                  params: {
                    author: userId,
                    user_key: userKey
                  }
                }).then((res) => {
                  consoleLogger('FETCHING USER INFO ========================', res.data)
                  userInfo = res.data
                  // IF CUSTOMER
                  if (res.data.role === 'sf_customer') {
                    // CHECK IF VERIFIED
                    if (!res.data.acf.verified) {
                      valid = false
                      xredirect = `/login/verify/${res.data.id}/${res.data.acf.registration_key}`
                    }
                  }
                  // IF CREATOR
                  if (res.data.role === 'sf_creator') {
                    // CHECK IF SUBSCRIPTION IS VALID
                    if (res.data.acf.subscription_expired && !res.data.acf.subscription_paid) {
                      valid = false
                      xredirect = `/signup/payment/${res.data.id}/${res.data.acf.registration_key}`
                    }
                    // check user profile and cover photo
                    dispatch(userSlice.actions.updateAccountCompletion({
                      hasProfilePhoto: !!res.data.acf.profile_picture,
                      hasCoverPhoto: !!res.data.acf.background_image,
                    }))
                    // if creator is premium, check if signature is already present
                    dispatch(userSlice.actions.updateAccountCompletion({
                      hasSignature: !!(res.data.acf.subscription_type === 'premium' && res.data.acf.signature_image)
                    }))
                    // REVERT CURRENCY TO USD
                    dispatch(uiSlice.actions.updateCurrency({
                      name: 'US Dollar',
                      currency: 'USD',
                      symbol: '$',
                      value: 1
                    }))
                    setSelectedCurrency({
                      name: 'US Dollar',
                      currency: 'USD',
                      symbol: '$',
                      value: 1
                    })
                  }
                }).catch(err => {
                  consoleLogger("FETCH USER INFO ERROR: ", err.response.data)
                  setErrors(err.response.data)
                })

                if (valid) {
                  dispatch(userSlice.actions.setUserKey(userInfo.acf.user_key))
                  dispatch(userSlice.actions.setUserInfo({...userInfo}))

                  // get user gallery data
                  await axios({
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    url: `/stylefolio/v1/gallery`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    method: 'get',
                    params: {
                      author: userId,
                    }
                  }).then((res) => {
                    consoleLogger('FETCHING USER GALLERY ========================', res.data)
                    userInfo = res.data
                    dispatch(userSlice.actions.setUserGallery(res.data))
                  }).catch(err => {
                    consoleLogger("FETCH USER GALLERY ERROR: ", err.response.data)
                    setErrors(err.response.data)
                  })

                  // FETCH USER COLLECTIONS
                  await axios({
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    url: `/stylefolio/v1/collections`,
                    method: 'GET',
                    params: {
                      author: userId,
                      user_key: userKey
                    }
                  }).then(res => {
                    consoleLogger("FETCHING USER COLLECTIONS RESPONSE =============", decodedToken.data.user.id, res.data)
                    dispatch(userSlice.actions.setCollections(res.data))
                  })
                  .catch(err => {
                    consoleLogger(err)
                  })
                  
                  // get user coupons
                  await axios({
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    url: `/stylefolio/v1/coupons`,
                    method: 'get',
                    params: {
                      per_page: -1,
                      author: userId,
                      orderby: 'date_modified_gmt',
                      order: 'desc',
                      status: ['publish', 'pending']
                    }
                  }).then((res) => {
                    consoleLogger('FETCHING USER COUPONS ========================', res.data)
                    dispatch(userSlice.actions.setUserCoupons(res.data))
                    dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', totalItems: res.data.length}))
                    // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', total: parseInt(res.headers['x-wp-total']), pages: parseInt(res.headers['x-wp-totalpages'])}))
                  }).catch(err => {
                    consoleLogger("FETCH USER COUPONS ERROR: ", err.response.data)
                    setErrors(err.response.data)
                  })
                  
                  // get user billing addresses
                  await axios({
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    url: `/stylefolio/v1/address`,
                    method: 'get',
                    params: {
                      user_key: userKey,
                      author: userId,
                    }
                  }).then((res) => {
                    consoleLogger('FETCHING USER BILLING ADDRESSES ========================', res.data)
                    dispatch(userSlice.actions.setUserAddresses(res.data))
                    // check user address/es
                    dispatch(userSlice.actions.updateAccountCompletion({hasAddress: res.data.length > 0}))
                    // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', totalItems: res.data.length}))
                    // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', total: parseInt(res.headers['x-wp-total']), pages: parseInt(res.headers['x-wp-totalpages'])}))
                  }).catch(err => {
                    consoleLogger("FETCH USER BILLING ADDRESSES ERROR: ", err.response.data)
                    setErrors(err.response.data)
                  })

                  setSubmitting(false)
                  dispatch(userSlice.actions.setAuthenticated(true))
                  if (handleModal) {
                    handleModal(false)
                  } else {
                    if (to) {
                      history.push(to)
                    } else {
                      if (path) {
                        history.push(decodedPath)
                      } else {
                        history.push(redirect)
                      }
                    }
                  }
                } else {
                  if (handleModal) {
                    handleModal(false)
                  }
                  history.push(xredirect)
                }
              })()
            })
            .catch(err => {
              setSubmitting(false)
              // const errorData = err
              consoleLogger("LOGIN ERROR: ", err.response.data)
              if (err.response.data.code === '[jwt_auth] incorrect_password' || "[jwt_auth] invalid_username") {
                setErrors({message: 'Incorrect Username/Password'})
              } else {
                setErrors(err.response.data)
              }
            })
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setValues, errors, isValid, dirty}) => (
          <Form className={cn('ui form', {
            [className]: className
          })}>
            <Grid centered padded>
              <Grid.Column className='formBox'>
                <h1>Login</h1>
                {customer && handleModal &&
                  <p>Before you can add things to your cart, you need to login first.</p>
                }
                <StyledCard>
                  <StyledCard.Content>
                    <SUIForm.Input name='username' fluid placeholder='Username' type='text' label='Username'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.username}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input name='password' fluid iconPosition='right' placeholder='Password' type={showPass ? 'text' : 'password'} label='Password'
                      icon={<Icon name={showPass ? 'eye slash' : 'eye'} link onClick={handleShowPass} />}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password}
                      disabled={isSubmitting}
                    />
                    <p className='helpText'>Can't remember your password? <Link to='/resetpassword' onClick={handleResetPasswordLink}>Reset password</Link></p>
                  </StyledCard.Content>
                  <StyledCard.Content extra>
                    <div className='ui two buttons'>
                      <Button
                        size='large'
                        type='submit'
                        color='green'
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        disabled={!(dirty && !isSubmitting)}
                      >
                        Login
                      </Button>
                    </div>
                  </StyledCard.Content>
                  <StyledCard.Content extra className='subCard'>
                    <p>Don't have an account yet? <Link to={`/signup`} onClick={handleSignupLink}>Signup now!</Link></p>
                  </StyledCard.Content>
                </StyledCard>
                {errors && errors.message &&
                  <Message negative>
                    <div dangerouslySetInnerHTML={{ __html: errors.message }} />
                  </Message>
                }
              </Grid.Column>
            </Grid>
          </Form>
        )}
      </Formik>
    </FormBox>
  )
}

export default LoginForm
