import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import he from "he";
import { Helmet } from "react-helmet";
import { titleCase } from "title-case";
import { consoleLogger, checkBrightness } from "@Utils";
import { MuuriComponent } from "muuri-react";
import { orderBy } from "lodash";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import vendorSlice, { getVendorState } from "@Slices/vendorSlice";

// COMPONENTS
import {
  // Grid,
  Dimmer,
  Loader,
  Button,
  // Icon,
  // Placeholder,
  // Item,
  // Header,
} from "semantic-ui-react";
import Viewer from "./Viewer";
import { CollectionItem } from "../../Pages/Collections/Components";
import { GeneralPreloader, LikeButton } from "@Common";

// STYLES
import { CollectionsViewContainer } from "./CollectionsViewStyles";
import Masonry from "react-masonry-css";
import GalleryItem from "./GalleryItem";

const CollectionsView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { noHelmet } = props;
  const { page, postid } = useParams();
  const { vendorInfo, vendorProducts, vendorCollections } =
    useSelector(getVendorState);
  const { showViewer, viewerData, mountViewer } = useSelector(getUIState);

  const [ready, setReady] = React.useState(false);
  const [collectionData, setCollectionData] = React.useState();
  const [galleryData, setGalleryData] = React.useState();

  const handleBack = (ev) => {
    ev.preventDefault();
    history.push(`/@${vendorInfo.username}/collections`);
  };

  React.useEffect(() => {
    if (page === "collections" && postid) {
      const tempData = vendorCollections.filter(
        (n) => n.id.toString() === postid.toString()
      )[0];
      setCollectionData(tempData);
      consoleLogger("====================== tempData", tempData);
      if (!tempData) {
        dispatch(uiSlice.actions.setShowViewer(false));
        dispatch(uiSlice.actions.setMountViewer(false));
        if (vendorCollections.length > 0) {
          history.push(`/@${vendorInfo.username}/collections`);
        } else {
          history.push(`/@${vendorInfo.username}/gallery`);
        }
      }
    }
  }, [page, postid]); // eslint-disable-line

  React.useEffect(() => {
    if (collectionData) {
      consoleLogger(
        "----------------",
        collectionData.gallery_data.map(
          (n) =>
            vendorProducts.filter(
              (i) =>
                i.post_status.toString() === "publish" &&
                i.id.toString() === n.id.toString()
            )[0]
        )
      );
      // let tempData = collectionData.gallery_data.map((n, index) => vendorProducts.filter(i => i.post_status.toString() === 'publish' && i.id.toString() === n.id.toString()).map(o => ({
      //   ...o,
      //   collection_order: index + 1,
      // }))[0])
      let tempData = [];
      collectionData.gallery_data.forEach((n, index) => {
        const tempItem = vendorProducts.filter(
          (i) => i.id.toString() === n.id.toString()
        )[0];
        if (tempItem && tempItem.post_status === "publish") {
          tempData.push({
            ...tempItem,
            collection_order: index + 1,
          });
        }
      });
      let tempData2 = orderBy(tempData, "collection_order");
      setGalleryData(tempData2);
    }
  }, [collectionData]); // eslint-disable-line

  React.useEffect(() => {
    if (galleryData) {
      setReady(true);
    }
  }, [galleryData]);

  return (
    <CollectionsViewContainer
      className="collectionViewer"
      sfStyles={{ ...vendorInfo.acf.custom_stylesheet }}
    >
      {ready ? (
        <>
          <div className="wrapper">
            <div className="header">
              <h1
                className={cn({
                  limited: !!collectionData.limited_edition,
                })}
              >
                <span className="title">{he.decode(collectionData.title)}</span>
                {!!collectionData.limited_edition && (
                  <span className="label">Limited</span>
                )}
              </h1>
              {collectionData.description && (
                <h4>{he.decode(collectionData.description)}</h4>
              )}
              <div className="actions">
                <Link
                  to={`/@${vendorInfo.username}/collections`}
                  component={Button}
                  className="backButton"
                  type="button"
                  icon="arrow left"
                  content={<span>Back to collections</span>}
                  labelPosition="left"
                  onClick={(ev) => handleBack(ev)}
                />
                <LikeButton itemData={collectionData} type="collection" />
              </div>
            </div>
            <div className="cover">
              <LazyLoadImage
                wrapperClassName="image"
                // afterLoad={(ev) => {
                //   handleAfterLoad(ev)
                // }}
                effect="blur"
                alt={collectionData.title}
                src={
                  collectionData.cover_image.medium[0]
                    ? collectionData.cover_image.medium[0]
                    : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`
                }
              />
            </div>
            <div className="content">
              <MuuriComponent
                forceSync={true}
                instantLayout={true}
                gridProps={{ className: "galleryGrid" }}
                layout={{
                  fillGaps: true,
                  horizontal: false,
                  alignRight: false,
                  alignBottom: false,
                  rounding: true,
                }}
                layoutOnResize={100}
                layoutDuration={300}
              >
                {galleryData.map((item) => (
                  <GalleryItem
                    key={`${item.ID}-${item.post_name}`}
                    item={item}
                    username={vendorInfo.username}
                    mode={"collections"}
                  />
                ))}
              </MuuriComponent>
            </div>
          </div>
          {mountViewer &&
          vendorProducts &&
          vendorProducts.length > 0 &&
          page === "collections" ? (
            <Viewer />
          ) : (
            <>
              {!noHelmet && (
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>
                    {vendorInfo.name} - {titleCase(collectionData.title)}
                  </title>
                  <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_DOMAIN}/@${vendorInfo.username}/${page}/${postid}`}
                  />
                </Helmet>
              )}
            </>
          )}
        </>
      ) : (
        <GeneralPreloader title="Loading Collection..." />
      )}
    </CollectionsViewContainer>
  );
};

export default React.memo(CollectionsView);
