import React from 'react'
import cn from 'classnames'

// COMPONENTS
import { Button, Modal } from 'semantic-ui-react'
import { UpgradeModal } from './'

// STYLES
import { Container } from './UpgradePlanStyles'

const UpgradePlanButton = (props) => {
  const {
    as
  } = props
  const [ upgradeOpen, setUpgradeOpen ] = React.useState(false)
  const handleModal = (bool) => {
    setUpgradeOpen(bool)
  }

  return (
    <Container className={cn({
      'text': as === 'text'
    })}>
      { as && as === 'text' ?
        <span className='link'
          onClick={() => {
            handleModal(true)
          }}
        >
          Upgrade to PRO
        </span> : 
        <Button type='button' size='tiny' color='blue' content='Upgrade to PRO'
          onClick={() => {
            handleModal(true)
          }}
        />
      }
      {upgradeOpen &&
        <UpgradeModal
          open={upgradeOpen}
          handleModal={handleModal}
        />
      }
    </Container>
  )
}

export default React.memo(UpgradePlanButton)
