import React from 'react'
import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { lighten, darken } from 'polished'

const LayoutContainer = styled.div`
  padding: 2rem;
  @media ${stylevars.breakpoints.phone} {
    padding: 1rem;
  }

  > .header {
    @media ${stylevars.breakpoints.phone} {
      padding: 0rem;
      margin: 0;
    }
  }

  > .divider {
    @media ${stylevars.breakpoints.phone} {
      /* margin: 0; */
    }
  }

  .display_col {
    position: relative;
  }
  .bio_field {
    width: 100%;
  }
  .bio_col {
    /* padding-top: 2rem !important; */
    position: relative;

    .quill  {
      .ql-toolbar {
        border: 1px solid ${stylevars.paper.borderColor};
      }
      .ql-container {
        border: none;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.02);
        .ql-editor {
          padding: 0;
        }
      }
    }
  }
  .bio_buttons {
    text-align: right;
    padding: 1rem;
    background-color: ${stylevars.paper.borderColor};
  }
  .display_profile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 50%;
    height: calc(min(calc(var(--vw, 1vw) * 30), 150px)) !important;
    width: calc(min(calc(var(--vw, 1vw) * 30), 150px)) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    background-color: #fff;
  }
  .display_cover {
    position: relative;
    z-index: 1;
    .uploader_image_overlay {
      padding: 2rem;
      justify-content: flex-end;
      align-items: flex-end;
      .icon {
        margin-bottom: 1rem;
      }
    }
  }

  .profile_theme_selection {
    position: relative;
  }

  .themeSelection {
    width: 100%;
    display: grid;
    grid-template-columns: repeat( 6, minmax(120px, 1fr) );
    column-gap: 1rem;
    row-gap: 1rem;
    @media ${stylevars.breakpoints.tablet} {
      grid-template-columns: repeat( 3, minmax(120px, 1fr) );
    }
    @media ${stylevars.breakpoints.phone} {
      grid-template-columns: repeat( 1, minmax(120px, 1fr) );
    }
  }
  .themeItem {
    text-align: center !important;
    display: flex;
    justify-content: center;
    transition: all 0.15s ease-out;
    @media ${stylevars.breakpoints.phone} {
      &:not(:last-of-type) {
        margin-bottom: 1rem !important;
        border-bottom: 1px solid ${stylevars.paper.borderColor};
        padding-bottom: 1rem !important;
      }
    }

    /* .themeItem_wrapper */
    &_wrapper {
      display: inline-block;
      position: relative;
      border-width: 0.1rem;
      border-style: solid;
      border-color: ${stylevars.paper.borderColor};
      transition: border 0.15s ease-out;
      padding: 1rem;
    }
    /* .themeItem_name */
    &_name {
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: ${stylevars.paper.borderColor};
      background-color: ${stylevars.paper.bgColor};
      color: ${stylevars.typography.subtitle.color};
      opacity: 1;
      transition: all 0.15s ease-out;
      z-index: 1;
      font-size: 1.25rem;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      &:hover {
        border-color: ${stylevars.typography.subtitle.color};
        color: ${stylevars.typography.title.color};
      }
      &.active {
        border-color: ${stylevars.palette.primary.main};
        background-color: ${stylevars.palette.primary.main};
        color: ${stylevars.palette.primary.contrastText};
        > span.customize {
          border-left-color: transparent;
          background-color: ${darken(0.1, stylevars.palette.primary.main)};
          &.label {
          }
        }
      }

      > span {
        display: inline-block;
        padding: 1rem;

        &.label {
          flex-grow: 1;
        }
        &.customize {
          margin: 0.5rem;
          padding: 0.5rem;
          border-radius: 10rem;
          border-left-width: 1px;
          border-left-style: solid;
          border-left-color: ${stylevars.paper.borderColor};
          &:hover {
            background-color: ${darken(0.15, stylevars.palette.primary.main)};
          }
        }
      }
    }
    /* .themeItem_image */
    &_image {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
      display: block;
    }

    &:hover {
      z-index: 2;
      box-shadow: 0 0.25rem 0.5rem 0.15rem rgba(0,0,0,0.15);
      /* transform: scale(1.05); */
    }
  }

  .subPageMenu {
    .menu {
      background-color: ${stylevars.paper.bgColor};
      /* background-color: rgba(${stylevars.paper.borderColorRGB},0.15); */
      /* background-color: ${stylevars.paper.bgColor}; */
      /* border-color: ${stylevars.typography.subtitle.color} !important; */
      .item {
        font-weight: bold !important;
        color: ${stylevars.typography.subtitle.color} !important;
        border-color: transparent !important;
        &.active {
          color: ${stylevars.palette.info.main} !important;
          border-color: ${stylevars.palette.info.main} !important;
        }
        &:hover {
          color: ${stylevars.typography.title.color} !important;
          border-color: ${stylevars.typography.title.color} !important;
        }
      }
    }
    .segment {
      border: none;
    }
  }
`

const StyledLayoutContainer = ({children, ...rest}) => {
  return (
    <LayoutContainer
      {...rest}
    >
      {children}
    </LayoutContainer>
  )
}

export default React.memo(StyledLayoutContainer)