import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled.div`
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .actions {
    width: 100%;
    background-color: #1d1d1d;
    padding: 2rem 1rem;
    text-align: center;
  }

  > .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    padding-bottom: 0;
    overflow-x: auto;
    @media ${stylevars.breakpoints.phone} {
      justify-content: flex-start;
    }
  }
`