import React from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import { Button, Modal } from 'semantic-ui-react'

// STYLES
import { StyledModalContainer } from './AddressModalStyles'

const AddressModal = (props) => {
  const {
    open,
    handler
  } = props

  const history = useHistory()

  const handleConfirm = () => {
    handler(false)
    history.push('/admin/account')
  }

  return (
    <StyledModalContainer>
      <Modal
        size='mini'
        open={open}
        dimmer={{
            blurring: true
        }}
        onOpen={() => handler(true)}
        onClose={() => handler(false)}
        blurring
      >
        <Modal.Header>Account Completion</Modal.Header>
        <Modal.Content>
          <p>You haven't set a <strong>billing and mailing address</strong> yet. Would you like to set it up now</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handler(false)} basic icon='close' labelPosition='right' content='No' />
          <Button onClick={handleConfirm} positive icon='arrow right' labelPosition='right' content={`Yes`} />
        </Modal.Actions>
      </Modal>
    </StyledModalContainer>
  )
}

export default AddressModal
