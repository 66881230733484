import React from 'react'
import cn from 'classnames'
// import { stylevars } from '@Common/StyleVars'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import {
  Dimmer,
  Loader,
} from 'semantic-ui-react'

const ViewerImage = (props) => {
  const dispatch = useDispatch()
  const {alt, src, setSrc, ...restProps} = props
  const { showViewer, viewerUI, viewerFetching, viewerImageDone } = useSelector(getUIState) // eslint-disable-line
  const [isReady, setIsReady] = React.useState(false) // eslint-disable-line
  const [imageStyles, setImageStyles] = React.useState({animationName: `hideViewerImage`, width: `180px`, height: `180px`})
  // const [imgSrc, setImgSrc] = React.useState(props.src) // eslint-disable-line
  // const [retinaSrc, setRetinaSrc] = React.useState(props.src) // eslint-disable-line

  // const handleOnLoad = () => {
  //   consoleLogger('props.src', props.src)
  //   setIsReady(false)
  //   setImgSrc('/images/viewer-image.png')
  //   setImageStyles({opacity: 0, width: `180px`, height: `180px`})
  //   const chkImg = new Image()
  //   chkImg.onload = () => {
  //     consoleLogger('onLoad', props.src)
  //     setImgSrc(props.src)
  //     setTimeout(() => {
  //       setImageStyles(state => ({
  //         ...state,
  //         aspectRatio: `${chkImg.width} / ${chkImg.height}`,
  //         animationName: `showViewerImage`,
  //         width: chkImg.width > chkImg.height ? `${viewerUI.width}px` : 'auto',
  //         height: chkImg.width > chkImg.height ? `auto` : `${viewerUI.height}px`
  //       }))
  //       setIsReady(true)
  //     }, 300)
  //   }
  //   chkImg.src = props.src
  // }

  // React.useEffect(() => {
  //   consoleLogger('props source =========================', props.src)
  //   setIsReady(false)
  //   setImgSrc('/images/viewer-image.png')

  //   const chkImg = new Image()
  //   chkImg.onload = () => {
  //     consoleLogger(props.src)
  //     setImgSrc(props.src)
  //     setTimeout(() => {
  //       setImageStyles(state => ({...state, aspectRatio: `${chkImg.width} / ${chkImg.height}`}))
  //       const chkImgRatio = (chkImg.width / chkImg.height)
  //       const viewerColRatio = (viewerUI.width / viewerUI.height)
  //       consoleLogger(`${chkImg.width / viewerColRatio} x ${chkImg.height / viewerColRatio}`, chkImgRatio, viewerColRatio)
  //       setImageStyles(state => ({...state, animationName: `showViewerImage`}))
  //       // setTimeout(() => {
  //         if (chkImg.width > chkImg.height) {
  //           setImageStyles(state => ({...state, width: `${viewerUI.width}px`, height: `auto`}))
  //         } else {
  //           setImageStyles(state => ({...state, width: `auto`, height: `${viewerUI.height}px`}))
  //         }
  //         setIsReady(true)
  //       // }, 300)
  //     }, 300)
  //   }
  //   chkImg.src = props.src
  //   return(() => {
  //     setIsReady(false)
  //     setImgSrc('/images/viewer-image.png')
  //   })
  // }, [props.src]) // eslint-disable-line

  // React.useEffect(() => {
  //   if (viewerFetching) {
  //     setIsReady(false)
  //     setImageStyles(state => ({...state, animationName: `hideViewerImage`, width: `180px`, height: `180px`}))
  //     handleOnLoad()
  //   } else {
  //     setImgSrc(props.src)
  //     handleOnLoad()
  //   }

  //   if (!showViewer) {
  //     consoleLogger('aaaaaaaaaaaaaaaaaaaa')
  //     setImgSrc('/images/viewer-image.png')
  //     setImageStyles(state => ({...state, animationName: `hideViewerImage`, width: `180px`, height: `180px`}))
  //   }

  //   return (() => {
  //     setIsReady(false)
  //   })
  // }, [viewerFetching, showViewer])
  
  React.useEffect(() => {
    if (viewerFetching) {
      consoleLogger('Viewer is fetching...')
      setIsReady(false)
      setImageStyles(state => ({...state, animationName: `hideViewerImage`, width: `180px`, height: `180px`, borderRadius: `180px`}))
      dispatch(uiSlice.actions.setViewerImageDone(false))
    }
    if (!showViewer) {
      consoleLogger('Viewer hidden')
    }
  }, [viewerFetching, showViewer]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('NEW props.src ===========', src)
    const chkImg = new Image()
    chkImg.src = src
    chkImg.onload = () => {
      consoleLogger('ON LOAD ===========', src)
      // setImageStyles(state => ({...state, animationName: `showViewerImage`}))
      setTimeout(() => {
        dispatch(uiSlice.actions.setViewerImageDone(true))
        setImageStyles(state => ({
          ...state,
          animationName: `showViewerImage`,
          aspectRatio: `${chkImg.width} / ${chkImg.height}`,
          width: chkImg.width > chkImg.height ? `${viewerUI.width}px` : 'auto',
          height: chkImg.width > chkImg.height ? `auto` : `${viewerUI.height}px`,
          borderRadius: `0px`,
        }))
      }, 500)
      setIsReady(true)
    }

    return(() => {
      consoleLogger('CALLBACK SRC', src)
    })
  }, [src]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('ON MOUNT ====================')
    setIsReady(false)
    dispatch(uiSlice.actions.setViewerImageDone(false))
    setImageStyles(state => ({...state, animationName: `none`, opacity: 0, width: `180px`, height: `180px`}))
  }, []) // eslint-disable-line

  const handleOnLoad = (ev) => {
    consoleLogger("Handle On Load", ev)
  }

  return (
    <div className='viewerImage_wrapper'>
      {viewerFetching &&
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      }
      <img {...restProps} onLoad={handleOnLoad} src={src} style={imageStyles} alt={alt}
        className={cn({
          'fetching': viewerFetching && !viewerImageDone,
          'ready': viewerImageDone
        })}
      />
    </div>
  )
}

export default React.memo(ViewerImage)
