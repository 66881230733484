import React from 'react'
import { consoleLogger, useSiteCurrency } from '@Utils'
import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import styled from 'styled-components'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Dropdown, Menu } from 'semantic-ui-react'

// STYLES
const CurrencySelectionContainer = styled('div')`
  margin-right: 1rem;
  font-size: 1rem;
  .dropdown {
    width: auto !important;
    min-width: auto !important;
  }
`

const CurrencySelection = () => {
  const reduxDispatch = useDispatch()
  const [{ options }, dispatch ] = usePayPalScriptReducer();
  const { currency, siteSettings, forexData } = useSelector(getUIState)
  const [ ready, setReady ] = React.useState(false)
  const [ selectedCurrency, setSelectedCurrency ] = useSiteCurrency()
  const [ currencyOptions, setCurrencyOptions ] = React.useState()

  React.useEffect(() => {
    consoleLogger('========= SITE SETTINGS ==========', currency)
    if (currency && siteSettings.currencySelection) {
      let tempArr = []
      siteSettings.currencySelection.forEach(item => {
        tempArr.push({
          key: item.currency,
          value: item.currency,
          text: `${item.symbol} ${item.currency.toUpperCase()}`,
          active: item.currency.toUpperCase() === currency.currency.toString().toUpperCase(),
          selected: item.currency.toUpperCase() === currency.currency.toString().toUpperCase(),
        })
      })
      setCurrencyOptions(tempArr)
    }
  }, [currency, siteSettings]) // eslint-disable-line

  React.useEffect(() => {
    if (currencyOptions) {
      setReady(true)
    }
  }, [currencyOptions])

  React.useEffect(() => {
    if (selectedCurrency) {
      consoleLogger('================ selectedCurrency', selectedCurrency)
      reduxDispatch(uiSlice.actions.updateCurrency(selectedCurrency))
    }
  }, [selectedCurrency]) // eslint-disable-line

  const handleChange = (ev, {value}) => {
    consoleLogger('handleChange =============', value)
    const crncy = siteSettings.currencySelection.filter(i => i.currency === value)[0]
    setSelectedCurrency({
      ...crncy,
      value: value === "USD" ? 1 : Number(forexData[value]) + Number(process.env.REACT_APP_FOREX_API_ADJUSTMENT)
    })
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value
      }
    })
  }

  React.useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: selectedCurrency.currency
      }
    })
  }, []) // eslint-disable-line

  return (
    <>
      {ready ?
        <CurrencySelectionContainer>
          <Dropdown
            selection
            options={currencyOptions}
            value={currency.currency}
            onChange={handleChange}
          />
        </CurrencySelectionContainer> : 
        <>...</>
      }
    </>
  )
}

export default React.memo(CurrencySelection)
