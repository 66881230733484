import { createSlice } from '@reduxjs/toolkit'

const gallery = createSlice({
  name: 'gallery',
  initialState: {
    galleryItems: [],
    totalGalleryPages: 0,
    galleryLoading: false,
    galleryPage: 1,
    gallerySizes: [],
    gallerySizeTypes: [],
    galleryMaterials: [],
  },
  reducers: {
    setGalleryItems: (state, action) => {
      state.galleryItems = action.payload
    },
    addGalleryItems: (state, action) => {
      // state.galleryItems = [...new Set([...state.galleryItems, ...action.payload])]
      const tempArr = [...new Set([...state.galleryItems, ...action.payload])]
      state.galleryItems = [...new Set(tempArr.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
    },
    updateGalleryItem: (state, action) => {
      state.galleryItems = state.galleryItems.map(item => item.id === action.payload.id ? ({...item, ...action.payload}) : ({...item}))
    },
    setTotalGalleryPages: (state, action) => {
      state.totalGalleryPages = action.payload
    },
    setGalleryLoading: (state, action) => {
      state.galleryLoading = action.payload
    },
    setGalleryPage: (state, action) => {
      state.galleryPage = action.payload
    },
    setGallerySizes: (state, action) => {
      state.gallerySizes = action.payload
    },
    setGallerySizeTypes: (state, action) => {
      state.gallerySizeTypes = action.payload
    },
    setGalleryMaterials: (state, action) => {
      state.galleryMaterials = action.payload
    },
  },
  devTools: false
})


export const getGalleryState = (state) => state.gallery

export default gallery