import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import cn from "classnames";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import {
  Grid,
  // Button,
  Dimmer,
  Loader,
  Image,
  Button,
} from "semantic-ui-react";
import { AllCreators } from "./Components";

// STYLES
import { StyledContainer } from "./AllCreatorsPageStyles";

const AllCreatorsPage = () => {
  const dispatch = useDispatch();
  const { siteSettings } = useSelector(getUIState);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate("store"));
    dispatch(uiSlice.actions.setCurrentPage("discover"));
    dispatch(uiSlice.actions.setCurrentPageURL());
    dispatch(uiSlice.actions.setCurrentPageTitle());
    // const token = localStorage.Authorization
    setReady(true);
  }, []); // eslint-disable-line

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Creators - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}/creators`}
        />
      </Helmet>
      {ready ? <AllCreators limit={-1} /> : <>Loading...</>}
    </StyledContainer>
  );
};

export default AllCreatorsPage;
