import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  .loginSignupButton {
    border-radius: 4rem;
    overflow: hidden;
    .button {
      font-size: 1.25rem;
      @media ${stylevars.breakpoints.phone} {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      > .icon {
        @media ${stylevars.breakpoints.phone} {
          margin: 0 !important;
        }
      }
      > span {
        @media ${stylevars.breakpoints.phone} {
          display: none;
        }
      }
    }
    .or {
      height: 3rem;
    }
  }
`