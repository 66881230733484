import React from 'react'
import cn from 'classnames'
import { consoleLogger } from '@Utils'
import InnerImageZoom from 'react-inner-image-zoom';

// COMPONENTS
import {
  Dimmer,
  Loader,
} from 'semantic-ui-react'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getGalleryState } from '@Slices/gallerySlice'

const ViewerImagePreview = (props) => {
  const {
    src,
    alt,
    type,
    paper,
    frame,
    border,
    canvas
  } = props

  const dispatch = useDispatch()
  const { showViewer, viewerUI, viewerFetching, viewerImageDone } = useSelector(getUIState) // eslint-disable-line
  const { gallerySizeTypes, galleryMaterials, gallerySizes } = useSelector(getGalleryState) // eslint-disable-line
  
  const [ imageStyles, setImageStyles ] = React.useState()
  const [ srcStyles, setSrcStyles ] = React.useState()
  const [ facewrapStyles, setFacewrapStyles] = React.useState()
  const [ sideStyles, setSideStyles ] = React.useState()
  const [ sideSrcStyles, setSideSrcStyles ] = React.useState()
  const [ sideSolidStyles, setSideSolidStyles ] = React.useState()
  const [ shadowStyles, setShadowStyles ] = React.useState()
  const [ frameStyles, setFrameStyles ] = React.useState()
  const [ borderStyles, setBorderStyles ] = React.useState()
  const [ borderFaceStyles, setBorderFaceStyles ] = React.useState()
  const [ wrapperStyles, setWrapperStyles ] = React.useState()
  // const [ imageLoading, setImageLoading ] = React.useState(false)

  const [ imageDimensions, setImageDimensions ] = React.useState()

  React.useEffect(() => {
    consoleLogger('NEW props.src ===========', src)
    const chkImg = new Image()
    chkImg.src = src
    dispatch(uiSlice.actions.setViewerImageDone(false))
    chkImg.onload = () => {
      consoleLogger('IMAGE ON LOAD ===========', src, `${chkImg.width} x ${chkImg.height}`)
      setImageDimensions({
        width: chkImg.width,
        height: chkImg.height
      })
      dispatch(uiSlice.actions.setViewerImageDone(true))
    }

    return(() => {
      consoleLogger('CALLBACK SRC', src)
    })
  }, [src]) // eslint-disable-line

  React.useEffect(() => {
    let ratio = 1
    let width = 160
    let height = 160
    let types, frames, papers, borders, sides, bgSizeAdjust

    // adjust image dimensions to fit inside viewerbox
    const adjust = 1.1
    if (imageDimensions) {
      width = Number(imageDimensions.width)
      height = Number(imageDimensions.height)
      const imgWidth = Number(imageDimensions.width)
      const imgHeight = Number(imageDimensions.height)
      const viewerWidth = Number(viewerUI.width)
      const viewerHeight = Number(viewerUI.height)
      
      let widthRatio = viewerWidth / imgWidth
      let heightRatio = viewerHeight / imgHeight
      ratio = Math.min(widthRatio, heightRatio)
      // if (Number(imageDimensions.width) > Number(viewerUI.width)) {
      //   ratio = viewerUI.width / imageDimensions.width
      //   width = (viewerUI.width) / adjust
      //   height = (imageDimensions.height * ratio) / adjust
      // } else if (Number(imageDimensions.height) > Number(viewerUI.height)) {
      //   ratio = viewerUI.height / imageDimensions.height
      //   width = (imageDimensions.width * ratio) / adjust
      //   height = (viewerUI.height) / adjust
      // }

      // if (imgWidth > viewerWidth) {
      //   if (imgHeight > viewerHeight) {
          
      //   }
      // }
      consoleLogger('============ RATIO ================', ratio, viewerUI)
      width = imgWidth * ratio
      height = imgHeight * ratio
    }

    const getGalleryType = (id) => {
      return gallerySizeTypes.filter(i => Number(i.id) === Number(id)).map(i => i)[0]
    }  
    const getGalleryMaterial = (id) => {
      return galleryMaterials.filter(i => Number(i.id) === Number(id)).map(i => i)[0]
    }

    const sidesType = {
      ['framed']: 0,
      ['framed-canvas']: 0,
      ['print-only']: 0,
      ['canvas']: 0.15,
      ['metal']: 0.01,
    }

    const zoomSize = {
      ['framed']: 1.25,
      ['framed-canvas']: 1.15,
      ['print-only']: 1,
      ['canvas']: 1.15,
      ['metal']: 1.15,
    }
    
    let frameSize = 0.095
    let borderSize = 0
    let blurSize = 0.1
    let dominantSize = width > height ? width : height
    let leaseSize = width < height ? width : height
    let wrapperWidth, wrapperHeight, sideCheck, frameSizePixel

    // ========================================
    // INITITAL STATE
    // ========================================
    // WRAPPER
    setWrapperStyles(state => ({
      ...state,
      width: width,
      height: height,
      borderRadius: `0%`,
      overflow: `visible`,
      marginLeft: 0,
    }))
    // MAIN IMAGE
    setFacewrapStyles(state => ({
      ...state,
      width: width,
      height: height,
      opacity: 0,
      boxShadow: `inset 0px 0px 0px 0px rgba(0, 0, 0, 0)`
    }))
    setImageStyles(state => ({
      ...state,
      width: width,
      height: height,
      boxShadow: `none`,
    }))
    setSrcStyles(state => ({
      ...state,
      width: width,
      height: height,
      boxShadow: `0px 0px 0px 0px rgba(0, 0, 0, 0)`
    }))
    let srcBGSize = `${width}px ${height}px`
    setSrcStyles(state => ({
      ...state,
      width: width,
      height: height,
      backgroundImage: `url(${src})`,
      backgroundSize: srcBGSize,
      backgroundPosition: `50% 50%`,
    }))
    // SIDE
    let sideWidth = Math.round(width * Number(sidesType[type.slug]))
    let sideTranslateX = 0 - Math.round(((width * Number(sidesType[type.slug])) / 2) - 1)
    let sideTransform = `rotateY(-90deg) translate3d(${sideTranslateX}px, 0px, ${Math.round(sideWidth / 2)}px)`
    setSideStyles(state => ({
      ...state,
      width: sideWidth,
      height: height,
      transform: sideTransform,
      opacity: 0,
    }))
    let sideSrcBGSize = `${width}px ${height}px`
    let sideSrcBGPosition = `0% 50%`
    setSideSrcStyles(state => ({
      ...state,
      width: sideWidth,
      height: height,
      backgroundImage: `url(${src})`,
      backgroundSize: sideSrcBGSize,
      backgroundPosition: sideSrcBGPosition,
      boxShadow: `inset 0px 0px 0px 0px rgba(0, 0, 0, 0)`
    }))
    let sideSolidColor = `#333`
    setSideSolidStyles(state => ({
      ...state,
      width: sideWidth,
      height: height,
      backgroundColor: sideSolidColor,
      boxShadow: `inset 0px 0px 0px 0px rgba(0, 0, 0, 0)`
    }))
    // FRAMES
    setFrameStyles(state => ({
      ...state,
      borderStyle: `solid`,
      opacity: 0,
    }))
    // BORDERS
    setBorderStyles(state => ({
      ...state,
      width: width,
      height: height,
      opacity: 0,
    }))
    // SHADOWS
    setShadowStyles(state => ({
      ...state,
      width: width,
      height: height,
      transform: `translate3d(0, ${Math.round(height / 10)}px, -${Math.round((width * sidesType[type.slug]) + 50)}px)`,
      filter: `blur(${width * blurSize}px)`,
      opacity: 0,
    }))

    // ========================================
    // CANVAS MODE
    // ========================================
    if (type && type.slug === 'canvas') {
      width = Math.round(width / zoomSize[type.slug])
      height = Math.round(height / zoomSize[type.slug])
      sideCheck = width > height ? height : width
      sideWidth = Math.round(sideCheck * Number(sidesType[type.slug]))
      bgSizeAdjust = Math.round(width * Number(sidesType[type.slug]))
      sideTranslateX = 0 - Math.round(((sideCheck * Number(sidesType[type.slug])) / 2) - 1)
      sideTransform = `rotateY(-90deg) translate3d(${sideTranslateX}px, 0px, ${Math.round(width / 2)}px)`
      setWrapperStyles(state => ({
        ...state,
        width: width,
        height: height,
        // marginLeft: Math.round((width / 4) - (width * Number(sidesType[type.slug]) * 1.5)),
        // left: `50%`,
        // marginLeft: `-${Math.round((width / 2))}px`,
      }))
      srcBGSize = `${width}px ${height}px`
      setSrcStyles(state => ({
        ...state,
        width: width,
        height: height,
        backgroundSize: srcBGSize,
      }))
      setSideStyles(state => ({
        ...state,
        width: sideWidth,
        height: height,
        transform: sideTransform,
        opacity: 1,
      }))
      blurSize = 0.05
      setShadowStyles(state => ({
        ...state,
        width: width,
        height: height,
        filter: `blur(${width * blurSize}px)`,
        opacity: 0.45,
      }))
      if (frame) {
        sides = getGalleryMaterial(frame.term_id)
        if (frame.slug === 'material-image-wrap') {
          setSrcStyles(state => ({
            ...state,
            backgroundSize: `${width + (bgSizeAdjust * 2)}px ${height + (bgSizeAdjust * 2)}px`,
          }))
          // Math.round(((width * Number(type.slug === 'metal' ? 0.005 : type.slug === 'canvas' ? 0.075 : 0)) / 2) - Number(type.slug === 'metal' ? 0 : type.slug === 'canvas' ? 3 : 0))
          setSideStyles(state => ({
            ...state,
            transform: sideTransform,
          }))
          setSideSrcStyles(state => ({
            ...state,
            opacity: 1,
            backgroundSize: `${width + (bgSizeAdjust * 2)}px ${height + (bgSizeAdjust * 2)}px`,
            boxShadow: `inset ${Math.round(width / 20)}px -${Math.round(width / 20)}px ${Math.round(width / 5)}px rgba(0, 0, 0, 0.35)`,
          }))
        } else {
          setSideSrcStyles(state => ({
            ...state,
            opacity: 0,
            backgroundSize: `${width + (bgSizeAdjust * 2)}px ${height + (bgSizeAdjust * 2)}px`,
            boxShadow: `inset ${Math.round(width / 20)}px 0 ${Math.round(width / 15)}px rgba(0, 0, 0, 0.15)`,
          }))
          sideSolidColor = `${sides.acf.color}`
          setSideSolidStyles(state => ({
            ...state,
            backgroundColor: sideSolidColor,
          }))
        }
      }
    }

    // ========================================
    // METAL MODE
    // ========================================
    if (type && type.slug === 'metal') {
      width = Math.round(width / zoomSize[type.slug])
      height = Math.round(height / zoomSize[type.slug])
      sideCheck = width > height ? height : width
      sideWidth = Math.round(sideCheck * Number(sidesType[type.slug]))
      sideTranslateX = 0 - Math.round(((sideCheck * Number(sidesType[type.slug])) / 2) - 1)
      sideTransform = `rotateY(-90deg) translate3d(${sideTranslateX}px, 0px, ${Math.round(width / 2)}px)`
      setWrapperStyles(state => ({
        ...state,
        width: width,
        height: height,
        // marginLeft: Math.round((width / 4) - (width * Number(sidesType[type.slug]) * 1.5)),
        // marginLeft: Math.round((width / 4)),
      }))
      setSideStyles(state => ({
        ...state,
        width: sideWidth,
        height: height,
        transform: sideTransform,
        opacity: 1,
      }))
      srcBGSize = `${width}px ${height}px`
      setSrcStyles(state => ({
        ...state,
        width: width,
        height: height,
        backgroundSize: srcBGSize,
      }))
      sideSolidColor = `#9c9c9c`
      setSideSolidStyles(state => ({
        ...state,
        backgroundColor: sideSolidColor,
      }))
      blurSize = 0.1
      setShadowStyles(state => ({
        ...state,
        width: width,
        height: height,
        filter: `blur(${width * blurSize}px)`,
        opacity: 0.25,
      }))
      // HIDDEN SPRITES
      setSideSrcStyles(state => ({
        ...state,
        opacity: 0,
      }))
    }

    // ========================================
    // PRINT WITH FRAME MODE
    // ========================================
    if (type && type.slug === 'framed') {
      frameSize = 0.15
      width = Math.round(width / zoomSize[type.slug])
      height = Math.round(height / zoomSize[type.slug])
      dominantSize = width > height ? width : height
      leaseSize = width < height ? width : height
      borders = getGalleryMaterial(border.term_id)
      borderSize = borders.acf.border_size / 8
      wrapperWidth = width + (leaseSize * frameSize) + (leaseSize * borderSize)
      wrapperHeight = height + (leaseSize * frameSize) + (leaseSize * borderSize)
      consoleLogger("=========== WRAPPER SIZE PIXEL ==========================", wrapperWidth, wrapperHeight)
      frameSizePixel = (leaseSize * frameSize)
      consoleLogger("=========== FRAME SIZE PIXEL ==========================", frameSizePixel)
      setWrapperStyles(state => ({
        ...state,
        width: wrapperWidth,
        height: wrapperHeight,
        marginLeft: 0,
      }))
      let imageWidth = width
      let imageHeight = height
      setImageStyles(state => ({
        ...state,
        width: imageWidth,
        height: imageHeight,
      }))
      setFacewrapStyles(state => ({
        ...state,
        width: imageWidth,
        height: imageHeight,
        opacity: 1,
        boxShadow: `inset 0px ${width * 0.01}px ${width * 0.010}px ${width * 0.001}px rgba(0, 0, 0, 0.35)`
      }))
      srcBGSize = `${width}px ${height}px`
      setSrcStyles(state => ({
        ...state,
        width: imageWidth,
        height: imageHeight,
        backgroundSize: srcBGSize,
        boxShadow: `inset -1px -1px 0px 0px rgba(255, 255, 255, 0.25)`
      }))
      setBorderStyles(state => ({
        ...state,
        width: width + (leaseSize * borderSize),
        height: height + (leaseSize * borderSize),
        opacity: 1,
      }))
      setBorderFaceStyles(state => ({
        ...state,
        backgroundColor: borders.acf.color,
        boxShadow: `inset 0px ${width * 0.01}px ${width * 0.02}px ${width * 0.002}px rgba(0, 0, 0, 0.75)`
      }))
      setShadowStyles(state => ({
        ...state,
        width: wrapperWidth,
        height: wrapperHeight,
        opacity: 0.5,
      }))
      // HIDDEN SPRITES
      setSideStyles(state => ({
        ...state,
        opacity: 0,
      }))
      if (frame) {
        frames = getGalleryMaterial(frame.term_id)
        
        setFrameStyles(state => ({
          ...state,
          opacity: 1,
          borderWidth: `${frameSizePixel}px`,
          borderImage: `url(${frames.acf.image}) 100 stretch`,
          // backgroundImage: `url(${frames.acf.image})`,
        }))
      }
    }

    // ========================================
    // FRAMED CANVAS MODE
    // ========================================
    if (type && type.slug === 'framed-canvas') {
      frameSize = 0.095
      width = Math.round(width / zoomSize[type.slug])
      height = Math.round(height / zoomSize[type.slug])
      borderSize = 0.05
      dominantSize = width > height ? width : height
      leaseSize = width < height ? width : height
      wrapperWidth = width + (leaseSize * frameSize) + (leaseSize * borderSize)
      wrapperHeight = height + (leaseSize * frameSize) + (leaseSize * borderSize)
      consoleLogger("=========== WRAPPER SIZE PIXEL ==========================", wrapperWidth, wrapperHeight)
      frameSizePixel = (leaseSize * frameSize)
      consoleLogger("=========== BORDER SIZE PIXEL ==========================", frameSizePixel)
      setWrapperStyles(state => ({
        ...state,
        width: wrapperWidth,
        height: wrapperHeight,
        marginLeft: 0,
      }))
      let imageWidth = width
      let imageHeight = height
      setImageStyles(state => ({
        ...state,
        width: imageWidth,
        height: imageHeight,
        boxShadow: `0px ${width * 0.003}px ${width * 0.006}px ${width * 0.002}px rgba(0, 0, 0, 0.5)`,
      }))
      srcBGSize = `${width}px ${height}px`
      setSrcStyles(state => ({
        ...state,
        width: imageWidth,
        height: imageHeight,
        backgroundSize: srcBGSize,
        boxShadow: `inset 0px 4px 4px 0px rgba(255, 255, 255, 0.5), inset 0px -4px 4px 0px rgba(0, 0, 0, 0.25)`,
      }))
      setFacewrapStyles(state => ({
        ...state,
        width: imageWidth,
        height: imageHeight,
        boxShadow: `inset 0px -${width * 0.005}px ${width * 0.01}px ${width * 0.001}px rgba(0, 0, 0, 0.5)`,
        opacity: 1,
      }))
      setBorderStyles(state => ({
        ...state,
        width: width + (leaseSize * borderSize),
        height: height + (leaseSize * borderSize),
        opacity: 1,
      }))
      setBorderFaceStyles(state => ({
        ...state,
        // backgroundColor: `#3c3c3c`,
        backgroundColor: `rgba(0, 0, 0, 0)`,
        boxShadow: `inset 0px ${width * 0.01}px ${width * 0.02}px ${width * 0.002}px rgba(0, 0, 0, 0.75)`
      }))
      setShadowStyles(state => ({
        ...state,
        width: wrapperWidth,
        height: wrapperHeight,
        opacity: 0.5,
      }))
      // HIDDEN SPRITES
      setSideStyles(state => ({
        ...state,
        opacity: 0,
      }))

      if (frame) {
        frames = getGalleryMaterial(frame.term_id)
        
        setFrameStyles(state => ({
          ...state,
          opacity: 1,
          borderWidth: `${frameSizePixel}px`,
          borderStyle: `solid`,
          borderImage: `url(${frames.acf.image}) 100 stretch`,
          // backgroundImage: `url(${frames.acf.image})`,
        }))
      }
    }
  }, [type, frame, border, paper, canvas, viewerUI, imageDimensions, galleryMaterials, gallerySizeTypes, src])

  return (
    <>
      <div className={cn('viewerImagePreview', {
        [type.slug]: type.slug,
        'loading': !(viewerFetching === !viewerImageDone)
      })}>
        <div className='wrapper' style={{...wrapperStyles}}>
          <Dimmer active={!viewerImageDone}>
            <Loader size='massive' inverted />
          </Dimmer>
          {type && type.slug === 'print-only' &&
            <InnerImageZoom src={src} zoomSrc={src} hideCloseButton />
          }
          <span className='sprite face' style={{...imageStyles}}>
            <span className='sprite facewrap' style={{...facewrapStyles}} />
            <span className='img' style={{...srcStyles}} />
          </span>
          <span className='sprite side' style={{...sideStyles}}>
            <span className='img' style={{...sideSrcStyles}} />
            <span className='solid' style={{...sideSolidStyles}} />
          </span>
          <span className='sprite border' style={{...borderStyles}}>
            <span className='borderColor' style={{...borderFaceStyles}} />
          </span>
          <span className='sprite frame' style={{...frameStyles}} />
          <span className='sprite shadow' style={{...shadowStyles}} />
        </div>
      </div>
    </>
  )
}

export default React.memo(ViewerImagePreview)
