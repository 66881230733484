import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const CheckoutContainer = styled("div")`
  padding: 2rem;
  background-color: ${stylevars.paper.bgColor};

  .cart {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &Container {
      width: 100%;
    }

    /* .cartItems */
    &Items {
      &Container {
        /* padding-top: 1rem; */
      }
    }

    /* .cartSummary */
    &Summary {
      /* .cartSummaryContainer */
      &Container {
        background-color: ${stylevars.paper.bgColor};
        padding: 1rem 0;
        position: sticky;
        top: 0;
      }
      /* .cartSummaryHeader */
      &Header {
        display: flex !important;
        align-items: center;
        margin-bottom: 1rem;
        h3 {
          flex-grow: 1;
          margin-bottom: 0;
          @media ${stylevars.breakpoints.tablet} {
            margin-bottom: 0.5rem;
          }
        }
        @media ${stylevars.breakpoints.tablet} {
          display: block !important;
          text-align: left;
        }
      }

      .row.coupon,
      .row.totalcheckout,
      .row.shippingcheckout,
      .row.subtotalcheckout {
        @media ${stylevars.breakpoints.tablet} {
          .column {
            width: 100% !important;
          }
        }
      }

      p.address {
        span {
          display: block;
        }
      }

      .addNumber {
        color: ${stylevars.palette.success.main};
        font-weight: bold;
      }

      .subtractNumber {
        color: ${stylevars.palette.danger.main};
        font-weight: bold;
      }

      .total {
        h2 {
          &.strike {
            margin: 0;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            @media ${stylevars.breakpoints.tablet} {
              font-size: 1rem !important;
            }
            span {
              &:first-of-type {
                margin-right: 1rem;
                text-decoration: line-through;
                color: red;
              }
              &.discount {
                font-size: 1rem;
                text-decoration: none;
              }
            }
          }
          &.discounted {
            display: flex;
            align-items: center;
            @media ${stylevars.breakpoints.tablet} {
              font-size: 1.5rem !important;
            }
            span {
              &:first-of-type {
                margin-right: 1rem;
              }
            }
            .saved {
              font-size: 1rem;
              font-weight: normal;
            }
          }
        }
      }

      .couponBox {
        > .vendorCartHeaderCoupon {
          width: 100%;
          &:not(:last-of-type) {
            margin-bottom: 0.25rem;
          }
          > .label {
            flex-grow: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          @media ${stylevars.breakpoints.tablet} {
            > .label {
            }
          }
        }
      }
    }
  }

  .checkout {
    width: 100% !important;
    max-width: 600px !important;
    margin: auto !important;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cartContainer {
    }

    .tableContainer {
      padding: 1rem;
      width: 100%;
      /* max-width: 1200px; */
    }

    .cartSummaryContainer {
      .row {
        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }

    .checkoutContainer {
      background-color: ${stylevars.paper.bgColor};
      padding: 1rem 0;
      .row {
        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
      }

      @media ${stylevars.breakpoints.tablet} {
        /* min-height: 440px; */
      }
    }

    .total {
      background-color: rgba(0, 0, 0, 0.05);
      min-height: 10rem;
      .strike {
        font-size: 1.2rem !important;
        span {
          margin: 0 !important;
        }
      }
      .discounted {
        margin: 0 !important;
        display: inline-flex;
        align-items: flex-start !important;
        flex-direction: column;
        span {
          &:not(:last-of-type) {
            margin-right: 0 !important;
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
          }
          &.saved {
            line-height: 1;
          }
        }
      }
    }

    .vendorCouponCheckout {
      display: inline-flex;
      &:not(:last-of-type) {
        margin-bottom: 0.25rem;
      }
      span {
        &:not(:last-of-type) {
          margin-right: 0.5rem;
        }
        &.label {
          flex-grow: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @media ${stylevars.breakpoints.tablet} {
            max-width: 3rem;
          }
        }
      }
    }

    .paymentSegment {
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
      }

      &.select {
        /* padding: 2rem; */
      }

      &.information {
        padding: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .rccs {
          margin-bottom: 4rem;
        }
      }

      &.billing {
      }

      .or {
        width: 2rem;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;

        &:before,
        &:after {
          content: "";
          display: block;
          height: calc(50% - 2rem);
          width: 1px;
          background-color: ${stylevars.paper.borderColor};
        }
        &:before {
          margin-bottom: 0.5rem;
        }
        &:after {
          margin-top: 0.5rem;
        }

        @media ${stylevars.breakpoints.phone} {
          width: 100%;
          height: 2rem;
          flex-direction: row;
          &:before,
          &:after {
            width: calc(50% - 3rem);
            height: 1px;
          }
          &:before {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
          &:after {
            margin-top: 0;
            margin-left: 0.5rem;
          }
        }
      }

      .paymentSelectRow {
        .column {
          width: calc(50% - 2rem);
        }
      }

      .paymentSelectButton {
        width: calc(100% - 2rem);
        min-height: 12rem;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 0;
        border: 1px solid rgba(${stylevars.paper.borderColorRGB}, 0);
        box-shadow: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.active {
          background-color: rgba(
            ${stylevars.palette.success.rgb},
            0.1
          ) !important;
        }
        .image {
          margin-bottom: 1rem;
          width: 100%;
          max-width: 220px;
          height: auto;
        }
        span {
          font-weight: bold;
        }
        &:hover {
          border: 1px solid rgba(${stylevars.paper.borderColorRGB}, 1);
        }
      }

      .field {
        position: relative;
      }
      .prompt.label {
        position: absolute;
        z-index: 2;
        top: calc(100% - 1rem);
        left: 0.5rem;
      }

      .addressLabel {
        padding-top: 0.75rem;
      }

      .addressField {
        position: relative;
        .field {
          margin: 0 !important;
        }
      }
      .addressPopup {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        background-color: ${stylevars.paper.bgColor};
        border: 1px solid ${stylevars.paper.borderColor};
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.25);

        .ui.list {
          .item {
            font-size: 1rem;
            line-height: 1.5;
            padding: 0.5rem 1rem;

            strong {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  .orderStatus {
    /* .orderStatus_header */
    &_header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media ${stylevars.breakpoints.phone} {
        display: block;
      }
      > span {
        flex-grow: 1;
      }
    }
    /* .orderStatus_status */
    &_status {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media ${stylevars.breakpoints.phone} {
        justify-content: flex-start;
      }
      > span {
        font-size: 0.85rem;
      }
    }
    /* .orderStatus_label */
    &_label {
      &.PROCESSING {
      }
      &.PROCESSING {
      }
    }
  }

  .checkoutContent {
    .loginForm {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.orderStatusViewContent {
    padding: 0;

    .orderStatus_header {
      padding: 0;
      margin: 0;
    }

    > .ui.divider {
      display: none;
    }

    .orderStatus_status {
      position: absolute;
      top: 1rem;
      right: 1rem;

      @media ${stylevars.breakpoints.phone} {
        position: relative;
        top: auto;
        right: 0;
        margin-top: -1rem;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;
