import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Parallax from "parallax-js";
import { Parallax as ReactParallax } from "react-parallax";

// REDUX
import { useDispatch, useSelector } from "react-redux"; // eslint-disable-line
import userSlice, { getUserState } from "@Slices/userSlice";
import cartSlice from "@Slices/cartSlice";
import uiSlice, { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import { Grid, Button } from "semantic-ui-react";

// STYLES
import { Container } from "./SplashPageStyles";
// import Lottie from 'react-lottie-player'
import NumberFormat from "react-number-format";

// const signupAnimation = require('./Animations/signup.json');
// const setupAnimation = require('./Animations/setup.json');
// const socialAnimation = require('./Animations/social.json');

const MaintenancePage = () => {
  const dispatch = useDispatch();
  const { authenticated, userInfo } = useSelector(getUserState);
  const { siteSettings } = useSelector(getUIState);
  const [actionLink, setActionLink] = React.useState("/signup");

  const sceneRef = React.useRef();

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate("store"));
    dispatch(uiSlice.actions.setCurrentPage("splash"));
    dispatch(uiSlice.actions.setCurrentPageURL());
    dispatch(uiSlice.actions.setCurrentPageTitle());

    // clear stored user data
    dispatch(userSlice.actions.setAuthenticated(false));
    dispatch(userSlice.actions.setUserInfo(""));
    dispatch(userSlice.actions.setUserKey(""));
    dispatch(userSlice.actions.setUserAddresses([]));
    localStorage.removeItem("Authorization");
    // localStorage.removeItem('carts')
    localStorage.removeItem("coupons");
    localStorage.removeItem("cartItems");
    dispatch(cartSlice.actions.setCart([]));
    // reset user completion
    dispatch(
      userSlice.actions.updateAccountCompletion({
        hasAddress: false,
        hasProfilePhoto: false,
        hasCoverPhoto: false,
      })
    );

    const parallaxInstance = new Parallax(sceneRef.current, {
      relativeInput: true,
      hoverOnly: true,
    });

    parallaxInstance.enable();

    return () => {
      parallaxInstance.disable();
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (authenticated && userInfo.role === "sf_creator") {
      setActionLink("/admin/gallery");
    } else {
      setActionLink("/signup");
    }
  }, [authenticated, userInfo]); // eslint-disable-line

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}`} />
      </Helmet>
      <section className="section hero fluid">
        <div className="logo">
          <img src="/logo_light.svg" className="logoImage" />
          <h1>
            <span className="text">We'll be back soon!</span>
          </h1>
          <h4>
            Sorry for the inconvenience but we're performing some maintenance at
            the moment. We'll be back online shortly!
          </h4>
        </div>
        <div className="heroTexts">
          <div className="heroText">
            <span className="text">Create</span>
            <span className="dot red" />
          </div>
          <div className="heroText">
            <span className="text">Print</span>
            <span className="dot green" />
          </div>
          <div className="heroText">
            <span className="text">Sell</span>
            <span className="dot blue" />
          </div>
          <div className="heroText">
            <span className="text">Profit</span>
            <span className="dot yellow" />
          </div>
        </div>
        <div className="section_wrapper">
          <div className="heroContent">
            <div className="heroParallax" ref={sceneRef}>
              <div className="image image3" data-depth={0.2}>
                <img
                  src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/parallax3.png`}
                />
              </div>
              <div className="image image2" data-depth={0.6}>
                <img
                  src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/parallax2.png`}
                />
              </div>
              <div className="image image1" data-depth={1}>
                <img
                  src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/parallax1.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default React.memo(MaintenancePage);
