import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Card } from 'semantic-ui-react'

export const Root = styled.div`
  width: 100%;
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
  padding: 2rem;
  background-image: url('https://sfv1cdn1.sgp1.digitaloceanspaces.com/generic_bg2.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  background-size: cover;
  
  text-align: center;

  .field {
    position: relative;
  }
  .ui.label {
    position: absolute;
    top: calc(100% - 1.25rem);
    right: 0.5rem;
  }

  .formBox {
    width: 100%;
    max-width: calc(min(calc(var(--vw, 1vw) * 50), 380px));

    @media ${stylevars.breakpoints.phone} {
      max-width: 100%;
    }

    .notCreatorCustomer {
      margin-top: 2rem;
      text-align: center;
    }
  }
`

export const FormBox = styled('div')`
  .formBox {
    width: 100%;
    max-width: calc(min(calc(var(--vw, 1vw) * 50), 380px));

    @media ${stylevars.breakpoints.phone} {
      max-width: 100%;
    }

    .codebox {
      width: 100% !important;
      overflow: hidden;
      > div {
        width: 100%;
        display: grid;
        column-gap: 0.5rem;
        grid-template-columns: repeat(8, 1fr);
      }
      input {
        display: inline-block;
        padding: 0.5rem;
        width: 100% !important;
        height: 3rem !important;
        text-transform: uppercase;
      }
    }
  }
`

export const StyledCard = styled(Card)`
  width: 100% !important;
  margin: 0 auto !important;
  background-color: #fff;

  .subCard {
    background-color: #f9f9f9 !important;
    text-align: center;
  }

  .helpText {
    color: rgba(0,0,0,0.35);
    text-align: center;
  }
`