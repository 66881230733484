import React from 'react'
import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

// Redux Toolkit
// import { useSelector } from 'react-redux'
// import { getUserState } from '@Slices/userSlice'

const SideBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: calc(var(--vw, 1vw) * 100);
  transition: all 0.15s ease-out;
  pointer-events: none;

  @media ${stylevars.breakpoints.phone} {
    .sideBarWrapper {
      transform: translateX(-${stylevars.sideBar.width});
      @media ${stylevars.breakpoints.phone} {
        transform: translateX(-80vw);
      }
    }
    &.show {
      pointer-events: all;
      background-color: rgba(0, 0, 0, 0.4);
      .sideBarWrapper {
        transform: translateX(0%);
      }
    }
  }
  
  @media ${stylevars.breakpoints.tablet} {
    .sideBarWrapper {
      transform: translateX(-${stylevars.sideBar.width});
      @media ${stylevars.breakpoints.phone} {
        transform: translateX(-80vw);
      }
    }
    &.show {
      pointer-events: all;
      background-color: rgba(0, 0, 0, 0.4);
      .sideBarWrapper {
        transform: translateX(0%);
      }
    }
  }

  .sideBarWrapper {
    pointer-events: all;
    transition: all 0.15s ease-out;
    background-color: ${stylevars.sideBar.bgColor};
    width: 100%;
    max-width: ${stylevars.sideBar.width};
    height: calc(var(--vh, 1vh) * 100);

    @media ${stylevars.breakpoints.phone} {
      max-width: 80vw;
    }
  }

  .ui {
    &.header {
      min-height: ${stylevars.navBar.height};
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
      margin: 0 !important;
      padding: 0 2rem !important;

      img {
        width: 100% !important;
        height: 32px !important;
        object-fit: contain;
        object-position: center center;
        margin: 0 !important;
        padding: 0;
      }
    }

    &.list {
      margin: 0;
      .item {
        color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        min-height: 3rem;
        background-color: ${stylevars.sideBar.linkColor.main};
        transition: all 0.15s ease-out;
        .icon {
          color: ${stylevars.sideBar.iconColor.main};
          margin: 0;
          font-size: 1.5rem;
          height: 3rem;
          width: 4rem;
          line-height: 4rem;
          transition: all 0.15s ease-out;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .header {
          color: ${stylevars.sideBar.textColor.main};
          line-height: 2rem;
          margin: 0;
          padding: 0;
          transition: all 0.15s ease-out;
        }
        &:hover {
          color: #fff;
          .icon {
            color: ${stylevars.sideBar.iconColor.hover};
          }
          .header {
            color: ${stylevars.sideBar.textColor.hover};
          }
          background-color: ${stylevars.sideBar.linkColor.hover};
        }
        &.active {
          .icon {
            color: ${stylevars.sideBar.iconColor.active};
          }
          .header {
            color: ${stylevars.sideBar.textColor.active};
          }
          background-color: ${stylevars.sideBar.linkColor.active};
        }
      }
    }
  }
`

const StyledSideBar = (props) => {
  // const { authenticated } = useSelector(getUserState)
  return (
    <SideBar {...props}>
      {props.children}
    </SideBar>
  )
}

export default React.memo(StyledSideBar)