import React from 'react'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import { consoleLogger } from '@Utils'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  Grid,
  Form as SUIForm,
  Button,
  Divider,
} from 'semantic-ui-react'
import AccountBillingForm from './AccountBillingForm'
import AccountMailingForm from './AccountMailingForm'
import { toast } from 'react-toastify';
import AccountInformationOverview from './AccountInformationOverview';
import { ReactComponent as FoundationLogo } from '@Common/Icons/FoundationLogo.svg'

// VALIDATION SCHEMA
Yup.addMethod(Yup.string, 'nospecial', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    value = originalValue.matches(/[^a-zA-Z0-9]/)

    consoleLogger(originalValue, value)
    return value ? value.toString() : ''
  });
});
const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(32, "Must be shorter than 32 characters")
    // .test('nospecial', 'Special characters are not allowed', (val) => {
    //   const pattern = /^[a-zA-Z0-9]+$/
    //   return (
    //     pattern.test(val)
    //   )
    // })
    .required('Must enter a first name'),
  last_name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(32, "Must be shorter than 32 characters")
    // .test('nospecial', 'Special characters are not allowed', (val) => {
    //   const pattern = /^[a-zA-Z0-9]+$/
    //   return (
    //     pattern.test(val)
    //   )
    // })
    .required('Must enter a last name'),
  nickname: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(16, "Must be shorter than 16 characters"),
    // .test('nospecial', 'Special characters are not allowed', (val) => {
    //   const pattern = /^[a-zA-Z0-9]+$/
    //   return (
    //     pattern.test(val)
    //   )
    // }),
  display_name: Yup.string()
    .required('Must select a display name'),
  email: Yup.string()
    .email('Invalid email'),
  password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(32, "Must be shorter than 32 characters"),
  new_password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(32, "Must be shorter than 32 characters"),
  new_password2: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(32, "Must be shorter than 32 characters")
})

const AccountInformation = () => {
  const dispatch = useDispatch()
  const { userInfo, userKey, userAddresses } = useSelector(getUserState)
  const [ socialMedia, setSocialMedia ] = React.useState({
    facebook: userInfo.acf.social_media && userInfo.acf.social_media.facebook ? userInfo.acf.social_media.facebook : '',
    twitter: userInfo.acf.social_media && userInfo.acf.social_media.twitter ? userInfo.acf.social_media.twitter : '',
    instagram: userInfo.acf.social_media && userInfo.acf.social_media.instagram ? userInfo.acf.social_media.instagram : '',
    behance: userInfo.acf.social_media && userInfo.acf.social_media.behance ? userInfo.acf.social_media.behance : '',
    dribbble: userInfo.acf.social_media && userInfo.acf.social_media.dribbble ? userInfo.acf.social_media.dribbble : '',
    likedin: userInfo.acf.social_media && userInfo.acf.social_media.likedin ? userInfo.acf.social_media.likedin : '',
    youtube: userInfo.acf.social_media && userInfo.acf.social_media.youtube ? userInfo.acf.social_media.youtube : '',
    foundation: userInfo.acf.social_media && userInfo.acf.social_media.foundation ? userInfo.acf.social_media.foundation : '',
  })
  const [displayNameOptions, setDisplayNameOptions] = React.useState([])
  // const displayNameOptions = [
  //   { key: 'name1', value: 'name1', text: 'Name 1' },
  //   { key: 'name2', value: 'name2', text: 'Name 2' },
  //   { key: 'name3', value: 'name3', text: 'Name 3' },
  //   { key: 'name4', value: 'name4', text: 'Name 4' },
  // ]

  React.useEffect(() => {
    let displayNames = []
    if (userInfo.first_name) {
      displayNames.push({ key: userInfo.first_name, value: userInfo.first_name, text: userInfo.first_name })
    }
    if (userInfo.last_name) {
      displayNames.push({ key: userInfo.last_name, value: userInfo.last_name, text: userInfo.last_name })
    }
    if (userInfo.first_name && userInfo.last_name) {
      displayNames.push({ key: `${userInfo.first_name} ${userInfo.last_name}`, value: `${userInfo.first_name} ${userInfo.last_name}`, text: `${userInfo.first_name} ${userInfo.last_name}` })
      displayNames.push({ key: `${userInfo.last_name}, ${userInfo.first_name}`, value: `${userInfo.last_name}, ${userInfo.first_name}`, text: `${userInfo.last_name}, ${userInfo.first_name}` })
    }
    if (userInfo.nickname) {
      displayNames.push({ key: `nickname-${userInfo.nickname}`, value: userInfo.nickname, text: userInfo.nickname })
    }
    if (userInfo.username) {
      displayNames.push({ key: `username-${userInfo.username}`, value: userInfo.username, text: userInfo.username })
    }
    setDisplayNameOptions(displayNames)

    setSocialMedia({
      facebook: userInfo.acf.social_media && userInfo.acf.social_media.facebook ? userInfo.acf.social_media.facebook : '',
      twitter: userInfo.acf.social_media && userInfo.acf.social_media.twitter ? userInfo.acf.social_media.twitter : '',
      instagram: userInfo.acf.social_media && userInfo.acf.social_media.instagram ? userInfo.acf.social_media.instagram : '',
      behance: userInfo.acf.social_media && userInfo.acf.social_media.behance ? userInfo.acf.social_media.behance : '',
      dribbble: userInfo.acf.social_media && userInfo.acf.social_media.dribbble ? userInfo.acf.social_media.dribbble : '',
      likedin: userInfo.acf.social_media && userInfo.acf.social_media.likedin ? userInfo.acf.social_media.likedin : '',
      youtube: userInfo.acf.social_media && userInfo.acf.social_media.youtube ? userInfo.acf.social_media.youtube : '',
    })
  }, [userInfo])

  return (
    <>
      <Formik
        initialValues={{
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          nickname: userInfo.nickname,
          display_name: userInfo.display_name,
          password: '',
          new_password: '',
          new_password2: '',
          social_facebook: socialMedia.facebook,
          social_twitter: socialMedia.twitter,
          social_instagram: socialMedia.instagram,
          social_youtube: socialMedia.youtube,
          social_behance: socialMedia.behance,
          social_dribbble: socialMedia.dribbble,
          social_linkedin: socialMedia.linkedin,
          social_foundation: socialMedia.foundation,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          (async () => {
            const token = localStorage.Authorization
            let newData = {
              first_name: data.first_name,
              last_name: data.last_name,
              display_name: data.display_name,
              nickname: data.nickname,
              user_key: userKey,
              social_media: {
                facebook: data.social_facebook,
                twitter: data.social_twitter,
                instagram: data.social_instagram,
                youtube: data.social_youtube,
                behance: data.social_behance,
                dribbble: data.social_dribbble,
                linkedin: data.social_linkedin,
                foundation: data.social_foundation,
              }
            }
            setSubmitting(true)
            consoleLogger('SUBMITING FORM ===========================', data, newData)
            await axios({
              baseURL: process.env.REACT_APP_API_URL,
              url: `/stylefolio/v1/users/${userInfo.id}`,
              headers: {
                Authorization: `${token}`,
              },
              method: 'POST',
              data: newData
            }).then(res => {
              const userInfo = res.data
              consoleLogger("USER INFO =====================", userInfo)
              dispatch(userSlice.actions.updateUserInfo({...userInfo}))
              setSubmitting(false)
              
              toast.success('You have successfully updated your personal information.', {
                containerId: 'mainToaster',
                progress: undefined,
              });
              // dispatch(uiSlice.actions.setUIToast({
              //   visible: true,
              //   type: 'positive',
              //   icon: 'info circle',
              //   title: 'Personal Information Updated',
              //   message: 'You have successfully updated your personal information.'
              // }))
            }).catch(err => {
              const errorData = err
              consoleLogger("ERROR: ", err)
              setErrors(errorData)
              setSubmitting(false)
            })
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, values, setFieldValue, errors, isValid, dirty}) => (
          <Form className='ui form formContainer'>
            <Grid divided>
              <Grid.Row>
                <Grid.Column mobile={16} computer={4}>
                  <AccountInformationOverview />
                </Grid.Column>
                <Grid.Column mobile={16} computer={12}>
                  <h3>Personal Information</h3>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} computer={8}>
                        <SUIForm.Input name='first_name' fluid placeholder='First Name' type='text' label='First Name *'
                          defaultValue={userInfo.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.first_name}
                          disabled={isSubmitting}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={8}>
                        <SUIForm.Input name='last_name' fluid placeholder='Last Name' type='text' label='Last Name *'
                          defaultValue={userInfo.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.last_name}
                          disabled={isSubmitting}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column mobile={16} computer={8}>
                        <SUIForm.Input name='nickname' fluid placeholder='Last Name' type='text' label='Nick Name (optional)'
                          defaultValue={userInfo.nickname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.nickname}
                          disabled={isSubmitting}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={8}>
                        <SUIForm.Select name='display_name' fluid placeholder='Display Name' label='Publicly Displayed Name *'
                          onChange={(ev, {value}) => {
                            consoleLogger(value)
                            setFieldValue('display_name', value)
                          }}
                          defaultValue={userInfo.display_name}
                          options={displayNameOptions}
                          error={errors.display_name}
                          disabled={isSubmitting}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {userInfo.role === 'sf_creator' &&
                    <>
                      <h3>Social Media</h3>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="facebook" iconPosition='left' name='social_facebook' fluid placeholder='facebook id' type='text' label='Facebook ID'
                              defaultValue={socialMedia.facebook}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_facebook}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="twitter" iconPosition='left' name='social_twitter' fluid placeholder='twitter handle' type='text' label='Twitter Handle'
                              defaultValue={socialMedia.twitter}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_twitter}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="instagram" iconPosition='left' name='social_instagram' fluid placeholder='instagram id' type='text' label='Instagram ID'
                              defaultValue={socialMedia.instagram}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_instagram}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="youtube" iconPosition='left' name='social_youtube' fluid placeholder='youtube channel id' type='text' label='Youtube Channel ID'
                              defaultValue={socialMedia.youtube}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_youtube}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="behance" iconPosition='left' name='social_behance' fluid placeholder='behance id' type='text' label='Behance ID'
                              defaultValue={socialMedia.behance}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_behance}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="dribbble" iconPosition='left' name='social_dribbble' fluid placeholder='dribble id' type='text' label='Dribbble ID'
                              defaultValue={socialMedia.dribbble}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_dribbble}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon="linkedin" iconPosition='left' name='social_linkedin' fluid placeholder='linkedin id' type='text' label='LinkedIn ID'
                              defaultValue={socialMedia.linkedin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_linkedin}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={4}>
                            <SUIForm.Input icon={<span className='customicon'><FoundationLogo /></span>} iconPosition='left' name='social_foundation' fluid placeholder='@your_id' type='text' label='Foundation ID'
                              defaultValue={socialMedia.foundation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.social_foundation}
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </>
                  }

                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='actions'>
                <Grid.Column mobile={16}>
                  <Button
                    type='submit'
                    positive
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className='addresses'>
                <Grid.Column mobile={16} computer={16}>
                  <h3 className='pageTitle'>Addresses</h3>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} computer={8}>
                        <AccountBillingForm />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={8}>
                        {userAddresses && userAddresses.length > 0 &&
                          <AccountMailingForm />
                        }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default React.memo(AccountInformation)
