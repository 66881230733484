import React from "react";
import { MuuriComponent } from "muuri-react";
import axios from "axios";
import { debounce } from "lodash";
import { consoleLogger } from "@Utils";
import { Helmet } from "react-helmet";
import useLocalStorage from "react-use-localstorage";
import { ToastContainer } from "react-toastify";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import gallerySlice, { getGalleryState } from "@Slices/gallerySlice";

// COMPONENTS
import { Button, Checkbox, Dimmer, Loader, Message } from "semantic-ui-react";
import { DashToolbar, PagePreloader } from "@Common";
import { GalleryItem, GalleryUploader, GalleryGridFilter } from "./Components";

import { StyledContainer } from "./GalleryStyles";
import { toast } from "react-toastify";

const GalleryPage = () => {
  const token = localStorage.Authorization;
  const dispatch = useDispatch();
  const { authenticated, userInfo, userGallery } = useSelector(getUserState);
  const { siteSettings, galleryCanArrange, galleryShowUploader } =
    useSelector(getUIState);
  const [isReady, setIsReady] = React.useState(false);
  const redirect = encodeURIComponent(`/admin/gallery`);
  const [draggable, setDraggable] = React.useState(false);
  const [uploadModal, setUploadModal] = React.useState(false);
  const muuriRef = React.useRef(null);

  const handleUpdateOrder = debounce(
    async (results) => {
      consoleLogger("=========================", results);
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/gallery/arrange`,
        headers: {
          Authorization: `${token}`,
        },
        method: "POST",
        data: {
          author: userInfo.id,
          sequence: results,
        },
      })
        .then((res) => {
          consoleLogger("handleUpdateOrder RESPONSE =============", res.data);
          // dispatch(userSlice.actions.rearrangeUserGallery(results))
          // handleSetProcess(id, false)

          toast.success(
            `Your gallery arrangement has been saved automatically.`,
            {
              containerId: "mainToaster",
              progress: undefined,
            }
          );
          // dispatch(uiSlice.actions.setUIToast({
          //   visible: true,
          //   type: 'positive',
          //   icon: 'info circle',
          //   title: `Auto save...`,
          //   message: `Your gallery arrangement has been saved automatically.`
          // }))
        })
        .catch((err) => {
          consoleLogger(err);
          // handleSetProcess(id, false)
        });
    },
    3000,
    { leading: false }
  );

  const setShowGalleryUploader = () => {
    dispatch(uiSlice.actions.setShowGalleryUploader(true));
  };

  const fetchGallery = async () => {
    setIsReady(false);
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery`,
      method: "GET",
      params: {
        author: userInfo.id,
      },
    })
      .then((res) => {
        consoleLogger("FETCHING USER GALLERY RESPONSE =============", res.data);
        // setTotalPages(Number(res.headers['x-wp-totalpages']))
        dispatch(userSlice.actions.setUserGallery(res.data));
      })
      .catch((err) => {
        consoleLogger(err);
      });
    setIsReady(true);
  };

  const handleDraggable = () => {
    setDraggable((prev) => !prev);
  };
  const handleUploadModal = (bool) => {
    setUploadModal(bool);
  };

  React.useEffect(() => {
    (async () => {
      dispatch(uiSlice.actions.setPageTemplate("dash"));
      dispatch(uiSlice.actions.setCurrentPage("gallery"));
      dispatch(uiSlice.actions.setCurrentPageURL());
      dispatch(uiSlice.actions.setCurrentPageTitle());
    })();

    return () => {
      dispatch(uiSlice.actions.setGalleryArrange(false));
      dispatch(uiSlice.actions.setShowGalleryUploader(false));
    };
  }, []); // eslint-disable-line

  const [uploadLimit, setUploadLimit] = React.useState();
  const [reachedLimit, setReachedLimit] = React.useState(true);
  React.useEffect(() => {
    setUploadLimit(
      userInfo.acf.subscription_type === "premium"
        ? siteSettings.premiumLimit
        : siteSettings.starterLimit
    );
    // setReady(true)
  }, []); // eslint-disable-line

  React.useEffect(() => {
    setReachedLimit(
      userInfo.acf.subscription_type === "premium"
        ? false
        : userGallery.length >= uploadLimit
    );
  }, [uploadLimit]); // eslint-disable-line

  const galleryToolbarActions = [
    {
      key: "toggleDraggable",
      name: "toggleDraggable",
      className: "toggleDraggable",
      type: "checkbox",
      label: "Arrange Items",
      text: "Arrange Items",
      callback: handleDraggable,
    },
    {
      key: "uploadButton",
      name: "uploadButton",
      className: "uploadPhotoButton",
      type: "button",
      icon: "upload cloud",
      color: "blue",
      label: "Upload Photos",
      text: "Upload Photos",
      callback: handleUploadModal,
      disabled: reachedLimit || draggable,
    },
  ];

  const [processGallery, setProcessGallery] = React.useState(false);
  const [sortKeys, setSortKeys] = React.useState(null);
  const [arrangeBy, setArrangeBy] = React.useState("listOrder");
  const [order, setOrder] = React.useState("asc");
  const arrangeByOptions = [
    {
      key: "arrangeby-date-uploaded",
      text: "Date Uploaded",
      value: "dateUploaded",
      active: arrangeBy === "dateUploaded",
    },
    {
      key: "arrangeby-date-modified",
      text: "Date Modified",
      value: "dateModified",
      active: arrangeBy === "dateModified",
    },
    {
      key: "arrangeby-listorder",
      text: "User Defined",
      value: "listOrder",
      active: arrangeBy === "listOrder",
    },
  ];
  // const [ arrangeBy, setArrangeBy ] = useLocalStorage('__sfLocalSettings_galleryGrid_arrangeBy', 'order')
  // const [ order, setOrder ] = useLocalStorage('__sfLocalSettings_galleryGrid_order', 'desc')
  const handleGallerySort = (val) => {
    consoleLogger("+++ handleGallerySort", val);
    if (val === "listOrder") {
      setOrder("asc");
    }
    setArrangeBy(val);
  };
  const handleOrder = (val) => {
    consoleLogger("+++ handleOrder", val);
    setOrder(val);
  };

  // TODO: FILTER FOR GALLERY GRID
  // const [ filter, setFilter ] = React.useState({
  //   search: "",
  //   visible: "all"
  // })
  // const filterFunction = React.useCallback((search, visible) => {
  //   const isSearchMatch = !search
  //     ? true
  //     : data.tags.toLowerCase().indexOf(search) > -1;
  //   const isFilterMatch = visible === "all" ? true : data.showInGallery === visible;
  //   return isSearchMatch && isFilterMatch;
  // }, [search, visible])

  // const [ galleryFilter, setGalleryFilter] = React.useState('unattached')
  const [galleryFilter, setGalleryFilter] = useLocalStorage(
    "__sfLocalSettings_galleryGrid_filter",
    "all"
  );
  const handleGalleryFilter = (data) => {
    setProcessGallery(true);
    setGalleryFilter(data);
  };

  const [showAll, setShowAll] = React.useState(true);
  const handleShowAll = () => {
    setProcessGallery(true);
    setShowAll((prev) => !prev);
  };
  // const filterFunction = React.useCallback((data) => {
  //   consoleLogger('=========== MUURI DATA', data)
  //   const vis = !showAll ? 'visible' : 'hidden'
  //   return showAll ? true : data.visibility === 'visible' ? true : false
  // }, [showAll])

  const filterFunction = React.useCallback(
    (data) => {
      consoleLogger("=============== galleryFilter", galleryFilter);
      consoleLogger("=========== MUURI DATA", data);
      // const vis = !showAll ? 'visible' : 'hidden'
      // return showAll ? true : data.visibility === 'visible' ? true : false
      if (galleryFilter === "active" || galleryFilter === "inactive") {
        return data.status === galleryFilter;
      } else if (
        galleryFilter === "attached" ||
        galleryFilter === "unattached"
      ) {
        return data.visibility === galleryFilter;
      } else {
        return true;
      }
    },
    [galleryFilter]
  ); // eslint-disable-line

  React.useEffect(() => {
    handleFilterNote(galleryFilter !== "all");
    // if (galleryFilter !== "all") {
    //   toast.warning(
    //     `You are currently viewing a filtered version of your gallery`,
    //     {
    //       containerId: "galleryToaster",
    //       toastId: "galleryFilterToast",
    //     }
    //   );
    // }
  }, [isReady, galleryFilter]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("galleryFilter =============", galleryFilter);
    setTimeout(() => {
      setProcessGallery(false);
    }, 30);
  }, [galleryFilter]);

  const [allTags, setAllTags] = React.useState([]);
  React.useEffect(() => {
    if (!authenticated) {
      history.push(`/login/redirect/${redirect}`);
    } else {
      if (userGallery) {
        setIsReady(true);

        let tempTags = [];
        userGallery.forEach((galleryItem) => {
          const tags = galleryItem.acf.tags;
          // let diffTags
          if (tags && tags.length > 0) {
            // diffTags = tags.filter(x => !tempTags.includes(x))
            if (tempTags.length > 0) {
              tempTags = [...new Set([...tempTags, ...tags])];
            } else {
              tempTags = [...tags];
            }
          }
        });
        setAllTags(tempTags);
      }
    }
  }, [userGallery]); // eslint-disable-line

  React.useEffect(() => {
    if (allTags) {
      consoleLogger("================ ALL TAGS ==================");
      consoleLogger(allTags);
    }
  }, [allTags]);

  React.useEffect(() => {
    (async () => {
      setProcessGallery(true);
      if (muuriRef.current) {
        consoleLogger("================= arrangeBy ===================");
        consoleLogger(arrangeBy, typeof arrangeBy);
        consoleLogger("================= order ===================");
        consoleLogger(order, typeof order);
        const tempKeys = muuriRef.current
          .getItems()
          .map((item) => item.getKey());
        setSortKeys(tempKeys);
      }
      setTimeout(() => {
        setProcessGallery(false);
      }, 100);
    })();
  }, [arrangeBy, order]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("================= SORT KEYS ===================");
    consoleLogger(sortKeys);
  }, [sortKeys]); // eslint-disable-line

  // const handleSort = React.useCallback((dataItemA, dataItemB) => {
  //     consoleLogger('>>> ITEM A =====================================')
  //     consoleLogger(dataItemA)
  //     consoleLogger('>>> ITEM B =====================================')
  //     consoleLogger(dataItemB)
  //     let idA = parseInt(dataItemA.dateUploaded);
  //     let idB = parseInt(dataItemB.dateUploaded);
  //     if (arrangeBy === 'dateModified') {
  //       idA = parseInt(dataItemA.dateModified);
  //       idB = parseInt(dataItemB.dateModified);
  //     }
  //     return idA - idB;
  // }, [arrangeBy]) // eslint-disable-line

  const galleryChildren = userGallery.map((galleryItem) => (
    <GalleryItem
      key={`${galleryItem.ID}`}
      id={galleryItem.ID}
      post={galleryItem}
      fetchGallery={fetchGallery}
      draggable={draggable}
      mode="gallery"
      dateUploaded={Number(galleryItem.post_date_epoch)}
      dateModified={Number(galleryItem.post_modified_epoch)}
      listOrder={Number(galleryItem.acf.list_order)}
      status={galleryItem.post_status === "publish" ? "active" : "inactive"}
      visibility={galleryItem.acf.show_in_gallery ? "attached" : "unattached"}
      // tags={galleryItem.acf.tags && galleryItem.acf.tags.length > 0 ? galleryItem.acf.tags.join(' ') : null}
    />
  ));

  const [filterNote, setFilterNote] = React.useState(false);
  const handleFilterNote = (bool) => {
    setFilterNote(bool);
  };

  // Render.
  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Gallery</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}/admin/gallery`}
        />
      </Helmet>
      {isReady ? (
        <>
          {userGallery.length > 0 ? (
            <>
              <ToastContainer
                containerId="galleryToaster"
                enableMultiContainer={true}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
              />
              <DashToolbar
                title="Gallery"
                subtitle={
                  <div className="size">
                    <span className="label">Size:</span>
                    <span className="value">
                      {userGallery.length} of{" "}
                      <span className="text--bold">{uploadLimit}</span>
                    </span>
                  </div>
                }
                actions={galleryToolbarActions}
              />
              <GalleryGridFilter
                value={arrangeBy}
                onValueChange={handleGallerySort}
                order={order}
                onOrderChange={handleOrder}
                options={arrangeByOptions}
                disabled={draggable}
                showAll={showAll}
                handleShowAll={handleShowAll}
                filter={galleryFilter}
                handleFilter={handleGalleryFilter}
              />
              {filterNote && (
                <Message
                  positive
                  onDismiss={() => handleFilterNote(false)}
                  content="You are currently viewing a filtered version of your gallery."
                />
              )}
              <div className="galleryGridContainer">
                {processGallery ? (
                  <>
                    <PagePreloader title="Updating gallery..." />
                  </>
                ) : (
                  <MuuriComponent
                    ref={muuriRef}
                    items="*"
                    gridProps={{ className: "galleryGrid" }}
                    onDragEnd={(item) => {
                      const results = muuriRef.current
                        .getItems()
                        .map((item) => item._component.props.id);
                      handleUpdateOrder(results);
                      consoleLogger(results);
                    }}
                    layoutOnInit={true}
                    dragEnabled={draggable}
                    dragSortHeuristics={{
                      sortInterval: 50,
                    }}
                    layoutDuration={150}
                    dragRelease={{
                      duration: 400,
                      easing: "ease-out",
                    }}
                    dragContainer={document.body}
                    dragPlaceholder={{
                      enabled: true,
                      createElement: function (item) {
                        return item.getElement().cloneNode(true);
                      },
                    }}
                    propsToData={({
                      dateUploaded,
                      dateModified,
                      listOrder,
                      visibility,
                      tags,
                      status,
                    }) => ({
                      dateUploaded,
                      dateModified,
                      listOrder,
                      visibility,
                      tags,
                      status,
                    })}
                    sort={arrangeBy}
                    sortOptions={{ descending: order === "desc" }}
                    filter={filterFunction}
                  >
                    {galleryChildren}
                  </MuuriComponent>
                )}
              </div>
            </>
          ) : (
            <div className="galleryEmpty">
              <p>You haven't uploaded anything yet.</p>
              <Button
                color="blue"
                icon="upload cloud"
                content={<span>Upload Photos</span>}
                labelPosition="right"
                className="uploadPhotoButton"
                onClick={handleUploadModal}
              />
            </div>
          )}
        </>
      ) : (
        <PagePreloader />
      )}
      {uploadModal && (
        <GalleryUploader
          modalOpen={uploadModal}
          handleModal={handleUploadModal}
          fetchGallery={fetchGallery}
        />
      )}
    </StyledContainer>
  );
};

export default React.memo(GalleryPage);
