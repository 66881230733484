import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

const featuredBoxHeight = `calc(calc(var(--vh, 1vh) * 60) - ${stylevars.navBar.height})`
const featuredBoxAvatarSize = `42px`
const featuredBoxAvatarSizeActive = `220px`

export const FeaturedContainer = styled('div')`
  position: relative;
  margin-bottom: 6rem;
  .featuredBox {
    width: 100%;
    height: ${featuredBoxHeight};
    /* height: calc(calc(min(calc(var(--vh, 1vh) * 60), 480px))); */
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;

    .swiper-container {
      /* height: calc(min(calc(var(--vh, 1vh) * 60), 320px)); */
      /* height: calc(min(calc(var(--vh, 1vh) * 60), 480px)); */
      height: ${featuredBoxHeight};
      overflow: visible;
    }

    .swiper-slide {
      /* .swiper-slide-active */
      &-active {
        .featuredBox_name {
          bottom: 0;
          transition: bottom 0.15s ease-out 0.25s;
        }
        /* .featuredBox_avatar {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
          transition: transform 0.15s ease-out 0.25s, opacity 0.15s ease-out 0.25s;
        } */
        .featuredBox_cover {
          img {
            transform: scale(1) !important;
            transition: opacity .3s, filter .3s, transform 10s ease-out 0.25s !important;
          }
        }
      }
    }

    .swiper-pagination {
      background-color: transparent;
      height: 2rem;
      bottom: 0rem;
      z-index: 100;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-pagination-bullet {
      background-color: rgba(255, 255, 255, 0.35);
      opacity: 1;
      &-active {
        background-color: ${stylevars.palette.primary.main};
      }
    }

    [class^=swiper-button-] {
      top: 0;
      width: 4rem;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      transform: none;
      margin: 0;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        color: rgba(255, 255, 255, 0.25);
        text-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.35);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        &:after {
          color: ${stylevars.palette.primary.main};
        }
      }
    }
    .swiper-button {
      &-prev {
        left: 0;
      }
      &-next {
        right: 0;
      }
    }
  }
`

export const SliderContainer = styled('div')`
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  /* height: calc(min(calc(var(--vh, 1vh) * 60), 320px)); */
  /* height: calc(min(calc(var(--vh, 1vh) * 60), 480px)); */
  height: ${featuredBoxHeight};

  .featuredBox_content {
    padding: 2rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 2;
    width: 100%;
    /* max-width: 720px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ${props => props.bgColor1}; */
    /* background-color: transparent; */
    /* border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    transition: all 0.15s ease-out;
    padding-bottom: calc(${featuredBoxAvatarSizeActive} / 2 + 2rem);
    background-image: linear-gradient(to top, rgba(0,0,0,0.75), rgba(0,0,0,0));

    .featuredBox_avatar {
      min-width: ${featuredBoxAvatarSizeActive};
      min-height: ${featuredBoxAvatarSizeActive};
    }

    .content {
      padding: 1rem 2rem;
      width: calc(100% - 2rem);
      max-width: calc(100% - 2rem);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;

      .name {
        /* color: ${props => props.txtColor}; */
        color: #ffffff;
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
        font-size: 4rem;
        font-weight: 100;
        line-height: 1.6;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0.5rem;
        text-align: center;
        
        @media ${stylevars.breakpoints.phone} {
          font-size: 3rem;
        }
      }
      .description {
        /* color: ${props => props.txtColor}; */
        color: rgba(255,255,255,1);
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
        font-size: 1.3rem;
        line-height: 1.6;
        font-weight: 200;
        max-width: 600px;
        text-align: center;
        margin-bottom: 2rem;

        @media ${stylevars.breakpoints.phone} {
          font-size: 1rem;
        }
      }

      .actions {
        text-align: center;
        .button {
          margin: 0;
          font-size: 1.3rem;
        }
      }
    }

    /* &:hover {
      background-color: ${props => props.bgColor2};
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    } */
  }

  .featuredBox_cover {
    width: 100%;
    /* height: calc(min(calc(var(--vh, 1vh) * 60), 480px)); */
    height: ${featuredBoxHeight};
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.4) !important;
      transition: opacity .3s, filter .3s, transform .3s ease-out !important;
    }
  }
`

export const PagingContainer = styled('div')`
  position: absolute;
  z-index: 2;
  bottom: calc(-${featuredBoxAvatarSizeActive} / 2);
  /* left: 50%;
  transform: translateX(-50%); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${featuredBoxAvatarSizeActive};
  transition: all 0.3s ease-out;
  pointer-events: none;

  .item {
    margin-left: -1rem;
    margin-right: -1rem;
    animation: featuredCreatorOut 0.3s ease-out 1 both;
    .avatar {
      width: 100%;
      animation: featuredCreatorAvatarOut 0.3s ease-out 1 both;
      background-image: none !important;
      background-color: #fff;
      box-shadow: none;
      overflow: hidden;
      img {
        filter: grayscale(100%);
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        transition: all 0.3s ease-out;
      }
    }

    &.active {
      animation: featuredCreatorIn 0.3s ease-out 1 both;
      .avatar {
        animation: featuredCreatorAvatarIn 0.3s ease-out 1 both;
        img {
          filter: grayscale(0%);
          width: calc(100% - 16px);
          height: calc(100% - 16px);
        }
      }
    }
  }

  @keyframes featuredCreatorIn {
    0% {
      width: ${featuredBoxAvatarSize};
      height: ${featuredBoxAvatarSize};
      z-index: 1;
      margin-left: -1rem;
      margin-right: -1rem;
    }
    99% {
      width: ${featuredBoxAvatarSizeActive};
      height: ${featuredBoxAvatarSizeActive};
      z-index: 1;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    100% {
      width: ${featuredBoxAvatarSizeActive};
      height: ${featuredBoxAvatarSizeActive};
      z-index: 1;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
  @keyframes featuredCreatorAvatarIn {
    0% {
      width: ${featuredBoxAvatarSize};
      height: ${featuredBoxAvatarSize};
      z-index: 1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    99% {
      width: ${featuredBoxAvatarSizeActive};
      height: ${featuredBoxAvatarSizeActive};
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    100% {
      width: ${featuredBoxAvatarSizeActive};
      height: ${featuredBoxAvatarSizeActive};
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  @keyframes featuredCreatorOut {
    0% {
      width: ${featuredBoxAvatarSizeActive};
      height: ${featuredBoxAvatarSizeActive};
      z-index: 1;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    99% {
      width: ${featuredBoxAvatarSize};
      height: ${featuredBoxAvatarSize};
      z-index: 1;
      margin-left: -1rem;
      margin-right: -1rem;
    }
    100% {
      width: ${featuredBoxAvatarSize};
      height: ${featuredBoxAvatarSize};
      z-index: 1;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
  @keyframes featuredCreatorAvatarOut {
    0% {
      width: ${featuredBoxAvatarSizeActive};
      height: ${featuredBoxAvatarSizeActive};
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    99% {
      width: ${featuredBoxAvatarSize};
      height: ${featuredBoxAvatarSize};
      z-index: 1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    100% {
      width: ${featuredBoxAvatarSize};
      height: ${featuredBoxAvatarSize};
      z-index: 1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
`