import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { StyledCard } from './Styles'

const ThankYou = (props) => {
  const { redirect } = props
  const [ loginLink, setLoginLink ] = React.useState('/login/collector')

  React.useEffect(() => {
    if (redirect) {
      setLoginLink(state => (`${state}/redirect/${redirect}`))
    }
  }, [redirect])

  return (
    <>
      <h1>Verification Successful</h1>
      <StyledCard>
        <StyledCard.Content>
          <p>Thank you for verifying your account.</p>
        </StyledCard.Content>
        <StyledCard.Content extra className='subCard'>
          <Button as={Link} positive icon="arrow right" labelPosition="right" content="Login to your account now" to={`/login`} />
        </StyledCard.Content>
      </StyledCard>
    </>
  )
}

export default React.memo(ThankYou)
