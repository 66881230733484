import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const DocumentContainer = styled('div')`
  * {
    line-height: 1.6;
  }

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
  }
  h2 {
    font-size: 2rem;
    font-weight: 400;
    
    &.ordered {
      counter-increment: h2ordered;
      &:before {
        display: inline-block;
        padding-right: 1rem;
        content: counter(h2ordered) ".";
      }
    }
  }

  .list {
    &.list--roman {
      /* counter-reset: item; */
      li {
        list-style-type: lower-roman;
        margin: 1rem 0;
        padding-left: 1rem;
      }
    }

    li {
      &::marker {
        font-weight: bold;
      }
    }
  }

  .faqs {
    counter-reset: faqslist;
    .item {
      counter-increment: faqslist;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid ${stylevars.paper.borderColor};
      }
      .question {
        font-weight: bold;
        &:before {
          display: inline-block;
          content: counter(faqslist) ".";
          font-weight: bold;
          width: 3rem;
        }
      }
      .answer {
        padding-left: 3rem;
      }
    }
  }
`