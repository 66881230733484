import React from 'react'
import axios from 'axios'
import htmlEditButton from 'quill-html-edit-button'
import ReactQuill, {Quill} from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line

// COMPONENTS
import { Button, Dimmer, Divider, Grid, Header, Loader, Segment, TextArea } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const ProfileDisplay = () => {
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { userInfo, userKey } = useSelector(getUserState)
  // const { pageScrolled } = useSelector(getUIState)
  const [isReady, setIsReady] = React.useState(false)

  Quill.register('modules/htmlEditButton', htmlEditButton)

  React.useEffect(() => {
    (async () => {
      setBio(userInfo.acf.biography)
    })()
  }, [dispatch, userInfo]) // eslint-disable-line

  const [isFetching, setIsFetching] = React.useState(false)
  const handleFetching = (bool) => {
    setIsFetching(bool)
  }

  const [bio, setBio] = React.useState(userInfo.acf.biography)
  const [bioUpdating, setBioUpdating] = React.useState(false)
  const handleBioChange = async (ev) => {
    setBio(ev.target.value)
  }
  const handleBioChange2 = async (content) => {
    setBio(content)
  }
  const handleUpdateBio = async () => {
    setBioUpdating(true)

    await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: `/stylefolio/v1/users/${userInfo.id}`,
      headers: {
        Authorization: `${token}`,
      },
      method: 'POST',
      data: {
        acf_biography: bio,
        user_key: userKey,
      }
    }).then(res => {
      consoleLogger("BIO UPDATE RESPONSE =====================", res.data)
        
      toast.success(`You've updated your biography`, {
        containerId: 'mainToaster',
        progress: undefined,
      });
      dispatch(userSlice.actions.updateUserInfo(res.data))
    }).catch(err => {
      consoleLogger("ERROR", err)
    })

    setBioUpdating(false)
  }

  return (
    <>
      <div className='profile_theme_selection'>
        <Header as='h3'>
          Tell us more about yourself
        </Header>
        <div className='bio_col'>
          {bioUpdating &&
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          }
          <TextArea className='bio_field' value={bio}
            rows={5}
            onChange={(ev) => handleBioChange(ev)}
          />
          {/* <ReactQuill value={bio}
            onChange={(content) => handleBioChange2(content)}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                // ['blockquote', 'code-block'],
                // ['video', 'image'],
                // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                // [{ 'direction': 'rtl' }],                         // text direction

                // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                // [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']
              ],
              // htmlEditButton: {},
            }}
          /> */}
          <div className='bio_buttons'>
            <Button
              positive
              content="Update Biography"
              labelPosition='right'
              icon='save'
              onClick={() => handleUpdateBio()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(ProfileDisplay)
