import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const ViewerContainer = styled("div")`
  position: fixed;
  left: 0;
  bottom: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1101;
  pointer-events: none;
  animation: showViewerAnimation 0.15s ease-out 1;

  &.show {
    .itemViewer-wrapper {
      bottom: 0;
    }
    .itemViewer-overlay {
      opacity: 0.75;
      pointer-events: all;
    }
  }

  .itemViewer {
    /* itemViewer-wrapper */
    &-wrapper {
      position: absolute;
      bottom: calc(var(--vh, 1vh) * -100);
      left: 2rem;
      right: 2rem;
      z-index: 2;
      width: calc(100% - 4rem);
      height: calc(calc(var(--vh, 1vh) * 100) - 2rem);
      background-color: #fff;
      pointer-events: all;
      transition: all 0.15s ease-out;
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media ${stylevars.breakpoints.tablet} {
        left: 0;
        right: 0;
        width: 100%;
        height: calc(calc(var(--vh, 1vh) * 100));
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      @media ${stylevars.breakpoints.phone} {
        left: 0;
        right: 0;
        width: 100%;
        height: calc(calc(var(--vh, 1vh) * 100));
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    /* itemViewer-header */
    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      min-height: ${stylevars.navBar.height};
      position: relative;
      @media ${stylevars.breakpoints.tablet} {
        border-top: 1px solid ${stylevars.paper.borderColor};
        border-bottom: 1px solid ${stylevars.paper.borderColor};
      }
      .button {
        background-color: transparent;
        font-size: 2rem;
        padding: 0;
        width: ${stylevars.navBar.height};
        height: ${stylevars.navBar.height};
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        .icon {
          width: ${stylevars.navBar.height};
          height: ${stylevars.navBar.height};
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      h1 {
        font-size: 1.5rem;
        flex-grow: 1;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-right: 2rem;
        .placeholder {
          height: 1.5rem;
        }
        @media ${stylevars.breakpoints.tablet} {
          position: relative;
          width: calc(100% - 16rem);
          padding-left: 1rem;
          padding-right: 0rem;
          span {
            display: block;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .gallerySlideControl {
        padding: 0 1rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        opacity: 1;
        transition: all 0.15s ease-out 0.3s;
        .gallerySlideCounter {
          margin-left: 1rem;
          margin-right: 1rem;
          font-size: 1rem;
          white-space: nowrap;
        }
        .gallerySlideCounterFade {
          color: rgba(0, 0, 0, 0.25);
          font-size: 0.8rem;
        }
        .ui.buttons {
          margin-right: 1rem;
        }
        .ui.button {
          background-color: rgba(0, 0, 0, 0.25);
          color: #fff;
          margin: 0;
          &:hover {
            background-color: rgba(0, 0, 0, 0.85);
          }

          &.prev {
          }
          &.next {
          }

          &.hideViewer {
            background-color: transparent;
            color: ${stylevars.palette.danger.main};
          }
        }
      }

      .viewerProgress {
        position: absolute;
        bottom: 0rem;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 2000;
        opacity: 1;
        transition: opacity 0.3s ease-out;
        .bar {
          border-radius: 0;
          height: 0.3rem;
        }

        &.done {
          opacity: 0;
          transition: opacity 0.3s ease-out 0.5s;
        }
      }
    }

    /* itemViewer-body */
    &-body {
      padding: 0rem;
      flex-grow: 1;
      height: calc(100%);
      max-height: calc(100%);
      overflow: hidden;
      position: relative;

      @media ${stylevars.breakpoints.tablet} {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        max-height: calc(
          calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height}
        );
      }

      @media ${stylevars.breakpoints.phone} {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        max-height: calc(
          calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height}
        );
      }

      /* itemViewer-body-row */
      &-row {
        width: 100%;
        height: calc(100%);
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        @media ${stylevars.breakpoints.phone} {
          height: calc(100%);
          display: block;
        }
      }

      .loader {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }

      .column.image {
        background-color: rgba(0, 0, 0, 0.025);
        padding: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: calc(100% - 0rem);
        /* height: calc(100% - ${stylevars.navBar.height} - 4rem); */
        position: relative;

        @media ${stylevars.breakpoints.tablet} {
          height: calc(100% - 0rem);
          /* width: 100% !important; */
        }
        @media ${stylevars.breakpoints.phone} {
          height: calc(100% - ${stylevars.navBar.height} - 1.5rem);
          width: 100% !important;
        }

        .viewerImagePreview_description {
          position: absolute;
          top: 0rem;
          left: 0rem;
          width: calc(100%);
          transition: all 0.15s ease-out;
          z-index: 2;
          pointer-events: none;

          &:hover {
            z-index: 4;
            .viewerImagePreview_descriptionWrapper {
              transform: translateY(0);
              opacity: 1;
            }
          }

          @media ${stylevars.breakpoints.phone} {
            top: 0rem;
            left: 0rem;
          }

          /* .viewerImagePreview_descriptionIcon */
          &Icon {
            color: rgba(255, 255, 255, 1);
            transition: all 0.15s ease-out;
            text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
            left: 1rem;
            top: 0.95rem;
            position: absolute;
            z-index: 2;
            display: inline-block;
            cursor: pointer;
            pointer-events: all;
          }

          /* .viewerImagePreview_descriptionWrapper */
          &Wrapper {
            pointer-events: none;
            width: calc(100% - 0rem);
            transition: all 0.15s ease-out;
            opacity: 0;
            padding: 1rem;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            padding-left: 4rem;
            transform: translateY(-100%);
            @media ${stylevars.breakpoints.phone} {
              width: calc(100% - 0rem);
            }
          }
        }

        .viewerImagePreview_tags {
          position: absolute;
          top: 0rem;
          left: 0rem;
          width: calc(100%);
          transition: all 0.15s ease-out;
          z-index: 2;
          pointer-events: none;

          &:hover {
            z-index: 4;
            .viewerImagePreview_tagsWrapper {
              transform: translateY(0);
              opacity: 1;
            }
          }

          @media ${stylevars.breakpoints.phone} {
            top: 0rem;
            left: 0rem;
          }

          /* .viewerImagePreview_tagsIcon */
          &Icon {
            color: rgba(255, 255, 255, 1);
            transition: all 0.15s ease-out;
            text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
            right: 1rem;
            top: 0.95rem;
            position: absolute;
            z-index: 2;
            display: inline-block;
            cursor: pointer;
            pointer-events: all;
          }

          /* .viewerImagePreview_tagsWrapper */
          &Wrapper {
            pointer-events: none;
            width: calc(100% - 0rem);
            transition: all 0.15s ease-out;
            opacity: 0;
            padding: 1rem;
            color: #fff;
            padding-right: 4rem;
            transform: translateY(-100%);
            text-align: right;
            @media ${stylevars.breakpoints.phone} {
              width: calc(100% - 0rem);
            }

            .tag {
              font-size: 0.85rem;
              line-height: 1;
              padding: 0.5rem 1rem;
              background-color: rgba(0, 0, 0, 0.5);
              display: inline-block;
              margin: 0 0.5rem 0.5rem 0;
              border-radius: 0.5rem;
            }
          }
        }

        .viewerLimitedEdition {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;
          padding-right: 1rem;
          margin-bottom: 1rem;
          border-radius: 0.5rem;

          background-color: ${stylevars.typography.title.color};

          .label {
            padding: 0.1rem 0.5rem;
            border: 1px solid ${stylevars.palette.primary.main};
            color: ${stylevars.palette.primary.main};
            font-size: 8px;
            line-height: 1.6;
            border-radius: 0.25rem;
            text-transform: uppercase;
            margin-right: 1rem;
          }
          .context {
            color: ${stylevars.paper.bgColor};
            font-size: 8px;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }

        .viewerImagePreview {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          perspective: calc(var(--vw, 1vw) * 70);
          transition: all 0.15s ease-out;
          /* border: 0.1rem solid rgba(0, 0, 0, 0); */
          box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 0);

          @media ${stylevars.breakpoints.tablet} {
            perspective: calc(var(--vw, 1vw) * 105);
          }
          @media ${stylevars.breakpoints.phone} {
            perspective: calc(var(--vw, 1vw) * 140);
          }

          .ui.dimmer {
            display: flex !important;
            opacity: 0;
            transition: all 0.15s ease-out !important;
            background-color: rgba(0, 0, 0, 0.25) !important;
            pointer-events: none;
            box-shadow: inset 0px 0px calc(var(--vw, 1vw) * 80) 0px
              rgba(0, 0, 0, 1);
            &.active {
              opacity: 1;
            }
          }

          .wrapper {
            min-width: 160px;
            min-height: 160px;
            position: relative;
            transform-style: preserve-3d;
            transition: all 0.15s ease-out;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            /* transform: scale(0.85); */
            /* @media ${stylevars.breakpoints.tablet} {
              transform: scale(0.85);
            }
            @media ${stylevars.breakpoints.phone} {
              transform: scale(0.85);
            } */
          }

          .sprite {
            transition: all 0.15s ease-out;
            display: block;
            position: absolute;
            background-color: #333333;
            background-size: calc(100%) calc(100%);
            opacity: 0;
            &.face {
              z-index: 3;
              width: auto;
              height: auto;
              opacity: 1;
              background-color: transparent;
              padding: 0;
              .img {
                width: 100%;
                height: 100%;
                display: block;
                line-height: 0%;
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                position: relative;
                z-index: -1;
                transition: all 0.15s ease-out;
              }
            }
            &.facewrap {
              width: 100%;
              height: 100%;
              display: block;
              position: absolute;
              background-color: transparent;
              /* box-shadow: inset 0px 3px 12px 1px rgba(0, 0, 0, 0.35); */
            }
            &.side {
              z-index: 2;
              .img {
                width: 100%;
                height: 100%;
                display: block;
                line-height: 0%;
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                position: relative;
                transition: all 0.15s ease-out;
                z-index: 2;
              }
              .solid {
                width: 100%;
                height: 100%;
                display: block;
                line-height: 0%;
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                transition: all 0.15s ease-out;
                z-index: 1;
              }
            }
            &.border {
              background-color: transparent;
              opacity: 1;
              z-index: 2;
              .borderColor {
                transition: all 0.15s ease-out;
                opacity: 1;
                width: 100%;
                height: 100%;
                display: block;
                background-color: #fff;
                /* box-shadow: inset 0px 3px 12px 1px rgba(0, 0, 0, 0.35); */
              }
            }
            &.frame {
              z-index: 1;
            }
            &.shadow {
              z-index: 1;
              position: absolute;
            }
          }

          &.regular-print {
          }

          &.canvas {
            .wrapper {
              transform: rotateY(35deg) scale(0.75);
              @media ${stylevars.breakpoints.tablet} {
                transform: rotateY(35deg) scale(0.75);
              }
              @media ${stylevars.breakpoints.phone} {
                transform: rotateY(35deg) scale(0.75);
              }
            }
            .sprite {
              &.face {
                background-color: #ffffff;
                background-position: 50% 50%;
              }
              &.side {
                opacity: 1;
              }
              &.shadow {
                z-index: 1;
                background: #0f0f0f;
                opacity: 0.8;
                /* filter: blur(calc(var(--vw, 1vw) * 4)); */
                /* box-shadow: -40px 0px 120px rgba(0, 0, 0, 0.5); */
                @media ${stylevars.breakpoints.phone} {
                  /* filter: blur(calc(var(--vw, 1vw) * 10)); */
                }
              }
            }
          }

          &.framed {
            .wrapper {
              transform: scale(0.75);
              @media ${stylevars.breakpoints.tablet} {
                transform: scale(0.75);
              }
              @media ${stylevars.breakpoints.phone} {
                transform: scale(0.75);
              }
            }
            .sprite {
              &.facewrap {
                opacity: 1;
              }
              &.face {
                /* transform: scale(0.7); */
              }
              &.border {
                opacity: 1;
              }
              &.shadow {
                opacity: 0.5;
              }
              &.frame {
                opacity: 1;
                width: inherit;
                height: inherit;
                z-index: 1;
              }
            }
          }

          &.framed-canvas {
            .wrapper {
              transform: scale(0.75);
              @media ${stylevars.breakpoints.tablet} {
                transform: scale(0.75);
              }
              @media ${stylevars.breakpoints.phone} {
                transform: scale(0.75);
              }
            }
            .sprite {
              &.facewrap {
                opacity: 1;
              }
              &.face {
                /* transform: scale(0.9); */
              }
              &.border {
                opacity: 0;
              }
              &.shadow {
                opacity: 0.5;
              }
              &.frame {
                opacity: 1;
                width: inherit;
                height: inherit;
                z-index: 1;
              }
            }
          }

          &.metal {
            .wrapper {
              transform: rotateY(35deg) scale(0.85);
              @media ${stylevars.breakpoints.tablet} {
                transform: rotateY(35deg) scale(0.85);
              }
              @media ${stylevars.breakpoints.phone} {
                transform: rotateY(35deg) scale(0.85);
              }
            }
            .sprite {
              &.face {
                /* background-color: #ffffff;
                background-position: 50% 50%; */
              }
              &.side {
                opacity: 1;
                background-image: none !important;
                background-color: #9e9e9e !important;
              }
              &.shadow {
                z-index: 1;
                background: #0f0f0f;
                opacity: 0.4;
              }
            }
          }
        }

        @keyframes showViewerImage {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes hideViewerImage {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .placeholder {
          width: 100%;
          max-width: 100%;
          min-height: calc(
            calc(var(--vh, 1vh) * 60) - ${stylevars.navBar.height}
          );
          .image {
            width: 100%;
            height: 100%;
          }
        }

        .iiz {
          width: auto;
          position: relative;
          z-index: 10;
        }
      }

      .column.details {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        /* height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar
          .height} - ${stylevars.navBar.height} - 2rem); */
        width: 30%;
        height: 100%;
        min-height: calc(
          calc(var(--vh, 1vh) * 100) - calc(${stylevars.navBar.height} * 2) -
            8rem
        );
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 1rem;

        @media ${stylevars.breakpoints.phone} {
          width: 100% !important;
          max-height: none;
          overflow-y: unset;
          overflow-x: unset;
          background-color: #fff;
        }

        /* .viewerHeader {
          position: sticky;
          top: 0;
          background-color: #fff;
          z-index: 10;
        } */
      }

      .selectType {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* display: flex;
        flex-wrap: wrap;
        flex-direction: row; */
        /* flex-grow: 1; */

        /* overflow-y: auto;
        overflow-x: hidden; */
        margin-top: 0;

        /* &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 10px;
          height: 10px;
        }
        &::-webkit-scrollbar-thumb {
          cursor: pointer;
          border-radius: 5px;
          background: ${stylevars.typography.subtitle.color};
          transition: color 0.2s ease;
        }
        &::-webkit-scrollbar-track {
          background: ${stylevars.paper.borderColor};
          border-radius: 0px;
        }

        &:hover {
          &::-webkit-scrollbar-thumb {
            background: ${stylevars.palette.primary.main};
          }
        } */

        .item {
          max-width: 100%;
          margin: 0;
          padding: 1rem;
          border-bottom: 1px solid ${stylevars.paper.borderColor};
          @media ${stylevars.breakpoints.tablet} {
            max-width: 100%;
            flex-direction: row !important;
          }
          @media ${stylevars.breakpoints.phone} {
            max-width: 100%;
            flex-direction: row !important;
          }
          &:hover {
            background-color: rgba(${stylevars.paper.borderColorRGB}, 0.2);
          }
          &.active {
            background-color: rgba(${stylevars.paper.borderColorRGB}, 0.4);
            .header {
              color: ${stylevars.palette.primary.main} !important;
            }
          }
          .image {
            width: 5rem !important;
            img {
              object-fit: contain;
            }
          }
          .content {
            @media ${stylevars.breakpoints.phone} {
              padding-top: 0 !important;
              padding-left: 1rem !important;
            }
            .header {
              font-size: 1rem;
            }
            .description {
              font-size: 1rem;
            }
          }
        }
      }

      .viewerOptionWrapper {
        flex-grow: 1;
        padding-bottom: calc(76px + 2rem);
        /* padding-bottom: calc(72px + 2rem); */
        /* min-height: calc(calc(var(--vh, 1vh) * 100) - calc(${stylevars.navBar
          .height} * 2) - 8rem);
        height: auto; */
      }

      .viewerOptionGroup {
        margin-bottom: 2rem;
        > .ui.items {
          margin: 0;
          padding: 0.5rem;
        }
      }

      .viewerHeader {
        display: flex;
        align-items: center;
        margin: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid ${stylevars.paper.borderColor};

        &:nth-child(1) {
          z-index: 10;
        }
        &:nth-child(2) {
          z-index: 9;
        }
        &:nth-child(3) {
          z-index: 8;
        }
        &:nth-child(4) {
          z-index: 7;
        }
        &:nth-child(5) {
          z-index: 6;
        }

        .sub.header {
          color: ${stylevars.typography.subtitle.color} !important;
          margin-left: 1rem;
        }

        @media ${stylevars.breakpoints.tablet} {
          position: sticky;
          top: -1px;
          bottom: 177px;
          background-color: ${stylevars.paper.bgColor};
        }
      }

      .viewerSubheader {
        font-size: 0.8rem;
        color: ${stylevars.typography.extra.color} !important;
        text-transform: uppercase;
        padding: 0.25rem 0.5rem;
        background-color: rgba(${stylevars.typography.title.colorRGB}, 1);
        border: 1px solid rgba(${stylevars.typography.title.colorRGB}, 1);
        border-radius: 0.5rem;
        width: auto;
        display: inline-block;
      }

      .selectSize {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .item {
          width: calc(33.334% - 0.5rem);
          margin: 0;
          margin: 0.25rem;
          padding: 1rem;
          border: 1px solid rgba(${stylevars.paper.borderColorRGB}, 0.5);
          text-align: center;
          &:hover {
            background-color: rgba(${stylevars.paper.borderColorRGB}, 0.2);
          }
          &.active {
            border: 1px solid rgba(${stylevars.paper.borderColorRGB}, 1);
            background-color: rgba(${stylevars.paper.borderColorRGB}, 0.4);
            .header {
              color: ${stylevars.palette.primary.main} !important;
            }
          }

          /* @media ${stylevars.breakpoints.tablet} {
            width: calc(50% - 0.5rem);
          } */
          @media ${stylevars.breakpoints.phone} {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }

          > .content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            > .header {
              font-size: 1rem;
            }
          }
        }
      }

      .viewerBodyDimmer {
        background-color: transparent;
        z-index: 1001 !important;
      }
      .detailsDimmer {
      }
    }

    &-overlay {
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.75);
      width: calc(var(--vw, 1vw) * 100);
      height: calc(var(--vh, 1vh) * 100);
      pointer-events: none;
      opacity: 0;
      transition: all 0.15s ease-out;
    }
  }

  .cartActions {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
    position: sticky;
    bottom: 0px;
    /* width: calc(40% - 1.5rem - var(--scrollbarWidth) - 1px); */
    padding: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    background-color: ${stylevars.paper.bgColor};
    z-index: 21;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -2rem;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 2rem;
      /* background-color: ${stylevars.paper.borderColor}; */
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.15),
        rgba(0, 0, 0, 0)
      );
      pointer-events: none;
    }

    @media ${stylevars.breakpoints.tablet} {
      bottom: 0px;
    }

    @media ${stylevars.breakpoints.phone} {
      width: calc(calc(var(--vw, 1vw) * 100) - var(--scrollbarWidth));
    }

    /* .cartActions-likes */
    &-likes {
      padding: 0;
      color: ${stylevars.typography.extra.color};
      background-color: ${stylevars.typography.title.color};
      border: 2px solid ${stylevars.typography.title.color};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      .ui.button {
        margin: 0;
        padding: 0;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: transparent;

        &:before,
        &:after {
          margin-top: -1.15rem !important;
          z-index: 2;
        }

        .icon {
          font-size: 2rem;
          line-height: 1;
          color: ${stylevars.palette.danger.main};
        }
        .label {
          margin: 0.15rem 0 0 0 !important;
          padding: 0 !important;
          line-height: 0;
          font-size: 0.7rem;
          min-height: 1rem;
          pointer-events: none;
          background: transparent;
          color: ${stylevars.paper.bgColor};
          /* font-weight: normal; */
          text-align: center;

          width: auto;
          white-space: nowrap;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    /* .cartActions-price */
    &-price {
      padding: 1rem 0;
      color: ${stylevars.typography.extra.color};
      background-color: ${stylevars.typography.title.color};
      border: 2px solid ${stylevars.typography.title.color};
      border-right: none;
      min-width: 12rem;
      width: 100%;
      margin: 0rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      /* @media ${stylevars.breakpoints.laptop} {
        min-width: 10rem;
      }
      @media ${stylevars.breakpoints.tablet} {
        min-width: 10rem;
      }
      @media ${stylevars.breakpoints.phone} {
      } */
    }

    /* .cartActions-groupBuy */
    &-groupBuy {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-direction: row;
      border: 2px solid ${stylevars.typography.title.color};
      border-left: none;
      background-color: ${stylevars.typography.title.color};
    }

    /* .cartActions-quantity */
    &-quantity {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0rem;
      border: 1px solid #ffffff;
      background-color: #ffffff;
      .button {
        width: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${stylevars.paper.borderColor};
        cursor: pointer;
        border: none;
        outline: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        &:focus {
          border: none;
          outline: none;
        }
        .icon {
          margin: 0 !important;
          font-size: 0.65rem;
          line-height: 1;
        }

        &:hover {
          background-color: ${stylevars.typography.title.color};
          color: #fff;
        }
      }
      > .input {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50px;
        font-size: 1.5rem;
        font-weight: bold;
        border: none;
        outline: none;
        margin: 0;
      }
    }

    /* .cartActions-buyButton */
    &-buyButton {
      width: auto;
      margin: 0;
      border-radius: 0;
      .icon {
        margin: 0 !important;
      }
    }
  }

  @keyframes showViewerAnimation {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;
