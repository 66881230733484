import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import he from 'he'
import cn from 'classnames'
import { toast } from 'react-toastify';
import { consoleLogger, stripHTML } from '@Utils'
import { orderBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { MuuriComponent } from 'muuri-react'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import gallerySlice, { getGalleryState } from '@Slices/gallerySlice' // eslint-disable-line
import uiSlice from '@Slices/uiSlice'

// FORMIK
import { Formik, Form, Field, FieldArray, useFormikContext} from 'formik';
import * as Yup from 'yup'

// COMPONENTS
import { Grid, Form as SUIForm, TextArea, Label, Button, Header, Modal, Dimmer, Loader } from 'semantic-ui-react'
import { GeneralPreloader } from '@Common'
import { GalleryItem } from '../../Gallery/Components'

// STYLES
import { CollectionsSelectionContainer } from './CollectionsSelectionStyles'

const CollectionsSelection = (props) => {
  const {
    collectionData,
    modalOpen,
    handleModal,
    handleDataValue,
  } = props
  const token = localStorage.Authorization
  const dispatch = useDispatch()
  const { userInfo, userKey, userGallery, userCollections } = useSelector(getUserState)

  const [ ready, setReady ] = React.useState(false)
  const [ processing, setProcessing ] = React.useState(false)
  const [ currentGallery, setCurrentGallery ] = React.useState()
  const [ saveData, setSaveData ] = React.useState([])
  const handleSaveData = (data) => {
    let temp = 0
    let arr = []
    saveData.forEach((n, index) => {
      if (n.id.toString() === data.id.toString()) {
        temp += 1
        arr = saveData.slice(0,index).concat(saveData.slice(index+1, saveData.length))
      }
    })
    if (temp === 0) {
      setSaveData(prev => [...prev, data])
    } else {
      setSaveData(arr)
    }
  }

  React.useEffect(() => {
    if (saveData) {
      consoleLogger('================= SAVED DATA ================', saveData)
    }
  }, [saveData]) // eslint-disable-line
  
  const [ availableGallery, setAvailableGallery ] = React.useState()
  React.useEffect(() => {
    (async()=>{
      let tempCollectionGallery = []
      let tempGallery = []
      if (collectionData && collectionData.id) {
        // filter gallery from current content
        // tempCollectionGallery = userCollections.filter(n => n.id.toString() === collectionData.id.toString()).map(o => o.gallery_data)[0]
        tempCollectionGallery = collectionData.gallery_data
        tempGallery = []
        userGallery.forEach(n => {
          let x = 0
          tempCollectionGallery.forEach(o => {
            if (n.id.toString() === o.id.toString()) {
              x += 1
            }
          })
          if (x === 0) {
            tempGallery.push(n)
          }
        })
      } else {
        userGallery.forEach(n => {
          tempGallery.push(n)
        })
      }
      setCurrentGallery(tempCollectionGallery)
      setAvailableGallery(tempGallery)
      consoleLogger(`============== tempGallery =============== ${userGallery.length}`, tempCollectionGallery, tempGallery)
    })()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (currentGallery && availableGallery) {
      setReady(true)
    }
  }, [currentGallery, availableGallery]) // eslint-disable-line

  const handleCollectionsSelectionModal = (bool) => {
    handleModal(bool)
  }

  const handleUpdate = async () => {
    setProcessing(true)
    handleDataValue([...currentGallery, ...saveData])
    // await axios({
    //   baseURL: `${process.env.REACT_APP_API_URL}`,
    //   url: `/stylefolio/v1/collections/${cid}`,
    //   method: 'POST',
    //   headers: {
    //     Authorization:`${token}`,
    //   },
    //   data: {
    //     gallery_data: [...currentGallery, ...saveData]
    //   },
    // }).then(res => {
    //   consoleLogger("GALLERY RESPONSE ==========", res.data)
    //   // toast message
    //   toast.success(`Collection updated - ${res.data.title}`, {
    //     containerId: 'mainToaster',
    //     progress: undefined,
    //   });
    //   dispatch(uiSlice.actions.setCurrentPage(`collections`))
    //   dispatch(uiSlice.actions.setCurrentPageURL(`admin/collections`))
    //   dispatch(uiSlice.actions.setCurrentPageTitle(`${res.data.title}`))
    //   dispatch(userSlice.actions.updateUserCollection(res.data))
    // }).catch(err => {
    //   consoleLogger(err)
    // })
    setProcessing(false)
    handleModal(false)
  }

  return (
    <>
      <Modal
        open={modalOpen}
        dimmer={{
            blurring: true
        }}
        className='sfFullscreen collectionsSelectionModal'
        onClose={() => handleCollectionsSelectionModal(false)}
      >
        <Modal.Header>
          <span className='title'>{`Select content for your collection`}</span>
          <span className='header-extra'>
            <span className='label'>Selected: </span>
            <span className='value'>{saveData.length}</span>
          </span>
        </Modal.Header>
        <Modal.Content>
          <CollectionsSelectionContainer>
            {processing &&
              <Dimmer inverted active>
                <Loader>Updating collection content...</Loader>
              </Dimmer>
            }
            {ready ?
              <MuuriComponent
                dragEnabled={false}
                gridProps={{className: 'galleryGrid'}}
                layout={{
                  fillGaps: true,
                  horizontal: false,
                  alignRight: false,
                  alignBottom: false,
                  rounding: true
                }}
              >
                {availableGallery.map(gallery => (
                  <GalleryItem
                    key={`${gallery.id}-${gallery.slug}`}
                    id={gallery.id}
                    cid={collectionData ? collectionData.id : uuidv4()}
                    post={gallery}
                    fetchGallery={() => {}}
                    mode='selection'
                    draggable={false}
                    // handleProcess={handleProcessing}
                    handleSelect={handleSaveData}
                    saveData={saveData}
                  />
                ))}
              </MuuriComponent> : 
              <GeneralPreloader />
            }
          </CollectionsSelectionContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            icon='close'
            content='Cancel'
            labelPosition='right'
            disabled={processing}
            onClick={() => handleCollectionsSelectionModal(false)}
          />
          {saveData.length > 0 &&
            <Button
              positive
              icon='plus'
              content='Add to collection'
              labelPosition='right'
              loading={processing}
              disabled={processing}
              onClick={async () => {
                // handleCollectionsSelectionModal(false)
                handleUpdate()
              }}
            />
          }
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default CollectionsSelection
