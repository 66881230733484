import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";
import { darken } from "polished";
import { Menu } from "semantic-ui-react";

export const Container = styled(Menu.Item)`
  .storeButtons {
    padding: 1rem;
    margin: 0;
  }
`;

export const ModalContentContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  .nothing {
    padding: 1rem;
    font-style: italic;
  }
  .searchField {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border: 1px solid ${stylevars.paper.borderColor};
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 0 1rem;
    .searchFieldInput {
      padding: 1rem 1rem 1rem 0;
      font-size: 2rem;
      width: 100%;
      display: block;
      border: none;
      box-shadow: none;
      outline: none;
    }
    > .button {
      font-size: 2rem;
      background-color: transparent;
      padding: 0;
      margin: 0 !important;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover {
      border: 1px solid ${stylevars.typography.title.color};
    }
    &.active {
      border: 1px solid ${stylevars.typography.title.colorRGB};
      box-shadow: rgba(${stylevars.typography.title.colorRGB}, 0.4) 0px 0px 0px
          2px,
        rgba(${stylevars.typography.title.colorRGB}, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }
    &.focus {
      border: 1px solid ${stylevars.typography.title.color};
    }
  }

  .searchComboField {
    width: 100%;
    > input {
      @media ${stylevars.breakpoints.phone} {
        font-size: 1.25rem;
        padding-left: 5rem !important;
      }
    }
    > .icon.search {
      @media ${stylevars.breakpoints.phone} {
        font-size: 1.25rem;
      }
    }
    .searchTypeGroup {
      border-style: solid;
      border-width: 1px;
      border-color: rgba(34, 36, 38, 0.15);
      border-left-color: transparent;
      padding: 0.5rem;
      > .button {
        font-size: 1.25rem;
        border-radius: 0 !important;

        &.searchTypeButtonMain {
          @media ${stylevars.breakpoints.phone} {
            /* font-size: 1rem;
            height: 3rem; */
            display: none;
          }
        }

        &:not(.dropdown) {
          border-top-left-radius: 0.5rem !important;
          border-bottom-left-radius: 0.5rem !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &.dropdown {
          border-top-left-radius: 0rem !important;
          border-bottom-left-radius: 0rem !important;
          border-top-right-radius: 0.5rem !important;
          border-bottom-right-radius: 0.5rem !important;
          @media ${stylevars.breakpoints.phone} {
            border-top-left-radius: 0.5rem !important;
            border-bottom-left-radius: 0.5rem !important;
          }
        }
      }
      > .dropdown {
        font-size: 1.25rem;
        background-color: ${darken(0.05, stylevars.palette.primary.main)};
      }
    }
  }

  .recentSearch {
    width: 100%;
  }

  .searchResults {
    width: 100%;
    flex-grow: 1;
    height: calc(calc(var(--vh, 1vh) * 100) - 156px - 9rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
