import styled from "styled-components";
import { stylevars } from '@Common/StyleVars'

export const ToolbarContainer = styled('div')`
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 1rem;
  z-index: 30;
  padding: 0;
  background-color: ${stylevars.paper.bgColor};
  border-bottom: 1px solid ${stylevars.paper.borderColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${stylevars.breakpoints.tablet} {
    width: 100%;
    left: 0;
    justify-content: center;
  }

  @media ${stylevars.breakpoints.phone} {
    display: block;
    padding: 0;
  }

  &.noGap {
    margin-bottom: 0;
  }

  .toolbar-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: calc(${stylevars.navBar.height} - 1px);
    max-height: calc(${stylevars.navBar.height} - 1px);
    background-color: ${stylevars.paper.bgColor};

    @media ${stylevars.breakpoints.phone} {
      width: 100%;
      min-height: 4rem;
      display: block;
    }

    &.right {
      margin-bottom: 0;
      border-bottom: none;

      .action-group {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        padding: 0 1rem;

        > * {
          margin-right: 0 !important;
          &:not(:last-child) {
            margin-right: 1rem !important;
          }
        }
      }
      
      @media ${stylevars.breakpoints.phone} {
        position: relative;
        display: none;
        justify-content: center;
        height: ${stylevars.navBar.height};
        &.show {
          display: flex;
          background-color: rgba(0,0,0,0.075);
        }
      }
    }

    &.left {
      display: flex;
      align-items: center;
      > .title {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 0 1rem;
        position: relative;
        width: 100%;
        .goBack {
          margin-right: 1rem;
        }
        h1 {
          display: block;
          line-height: 1.2;
          margin: 0;
          padding: 0;
          margin-right: 1rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 1.4rem;
        }
      }
      .size {
        line-height: 1rem;
        font-size: 0.8rem;
        color: rgba(0,0,0,0.5);
        .label {
        }
        .value {
          margin-left: 0.5rem;
        }
      }
      .toggler {
        display: none;
        justify-content: center;
        align-items: center;
        width: ${stylevars.navBar.height};
        height: ${stylevars.navBar.height};
        > .ui.button {
          background-color: #ffffff !important;
        }
      }
      @media ${stylevars.breakpoints.phone} {

        .toggler {
          display: flex;
          &.show {
            background-color: rgba(0,0,0,0.075);
          }
        }
      }
    }
  }
`