import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import uiSlice, { getUIState } from '@Slices/uiSlice';

// COMPONENTS
import {
  Grid,
  // Button,
  Dimmer,
  Loader,
  Image,
} from 'semantic-ui-react';
import {
  FeaturedUsers,
  TrendingUsers,
  PopularItems,
  NewCollections,
  NewCreators,
} from '@StorePages/Discover/Components';
import { HomeSection } from './Components';

// STYLES
import { StyledContainer } from './HomeStyles';
// Import Swiper styles
// import 'swiper/swiper.scss';

const HomePage = () => {
  const dispatch = useDispatch();
  const { siteSettings } = useSelector(getUIState);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'));
    dispatch(uiSlice.actions.setCurrentPage('home'));
    dispatch(uiSlice.actions.setCurrentPageURL());
    dispatch(uiSlice.actions.setCurrentPageTitle());
    // const token = localStorage.Authorization
    setReady(true);
  }, []); // eslint-disable-line

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}/discover`}
        />
      </Helmet>
      {ready ? (
        <>
          {/* <div className='section header'>
            <div className='section_wrapper'>
              <h1>Discover StyleFolio Creators</h1>
            </div>
          </div> */}
          {/* <FeaturedUsers /> */}
          <HomeSection />
          <TrendingUsers limit={6} />
          <PopularItems limit={16} />
          <NewCollections limit={16} />
          <NewCreators limit={6} />
        </>
      ) : (
        <>Loading...</>
      )}
    </StyledContainer>
  );
};

export default React.memo(HomePage);
