import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { consoleLogger } from '@Utils'

const user = createSlice({
  name: 'user',
  initialState: {
    authenticated: false,
    userInfo: '',
    userKey: '',
    userCollections: [],
    userGallery: [],
    userGalleryPages: 0,
    userGalleryTotal: 0,
    userCoupons: [],
    userAddresses: [],
    accountCompletion: {
      hasAddress: false,
      hasProfilePhoto: false,
      hasCoverPhoto: false,
      hasSignature: false,
    },
    printRequest: {}
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload
    },
    setRememberUser: (state, action) => {
      state.rememberUser = action.payload === 'true' ? true : false
    },
    setUserKey: (state, action) => {
      state.userKey = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    updateUserInfo: (state, action) => {
      state.userInfo = ({...state.userInfo, ...action.payload})
    },
    setUserLayout: (state, action) => {
      state.userInfo.acf.layout_style = action.payload
    },
    setCollections: (state, action) => {
      state.userCollections = action.payload
    },
    addUserCollection: (state, action) => {
      state.userCollections.push(action.payload)
    },
    updateUserCollection: (state, action) => {
      consoleLogger('----------------------------', action.payload)
      state.userCollections = state.userCollections.map(item => item.id.toString() === action.payload.id.toString() ? ({...item, ...action.payload}) : ({...item}))
    },
    setUserGallery: (state, action) => {
      state.userGallery = action.payload
    },
    addUserGallery: (state, action) => {
      state.userGallery.push(action.payload)
    },
    updateUserGallery: (state, action) => {
      state.userGallery = state.userGallery.map(item => item.ID === action.payload.ID ? ({...item, ...action.payload}) : ({...item}))
    },
    rearrangeUserGallery: (state, action) => {
      action.payload.forEach((item, index) => {
        consoleLogger(item, index)
        // const galleryIndex = findIndex(state.userGallery, { ID: action.payload.ID })
        state.userGallery.filter(i => i.id === Number(item)).map(n => ({...n, acf: {...n.acf, list_order: index}}))
      })
      state.userGallery.sort((a, b) => a.acf.list_order > b.acf.list_order ? 1 : -1)
    },
    setUserGalleryPages: (state, action) => {
      state.userGalleryPages = action.payload
    },
    setUserGalleryTotal: (state, action) => {
      state.userGalleryTotal = action.payload
    },
    setUserCoupons: (state, action) => {
      state.userCoupons = action.payload
    },
    updateUserCoupons: (state, action) => {
      consoleLogger('REDUX COUPON UPDATE ==============================')
      const couponIndex = findIndex(state.userCoupons, { ID: action.payload.ID, acf: { code: action.payload.acf.code } })
      if (couponIndex > -1) {
        consoleLogger('REDUX COUPON UPDATE ==============================', couponIndex)
        state.userCoupons[couponIndex] = action.payload
      }
    },
    addUserCoupons: (state, action) => {
      state.userCoupons.push(action.payload)
    },
    removeUserCoupon: (state, action) => {
      const couponIndex = findIndex(state.userCoupons, { ID: action.payload.ID, acf: { code: action.payload.acf.code } })
      // consoleLogger('couponIndex', couponIndex)
      if (couponIndex > -1) {
        state.userCoupons = state.userCoupons.slice(0, couponIndex).concat(state.userCoupons.slice(couponIndex+1, state.userCoupons.length))
      }
    },

    setUserAddresses: (state, action) => {
      state.userAddresses = action.payload
    },
    updateUserAddress: (state, action) => {
      state.userAddresses = state.userAddresses.map(item => item.id === action.payload.id ? ({...item, ...action.payload}) : ({...item}))
    },
    addUserAddress: (state, action) => {
      state.userAddresses.push(action.payload)
    },
    removeUserAddress: (state, action) => {
      const addressIndex = findIndex(state.userAddresses, { id: action.payload.id })
      if (addressIndex > -1) {
        state.userAddresses = state.userAddresses.slice(0, addressIndex).concat(state.userAddresses.slice(addressIndex+1, state.userAddresses.length))
      }
    },
    setDefaultBillingAddress: (state, action) => {
      state.userInfo.acf.billing_address = action.payload
    },
    setDefaultMailingAddress: (state, action) => {
      state.userInfo.acf.mailing_address = action.payload
      state.userInfo.acf.same_billing_mailing_address = false
    },
    setSameBillingMailingAddress: (state, action) => {
      state.userInfo.acf.mailing_address = ''
      state.userInfo.acf.same_billing_mailing_address = true
    },

    updateAccountCompletion: (state, action) => {
      consoleLogger('--------------- ACTION ------------------', action)
      state.accountCompletion = ({...state.accountCompletion, ...action.payload})
    },

    setPrintRequest: (state, action) => {
      state.printRequest = action.payload
    },

    updatePrintRequest: (state, action) => {
      consoleLogger('--------------- ACTION ------------------', action)
      state.printRequest = ({...state.printRequest, ...action.payload})
    },
  },
  devTools: false
})


export const getUserState = (state) => state.user

export default user