import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Card } from 'semantic-ui-react'

export const Container = styled.div`
  padding: 0rem;

  .section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .section_wrapper {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    &.fluid {
      .section_wrapper {
        max-width: 100%;
      }
    }

    &.padded {
      padding: 2rem 0;
      .section_wrapper {
        padding: 2rem;
      }
    }
    
    &.hero {
      padding: 0;
      min-height: calc(calc(var(--vh, 1vh) * 100));
      background-color: #ffffff;
      align-items: stretch;
      overflow: hidden;

      @media ${stylevars.breakpoints.phone} {
        min-height: auto;
      }

      .section_wrapper {
        align-items: center;
      }

      .heroContent {
        width: 100%;
        height: calc(calc(var(--vh, 1vh) * 100));
        @media ${stylevars.breakpoints.phone} {
          height: auto;
        }
      }
      .heroParallax {
        position: relative;
        width: 100%;
        height: calc(calc(var(--vh, 1vh) * 100));

        @media ${stylevars.breakpoints.phone} {
          height: calc(calc(var(--vh, 1vh) * 100));
          margin-bottom: 2rem;
        }
        
        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex !important;
          justify-content: center;
          align-items: center;
          &.image1 {
            z-index: 1;
          }
          &.image2 {
            z-index: 2;
          }
          &.image3 {
            z-index: 3;
          }
          img {
            position: relative;
            object-fit: contain;
            width: 100%;
            max-width: 1680px;
            height: auto;

            @media ${stylevars.breakpoints.phone} {
              height: auto;
            }
          }
        }
      }
    }
  }

  .logo {
    position: fixed;
    z-index: 1000;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100vw;
    background-color: rgba(255,255,255,0.85);
    padding: 4rem;
    /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    backdrop-filter: blur(0.5rem);

    .logoImage {
      width: 100%;
      max-width: 320px;
    }

    h4 {
      color: #9c9c9c;
      font-size: 1.5rem;
    }

    h1 {
      color: #333;
      font-size: 3rem;
      .text {
        display: inline-block;
        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }

    [class^='color--'] {
        font-size: 4rem;
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        margin-left: 1rem;
    }
    .color-- {
      &red {
        background-color: #FF0000;
      }
      &green {
        background-color: #0CB30C;
      }
      &blue {
        background-color: #0C32FF;
      }
      &yellow {
        background-color: #FDFA00;
      }
    }
  }
  .heroTexts {
    position: absolute;
    bottom: 4rem;
    right: 4rem;
    z-index: 1001;

    /* @media ${stylevars.breakpoints.phone} {
      position: relative;
      bottom: 1rem;
      right: 1rem;
    } */
    @media ${stylevars.breakpoints.phone} {
      text-align: center;
    }
  }
  .heroText {
    text-align: right;

    @media ${stylevars.breakpoints.phone} {
      text-align: center;
      display: inline-block;
      margin-left: 0.5rem;
    }

    .text {
      text-align: right;
      display: inline-block;
      font-size: calc(min(calc(calc(var(--vw, 1vw) * 8)), 4rem));
      line-height: 1;
      font-weight: bold;
      margin-right: 0.5rem;
      @media ${stylevars.breakpoints.phone} {
        text-align: center;
        font-size: 2rem;
      }
    }
    .dot {
      display: inline-block;
      width: calc(min(calc(calc(var(--vw, 1vw) * 2)), 0.75rem));
      height: calc(min(calc(calc(var(--vw, 1vw) * 2)), 0.75rem));
      line-height: 5rem;
      &.red {
        background-color: #ff000f;
      }
      &.green {
        background-color: #00B300;
      }
      &.blue {
        background-color: #0040FF;
      }
      &.yellow {
        background-color: #FFFC00;
      }
    }
  }
`