import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom' // eslint-disable-line
import NumberFormat from 'react-number-format'
import { findIndex, nth } from 'lodash'
// import { Helmet } from 'react-helmet'
import { consoleLogger, priceFormat, maskEmail, containsObject } from '@Utils'
import { format, formatDistanceToNow } from 'date-fns'
import cn from 'classnames'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice from '@Slices/uiSlice' // eslint-disable-line
import cartSlice, { getCartState } from '@Slices/cartSlice' // eslint-disable-line
import axios from 'axios'
// import { getGalleryState } from '@Slices/gallerySlice' // eslint-disable-line

// COMPONENTS
import {
  Button,
  Header,
  Icon,
  Label,
  Segment,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Item,
  Image,
  Table,
} from 'semantic-ui-react'
import LoginForm from '../../Pages/Login/LoginForm'

// STYLES
import StyledStoreCartContainer from '../../StorePages/Cart/StoreCartStyles'

const lookup = require('country-code-lookup')

const AccountPayoutView = (props) => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const { payoutId } = props
  const [ isReady, setIsReady ] = React.useState(false)
  const [ payoutInfo, setPayoutInfo ] = React.useState()
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  // const { checkout } = useSelector(getCartState)
  
  const statusArray = [
    {
      key: '0',
      label: 'PENDING',
    },
    {
      key: '1',
      label: 'PROCESSING',
      color: 'blue',
    },
    {
      key: '2',
      label: 'CLEARED',
      color: 'green',
    },
    {
      key: '3',
      label: 'CANCELLED',
      color: 'orange',
    },
    {
      key: '4',
      label: 'UNKNOWN',
      color: 'red',
    },
  ]

  const payoutMethodLabel = [
    {
      key: "paypal",
      label: "PayPal"
    }
  ]

  React.useEffect(() => {
    (async() => {
      if (!isReady) {
        if (payoutId) {
          consoleLogger('=================== START =======================')
          let tempInfo
          let tempItem
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/payout/${payoutId}`,
            method: 'GET',
            params: {
              author: userInfo.id,
              user_key: userKey,
            }
          }).then(res => {
            // itemId = res.data.acf.sku.split('-')[1]
            consoleLogger('PAYOUT DETAILS RESPONSE ===========', res.data)
            tempInfo = {
              payout_id: payoutId,
              transaction_id: res.data.acf.transaction_id,
              vendor_info: typeof res.data.acf.vendor_info === 'string' ? JSON.parse(res.data.acf.vendor_info) : res.data.acf.vendor_info,
              amount: res.data.acf.amount,
              fees: res.data.acf.fees,
              adjustments: res.data.acf.adjustments,
              total_amount: res.data.acf.total_amount,
              payout_method: res.data.acf.payout_method,
              paypal_email: res.data.acf.paypal_email,
              mailing_address: res.data.acf.mailing_address,
              status: res.data.acf.status,
              notes: res.data.acf.notes,
              post_date: res.data.post_date,
              post_date_epoch: res.data.post_date_epoch,
              post_date_gmt: res.data.post_date_gmt,
              post_date_gmt_epoch: res.data.post_date_gmt_epoch,
              post_modified: res.data.post_modified,
              post_modified_epoch: res.data.post_modified_epoch,
              post_modified_gmt: res.data.post_modified_gmt,
              post_modified_gmt_epoch: res.data.post_modified_gmt_epoch,
              timestamp: res.data.timestamp,
            }
          }).catch(err => {
            consoleLogger(err)
          })

          setPayoutInfo(tempInfo)
          setIsReady(true)

          consoleLogger('============ END ==================')
        }
      }
    })()
  }, [payoutId]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (payoutInfo && payoutInfo.itemId) {
        consoleLogger('========== payoutInfo ============', payoutInfo)
      }
    })()
  }, [payoutInfo])

  return (
    <>
      {!isReady ?
        <Dimmer active inverted>
          <Loader inverted>Loading Payout Details...</Loader>
        </Dimmer> : 
        <StyledStoreCartContainer className='orderStatusViewContent'>
          <>
            <Header as='h2' className='orderStatus_header'>
              <span></span>
              <div className='orderStatus_status'>
                <span className='text--bold'>STATUS:</span>
                <Label color={statusArray.filter(i => i.key === payoutInfo.status).map(n => n.color)} size='large' className={cn('orderStatus_label', {
                  [statusArray.filter(i => i.key === payoutInfo.status).map(n => n.label)]: payoutInfo.status
                })}>{statusArray.filter(i => i.key === payoutInfo.status).map(n => n.label)}</Label>
              </div>
            </Header>
            <Divider />
            <Segment className='cart'>
              <Grid divided className='cartContainer'>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16} className='cartSummary'>
                    <div className='cartSummaryContainer'>
                      <Header as='h3'>Payout Details</Header>
                      <Divider />
                      <Grid>
                        <Grid.Row>
                          <Grid.Column computer={16}>
                            <Table basic='very'>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Payout ID
                                  </Table.Cell>
                                  <Table.Cell>
                                    <h4>{payoutInfo.payout_id}</h4>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Transaction ID
                                  </Table.Cell>
                                  <Table.Cell>
                                  <h4>{payoutInfo.transaction_id}</h4>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Recipient Name
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{`${payoutInfo.vendor_info.last_name}, ${payoutInfo.vendor_info.first_name}`}</p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Payout Method
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{payoutMethodLabel.filter(i => i.key === userInfo.acf.payout_method).map(n => n.label)} <span className='text--bold'>[ {userInfo.acf.paypal_email} ]</span></p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Request Amount
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>
                                      <NumberFormat
                                        value={payoutInfo.amount}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale
                                      />
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Fees
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>
                                      {payoutInfo.fees !== 'TBD' ?
                                        <NumberFormat
                                          value={payoutInfo.fees}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                          decimalScale={2}
                                          fixedDecimalScale
                                        /> : 
                                        <>TBD</>
                                      }
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Adjustments
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>
                                      {payoutInfo.adjustments !== 'TBD' ?
                                        <NumberFormat
                                          value={payoutInfo.adjustments}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                          decimalScale={2}
                                          fixedDecimalScale
                                        /> : 
                                        <>TBD</>
                                      }
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Final Amount
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>
                                      {payoutInfo.total_amount !== 'TBD' ?
                                        <NumberFormat
                                          value={payoutInfo.total_amount}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                          decimalScale={2}
                                          fixedDecimalScale
                                        /> : 
                                        <>TBD</>
                                      }
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Post Date
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{format(new Date(payoutInfo.post_date_gmt_epoch * 1000), 'yyyy-MM-dd')}</p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Last Updated
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{format(new Date(payoutInfo.post_modified_gmt_epoch * 1000), 'yyyy-MM-dd')} (<span>{formatDistanceToNow(new Date(payoutInfo.post_modified_gmt_epoch * 1000), {addSuffix: true})}</span>)</p>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Status
                                  </Table.Cell>
                                  <Table.Cell>
                                    <h4>{statusArray.filter(i => i.key === payoutInfo.status).map(n => n.label)}</h4>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={120}>
                                    Notes
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{payoutInfo.notes}</p>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                        </Grid.Row>
                        {/* <Divider />
                        <Grid.Row>
                          <Grid.Column computer={16}>
                            <Button as='a' href='/checkout' fluid positive size='large' icon='arrow right' content='Proceed to Checkout' labelPosition='right' />
                          </Grid.Column>
                        </Grid.Row> */}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        </StyledStoreCartContainer>
      }
    </>
  )
}

export default React.memo(AccountPayoutView)
