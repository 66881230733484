import React from 'react'

import { ReactComponent as VerifiedSVG } from '@StorePages/User/verified.svg'

// STYLES
import { UserDisplayNameContainer } from './UserDisplayNameStyles'

const UserDisplayName = (props) => {
  const { user } = props
  return (
    <UserDisplayNameContainer>
      <h3 className='name'>{user.name}</h3>
      {user.acf.subscription_type === 'premium' &&
        <span className='badge'>
          <VerifiedSVG />
        </span>
      }
    </UserDisplayNameContainer>
  )
}

export default UserDisplayName
