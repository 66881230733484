import React from 'react'
// import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// COMPONENTS
import {Root} from './Styles'
import PaymentForm from './PaymentForm'

const SignupPaymentPage = () => {
  // const history = useHistory()
  // const { userid, key } = useParams()

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Subscription Payment - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/signup`} />
      </Helmet>
      <PaymentForm />
    </Root>
  )
}

export default React.memo(SignupPaymentPage)
