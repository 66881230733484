import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const ConfirmOrderContainer = styled("div")`
  text-align: left;
  .checkoutContainer {
    text-align: left;
  }
  h3 {
    margin: 1rem 0 !important;
    text-align: left;
  }
`;
