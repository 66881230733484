import React from 'react'
import { Link } from 'react-router-dom'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { List, Message } from 'semantic-ui-react'

// STYLES
import { Container } from './AccountCompletionStyles'

const AccountCompletion = () => {

  const { authenticated, userInfo, userKey, accountCompletion } = useSelector(getUserState)
  const { pageTemplate } = useSelector(getUIState)

  const [ show, setShow ] = React.useState(false)

  React.useEffect(() => {
    let count = 0
    if (accountCompletion) {
      if (!accountCompletion.hasAddress) {
        count += 1
      }
      if (userInfo.role === 'sf_creator' && userInfo.acf.subscription_type === 'premium' && !accountCompletion.hasSignature) {
        count += 1
      }
      if (userInfo.role === 'sf_creator' && !accountCompletion.hasProfilePhoto) {
        count += 1
      }
      if (userInfo.role === 'sf_creator' && !accountCompletion.hasCoverPhoto) {
        count += 1
      }
      setShow(count > 0)
    }
  }, [accountCompletion]) // eslint-disable-line

  return (
    <>
      {show && pageTemplate === 'dash' &&
        <Container>
          <Message color='red' icon='exclamation triangle' header='Account Completion' content={
            <List as='ul'>
              {userInfo.role === 'sf_creator' && !accountCompletion.hasProfilePhoto && (
                <List.Item as='li'>You have not uploaded a profile photo. <Link to='/admin/layout/profile'>Setup now</Link></List.Item>
              )}
              {userInfo.role === 'sf_creator' && !accountCompletion.hasCoverPhoto && (
                <List.Item as='li'>You have not uploaded a cover photo. <Link to='/admin/layout/profile'>Setup now</Link></List.Item>
              )}
              {!accountCompletion.hasAddress && (
                <List.Item as='li'>You have not setup your billing and/or mailing address. <Link to='/admin/account/information'>Setup now</Link></List.Item>
              )}
              {userInfo.role === 'sf_creator' && userInfo.acf.subscription_type === 'premium' && !accountCompletion.hasSignature && (
                <List.Item as='li'>You have not setup a digital signature. <Link to='/admin/account/certificate'>Setup now</Link></List.Item>
              )}
            </List>
          } />
        </Container>
      }
    </>
  )
}

export default React.memo(AccountCompletion)
