import React from 'react'
// import axios from 'axios'
import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  Icon,
  // Table,
  // Button,
  // Checkbox,
  // Modal,
  // Grid,
  // Pagination,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  Dropdown,
} from 'semantic-ui-react'

const TableDisplaySelect = (props) => {
  const dispatch = useDispatch()
  const {
    info
  } = props
  const { userInfo } = useSelector(getUserState)
  const { uiFilters, uiTables } = useSelector(getUIState)
  const [ pageSize, setPageSize ] = React.useState(uiTables.filter(i => i.component === info.namespace)[0].pageSize)
  const options = [
    {
      key: 'show5',
      text: '5',
      value: 5
    },
    {
      key: 'show15',
      text: '15',
      value: 15
    },
    {
      key: 'show50',
      text: '50',
      value: 50
    },
    {
      key: 'show100',
      text: '100',
      value: 100
    },
  ]

  const handleChange = (value) => {
    setPageSize(value)
    dispatch(uiSlice.actions.updateUITables({component: info.namespace, pageSize: value}))
    // check if page number is greater than total pages
    if (uiTables.filter(i => i.component === info.namespace)[0].pageNumber > Math.ceil(info.totalItems / value)) {
      dispatch(uiSlice.actions.updateUITables({component: info.namespace, pageNumber: 1}))
    }
  }

  return (
    <>
      <span className={cn('displayCountDropdown_label')}>Display Rows</span>
      <Dropdown
        compact
        selection
        options={options}
        defaultValue={pageSize}
        className={cn('displayCountDropdown')}
        onChange={(ev, {value}) => handleChange(value)}
      />
    </>
  )
}

export default React.memo(TableDisplaySelect)