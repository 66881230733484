import React from 'react'

// COMPONENTS
import { Button, Grid, Icon, Item, Placeholder } from 'semantic-ui-react'

const CartItemSkeleton = () => {
  return (
    <>
      <Item>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={2}>
              <Placeholder className='image'>
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
            <Grid.Column mobile={16} computer={6} verticalAlign='middle'>
              <Item.Content>
                <Placeholder className='header'>
                  <Placeholder.Line />
                </Placeholder>
                <Placeholder className='extraDetails'>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder>
                <div className='itemActions'>
                  <Button
                    negative size='mini' basic
                    disabled
                  >
                    <Icon name='trash' />
                    <span>Remove</span>
                  </Button>
                </div>
              </Item.Content>
            </Grid.Column>
            <Grid.Column mobile={4} computer={2} verticalAlign='middle' textAlign='center'>
              <div className='price'>
                <p><span>0</span></p>
                <small>Price</small>
              </div>
            </Grid.Column>
            <Grid.Column mobile={8} computer={4} verticalAlign='middle' textAlign='center' className='quantity'>
              <div className='quantityContainer'>
                <Button
                  icon='minus' className='minus'
                  disabled
                />
                  <span className='quantityText'>0x</span>
                <Button
                  icon='plus' className='plus'
                  disabled
                />
              </div>
              <small>Quantity</small>
            </Grid.Column>
            <Grid.Column mobile={4} computer={2} verticalAlign='middle' textAlign='center'>
              <div className='amount'>
                <p>
                  <span>$0</span>
                </p>
                <small>Amount</small>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Item>
    </>
  )
}

export default React.memo(CartItemSkeleton)
