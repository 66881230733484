import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Card } from 'semantic-ui-react'

export const StyledContainer = styled.div`
  background-color: #f3f3f3;
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .container {
    padding: 2rem;
    background-color: #ffffff;
    width: 100%;
    max-width: 1200px;
    counter-reset: h2ordered;
  }
`