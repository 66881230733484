import React from 'react'
import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

const Container = styled.div`
  &.profile {
    width: auto;
    display: inline-block;
  }
  &.cover {
    width: auto;
    display: inline-block;
  }
  .uploader_image {
    position: relative;
    width: auto;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;

    &.profile {
      border-radius: 50%;
      .uploader_image_overlay {
        > span {
          position: absolute;
          bottom: 2rem;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          font-size: 0.75rem;
          color: white;
          white-space: nowrap;
        }
      }
      .ui.image {
        width: calc(min(calc(var(--vw, 1vw) * 30), 150px)) !important;
        height: calc(min(calc(var(--vw, 1vw) * 30), 150px)) !important;
      }
    }
    .ui.image {
      &.fluid {
        width: 100% !important;
        height: 150px !important;
        object-fit: cover;
      }
    }
    &.cover {
      width: 100%;
      .ui.image {
        height: calc(min(calc(var(--vw, 1vw) * 60), 320px)) !important;
      }
      .uploader_image_overlay {
        > span {
          position: absolute;
          bottom: 1rem;
          right: 2rem;
          text-align: center;
          font-size: 0.75rem;
          color: white;
          white-space: nowrap;
        }
      }
    }
    /* .uploader_image_overlay */
    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(${stylevars.palette.info.rgb}, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.15s ease-out;
      pointer-events: none;

      .icon {
        color: #fff;
        font-size: 4rem;
        line-height: 4rem;
      }
    }
    &:hover {
      .uploader_image_overlay {
        opacity: 1;
      }
    }
  }
  .uploader_content {
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .uploader_note {
    margin-bottom: 1rem;

    p {
      margin: 0;
      line-height: 1.6;
    }
  }

  .uploaderDropzone {
    position: relative;
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 1rem;
    border-width: 2px;
    border-style: dashed;
    border-color: rgba(${stylevars.palette.info.rgb}, 0);
    transition: border 0.15s ease-out;
    /* .uploaderDropzone_indicator */
    &_indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
      z-index: 2;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      opacity: 0;
      transition: opacity 0.15s ease-out;
    }

    &:hover {
      border-color: rgba(${stylevars.palette.info.rgb}, 1);
      &.hasDropped {
        border-color: rgba(${stylevars.palette.info.rgb}, 0);
      }
    }
    &.dragging {
      .uploaderDropzone_indicator {
        opacity: 1;
      }
    }
  }

  .tooLarge {
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    background-color: #ff0000;
    color: #fff;
    width: auto;
  }

  .uploader_column {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
  }

  .defaultImage {
    margin: auto;
    width: 250px !important;
    height: 250px !important;
    pointer-events: none;
    &.fluid {
      width: 100% !important;
      height: 250px !important;
      object-fit: cover;
    }
  }
`

const StyledContainer = ({children, ...rest}) => {
  return (
    <Container
      {...rest}
    >
      {children}
    </Container>
  )
}

export default React.memo(StyledContainer)