import React from 'react'
import NumberFormat from 'react-number-format'
import axios from 'axios'
import { consoleLogger, priceFormat } from '@Utils'
import { PayPalButtons } from "@paypal/react-paypal-js";

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Button, Modal } from 'semantic-ui-react'

// STYLES
import { UpgradeModalContainer } from './UpgradeModalStyles'

const UpgradeModal = (props) => {
  const {
    open,
    handleModal
  } = props

  const dispatch = useDispatch()
  const sfToken = localStorage.Authorization

  const { userInfo, userKey } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)

  const [ processing, setProcessing ] = React.useState(false)
  const handleProcessing = (bool) => {
    setProcessing(bool)
  }

  const upgradeSubscription = (data, actions) => {
    handleProcessing(true)
    return actions.subscription.create({
      "plan_id": process.env.REACT_APP_PAYPAL_SUBSCRIPTION_UPGRADE,
      "application_context": {
        "shipping_preference":"NO_SHIPPING"
      }
    })
  }

  const upgradeApproval = async (data, actions) => {
    consoleLogger("Paypal approved")
    consoleLogger("return data ========", data)
    consoleLogger("return actions ========", actions)

    // AFTER PAYPAL PAYMENT APPROVAL
    // WE NEED TO REVISE THE SUBSCRIPTION DETAILS
    // FROM STARTER TO PRO (PREMIUM)
    
    let ppToken
    let error = 0
    const subscriptionId = userInfo.acf.subscription_id
    const newSubscriptionId = data.subscriptionID
    // get OATH token first
    await axios({
      baseURL: process.env.REACT_APP_PAYPAL_API_URL,
      url: `/v1/oauth2/token`,
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Basic ${process.env.REACT_APP_PAYPAL_BASE64}`
      },
      params: {
        "grant_type": "client_credentials"
      }
    }).then(res => {
      ppToken = res.data.access_token
    }).catch(err => {
      consoleLogger(err)
      error += 1
    })

    // cancel the old subscription

    if (error === 0) {
      await axios({
        baseURL: process.env.REACT_APP_PAYPAL_API_URL,
        url: `/v1/billing/subscriptions/${subscriptionId}/cancel`,
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${ppToken}`
        },
        data: {
          "reason": 'upgraded to pro plan'
        }
      }).then(res => {
        consoleLogger('========================= UPGRADE RESPONSE', res.data)
      }).catch(err => {
        consoleLogger(err)
        error += 1
      })
    }

    // update user info with new subscription id
    if (error === 0) {
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/subscription/upgrade`,
        method: 'POST',
        headers: {
          "Authorization": sfToken,
        },
        data: {
          user_id: userInfo.id,
          user_key: userKey,
          subscription_id: newSubscriptionId,
          update_duration: true,
        }
      })
      .then(res => {
        dispatch(userSlice.actions.setUserInfo(res.data))
      })
      .catch(err => {
        const errorData = err.response.data
        consoleLogger("ERROR: ", errorData)
      })
    }

    handleProcessing(false)
    handleModal(false)
  }
  const paypalOnError = (err) => {
    consoleLogger("ERROR", err)
    handleProcessing(false)
  }

  return (
    <>
      <Modal
        onClose={() => handleModal(false)}
        onOpen={() => handleModal(true)}
        open={open}
        closeOnDimmerClick={false}
        size='tiny'
      >
        <>
          <Modal.Content scrolling>
            <Modal.Description>
              <UpgradeModalContainer>
                <div className='wrapper'>
                  <p>You are about to</p>
                  <h2>Upgrade to a <strong>PRO</strong> Plan</h2>
                  <div className='content'>
                    <div className='left'>
                      <div className='price'>
                        <span className='amount'>
                          <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.premiumPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </span>
                        <span className='extra'>/mo.</span>
                      </div>
                      <div className='annualPrice'>Annual Price: <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.premiumPrice * 12} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                    </div>
                    <div className='right'>
                      <ol className='features customList'>
                        <li><span>{siteSettings.premiumFee * 100}% Fee</span></li>
                        <li><span>3 Themes</span></li>
                        <li><span>Gallery with <NumberFormat decimalScale={0} fixedDecimalScale={false} value={siteSettings.premiumLimit} displayType={'text'} thousandSeparator={true} /> images</span></li>
                        <li>
                          <span>
                            <NumberFormat decimalScale={0} fixedDecimalScale={false} value={siteSettings.premiumCollectionsLimit} displayType={'text'} thousandSeparator={true} />
                            {siteSettings.premiumCollectionsLimit > 1 ? ' Collections' : ' Collection'}
                          </span>
                        </li>
                        <li><span>Limited Edition prints</span></li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='paypalButtonWrapper'>
                  <PayPalButtons
                    createSubscription={upgradeSubscription}
                    onApprove={upgradeApproval}
                    catchError={paypalOnError}
                    onError={paypalOnError}
                    onCancel={paypalOnError}
                  />
                </div>
              </UpgradeModalContainer>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type='button'
              content="Cancel"
              labelPosition='right'
              icon='close'
              onClick={() => handleModal(false)}
              basic
              loading={processing}
              disabled={processing}
            />
          </Modal.Actions>
        </>
      </Modal>
    </>
  )
}

export default React.memo(UpgradeModal)
