import React from 'react'
import { Button } from 'semantic-ui-react'
// import useCountDown from 'react-countdown-hook'

const DeleteButton = (props) => {
  const {
    post,
    isDeleting,
    handleDeleteItem
  } = props
  // const [ timeleft, { start, pause, resume, reset } ] = useCountDown(5000, 1000)
  
  // React.useEffect(() => {
  //   start()
  // }, []) // eslint-disable-line

  return (
    <>
      {/* {timeleft === 0 ? */}
        <Button
          type='button'
          content={`Delete`}
          labelPosition='right'
          icon={`check`}
          onClick={() => handleDeleteItem(post)}
          negative
          loading={isDeleting}
          disabled={isDeleting}
        />
        {/* <Button
          type='button'
          content={`Delete`}
          labelPosition='right'
          label={`${timeleft/1000}s`}
          negative
          disabled
        />
      } */}
    </>
  )
}

export default DeleteButton
