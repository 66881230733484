import React from 'react'
import cn from 'classnames'
import axios from 'axios'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Button, Dimmer, Grid, Icon, Image, Label, Loader, Popup, Segment } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const ThemeItem = (props) => {
  const dispatch = useDispatch()
  const { userInfo, userKey } = useSelector(getUserState)
  const token = localStorage.Authorization
  const [ ready, setReady ] = React.useState(false)
  const [ processing, setProcessing ] = React.useState(false)
  const { handleProcess, modalHandler } = props
  const handleThemeClick = async ({id, name}) => {
    if (!processing) {
      // setProcessing(true)
      handleProcess(true)
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/users/${userInfo.id}`,
        headers: {
          Authorization:`${token}`,
        },
        method: 'POST',
        data: {
          acf_layout_style: id,
          user_key: userKey,
        },
      }).then((res) => {
        consoleLogger("USER LAYOUT STYLE RESPONSE.... ", res.data)
        dispatch(userSlice.actions.updateUserInfo(res.data))
        
        toast.success(`You've selected ${name} theme.`, {
          containerId: 'mainToaster',
          progress: undefined,
        });
      }).catch(err => {
        consoleLogger(err)
      })
      // setProcessing(false)
      handleProcess(false)
    }
  }

  React.useEffect(() => {
    setReady(true)
  }, []) // eslint-disable-line

  return (
    <>
      <div className={cn('themeItem')}>
        {ready ?
          <>
            {processing &&
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            }
            <div
              className={cn('themeItem_wrapper', {
                'active': parseInt(userInfo.acf.layout_style) === parseInt(props.id)
              })}
            >
              <Image
                fluid
                className='themeItem_image'
                src={`/images/screenshot_theme${props.id}.jpg`}
              />
              <div className={cn('themeItem_name', {
                  'active' : parseInt(userInfo.acf.layout_style) === parseInt(props.id),
                })}
              >
                <span className='label'
                  onClick={() => handleThemeClick({id: props.id, name: props.name})}
                >{props.name}</span>
                {parseInt(userInfo.acf.layout_style) === parseInt(props.id) &&
                  <Popup
                    position='top right'
                    trigger={
                      <span className={cn('customize')}
                        onClick={() => modalHandler(true)}
                      >
                        <Icon name='paint brush' />
                      </span>
                    }
                    content={`Configure the theme's color scheme`}
                  />
                }
              </div>
            </div>
          </> : 
          <>Loading...</>
        }
      </div>
    </>
  )
}

export default React.memo(ThemeItem)
