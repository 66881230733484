import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CollectionsEditorContainer = styled('div')`
  padding: 2rem;
  width: 100%;
  position: relative;
  min-height: 240px;

  @media ${stylevars.breakpoints.phone} {
    padding: 1rem;
  }

  .galleryContent {
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* margin-bottom: calc(${stylevars.navBar.height} + 0rem); */
    min-height: calc(calc(var(--vh, 1vh) * 100) - 420px - calc(${stylevars.navBar.height} * 3) - 7rem);
    padding: 0rem;
    width: 100%;
    background-color: ${stylevars.paper.bgColor};
    /* min-height: calc(calc(var(--vh, 1vh) * 100) - calc(${stylevars.navBar.height} * 2) - 8rem); */
  }
  .galleryGrid {
    
  }

  .dashContentHeader {
    background-color: ${stylevars.paper.bgColor};
    border: none !important;
    border-bottom: 1px solid ${stylevars.paper.borderColor} !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
    height: ${stylevars.navBar.height};
    max-height: ${stylevars.navBar.height};

    &.sticky {
      position: sticky;
      /* top: calc(${stylevars.navBar.height} + 1px); */
      top: 0;
      z-index: 10;
    }

    h3 {
      flex-grow: 1;
      display: block;
      margin: 0;
      padding: 0 1rem;
      @media ${stylevars.breakpoints.phone} {
        padding-bottom: 1rem;
      }
    }

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }

    .extraActions {
      padding: 0 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      line-height: 0;
      @media ${stylevars.breakpoints.phone} {
        justify-content: flex-start;
      }
      > span {
        font-weight: bold;
        font-size: 1rem;
        margin-right: 0.5rem;
      }
      .field {
        margin: 0 !important;
        margin-right: 1rem !important;
      }
    }
  }
`