import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledModalContainer = styled('div')`
  .closeButton {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 3rem;
    height: 3rem;
    padding: 0 !important;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    box-shadow: none !important;
    border: none !important;
    background-color: rgba(255, 0, 0, 0) !important;
    color: rgba(255, 0, 0, 1) !important;

    .icon {
      font-size: 1.5rem;
      line-height: 1;
      width: 3rem;
      height: 3rem;
      padding: 0 !important;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background-color: rgba(255, 0, 0, 1) !important;
      color: rgba(255, 255, 255, 1) !important;
    }
  }

  > .content > .formBoxContainer > .ui.form > .ui.grid > .formBox {
    padding: 0;
    background-color: #efefef;

    > h1 {
      display: block;
      padding: 1rem;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
    }

    > p {
      display: block;
      padding: 1rem;
      margin: 1rem 1rem 0 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      

      &.notCreatorCustomer {
        margin-bottom: 1rem;
      }
    }

    > .ui.card {
      /* border: none;
      box-shadow: none; */
      margin: 1rem!important;
      width: calc(100% - 2rem) !important;
      background-color: #ffffff;
    }
  }

  .ui.message {
    margin: 0;
    background-color: #ffffff;
    border-radius: 0;
    &.negative {
      border: none;
      background-color: ${stylevars.palette.danger.main};
      color: #ffffff;
    }
    &.positive {
      border: none;
      background-color: ${stylevars.palette.success.main};
      color: #ffffff;
    }
  }
`