import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CoverPhotoContainer = styled('div')`
  position: relative;

  .overlay {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to top, rgba(0,0,0,0.75), rgba(0,0,0,0));
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;

    opacity: 1;

    transition: all 0.15s ease-out;

    @media ${stylevars.breakpoints.phone} {
      top: auto;
      bottom: 0;
      height: auto;
      padding: 0.5rem;
      opacity: 1;
    }

    > .actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row;

      @media ${stylevars.breakpoints.phone} {
        flex-direction: column;

        .button {
          margin: 0.25rem !important;
        }
      }
    }
  }

  .imageWrapper {
    display: block !important;
    width: 100%;
    height: 420px;
    overflow: hidden;

    @media ${stylevars.breakpoints.phone} {
      height: auto;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      margin: 0;
      padding: 0;
      @media ${stylevars.breakpoints.phone} {
        height: auto;
      }
    }
  }

  &:hover {
    .overlay {
      opacity: 1;

      > .actions {
        pointer-events: all;
      }
    }
  }
`

export const MediaTabContainer = styled('div')`
  position: relative;
  min-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(calc(var(--vh, 1vh) * 80) - 12rem);

  .mediaTabSelect {
    position: sticky;
    z-index: 10;
    top: 0;
    border-bottom: 1px solid ${stylevars.paper.borderColor};
    padding: 1rem;
    display: flex;
    justify-content: center;
    background-color: ${stylevars.paper.bgColor};

    .button {
    }
  }
  .mediaTabContent {
    padding: 1rem;
  }
`