import React from 'react'
import { useHistory } from "react-router-dom"
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {Root} from './Styles'
import LoginForm from './LoginForm'
import { Button, Modal } from 'semantic-ui-react'

// STYLES
import { StyledModalContainer } from './LoginModalStyles'

const LoginPage = (props) => {
  const { open, handler, to, customer } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { authenticated } = useSelector(getUserState)
  // const { path } = useParams()
  // const decodedPath = decodeURIComponent(path)
  // consoleLogger('decodedPath =======================', decodedPath)

  React.useEffect(() => {
    consoleLogger("============ to ===============", to)
    // dispatch(uiSlice.actions.setPageTemplate('store'))
    // dispatch(uiSlice.actions.setCurrentPage('login'))
    // dispatch(uiSlice.actions.setCurrentPageURL())
    // dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  // React.useEffect(() => {
  //   consoleLogger('LOGIN PAGE')
  //   if (authenticated) {
  //     if (to) {
  //       history.push(to)
  //     }
  //     // history.push('/admin')
  //   }
  // }, [authenticated, history]) // eslint-disable-line

  return (
      <Modal
        size='mini'
        open={open}
        dimmer={{
            blurring: true
        }}
        onOpen={() => handler(true)}
        onClose={() => handler(false)}
        className='loginModal'
      >
        <StyledModalContainer>
          <Modal.Content>
              <Button className='closeButton' circular color='red' icon='close'
                onClick={() => handler(false)}
              />
              <LoginForm handleModal={handler} customer={customer} />
          </Modal.Content>
        </StyledModalContainer>
      </Modal>
  )
}

export default React.memo(LoginPage)
