import React from 'react'
import { consoleLogger } from '@Utils'
import cn from 'classnames'

// COMPONENTS
import { Dropdown, Menu, Checkbox, Button } from 'semantic-ui-react'

// STYLES
import { GalleryGridFilterContainer } from './GalleryGridFilterStyles'

const GalleryGridFilter = (props) => {
  const {
    options,
    value,
    order,
    onValueChange,
    onOrderChange,
    disabled,
    showAll,
    handleShowAll,
    filter,
    handleFilter
  } = props
  const [ ready, setReady ] = React.useState(false)

  const handleArrangeByChange = ((ev, {value}) => {
    onValueChange(value)
  })

  const handleDirectionChange = ((ev, {value}) => {
    onOrderChange(value)
  })

  const directionOptions = [
    { key: 'direction-asc', text: 'ASC', value: 'asc', active: order === 'asc' },
    { key: 'direction-desc', text: 'DESC', value: 'desc', active: order === 'desc' },
  ]

  // const [ filterSelected, setFilterSelected ] = React.useState('all')
  const handleSetFilter = (ev, {value}) => {
    consoleLogger(value)
    // setFilterSelected(value)
    handleFilter(value)
  }
  const filterOptions = [
    { key: 'active', text: 'Show Published Only', value: 'active', active: filter === 'active' },
    { key: 'inactive', text: 'Show Unpublished Only', value: 'inactive', active: filter === 'inactive' },
    { key: 'divider1', divider: true },
    { key: 'attached', text: 'Show Attached Only', value: 'attached', active: filter === 'attached' },
    { key: 'unattached', text: 'Show Unattached Only', value: 'unattached', active: filter === 'unattached' },
    { key: 'divider2', divider: true },
    { key: 'all', text: 'Show All', value: 'all', active: filter === 'all' },
  ]

  React.useEffect(() => {
    consoleLogger('-------------------- order')
    consoleLogger(order)
  }, [order])

  React.useEffect(() => {
    setReady(true)
  }, [])

  return (
    <GalleryGridFilterContainer className={cn({
      'disabled': disabled
    })}>
      {ready &&
        <>
          <div className='filterGroup'>
            {/* <Button
              size='tiny'
              content={showAll ? `Hide unattached items` : `Show all items`}
              color={showAll ? 'grey' : 'blue'}
              onClick={handleShowAll}
              disabled={disabled}
            /> */}
            <div className='filterLabel'>Filter</div>
            <Menu compact className='filterOption'>
              <Dropdown
                placeholder='Select filter'
                text={filterOptions.filter(n => n.value === filter).map(i => i.text)}
                value={filter}
                item
                disabled={disabled}
              >
                <Dropdown.Menu>
                  {filterOptions && filterOptions.map(item => {
                    if (item.divider) return (<Dropdown.Divider key={item.key} />)
                    return (
                      <Dropdown.Item
                        key={item.key}
                        text={item.text}
                        value={item.value}
                        active={item.value === filter}
                        onClick={handleSetFilter}
                      />
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </div>
          <div className='filterGroup'>
            <div className='filterLabel'>Sort</div>
            <Menu compact className='filterOption'>
              <Dropdown
                onChange={handleArrangeByChange}
                options={options}
                placeholder='Select option'
                value={value}
                item
                disabled={disabled}
              />
            </Menu>
            {value !== 'listOrder' &&
              <Menu compact>
                <Dropdown
                  onChange={handleDirectionChange}
                  options={directionOptions}
                  placeholder='Select order option'
                  value={order}
                  simple
                  item
                  disabled={disabled}
                />
              </Menu>
            }
          </div>
        </>
      }
    </GalleryGridFilterContainer>
  )
}

export default React.memo(GalleryGridFilter)
