import React from 'react'
import { Link } from 'react-router-dom'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import {
  Button,
  Grid,
  Icon,
  List,
} from 'semantic-ui-react'

import { StyledFooterContainer } from './FooterStyles.js'

const Footer = () => {
  const { authenticated } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)

  return (
    <>
      {siteSettings && siteSettings.siteMode === 1 &&
        <StyledFooterContainer>
          <div className='section footer'>
            <div className='section_wrapper'>
              <Grid stackable>
                <Grid.Column computer={7}>
                  <Link to='/'><img className='logo' src='/logo_dark.svg' alt='logo' /></Link>
                  <p className="text">
                    <span>For more information or if you have any suggestions/requests, please email us at:</span>
                    <span><Icon name="mail" /><a href="mailto:hello@stylefolio.app">hello@stylefolio.app</a></span>
                  </p>
                </Grid.Column>
                <Grid.Column computer={3}>
                  <h3>About Us</h3>
                  <List inverted relaxed>
                    <List.Item>
                      <List.Content>
                        <List.Header as={Link} to='/about'>About {process.env.REACT_APP_PROJECT_NAME}</List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <List.Header as={Link} to='/terms'>Terms of Service</List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <List.Header as={Link} to='/privacy'>Privacy Policy</List.Header>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <List.Header as={Link} to='/faq'>FAQ</List.Header>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column computer={3}>
                  <h3>Creators</h3>
                  <List inverted relaxed>
                    <List.Item>
                      <List.Content>
                      <List.Header as={Link} to='/discover'>Discover</List.Header>
                      </List.Content>
                    </List.Item>
                    {/* <List.Item>
                      <List.Content>
                      <List.Header as={Link} to='/creator-guide'>Creator Guide</List.Header>
                      </List.Content>
                    </List.Item> */}
                  </List>
                </Grid.Column>
                <Grid.Column computer={3}>
                  <h3>Community</h3>
                  <div className='community'>
                    <a href='//twitter.com/'>
                      <Icon name='twitter' />
                    </a>
                    <a href='//facebook.com/105059505383172'>
                      <Icon name='facebook' />
                    </a>
                    <a href='//instagram.com/stylefol.io'>
                      <Icon name='instagram' />
                    </a>
                    <a href='//medium.com/'>
                      <Icon name='medium' />
                    </a>
                  </div>
                </Grid.Column>
              </Grid>
              <div className='copyright'>
                <div>{process.env.REACT_APP_PROJECT_NAME} &copy; {new Date().getFullYear()}</div>
              </div>
            </div>
          </div>
        </StyledFooterContainer>
      }
    </>
  )
}

export default React.memo(Footer)
