import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Parallax from 'parallax-js'
import { Parallax as ReactParallax } from 'react-parallax';

// REDUX
import { userSelector, useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import {
  Grid,
  Button,
} from 'semantic-ui-react'

// STYLES
import { Container } from './CreatePageStyles'
// import Lottie from 'react-lottie-player'
import NumberFormat from 'react-number-format'

// const signupAnimation = require('./Animations/signup.json');
// const setupAnimation = require('./Animations/setup.json');
// const socialAnimation = require('./Animations/social.json');

const HomePage = () => {
  const dispatch = useDispatch()
  const { authenticated, userInfo } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  const [ actionLink, setActionLink ] = React.useState('/signup')

  const sceneRef = React.useRef()

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('create'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
    
    const parallaxInstance = new Parallax(sceneRef.current, {
      relativeInput: true,
      hoverOnly: true
    })

    parallaxInstance.enable()

    return (() => {
      parallaxInstance.disable()
    })
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (authenticated && userInfo.role === 'sf_creator') {
      setActionLink('/admin/gallery')
    } else {
      setActionLink('/signup')
    }
  }, [authenticated, userInfo]) // eslint-disable-line

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}`} />
      </Helmet>
      <section className='section hero fluid'>
        <div className='section_wrapper'>
          <div className='heroContent'>
            <div className='heroParallax' ref={sceneRef}>
              <div className='image image3' data-depth={0.2}>
                <img src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/parallax3.png`} />
              </div>
              <div className='image image2' data-depth={0.6}>
              <img src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/parallax2.png`} />
              </div>
              <div className='image image1' data-depth={1}>
              <img src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/parallax1.png`} />
              </div>
            </div>
            <div className='heroTexts'>
              <div className='heroText'>
                <span className='text'>Create</span><span className='dot red' />
              </div>
              <div className='heroText'>
                <span className='text'>Print</span><span className='dot green' />
              </div>
              <div className='heroText'>
                <span className='text'>Sell</span><span className='dot blue' />
              </div>
              <div className='heroText'>
                <span className='text'>Profit</span><span className='dot yellow' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section full simple-process'>
        <img src='/logo_light.svg' className='logo' />
        <h1>is a web portfolio print platform for photographers and artists.</h1>
      </section>
      <section className='section process fluid padded'>
        <div className='section_wrapper'>
          <h1>The process is simple</h1>
          <div className='content'>
            <img src={`${process.env.REACT_APP_DIGITALOCEAN_CDN}/web-browsers.png`} className='image' />
            <ol className='steps customList'>
              <li><span>Create an account and upload your work.</span></li>
              <li><span>Apply a theme to personalize your gallery.</span></li>
              <li><span>Link your {process.env.REACT_APP_PROJECT_NAME} gallery to your website and social media.</span></li>
            </ol>
          </div>
        </div>
      </section>
      <section className='section pricing fluid padded'>
        <div className='section_wrapper'>
          <h1>Pricing</h1>
          <div className='boxes'>
            <div className='box divided'>
              <h2>Starter</h2>
              <div className='price'>
                <span className='amount'>
                  <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.starterPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </span>
                <span className='extra'>/mo.</span>
              </div>
              <ol className='features customList'>
                <li><span>{siteSettings.starterFee * 100}% Fee</span></li>
                <li><span>1 Theme</span></li>
                <li><span>Gallery with <NumberFormat decimalScale={0} fixedDecimalScale={false} value={siteSettings.starterLimit} displayType={'text'} thousandSeparator={true} /> images</span></li>
                <li>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale={false} value={siteSettings.starterCollectionsLimit} displayType={'text'} thousandSeparator={true} />
                    {siteSettings.starterCollectionsLimit > 1 ? ' Collections' : ' Collection'}
                  </span>
                </li>
              </ol>
              <Button size='huge' color='primary' className='signupButton' as={Link} to='/signup'>Signup</Button>
            </div>
            <div className='box'>
              <h2>Pro</h2>
              <div className='price'>
                <span className='amount'>
                  <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.premiumPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </span>
                <span className='extra'>/mo.</span>
              </div>
              <ol className='features customList'>
                <li><span>{siteSettings.premiumFee * 100}% Fee</span></li>
                <li><span>3 Themes</span></li>
                <li><span>Gallery with <NumberFormat decimalScale={0} fixedDecimalScale={false} value={siteSettings.premiumLimit} displayType={'text'} thousandSeparator={true} /> images</span></li>
                <li>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale={false} value={siteSettings.premiumCollectionsLimit} displayType={'text'} thousandSeparator={true} />
                    {siteSettings.premiumCollectionsLimit > 1 ? ' Collections' : ' Collection'}
                  </span>
                </li>
                <li><span>Limited Edition prints</span></li>
              </ol>
              <Button size='huge' color='primary' className='signupButton' as={Link} to='/signup'>Signup</Button>
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default React.memo(HomePage)
