import React from 'react'
import {
  consoleLogger,
} from '@Utils'

// COMPONENTS
import { ResultGroup } from './Components'

// STYLES
import { Container } from './SearchResultsStyles'

const SearchResults = (props) => {
  const {
    results,
    handleSearchModal
  } = props

  return (
    <Container className='searchResults'>
      {results && results.map(data => (
        <ResultGroup data={data} handleSearchModal={handleSearchModal} />
      ))}
    </Container>
  )
}

export default React.memo(SearchResults)
