import React from 'react'
import { Table } from 'semantic-ui-react'
import NumberFormat from 'react-number-format'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

import {StyledTable} from './Styles'

const SignupPackageTable = () => {
  const { authenticated } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  return (
    <StyledTable className='packageTable'>
      <Table compact basic="very" className="table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="packageTableHeader blank"></Table.HeaderCell>
            <Table.HeaderCell className="packageTableHeader starter">{`${siteSettings.starterPackageName} `}</Table.HeaderCell>
            <Table.HeaderCell className="packageTableHeader featured">{`${siteSettings.premiumPackageName} `}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="packageTableCell packageTableItem"><span className='text--bold'>Gallery</span></Table.Cell>
            <Table.Cell className="packageTableCell starter">Gallery with {siteSettings.starterLimit} images</Table.Cell>
            <Table.Cell className="packageTableCell featured">Gallery with {siteSettings.premiumLimit} images</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="packageTableCell packageTableItem"><span className='text--bold'>Collections</span></Table.Cell>
            <Table.Cell className="packageTableCell starter">{siteSettings.starterCollectionsLimit} {siteSettings.starterCollectionsLimit > 1 ? 'Collections' : 'Collection'}</Table.Cell>
            <Table.Cell className="packageTableCell featured">{siteSettings.premiumCollectionsLimit} {siteSettings.premiumCollectionsLimit > 1 ? 'Collections' : 'Collection'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="packageTableCell packageTableItem"><span className='text--bold'>Themes</span></Table.Cell>
            <Table.Cell className="packageTableCell starter">
              <p>1 Theme</p>
            </Table.Cell>
            <Table.Cell className="packageTableCell featured">
              <p>
                3 Themes<br />
                + customizable color scheme (coming soon!)
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="packageTableCell packageTableItem"><span className='text--bold'>Limited Edition Prints</span></Table.Cell>
            <Table.Cell className="packageTableCell starter">No</Table.Cell>
            <Table.Cell className="packageTableCell featured">Yes</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="packageTableCell packageTableItem"><span className='text--bold'>Price</span></Table.Cell>
            <Table.Cell className="packageTableCell starter">
              <p>
                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.starterPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per Month
              </p>
            </Table.Cell>
            <Table.Cell className="packageTableCell featured">
              <p>
                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.premiumPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per Month
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </StyledTable>
  )
}

export default SignupPackageTable
