import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CartVendorContainer = styled('div')`
  position: relative;
  .vendorCart {
    position: relative;
    /* .vendorCartHeader */
    &Header {
      position: sticky;
      top: 0;
      z-index: 3;
      margin: 0;
      background-color: #f2f2f2;
      
      > .row {
        padding: 0;
        .header {
        }
      }
      > .label {
        padding: 0;
        margin-top: 0.65rem;
        margin-right: 0.5rem;
        z-index: 2;
      }
      /* .vendorCartHeaderName */
      &Name {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .avatar {
          margin: 0;
          margin-right: 1rem;
          width: 3rem;
          height: 3rem;
          box-sizing: border-box;
          object-fit: cover;
          img {
            width: 88%;
            height: 88%;
          }
        }
        h3 {
          display: inline-flex;
          align-items: center;
          height: 100%;
          line-height: 1;
          margin: 0;
        }
      }
      /* .vendorCartHeaderLinks */
      &Links {
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: right;

        @media ${stylevars.breakpoints.phone} {
          padding-top: 0;
          padding-bottom: 0;
        }
        
        a {
          font-size: 0.8rem !important;
          font-weight: normal;
        }
      }
      /* .vendorCartHeaderCoupon */
      &Coupon {
        .label {
          span {
            margin: 0 0.2rem;
            &:first-of-type {
              font-weight: bold;
            }
          }
        }
      }

      .form {
        display: inline-block;
      }
    }
    /* .vendorCartSubHeader */
    &SubHeader {
      margin-bottom: 1rem;
      .row {
        padding: 0;
      }

      .backToGallery {
        @media ${stylevars.breakpoints.phone} {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
      .couponField {
        @media ${stylevars.breakpoints.phone} {
          width: 100%;
        }
      }
    }
    .items {
      margin: 0;
    }
    

  }
`