import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// import axios from 'axios'
// import cn from 'classnames'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  Header,
  Divider,
  Tab,
} from 'semantic-ui-react'
import OrdersTable from './OrdersTable'

// STYLES
import { StyledContainer } from './OrdersStyles.js'

const OrdersPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tab } = useParams()
  const { authenticated, userInfo } = useSelector(getUserState)
  const [isReady, setIsReady] = React.useState(false)

  const redirect = encodeURIComponent(`/admin/account`)

  const creatorPanes = [
    {
      menuItem: 'All',
      render: () => (
        <Tab.Pane>
          <OrdersTable status='all' />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'To Pay',
      render: () => (
        <Tab.Pane>
          <OrdersTable status='topay' />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'To Ship',
      render: () => (
        <Tab.Pane>
          <OrdersTable status='toship' />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'To Receive',
      render: () => (
        <Tab.Pane>
          <OrdersTable status='torecieve' />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Completed',
      render: () => (
        <Tab.Pane>
          <OrdersTable status='completed' />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Cancelled',
      render: () => (
        <Tab.Pane>
          <OrdersTable status='cancelled' />
        </Tab.Pane>
      ),
    },
  ]
  
  React.useEffect(() => {
  
    dispatch(uiSlice.actions.setPageTemplate('dash'))
    dispatch(uiSlice.actions.setCurrentPage(`orders`))
    dispatch(uiSlice.actions.setCurrentPageURL(''))
    dispatch(uiSlice.actions.setCurrentPageTitle(''))
    
    if (!authenticated) {
      history.push(`/login/redirect/${redirect}`)
    }
    setIsReady(true)
    // set tab
    setActiveIndex(tab ? tabSlugs.indexOf(tab) : 0)
    return(() => {})
  }, [history, authenticated, redirect, tab, userInfo.role]) // eslint-disable-line

  const [activeIndex, setActiveIndex] = React.useState(0)

  const tabSlugs = ['all', 'to-pay', 'to-ship', 'to-receive', 'completed', 'cancelled']

  const handleTabChange = (ev, data) => {
    // consoleLogger(data)
    history.push(`/admin/orders/${tabSlugs[data.activeIndex]}`)
  }

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Orders</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/admin/orders`} />
      </Helmet>
      <div>
        <Header as='h1'>
          Orders
        </Header>
        <Divider className='clearBottom' />
        
        <div className='content'>
          {isReady ? 
            // <Tab className='subPageMenu' menu={{ secondary: true, pointing: true }} panes={creatorPanes} onTabChange={handleTabChange} activeIndex={activeIndex} /> : 
            <div className='wrapper'>
              <OrdersTable />
            </div> : 
            <div>Loading...</div>
          }
        </div>
      </div>
    </StyledContainer>
  )
}

export default OrdersPage
