import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const DocumentContainer = styled.div`

  position: relative;
  user-select: none;
  pointer-events: none;

  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter/Inter-Regular.ttf');
  }
  @font-face {
    font-family: 'Merriweather';
    src: url('/fonts/Merriweather/Merriweather-Regular.ttf');
  }
  background-color: #ffffff;
  width: 210mm;
  height: 297mm;
  font-size: 10pt;
  font-family: 'Inter' !important;

  .sampleOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/sample-overlay.png');
  }

  .nullOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @page {
    size: A5;
    margin: 0;
  }
  @media print {
    html, body {
      font-size: 10pt;
      width: 210mm;
      height: 297mm;
      /* height: initial !important; */
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      font-smooth: alway !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .page {
    background-color: #ffffff;
    padding: 2rem;
    page-break-after: always;
    width: 210mm;
    height: 297mm;
    font-family: 'Inter' !important;
    position: relative;
  }

  .header {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    text-align: center;
    font-family: 'Inter' !important;
    page-break-after: none;
  }

  .logo {
    width: 180px;
    height: auto;
  }

  .documentTitle {
    text-align: center;
    font-size: 2em;
    margin: 2rem 0;
    font-family: 'Merriweather' !important;
  }

  .documentSubtitle {
    text-align: center;
    font-size: 1.25em;
    margin: 2rem 0;
    font-family: 'Inter' !important;
  }

  p {
    font-size: 1em;
    font-family: 'Inter' !important;
  }

  .productDetailsTable {
    width: 100%;
    padding: 0;
    margin: 0;
    td {
      padding: 0rem;
      width: 50%;
      vertical-align: top;
      font-family: 'Inter' !important;
    }

    .productDetails {
      padding-left: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      height: 458px;

      .productDetailsFields {
        flex-grow: 1;
        width: 100%;
      }
      .productDetailsQR {
        width: 100%;
        margin-top: 1.5rem;
        > .field {
          margin: 0;
        }
      }
    }
  }


  .productImage {
    background-color: rgba(0,0,0,0.035);
    height: 458px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    img {
      width: 100%;
      object-fit: contain;
      object-position: center center;
      flex-grow: 1;
      max-height: 406px;
    }
  }

  .lotNumber {
    font-size: 2em;
    text-align: center;
    /* font-weight: bold; */
    padding: 1rem 0;
    /* font-family: 'Inter' !important; */
    font-family: 'Merriweather' !important;
  }

  .productTitle {
    font-size: 1.25em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
    font-family: 'Inter' !important;
  }

  .field {
    display: flex;
    justify-content: flex-start;
    align-items; center;
    flex-wrap: nowrap;
    line-height: 1.6;
    margin-bottom: 0.75rem;
    font-size: 1em;
    font-family: 'Inter' !important;

    .label {
      width: 30%;
      font-family: 'Inter' !important;
    }
    .value {
      width: 70%;
      border-bottom: 1px solid rgba(0,0,0,0.25);
      font-family: 'Inter' !important;
    }
    .labelFull {
      width: 100%;
      font-family: 'Inter' !important;
    }

    .qrCode {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        width: 140px;
        height: 140px;
      }
    }
    .qrCodeLabel {
      margin-left: 1rem;
      width: calc(100% - 140px - 1rem);
      .instruction {
        color: rgba(0,0,0,0.5);
        font-size: 0.85em;
      }
      .certID {
        word-break: break-all;
      }
    }
  }

  .signatureTable {
    width: 100%;
    td {
      padding: 1rem;
      width: 50%;
      vertical-align: bottom;
    }

    .date {
      text-align: center;
      font-family: 'Inter' !important;
      font-size: 1.25em;
      font-family: 'Merriweather' !important;
    }
    .dateLabel {
      text-align: center;
      border-top: 1px solid rgba(0,0,0,0.25);
      padding-top: 1rem;
      margin-top: 1rem;
      text-align: center;
      font-weight: bold;
      font-size: 1.25em;
      font-family: 'Inter' !important;
    }
  }

  .signatureBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .signatureWrapper {
      width: 100%;
      text-align: center;

      img {
        width: 220px;
        height: 100px;
        object-fit: contain;
      }
    }

    .fullName {
      width: 100%;
      border-top: 1px solid rgba(0,0,0,0.25);
      padding-top: 1rem;
      margin-top: 1rem;
      text-align: center;
      font-weight: bold;
      font-size: 1.25em;
      font-family: 'Inter' !important;
    }
  }

  .documentExtras {
    p {
      font-size: 1em;
      line-height: 1.6;
      margin: 1rem 0;
      font-family: 'Inter' !important;
      span {
        display: block;
      }
    }
  }

  table, img, svg {
    break-inside: avoid;
  }

  .footer {
    margin-top: 1rem;
    padding: 0.5rem 0;
    background-color: rgba(0,0,0,0.15);
    font-size: 0.65em;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: calc(100% - 2rem);

    .footerTable {
      width: 100%;
      td {
        width: 50%;
        text-align: center;
        @media print {
          font-size: 8pt;
        }
      }
    }
    .styleURL {
      display: inline-block;
      text-align: center;
    }
  }
`;
