export const stylevars = {
  palette: {
    primary: {
      main: '#ffba08',
      light: '#f1e6af',
      contrastText: '#ffffff',
      rgb: '255,186,8',
    },
    secondary: {
      main: '#e43f6f',
      light: '#f56476',
      contrastText: '#ffffff',
      rgb: '228,63,111',
    },
    success: {
      main: '#9bc53d',
      light: '#fde74c',
      contrastText: '#ffffff',
      rgb: '155,197,61',
    },
    info: {
      main: '#5e60ce',
      light: '#5390d9',
      contrastText: '#ffffff',
      rgb: '94,96,206',
    },
    warning: {
      main: '#e76f51',
      light: '#f4a261',
      contrastText: '#ffffff',
      rgb: '231,111,81',
    },
    danger: {
      main: '#ff686b',
      light: '#ffa69e',
      contrastText: '#ffffff',
      rgb: '255,104,107',
    },
    pending: {
      main: '#c9c9c9',
      light: '#d3d3d3',
      contrastText: '#ffffff',
      rgb: '201,201,201',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    title: {
      color: '#1f1f1f',
      colorRGB: '31,31,31'
    },
    subtitle: {
      color: '#9c9c9c',
      colorRGB: '156,156,156'
    },
    extra: {
      color: '#d3d3d3',
      colorRGB: '211,211,211'
    },
  },
  paper: {
    bgColor: '#ffffff',
    bodyColor: '#F4F7F6',
    borderColor: '#e0e0e0',
    borderColorRGB: '224,225,226',
  },
  navBar: {
    height: '60px',
    heightInt: 60,
    bgColor: '#ffffff',
    linkBgColor: {
      main: 'rgba(0,0,0,0)',
      hover: 'rgba(0,0,0,0.1)',
      active: '#ffba08',
    },
    textColor: {
      main: '#333333',
      hover: '#333333',
      active: '#ffffff'
    },
    iconColor: {
      main: '#333333',
      hover: '#ffba08',
      active: '#ffffff',
    },
  },
  sideBar: {
    width: '220px',
    widthInt: 200,
    bgColor: '#333333',
    linkColor: {
      main: 'rgba(255,255,255,0)',
      hover: 'rgba(255,255,255,0.1)',
      active: '#ffba08',
    },
    textColor: {
      main: '#ffffff',
      hover: '#ffba08',
      active: '#ffffff',
    },
    iconColor: {
      main: '#ffba08',
      hover: '#ffba08',
      active: '#ffffff',
    },
  },
  gallery: {
    gap: '5px',
  },
  themes: {
    sideBarWidth: '400px',
  },
  breakpoints: {
    phone: 'only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px)',
    tablet: 'only screen and (min-device-width:768px) and (max-device-width:1024px), (min-width:768px) and (max-width: 1024px)',
    laptop: 'only screen and (min-width:800px) and (max-width:1440px)',
    desktop: 'only screen and (min-width:1440px)',
    portrait: 'and (orientation: portrait)',
    landscape: 'and (orientation: landscape)',
  },
  imageCDN: 'https://sfv1cdn1.sgp1.digitaloceanspaces.com',
}