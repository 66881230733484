import { createSlice } from '@reduxjs/toolkit'

const posts = createSlice({
  name: 'posts',
  initialState: {
    posts: [],
  },
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload
    },
  },
  devTools: false
})


export const getPostsState = (state) => state.posts

export default posts