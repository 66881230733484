import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import he from 'he'
import { Helmet } from 'react-helmet'
import { titleCase } from 'title-case'
import { consoleLogger, checkBrightness } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import vendorSlice, { getVendorState } from '@Slices/vendorSlice'

// COMPONENTS
import { MuuriComponent } from 'muuri-react';
import Masonry from 'react-masonry-css'
import {
  // Grid,
  Dimmer,
  Loader,
  Button,
  // Icon,
  // Placeholder,
  // Item,
  // Header,
} from 'semantic-ui-react'
import Viewer from './Viewer'
import { CollectionItem } from '../../Pages/Collections/Components'
import { GeneralPreloader } from '@Common'

// STYLES
import { CollectionsContainer } from './CollectionsStyles'

const Collections = (props) => {
  const token = localStorage.Authorization
  const { mode, noHelmet } = props
  const dispatch = useDispatch()
  const { vendorInfo, vendorCollections } = useSelector(getVendorState)
  const { page } = useParams()

  const [ ready, setReady ] = React.useState(false)

  const brightness = checkBrightness(vendorInfo.acf.custom_stylesheet.bgColor)

  React.useEffect(() => {
    setReady(true)
  }, []) // eslint-disable-line

  return (
    <>
      <CollectionsContainer className='collectionsMain' themeType={brightness < 125 ? 'dark' : 'light'}>
        {ready ?
          <>
            {vendorCollections.filter(i => i.active).map(collection => (
              <CollectionItem classname='item' key={collection.id} {...collection} vendorInfo={vendorInfo} mode={mode} noHelmet={noHelmet} />
            ))}
          </> : 
          <GeneralPreloader title='Loading Collections...' />
        }
      </CollectionsContainer>
      {!noHelmet &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{vendorInfo.name} - {titleCase(page)}</title>
          <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/@${vendorInfo.username}/${page}`} />
        </Helmet>
      }
    </>
  )
}

export default React.memo(Collections)
