import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledGeneralPreloader = styled.div`
  /* width: 100vw;
  height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.85);

  > .logo {
    width: 100%;
    max-width: 180px;
    height: auto;
  }

  > .title {
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.6;
  }

  > .content {
    margin-top: 1rem;
  }
`

export const StyledPagePreloader = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 240px;

  &.linear {
    justify-content: flex-start;

    .progress {
      width: 100%;
    }
  }

  > .logo {
    width: 100%;
    max-width: 180px;
    height: auto;
  }

  > .title {
    padding-top: 1rem;
    border-top: 1px solid ${stylevars.paper.borderColor};
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.6;
  }

  > .content {
    background-color: rgba(0,0,0,0.05);
    /* color: ${stylevars.paper.borderColor}; */
    margin: 1rem;
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;
    max-width: 600px;
  }
`