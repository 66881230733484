import React from 'react'
import NumberFormat from 'react-number-format'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { consoleLogger, priceFormat } from '@Utils'
import { v4 as uuidv4 } from 'uuid'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
// import { getVendorState } from '@Slices/vendorSlice'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'
import cartSlice, { getCartState } from '@Slices/cartSlice'
import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import { Button, Confirm, Dimmer, Grid, Header, Icon, Item, Loader } from 'semantic-ui-react'
import CartItem from './CartItem'
import SFAvatar from '@Common/Avatar'

// STYLES
import { CartVendorContainer } from './CartVendorStyles'

const CartVendor = (props) => {
  const {
    vendor,
    vIndex,
    handleCartError,
    handleCartItemCount,
    handleTotalLoaded,
    handleProcessing
   } = props
  const dispatch = useDispatch()
  const [ items, setItems ] = React.useState()
  const [ processing, setProcessing ] = React.useState(false)
  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    setReady(true)
  }, []) // eslint-disable-line

  return (
    <CartVendorContainer>
        <>
          {processing &&
            <Dimmer inverted active>
              <Loader>Updating...</Loader>
            </Dimmer>
          }
          <div className='vendorCart' key={`${vendor.id}-${vendor.slug}`}>
            <Grid className='vendorCartHeader'>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8} className='vendorCartHeaderName'>
                  <SFAvatar className='avatar' width='7rem' height='7rem' userInfo={{acf: {profile_picture: vendor.profile_picture, subscription_type: vendor.subscription_type}}} />
                  <Header as='h3'>
                    {vendor.name}
                  </Header>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} className='vendorCartHeaderLinks'>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid className='vendorCartSubHeader'>
              <Grid.Row>
                <Grid.Column mobile={16} computer={8}>
                  <Button
                    className='backToGallery'
                    as={Link}
                    to={`/@${vendor.slug}/gallery`}
                    basic
                    color='blue'
                    icon='reply'
                    labelPosition='left'
                    content={`Back to ${vendor.name}'s Gallery`}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} computer={8} textAlign='right'>
                  {/* <VendorCoupon
                      vendor={vendor}
                  /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Item.Group divided>
              {vendor.items.map((item, index) => (
                <CartItem
                  key={`${vendor.id}-${index}-${item.id}-${item.slug}`}
                  item={item}
                  index={index}
                  vendor={vendor}
                  vIndex={vIndex}
                  handleCartError={handleCartError}
                  handleCartItemCount={handleCartItemCount}
                  handleTotalLoaded={(ev) => handleTotalLoaded(ev)}
                  handleProcessing={handleProcessing}
                />
              ))}
            </Item.Group>
          </div>
        </>
    </CartVendorContainer>
  )
}

export default React.memo(CartVendor)
