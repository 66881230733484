import React from 'react'
import { useHistory, useParams } from 'react-router'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  Button,
  Divider,
  Header,
  Icon,
  Message,
  Tab,
} from 'semantic-ui-react'
import { DashToolbar, GeneralPreloader } from '@Common'

// TAB CONTENT
import ProfileDisplay from './ProfileDisplay'

// STYLES
import StyleLayoutContainer from './LayoutStyles'
import Biography from './Biography'
import Themes from './Themes'

const LayoutPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isReady, setIsReady] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const { tab } = useParams()
  const { authenticated, userInfo } = useSelector(getUserState)

  const tabSlugs = ['profile', 'biography', 'themes']

  const redirect = encodeURIComponent(`/admin/layout`)

  React.useEffect(() => {
    // setIsReady(true)
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (!authenticated) {
      history.push(`/login/redirect/${redirect}`)
    } else {
      setIsReady(true)
    }
  }, [authenticated]) // eslint-disable-line

  React.useEffect(() => {
    dispatch(userSlice.actions.updateAccountCompletion({
      hasProfilePhoto: !!userInfo.acf.profile_picture,
      hasCoverPhoto: !!userInfo.acf.background_image,
    }))
  }, [userInfo]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
  
      dispatch(uiSlice.actions.setPageTemplate('dash'))
      dispatch(uiSlice.actions.setCurrentPage('layout'))
      dispatch(uiSlice.actions.setCurrentPageURL())
      dispatch(uiSlice.actions.setCurrentPageTitle())
      setActiveIndex(tab ? tabSlugs.indexOf(tab) : 0)
    })()
  }, [dispatch, tab]) // eslint-disable-line

  const [previewOpen, setPreviewOpen] = React.useState(false)
  const handleTogglePreview = (bool) => {
    setPreviewOpen(bool)
  }

  const handleTabChange = (ev, data) => {
    // consoleLogger(data)
    // const tabSlugs = ['sales', 'billing', 'information', 'security']
    history.push(`/admin/layout/${tabSlugs[data.activeIndex]}`)
  }

  const layoutPanes = [
    {
      menuItem: 'Profile',
      render: () => (
        <Tab.Pane>
          <ProfileDisplay />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Biography',
      render: () => (
        <Tab.Pane>
          <Biography />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Themes',
      render: () => (
        <Tab.Pane>
          <Themes />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <StyleLayoutContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Layout</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/admin/layout`} />
      </Helmet>
      {isReady ?
        <>
          <DashToolbar title='Layout' noGap className='noGap' />
          <Tab className='subPageMenu' menu={{ secondary: true, pointing: true }} panes={layoutPanes} onTabChange={handleTabChange} activeIndex={activeIndex} />
        </> : 
        <GeneralPreloader />
      }
    </StyleLayoutContainer>
  )
}

export default React.memo(LayoutPage)
