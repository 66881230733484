import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const ProfileContainer = styled('div')`
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media ${stylevars.breakpoints.phone} {
    padding: 0;
  }
  
  .profileContent {
    width: 100%;
    max-width: 720px;
    border-radius: 1rem;
    background-color: var(--sfProfile_profileBGColor);
    color: var(--sfProfile_profileTextColor);
    overflow: hidden;
    padding: 2rem;

    .quill {
      &.viewOnly {
        .ql-toolbar {
          display: none;
        }
        .ql-container {
          border: none;
          .ql-editor {
            padding: 0;
            p {
              font-size: 1rem;
              line-height: 1.6;
            }
          }
        }

        iframe {
          width: 100% !important;
          height: calc(min(calc(var(--vw, 1vw) * 40), 350px));
        }
      }
      .ql-editor {
        > * {
          margin-bottom: 1rem;
        }
      }
    }
  }
`