import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('section')`
  transition: all 0.15s ease-out;
  overflow: hidden;
  border-radius: 0.5rem;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  .link {
    overflow: hidden;
    position: relative;
    display: block;
    width: 100%;
    height: calc(min(calc(var(--vh, 1vh) * 30), 240px));

    .title {
      position: absolute;
      padding: 1rem;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
      width: calc(100%);
      max-width: calc(100%);
      min-height: 4rem;
      bottom: 0rem;
      left: 0;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: lighter;
      line-height: 1;
      z-index: 2;
      text-align: center;
      transition: bottom 0.15s ease-out;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .avatar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      width: 8rem;
      height: 8rem;
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform 0.3s ease-out;
      }
    }
  }
`