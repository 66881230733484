import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom' // eslint-disable-line
import NumberFormat from 'react-number-format'
import { findIndex, nth } from 'lodash'
// import { Helmet } from 'react-helmet'
import { consoleLogger, priceFormat, maskEmail, containsObject } from '@Utils'
import { format, formatDistanceToNow } from 'date-fns'
import cn from 'classnames'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
import cartSlice, { getCartState } from '@Slices/cartSlice' // eslint-disable-line
import axios from 'axios'
// import { getGalleryState } from '@Slices/gallerySlice' // eslint-disable-line

// COMPONENTS
import {
  Button,
  Header,
  Icon,
  Label,
  Segment,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Item,
  Image,
} from 'semantic-ui-react'
import { SalesItem } from './Components'

// STYLES
import StyledStoreCartContainer from '../../StorePages/Cart/StoreCartStyles'

const lookup = require('country-code-lookup')

const AccountTransactionView = (props) => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const { itemId, salesId } = props
  const [ isReady, setIsReady ] = React.useState(false)
  const [ salesInfo, setSalesInfo ] = React.useState()
  const [ salesItem, setSalesItem ] = React.useState()
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  // const { checkout } = useSelector(getCartState)
  
  const statusArray = [
    {
      key: '0',
      label: 'PENDING',
    },
    {
      key: '1',
      label: 'PROCESSING',
      color: 'blue',
    },
    {
      key: '2',
      label: 'CLEARED',
      color: 'green',
    },
    {
      key: '3',
      label: 'CANCELLED',
      color: 'orange',
    },
    {
      key: '4',
      label: 'UNKNOWN',
      color: 'red',
    },
  ]

  React.useEffect(() => {
    (async() => {
      if (!isReady) {
        if (salesId) {
          consoleLogger('=================== START =======================')
          let tempInfo
          let tempItem
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/sales/${salesId}`,
            method: 'GET',
            params: {
              author: userInfo.id,
              user_key: userKey,
            }
          }).then(res => {
            // itemId = res.data.acf.sku.split('-')[1]
            consoleLogger('SALES DETAILS RESPONSE ===========', res.data)
            tempInfo = {
              sales_id: salesId,
              order_id: res.data.acf.order_id,
              transaction_id: res.data.acf.transaction_id,
              customer_info: typeof res.data.acf.customer_info === 'string' ? JSON.parse(res.data.acf.customer_info) : res.data.acf.customer_info,
              vendor_info: typeof res.data.acf.vendor_info === 'string' ? JSON.parse(res.data.acf.vendor_info) : res.data.acf.vendor_info,
              payment_status: res.data.acf.payment_status,
              amount: res.data.acf.amount,
              quantity: res.data.acf.quantity,
              fees: res.data.acf.fees,
              fee_rate: res.data.acf.fee_rate,
              coupon: res.data.acf.coupon,
              total_amount: typeof res.data.acf.total_amount === 'string' ? JSON.parse(res.data.acf.total_amount) : res.data.acf.total_amount,
              sku: res.data.acf.sku,
              status: res.data.acf.status,
              itemId: itemId,
              item_name: res.data.acf.item_name,
              item_description: res.data.acf.item_description,
              post_date: res.data.post_date,
              post_modified: res.data.post_modified,
              post_date_epoch: res.data.post_date_epoch,
              post_modified_epoch: res.data.post_modified_epoch,
            }
            // tempInfo = res.data
          }).catch(err => {
            consoleLogger(err)
          })
          
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/gallery/${itemId}`,
            method: 'GET',
          }).then(res => {
            consoleLogger('ACTUAL ITEM DETAILS RESPONSE ===========', res.data)
            tempItem = {
              id: res.data.ID,
              ID: res.data.ID,
              title: res.data.post_title,
              description: '',
              post_author: res.data.post_author,
              author_slug: res.data.user.slug,
              post_name: res.data.post_name,
              media: res.data.media
            }
            // tempItem = res.data
          }).catch(err => {
            consoleLogger(err)
          })

          setSalesInfo(tempInfo)
          setSalesItem(tempItem)
          setIsReady(true)

          consoleLogger('============ END ==================')
        }
      }
    })()
  }, [salesId]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (salesInfo) {
        consoleLogger('========== salesInfo ============', salesInfo)
      }
    })()
  }, [salesInfo])

  React.useEffect(() => {
    (async () => {
      if (salesItem) {
        consoleLogger('========== salesItem ============', salesItem)
      }
    })()
  }, [salesItem])

  return (
    <>
      {!isReady ?
        <Dimmer active inverted>
          <Loader inverted>Loading Sales Information...</Loader>
        </Dimmer> : 
        <StyledStoreCartContainer className='orderStatusViewContent'>
          <>
            <Header as='h2' className='orderStatus_header'>
              <span></span>
              <div className='orderStatus_status'>
                <span className='text--bold'>STATUS:</span>
                <Label color={statusArray.filter(i => i.key === salesInfo.status).map(n => n.color)} size='large' className={cn('orderStatus_label', {
                  [statusArray.filter(i => i.key === salesInfo.status).map(n => n.label)]: salesInfo.status
                })}>{statusArray.filter(i => i.key === salesInfo.status).map(n => n.label)}</Label>
              </div>
            </Header>
            <Divider />
            <Segment className='cart'>
              <Grid divided className='cartContainer'>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={11} className='cartItems'>
                    <div className='cartItemsContainer'>
                      <div className='vendorCart'>
                        <Item.Group divided>
                          {salesItem ?
                            <>
                              <SalesItem info={salesInfo} item={salesItem} />
                            </> : 
                            <>Loading...</>
                          }
                        </Item.Group>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5} className='cartSummary'>
                    <div className='cartSummaryContainer'>
                      <Header as='h3'>Sales Information</Header>
                      <Divider />
                      <Grid>
                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <p>Sales ID:</p>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <p className='text--bold'>{salesInfo.sales_id}</p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <p>Order ID:</p>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <p className='text--bold'>{salesInfo.order_id}</p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <p>Post Date:</p>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <p className='text--bold'>{format(new Date(salesInfo.post_date_epoch * 1000), 'yyyy-MM-dd')}</p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <p>Last Updated:</p>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <p><div className='text--bold'>{format(new Date(salesInfo.post_modified_epoch * 1000), 'yyyy-MM-dd')}</div> <div>({formatDistanceToNow(new Date(salesInfo.post_modified_epoch * 1000), {addSuffix: true})})</div></p>
                          </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <p>Status:</p>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <p className='text--bold'>{statusArray.filter(i => i.key === salesInfo.status).map(n => n.label)}</p>
                          </Grid.Column>
                        </Grid.Row>
                        
                        <Divider />
                        
                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <p>Fees:</p>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <Header as='h4'>
                              <NumberFormat
                                value={salesInfo.fees}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                              {` `}
                              <small className='text--light'>({Number(salesInfo.fee_rate) * 100}%)</small>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                          <Grid.Column computer={6}>
                            <Header as='h2'>Total</Header>
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <Header as='h2'>
                              <NumberFormat
                                value={salesInfo.total_amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        {/* <Divider />
                        <Grid.Row>
                          <Grid.Column computer={16}>
                            <Button as='a' href='/checkout' fluid positive size='large' icon='arrow right' content='Proceed to Checkout' labelPosition='right' />
                          </Grid.Column>
                        </Grid.Row> */}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        </StyledStoreCartContainer>
      }
    </>
  )
}

export default React.memo(AccountTransactionView)
