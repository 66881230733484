import React from 'react'
// import axios from 'axios'
// import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  // Icon,
  // Table,
  // Button,
  // Checkbox,
  // Modal,
  // Grid,
  Pagination,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  Dropdown,
} from 'semantic-ui-react'

const TablePagination = (props) => {
  const { info } = props
  const dispatch = useDispatch() // eslint-disable-line
  // const { userInfo, userCoupons } = useSelector(getUserState) // eslint-disable-line
  // const { uiFilters, uiTables } = useSelector(getUIState) // eslint-disable-line
  
  // const [ pageNumber, setPageNumber ] = React.useState(uiTables.filter(i => i.component === info.namespace)[0].currentPage)
  // const [ totalPages, setTotalPages ] = React.useState(Math.ceil(userCoupons.length / 5))

  // React.useEffect(() => {
  //   setTotalPages(Math.ceil(userCoupons.length / uiTables.filter(i => i.component === info.namespace)[0].pageSize))
  // }, [uiTables, userCoupons])

  const handlePageChange = (ev, {activePage}) => {
    consoleLogger(activePage)
    // setPageNumber(activePage)
    dispatch(uiSlice.actions.updateUITables({component: info.namespace, pageNumber: activePage}))
  }

  return (
    <>
      <Pagination
        defaultActivePage={info.pageNumber}
        onPageChange={handlePageChange}
        totalPages={info.totalPages}
        size='tiny'
      />
    </>
  )
}

export default React.memo(TablePagination)
