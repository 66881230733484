import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const PopularItemsContainer = styled('section')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  padding-bottom: 4rem;

  .wrapper {
    padding: 0 2rem;
    width: 100%;
    max-width: 1480px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .sectionTitle {
    font-size: 2rem;
    font-weight: 400;
    margin: 2rem 0;
  }
  .gridList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    position: relative;

    @media ${stylevars.breakpoints.tablet} {
      grid-template-columns: repeat(3, 1fr);
    }
    @media ${stylevars.breakpoints.phone} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`