import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Header, Divider, Dimmer, Loader, Modal } from 'semantic-ui-react'
import { CollectionsModal } from '.'
import { DashToolbar, PagePreloader } from '@Common'
import { Collections } from './Components'

// STYLES
import { CollectionsContainer } from './CollectionsStyles'

const CollectionsPage = (props) => {
  const { vendorInfo, mode, noHelmet } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { userInfo, userKey, userGallery, userCollections, authenticated } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  const [isReady, setIsReady] = React.useState(false)
  const { id } = useParams()

  const fetchCollections = async () => {
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/collections`,
      method: 'GET',
      params: {
        author: userInfo.id,
        user_key: userKey
      }
    }).then(res => {
      dispatch(userSlice.actions.setCollections(res.data))
    })
    .catch(err => {
      consoleLogger(err)
    })
    setIsReady(true)
  }

  React.useEffect(() => {
    (async () => {
      dispatch(uiSlice.actions.setPageTemplate('dash'))
      dispatch(uiSlice.actions.setCurrentPage('collections'))
      dispatch(uiSlice.actions.setCurrentPageURL())
      dispatch(uiSlice.actions.setCurrentPageTitle())
      if (!authenticated) {
        const encodedPath = encodeURIComponent(`/collections`)
        history.push(`/login/redirect/${encodedPath}`)
      } else {
        // await fetchCollections()
        setIsReady(true)
      }
    })()
  }, []) // eslint-disable-line

  const [ collectionsLimit, setCollectionsLimit ] = React.useState()
  const [ reachedLimit, setReachedLimit ] = React.useState(true)
  React.useEffect(() => {
    setCollectionsLimit(userInfo.acf.subscription_type === 'premium' ? siteSettings.premiumCollectionsLimit : siteSettings.starterCollectionsLimit)
    // setReady(true)
  }, []) // eslint-disable-line

  React.useEffect(() => {
    setReachedLimit(userInfo.acf.subscription_type === 'premium' ? false : userCollections.length >= collectionsLimit)
  }, [userCollections, collectionsLimit]) // eslint-disable-line
  
  const collectionsToolbarActions = [
    {
      key: 'newCollectionButton',
      name: 'newCollectionButton',
      className: 'newCollectionButton',
      type: 'button',
      icon: 'plus',
      color: 'blue',
      label: 'New Collection',
      text: 'New Collection',
      callback: () => {
        history.push('/admin/collections/new')
      },
      disabled: reachedLimit,
    }
  ]

  return (
    <CollectionsContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Collections</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/admin/collections`} />
      </Helmet>
      {!isReady ?
        <PagePreloader /> : 
        <>
          <DashToolbar title='Collections' subtitle={<><div className='size'><span className='label'>Size:</span><span className='value'>{userCollections.length} of <span className='text--bold'>{collectionsLimit}</span></span></div></>} actions={collectionsToolbarActions} />
          <Collections collections={userCollections} mode='edit' vendorInfo={userInfo} mode={mode} noHelmet={noHelmet} />
        </>
      }
    </CollectionsContainer>
  )
}

export default React.memo(CollectionsPage)
