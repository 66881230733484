import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import { currencySymbols } from '@Utils/forexData'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// COMPONENTS
import { Grid, Item, Image, Placeholder } from 'semantic-ui-react'

// STYLES
import { OrderItemContainer } from './ItemStyles'

const OrderItem = (props) => {
  const {
    item
  } = props

  const [ ready, setReady ] = React.useState(false)
  const handleAfterLoad = () => {
    setReady(true)
  }

  return (
    <OrderItemContainer>
      <div className='wrapper'>
        <div className='image'>
          <Link to={`/@${item.author_slug}/gallery/${item.id}`}>
            <LazyLoadImage
              delayMethod='debounce'
              delayTime={1000}
              visibleByDefault={true}
              placeholder={
                <Placeholder style={{ height: 150, width: 150 }}>
                  <Placeholder.Image />
                </Placeholder>
              }
              wrapperClassName='imageWrapper'
              afterLoad={(ev) => {
                handleAfterLoad(ev)
              }}
              effect="blur"
              alt={item.post_title}
              src={item.media.medium}
            />
          </Link>
        </div>
        <div className='details'>
          <div className='title'>
            <Link to={`/@${item.author_slug}/gallery/${item.id}`}>
              <h4>{item.name}</h4>
            </Link>
            <p>{item.description}</p>
          </div>
          <div className='info'>
            <p className='info-item'>
              <span className='label'>Price</span>
              <NumberFormat
                value={item.unit_amount.value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currencySymbols[item.unit_amount.currency_code]}`}
                decimalScale={2}
                fixedDecimalScale
              />
            </p>
            <p className='info-item'>
              <span className='label'>Quantity</span>
              <NumberFormat
                value={item.quantity}
                displayType={'text'}
                thousandSeparator={true}
                suffix='x'
                decimalScale={0}
              />
            </p>
            <p className='info-item'>
              <span className='label'>Amount</span>
              <NumberFormat
                value={item.quantity * item.unit_amount.value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currencySymbols[item.unit_amount.currency_code]}`}
                decimalScale={2}
                fixedDecimalScale
              />
            </p>
          </div>
        </div>
      </div>
    </OrderItemContainer>
  )
}

export default React.memo(OrderItem)
