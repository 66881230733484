import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {useRefresh, getResponsiveStyle} from 'muuri-react';
import NumberFormat from 'react-number-format';
import { consoleLogger } from '@Utils'
import shortNumber from 'short-number'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import {
  Button,
  Icon,
  Dimmer,
  Loader,
  Popup,
  Modal,
  Placeholder
} from 'semantic-ui-react'

// STYLES
import { GalleryItemContainer } from './GalleryItemStyles'
import 'react-lazy-load-image-component/src/effects/blur.css';

const GalleryItem = (props) => {
  const {
    id,
    post,
    gdata,
    cid,
    fetchGallery,
    draggable,
    handleProcess,
    handleSelect,
    saveData,
    handleDataValue
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.Authorization
  const [ ready, setReady ] = React.useState(false)
  const { userInfo, userKey, userGallery, userCollections } = useSelector(getUserState)
  const { galleryCanArrange } = useSelector(getUIState)
  const [ isProcessing, setIsProcessing ] = React.useState(false)
  const handleAfterLoad = () => {
    setReady(true)
  }

  const [ collectionSnapshot, setCollectionSnapshot ] = React.useState(userCollections.filter(n => n.id.toString() === cid.toString()).map(i => i.gallery_data)[0])

  const handleCollectionItemRemove = async (gid) => {
      // handleProcess(true)

      setIsProcessing(true)
      setIsDeleting(true)
      // const tempGallery = collectionSnapshot
      const tempGallery = gdata
      consoleLogger('============= tempGallery =================', tempGallery)
      const results = tempGallery.filter(n => n.id !== gid)
      consoleLogger('============= RESULTS =====================', results)
      handleDataValue(results)
      setCollectionSnapshot(results)
      // await axios({
      //   baseURL: `${process.env.REACT_APP_API_URL}`,
      //   url: `/stylefolio/v1/collections/${cid}`,
      //   method: 'POST',
      //   headers: {
      //     Authorization:`${token}`,
      //   },
      //   data: {
      //     author: userInfo.id,
      //     user_key: userKey,
      //     gallery_data: results
      //   }
      // }).then((res) => {
      //   consoleLogger('handleUpdateOrder RESPONSE =============', res.data)
      //   dispatch(userSlice.actions.updateUserCollection(res.data))
      //   // handleSetProcess(id, false)
        
      //   toast.warning(`Collection content updated.`, {
      //     containerId: 'mainToaster',
      //     progress: undefined,
      //   });
      // }).catch(err => {
      //   consoleLogger(err)
      //   // handleSetProcess(id, false)
      // })

      setIsDeleting(false)
      setOpenCollectionModal(false)
      setIsProcessing(false)

      // handleProcess(false)
  }

  const handleEditItem = () => {
    history.push(`/admin/gallery/view/${post.id}`)
  }

  const [ isDeleting, setIsDeleting ] = React.useState(false)

  const [ openCollectionModal, setOpenCollectionModal ] = React.useState(false)
  const handleOpenCollectionModal = (bool) => {
    setOpenCollectionModal(bool)
  }

  React.useEffect(() => {
    return(() => {
      setReady(false)
    })
  })
  
  useRefresh([ready, userGallery, userCollections, post])
  // const responsiveStyle = getResponsiveStyle({
  //   // The Muuri component is virtually divided into 8 columns,
  //   // the width of the item will be 3 columns (minus the margin).
  //   columns: 2 / 8,
  //   // The margin of the item, it can be any CSS values
  //   // valid for the margin expressed in "px" or "%".
  //   // margin: '5%',
  //   // The width/height ratio. If you want to set a static
  //   // height just set the "height" option in px and remove the "ratio".
  //   ratio: 1.75,
  // });

  return (
    <GalleryItemContainer
      key={post.id}
      className={cn("item", {
        'arrange': draggable,
        'publish': post.post_status === "publish"
      })}
    >
      <div className="item-wrapper">
        <div className="item-content">
          <div className="handle">
            <Icon name="arrows alternate" />
          </div>
          <div className="item-details">
            <Dimmer active={isProcessing} className="preloader">
              <Loader size="massive" />
            </Dimmer>
            <div className='topRight'>
              <div className='itemViews'>
                <Icon name="eye" />
                <span>{shortNumber(post.acf.pageviews)}</span>
              </div>
              <div className='itemViews'>
                <Icon name="like" />
                <span>{shortNumber(post.acf.likes.length)}</span>
              </div>
              {!!post.acf.limited_edition &&
                <div className='limitedEdition'>
                  <Icon name="tag" />
                  <span>
                    <NumberFormat
                      value={post.acf.sold_copies}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale={false}
                    />
                    {` of `}
                    <NumberFormat
                      value={post.acf.number_lots}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale={false}
                    />
                  </span>
                </div>
              }
            </div>
            <div className={cn("showInGallery")}>
              <Popup
                trigger={
                  <Button
                    circular
                    type='button'
                    icon={"minus"}
                    color={"red"}
                    onClick={() => {
                      handleOpenCollectionModal(true)
                    }}
                  />
                }
                content={`Remove from collection`}
                position='bottom center'
                inverted
              />
            </div>
            <div className={cn("itemInfo itemActions")}>
              <Button
                icon="pencil"
                type='button'
                labelPosition="right"
                content={<span>Edit</span>}
                color="blue"
                onClick={handleEditItem}
              />
            </div>
            <div className={cn("itemInfo itemFooter")}>
              <div className="name">
                {post.post_title}
              </div>
              <div className="price">
                <NumberFormat
                  value={post.acf.base_price}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            </div>
          </div>
          <LazyLoadImage
            delayMethod='debounce'
            delayTime={1000}
            visibleByDefault={true}
            placeholder={
              <Placeholder style={{ height: 150, width: 150 }}>
                <Placeholder.Image />
              </Placeholder>
            }
            wrapperClassName='imageWrapper'
            afterLoad={(ev) => {
              handleAfterLoad(ev)
            }}
            effect="blur"
            alt={post.post_title}
            src={post.media.medium}
          />
        </div>
      </div>
      {openCollectionModal &&
        <Modal
          size='mini'
          open={openCollectionModal}
          dimmer={{
              blurring: true
          }}
          onOpen={() => handleOpenCollectionModal(true)}
          onClose={() => handleOpenCollectionModal(false)}
        >
          <Modal.Header>
            Removing Collection Item
          </Modal.Header>
          <Modal.Content scrolling>
            <p>You are about to remove <span className="text--bold">{post.post_title}</span> from your collection. Are you sure you want to continue?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type='button'
              basic
              disabled={isDeleting}
              onClick={() => handleOpenCollectionModal(false)}
            >
              Cancel
            </Button>
            <Button
              type='button'
              content={`Delete`}
              labelPosition='right'
              icon={`check`}
              onClick={() => handleCollectionItemRemove(post.id)}
              negative
              loading={isDeleting}
              disabled={isDeleting}
            />
          </Modal.Actions>
        </Modal>
      }
    </GalleryItemContainer>
  )
}

export default React.memo(GalleryItem)