import React from 'react'
import cn from 'classnames'
import ReactQuill from 'react-quill'
import { debounce } from 'lodash'

// REDUX
import { useSelector, useDispatch } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line

// FORMIK
import { Formik, Form, Field, FieldArray, useFormikContext} from 'formik';
import * as Yup from 'yup'

// COMPONENTS
import { Button, Dimmer, Divider, Grid, Header, Loader, Segment } from 'semantic-ui-react'
import PhotoUploader from './Components/PhotoUploader'
import ThemeSelection from './Components/ThemeSelection'
import { CoverPhoto } from '../Collections/Components'

const ProfileDisplay = () => {
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { userInfo, userKey } = useSelector(getUserState)
  // const { pageScrolled } = useSelector(getUIState)
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    setIsReady(true)
  }, []) // eslint-disable-line

  return (
    <>
      <div className='profile_theme_selection'>
        <Header as='h3'>
          Profile Photo and Cover
        </Header>
        <Divider />
        {isReady ?
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16} className='display_col'>
                <PhotoUploader type='profile' defaultImage={userInfo.acf.profile_picture ? userInfo.acf.profile_picture : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_photo.jpg`} width={250} height={250} className='display_profile' />
                <PhotoUploader type='cover' defaultImage={userInfo.acf.background_image ? userInfo.acf.background_image : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`} width={700} height={250} className='display_cover' />
              </Grid.Column>
            </Grid.Row>
          </Grid> : 
          <>Loading...</>
        }
      </div>
    </>
  )
}

export default React.memo(ProfileDisplay)
