import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const StyledContainer = styled.div`
  padding: 2rem;
  width: 100%;
  position: relative;

  @media ${stylevars.breakpoints.phone} {
    padding: 1rem;
  }

  .galleryEmpty {
    margin-top: 0rem;
    text-align: center;
  }

  .galleryGridContainer {
    position: relative;
    min-height: 240px;
  }

  .galleryGrid {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    /* margin-top: 4rem; */

    @media ${stylevars.breakpoints.phone} {
      /* margin-top: 5rem; */
    }

    .item {
    }
  }
`;
