import styled from "styled-components"
import { stylevars } from '@Common/StyleVars'

export const StyledContainer = styled('div')`
  padding: 2rem;

  .dashboardWidgets {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(180px, 1fr));
    grid-gap: 0.5rem;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    @media ${stylevars.breakpoints.laptop} {
      grid-template-columns: repeat(2, minmax(180px, 1fr));
    }
    @media ${stylevars.breakpoints.tablet} {
      grid-template-columns: repeat(2, minmax(180px, 1fr));
    }
    @media ${stylevars.breakpoints.phone} {
      grid-template-columns: repeat(1, minmax(180px, 1fr));
    }

    &.full {
      grid-template-columns: repeat(1, minmax(180px, 1fr));
    }

    &.col3 {
      grid-template-columns: repeat(3, minmax(180px, 1fr));
      @media ${stylevars.breakpoints.laptop} {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
      }
      @media ${stylevars.breakpoints.tablet} {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
      }
      @media ${stylevars.breakpoints.phone} {
        grid-template-columns: repeat(1, minmax(180px, 1fr));
      }
    }

    .widget {
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: ${stylevars.paper.bgColor};
      transition: all 0.15s ease-out;

      &:hover {
        z-index: 3;
        box-shadow: 0 0.25rem 0.5rem 0.25rem rgba(0,0,0,0.1);
      }

      /* .widgetWrapper */
      &Wrapper {
        padding: 1rem;
        min-height: 8rem;

        > .label {
          font-size: 1rem;
          width: 100%;
          display: block;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      .content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        grid-gap: 0.5rem;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
      }
      .icon {
      }
      .value {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        font-size: 3rem;
        line-height: 3rem;
        margin-left: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .symbol {
          font-size: 2rem;
          line-height: 3rem;
          color: rgba(0, 0, 0 ,0.5);
        }
        .amount {
          letter-spacing: -0.25rem;
          padding: 0 0.2rem;
        }
        .float {
          padding-right: 0.2rem;
          font-size: 1.5rem;
          line-height: 1.85rem;
          letter-spacing: -0.1rem;
        }
      }
      .galleryItem {
        width: 100%;
        position: relative;
        padding: 0;
        margin: 0;
        a {
          display: block;
        }
        img {
          display: block;
          width: 100%;
          height: 320px;
          object-fit: cover;
        }
        .label {
          position: absolute;
          top: 1rem;
          left: -1rem;
        }
        .index {
          position: absolute;
          left: 1rem;
          top: 1rem;
          width: 2rem;
          height: 2rem;
          border-radius: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.85);
          font-size: 0.75rem;
          line-height: 1;
        }
        .pageviews {
          position: absolute;
          top: 1rem;
          right: 1rem;
          color: white;
          padding: 0.5rem 1rem;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 0.5rem;
        }
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
`