import React from 'react'
import axios from 'axios'
import format from 'date-fns/format';
import { createLocalStorageStateHook } from 'use-local-storage-state'


export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const setAuthorizationHeader = (token) => {
  const Authorization = `Bearer ${token}`
  localStorage.setItem('Authorization', Authorization)
  axios.defaults.headers.common['Authorization'] = Authorization
}

export const truncate = (str, length = 45) => {
  return str.length > length ? str.substring(0, (length - 3)) + "..." : str;
}


export const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const getPrintType = (id, array) => {
  return array.filter(i => Number(i.id) === Number(id))[0]
}  
export const getPrintMaterial = (id, array) => {
  return array.filter(i => Number(i.id) === Number(id)).map(i => i)[0]
}

export const getPrintSize = (id, array) => {
  return array.filter(i => Number(i.id) === Number(id)).map(o => o)[0]
}

export const getAspectRatio = (ratio) => {
  const aspectRatioOptions = [
    {
      key: 'standard-2x3',
      value: '2x3',
      text: 'Standard Aspect Ratio (2:3)',
    },
    {
      key: 'graphics-4x3',
      value: '4x3',
      text: 'Graphics Aspect Ratio (4:3)',
    },
    {
      key: 'panorama-1x3',
      value: '1x3',
      text: 'Panorama Aspect Ratio (1:3)',
    },
    {
      key: 'square-1x1',
      value: '1x1',
      text: 'Square Aspect Ratio (1:1)',
    },
  ]
  return aspectRatioOptions.filter(i => i.value.toString() === ratio.toString()).map(a => a)[0]
}

export const priceFormat = (num) => {
  const tnum = Number(num)
  return Math.round(tnum * 100) / 100
}

export const cleanPrice = (num) => {
  const snum = num.toString()
  let xnum = snum
  if (snum.includes('.')) {
    const n = snum.split('.')
    xnum = n[0]
  }
  const numLength = xnum.length
  // consoleLogger("============ numLength ============", numLength)
  const placement = 10 ** Math.floor(numLength/3)
  return priceFormat(Math.ceil(Number(num)/placement)*placement)
}

export const MaskCharacter = (str, mask, n = 1) => {
  // Slice the string and replace with
  // mask then add remaining string
  return ('' + str).slice(0, -n)
    .replace(/./g, mask)
    + ('' + str).slice(-n);
}

export const maskEmail = (email = '') => {
  const [name, domain] = email.split('@');
  const { length: len } = name;
  const maskedName = name[0] + '******' + name[len - 3] + name[len - 2] + name[len - 1];
  const maskedEmail = maskedName + '@' + domain;
  return maskedEmail;
}

export const containsObject = (needle, haystack) => {
  for (let i = 0; i < haystack.length; i++) {
    if (haystack[i] === needle) {
      return true;
    }
  }
  return false;
}

export const getWeekNumber = (d) => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

export const get7Days = () => {
  let result = [];
  for (let i=0; i<7; i++) {
    let d = new Date();
    d.setDate(d.getDate() - i);
    result.push( format(d, 'MM/dd/yyyy') )
  }
  return(result);
}

export const checkBrightness = (val) => {
  const rgb = val.split(',')
  const result = Math.round(((parseInt(rgb[0]) * 299) +
                      (parseInt(rgb[1]) * 587) +
                      (parseInt(rgb[2]) * 114)) / 1000)
  return result
}

export const stripHTML = (str) => {
  return str.replace(/(<([^>]+)>)/gi, "")
}


export const useSiteCurrency = createLocalStorageStateHook('sf_currency', {
  name: 'US Dollar',
  currency: 'USD',
  symbol: '$',
  value: 1
})



export const consoleLogger = (...args) => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  if (process.env.REACT_APP_SITE_DEBUG === 'true') {
    if (args.length === 4) {
      console.log(args[0], args[1], args[2], args[3])
    } else if (args.length === 3) {
      console.log(args[0], args[1], args[2])
    } else if (args.length === 2) {
      console.log(args[0], args[1])
    } else if (args.length === 1) {
      console.log(args[0])
    }
  }
  return null
}


export const getTotalCartItems = (items) => {
  let total = 0
  items.forEach(item => {
    total += item.quantity
  })
  return total > 99 ? `99` : total
}

export const getVendorSlug = (id, cartData) => {
  consoleLogger('getVendorSlug ID', id, cartData)
  return cartData && cartData.length > 0 ? cartData.filter(cart => cart.id.toString() === id.toString()).map(i => i.slug) : 'aaaaaaa'
}

export const getTypeName = (id, data) => {
  return data && data.filter(i => i.id.toString() === id.toString()).map(o => o.name)
}

export const getTypeSize = (id, data) => {
  return data && data.filter(i => i.id.toString() === id.toString()).map(o => o.acf.size)
}

export const getMaterialTypeThumnail = (id, data) => {
  return `${data.filter(i => i.id === id).map(o => o.acf.image)}`
}

export const leadZeros = (num, length) => {
  let l = length
  if (typeof l !== 'number') {
    l = 12
  }
  String(num).padStart(l, '0');
}

export function roundTo(n, d) {
  return Math.floor((n + d - 1) / d) * d;
}

export const countryOptions = [
  {
    key: 'philippines',
    value: 'PH',
    text: 'Philippines'
  },
  {
    key: 'australia',
    value: 'AU',
    text: 'Australia'
  },
  {
    key: 'belgium',
    value: 'BE',
    text: 'Belgium'
  },
  {
    key: 'brazil',
    value: 'BR',
    text: 'Brazil'
  },
  {
    key: 'brunei',
    value: 'BN',
    text: 'Brunei'
  },
  {
    key: 'canada',
    value: 'CA',
    text: 'Canada'
  },
  {
    key: 'denmark',
    value: 'DK',
    text: 'Denmark'
  },
  {
    key: 'egypt',
    value: 'EG',
    text: 'Egypt'
  },
  {
    key: 'finland',
    value: 'FI',
    text: 'Finland'
  },
  {
    key: 'france',
    value: 'FR',
    text: 'France'
  },
  {
    key: 'germany',
    value: 'DE',
    text: 'Germany'
  },
  {
    key: 'greece',
    value: 'GR',
    text: 'Greece'
  },
  {
    key: 'hongkong',
    value: 'HK',
    text: 'Hong Kong'
  },
  {
    key: 'india',
    value: 'IN',
    text: 'India'
  },
  {
    key: 'indonesia',
    value: 'ID',
    text: 'Indonesia'
  },
  {
    key: 'italy',
    value: 'IT',
    text: 'Italy'
  },
  {
    key: 'israel',
    value: 'IL',
    text: 'Israel'
  },
  {
    key: 'japan',
    value: 'JP',
    text: 'Japan'
  },
  {
    key: 'malaysia',
    value: 'MY',
    text: 'Malaysia'
  },
  {
    key: 'new-zealand',
    value: 'NZ',
    text: 'New Zealand'
  },
  {
    key: 'poland',
    value: 'PL',
    text: 'Poland'
  },
  {
    key: 'portugal',
    value: 'PT',
    text: 'Portugal'
  },
  {
    key: 'russia',
    value: 'RU',
    text: 'Russia'
  },
  {
    key: 'singapore',
    value: 'SG',
    text: 'Singapore'
  },
  {
    key: 'slovakia',
    value: 'SK',
    text: 'Slovakia'
  },
  {
    key: 'south-africa',
    value: 'ZA',
    text: 'South Africa'
  },
  {
    key: 'south-korea',
    value: 'KR',
    text: 'South Korea'
  },
  {
    key: 'spain',
    value: 'ES',
    text: 'Spain'
  },
  {
    key: 'sweden',
    value: 'SE',
    text: 'Sweden'
  },
  {
    key: 'switzerland',
    value: 'CH',
    text: 'Switzerland'
  },
  {
    key: 'taiwan',
    value: 'TW',
    text: 'Taiwan'
  },
  {
    key: 'thailand',
    value: 'TH',
    text: 'Thailand'
  },
  {
    key: 'united-arab-emirates',
    value: 'UAE',
    text: 'United Arab Emirates'
  },
  {
    key: 'united-kingdom',
    value: 'UK',
    text: 'United Kingdom'
  },
  {
    key: 'united-states',
    value: 'US',
    text: 'United States'
  },
  {
    key: 'vatican-city-state',
    value: 'VA',
    text: 'New Zealand'
  },
  {
    key: 'vietnam',
    value: 'VN',
    text: 'Vietnam'
  },
]