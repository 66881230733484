import React from 'react'
import { Link, useHistory } from 'react-router-dom'

// REDUX
import { useDispatch } from 'react-redux'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  // Grid,
  Button,
} from 'semantic-ui-react'

// STYLES
import { Styled404Container, StyledCard } from './404Styles'

const PageNotFound = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('404'))
    return(() => {})
  }, [dispatch])

  return (
    <Styled404Container>
      <div className='section hero'>
        <div className='section_wrapper'>
          <div className='hero_context'>
            <StyledCard>
              <div className='card_wrapper'>
                <h1>Oops! Something's wrong...</h1>
                <h3>We can't seem to find the page you're looking for. It's either the page you're looking for no longer exists on our system or you've entered the wrong address.</h3>
                <Button
                  to={'/'}
                  as={Link}
                  primary
                  size='large'
                  className='callToAction'
                >
                  Go back to Home page
                </Button>
                <Button
                  basic
                  size='large'
                  className='callToAction'
                  onClick={() => history.goBack()}
                >
                  Go back to previous page
                </Button>
              </div>
            </StyledCard>
          </div>
        </div>
      </div>
    </Styled404Container>
  )
}

export default PageNotFound