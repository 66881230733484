import React from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import { DashToolbar } from '@Common'
import { WidgetSales, WidgetSalesLatest, WidgetGalleryCount, WidgetPageviews, WidgetGalleryPopular, WidgetEmpty } from './Components'

// STYLES
import { StyledContainer } from './Styles'
import { Message } from 'semantic-ui-react'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const { userInfo, userGallery, collections } = useSelector(getUserState)
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('dash'))
    dispatch(uiSlice.actions.setCurrentPage('dashboard'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      // await axios.get(`${process.env.REACT_APP_API_URL}/wp/v2/sf-collections?_embed&author=${userInfo.id}`)
      //   .then(res => {
      //     dispatch(userSlice.actions.setCollections(res.data))
      //   })
      //   .catch(err => {
      //     consoleLogger(err)
      //   })
    })()
      .then(() => {
        setIsReady(true)
      })
  }, [dispatch, userInfo, setIsReady])

  consoleLogger(collections)

  return (
    <StyledContainer>
      {!isReady ? (
        <div>loading...</div>
      ) : (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{process.env.REACT_APP_PROJECT_NAME} - Dashboard</title>
            <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/admin/dashboard`} />
          </Helmet>
          <DashToolbar title='Dashboard' />
          <div className='dashboardWidgets'>
            <WidgetSales />
            <WidgetSalesLatest />
            <WidgetGalleryCount />
            <WidgetPageviews />
          </div>
          <div className='dashboardWidgets full'>
            {userGallery.length <= 0 ?
              <WidgetEmpty /> :
              <>
                <WidgetGalleryPopular length={userGallery.length >= 3 ? 6 : 1} />
              </>
            }
          </div>
        </div>
      )}
    </StyledContainer>
  )
}

export default React.memo(DashboardPage)
