import React from 'react'
import Helmet from 'react-helmet'

// REDUX
import { useDispatch } from 'react-redux'
import uiSlice from '@Slices/uiSlice'

// STYLES
import { StyledContainer } from './Styles'

const AboutPage = () => {
  const dispatch = useDispatch()
  const [ ready, setReady ] = React.useState(false)
  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage(`about ${process.env.REACT_APP_PROJECT_NAME}`))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
    // const token = localStorage.Authorization
    setReady(true)
  }, []) // eslint-disable-line

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - About</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/about`} />
      </Helmet>
      <div className='container'>
        { ready ?
          <>
            <h1>About {process.env.REACT_APP_PROJECT_NAME}</h1>
            <p>{process.env.REACT_APP_PROJECT_NAME} is a web printing platform that simplifies the creator's print selling process to it's collectors and at the same time can function as a full gallery system for artists.</p>
          </> : 
          <>Loading...</>
        }
      </div>
    </StyledContainer>
  )
}

export default React.memo(AboutPage)
