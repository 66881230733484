import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const OrderItemContainer = styled('div')`
  &:not(:last-of-type) {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${stylevars.paper.borderColor};
  }
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }
    @media print {
      display: flex;
      calc(100vh - ${stylevars.navBar.height} - 4rem);
    }

    .image {
      min-width: 40%;
      max-width: 40%;
      @media ${stylevars.breakpoints.phone} {
        max-width: 100%;
        margin-bottom: 1rem;
      }
      @media print {
        max-width: 40%;
        margin-bottom: 0rem;
      }
      img {
        width: 100%;
      }
    }
    .details {
      flex-grow: 1;
      margin-left: 1rem;

      .title {
        h4 {
          font-size: 1.25rem;
          font-weight: bold;
          margin: 0;
          margin-bottom: 1rem;
          padding: 0;
        }
        p {
          font-size: 1rem;
          font-weight: normal
          margin: 0;
          margin-bottom: 1rem;
          padding: 0;
        }
      }

      .info {
        background-color: rgba(0,0,0,0.05);
        padding: 1rem;
        border-radius: 0.25rem;

        .info-item {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 1.6;

          &:not(:last-of-type) {
            margin-bottom: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid rgba(0,0,0,0.05);
          }

          .label {
            width: min(120px, 40%);
            font-size: 0.85rem;
          }
          .value {
            font-size: 1rem;
          }
        }
      }
    }
  }
`