import React from 'react'
import NumberFormat from 'react-number-format'
import { format, formatDistanceToNow, parse } from 'date-fns'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import {
  Grid,
  Button,
  Divider,
} from 'semantic-ui-react'
import { UpgradePlanButton } from './Components'

const AccountInformationOverview = () => {
  const { userInfo, userKey } = useSelector(getUserState)

  const subscriptionLabelArray = {
    starter: "Starter",
    premium: "Premium",
  }
  
  return (
    <>
      <h3>Account Details</h3>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <div className="field">
              <label>Username</label>
              <div className='fieldDisplay'>{userInfo.username}</div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {userInfo.role === "sf_creator" &&
          <Grid.Row>
            <Grid.Column mobile={16} computer={16}>
              <div className="field">
                <label>{process.env.REACT_APP_PROJECT_NAME} Gallery URL</label>
                <div className="ui icon input">
                  <div className='fieldDisplay withIcon'>
                    <span>{`${window.location.origin}/`}<strong>@{`${userInfo.username}`}</strong></span>
                    <a
                      href={`${window.location.origin}/@${userInfo.username}`}
                      target="_blank"
                      className=" fieldDisplayIcon"
                      rel="noopener noreferrer"
                    >
                      <i className="external link icon blue" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <div className="field">
              <label>Email Address</label>
              <div className='fieldDisplay'>{userInfo.email}</div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {userInfo.role === "sf_creator" &&
          <>
            <Grid.Row>
              <Grid.Column mobile={16} computer={16}>
                <div className="field">
                  <label>Pageviews</label>
                  <div className='fieldDisplay'>
                    <NumberFormat value={userInfo.acf ? userInfo.acf.pageview_count : 0} displayType={'text'} thousandSeparator={true} />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} computer={16}>
                <div className="field">
                  <label>Subscription</label>
                  <div className='fieldDisplay'>
                    <Grid divided>
                      <Grid.Row>
                        <Grid.Column computer={8} mobile={16}>
                          <div>Subscription Type</div>
                          <div className='text--bold'>{subscriptionLabelArray[userInfo.acf.subscription_type]}</div>
                          {userInfo.acf.subscription_type === 'starter' &&
                            <UpgradePlanButton />
                          }
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                          <div>Subscription ID</div>
                          <div className='text--bold'>{userInfo.acf.subscription_id}</div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column computer={8} mobile={16}>
                          <div>Start Date</div>
                          <div className='text--bold'>{format(new Date(userInfo.acf.subscription_start.replace(/ /g, "T")), 'MMM dd yyyy')}</div>
                          <small>{formatDistanceToNow(new Date(userInfo.acf.subscription_start.replace(/ /g, "T")), {addSuffix: true})}</small>
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                          <div>Due Date</div>
                          <div className='text--bold'>{format(new Date(userInfo.acf.subscription_end.replace(/ /g, "T")), 'MMM dd yyyy')}</div>
                          <small>{formatDistanceToNow(new Date(userInfo.acf.subscription_end.replace(/ /g, "T")), {addSuffix: true})}</small>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </>
        }
      </Grid>
    </>
  )
}

export default AccountInformationOverview
