import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.text {
    margin: 0;
    display: inline-block;
  }

  .link {
    color: ${stylevars.palette.info.main};
    transition: all 0.15s ease-out;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;

    &:hover {
      color: #fff;
      background-color: ${stylevars.palette.info.main};
    }
  }
`