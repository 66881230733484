import React from 'react'
import cn from 'classnames'
import { consoleLogger } from '@Utils'

// COMPONENTS
import { Button, Modal } from 'semantic-ui-react'

// STYLES
import { CollectionsModalContainer } from './CollectionsModalStyles'
import { CollectionsForm } from '.'

const CollectionsModal = (props) => {
  const { data, open, modalHandler } = props

  const [ steps, setSteps ] = React.useState(0)
  const handleSteps = () => {
    setSteps(1)
  }

  React.useEffect(() => {
    consoleLogger('====================== DATA ====================', data)
  }, [data]) // eslint-disable-line

  React.useEffect(() => {
    return(() => {
      setSteps(0)
    })
  }, []) // eslint-disable-line
  return (
    <>
      <Modal
        open={open}
        dimmer={{
            blurring: true
        }}
        onOpen={() => modalHandler(true)}
        onClose={() => modalHandler(false)}
        className={cn('collectionsFormModal', {
          'sfFullscreen': steps === 1
        })}
      >
        <Modal.Content>
          <CollectionsModalContainer className='collectionsModalContainer'>
            <CollectionsForm data={data} />
          </CollectionsModalContainer>
        </Modal.Content>
        <Modal.Actions>
          <>
            <Button icon='close' content='Cancel' labelPosition='right' basic onClick={() => modalHandler(false)}/>
            <Button icon='check' content='Save Settings' labelPosition='right' positive onClick={handleSteps} />
          </>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default React.memo(CollectionsModal)
