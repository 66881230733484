import React from "react";
import { format } from "date-fns";
import { consoleLogger } from "@Utils";
import QRCode from "react-qr-code";

// STYLES
import { DocumentContainer } from "./CertificateTemplateStyles";

const CertificateTemplate = React.forwardRef((props, ref) => {
  const { data, sample } = props;

  const [ready, setReady] = React.useState(false);

  const [productBase64, setProductBase64] = React.useState();
  const [signatureBase64, setSignatureBase64] = React.useState();
  const [logoBase64, setLogoBase64] = React.useState();
  React.useEffect(() => {
    (async () => {
      setReady(true);
    })();
  }, [data, sample]); // eslint-disable-line

  return (
    <DocumentContainer ref={ref}>
      {ready && (
        <>
          <div className="page">
            <div className={sample ? "sampleOverlay" : "nullOverlay"} />
            <div className="header">
              <img
                src={`/logo_light.png`}
                className="logo"
                alt="stylefolio logo"
              />
            </div>
            <h1 className="documentTitle">Certificate of Authenticity</h1>
            <p className="documentSubtitle">
              This certifies that the work identified beneath is an original
              Limited Edition print and guarantees all descriptions and
              information to be exact.
            </p>
            <table className="productDetailsTable">
              <tr>
                <td>
                  <div className="productImage">
                    <img src={data.productImage} alt={data.productTitle} />
                    <div className="lotNumber">
                      {data.copyNumber}/{data.productEdition}
                    </div>
                  </div>
                </td>
                <td style={{ position: "relative" }}>
                  <div className="productDetails">
                    <div className="productDetailsFields">
                      <h2 className="productTitle">{data.productTitle}</h2>
                      <div className="field">
                        <div className="label">Artist</div>
                        <div className="value">{data.artistName}</div>
                      </div>
                      <div className="field">
                        <div className="label">Edition</div>
                        <div className="value">{data.productEdition}</div>
                      </div>
                      <div className="field">
                        <div className="label">A/P</div>
                        <div className="value">{data.artistProof}</div>
                      </div>
                      <div className="field">
                        <div className="label">Print Year</div>
                        <div className="value">{data.printYear}</div>
                      </div>
                      <div className="field">
                        <div className="label">Paper Size</div>
                        <div className="value">{data.paperSize}</div>
                      </div>
                      <div className="field">
                        <div className="label">Image Size</div>
                        <div className="value">{data.imageSize}</div>
                      </div>
                      <div className="field">
                        <div className="label">Paper Type</div>
                        <div className="value">{data.paperType}</div>
                      </div>
                      {/* <div className='field'>
                        <div className='labelFull'>{data.printerDetails}</div>
                      </div>
                      <div className='field'>
                        <div className='labelFull'>{data.printerDetails2}</div>
                      </div> */}
                    </div>
                    <div className="productDetailsQR">
                      <div className="field">
                        <div className="qrCode">
                          <QRCode
                            value={
                              sample
                                ? `${process.env.REACT_APP_DOMAIN}`
                                : `${process.env.REACT_APP_DOMAIN}/certificate/${data.certificateKey}`
                            }
                            size={140}
                          />
                          <div className="qrCodeLabel">
                            <div className="instruction">
                              Scan to verify certificate online.
                            </div>
                            <div className="certID">{data.certificateID}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="documentExtras">
              <p>
                <span>
                  Optimally viewed in <strong>3600k</strong> full spectrum
                  light.
                </span>
                <span>
                  Limited to an Edition of{" "}
                  <strong>{data.productEdition}</strong> with{" "}
                  <strong>{data.artistProof} A/P</strong> Worldwide.
                </span>
              </p>
              {data.cameraSensor && data.cameraSensor !== "na" ? (
                <p>
                  The photograph was made with an{" "}
                  <strong>{data.cameraSensor}</strong> digital camera and
                  processed digitally before being printed according to gallery
                  standards.
                </p>
              ) : (
                <p>
                  This photograph was processed digitally before being printed
                  according to gallery standards.
                </p>
              )}
              <p>
                To take care of your print, do not expose to direct ultraviolet
                light.
              </p>
            </div>

            <table className="signatureTable">
              <tr>
                <td>
                  <div className="signatureBox">
                    <div className="signatureWrapper">
                      {data.artistSignature ? (
                        <img
                          src={data.artistSignature}
                          alt="creator signature"
                        />
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                    <div className="fullName">{data.artistName}</div>
                  </div>
                </td>
                <td>
                  <div className="date">
                    {format(
                      new Date(data.certificateDate),
                      "do 'of' MMMM, yyyy"
                    )}
                  </div>
                  <div className="dateLabel">Date</div>
                </td>
              </tr>
            </table>

            <div className="footer">
              <table className="footerTable">
                <tr>
                  <td>
                    Copyright &copy; {data.printYear}{" "}
                    {process.env.REACT_APP_PROJECT_NAME} - All rights reserved.
                  </td>
                  <td>
                    <a href={process.env.REACT_APP_DOMAIN} className="styleURL">
                      www.stylefol.io
                    </a>{" "}
                    |{" "}
                    <a href="mailto:hello@stylefolio.app" className="styleURL">
                      hello@stylefolio.app
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </>
      )}
    </DocumentContainer>
  );
});

export default React.memo(CertificateTemplate);
