import React from 'react'
import axios from 'axios'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// COMPONENTS
import {
  Grid,
  Button,
  Form as SUIForm,
  Message,
  Modal,
  Loader,
  Dimmer
} from 'semantic-ui-react'

const validationSchema = Yup.object().shape({
  payout_method: Yup.string()
    .required('Must select a payout method'),
  paypal_email: Yup.string()
    .email('Invalid email')
    .required('Must enter an email'),
  payout_minimum: Yup.number()
    .min(100, 'Must not be less than 100')
    .required('Must enter a payout minimum'),
})

const AccountPayoutSettingsForm = (props) => {
  const {
    open,
    handler
  } = props
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { authenticated, userInfo, userKey } = useSelector(getUserState)

  const payoutMethodOptions = [
    {
      key:'paypal-method',
      value:'paypal',
      text:'PayPal'
    },
  ]

  return (
    <>
      <Formik
        initialValues={{
          payout_method: userInfo.acf.payout_method,
          paypal_email: userInfo.acf.paypal_email,
          payout_minimum: userInfo.acf.payout_minimum,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          (async () => {
            // let token
            // const newData = data
            // newData.jwt_auth_token_before_dispatch = 
            setSubmitting(true)

            const newData = {
              ...data,
              user_key: userKey
            }
            
            consoleLogger("FORM DATA ON SUBMIT =============================", data)

            // REGISTER NEW USER
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/stylefolio/v1/users/${userInfo.id}`,
              headers: {
                Authorization: `${token}`,
              },
              method: 'POST',
              data: newData
            })
              .then(res => {
                consoleLogger('PAYOUT SETTINGS RESPONSE ========================', res.data)
                dispatch(userSlice.actions.setUserInfo({...res.data}))
              })
              .catch(err => {
                setSubmitting(false)
                const errorData = err.response.data
                consoleLogger("ERROR: ", errorData)
                // dispatch(userSlice.actions.setUserInfo({}))
                // dispatch(userSlice.actions.setAuthenticated(false))
                if (errorData.code === 'invalid_amount') {
                  setErrors({payout_minimum: 'Payout minimum should not be lower than 100.'})
                } else {
                  setErrors(errorData)
                }
              })
              setSubmitting(false)
              handler(false)
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setValues, setFieldValue, errors, isValid, dirty, values}) => (
          <Form className='ui form'>
            
            <Modal
              size='mini'
              onClose={() => handler(false)}
              onOpen={() => handler(true)}
              open={open}
              dimmer={{
                  blurring: true
              }}
              closeOnDimmerClick={!isSubmitting}
            >
              <Modal.Header>Payout Settings</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description>
                  <div className='payoutSettingsForm'>
                    {isSubmitting &&
                      <Dimmer active inverted>
                        <Loader />
                      </Dimmer>
                    }
                    <SUIForm.Select name='payout_method' fluid placeholder='Payout Method' label='Payout Method *'
                      className='billingDropdown'
                      onChange={(ev, {value}) => {
                        // handleBillingAddressChange(value)
                        setFieldValue('payout_method', value)
                      }}
                      defaultValue={userInfo.acf.payout_method}
                      options={payoutMethodOptions}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input name='paypal_email' fluid placeholder='Paypal Email Address' type='text' label='PayPal Email *'
                      defaultValue={userInfo.acf.paypal_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.paypal_email}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input name='payout_minimum' fluid placeholder='Payout Minimum' type='text' label='Payout Minimum *'
                      defaultValue={userInfo.acf.payout_minimum}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.payout_minimum}
                      disabled={isSubmitting}
                    />
                  </div>
                  
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button type='button' icon='close' content={<span>Cancel</span>} compact
                  onClick={() => handler(false)}
                  disabled={isSubmitting}
                />
                <Button type='submit' color='green' icon='save' content={<span>Save</span>} compact
                  onClick={handleSubmit}
                  disabled={!(dirty && !isSubmitting)}
                />
              </Modal.Actions>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default React.memo(AccountPayoutSettingsForm)
