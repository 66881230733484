import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import { cleanPrice } from '@Utils'

// REDUX
import { useSelector } from 'react-redux'
import { getUIState } from '@Slices/uiSlice'

import { StyledContainer } from './GalleryListStyles'

const GalleryListItem = (props) => {
  const { item } = props
  const { currency } = useSelector(getUIState)
  return (
    <StyledContainer className='gallery_listview_item'>
      <div className="title">Added to your cart</div>
      <div className='wrapper'>
        <img src={item.media.thumbnail} className='image' />
        <div className='details'>
          <Link to={`/@${item.author_slug}/gallery/${item.id}`}>
            {item.item_name}
          </Link>
        </div>
        <div className='price'>
          <NumberFormat
            value={cleanPrice(item.total_amount * currency.value)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={currency.symbol}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      </div>
    </StyledContainer>
  )
}

export default React.memo(GalleryListItem)
