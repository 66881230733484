import React from "react";
import { format } from "date-fns";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import seedrandom from "seedrandom";
import { useReactToPrint } from "react-to-print";
import { consoleLogger } from "@Utils";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import cartSlice from "@Slices/cartSlice";
import { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import { Button, Header, Icon, Modal, Segment } from "semantic-ui-react";
import { CertificateTemplate } from "./Components";

// STYLES
import { Container, ModalContainer } from "./CertificateGeneratorStyles";
import "react-lazy-load-image-component/src/effects/blur.css";

const CertificateGenerator = () => {
  const dispatch = useDispatch();
  const { userInfo, userKey, userGallery, userCollections } =
    useSelector(getUserState);

  const token = localStorage.Authorization;

  const drawRef = React.useRef();
  const [drawWidth, setDrawWidth] = React.useState();
  const [drawOpen, setDrawOpen] = React.useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = React.useState();

  const tempData = {
    productTitle: "Sample Artwork Title",
    productEdition: 10,
    artistProof: 1,
    certificateID: "CERT-ABCDEFG1234567890-2022",
    productImage:
      Object.keys(userGallery).length > 0
        ? userGallery[0].media.medium
        : "/images/sample-image.jpg",
    printYear: format(new Date(), "yyyy"),
    copyNumber: 4,
    paperSize: "Standard 16x24",
    imageSize: "Standard 16x24",
    paperType: "Matte Paper (Textured)",
    printerDetails: "Epson SureColor P9000",
    printerDetails2: "Epson Ultrachrome K3 HDX",
    certificateKey: "ABC1234567890",
    artistName: `${userInfo.first_name} ${userInfo.last_name}`,
    artistSignature: userInfo.acf.signature_image,
    certificateDate: new Date(),
    cameraSensor: `apsc`.toUpperCase(),
  };

  const handleDrawOpen = (bool) => {
    setDrawOpen((prev) => (bool ? bool : !prev));
  };
  const handleClear = () => {
    drawRef.current.clear();
  };
  const handleSave = async () => {
    if (!drawRef.current.isEmpty()) {
      setDrawOpen(false);
      const temp = drawRef.current.getTrimmedCanvas().toDataURL("image/png");
      consoleLogger(temp);

      const rng = seedrandom();

      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/upload`,
        headers: {
          Authorization: `${token}`,
        },
        method: "POST",
        data: {
          user_key: userKey,
          author: userInfo.id,
          base64: temp,
          title: Math.abs(rng.int32()),
        },
      })
        .then(async (res) => {
          consoleLogger("UPLOAD BASE64 RESPONSE.... ", res.data);
          const newData = new Object();
          newData.acf_signature = decodeURI(res.data);
          newData.user_key = userKey;
          await axios({
            baseURL: process.env.REACT_APP_API_URL,
            url: `/stylefolio/v1/users/${userInfo.id}`,
            headers: {
              Authorization: `${token}`,
            },
            method: "POST",
            data: newData,
          })
            .then((res) => {
              dispatch(userSlice.actions.updateUserInfo(res.data));
              dispatch(
                userSlice.actions.updateAccountCompletion({
                  hasSignature: true,
                })
              );
            })
            .catch((err) => consoleLogger(err));
        })
        .catch((err) => consoleLogger(err));
    }
  };

  React.useEffect(() => {
    const adjustWidthOnResize = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      consoleLogger(`${vw} x ${vh}`);
      setDrawWidth({ width: vw < 700 ? vw - 32 : 700, height: 300 });
    };
    adjustWidthOnResize();
    window.addEventListener("resize", adjustWidthOnResize);
    return () => {
      window.removeEventListener("resize", adjustWidthOnResize);
    };
  }, [drawRef]);

  React.useEffect(() => {
    if (userInfo && userInfo.acf.signature_image) {
      setTrimmedDataURL(userInfo.acf.signature_image);
      consoleLogger(userInfo.acf.signature_image);
    }
  }, [userInfo]);

  const printComponentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: `${process.env.REACT_APP_PROJECT_NAME} - Certificate of Authenticity`,
    fonts: [
      {
        family: "Inter",
        source: "/fonts/Inter/Inter-Regular.ttf",
      },
      {
        family: "Merriweather",
        source: "/fonts/Merriweather/Merriweather-Regular.ttf",
      },
    ],
  });

  return (
    <Container>
      <Header as="h3">Certificate of Authenticity</Header>
      <p>
        The purpose of this certificate is to provide proof for your collectors
        that what they are paying is authentic and original copies of your
        artwork.
      </p>
      <div className="pdfViewer">
        {trimmedDataURL && (
          <div className="certificateWrapper">
            <CertificateTemplate
              ref={printComponentRef}
              data={tempData}
              sample
            />
          </div>
        )}
        <div className="buttonGroup">
          {!trimmedDataURL ? (
            <>
              <Button
                color="blue"
                onClick={handleDrawOpen}
                icon="edit"
                content="Create Your Signature"
                labelPosition="right"
              />
            </>
          ) : (
            <>
              <Button
                color="blue"
                basic
                onClick={handleDrawOpen}
                icon="edit"
                content="Redo Signature"
                labelPosition="right"
              />
              <Button
                color="blue"
                onClick={() => handlePrint()}
                icon="print"
                content="Print Sample"
                labelPosition="right"
              />
            </>
          )}
        </div>
      </div>

      {drawOpen && (
        <Modal
          size="small"
          open={drawOpen}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => handleDrawOpen(true)}
          onClose={() => handleDrawOpen(false)}
          className="drawModal"
        >
          <Modal.Content scrolling>
            <ModalContainer>
              <SignatureCanvas
                ref={drawRef}
                penColor="black"
                minWidth={1}
                maxWidth={4}
                canvasProps={{
                  width: drawWidth.width,
                  height: drawWidth.height,
                  className: "sigCanvas",
                }}
              />
            </ModalContainer>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              icon="close"
              labelPosition="right"
              content="Close"
              onClick={() => handleDrawOpen(false)}
            />
            <Button
              type="button"
              color="red"
              icon="trash"
              labelPosition="right"
              content="Clear"
              onClick={handleClear}
            />
            <Button
              type="button"
              color="green"
              icon="check"
              labelPosition="right"
              content="Save"
              onClick={handleSave}
            />
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
};

export default React.memo(CertificateGenerator);
