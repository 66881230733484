import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledContainer = styled.div`
  font-size: 1rem;
  .title {
    font-size: 1.15rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: ${stylevars.typography.title.color};
  }
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .image {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .details {
    margin-left: 0.5rem;
  }
  .price {
    margin-left: 0.5rem;
  }
`