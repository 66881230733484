import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const Container = styled("div")`
  position: relative;
  .item {
    transition: all 0.15s ease-out;
    overflow: hidden;
    border-radius: 0.5rem;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
  }
  .link {
    overflow: hidden;
    position: relative;
    display: block;
    width: 100%;
    height: calc(min(calc(var(--vh, 1vh) * 30), 320px));

    .image {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(1);
        transition: transform 0.3s ease-out;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .details {
    padding: 1rem;

    .title {
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        flex-grow: 1;
        display: inline-block;
        font-size: 1.25rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 1rem;
        width: 100%;
        max-width: 220px;
        word-break: break-all;

        > .limited {
          font-size: 0.75rem;
          display: block;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .likeButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        .button {
          background-color: transparent;
          padding: 0;
          height: 3rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .icon {
            font-size: 1.25rem;
            color: ${stylevars.palette.danger.main};
          }
          span {
            font-size: 1rem;
            margin-left: 0.5rem;
          }
        }
      }
    }

    .field {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }

      .label {
        font-size: 0.85rem;
        color: rgba(0, 0, 0, 0.5);
      }

      .value {
        font-size: 1rem;
        font-weight: 400;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .valueLink {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: ${stylevars.typography.title.color};
          &:hover {
            color: ${stylevars.palette.info.main};
          }
        }
      }

      &.creator {
        .avatar {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      &.price {
        .value {
          font-weight: bold;
          .copies {
            > span {
              display: inline-block;
              line-height: 1.4;
              &:nth-child(1) {
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
    }
  }
`;
