import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useReactToPrint } from 'react-to-print'
import html2canvas from "html2canvas"
import pdfMake from "pdfmake/build/pdfmake.js"

// COMPONENTS
import { CertificateTemplate } from '@AdminPages/Account/Components'

// REDUX
import { useDispatch } from 'react-redux'
import uiSlice from '@Slices/uiSlice'

// STYLES
import { Container } from './CertificatePageStyles'
import { Button, Dimmer, Loader } from 'semantic-ui-react'

const CertificatePage = (props) => {
  const dispatch = useDispatch()
  const { key } = useParams()

  const [ ready, setReady ] = React.useState(false)
  const [ data, setData ] = React.useState()

  React.useEffect(() => {
    (async () => {
      if (key) {
        await axios({
          baseURL: process.env.REACT_APP_API_URL,
          url: `/stylefolio/v1/certificate/${key}`,
          method: 'GET',
        }).then(res => {
          setData(res.data)
        })
      }
    })()
  }, [key]) // eslint-disable-line

  React.useEffect(() => {
    if (data) {
      setReady(true)
    }
  }, [data]) // eslint-disable-line

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('certificate'))
    dispatch(uiSlice.actions.setCurrentPageURL(`certificate/${key}`))
    dispatch(uiSlice.actions.setCurrentPageTitle(''))
  }, []) // eslint-disable-line
  
  const printComponentRef = React.useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: `${process.env.REACT_APP_PROJECT_NAME} - Certificate of Authenticity`,
    fonts: [{
      family: 'Inter',
      source: '/fonts/Inter/Inter-Regular.ttf'
    },
    {
      family: 'Merriweather',
      source: '/fonts/Merriweather/Merriweather-Regular.ttf'
    }]
  })
  // const handleDownload = (certData) => {
  //   // pdfRef.current.save()
  //   html2canvas(printComponentRef.current).then(canvas => {
  //     var data = canvas.toDataURL();
  //     var pdfExportSetting = {
  //       content: [
  //         {
  //           image: data,
  //           width: 500
  //         }
  //       ]
  //     }
  //     pdfMake.createPdf(pdfExportSetting).download(`${certData.certificateID}.pdf`);
  //   })
  // }

  return (
    <Container>
      {ready ?
        <>
          <div className='wrapper'>
            <CertificateTemplate ref={printComponentRef} data={data} />
          </div>
          <div className='actions'>
            <Button
              color='blue'
              onClick={() => handlePrint()}
              icon='print'
              content='Print Certificate'
              labelPosition='right'
              size='large'
            />
            {/* <Button
              color='green'
              onClick={() => handleDownload(data)}
              icon='download'
              content='Download PDF'
              labelPosition='right'
            /> */}
          </div>
        </> : 
        <Dimmer inverted active>
          <Loader>Loading certificate...</Loader>
        </Dimmer>
      }
    </Container>
  )
}

export default CertificatePage
