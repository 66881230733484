import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledAccountContainer = styled.div`
  padding: 2rem;

  @media ${stylevars.breakpoints.phone} {
    padding: 1rem;
  }

  .ui {
    &.segment {
      box-shadow: none;

      h3 {
        min-height: 2rem;
        line-height: 2rem;
        margin: 0;
        padding-top: 0rem;
        padding-bottom: 2rem;
        @media ${stylevars.breakpoints.phone} {
          padding-top: 1rem;
        }
      }
    }
    &.table {
      margin-top: 0;
      &.very.basic {
        .balance {
          td {
            border-top: 2px solid ${stylevars.paper.borderColor};
            font-weight: bold;
          }
        }
      }
    }
    &.grid {
      .column {
        @media ${stylevars.breakpoints.phone} {
          margin-bottom: 2rem;
          &:not(:first-of-type) {
            padding-top: 2rem;
            box-shadow: none !important;
          }
        }
      }
    }
  }
  
  .formContainer {
    /* margin-top: 1rem; */

    .row {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      &:not(:first-of-type) {
        margin-top: 0rem !important;
      }
      @media ${stylevars.breakpoints.phone} {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &.actions {
        border-top: 1px solid ${stylevars.paper.borderColor};
        border-bottom: 1px solid ${stylevars.paper.borderColor};
        background-color: rgba(${stylevars.paper.borderColorRGB}, 1);
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        text-align: right;
      }
      &.addresses {
        /* background-color: ${stylevars.paper.borderColor}; */
        padding-top: 2rem !important;
      }
    }
    .column {
      @media ${stylevars.breakpoints.phone} {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
        padding-top: 0rem !important;
        padding-bottom: 1rem !important;
      }

      .ui.grid:not(:last-of-type) {
        margin-bottom: 1rem !important;
      }
    }

    .fieldDisplay {
      width: 100%;
      padding: 0.5rem 1rem;
      border: 1px solid rgba(34, 36, 38, 0.15);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 0.28571429rem;
      box-shadow: 0em 0em 0em 0em transparent inset;
      background-color: rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      &.withIcon {
        padding-right: 3rem;
      }
      > span {
        width: 100%;
      }
      @media ${stylevars.breakpoints.phone} {
        > .ui.divided.grid {
          padding-top: 1rem !important;
        }
      }
    }
    .fieldDisplayIcon {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .subPageHeaderLink {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    position: relative;
    &:not(:last-of-type) {
      padding-right: 1rem;
      margin-right: 1rem;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.04rem;
        height: 120%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    &.active {
      color: rgba(0, 0, 0, 0.87);
    }
    &:hover {
      color: ${stylevars.palette.info.main};
    }
  }

  .subPageMenu {
    .menu {
      background-color: ${stylevars.paper.bgColor};
      /* background-color: rgba(${stylevars.paper.borderColorRGB},0.15); */
      /* background-color: ${stylevars.paper.bgColor}; */
      /* border-color: ${stylevars.typography.subtitle.color} !important; */
      .item {
        font-weight: bold !important;
        color: ${stylevars.typography.subtitle.color} !important;
        border-color: transparent !important;
        &.active {
          color: ${stylevars.palette.info.main} !important;
          border-color: ${stylevars.palette.info.main} !important;
        }
        &:hover {
          color: ${stylevars.typography.title.color} !important;
          border-color: ${stylevars.typography.title.color} !important;
        }
      }
    }
    .segment {
      border: none;
    }
  }

  .tableRefresher {
    border: none !important;
    box-shadow: none !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    .icon {
      margin-right: 0 !important;
    }
  }
  .tableSearchField {
    margin-right: 1rem;
  }
  .tableFilterDropdown {
    padding-right: 0em !important;
    margin-right: 1rem;
    &.end {
      padding-right: 0.357em !important;
      margin-right: 0rem;
    }
    .menu {
      background-color: #fff;
      /* margin-top: 0rem !important; */

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: ${stylevars.typography.extra.color};

        &.active {
          color: ${stylevars.typography.title.color};
          .icon {
            color: ${stylevars.palette.info.main};
          }
        }

        &.reset {
          color: ${stylevars.typography.title.color};
        }
      }
    }
  }
  .displayCountDropdown_label {
    padding-right: 0.5rem;
  }
  .displayCountDropdown {
    .menu {
      background-color: #fff;
    }
  }

  .cashoutInfoIcon {
    margin-left: 0.5rem;
  }

  .payoutButton {
    margin-bottom: 2rem;
  }

  .pageTitle  {
    border-bottom: 1px solid ${stylevars.paper.borderColor};
    margin-bottom: 1rem !important;
    padding-bottom: 1rem !important;
  }
`
