import React from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory, useLocation } from "react-router-dom"; // eslint-disable-line
import axios from "axios";
import cn from "classnames";
import he from "he";
import useKeypress from "react-use-keypress";
import { debounce } from "lodash";
import NumberFormat from "react-number-format";
import { Helmet } from "react-helmet";
import {
  consoleLogger,
  priceFormat,
  cleanPrice,
  getPrintType,
  getPrintMaterial,
  getPrintSize,
  getAspectRatio,
  usePrevious,
} from "@Utils";
import { ToastContainer, toast } from "react-toastify";
import shortNumber from "short-number";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import { getGalleryState } from "@Slices/gallerySlice";
import vendorSlice, { getVendorState } from "@Slices/vendorSlice";
import cartSlice, { getCartState } from "@Slices/cartSlice";
import { getUserState } from "@Slices/userSlice";

// COMPONENTS
import {
  Grid,
  Progress,
  Dimmer,
  Loader,
  Icon,
  Button,
  Item,
  Header,
  Message,
  Portal,
  Label,
} from "semantic-ui-react";
import ViewerImagePreview from "./ViewerImagePreview";
import GalleryListItem from "./GalleryListItem";
import LoginModal from "../../Pages/Login/LoginModal";
import {
  ViewerOptionsTypes,
  ViewerOptionsSizes,
  ViewerOptionsFrames,
} from "./Components";
import { AddressModal } from "@Common";

import { ViewerContainer } from "./ViewerStyles";
import currency from "currency.js";

const Viewer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.Authorization;
  const currentPathname = location.pathname;
  const { authenticated, userInfo, userKey, accountCompletion } =
    useSelector(getUserState);
  const { carts } = useSelector(getCartState);
  const {
    siteSettings,
    showViewer,
    viewerData,
    viewerFetching,
    viewerIsSlider,
    viewerImageDone,
    currency,
  } = useSelector(getUIState); // eslint-disable-line
  const { gallerySizeTypes, galleryMaterials, gallerySizes } =
    useSelector(getGalleryState);
  const { vendorInfo, vendorProducts, vendorProductIndex } =
    useSelector(getVendorState); // eslint-disable-line
  const { username, page, postid, gid } = useParams(); // eslint-disable-line

  const toastOptions = {
    onOpen: (props) => consoleLogger(props.foo),
    onClose: (props) => consoleLogger(props.foo),
    containerId: "viewerToaster",
    autoClose: 5000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
    closeOnClick: true,
    draggable: true,
  };

  const [itemId, setItemId] = React.useState();
  React.useEffect(() => {
    consoleLogger(
      "==================== POST ID / GID ============================"
    );
    consoleLogger(postid, gid);
    setItemId(gid ? gid : postid);
  }, [postid, gid]); // eslint-disable-line

  const prevPostid = usePrevious(itemId);
  const prevUsername = usePrevious(username);

  const [defaultTypeID, setDefaultTypeID] = React.useState();
  const [basePrice, setBasePrice] = React.useState();
  const [fee, setFee] = React.useState();
  const [priceFactor, setPriceFactor] = React.useState(1);
  const [modPrices, setModPrices] = React.useState([]);
  const [totalItemPrice, setTotalItemPrice] = React.useState();
  const [cartQuantity, setCartQuantity] = React.useState(0);

  const hideViewer = (ev) => {
    ev.preventDefault();
    dispatch(uiSlice.actions.setShowViewer(false));

    dispatch(uiSlice.actions.setViewerFetching(false));
    document.body.classList.remove("dimmable", "dimmed", "no-scrolling");

    setTimeout(() => {
      dispatch(uiSlice.actions.setMountViewer(false));
      if (!gid) {
        history.push(`/@${vendorInfo.username}/gallery`);
      } else {
        history.push(`/@${vendorInfo.username}/collections/${postid}`);
      }
    }, 150);
  };

  const [quantity, setQuantity] = React.useState(1);
  const [selectedType, setSelectedType] = React.useState();
  const [aspectRatio, setAspectRatio] = React.useState();
  const [selectedSize, setSelectedSize] = React.useState();
  const [selectedFrame, setSelectedFrame] = React.useState();
  const [selectedBorder, setSelectedBorder] = React.useState();
  const [selectedPaper, setSelectedPaper] = React.useState();
  const [selectedCanvas, setSelectedCanvas] = React.useState();
  const [isLimitedEdition, setIsLimitedEdition] = React.useState(false);
  const [isAvailable, setIsAvailable] = React.useState(false);
  const [limitCount, setLimitCount] = React.useState(99);
  const handleSelectType = (typeid) => {
    // const typeid = ev.currentTarget.getAttribute('typeid')
    const typeObj = getPrintType(typeid, gallerySizeTypes);
    consoleLogger("handleSelectType", typeObj);
    setSelectedType((prevState) => {
      if (prevState && prevState.id === typeid) {
        return prevState;
      } else {
        // DEFAULT SIZE
        let selectSizeIndex = 0;
        const sizes = gallerySizes
          .filter((n) => n.acf.active)
          .filter(
            (i) =>
              i.acf.aspect_ratio.toString() ===
              viewerData.acf.aspect_ratio.toString()
          );
        const sizesTemp = sizes.filter(
          (x, index) => x.acf.length >= 12 && x.acf.width >= 12
        );
        if (typeObj.slug === "canvas" || typeObj.slug === "framed-canvas") {
          sizes.forEach((n, index) => {
            if (n.id === sizesTemp[0].id) {
              selectSizeIndex = index;
            }
          });
        }
        consoleLogger("selectSizeIndex ===========", selectSizeIndex);

        // DEFAULT FRAMES
        let selectFrameIndex = 0;
        if (typeObj.slug === "framed" || typeObj.slug === "framed-canvas") {
          selectFrameIndex =
            typeObj.acf.frame_options.findIndex(
              (o) => o.slug === "material-natural-frame"
            ) > -1
              ? typeObj.acf.frame_options.findIndex(
                  (o) => o.slug === "material-natural-frame"
                )
              : 0;
        }
        if (typeObj.slug === "canvas") {
          selectFrameIndex =
            typeObj.acf.frame_options.findIndex(
              (o) => o.slug === "material-image-wrap"
            ) > -1
              ? typeObj.acf.frame_options.findIndex(
                  (o) => o.slug === "material-image-wrap"
                )
              : 0;
        }
        consoleLogger("selectFrameIndex ===========", selectFrameIndex);

        // DEFAULT BORDER
        let selectBorderIndex = 0;
        if (typeObj.slug === "framed") {
          selectBorderIndex =
            typeObj.acf.border_options.findIndex(
              (o) => o.slug === "frame-option-2inch-border-matte-board"
            ) > -1
              ? typeObj.acf.border_options.findIndex(
                  (o) => o.slug === "frame-option-2inch-border-matte-board"
                )
              : 0;
        }
        consoleLogger("selectBorderIndex ===========", selectBorderIndex);

        // DEFAULT PAPER
        let selectPaperIndex = 0;
        if (typeObj.slug === "print-only" || typeObj.slug === "framed") {
          selectPaperIndex =
            typeObj.acf.paper_options.findIndex(
              (o) => o.slug === "material-lustre-paper"
            ) > -1
              ? typeObj.acf.paper_options.findIndex(
                  (o) => o.slug === "material-lustre-paper"
                )
              : 0;
        }
        consoleLogger("selectPaperIndex ===========", selectPaperIndex);

        // DEFAULT CANVAS
        let selectCanvasIndex = 0;
        if (typeObj.slug === "framed-canvas") {
          selectCanvasIndex = 0;
        }
        consoleLogger("selectCanvasIndex ===========", selectCanvasIndex);

        handleSelectSize(sizes[selectSizeIndex]);
        handleSelectFrame(
          typeObj.acf.has_frames
            ? typeObj.acf.frame_options.map((i) => i)[selectFrameIndex]
            : null
        );
        handleSelectBorder(
          typeObj.acf.has_border
            ? typeObj.acf.border_options.map((i) => i)[selectBorderIndex]
            : null
        );
        handleSelectPaper(
          typeObj.acf.has_paper
            ? typeObj.acf.paper_options.map((i) => i)[selectPaperIndex]
            : null
        );
        handleSelectCanvas(
          typeObj.acf.has_canvas
            ? typeObj.acf.canvas_options.map((i) => i)[selectCanvasIndex]
            : null
        );

        return typeObj;
      }
    });
  };
  const handleSelectSize = React.useCallback(
    (size) => {
      // setSelectedSize(size)
      let obj = null;
      if (size) {
        obj = getPrintSize(size.term_id, gallerySizes);
      }
      consoleLogger("handleSelectSize", obj);
      setSelectedSize(obj);
    },
    [gallerySizes]
  );

  const handleSelectFrame = (frame) => {
    // setSelectedFrame(frame)
    let obj = null;
    if (frame) {
      obj = getPrintMaterial(frame.term_id, galleryMaterials);
    }
    consoleLogger("handleSelectFrame", obj);
    setSelectedFrame(obj);
    if (!obj) {
      setModPrices((price) => ({
        ...price,
        frame: 0,
      }));
    }
  };
  const handleSelectBorder = (material) => {
    // setSelectedBorder(material)
    let obj = null;
    if (material) {
      obj = getPrintMaterial(material.term_id, galleryMaterials);
    }
    consoleLogger("handleSelectBorder", obj);
    setSelectedBorder(obj);
    if (!obj) {
      setModPrices((price) => ({
        ...price,
        border: 0,
      }));
    }
  };
  const handleSelectPaper = (material) => {
    // setSelectedPaper(material)
    let obj = null;
    if (material) {
      obj = getPrintMaterial(material.term_id, galleryMaterials);
    }
    consoleLogger("handleSelectPaper", obj);
    setSelectedPaper(obj);
    if (!obj) {
      setModPrices((price) => ({
        ...price,
        paper: 0,
      }));
    }
  };
  const handleSelectCanvas = (material) => {
    // setSelectedCanvas(material)
    let obj = null;
    if (material) {
      obj = getPrintMaterial(material.term_id, galleryMaterials);
    }
    consoleLogger("handleSelectCanvas", obj);
    setSelectedCanvas(obj);
    if (!obj) {
      setModPrices((price) => ({
        ...price,
        canvas: 0,
      }));
    }
  };

  const handlePrevGalleryItem = () => {
    vendorProducts.forEach((item, index) => {
      // consoleLogger('item', item.ID.toString(), 'itemId', itemId.toString())
      const tIndex = (index += 1);
      if (item.ID.toString() === itemId.toString()) {
        if (index !== 0) {
          const prevIndex = tIndex - 2;
          // dispatch(vendorSlice.actions.setVendorProductIndex(prevIndex))
          history.push(`./${vendorProducts[prevIndex].ID}`);
        }
      }
    });
  };
  const handleNextGalleryItem = () => {
    vendorProducts.forEach((item, index) => {
      // consoleLogger('item', item.ID.toString(), 'itemId', itemId.toString())
      if (item.ID.toString() === itemId.toString()) {
        if (index !== vendorProducts.length) {
          const nextIndex = index + 1;
          // dispatch(vendorSlice.actions.setVendorProductIndex(nextIndex))
          history.push(`./${vendorProducts[nextIndex].ID}`);
        }
      }
    });
  };
  const handleQuantity = (ev) => {
    const val = Number(ev.currentTarget.value);
    if (val <= limitCount && val >= 1) {
      setQuantity(val);
    }
  };
  const handleQuantityFocus = (ev) => {
    ev.target.select();
  };
  const handleQuantityChange = (mode) => {
    if (isAvailable) {
      if (mode === "dec") {
        setQuantity((prev) => (prev > 1 ? (prev -= 1) : 1));
      } else {
        setQuantity((prev) => (prev < limitCount ? (prev += 1) : limitCount));
      }
    }
  };

  React.useEffect(() => {
    consoleLogger("=========== limitCount ==========");
    consoleLogger(limitCount);
    setIsAvailable(limitCount > 0);
  }, [limitCount]); // eslint-disable-line

  // React.useEffect(() => {
  //   consoleLogger('=========== quantity ==========', quantity)
  // }, [quantity]) // eslint-disable-line

  const [progressLoaded, setProgressLoaded] = React.useState(0);

  // CHECK CART TO VALIDATE PRODUCT AVAILABILITY
  React.useEffect(() => {
    if (carts) {
      consoleLogger("=================== CART CHANGED ======================");
      let tempQuantity = 0;
      carts.forEach((n) => {
        if (n.items && n.items.length > 0) {
          const tempItems = n.items.filter(
            (item) => item.id.toString() === itemId
          );
          consoleLogger(tempItems);
          tempItems.forEach((item) => {
            consoleLogger(
              "=================== ITEM FOUND ======================"
            );
            consoleLogger(item);
            tempQuantity += Number(item.quantity);
            setLimitCount((prev) => prev - tempQuantity);
          });
        }
      });
      setCartQuantity(Number(tempQuantity));
    }
  }, [carts]); // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (itemId) {
        consoleLogger("retrieving post id", itemId);
        if (itemId) {
          document.body.classList.add("dimmable", "dimmed", "no-scrolling");
          // dispatch(uiSlice.actions.setMountViewer(true))
          // dispatch(uiSlice.actions.setShowViewer(true))
          dispatch(uiSlice.actions.setViewerFetching(true));
          dispatch(uiSlice.actions.setViewerImageDone(false));

          setProgressLoaded(0);

          if (vendorInfo && vendorProducts && vendorProducts.length > 0) {
            if (
              vendorProducts
                .filter((i) => i.id.toString() === itemId.toString())
                .map((product) => product).length > 0
            ) {
              consoleLogger("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
              const product = vendorProducts
                .filter((i) => i.id.toString() === itemId.toString())
                .map((product) => product)[0];
              // check if product is a limited edition
              setIsLimitedEdition(!!product.acf.limited_edition);
              // check if product still have stock available
              if (product.acf.limited_edition) {
                let tempCount = 0;
                if (
                  Number(product.acf.sold_copies) <
                  Number(product.acf.number_lots)
                ) {
                  tempCount =
                    Number(product.acf.number_lots) -
                    Number(product.acf.sold_copies);
                }
                setLimitCount(tempCount);

                if (carts) {
                  consoleLogger(
                    "=================== CART CHANGED ======================"
                  );
                  let tempQuantity = 0;
                  carts.forEach((n) => {
                    if (n.items && n.items.length > 0) {
                      const tempItems = n.items.filter(
                        (item) => item.id.toString() === itemId
                      );
                      consoleLogger(tempItems);
                      tempItems.forEach((item) => {
                        consoleLogger(
                          "=================== ITEM FOUND ======================"
                        );
                        consoleLogger(item);
                        tempQuantity += Number(item.quantity);
                        setLimitCount((prev) => prev - tempQuantity);
                      });
                    }
                  });
                  setCartQuantity(Number(tempQuantity));
                }
              } else {
                setIsAvailable(true);
              }
              dispatch(uiSlice.actions.setViewerData(product));
              dispatch(uiSlice.actions.setViewerIsSlider(true));
              // set the base price
              setBasePrice(
                product.acf.base_price ? Number(product.acf.base_price) : 10
              );
              // set default print type id
              const typeid = product.acf.print_types
                .filter((i) => i.active)
                .map((o) => o.id)[0];
              const type = getPrintType(typeid, gallerySizeTypes);
              consoleLogger("typeid ============================", typeid);
              setDefaultTypeID(typeid);
              setFee(cleanPrice(Number(type.acf.fee)));
              // set aspect ratio
              const ar = product.acf.aspect_ratio
                ? product.acf.aspect_ratio
                : "2x3";
              setAspectRatio(getAspectRatio(ar));
              // set sizes
              const size = gallerySizes
                .filter((n) => n.acf.active)
                .filter((i) => i.acf.aspect_ratio.toString() === ar.toString())
                .map((o) => o)[0];
              handleSelectSize(size);
              // set price factor
              setPriceFactor(
                (Number(size.acf.length) + Number(size.acf.width)) / 2
              );
              if (
                selectedFrame &&
                selectedFrame.slug === "material-image-wrap"
              ) {
                const tempPriceFactor =
                  (Number(size.acf.length) + 2 + (Number(size.acf.width) + 2)) /
                  2;
                setPriceFactor(tempPriceFactor);
              }
              if (selectedType && selectedType.slug === "framed-canvas") {
                const tempPriceFactor =
                  (Number(size.acf.length) + 2 + (Number(size.acf.width) + 2)) /
                  2;
                setPriceFactor(tempPriceFactor);
              }
              // complete the progress loader
              setProgressLoaded(100);
              dispatch(uiSlice.actions.setViewerFetching(false));
            } else {
              history.push("../gallery");
              dispatch(uiSlice.actions.setShowViewer(false));
              dispatch(uiSlice.actions.setMountViewer(false));
            }
          } else {
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/stylefolio/v1/gallery/${itemId}`,
              method: "get",
              params: {
                update_pageview: true,
              },
              onDownloadProgress: (progressEvent) => {
                consoleLogger(
                  "progressEvent ====================",
                  progressEvent
                );
                let percentCompleted = 0;
                if (progressEvent.lengthComputable) {
                  percentCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                } else {
                  percentCompleted = 100;
                }
                setProgressLoaded(percentCompleted);
                consoleLogger("completed: ", percentCompleted);
              },
            })
              .then((res) => {
                if (res.data.post_status === "publish") {
                  dispatch(uiSlice.actions.setViewerData(res.data));
                  dispatch(uiSlice.actions.setViewerIsSlider(true));
                  // set the base price
                  setBasePrice(
                    res.data.acf.base_price
                      ? Number(res.data.acf.base_price)
                      : 10
                  );
                  // set default print type id
                  const typeid = res.data.acf.print_types
                    .filter((i) => i.active)
                    .map((o) => o.id)[0];
                  const type = getPrintType(typeid, gallerySizeTypes);
                  consoleLogger("typeid ============================", typeid);
                  setDefaultTypeID(typeid);
                  setFee(cleanPrice(Number(type.acf.fee)));
                  // set aspect ratio
                  const ar = res.data.acf.aspect_ratio
                    ? res.data.acf.aspect_ratio
                    : "2x3";
                  setAspectRatio(getAspectRatio(ar));
                  // set sizes
                  const size = gallerySizes
                    .filter((n) => n.acf.active)
                    .filter(
                      (i) => i.acf.aspect_ratio.toString() === ar.toString()
                    )
                    .map((o) => o)[0];
                  handleSelectSize(size);
                  // set price factor
                  setPriceFactor(
                    (Number(size.acf.length) + Number(size.acf.width)) / 2
                  );
                  if (
                    selectedFrame &&
                    selectedFrame.slug === "material-image-wrap"
                  ) {
                    const tempPriceFactor =
                      (Number(size.acf.length) +
                        2 +
                        (Number(size.acf.width) + 2)) /
                      2;
                    setPriceFactor(tempPriceFactor);
                  }
                  if (selectedType && selectedType.slug === "framed-canvas") {
                    const tempPriceFactor =
                      (Number(size.acf.length) +
                        2 +
                        (Number(size.acf.width) + 2)) /
                      2;
                    setPriceFactor(tempPriceFactor);
                  }

                  // } else {
                  //   history.push(`../gallery`)
                }

                dispatch(uiSlice.actions.setViewerFetching(false));
              })
              .catch((err) => {
                consoleLogger(err);
                history.push(`../gallery`);
                dispatch(uiSlice.actions.setShowViewer(false));
                dispatch(uiSlice.actions.setMountViewer(false));
              });
          }
        }
      }
    })();

    return () => {
      setProgressLoaded(0);
    };
  }, [itemId]); // eslint-disable-line

  const [userLikesIt, setUserLikesIt] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      // check if logged in user likes this item
      if (userInfo && viewerData) {
        if (viewerData.acf && viewerData.acf.likes) {
          let chk = 0;
          viewerData.acf.likes.forEach((n) => {
            if (n.id.toString() === userInfo.id.toString()) {
              chk += 1;
            }
          });
          setUserLikesIt(chk > 0);
        }

        dispatch(vendorSlice.actions.updateVendorProductIndex(itemId));

        // UPDATE PAGEVIEW
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/gallery/${itemId}`,
          method: "GET",
          params: {
            update_pageview: true,
          },
        });
      }
    })();
  }, [viewerData]); // eslint-disable-line

  React.useEffect(() => {
    if (defaultTypeID && viewerData) {
      handleSelectType(defaultTypeID);
    }
  }, [defaultTypeID, viewerData]); // eslint-disable-line

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate("store"));
    dispatch(uiSlice.actions.setViewerIsSlider(true));
    dispatch(uiSlice.actions.setShowViewer(true));

    return () => {
      dispatch(uiSlice.actions.setViewerData());
      dispatch(uiSlice.actions.setViewerFetching(true));
      dispatch(uiSlice.actions.setViewerImageDone(false));
    };
  }, []); // eslint-disable-line

  // PRICING MODIFICATIONS
  React.useEffect(() => {
    consoleLogger("ASPECT RATIO OBJECT ===========", aspectRatio);
    if (selectedType) {
      consoleLogger(
        " SELECTED TYPE ===============>>>>>>>>>",
        selectedType.slug
      );
      setFee(cleanPrice(Number(selectedType.acf.fee)));
      setModPrices((prices) => ({
        ...prices,
        paper:
          selectedType.slug === "canvas" || "framed-canvas"
            ? Number(
                galleryMaterials
                  .filter((i) => i.slug === "material-cotton-canvas")
                  .map((n) => n.acf.price)[0]
              )
            : 0,
      }));
    }
    if (selectedSize) {
      consoleLogger(
        "selectedSize Price",
        selectedSize.acf.will_affect_price,
        selectedSize.acf.price
      );
      if (selectedSize.acf.will_affect_price) {
        setPriceFactor(
          (Number(selectedSize.acf.length) + Number(selectedSize.acf.width)) / 2
        );
      }
    }
    if (selectedFrame) {
      consoleLogger(
        "selectedFrame Price",
        selectedFrame.acf.will_affect_price,
        selectedFrame.acf.price
      );
      if (selectedFrame.acf.will_affect_price) {
        setModPrices((prices) => ({
          ...prices,
          frame:
            selectedType.slug === "framed" || "framed-canvas"
              ? selectedFrame.acf.price
                ? Number(selectedFrame.acf.price)
                : 0
              : 0,
        }));
      }
      if (selectedFrame && selectedFrame.slug === "material-image-wrap") {
        const tempPriceFactor =
          (Number(selectedSize.acf.length) +
            2 +
            (Number(selectedSize.acf.width) + 2)) /
          2;
        setPriceFactor(tempPriceFactor);
      }
      if (selectedType && selectedType.slug === "framed-canvas") {
        const tempPriceFactor =
          (Number(selectedSize.acf.length) +
            2 +
            (Number(selectedSize.acf.width) + 2)) /
          2;
        setPriceFactor(tempPriceFactor);
      }
    }
    if (selectedBorder) {
      consoleLogger(
        "selectedBorder Price",
        selectedBorder.acf.will_affect_price,
        selectedBorder.acf.price
      );
      if (selectedBorder.acf.will_affect_price) {
        setModPrices((prices) => ({
          ...prices,
          border:
            selectedType.slug === "framed"
              ? selectedBorder.acf.price
                ? Number(selectedBorder.acf.price)
                : 0
              : 0,
        }));
      }
    }
    if (selectedPaper) {
      consoleLogger(
        "selectedPaper Price",
        selectedPaper.acf.will_affect_price,
        selectedPaper.acf.price
      );
      if (selectedPaper.acf.will_affect_price) {
        let prices = [];
        if (modPrices && modPrices.length > 0) {
          prices = modPrices.map((i) => i);
        }
        setModPrices((prices) => ({
          ...prices,
          paper: selectedPaper.acf.price ? Number(selectedPaper.acf.price) : 0,
        }));
      }
    }
    if (selectedCanvas) {
      consoleLogger(
        "selectedCanvas Price",
        selectedCanvas.acf.will_affect_price,
        selectedCanvas.acf.price
      );
      if (selectedCanvas.acf.will_affect_price) {
        let prices = [];
        if (modPrices && modPrices.length > 0) {
          prices = modPrices.map((i) => i);
        }
        setModPrices((prices) => ({
          ...prices,
          canvas: selectedCanvas.acf.price
            ? Number(selectedCanvas.acf.price)
            : 0,
        }));
      }
    } // eslint-disable-next-line
  }, [
    aspectRatio,
    selectedSize,
    selectedFrame,
    selectedBorder,
    selectedPaper,
    selectedCanvas,
    selectedType,
  ]); // eslint-disable-line

  // SET TOTAL ITEM PRICE
  React.useEffect(() => {
    // set total price
    consoleLogger("BASE PRICE ============", basePrice);
    consoleLogger("FEE ============", fee);
    consoleLogger("MOD PRICES ============", modPrices);
    consoleLogger("PRICE FACTOR ============", priceFactor);
    let mprice = 0;
    if (modPrices.canvas) {
      mprice += Number(modPrices.canvas) * Number(priceFactor);
    }
    if (modPrices.paper) {
      mprice += Number(modPrices.paper) * Number(priceFactor);
    }
    if (modPrices.frame) {
      mprice += Number(modPrices.frame) * Number(priceFactor);
    }
    if (modPrices.border) {
      mprice += Number(modPrices.border) * Number(priceFactor);
    }

    const totalPrice = Number(basePrice) + Number(fee) + Number(mprice);
    consoleLogger(
      "TOTAL PRICE ============",
      `${totalPrice} * ${currency.value}`
    );
    setTotalItemPrice(totalPrice);
    // }
  }, [basePrice, modPrices, priceFactor, fee, currency]); // eslint-disable-line

  const [loginOpen, setLoginOpen] = React.useState(false);
  const handleLoginModal = (bool) => {
    setLoginOpen(bool);
  };
  const [addressOpen, setAddressOpen] = React.useState(false);
  const handleAddressModal = (bool) => {
    setAddressOpen(bool);
  };
  const handleAddToCart = async () => {
    let allowed = true;
    // check if authenticated
    if (authenticated) {
      // check if user is a collector
      if (userInfo.role !== "sf_creator") {
        // check if user has setup a mailing address
        if (accountCompletion.hasAddress) {
          // check if user country is same with system country origin
          if (selectedType.slug !== "print-only") {
            if (
              userInfo.acf.billing_address.country_code !==
              siteSettings.countryOriginCode
            ) {
              allowed = false;
              toast.warning(
                "Sorry, the product type you are trying to order is not available for international customers.",
                { ...toastOptions, type: toast.TYPE.WARNING }
              );
            }
          }
        } else {
          // show address setup reminder if user has not setup a mailing address
          allowed = false;
          handleAddressModal(true);
        }
      } else {
        // show error if user is a creator
        allowed = false;
        toast.warning("You are not allowed to do that.", {
          ...toastOptions,
          type: toast.TYPE.WARNING,
        });
      }
    } else {
      // show login modal if user is not authenticated
      allowed = false;
      handleLoginModal(true);
    }

    // if everything checks out, continue to add item to cart
    if (allowed) {
      let mprice = 0;
      if (modPrices.canvas) {
        mprice += Number(modPrices.canvas) * Number(priceFactor);
      }
      if (modPrices.paper) {
        mprice += Number(modPrices.paper) * Number(priceFactor);
      }
      if (modPrices.frame) {
        mprice += Number(modPrices.frame) * Number(priceFactor);
      }
      if (modPrices.border) {
        mprice += Number(modPrices.border) * Number(priceFactor);
      }
      const print_fees = Number(fee) + Number(mprice);
      const tempItem = {
        id: vendorInfo.id,
        name: vendorInfo.name,
        slug: vendorInfo.slug,
        email: vendorInfo.email,
        profile_picture: vendorInfo.acf.profile_picture,
        subscription_type: vendorInfo.acf.subscription_type,
        item: {
          id: viewerData.ID,
          title: he.decode(viewerData.post_title),
          description: he.decode(viewerData.post_excerpt),
          slug: viewerData.post_name,
          vendorId: vendorInfo.id,
          vendorSlug: vendorInfo.slug,
          selectedType: selectedType,
          selectedSize: selectedSize,
          selectedFrame: selectedFrame ? selectedFrame : null,
          selectedPaper: selectedPaper ? selectedPaper : null,
          selectedBorder: selectedBorder ? selectedBorder : null,
          selectedCanvas: selectedCanvas ? selectedCanvas : null,
          base_price: basePrice,
          print_fees: print_fees,
          price: totalItemPrice,
          media: viewerData.media,
          quantity: quantity,
        },
      };
      consoleLogger("================= tempItem", tempItem);
      dispatch(cartSlice.actions.addToCart(tempItem));
      let itemDescription = `Print Type: ${selectedType.name} | `;
      if (selectedType) {
        itemDescription += `Size: ${selectedType.name} | `;
      }
      if (selectedFrame) {
        itemDescription += `Frame: ${selectedFrame.name} | `;
      }
      if (selectedBorder) {
        itemDescription += `Border: ${selectedBorder.name} | `;
      }
      if (selectedPaper) {
        itemDescription += `Print Material: ${selectedPaper.name} | `;
      }
      if (selectedCanvas) {
        itemDescription += `Canvas: ${selectedCanvas.name} | `;
      }
      const tempName = `${vendorInfo.name} - ${viewerData.post_title} (${selectedType.name} - ${selectedSize.name})`;

      const listItem = {
        id: viewerData.ID,
        media: viewerData.media,
        author_slug: vendorInfo.slug,
        item_name: tempName,
        item_description: itemDescription,
        amount: basePrice,
        quantity: quantity,
        total_amount: totalItemPrice,
      };
      toast(<GalleryListItem item={listItem} />, {
        ...toastOptions,
        type: toast.TYPE.INFO,
        icon: false,
      });
    }
  };

  const viewerImageColRef = React.useRef();

  const viewerImageColumnFunction = () => {
    consoleLogger(
      "--------------- viewerImageColumnFunction ------------------"
    );
    dispatch(
      uiSlice.actions.updateViewerUI({
        width: viewerImageColRef.current.offsetWidth,
        height: viewerImageColRef.current.offsetHeight,
      })
    );
  };

  React.useEffect(() => {
    const removeContextMenu = (event) => {
      if (event.target.parentNode.className.indexOf("iiz") > -1) {
        event.preventDefault();
      }
      consoleLogger(event.target.parentNode.className);
    };
    if (viewerImageColRef.current) {
      consoleLogger(
        "viewerImageColRef ==============",
        viewerImageColRef.current.offsetWidth,
        viewerImageColRef.current.offsetHeight
      );
      viewerImageColumnFunction();
      window.addEventListener("resize", viewerImageColumnFunction);
      document.addEventListener("contextmenu", removeContextMenu);
    }

    return () => {
      window.removeEventListener("resize", viewerImageColumnFunction);
      document.removeEventListener("contextmenu", removeContextMenu);
    };
  }, [viewerImageColRef, showViewer]); // eslint-disable-line

  const [processLikes, setProcessLikes] = React.useState(false);
  const handleLikes = async () => {
    if (authenticated) {
      setProcessLikes(true);
      consoleLogger("LIKE =============================");
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/like/${itemId}`,
        method: "GET",
        params: {
          userId: userInfo.id,
          userKey: userKey,
        },
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((res) => {
          dispatch(uiSlice.actions.setViewerData(res.data));
          dispatch(vendorSlice.actions.updateVendorProducts(res.data));
        })
        .catch((err) => {
          consoleLogger(err);
        });
      setProcessLikes(false);
    } else {
      handleLoginModal(true);
    }
  };

  return (
    <Portal open={showViewer}>
      <ViewerContainer
        className={cn("itemViewer dimmer visible", {
          show: showViewer,
        })}
      >
        <ToastContainer
          containerId={"viewerToaster"}
          closeButton={false}
          enableMultiContainer={true}
        />
        <div className="itemViewer-wrapper">
          <>
            <div className="itemViewer-header">
              <>
                <Button
                  icon="arrow left"
                  circular
                  size="large"
                  className="hideViewer"
                  onClick={hideViewer}
                  disabled={!(viewerFetching === !viewerImageDone)}
                />
                <h1>
                  {!viewerFetching && viewerData ? (
                    <>
                      <span>
                        {vendorInfo.name} - {he.decode(viewerData.post_title)}
                      </span>
                      <Helmet>
                        <meta charSet="utf-8" />
                        <title>
                          {vendorInfo.name} - {he.decode(viewerData.post_title)}
                        </title>
                        <link
                          rel="canonical"
                          href={`${process.env.REACT_APP_DOMAIN}/@${username}/gallery/${itemId}`}
                        />
                      </Helmet>
                    </>
                  ) : (
                    <>...</>
                  )}
                </h1>
                <div className="gallerySlideControl">
                  {/* {viewerIsSlider &&
                    <>
                      <div className='gallerySlideCounter'>
                        {vendorProductIndex} <span className='gallerySlideCounterFade'> / {vendorProducts.length}</span>
                      </div>
                      <Button.Group>
                        <Button
                          circular
                          basic
                          icon='arrow left'
                          className='prev'
                          size='tiny'
                          onClick={handlePrevGalleryItem}
                          disabled={!!(vendorProductIndex === 1 || !(viewerFetching === !viewerImageDone))}
                        />
                        <Button
                          circular
                          basic
                          icon='arrow right'
                          className='next'
                          size='tiny'
                          onClick={handleNextGalleryItem}
                          disabled={!!(vendorProductIndex === (vendorProducts.length) || !(viewerFetching === !viewerImageDone))}
                        />
                      </Button.Group>
                    </>
                  } */}
                  {/* <Button
                    icon='close'
                    circular
                    negative
                    className='hideViewer'
                    onClick={hideViewer}
                    disabled={!(viewerFetching === !viewerImageDone)}
                  /> */}
                </div>
              </>
              <Progress
                indicating
                percent={progressLoaded}
                color="blue"
                size="tiny"
                className={cn("viewerProgress", {
                  done: progressLoaded >= 100,
                })}
              />
            </div>
            <div className={`itemViewer-body`}>
              <div className="itemViewer-body-row">
                <div ref={viewerImageColRef} className={`column image`}>
                  {selectedType && viewerData && (
                    <>
                      {viewerData.post_excerpt && (
                        <div className="viewerImagePreview_description">
                          <div className="viewerImagePreview_descriptionIcon">
                            <Icon name="info circle" size="large" />
                          </div>
                          <div className="viewerImagePreview_descriptionWrapper">
                            {viewerData.post_excerpt}
                          </div>
                        </div>
                      )}
                      {viewerData.acf.tags && viewerData.acf.tags.length > 0 && (
                        <div className="viewerImagePreview_tags">
                          <div className="viewerImagePreview_tagsIcon">
                            <Icon name="tags" size="large" />
                          </div>
                          <div className="viewerImagePreview_tagsWrapper">
                            {viewerData.acf.tags &&
                              viewerData.acf.tags.map((tag, index) => (
                                <span className="tag">
                                  <span>{tag}</span>
                                </span>
                              ))}
                          </div>
                        </div>
                      )}
                      <ViewerImagePreview
                        src={viewerData.media.full}
                        alt={viewerData.slug}
                        type={selectedType}
                        frame={selectedFrame}
                        border={selectedBorder}
                        paper={selectedPaper}
                        canvas={selectedCanvas}
                      />
                      {!!viewerData.acf.limited_edition && (
                        <div className="viewerLimitedEdition">
                          <div className="label">Limited</div>
                          <div className="context">
                            {isAvailable ? (
                              <>
                                {`Current Copy: `}
                                <NumberFormat
                                  decimalScale={0}
                                  value={
                                    Number(viewerData.acf.sold_copies) +
                                    quantity +
                                    cartQuantity
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                {` of `}
                                <NumberFormat
                                  decimalScale={0}
                                  value={viewerData.acf.number_lots}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </>
                            ) : (
                              <>
                                {`Sold out `}
                                <NumberFormat
                                  decimalScale={0}
                                  value={viewerData.acf.number_lots}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                {Number(viewerData.acf.number_lots) > 1
                                  ? ` copies`
                                  : ` copy`}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="column details">
                  {viewerFetching ? (
                    <Dimmer active inverted className="detailsDimmer">
                      <Loader size="large" inverted>
                        Loading details...
                      </Loader>
                    </Dimmer>
                  ) : (
                    <>
                      {viewerData && (
                        <>
                          <div className="viewerOptionWrapper">
                            <ViewerOptionsTypes
                              selectedType={selectedType}
                              handleSelectType={handleSelectType}
                            />

                            <ViewerOptionsSizes
                              selectedType={selectedType}
                              selectedSize={selectedSize}
                              aspectRatio={aspectRatio}
                              handleSelectSize={handleSelectSize}
                            />

                            {selectedType && selectedType.acf.has_frames && (
                              <ViewerOptionsFrames
                                selectedType={selectedType}
                                selectedFrame={selectedFrame}
                                handleSelectFrame={handleSelectFrame}
                              />
                            )}

                            {selectedType && selectedType.acf.has_border && (
                              <div className="viewerOptionGroup">
                                <Header
                                  as="h3"
                                  className="viewerHeader selectBorderHeader"
                                >
                                  {selectedType.acf.border_options_label}
                                  <Header.Subheader></Header.Subheader>
                                </Header>

                                <Item.Group
                                  size="large"
                                  className="selectSize"
                                  link
                                >
                                  {selectedType.acf.border_options.map(
                                    (brdr) => (
                                      <Item
                                        key={brdr.slug}
                                        name={brdr.slug}
                                        className={cn({
                                          active:
                                            selectedBorder &&
                                            Number(selectedBorder.term_id) ===
                                              Number(brdr.term_id)
                                              ? true
                                              : false,
                                        })}
                                        onClick={() => handleSelectBorder(brdr)}
                                      >
                                        <Item.Content>
                                          <Item.Header>{brdr.name}</Item.Header>
                                        </Item.Content>
                                      </Item>
                                    )
                                  )}
                                </Item.Group>
                              </div>
                            )}

                            {selectedType && selectedType.acf.has_paper && (
                              <div className="viewerOptionGroup last">
                                <Header
                                  as="h3"
                                  className="viewerHeader selectPaperHeader"
                                >
                                  {selectedType.acf.paper_options_label}
                                  <Header.Subheader></Header.Subheader>
                                </Header>

                                <Item.Group
                                  size="large"
                                  className="selectSize"
                                  link
                                >
                                  {selectedType.acf.paper_options.map((ppr) => (
                                    <Item
                                      key={ppr.slug}
                                      name={ppr.slug}
                                      className={cn({
                                        active:
                                          selectedPaper &&
                                          Number(selectedPaper.term_id) ===
                                            Number(ppr.term_id)
                                            ? true
                                            : false,
                                      })}
                                      onClick={() => handleSelectPaper(ppr)}
                                    >
                                      <Item.Content>
                                        <Item.Header>{ppr.name}</Item.Header>
                                      </Item.Content>
                                    </Item>
                                  ))}
                                </Item.Group>
                              </div>
                            )}

                            {selectedType && selectedType.acf.has_canvas && (
                              <div className="viewerOptionGroup">
                                <Header
                                  as="h3"
                                  className="viewerHeader selectPaperHeader"
                                >
                                  {selectedType.acf.canvas_options_label}
                                  <Header.Subheader></Header.Subheader>
                                </Header>

                                <Item.Group
                                  size="large"
                                  className="selectSize"
                                  link
                                >
                                  {selectedType.acf.canvas_options.map(
                                    (cnvs) => (
                                      <Item
                                        key={cnvs.slug}
                                        name={cnvs.slug}
                                        className={cn({
                                          active:
                                            selectedCanvas &&
                                            Number(selectedCanvas.term_id) ===
                                              Number(cnvs.term_id)
                                              ? true
                                              : false,
                                        })}
                                        onClick={() => handleSelectCanvas(cnvs)}
                                      >
                                        <Item.Content>
                                          <Item.Header>{cnvs.name}</Item.Header>
                                        </Item.Content>
                                      </Item>
                                    )
                                  )}
                                </Item.Group>
                              </div>
                            )}
                          </div>

                          <div className="cartActions">
                            {totalItemPrice && selectedSize && (
                              <>
                                <div
                                  className={cn("cartActions-likes", {
                                    liked: userLikesIt,
                                  })}
                                >
                                  <Button
                                    type="button"
                                    icon
                                    onClick={handleLikes}
                                    loading={processLikes}
                                    disabled={processLikes}
                                  >
                                    <Icon
                                      name={
                                        userLikesIt ? "heart" : "heart outline"
                                      }
                                    />
                                    <Label color="white" circular>
                                      {shortNumber(viewerData.acf.likes.length)}
                                    </Label>
                                  </Button>
                                </div>
                                <div className="cartActions-price">
                                  <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={cleanPrice(
                                      Number(totalItemPrice) *
                                        Number(currency.value)
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={currency.symbol}
                                  />
                                </div>
                                <div className="cartActions-groupBuy">
                                  <div className="cartActions-quantity">
                                    <Button
                                      type="button"
                                      className="button minus"
                                      onClick={() =>
                                        handleQuantityChange("dec")
                                      }
                                      disabled={
                                        !(
                                          selectedType &&
                                          selectedSize &&
                                          totalItemPrice &&
                                          isAvailable
                                        )
                                      }
                                    >
                                      <Icon name="minus" size="tiny" />
                                    </Button>
                                    <input
                                      name="quantity"
                                      autoComplete="off"
                                      className="input"
                                      defaultValue="1"
                                      value={quantity}
                                      onChange={(ev) => handleQuantity(ev)}
                                      onClick={handleQuantityFocus}
                                    />
                                    <Button
                                      type="button"
                                      className="button plus"
                                      onClick={() =>
                                        handleQuantityChange("inc")
                                      }
                                      disabled={
                                        !(
                                          selectedType &&
                                          selectedSize &&
                                          totalItemPrice &&
                                          isAvailable
                                        )
                                      }
                                    >
                                      <Icon name="plus" size="tiny" />
                                    </Button>
                                  </div>
                                  <Button
                                    positive
                                    type="button"
                                    size="big"
                                    disabled={
                                      !(
                                        selectedType &&
                                        selectedSize &&
                                        totalItemPrice &&
                                        isAvailable
                                      )
                                    }
                                    onClick={handleAddToCart}
                                    className="cartActions-buyButton"
                                  >
                                    <Icon name="cart" />
                                  </Button>
                                </div>
                              </>
                            )}
                            {/* <Button
                                // to={`/@${username}/gallery`}
                                basic
                                size='big'
                                onClick={hideViewer}
                              >
                                <Icon name='arrow left' />
                              </Button> */}
                          </div>
                          {/* {JSON.stringify(vendorProductIndex)} / {JSON.stringify(galleryItems.length)} */}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="itemViewer-overlay" />
        {loginOpen && (
          <LoginModal
            customer
            open={loginOpen}
            handler={handleLoginModal}
            to={currentPathname}
          />
        )}
        {addressOpen && (
          <AddressModal open={addressOpen} handler={handleAddressModal} />
        )}
      </ViewerContainer>
    </Portal>
  );
};

export default React.memo(Viewer);
