import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import he from 'he'
import cn from 'classnames'
import { toast } from 'react-toastify';
import { consoleLogger, usePrevious } from '@Utils'
import { orderBy } from 'lodash'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import gallerySlice, { getGalleryState } from '@Slices/gallerySlice' // eslint-disable-line
import uiSlice from '@Slices/uiSlice'

// FORMIK
import { Formik, Form, Field, FieldArray, useFormikContext} from 'formik';
import * as Yup from 'yup'

// COMPONENTS
import { Grid, Form as SUIForm, TextArea, Label, Button, Header, Modal } from 'semantic-ui-react'
import { PagePreloader } from '@Common'

// STYLES
import { CollectionsFormContainer } from './CollectionsFormStyles'
import { CollectionsSelection, CoverPhoto, GalleryDND } from './Components';

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  post_title: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(255, "Must be shorter than 255 characters")
    .required("Must enter a title"),
  post_excerpt: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(255, "Must be shorter than 255 characters"),
  cover_image: Yup.string()
    .required("Must have a cover photo"),
  limited_edition: Yup.bool(),
})

const CollectionsForm = (props) => {
  const { mode, collectionData } = props
  const token = localStorage.Authorization
  const history = useHistory()
  const dispatch = useDispatch()
  const { userInfo, userKey, authenticated, userGallery, userCollections } = useSelector(getUserState)
  const { gallerySizes, gallerySizeTypes } = useSelector(getGalleryState)
  const [ isLoading, setIsLoading ] = React.useState(true)
  const [ isReady, setIsReady ] = React.useState(false)
  const [ itemData, setItemData ] = React.useState()
  const [ itemIndex, setItemIndex ] = React.useState()
  const [ isLimited, setIsLimited ] = React.useState(false)
  const [ galleryData, setGalleryData ] = React.useState()
  const [ nowCollectionData, setNowCollectioNData ] = React.useState()

  const initialCollectionData = usePrevious(mode !== 'new' ? collectionData  : null)
  
  const [deleteTarget, setDeleteTarget] = React.useState({})
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalBusy, setModalBusy] = React.useState(false)
  
  const [ coverImage, setCoverImage ] = React.useState()
  const [ dndRefresh, setDndRefresh ] = React.useState(false)
  const handleCoverImage = (data, formHandler) => {
    consoleLogger('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX handleCoverImage XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX', data)
    // forceRender()
    setCoverImage(data)
    setNowCollectioNData(prev => ({
      ...prev,
      cover_image: data
    }))
    formHandler('cover_image', data.id)
  }

  const [ draggable, setDraggable ] = React.useState(false)
  const handleToggleDraggable = (bool) => {
    setDraggable(prev => !prev)
  }
  const [ contentOpen, setContentOpen ] = React.useState(false)
  const handleContentModal = (bool) => {
    setContentOpen(bool)
  }

  const handleDeleteModal = (bool) => {
    setModalOpen(bool)
  }
  
  const handleDeleteItem = async (deleteTarget) => {
    // permanently delete sf-gallery item
    let errors = 0
    setModalBusy(true)
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/collections/${deleteTarget.id}`,
      headers: {
        Authorization:`${token}`,
      },
      method: 'delete',
      data: {
        id: deleteTarget.id,
        user_key: userKey,
        author: userInfo.id,
      },
    }).then((res) => {
      consoleLogger("SF-COLLECTION DELETED.... ", res)
      dispatch(userSlice.actions.setCollections(res.data))
      toast.warn(`Collection item deleted - ${deleteTarget.title}`, { containerId: 'mainToaster' });
    }).catch(err => {
      errors += 1
      consoleLogger(err)
    })

    if (errors === 0) {
      history.push('/admin/collections')
    }
  }

  const handleClose = () => {
    history.push(`/admin/collections`)
  }

  React.useEffect(() => {
    (async () => {
      consoleLogger('itemData =============', itemData)
      if (itemData && !modalBusy) {
        // set gallery data
        // let tempGalleryData = []
        // const galleryDataPromise = itemData.gallery_data.map(async (n, index) => {
        //   tempGalleryData[index] = userGallery.filter(i => i.id === n.id)[0]
        // })
        // await Promise.all(galleryDataPromise)
        consoleLogger('----------------', itemData.gallery_data.map(n => userGallery.filter(i => i.id.toString() === n.id.toString())[0]))
        let tempData = itemData.gallery_data.map((n, index) => userGallery.filter(i => i.id.toString() === n.id.toString()).map(o => ({
          ...o,
          collection_order: index + 1,
        }))[0])
        let tempData2 = orderBy(tempData, 'collection_order')
        consoleLogger(tempData2)
        setGalleryData(tempData2)
        setCoverImage(itemData.cover_image)
        setDeleteTarget({id: itemData.id, title: itemData.title})
      }
    })()
  }, [itemData]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (mode !== 'new') {
        setNowCollectioNData(collectionData)
        consoleLogger('============ COLLECTION ID =================', collectionData.ID)
        if (!authenticated) {
          const encodedPath = encodeURIComponent(`/admin/collections/view/${collectionData.ID}`)
          // history.push(`/login/redirect/${encodedPath}`)
        } else {
          if (collectionData.ID) {
            let tempData
            if (userCollections.filter(i => i.ID === collectionData.ID).map(n => n).length > 0) {
              tempData = userCollections.filter(i => i.ID === collectionData.ID).map(o => o)[0]
              setItemData(tempData)
              setIsLimited(!!tempData.limited_edition)
            } else {
              // history.push(`/`)
            }
            // set index
            let idx = 0
            userCollections.forEach((item, index) => {
              if (item.id === collectionData.ID) {
                idx = index + 1
              }
            })
            setItemIndex(idx)
          }
        }
      } else {
        setIsReady(true)
        setIsLoading(false)
        consoleLogger('NEW!!!!!!!!!!!!!!!!!!!!!')
      }
    })()
  }, [collectionData, userCollections, userGallery, mode]) // eslint-disable-line
  
  React.useEffect(() => {
    if (mode === 'new') {
      setIsReady(true)
    }
    return(() => {
      dispatch(uiSlice.actions.setCurrentPageTitle())
      setGalleryData()
      setItemData()
    })
  }, []) // eslint-disable-line

  React.useEffect(() => {
    // consoleLogger('################# nowCollectionData ##################', nowCollectionData)
    consoleLogger('======================= COLLECTION MODE ======================')
    consoleLogger(mode)
    consoleLogger('======================= INITIAL COLLECTION DATA ======================')
    consoleLogger(initialCollectionData)
    consoleLogger('======================= NOW COLLECTION DATA ======================')
    consoleLogger(nowCollectionData)
  }, [initialCollectionData, nowCollectionData, mode])


  // const [ galleryDataValue, setGalleryDataValue ] = React.useState(collectionData ? collectionData.gallery_data : '')
  const handleGalleryDataValue = (data, formHandler) => {
    if (!draggable) {
      forceRender()
      // setIsReady(false)
    }
    consoleLogger('============================ handleGalleryDataValue ===========================')
    consoleLogger(data)
    // setGalleryDataValue(data)
    let tempData = data.map((n, index) => userGallery.filter(i => i.id.toString() === n.id.toString()).map(o => ({
      ...o,
      collection_order: index + 1,
    }))[0])
    let tempData2 = orderBy(tempData, 'collection_order')
    let tData = data.map((n, index) => userGallery.filter(i => i.id.toString() === n.id.toString()).map(o => ({
      id: o.id,
      collection_order: index + 1,
    }))[0])
    let tData2 = orderBy(tData, 'collection_order')
    consoleLogger(tempData2)
    setGalleryData(tempData2)
    consoleLogger('============================ tData2', tData2)
    consoleLogger('============================ tempData2', tempData2)
    setNowCollectioNData(prev => ({
      ...prev,
      gallery_data: tData2
    }))

    formHandler('gallery_data', JSON.stringify(tData2))
  }

  const forceRender = () => {
    setDndRefresh(true)
    setTimeout(() => {
      setDndRefresh(false)
    }, 500)
  }

  React.useEffect(() => {
    (async () => {
      consoleLogger('=============== USE EFFECT: galleryData =============')
      consoleLogger(galleryData)
      if (galleryData) {
        dispatch(uiSlice.actions.setPageTemplate('dash'))
        dispatch(uiSlice.actions.setCurrentPage(`collections`))
        dispatch(uiSlice.actions.setCurrentPageURL(`admin/collections`))
        dispatch(uiSlice.actions.setCurrentPageTitle(`${itemData ? itemData.title : 'New'}`))

        setIsReady(true)
        setIsLoading(false)
        consoleLogger('READY!!!!!!!!!!!!!!!!!!!!!')
      }
    })()
  }, [galleryData]) // eslint-disable-line

  return (
    <CollectionsFormContainer>
      {isReady &&
        <Formik
          // enableReinitialize={true}
          initialValues={{
            post_title: mode !== 'new' ? he.decode(collectionData.title) : '',
            post_excerpt: mode !== 'new' ? he.decode(collectionData.description) : '',
            cover_image: mode !== 'new' ? collectionData.cover_image.id : '',
            gallery_data: mode !== 'new' ? JSON.stringify(collectionData.gallery_data) : [],
            limited_edition: mode !== 'new' ? collectionData.limited_edition : false,
            active: mode !== 'new' ? collectionData.active : false,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, {setErrors, setSubmitting}) => {
            (async () => {
              // data.post_status = data.statusCheck ? 'publish' : 'pending'
              setSubmitting(true)
              // setIsReady(false)
              consoleLogger("================= SUBMIT FORM DATA =================")
              consoleLogger(data)
              let tempId
              let errors = 0
              if (mode === 'new') {
                await axios({
                  baseURL: `${process.env.REACT_APP_API_URL}`,
                  url: `/stylefolio/v1/collections`,
                  method: 'PUT',
                  headers: {
                    Authorization:`${token}`,
                  },
                  data: {
                    ...data,
                    author: userInfo.id,
                    user_key: userKey,
                  },
                }).then(res => {
                  consoleLogger("NEW GALLERY RESPONSE ==========", res.data)
                  // toast message
                  toast.success(`New collection created - ${res.data.new.title}`, {
                    containerId: 'mainToaster',
                    progress: undefined,
                  });
                  tempId = res.data.new.id
                  dispatch(uiSlice.actions.setCurrentPage(`collections`))
                  dispatch(uiSlice.actions.setCurrentPageURL(`admin/collections`))
                  dispatch(uiSlice.actions.setCurrentPageTitle(`${res.data.new.title}`))
                  dispatch(userSlice.actions.setCollections(res.data.collections))
                }).catch(err => {
                  consoleLogger(err)
                  errors += 1
                  // setErrors(err.response.data)
                })
              } else {
                await axios({
                  baseURL: `${process.env.REACT_APP_API_URL}`,
                  url: `/stylefolio/v1/collections/${nowCollectionData.ID}`,
                  method: 'POST',
                  headers: {
                    Authorization:`${token}`,
                  },
                  data,
                }).then(res => {
                  consoleLogger("GALLERY RESPONSE ==========", res.data)
                  // toast message
                  toast.success(`Collection updated - ${res.data.title}`, { containerId: 'mainToaster' });

                  setItemData(res.data)
                  dispatch(uiSlice.actions.setCurrentPage(`collections`))
                  dispatch(uiSlice.actions.setCurrentPageURL(`admin/collections`))
                  dispatch(uiSlice.actions.setCurrentPageTitle(`${res.data.title}`))
                  dispatch(userSlice.actions.updateUserCollection(res.data))
                }).catch(err => {
                  consoleLogger(err)
                  errors += 1
                  setErrors(err.response.data)
                })
              }

              if (mode === 'new') {
                if (errors === 0) {
                  history.push(`/admin/collections/view/${tempId}`)
                } else {
                  toast.error(`Collection creation failed`, { containerId: 'mainToaster' });
                  history.push(`/admin/collections`)
                }
              } else {
                setSubmitting(false)
                // setIsReady(true)
                setDraggable(false)
              }
            })()
          }}
        >
          {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setFieldValue, values, errors, isValid, dirty}) => (
            <Form className='ui form'>
              <>
                <div className='section'>
                  <div className='section-wrapper'>
                    <div className='column coverColumn'>
                      <Field
                        name='cover_image'
                        type='hidden'
                        // defaultValue={mode !== 'new' ? initialCollectionData.cover_image.id : ''}
                        // onChange={(ev) => {
                        //   consoleLogger('=============== ON CHANGE COVER IMAGE', coverImage)
                        //   handleChange(ev)
                        //   // setFieldValue('cover_image', nowCollectionData.cover_image.id)
                        // }}
                        // onBlur={handleBlur}
                      />
                      <CoverPhoto
                        collectionData={nowCollectionData}
                        handler={(data) => handleCoverImage(data, setFieldValue)}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className='column detailsColumn'>
                      <div className='details'>
                        <Grid verticalAlign='top' className='productDetailGrid'>
                          <Grid.Row className='formGridRow'>
                            <Grid.Column width={16}>
                              <SUIForm.Field
                                disabled={isSubmitting}
                                error={errors.post_title}
                              >
                                <label>Title *</label>
                                <Field
                                  fluid 
                                  type='text'
                                  name='post_title'
                                  icon='image'
                                  iconPosition='left'
                                  placeholder='Title of your collection'
                                  // onChange={(ev) => {
                                  //   consoleLogger(ev.currentTarget.value)
                                  //   handleChange(ev)
                                  //   setFieldValue('post_title', ev.currentTarget.value)
                                  // }}
                                  // onBlur={handleBlur}
                                  // defaultValue={mode !== 'new' ? initialCollectionData.title : ''}
                                  error={errors.post_title}
                                />
                                {errors.post_title &&
                                  <Label basic color='red' pointing>
                                    {errors.post_title}
                                  </Label>
                                }
                              </SUIForm.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row className='formGridRow'>
                            <Grid.Column width={16}>
                              <SUIForm.Field
                                error={errors.post_excerpt}
                                disabled={isSubmitting}
                              >
                                <label>Description</label>
                                <TextArea
                                  name='post_excerpt'
                                  rows={8}
                                  defaultValue={mode !== 'new' ? he.decode(initialCollectionData.description) : ''}
                                  placeholder='Tell us more about this collection'
                                  disabled={isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.post_excerpt &&
                                  <Label pointing='above' prompt>{errors.post_excerpt}</Label>
                                }
                              </SUIForm.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row className='formGridRow'>
                            <Grid.Column mobile={8} tablet={6} computer={8} className='formLabelColumn'>
                              <span>Limited Edition</span>
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={10} computer={8} textAlign='right' className='formLabelColumn'>
                              <div className="ui fitted toggle checkbox">
                                <Field type="checkbox" name="limited_edition" disabled={isSubmitting}
                                  onChange={(ev) => {
                                    consoleLogger(ev.currentTarget.checked)
                                    setFieldValue('limited_edition', ev.currentTarget.checked)
                                  }}
                                />
                                <label></label>
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row className='formGridRow'>
                            <Grid.Column mobile={8} tablet={6} computer={8} className='formLabelColumn'>
                              <span>Active</span>
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={10} computer={8} textAlign='right' className='formLabelColumn'>
                              {!isLoading &&
                                <div className="ui fitted toggle checkbox">
                                  <Field type="checkbox" name="active" disabled={isSubmitting}
                                    onChange={(ev) => {
                                      consoleLogger(ev.currentTarget.checked)
                                      setFieldValue('active', ev.currentTarget.checked)
                                    }}
                                  />
                                  <label></label>
                                </div>
                              }
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='galleryContent'>
                  {mode !== 'new' &&
                    <>
                      <div className='dashContentHeader sticky'>
                        <h3>Collection Content</h3>
                        {galleryData && galleryData.length > 0 &&
                          <div className='extraActions'>
                            <SUIForm.Checkbox
                              toggle
                              checked={draggable}
                              onChange={handleToggleDraggable}
                              name='toggleDragaable'
                              label='Arrange Content'
                              disabled={isSubmitting}
                            />
                            <Button
                              type='button'
                              icon='plus'
                              content='Add Content'
                              labelPosition='right'
                              type='button'
                              color='blue'
                              size='large'
                              onClick={() => handleContentModal(true)}
                              disabled={draggable || isSubmitting}
                            />
                          </div>
                        }
                      </div>

                      <div className='galleryGridContainer'>
                        {isSubmitting &&
                          <PagePreloader linear />
                        }
                        <Field
                          name='gallery_data'
                          defaultValue={mode !== 'new' ? JSON.stringify(nowCollectionData.gallery_data) : []}
                          type='hidden'
                        />
                          <>
                            {galleryData && galleryData.length > 0 ?
                              <>
                                {!dndRefresh &&
                                  <GalleryDND cid={nowCollectionData ? nowCollectionData.id : ''} galleryData={galleryData} draggable={draggable} handleDataValue={(data) => handleGalleryDataValue(data, setFieldValue)} />
                                }
                              </> : 
                              <div className='empty'>
                                <p>You have not yet added any gallery item on this collection...</p>
                                <Button type='button' icon='plus' content='Add Content' labelPosition='right' type='button' color='blue' size='large' onClick={() => handleContentModal(true)} />
                              </div>
                            }
                          </>
                      </div>
                    </>
                  }
                </div>

                <div className='actions'>
                  <div className='actionsGroup'>
                    {/* <div className='productActionNav'>
                      <div className='productActionNavText'>{itemIndex}<span> / {userCollections.length}</span></div>
                      <div className='productActionNavGroup'>
                        <Button basic circular size='tiny' type='button' icon='chevron left' onClick={handlePrevGalleryItem}
                          disabled={itemIndex === 1 || isLoading}
                        />
                        <Button basic circular size='tiny' type='button' icon='chevron right' onClick={handleNextGalleryItem}
                          disabled={itemIndex === userCollections.length || isLoading}
                        />
                      </div>
                    </div> */}
                    <div className='actionButtons'>
                      <Button type='button' icon='arrow left' content={<span>Cancel</span>} labelPosition='left' basic onClick={handleClose} />
                      {mode !== 'new' &&
                        <Button
                          type='button'
                          negative
                          onClick={() => handleDeleteModal(true, itemData.id, itemData.title)}
                          disabled={isSubmitting || isLoading}
                          icon='trash'
                          labelPosition='right'
                          content={<span>Delete Permanently</span>}
                        />
                      }
                      <>
                        <Button
                          type='submit'
                          positive
                          onClick={() => handleSubmit()}
                          loading={isSubmitting}
                          disabled={!(dirty && isValid && !isSubmitting) || isLoading}
                          icon='save'
                          content={<span>{mode !== 'new' ? 'Update' : 'Create'}</span>}
                          labelPosition='right'
                        />
                      </>
                    </div>
                  </div>
                </div>
              </>
              {contentOpen &&
                <CollectionsSelection collectionData={nowCollectionData} modalOpen={contentOpen} handleModal={handleContentModal} handleDataValue={(data) => handleGalleryDataValue(data, setFieldValue)} />
              }
              {modalOpen &&
                <Modal
                  size='mini'
                  open={modalOpen}
                  dimmer={{
                      blurring: true
                  }}
                  closeOnDimmerClick={!modalBusy}
                  closeOnEscape={!modalBusy}
                  onOpen={() => handleDeleteModal(true)}
                  onClose={() => handleDeleteModal(false)}
                >
                  <Modal.Header>Deleting Collection</Modal.Header>
                  <Modal.Content>
                    <p>You are about to delete <span className="text--bold">{deleteTarget.title}</span>. Are you sure you want to continue?</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      basic
                      onClick={() => handleDeleteModal(false)}
                      disabled={modalBusy}
                      content='Cancel'
                    />
                    <Button
                      negative
                      icon='check'
                      labelPosition='right'
                      content='Delete'
                      onClick={() => handleDeleteItem(deleteTarget)}
                      disabled={modalBusy}
                      loading={modalBusy}
                    />
                  </Modal.Actions>
                </Modal>
              }
            </Form>
          )}
        </Formik>
      }
    </CollectionsFormContainer>
  )
}

export default React.memo(CollectionsForm)
