import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// REDUX
import { Provider } from 'react-redux'
import store from '@Redux/store'

// COMPONENTS
import App from './App'

// UI Library
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-less/semantic.less'
import './index.less';

ReactDOM.render(
  <>
    <React.StrictMode>
      <Provider store={store}>
        <PayPalScriptProvider options={{
          vault:true,
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          // "data-client-token": process.env.REACT_APP_PAYPAL_ACCESS_TOKEN
        }}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PayPalScriptProvider>
      </Provider>
    </React.StrictMode>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
