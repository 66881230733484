import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const EditGalleryContainer = styled.div`
  padding: 2rem;
  width: 100%;

  @media ${stylevars.breakpoints.phone} {
    padding: 1rem;
  }

  .listImageColumn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem !important;
    .iiz {
      .iiz__hint {
        transition: all 0.15s ease-out;
        opacity: 0;
      }
      &:hover {
        .iiz__hint {
          opacity: 1;
        }
      }
    }
    .iiz__img {
      width: 100%;
      height: auto;
      max-height: calc(calc(var(--vh, 1vh) * 80) - 10rem);
      object-fit: contain;
      position: sticky !important;
      top: calc(${stylevars.navBar.height} + 1rem);
      @media ${stylevars.breakpoints.phone} {
        position: relative;
        top: auto;
      }
    }

    .extra {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .extra-item {
        margin: 0 0.5rem;
      }
    }
  }

  .productDetail {
    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }
    > .segment {
      border: none !important;
      width: 100%;
      margin: 0;
    }
    .productDetailHeader {
      background-color: ${stylevars.paper.bgColor};
      border: none !important;
      border-bottom: 1px solid ${stylevars.paper.borderColor} !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 0;

      h3 {
        flex-grow: 1;
        display: block;
        margin: 0;
        padding: 0 1rem;
        @media ${stylevars.breakpoints.phone} {
          padding-bottom: 1rem;
        }
      }

      @media ${stylevars.breakpoints.phone} {
        display: block;
      }

      .publishToggle {
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        line-height: 0;
        @media ${stylevars.breakpoints.phone} {
          justify-content: flex-start;
        }
        > span {
          font-weight: bold;
          font-size: 1rem;
          margin-right: 0.5rem;
        }
      }
    }

    .featuredToggle {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      line-height: 0;
      padding-left: 1rem;
      > span {
        font-size: 1rem;
        margin-right: 0.5rem;
      }
    }

    .printTypeListBox {
      padding: 0;
    }

    .printTypeList {
      .item {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        padding: 1rem;
        margin: 0;

        &:hover {
          background-color: rgba(${stylevars.typography.title.colorRGB}, 0.085);
        }

        .image {
          width: 100%;
          max-width: 60px;
          img {
            width: 100%;
            height: auto;
          }
        }

        .meta {
          font-size: 0.85rem;
          margin-bottom: 1rem;
          color: ${stylevars.typography.subtitle.color};
        }

        .detail {
          flex-grow: 1;
          width: 100%;
          .header {
            text-align: left !important;
          }
        }

        .action {
          padding-left: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
        }
      }
    }
  }

  .actions {
    position: sticky !important;
    bottom: -1rem;
    z-index: 10;
    .actionsGroup {
      border-top: 1px solid ${stylevars.paper.borderColor};
      padding: 1rem 0;
      background-color: ${stylevars.paper.bgColor};
      z-index: 1001;
      width: 100%;
      height: ${stylevars.navBar.height};
      text-align: right;
      display: flex;
      flex-wrap: nowrap;
      @media ${stylevars.breakpoints.phone} {
        /* display: block; */
      }
      .productActionNav {
        padding-left: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* .productActionNavText */
        &Text {
          font-size: 1rem;
          margin-right: 0.5rem;
          > span {
            font-size: 0.85rem;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        /* .productActionNavGroup */
        &Group {
          .ui.button {
            box-shadow: none;
          }
        }

        @media ${stylevars.breakpoints.phone} {
          padding: 0 1rem;
        }
      }
      .actionButtons {
        padding-right: 1rem;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media ${stylevars.breakpoints.phone} {
          padding: 0 1rem;
          /* justify-content: center; */

          .ui.button {
            min-width: auto;
            width: auto;
            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            > .icon {
              margin: 0;
              position: relative;
              right: auto;
              top: auto;
              background-color: transparent !important;
            }
            > span {
              display: none;
            }
          }
        }
      }
    }
  }

  .formGridRow {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:first-of-type {
      padding-top: 1rem;
    }

    &:last-of-type {
      padding-bottom: 1rem;
    }
  }

  .formLabelColumn {
    font-weight: bold;
    text-align: right;
    &.adjust {
      margin-top: 0.5rem !important;
    }
    @media ${stylevars.breakpoints.phone} {
      text-align: left;
    }
  }

  .proFeatures {
    background-color: rgba(0,0,0,0.03);
    padding: 0rem;
    margin-top: 0rem;
    position: relative;
    h4 {
      margin: 0;
    }
    &.disabled {
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,0.5);
      }
    }
    .productDetailHeader {
      background: transparent;
    }
    .segment {
      border: none !important;
      background: transparent;
    }
  }

  .hiresStorage {
    width: 100%;
    padding: 1rem;
    .hiresStorageHelpText {
      color: rgba(0,0,0,0.5);
    }
  }

  .displayValue {
    border: 1px solid rgba(0,0,0,0.15);
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: rgba(255,255,255,0.5);
    line-height: 1.6;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 32px;
    width: 100%;
  }
`