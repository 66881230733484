import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";

export const StyledContainer = styled.div`
  padding: 0rem;

  .viewAllCreators {
    width: 100%;
    padding: 0 2rem;

    @media ${stylevars.breakpoints.phone} {
      position: sticky;
      bottom: 0;
      padding: 0;
      z-index: 10;
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem 0;
      border-top: 1px solid ${stylevars.paper.borderColor};

      @media ${stylevars.breakpoints.phone} {
        padding: 1rem;
        background-color: ${stylevars.paper.bgColor};
      }
    }
  }
`;
