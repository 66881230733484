import React from 'react'
// import axios from 'axios'
// import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice' // eslint-disable-line
import { getUIState } from '@Slices/uiSlice' // eslint-disable-line

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  // Icon,
  // Table,
  // Button,
  // Checkbox,
  // Modal,
  Grid,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  Dropdown,
} from 'semantic-ui-react'
import {
  TablePagination,
  TableDisplaySelect
} from '../DataTable'

const TableFooter = (props) => {
  const {
    info
  } = props
  // const { userCoupons } = useSelector(getUserState) // eslint-disable-line
  // const { uiTables } = useSelector(getUIState) // eslint-disable-line
  // const [ totalPages, setTotalPages ] = React.useState(Math.ceil(userCoupons.length / 5))
  // const [ totalItems, setTotalItems ] = React.useState(userCoupons.length)

  // React.useEffect(() => {
  //   setTotalPages(Math.ceil(userCoupons.length / 5))
  //   setTotalItems(userCoupons.length)
  // }, [uiTables, userCoupons])

  return (
    <div className='tableFooter'>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={4}></Grid.Column>
          <Grid.Column mobile={16} computer={8} textAlign='center'>
            {info.totalPages > 1 &&
              <TablePagination info={info} />
            }
          </Grid.Column>
          <Grid.Column mobile={16} computer={4} textAlign='right'>
            {info.totalItems > 5 &&
              <TableDisplaySelect info={info} />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default React.memo(TableFooter)
