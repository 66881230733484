import React from 'react'
import cn from 'classnames'
import { useHistory } from 'react-router'
import {useDropzone} from 'react-dropzone'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import { findIndex } from 'lodash'
import { consoleLogger } from '@Utils'
import filesize from 'filesize'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { Button, Checkbox } from 'semantic-ui-react'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import gallerySlice from '@Slices/gallerySlice'

// COMPONENTS
import { Header, Icon, Modal, Progress, Segment } from 'semantic-ui-react'
import { UploadErrorList, GalleryUploaderThumbs } from './'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpgradePlanButton } from '../../Account/Components'

const GalleryUploader = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.Authorization

  const {
    modalOpen,
    handleModal,
    fetchGallery
  } = props

  const { siteSettings, galleryShowUploader } = useSelector(getUIState)
  const { userInfo, userKey, userGallery, authenticated } = useSelector(getUserState)
  

  const [uploadCount, setUploadCount] = React.useState(0)
  const [uploadDone, setUploadDone] = React.useState(true)
  const [uploadFiles, setUploadFiles] = React.useState([])
  const [uploadFilesReady, setUploadFilesReady] = React.useState([])
  const [rejectedFiles, setRejectedFiles] = React.useState()
  const [ uploadReady, setUploadReady ] = React.useState(false)
  const [ isUploading, setIsUploading ] = React.useState(false)
  const [ mainProgress, setMainProgress ] = React.useState(0)

  const [ totalItems, setTotalItems ] = React.useState(userGallery.length)
  const [ uploadLimit, setUploadLimit ] = React.useState()

  const handleUploadModalToggle = (bool) => {
    handleModal(bool)
    // dispatch(uiSlice.actions.setShowGalleryUploader(bool))
    // if (!bool) {
    //   if (uploadCount.length > 0) {
    //     fetchGallery()
    //   }
    // }
  }

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive
  } = useDropzone({
    noDrag: isUploading,
    noClick: isUploading,
    noClick: true,
    accept: 'image/jpeg, image/png',
    maxSize: siteSettings.uploadFilesizeLimit,
    onDrop: (acceptedFiles, fileRejections) => {
      consoleLogger('============= acceptedFiles ============', acceptedFiles)
      let limitedFiles = acceptedFiles
      const userFileCount = Number(uploadFiles.length) + Number(acceptedFiles.length) + Number(userGallery.length)
      consoleLogger("============ FILE COUNT ===================", userFileCount, uploadLimit)
      if (uploadLimit > 0) {
        if (userFileCount > uploadLimit) {
          const adjust = Number(uploadLimit) - (Number(userGallery.length) + Number(uploadFiles.length))
          consoleLogger('Files Adjusted', adjust)
          limitedFiles = acceptedFiles.slice(0, adjust)
        }
      }
      
      setUploadDone(false)


      // check if file is in array
      let tempArray = [...uploadFiles]
      limitedFiles.forEach((file, index) => {
        if (findIndex(uploadFiles, {name: file.name, size: file.size}) <= -1) {
          tempArray.push(file)
        }
      })
      setUploadFiles(tempArray)
      setUploadFilesReady(tempArray.map((file, index) => Object.assign({
        index: index,
        preview: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        uploading: true,
        progress: 0
        // author: userInfo.id
      })))
      consoleLogger('============= fileRejections =============', fileRejections)
      setRejectedFiles(fileRejections.map((file, index) => Object.assign({
        ...file,
        index: index,
        preview: URL.createObjectURL(file.file),
      })))
      setUploadReady(true)
      // (async () => {
        // const rejectionPromise = fileRejections.map(async((file) => {
        //   let errorMsg = file.errors[0].message
        //   if (file.errors[0].code === 'file-too-large') {
        //     errorMsg = `File size is too large (File Size Limit: ${filesize(siteSettings.uploadFilesizeLimit)})`
        //   }
        // }))
      // })()
    },
    // onDropRejected: (fileRejections) => {
    // }
  })

  React.useEffect(() => {
    consoleLogger('==================== REJECTED FILES ================', rejectedFiles)
    consoleLogger(typeof rejectedFiles)
    if (typeof rejectedFiles !== 'undefined' && rejectedFiles.length > 0) {
      toast(<UploadErrorList errors={rejectedFiles} />, {
        containerId: 'uploaderToaster',
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [rejectedFiles]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("uploadFiles ===========", uploadFiles)
    if (uploadFiles.length > 0) {
      setUploadReady(true)
    } else {
      setUploadReady(false)
    }
  }, [uploadFiles]) // eslint-disable-line

  const handleUploadStart = async () => {
    setIsUploading(true)
    let tempCount = 0
    const uploadPromise = uploadFiles.map( async (file, index) => {
      consoleLogger("ACCEPTED FILE ========", index, file)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("user_key", userKey)
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/gallery`,
        timeout: 1000 * 3600,
        withCredentials: false,
        crossorigin: true,
        headers: {
          "Authorization":`${token}`,
          "Content-Type": "multipart/form-data",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        data: formData,
        onUploadProgress: (progressEvent) => {
          consoleLogger(index, progressEvent.loaded)
          setUploadFilesReady(prevState => prevState.map(file => file.index === index ? ({...file, progress: progressEvent.loaded}) : ({...file})))
        }
      }).then(res => {
        consoleLogger("UPLOAD RESPONSE ========", index, res.data)
        tempCount += 1
        setUploadCount(tempCount)
        setUploadFilesReady(prevState => prevState.map(file => file.index === index ? ({...file, uploading: false}) : ({...file})))
      }).catch(err => {
        // const errData = err.response.data
        // consoleLogger(`${errData.data.status} (${errData.code}) - ${errData.message}`)
        consoleLogger(err)
      })
    })

    await Promise.all(uploadPromise)
    setUploadDone(true)
    setTotalItems(count => count += tempCount)
    // dispatch(uiSlice.actions.setShowGalleryUploader(false))
    handleModal(false)
    fetchGallery()
  }
  
  React.useEffect(() => {
    return(() => {})
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (userInfo && siteSettings) {
      setUploadLimit(userInfo.acf.subscription_type === 'premium' ? siteSettings.premiumLimit : siteSettings.starterLimit)
    }
  }, [userInfo, siteSettings])

  React.useEffect(() => {
    if (uploadFilesReady && uploadFilesReady.length > 0) {
      const n = uploadFilesReady.map(i => i.size).reduce((a, b) => a + b) / uploadFilesReady.length
      const x = uploadFilesReady.map(i => i.progress).reduce((a, b) => a + b) + (uploadCount * n)
      const y = uploadFilesReady.map(i => i.size).reduce((a, b) => a + b) + (uploadFilesReady.length * n)
      setMainProgress((x / y)*100)
    }
  }, [uploadFilesReady]) // eslint-disable-line

  return (
    <>
      {open &&
        <Modal
          size='large'
          open={modalOpen}
          dimmer={{
              blurring: true
          }}
          onOpen={() => handleUploadModalToggle(true)}
          onClose={() => handleUploadModalToggle(false)}
          closeOnDimmerClick={!isUploading}
          className='dropzonModal'
        >
          <Modal.Header>
            Gallery Upload
            {isUploading && uploadFiles.length > 0 &&
              <Progress className='dropzoneProgress' indicating percent={mainProgress} size='tiny' />
            }
          </Modal.Header>
          <Modal.Content scrolling>
            <ToastContainer containerId={'uploaderToaster'} closeButton={false} enableMultiContainer={true} />
            <Segment placeholder {...getRootProps()}
              className={cn('dropzone', {
                'isDragActive': isDragActive
              })}
            >
              <div className='dropzoneWrapper'>
                {isDragActive ?
                  <>
                    <Header icon>
                      <Icon color='green' name='angle double down' />
                      Drop it like it's hot...
                    </Header>
                  </> : 
                  <>
                      {uploadFiles && uploadFiles.length > 0 ?
                        <>
                          {!uploadDone ?
                            <>
                              <GalleryUploaderThumbs files={uploadFiles} thumbs={uploadFilesReady} handleFiles={setUploadFiles} handleThumbs={setUploadFilesReady} isUploading={isUploading} open={open} />
                            </> : 
                            <Header icon>
                              <Icon color='green' name='check circle' />
                              <span>Creating temporary gallery items...</span>
                            </Header>
                          }
                        </> :
                        <>
                          <Header icon>
                            <Icon color='blue' name='upload cloud' />
                            <span>Upload your photos here! (Drag-and-drop or click the button below.)</span>
                          </Header>
                        </>
                      }
                  </>
                }
              </div>
              {uploadFiles && uploadFiles.length === 0 &&
                <Segment.Inline style={{pointerEvents: 'none'}}>
                  <Button icon="plus" labelPosition="right" content="Open file upload" primary className="uploaderButton" onClick={open} />
                </Segment.Inline>
              }
              <input {...getInputProps()} />
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <div className='dropzoneActionMessage'>
              {isUploading ?
                <>
                  <span>Please wait while we're uploading your files: </span>
                  <span className='text--bold'>{uploadCount}</span> / <span className='text--bold'>{uploadFiles.length}</span>
                </> :
                <>
                  {uploadFiles.length > 0 ?
                    <>
                      <span>Total of <span className='text--bold'>{uploadFiles.length}</span> items queued. Click the <span className='text--bold'>UPLOAD</span> button to proceed.</span>
                    </> : 
                    <>
                      <span>
                        {`Important: You can only upload `}
                        <NumberFormat decimalScale={0} fixedDecimalScale={false} value={uploadLimit - totalItems} displayType={'text'} thousandSeparator={true} /> 
                        {` images.`}
                        {userInfo.acf.subscription_type !== 'premium' && <UpgradePlanButton as='text' />}
                      </span>
                    </>
                  }
                </>
              }
            </div>
            <Button
              icon="close"
              labelPosition="right"
              content="Cancel"
              basic
              disabled={isUploading}
              onClick={() => handleUploadModalToggle(false)}
            />
            <Button
              icon="cloud upload"
              labelPosition="right"
              content="Upload"
              positive
              loading={isUploading}
              disabled={!uploadReady || isUploading}
              onClick={() => handleUploadStart()}
            />
          </Modal.Actions>
        </Modal>
      }
    </>
  )
}

export default React.memo(GalleryUploader)
