import React from 'react'
import { useHistory } from 'react-router'
import cn from 'classnames'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useRefresh, getResponsiveStyle} from 'muuri-react';
import { toast } from 'react-toastify';
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'

// STYLES
import { ItemContainer } from './GalleryDNDItemStyles'
import { Button, Icon } from 'semantic-ui-react';

const GalleryDNDItem = (props) => {
  const history = useHistory()
  const token = localStorage.Authorization
  const { cid, data, handleProcess, draggable } = props
  const dispatch = useDispatch()
  const { authenticated, userInfo, userKey, userGallery, userCollections } = useSelector(getUserState)
  
  const [ ready, setReady ] = React.useState(false)
  const [ processing, setProcessing ] = React.useState(false)
  const handleAfterLoad = () => {
    setReady(true)
  }
  const handleRemove = async (gid) => {
    setProcessing(true)
    handleProcess(true)
    const tempGallery = userCollections.filter(n => n.id.toString() === cid.toString()).map(i => i.gallery_data)[0]
    consoleLogger('============= tempGallery =================', tempGallery)
    const results = tempGallery.filter(n => n.id !== gid)
    consoleLogger('============= RESULTS =====================', results)

    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/collections/${cid}`,
      method: 'POST',
      headers: {
        Authorization:`${token}`,
      },
      data: {
        author: userInfo.id,
        user_key: userKey,
        gallery_data: results
      }
    }).then((res) => {
      consoleLogger('handleUpdateOrder RESPONSE =============', res.data)
      dispatch(userSlice.actions.updateUserCollection(res.data))
      // handleSetProcess(id, false)
      
      toast.warning(`Collection content updated.`, {
        containerId: 'mainToaster',
        progress: undefined,
      });
    }).catch(err => {
      consoleLogger(err)
      // handleSetProcess(id, false)
    })

    setProcessing(false)
    handleProcess(false)
    consoleLogger('handleRemove done')
  }

  const handleEdit = (gid) => {
    history.push(`/admin/gallery/view/${gid}`)
  }

  useRefresh([ready, data])
  return (
    <ItemContainer className={cn('galleryItem', {
      'limited': !!data.acf.limited_edition,
      'draggable': draggable
    })}>
      <div className='item-wrapper'>
        {!!data.acf.limited_edition &&
          <div className='item-limited'>LIMITED</div>
        }
        <div className='item-actions'>
          <Button className='remove' negative icon='close' circular onClick={() => handleRemove(data.id)} loading={processing} type='button' />
          <Button className='edit' color='blue' icon='pencil' circular onClick={() => handleEdit(data.id)} loading={processing} type='button' />
          <div className='item-title'>{data.post_title}</div>
        </div>
        <div className='item-overlay'>
          <div className='handle'>
            <Icon name='arrows alternate' />
          </div>
        </div>
        <LazyLoadImage
          wrapperClassName='imageWrapper'
          afterLoad={(ev) => {
            handleAfterLoad(ev)
          }}
          effect="blur"
          alt={data.post_title}
          src={data.media.medium}
        />
      </div>
    </ItemContainer>
  )
}

export default React.memo(GalleryDNDItem)
