import React from 'react'
// import axios from 'axios'
import { consoleLogger } from '@Utils'

// REDUX
// import { useDispatch, useSelector } from 'react-redux'
// import userSlice, { getUserState } from '@Slices/userSlice'
// import uiSlice from '@Slices/uiSlice'
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import {
  Grid,
  Icon,
  Table,
  Button,
  // Checkbox,
  // Input,
  Form,
} from 'semantic-ui-react'
import AccountSalesOverview from './AccountSalesOverview'
import AccountPayoutTable from './AccountPayoutTable'
import AccountTransactionsTable from './AccountTransactionsTable'

const AccountSales = () => {
  // const dispatch = useDispatch()
  // const { userInfo } = useSelector(getUserState)
  // const handleFetchCoupons = async () => {
  //   dispatch(uiSlice.actions.updateUIComponentReady({component: 'user-coupons', isReady: false}))
  //   await axios({
  //     baseURL: `${process.env.REACT_APP_API_URL}/wp/v2/sf-coupons?_embed`,
  //     method: 'get',
  //     params: {
  //       per_page: 100,
  //       author: userInfo.id,
  //       status: ['publish', 'pending']
  //     }
  //   }).then((res) => {
  //     consoleLogger('FETCHING USER COUPONS ========================', userInfo.id, res.data)
  //     dispatch(userSlice.actions.setUserCoupons(res.data))
  //   }).catch(err => {
  //     consoleLogger(err)
  //   })
  //   dispatch(uiSlice.actions.updateUIComponentReady({component: 'user-coupons', isReady: true}))
  // }
  const [ salesSub, setSalesSub ] = React.useState('transactions')
  const handleSubToggle = (sub) => {
    setSalesSub(sub)
  }

  return (
    <Form className='formContainer'>
      <Grid divided>
        <Grid.Row>
          <Grid.Column mobile={16} computer={4}>
            <h3>Sales</h3>
            <AccountSalesOverview />
          </Grid.Column>
          <Grid.Column mobile={16} computer={12}>
            <h3>
              <span className={`subPageHeaderLink${salesSub==='transactions' ? ' active' : ''}`}
                onClick={() => handleSubToggle('transactions')}
              >
                Transactions
              </span>
              <span className={`subPageHeaderLink${salesSub==='payout' ? ' active' : ''}`}
                onClick={() => handleSubToggle('payout')}
              >
                Payout
              </span>
            </h3>
            {salesSub==='payout' ?
              <AccountPayoutTable /> :
              <AccountTransactionsTable />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

export default React.memo(AccountSales)