import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
// import NumberFormat from 'react-number-format'
import { consoleLogger, truncate, countryOptions } from "@Utils";

// FORMIK
import { Formik, Form } from "formik";
import * as Yup from "yup";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import uiSlice from "@Slices/uiSlice";

// COMPONENTS
import {
  Grid,
  Form as SUIForm,
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Modal,
  Dropdown,
  Label,
  Input,
  Header,
  Divider,
} from "semantic-ui-react";

// STYLES
import { StyledContainer } from "./SelectAddressStyles";
import { toast } from "react-toastify";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(4, "Must be at least 4 characters long")
    .max(125, "Must be shorter than 32 characters")
    .required("Must enter a address name"),
  street_address1: Yup.string()
    .min(5, "Must be at least 5 characters long")
    .max(125, "Must be shorter than 125 characters")
    .required("Must enter a street address"),
  street_address2: Yup.string()
    .min(5, "Must be at least 5 characters long")
    .max(125, "Must be shorter than 125 characters"),
  city: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(125, "Must be shorter than 125 characters")
    .required("Must enter a city"),
  state: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(125, "Must be shorter than 125 characters")
    .required("Must enter a state"),
  postalcode: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(12, "Must be shorter than 12 characters")
    .required("Must enter a postal code"),
  country: Yup.string().required("Must select a country"),
  country_code: Yup.string().required("Must select a country"),
});

const SelectAddress = (props) => {
  const { handleAddress, noCustom } = props;

  const dispatch = useDispatch();
  const { userInfo, userAddresses, userKey, printRequest } =
    useSelector(getUserState);
  const token = localStorage.Authorization;

  const [isReady, setIsReady] = React.useState(false);
  const [billingAddresses, setBillingAddresses] = React.useState();
  const [theBillingAddress, setTheBillingAddress] = React.useState(
    userInfo.acf.billing_address
  );
  const [useCustom, setUseCustom] = React.useState(false);
  const [customAddress, setCustomAddress] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [removeOpen, setRemoveOpen] = React.useState(false);

  React.useEffect(() => {
    setIsReady(true);
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (userAddresses && userAddresses.length > 0) {
      setBillingAddresses([
        ...userAddresses.map((item) => ({
          active:
            userInfo.acf.mailing_address &&
            userInfo.acf.mailing_address.id === item.id,
          key: item.slug,
          value: item.slug,
          text: item.title,
          content: (
            <div className="addressDropdown_wrap">
              <span className="addressDropdown_label">{item.title}</span>
              <span className="addressDropdown_extra">
                {item.street_address1}, {item.city} {item.state}, {item.country}
              </span>
              {userInfo.acf.mailing_address &&
              userInfo.acf.mailing_address.id === item.id ? (
                <span className="addressDropdown_default">DEFAULT</span>
              ) : (
                ""
              )}
            </div>
          ),
        })),
      ]);
      // setTheBillingAddress(userAddresses.filter(i => i.default).map(n => n)[0])
    }
  }, [userInfo, userAddresses]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("the billing address ===================", theBillingAddress);
  }, [theBillingAddress]);

  // const handleToggleRemoveModal = (bool) => {
  //   setRemoveOpen(bool)
  // }

  const handleBillingAddressChange = (value) => {
    consoleLogger("handleBillingAddressChange", value);
    setTheBillingAddress(
      userAddresses.filter((i) => i.slug === value).map((n) => n)[0]
    );
  };

  // const handleEditAddressToggle = (bool) => {
  //   setEditAddress(bool)
  //   setNewAddress(false)
  // }

  const handleCustomAddress = (bool) => {
    setUseCustom(bool);
  };

  // const handleSetSameBillingMailing = async (id) => {
  //   setProcessing(true)
  //   consoleLogger('handleSetSameBillingMailing --------------------', id)
  //   await axios({
  //       baseURL: process.env.REACT_APP_API_URL,
  //       url: `/stylefolio/v1/address/${id}`,
  //       method: 'POST',
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //       data: {
  //         author: userInfo.id,
  //         user_key: userKey,
  //         default: true,
  //         type: 'mailing',
  //         same_billing_mailing_address: true
  //       }
  //   }).then(res => {
  //     dispatch(userSlice.actions.setUserAddresses(res.data.addresses))
  //     toast.success(`You have set "${res.data.updated.title}" as your default mailing address.`, {
  //       containerId: 'mainToaster',
  //       progress: undefined,
  //     });
  //     // dispatch(uiSlice.actions.setUIToast({
  //     //   visible: true,
  //     //   type: 'positive',
  //     //   icon: 'info circle',
  //     //   title: `Default Billing Address`,
  //     //   message: `You have set "${res.data.updated.title}" as your default billing address.`
  //     // }))
  //     setTheBillingAddress({slug: "same_billing_mailing_address"})
  //     dispatch(userSlice.actions.setSameBillingMailingAddress())
  //   }).catch(err => {
  //     consoleLogger(err)
  //   })
  //   setProcessing(false)
  // }

  // const handleSetDefault = async (id) => {
  //   setProcessing(true)
  //   consoleLogger('handleSetDefault --------------------', id)
  //   await axios({
  //       baseURL: process.env.REACT_APP_API_URL,
  //       url: `/stylefolio/v1/address/${id}`,
  //       method: 'POST',
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //       data: {
  //         author: userInfo.id,
  //         user_key: userKey,
  //         default: true,
  //         type: 'mailing'
  //       }
  //   }).then(res => {
  //     dispatch(userSlice.actions.setUserAddresses(res.data.addresses))
  //     toast.success(`You have set "${res.data.updated.title}" as your default mailing address.`, {
  //       containerId: 'mainToaster',
  //       progress: undefined,
  //     });
  //     // dispatch(uiSlice.actions.setUIToast({
  //     //   visible: true,
  //     //   type: 'positive',
  //     //   icon: 'info circle',
  //     //   title: `Default Billing Address`,
  //     //   message: `You have set "${res.data.updated.title}" as your default billing address.`
  //     // }))
  //     setTheBillingAddress(res.data.updated)
  //     dispatch(userSlice.actions.setDefaultMailingAddress(res.data.updated))
  //   }).catch(err => {
  //     consoleLogger(err)
  //   })
  //   setProcessing(false)
  // }

  // const handleRemoveAddress = async (id) => {
  //   setProcessing(true)
  //   setRemoveOpen(false)
  //   consoleLogger('handleRemoveAddress --------------------', id)
  //   await axios({
  //       baseURL: process.env.REACT_APP_API_URL,
  //       url: `/stylefolio/v1/address/${id}`,
  //       method: 'DELETE',
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //   }).then(res => {
  //     dispatch(userSlice.actions.removeUserAddress(res.data))

  //     toast.warn(`You have removed "${res.data.title}" from your billing address records.`, {
  //       containerId: 'mainToaster',
  //       progress: undefined,
  //     });
  //     // dispatch(uiSlice.actions.setUIToast({
  //     //   visible: true,
  //     //   type: 'positive',
  //     //   icon: 'info circle',
  //     //   title: `Billing Address Removed`,
  //     //   message: `You have removed "${res.data.title}" from your billing address records.`
  //     // }))
  //   }).catch(err => {
  //     consoleLogger(err)
  //   })
  //   setTheBillingAddress(userAddresses.filter(i => i.default).map(n => n)[0])
  //   setProcessing(false)
  // }

  const handleListAddress = () => {
    handleAddress(theBillingAddress);
  };

  return (
    <StyledContainer>
      {isReady && (
        <>
          {userInfo.acf && (
            <>
              {!useCustom && (
                <Grid verticalAlign="middle" textAlign="center">
                  <Grid.Row>
                    <Grid.Column mobile={16} computer={7}>
                      <div
                        className={cn("billingDropdownCombo", {
                          new: !billingAddresses,
                        })}
                      >
                        {billingAddresses && (
                          <SUIForm.Select
                            name="selected_address"
                            fluid
                            placeholder="Select Address"
                            label="Select Saved Address"
                            className="billingDropdown"
                            onChange={(ev, { value }) => {
                              handleBillingAddressChange(value);
                              // setFieldValue('selected_address', value)
                            }}
                            defaultValue={theBillingAddress.slug}
                            options={billingAddresses}
                            disabled={!(!useCustom && !processing)}
                          />
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      computer={2}
                      className="selectAddressOR"
                    >
                      <Divider vertical className="hideOnMobile">
                        OR
                      </Divider>
                      <div className="showOnMobile">OR</div>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={7}>
                      {!noCustom ? (
                        <>
                          <Button
                            className="billingDropdownAdd"
                            color="blue"
                            icon="plus"
                            content={<span>Enter A Custom Address</span>}
                            disabled={!(!useCustom && !processing)}
                            onClick={() => handleCustomAddress(true)}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            as={Link}
                            to="/admin/account/information"
                            color="blue"
                            icon="plus"
                            content={<span>Add New Address</span>}
                            disabled={!(!useCustom && !processing)}
                          />
                        </>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={16}>
                    <>
                      <div className={cn("addressDisplayText")}>
                        {processing && (
                          <Dimmer inverted active>
                            <Loader />
                          </Dimmer>
                        )}
                        {useCustom ? (
                          <>
                            <Header as="h3">Custom Address</Header>
                            <Formik
                              initialValues={{
                                title:
                                  useCustom && printRequest.address.title
                                    ? printRequest.address.title
                                    : "",
                                street_address1:
                                  useCustom &&
                                  printRequest.address.street_address1
                                    ? printRequest.address.street_address1
                                    : "",
                                street_address2:
                                  useCustom &&
                                  printRequest.address.street_address2
                                    ? printRequest.address.street_address2
                                    : "",
                                city:
                                  useCustom && printRequest.address.city
                                    ? printRequest.address.city
                                    : "",
                                state:
                                  useCustom && printRequest.address.state
                                    ? printRequest.address.state
                                    : "",
                                postalcode:
                                  useCustom && printRequest.address.postalcode
                                    ? printRequest.address.postalcode
                                    : "",
                                country:
                                  useCustom && printRequest.address.country
                                    ? printRequest.address.country
                                    : "",
                                country_code:
                                  useCustom && printRequest.address.country_code
                                    ? printRequest.address.country_code
                                    : "",
                              }}
                              validateOnChange={false}
                              validateOnBlur={false}
                              enableReinitialize={true}
                              validationSchema={validationSchema}
                              onSubmit={(
                                data,
                                { setErrors, setSubmitting }
                              ) => {
                                (async () => {
                                  setSubmitting(true);
                                  consoleLogger(
                                    "SUBMITING FORM ===========================",
                                    data
                                  );
                                  // const submitData = {
                                  //   ...data,
                                  //   user_key: userKey,
                                  //   author: userInfo.id,
                                  // }
                                  // await axios({
                                  //   baseURL: process.env.REACT_APP_API_URL,
                                  //   url: `/stylefolio/v1/address${!newAddress ? `/${theBillingAddress.id}` : ''}`,
                                  //   method: newAddress ? 'PUT' : 'POST',
                                  //   headers: {
                                  //     Authorization: `${token}`,
                                  //   },
                                  //   data: submitData
                                  // }).then(res => {
                                  //   let addressTitle = ''
                                  //   if (newAddress) {
                                  //     addressTitle = res.data.new.title
                                  //     if (res.data.new.default) {
                                  //       setTheBillingAddress(res.data.new)
                                  //       dispatch(userSlice.actions.setDefaultBillingAddress(res.data.new))
                                  //     }
                                  //   } else {
                                  //     addressTitle = res.data.updated.title
                                  //     setTheBillingAddress(res.data.updated)
                                  //     if (res.data.updated.default) {
                                  //       dispatch(userSlice.actions.setDefaultBillingAddress(res.data.updated))
                                  //     }
                                  //   }
                                  //   dispatch(userSlice.actions.setUserAddresses(res.data.addresses))

                                  //   toast.info(`You have ${newAddress ? 'added' : 'updated'} "${addressTitle}" address.`, {
                                  //     containerId: 'mainToaster',
                                  //     progress: undefined,
                                  //   });
                                  // }).catch(err => {
                                  //   // const errorData = err
                                  //   consoleLogger("ERROR: ", err)
                                  //   // setErrors(errorData)
                                  // })
                                  handleAddress({
                                    title: data.title,
                                    country: data.country,
                                    country_code: data.country_code,
                                    street_address1: data.street_address1,
                                    street_address2: data.street_address2,
                                    city: data.city,
                                    state: data.state,
                                    postalcode: data.postalcode,
                                  });
                                  setSubmitting(false);
                                })();
                              }}
                            >
                              {({
                                isSubmitting,
                                handleBlur,
                                handleChange,
                                handleReset,
                                handleSubmit,
                                values,
                                setFieldValue,
                                errors,
                                isValid,
                                dirty,
                              }) => (
                                <Form className="ui form formContainer">
                                  <div className="addressForm">
                                    <Grid>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input
                                            name="title"
                                            fluid
                                            placeholder="Address Name"
                                            type="text"
                                            label="Address Name *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.title}
                                            disabled={isSubmitting}
                                            defaultValue={
                                              useCustom &&
                                              printRequest.address.title
                                                ? printRequest.address.title
                                                : ""
                                            }
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Field
                                            error={errors.country_code}
                                          >
                                            <label>Country *</label>
                                            <Dropdown
                                              search
                                              selection
                                              name="country_code"
                                              fluid
                                              placeholder="Country"
                                              label="Country *"
                                              autoComplete="off"
                                              onChange={(ev, { value }) => {
                                                const cntry =
                                                  countryOptions.filter(
                                                    (n) => n.value === value
                                                  )[0];
                                                consoleLogger(
                                                  value,
                                                  cntry.text
                                                );
                                                setFieldValue(
                                                  "country_code",
                                                  value
                                                );
                                                setFieldValue(
                                                  "country",
                                                  cntry.text
                                                );
                                              }}
                                              options={countryOptions}
                                              disabled={isSubmitting}
                                              onFocus={(e) => {
                                                e.target.setAttribute(
                                                  "autocomplete",
                                                  "nope"
                                                );
                                              }}
                                              defaultValue={
                                                useCustom &&
                                                printRequest.address
                                                  .country_code
                                                  ? printRequest.address
                                                      .country_code
                                                  : ""
                                              }
                                            />
                                            {errors.country_code && (
                                              <Label pointing="above" prompt>
                                                {errors.country_code}
                                              </Label>
                                            )}
                                          </SUIForm.Field>
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input
                                            name="street_address1"
                                            fluid
                                            placeholder="Street Address"
                                            type="text"
                                            label="Street Address *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.street_address1}
                                            disabled={isSubmitting}
                                            defaultValue={
                                              useCustom &&
                                              printRequest.address
                                                .street_address1
                                                ? printRequest.address
                                                    .street_address1
                                                : ""
                                            }
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input
                                            name="street_address2"
                                            fluid
                                            placeholder="Apartment, suite, building name, etc..."
                                            type="text"
                                            label="Street Address Line 2 (optional)"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.street_address2}
                                            disabled={isSubmitting}
                                            defaultValue={
                                              useCustom &&
                                              printRequest.address
                                                .street_address2
                                                ? printRequest.address
                                                    .street_address2
                                                : ""
                                            }
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input
                                            name="city"
                                            fluid
                                            placeholder="City"
                                            type="text"
                                            label="City *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.city}
                                            disabled={isSubmitting}
                                            defaultValue={
                                              useCustom &&
                                              printRequest.address.city
                                                ? printRequest.address.city
                                                : ""
                                            }
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={8}>
                                          <SUIForm.Input
                                            name="state"
                                            fluid
                                            placeholder="State"
                                            type="text"
                                            label="State / Province *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.state}
                                            disabled={isSubmitting}
                                            defaultValue={
                                              useCustom &&
                                              printRequest.address.state
                                                ? printRequest.address.state
                                                : ""
                                            }
                                          />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} computer={8}>
                                          <SUIForm.Input
                                            name="postalcode"
                                            fluid
                                            placeholder="Postal Code"
                                            type="text"
                                            label="Postal / Zip Code *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.postalcode}
                                            disabled={isSubmitting}
                                            defaultValue={
                                              useCustom &&
                                              printRequest.address.postalcode
                                                ? printRequest.address
                                                    .postalcode
                                                : ""
                                            }
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  </div>
                                  <div className="addressDisplayText_buttons">
                                    <Button
                                      type="button"
                                      icon="arrow left"
                                      content={<span>Back</span>}
                                      compact
                                      size="large"
                                      basic
                                      onClick={() => handleCustomAddress(false)}
                                    />
                                    <Button
                                      type="submit"
                                      primary
                                      icon="check"
                                      content={<span>Use This Address</span>}
                                      compact
                                      size="large"
                                      onClick={handleSubmit}
                                      disabled={!(dirty && !isSubmitting)}
                                    />
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </>
                        ) : (
                          <>
                            <div className="addressDisplayText_name">
                              {theBillingAddress.title}
                            </div>
                            <div className="addressDisplayText_street1">
                              {theBillingAddress.street_address1}
                            </div>
                            <div className="addressDisplayText_street2">
                              {theBillingAddress.street_address2}
                            </div>
                            <div className="addressDisplayText_citystate">
                              {theBillingAddress.city},{" "}
                              {theBillingAddress.state}{" "}
                              {theBillingAddress.postalcode}
                            </div>
                            <div className="addressDisplayText_country">
                              {theBillingAddress.country}
                            </div>
                            <div className="addressDisplayText_buttons">
                              <Button
                                type="button"
                                primary
                                icon="check"
                                content={<span>Select This Address</span>}
                                compact
                                size="large"
                                onClick={handleListAddress}
                                // onClick={() => handleSetDefault(theBillingAddress.id)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}
        </>
      )}
    </StyledContainer>
  );
};

export default React.memo(SelectAddress);
