import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Card } from 'semantic-ui-react'

export const Container = styled.div`
  padding: 0rem;

  .section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .section_wrapper {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    &.fluid {
      .section_wrapper {
        max-width: 100%;
      }
    }

    &.padded {
      padding: 2rem 0;
      .section_wrapper {
        padding: 2rem;
      }
    }
    
    &.hero {
      padding: 0;
      min-height: calc(calc(var(--vh, 1vh) * 100));
      background-color: #ffffff;
      align-items: stretch;

      @media ${stylevars.breakpoints.phone} {
        min-height: auto;
      }

      .section_wrapper {
        align-items: center;
      }

      .heroContent {
        position: relative;
        width: 100%;
        height: calc(calc(var(--vh, 1vh) * 100));
        @media ${stylevars.breakpoints.phone} {
          height: auto;
        }
      }
      .heroParallax {
        position: relative;
        width: 100%;
        height: calc(calc(var(--vh, 1vh) * 100));

        @media ${stylevars.breakpoints.phone} {
          height: auto;
          margin-bottom: 4rem;
        }
        
        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex !important;
          justify-content: center;
          align-items: center;
          &.image1 {
            z-index: 1;
          }
          &.image2 {
            z-index: 2;
          }
          &.image3 {
            z-index: 3;
          }
          img {
            position: relative;
            object-fit: contain;
            width: 100%;
            max-width: 1680px;
            height: auto;

            @media ${stylevars.breakpoints.phone} {
              height: auto;
            }
          }
        }
      }
      .heroTexts {
        position: absolute;
        bottom: 4rem;
        right: 4rem;

        @media ${stylevars.breakpoints.phone} {
          position: relative;
          bottom: 1rem;
          right: 1rem;
        }
      }
      .heroText {
        text-align: right;

        .text {
          text-align: right;
          display: inline-block;
          font-size: calc(min(calc(calc(var(--vw, 1vw) * 10)), 5rem));
          line-height: 1;
          font-weight: bold;
          margin-right: 0.5rem;
        }
        .dot {
          display: inline-block;
          width: calc(min(calc(calc(var(--vw, 1vw) * 2)), 0.75rem));
          height: calc(min(calc(calc(var(--vw, 1vw) * 2)), 0.75rem));
          line-height: 5rem;
          &.red {
            background-color: #ff000f;
          }
          &.green {
            background-color: #00B300;
          }
          &.blue {
            background-color: #0040FF;
          }
          &.yellow {
            background-color: #FFFC00;
          }
        }
      }
    }

    &.simple-process {
      width: 100%;
      padding: 4rem 4rem;
      background-color: #F3F1E5;
      color: ${stylevars.typography.title.color};
      display: flex;
      justify-content: center;
      align-items: center;

      @media ${stylevars.breakpoints.phone} {
        display: block;
      }

      .logo {
        width: auto;
        height: 6rem;
        @media ${stylevars.breakpoints.phone} {
          width: 100%;
          max-width: 220px;
          height: auto;
          margin-bottom: 1rem;
        }
      }

      h1 {
        font-size: 2rem;
        line-height: 1.6;
        margin: 0;
        padding: 0;
        margin-left: 1rem;
        font-weight: 200;
      }
    }

    &.process {
      /* min-height: calc(var(--vh, 1vh) * 100); */
      padding: 8rem;
      background-color: #ffffff;
      /* min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height}); */
      height: auto;

      @media ${stylevars.breakpoints.phone} {
        padding: 2rem;
      }

      .section_wrapper {
        padding: 0;
      }
      .section_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
      }
      h1 {
        width: 100%;
        text-align: left;
      }

      .content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        @media ${stylevars.breakpoints.phone} {
          display: block;
        }

        .image {
          width: 40%;
          @media ${stylevars.breakpoints.phone} {
            width: 100%;
            margin-bottom: 2rem;
          }
        }
        .steps {
          display: inline-block;
          width: auto;
          padding-left: 4rem;
          @media ${stylevars.breakpoints.phone} {
            padding-left: 0;
          }
          li {
            display: block;
            width: auto;
            padding: 0.5rem 0.5rem 0.5rem 2rem;
            margin: 0;
            &:not(:last-of-type) {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    &.pricing {
      background-image: linear-gradient(to bottom, rgba(${stylevars.palette.primary.rgb}, 1), rgba(${stylevars.palette.secondary.rgb}, 1));
      padding: 8rem;

      color: #ffffff;

      .section_wrapper {
        @media ${stylevars.breakpoints.phone} {
          padding: 0;
        }
      }

      @media ${stylevars.breakpoints.phone} {
        padding: 2rem;
      }

      h1, h2 {
        width: 100%;
        text-align: center;
      }
      h2 {
        font-size: 2rem;
        font-weight: lighter;
      }

      .boxes {
        .box {
          text-align: center;
          box-shadow: inset 0 0 10rem 0.5rem rgba(0, 0, 0, 0);
          transition: background-color 0.15s ease-out, box-shadow 0.15s ease-out;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            box-shadow: inset 0 0 10rem 0.5rem rgba(0, 0, 0, 0.15);
          }
        }

        .price {
          line-height: 1.6;
          .amount {
            font-size: 3rem;
            font-weight: bold;
          }
          .extra {
            font-size: 1.5rem;
            font-weight: lighter;
            color: rgba(255, 255, 255, 0.5);
          }
        }
        .features {
          margin: 0;
          padding: 0;
          li {
            text-align: left;
            padding: 0.5rem 0;
            padding-left: 2rem;
            margin: 0;
            font-size: 1rem;
            &:not(:last-of-type) {
              border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }
            &:before {
              margin-top: -0.365rem;
              height: 0.75rem;
              width: 0.75rem;
              border: 1px solid ${stylevars.palette.primary.main};
              border-width: 0.25rem 0.25rem 0 0;
            }
            &:hover {
              &:before {
                left: 0rem;
                border-width: 0rem 0rem 0 0;
                border-color: #ffffff;
                background-color: #ffffff;
                border-radius: 50%;
                height: 1rem;
                width: 1rem;
              }
            }
          }
        }
      }
    }
  }

  @keyframes mobileDeviceHover {
    0% {
      transform: translateY(-5%);
    }
    50% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-5%);
    }
  }
  @keyframes mobileDeviceShadow {
    0% {
      transform: translate(-45%, -53%);
      opacity: 0.25;
    }
    50% {
      transform: translate(-50%, -50%);
      opacity: 0.75;
    }
    100% {
      transform: translate(-45%, -53%);
      opacity: 0.25;
    }
  }
  
  .boxes {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media ${stylevars.breakpoints.phone} {
      display: block;
    }

    .action {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 4rem;
      .ui.button {
        border-radius: 10rem;
      }
    }
    .box {
      width: 50%;
      /* min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height}); */
      padding: 4rem;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.25s ease-out;
      cursor: default;
      position: relative;
      background-color: rgba(0, 0, 0, 0);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      @media ${stylevars.breakpoints.phone} {
        display: block;
        width: 100%;
        padding: 2rem;
      }
      .lottiebox {
        width: 50%;
        height: 220px;
        @media ${stylevars.breakpoints.phone} {
          margin: 0 auto;
          width: calc(min(80%, 220px));
        }
      }
      &.divided {
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        @media ${stylevars.breakpoints.phone} {
          border-right: none;
          padding-bottom: 2rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        }
      }

      .signupButton {
        margin-top: 4rem;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }
  .customList {
    display: block;
    margin: 0;
    padding: 0;
    min-height: 160px;
    @media ${stylevars.breakpoints.phone} {
      min-height: auto;
    }

    li {
      display: block;
      margin: 1rem 0;
      font-size: 1.4rem;
      line-height: 1.6;
      list-style: none;
      position: relative;
      padding-left: 2rem;
      cursor: default;
      transition: all 0.15s ease-out;
      > span {
        display: block;
        transition: all 0.15s ease-out;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.5rem;
        height: 1rem;
        width: 1rem;
        border: 1px solid ${stylevars.palette.primary.main};
        border-width: 0.35rem 0.35rem 0 0;
        transform: rotate(45deg);
        transition: all 0.15s ease-out;
      }
      &:hover {
        > span {
          transform: translateX(-0.5rem);
        }
        &:before {
          left: 0.5rem;
          border-width: 0rem;
          background-color: ${stylevars.palette.primary.main};
          transform: rotate(180deg) scale(0.5);
        }
      }
    }
  }
`

export const StyledCard = styled(Card)`
  width: 100% !important;
  margin: 0 !important;
  background-color: rgba(255, 255, 255, 0.85) !important;

  .card_wrapper {
    padding: 2rem;
  }
`