import React from 'react'
import axios from 'axios'
import {
  consoleLogger,
} from '@Utils'

// COMPONENTS
import { Dimmer, Loader } from 'semantic-ui-react'

// STYLES
import { Container } from './RecentTermsStyles'

const RecentTerms = (props) => {

  const [ ready, setReady ] = React.useState(false)

  const {
    terms
  } = props

  const [ data, setData ] = React.useState()

  React.useEffect(() => {
    (async() => {
      if (terms) {
        setData(terms)
      } else {
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/meta`,
          method: 'GET',
          params: {
            type: 'search',
            limit: 3
          }
        }).then(res => {
          consoleLogger('SEARCH TERMS RESPONS ============', res.data)
          setData(res.data)
        }).catch(err => {
          consoleLogger(err)
        })
      }
    })()
  }, [terms]) // eslint-disable-line

  React.useEffect(() => {
    if (data) {
      setReady(true)
    }
  }, [data]) // eslint-disable-line

  return (
    <Container className='recentSearch'>
      <div className='wrapper'>
        {ready ?
          <>
            <span className='term-prefix'>Recent search terms: </span>
            {data && data.map(term => (
              <span className='term' key={`${term.key}-${term.ID}`}>{term.meta.value}</span>
            ))}
          </> :
          <div className='termsLoader'>
            <Loader />
            <span>Loading recent search terms...</span>
          </div>
        }
      </div>
    </Container>
  )
}

export default RecentTerms
