import React, { useCallback } from "react";
import cn from "classnames";
import { debounce } from "lodash";
import badwords from "badwords-list";
import axios from "axios";
import { consoleLogger } from "@Utils";

// COMPONENTS
import { Button, Dropdown, Input, Modal } from "semantic-ui-react";
import { SearchResults } from "@Components/Search";
import { RecentTerms } from "@Components/Search/Components";

// STYLES
import { Container, ModalContentContainer } from "./GlobalSearchStyles";

const GlobalSearch = () => {
  const inputRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [results, setResults] = React.useState();
  const [showResults, setShowResults] = React.useState(false);
  const [count, setCount] = React.useState();
  const [focus, setFocus] = React.useState(false);
  const [terms, setTerms] = React.useState();

  const handleModalOpen = (bool) => {
    setOpen(bool);
  };

  const handleShowSearch = () => {
    handleModalOpen(true);
  };

  const handleResults = (data) => {
    setResults(data);
    const count = data.map((i) => i.results.length).reduce((p, c) => p + c, 0);
    setCount(count);
    consoleLogger("handleResults ========================", count);
    setShowResults(true);
  };

  const handleTerms = (data) => {
    setTerms(data.slice(0, 3));
  };

  const handleChange = (ev, { value }) => {
    consoleLogger("handleChange ======================", value);
    const match = value.match(badwords.regex);
    console.log("bad word? ", !!match);
    if (!match) {
      setQuery(value);
    }
  };

  const handleSearchSubmit = async () => {
    if (query) {
      if (query.length > 3) {
        setProcessing(true);
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/search`,
          method: "POST",
          data: {
            q: query,
            type: selectedSearchType,
          },
        })
          .then((res) => {
            consoleLogger("SEARCH RESPONSE =======================", res.data);
            handleResults(res.data.results);
            handleTerms(res.data.meta);
          })
          .catch((err) => {
            consoleLogger(err);
          });
      } else {
        setShowResults(false);
      }
      setProcessing(false);
    }
  };

  const handleKeyUp = (ev) => {
    consoleLogger("handleKeyUp ===================", ev.key);
    if (ev.key === "Enter") {
      handleSearchSubmit();
    }
  };

  // const handleClearSearch = () => {
  //   setQuery('')
  //   setCount()
  //   setProcessing(false)
  //   setShowResults(false)
  //   inputRef.current.value = ''
  //   inputRef.current.focus()
  // }

  // React.useEffect(() => {
  //   (async() => {
  //     if (query) {
  //       if (query.length > 3) {
  //         setProcessing(true)
  //         await axios({
  //           baseURL: `${process.env.REACT_APP_API_URL}`,
  //           url: `/stylefolio/v1/search`,
  //           method: 'POST',
  //           data: {
  //             q: query,
  //             type: selectedSearchType
  //           },
  //         }).then(res => {
  //           consoleLogger("SEARCH RESPONSE =======================", res.data)
  //           handleResults(res.data.results)
  //           handleTerms(res.data.meta)
  //         }).catch(err => {
  //           consoleLogger(err)
  //         })
  //       } else {
  //         setShowResults(false)
  //       }
  //       setProcessing(false)
  //     }
  //   })()
  // }, [query]) // eslint-disable-line

  React.useEffect(() => {
    if (inputRef && inputRef.current) {
      if (open) {
        inputRef.current.focus();
      }
    }
  }, [open]); // eslint-disable-line

  const searchTypeOptions = [
    { text: "Creator", value: "creator" },
    { text: "Gallery", value: "gallery" },
    { text: "Collection", value: "collection" },
  ];

  const [selectedSearchType, setSelectedSearchType] = React.useState("creator");
  const handleSearchType = (e, { value }) => {
    setSelectedSearchType(value);
  };

  return (
    <Container className="menuItemUser storeButtonsGroup divided">
      <Button
        circular
        basic
        icon="search"
        className="storeButtons"
        onClick={handleShowSearch}
      />
      {open && (
        <Modal
          size="small"
          open={open}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => handleModalOpen(true)}
          onClose={() => handleModalOpen(false)}
          className="searchModal"
        >
          <Modal.Content>
            <ModalContentContainer>
              <Input
                action={
                  <Button.Group
                    primary
                    size="massive"
                    className="searchTypeGroup"
                  >
                    <Button
                      loading={processing}
                      disabled={processing}
                      onClick={handleSearchSubmit}
                      className="searchTypeButtonMain"
                    >
                      Search{" "}
                      {searchTypeOptions
                        .filter((i) => i.value === selectedSearchType)
                        .map((n) => n.text)}
                    </Button>
                    <Dropdown
                      className="button icon"
                      floating
                      options={searchTypeOptions}
                      defaultValue={selectedSearchType}
                      onChange={handleSearchType}
                      trigger={<></>}
                      disabled={processing}
                      onKeyUp={handleKeyUp}
                    />
                  </Button.Group>
                }
                icon="search"
                iconPosition="left"
                placeholder={`Search ${
                  selectedSearchType === "creator"
                    ? "name of creator"
                    : selectedSearchType === "gallery"
                    ? "title or description of the artwork"
                    : "title or description of the collection"
                }`}
                size="massive"
                fluid
                defaultValue={query}
                className="searchComboField"
                onChange={handleChange}
                disabled={processing}
                onKeyUp={handleKeyUp}
              />
              <RecentTerms terms={terms} />
              {showResults && (
                <>
                  {count > 0 ? (
                    <SearchResults
                      results={results}
                      handleSearchModal={handleModalOpen}
                    />
                  ) : (
                    <div className="nothing">Found nothing...</div>
                  )}
                </>
              )}
            </ModalContentContainer>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => handleModalOpen(false)}
              negative
              basic
              icon="close"
              labelPosition="right"
              content={`Close`}
            />
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
};

export default React.memo(GlobalSearch);
