import React from 'react'
import axios from 'axios'
import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'
// import { format, isFuture, parse, isDate } from 'date-fns'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  // Icon,
  // Table,
  Button,
  Checkbox,
  Modal,
  Grid,
  Label,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  // Dropdown,
} from 'semantic-ui-react'

import AccountTransactionsView from './AccountTransactionsView'

const AccountTransactionsModal = (props) => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(getUserState)
  const {
    info,
    handleOpen,
    handleClose,
    isEdit,
    data,
    open,
    handleFetch,
  } = props

  // consoleLogger(data)

  return (
          <Modal
            onClose={() => handleClose}
            onOpen={() => handleOpen}
            open={open}
            dimmer={{
                blurring: true
            }}
            size='large'
          >
            <>
              <Modal.Header>{`${data.acf.sales_id}`}</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description>
                  <AccountTransactionsView itemId={data.acf.sku.split('-')[1]} salesId={data.acf.sales_id} />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type='submit'
                  content="Okay"
                  labelPosition='right'
                  icon='check'
                  onClick={() => handleClose()}
                  primary
                  // loading={isSubmitting}
                  // disabled={!(dirty && isValid && !isSubmitting)}
                />
              </Modal.Actions>
            </>
          </Modal>
  )
}

export default React.memo(AccountTransactionsModal)