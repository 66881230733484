import React from 'react'
import {
  consoleLogger,
} from '@Utils'

import { UserCard, CollectionCard, GalleryCard } from '@StorePages/Discover/Components'

const ResultItem = (props) => {
  const {
    type,
    item,
    handleSearchModal
  } = props

  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    if (item) {
      consoleLogger('====================== item', item)
      setReady(true)
    }
  }, [item]) // eslint-disable-line

  switch (type) {
    case 'galleries':
      return (
        <div>
          <GalleryCard itemData={item} onClick={handleSearchModal} />
        </div>
      )
    case 'collections':
      return (
        <CollectionCard itemData={item} onClick={handleSearchModal} />
      )
    
    default:
      return (
        <UserCard user={item} onClick={handleSearchModal} />
      )
  }
}

export default React.memo(ResultItem)
