import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const ItemContainer = styled('div')`
  /* width: calc(20% - 2.5px); */
  /* width: calc(20%); */
  min-width: 120px;
  min-height: 120px;
  height: auto;
  /* margin: 0.25rem; */
  margin: ${stylevars.gallery.gap};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: cente;
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0);
  transition: border 0.15s ease-out, box-shadow 0.15s ease-out;
  touch-action: auto !important;
  width: 100%;
  max-width: calc(calc(calc(calc(var(--vw, 1vw) * 100) - ${stylevars.sideBar.width} - 4rem) / 5) - calc(calc(2px + ${stylevars.gallery.gap}) * 2));

  @media ${stylevars.breakpoints.laptop} {
    max-width: calc(calc(calc(calc(var(--vw, 1vw) * 100) - ${stylevars.sideBar.width} - 4rem) / 4) - calc(calc(2px + ${stylevars.gallery.gap}) * 2));
  }

  @media ${stylevars.breakpoints.tablet} {
    max-width: calc(calc(calc(calc(var(--vw, 1vw) * 100) - 4rem) / 3) - calc(calc(2px + ${stylevars.gallery.gap}) * 2));
  }

  @media ${stylevars.breakpoints.phone} {
    max-width: calc(calc(calc(var(--vw, 1vw) * 100) - 2rem) - calc(calc(5px + ${stylevars.gallery.gap}) * 2));
  }

  /* @media ${stylevars.breakpoints.desktop} {
    width: calc(20% - 2.5px);
  }
  @media ${stylevars.breakpoints.laptop} {
    width: calc(25% - 2.5px);
  }
  @media ${stylevars.breakpoints.tablet} {
    width: calc(33.33% - 2.5px);
  }
  @media ${stylevars.breakpoints.phone} {
    width: calc(100% - 2.5px);
  } */

  .item-wrapper {
    padding: 0px;
    position: relative;
  }

  .handle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    background-color: rgba(255,255,255,1);
    color: ${stylevars.typography.title.color};
    line-height: 1;
    z-index: 2;
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    padding: 0;
    opacity: 0;
    transform: translateY(2rem);
    transition: opacity 0.15s ease-out, transform 0.15s ease-out;

    i {
      margin: 0;
    }
  }

  .imageWrapper {
    display: block !important;
    margin: 0;
    padding: 0;
    opacity: 0.35;
    transition: opacity 0.15s ease-out;

    img {
      min-width: 120px;
      width: 100%;
      /* width: calc(calc(calc(calc(var(--vw, 1vw) * 100) - ${stylevars.sideBar.width} - 4rem) / 5) - calc(calc(2px + ${stylevars.gallery.gap}) * 2)); */
      min-height: 120px;
      height: auto;
      object-fit: cover;
      display: block;
      margin: 0;
      padding: 0;
      line-height: 0;
    }
  }

  &.muuri-item-dragging {
    z-index: 3;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  }
  &.muuri-item-releasing {
    z-index: 2;
  }
  &.muuri-item-hidden {
    z-index: 0;
  }
  &.muuri-item-placeholder {
    opacity: 0.25;
    margin: 0;
  }

  &.arrange {
    touch-action: none !important;
    .handle {
      opacity: 1;
      transform: translateY(0rem);
    }
    .item-details {
      opacity: 0;
      transform: translateY(-2rem);
      pointer-events: none;
    }
  }

  &.publish {
    .imageWrapper {
      opacity: 1;
    }
  }

  &:hover {
    .imageWrapper {
      opacity: 1;
    }
  }

  .item-content {
    position: relative;
  }

  .item-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
    transform: translateY(0);
    transition: transform 0.15s ease-out, opacity 0.15s ease-out, background-color 0.15s ease-out;
    pointer-events: all;

    .preloader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%, -50%);
      z-index: 2;
    }

    .showInGallery {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .ui.button {
        opacity: 0.5;
        transition: opacity 0.15s ease-out;
        width: 3rem;
        height: 3rem;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          margin: 0;
          color: white;
          font-size: 1rem;
        }

        &:hover {
          opacity: 1;
        }
      }

      &.show {
        background-color: ${stylevars.palette.danger.main};
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
      .itemInfo {
        opacity: 1;
      }
    }

    .itemInfo {
      opacity: 0;
      transition: opacity 0.15s ease-out;
    }

    .itemActions {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      pointer-events: none;

      .ui.button {
        background-color: transparent;
        padding-right: 3rem !important;
        margin: 0;
        pointer-events: none;
        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }
        i.icon {
          border-radius: 2.5rem;
          width: 2.25rem;
          height: 2.25rem;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: all;
        }
        > span {
          transition: opacity 0.15s ease-out;
          opacity: 0;
          pointer-events: none;
        }
        &:hover {
          > span {
            opacity: 1;
          }
        }

        &.red {
          i.icon {
            background-color: ${stylevars.palette.danger.main};
          }
        }

        &.green {
          i.icon {
            background-color: ${stylevars.palette.success.main};
          }
        }

        &.blue {
          i.icon {
            background-color: ${stylevars.palette.info.main};
          }
        }

        &.request {
          color: white;
          i.icon {
            background-color: transparent;
            color: white;
          }
          &:hover {
            i.icon {
              background-color: white;
              color: #333;
            }
          }
        }
      }
    }
    .topRight {
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: white;
      font-size: 0.8rem;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
    .limitedEdition {
      color: ${stylevars.palette.primary.main};
      margin-right: 0.5rem;
      .icon {
        margin-right: 0.5rem;
      }
    }
    .itemViews {
      .icon {
        margin-right: 0.5rem;
      }
    }
    .itemFooter {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      right: 1rem;
      color: white;
      font-size: 0.8rem;
      font-weight: normal;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .name {
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 1rem;
      }
    }
  }
`