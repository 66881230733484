import React from 'react'
import Helmet from 'react-helmet'

// REDUX
import { useDispatch } from 'react-redux'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import { TermsContent } from '@Common/Documents'

// STYLES
import { StyledContainer } from './Styles'

const TermsPage = () => {
  const dispatch = useDispatch()
  const [ ready, setReady ] = React.useState(false)
  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('terms of service'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
    // const token = localStorage.Authorization
    setReady(true)
  }, []) // eslint-disable-line

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Terms of Service</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/terms`} />
      </Helmet>
      <div className='container'>
        { ready ?
          <>
            <TermsContent />
          </> : 
          <>Loading...</>
        }
      </div>
    </StyledContainer>
  )
}

export default React.memo(TermsPage)
