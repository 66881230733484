import React from 'react'
import cn from 'classnames'
import axios from 'axios'
import { consoleLogger } from '@Utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
// import { getUIState } from '@Slices/uiSlice'

// STYLES
import { MediaLibraryContainer } from './MediaLibraryStyles'
import { Button, Dimmer, Loader } from 'semantic-ui-react'

const MediaLibrary = (props) => {
  const { collectionData, handleChange } = props
  const token = localStorage.Authorization
  const { userInfo, userKey } = useSelector(getUserState)
  const [ library, setLibrary ] = React.useState()
  const [ ready, setReady ] = React.useState(false)
  const [ selected, setSelected ] = React.useState()
  const [ coverImage, setCoverImage ] = React.useState()

  const handleClick = (data) => {
    if (coverImage.id) {
      if (data.id.toString() !== coverImage.id.toString()) {
        setSelected(data)
        handleChange(data)
      }
    } else {
      setSelected(data)
      handleChange(data)
    }
  }

  React.useEffect(() => {
    (async()=>{
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/media`,
        headers: {
          Authorization:`${token}`,
        },
        method: 'GET',
        data: {
          author: userInfo.id,
          user_key: userKey,
        },
      }).then(res => {
        setLibrary(res.data)
      }).catch(err => {
        consoleLogger(err)
      })
      setCoverImage(collectionData && collectionData.cover_image && collectionData.cover_image.id ? collectionData.cover_image : '')
    })()
  }, [collectionData]) // eslint-disable-line

  React.useEffect(() => {
    if (library) {
      setReady(true)
    }
  }, [library])

  return (
    <MediaLibraryContainer>
      {!ready ?
        <Dimmer inverted active>
          <Loader>Retrieving all your uploaded images...</Loader>
        </Dimmer> : 
        <div className='image-grid'>
          {library.filter(n => !n.medium[4]).map(media => (
            <div
              key={media.id}
              className={cn('item', {
                'selected' : selected && selected.id.toString() === media.id.toString(),
                'current' : coverImage && coverImage.id.toString() === media.id.toString()
              })}
              onClick={() => handleClick(media)}
            >
              <div className={cn('overlay', {
                'selected' : selected && selected.id.toString() === media.id.toString(),
                'current' : coverImage && coverImage.id.toString() === media.id.toString()
              })} />
              <LazyLoadImage
                wrapperClassName='imageWrapper'
                effect="blur"
                src={media.medium[0]}
              />
            </div>
          ))}
        </div>
      }
    </MediaLibraryContainer>
  )
}

export default React.memo(MediaLibrary)
