import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  .item {
    padding: 0 !important;
    margin: 0 !important;

    .header {
      color: ${stylevars.typography.title.color};
    }
  }
  .cartMenu {
    padding: 1rem;
    > .menu {
      margin-top: 0rem !important;
    }
    > .trigger {
      color: ${stylevars.typography.subtitle.color};
      display: flex;
      align-items: center;
      &:hover {
        color: ${stylevars.typography.title.color};
      }
      &.active {
        color: ${stylevars.typography.title.color};
      }
      .triggerName {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 2rem;
        margin-right: 0.5rem;
      }
      .icon {
        font-size: 1.5rem;
      }
      .floating.label {
        font-size: 0.75rem;
        top: 0.25rem;
        left: calc(100% - 0.75rem);
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.expand {
      > .menu {
        /* width: 320px; */
        min-width: 320px;

        
        @media ${stylevars.breakpoints.phone} {
          position: fixed !important;
          top: ${stylevars.navBar.height} !important;
          right: auto !important;
          left: 50% !important;
          transform: translateX(calc(-50%)) !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
          /* width: calc(calc(var(--vw, 1vw) * 100) - 4rem);
          max-width: calc(calc(var(--vw, 1vw) * 100) - 4rem);
          min-width: calc(calc(var(--vw, 1vw) * 100) - 4rem) !important; */
          width: calc(calc(var(--vw, 1vw) * 100) - 0rem);
          max-width: calc(calc(var(--vw, 1vw) * 100) - 0rem);
          min-width: calc(calc(var(--vw, 1vw) * 100) - 0rem) !important;

          &:after {
            display: none;
          }
        }
      }
    }
    /* > .trigger {
      display: flex;
      align-items: center;
      > .triggerName {
        line-height: 2rem;
        margin-right: 0.5rem;
      }
      > .icon {
        font-size: 1.5rem;
      }
    } */
    &Item {
      display: flex;
      align-items: center;
      padding: 0;
      width: 100%;
      overflow: hidden;
      .image {
        width: 100%;
        max-width: 3rem;
        margin-right: 0.5rem;
      }
      .text {
        width: calc(100% - 14.5rem);
        flex-grow: 1;
        margin-right: 0.5rem;
        h4 {
          width: 100%;
          /* max-width: 140px; */
          font-size: 1rem;
          font-weight: bold;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        p {
          font-size: 1rem;
          font-weight: normal;
          margin: 0;
          word-break: break-all;
          white-space: normal;
        }
        small {
          font-size: 0.8rem;
          margin: 0;
        }
      }
      .price {
        min-width: 6rem;
        text-align: center;
      }
      .label {
      }
      .description {
        text-align: right;
        margin-left: 0.5rem;
      }
    }
    &Actions {
      display: flex;
      > .button {
        flex-grow: 1;
        margin: 0 !important;
      }
    }

    .moreItems {
      border-top: none !important;
      border-bottom: none !important;
    }
  }
  .cartMenuActions {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    .dropdown {
      > .menu {
        left: 0;
        right: auto;
        top: calc(100% + 1px) !important;
        margin: 0 !important;
      }
    }
  }
  .emptyCart {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: 1rem;
    }
  }
`