import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const GalleryDNDContainer = styled('div')`
  position: relative;
  .galleryDNDWrapper {
    padding: 0rem;
    width: 100%;
    position: relative;
    display: flex;

    .dimmer {
      z-index: 4 !important;
    }
  }
  .galleryTarget {
    position: relative;
    z-index: 2;
    /* position: absolute;
    top: 0;
    left: calc(220px + 1rem);
    right: 0; */
    width: calc(100%);
    /* min-height: calc(calc(var(--vh, 1vh) * 100) - calc(${stylevars.navBar.height} * 2) - 20rem); */

    .galleryGrid {
      width: 100%;
      min-height: calc(calc(var(--vh, 1vh) * 100) - calc(${stylevars.navBar.height} * 2) - 20rem);
    }
  }
`