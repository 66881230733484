import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const MediaLibraryContainer = styled('div')`

  .image-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0.5rem;

    > .item {
      position: relative;
      cursor: pointer;
      transition: all 0.15s ease-out;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      /* height: min(120px, calc(var(--vh, 1vh) * 20)); */
      .overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.15s ease-out;
        border-width: 0px;
        border-style: solid;
        border-color: transparent;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.35);
          opacity: 0;
          transition: all 0.15s ease-out;
        }
        &.current {
          border-width: 4px;
          border-color: rgba(${stylevars.palette.primary.rgb},1);
        }
        &.selected {
          border-width: 0px;
          border-color: #ffffff;
          &:before {
            opacity: 1;
            background-color: rgba(0,0,0,0.5);
          }
        }
      }
      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        margin: 0;
        padding: 0;
        line-height: 1;
        transition: all 0.15s ease-out;
      }

      &:hover {
        z-index: 11;
        .overlay {
          &:before {
            /* opacity: 1; */
          }
        }
        img {
          transform: scale(1.1);
        }
      }
      &.selected {
        img {
          transform: scale(1.1);
        }
      }
    }

    @media ${stylevars.breakpoints.phone} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media ${stylevars.breakpoints.phone} {
    height: auto;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 30rem);
  }
`