import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const ColorPickerContainer = styled('div')`
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &.show {
    background-color: rgba(0, 0, 0, 0.1);
    .colorPicker {
      padding-bottom: 1rem;
    }
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid ${stylevars.paper.borderColor};
  }
  .colorPicker {
    padding: 0.5rem 1rem;
  }
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .label {
    margin-right: 1rem;
    flex-grow: 1;
    font-size: 0.85rem;
  }
  .box {
    min-width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    border: 1px solid ${stylevars.paper.borderColor};
    background-color: ${stylevars.paper.bgColor};
    position: relative;

    .color {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      bottom: 0.25rem;
      right: 0.25rem;
      z-index: 1;
      background-image: url('/images/transparent-pattern.png');
      background-color: #fff;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
  }

  .colorPicker {
  }
  .picker {
    position: relative;
    z-index: 5;
    width: calc(100% - 0rem);
    margin: 0 auto;
    margin-top: 1rem;
  }
`