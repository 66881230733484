import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const ItemContainer = styled('div')`
  position: relative;

  &.disabled {
    .wrapper {
      opacity: 0.5;
    }
  }

  .itemActions {
    position: absolute;
    z-index: 3;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    pointer-events: none;

    .ui.button {
      background-color: transparent;
      padding-right: 3rem !important;
      margin: 0;
      pointer-events: none;
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
      i.icon {
        border-radius: 2.5rem;
        width: 2.25rem;
        height: 2.25rem;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
      }
      > span {
        transition: opacity 0.15s ease-out;
        opacity: 0;
        pointer-events: none;
      }
      &:hover {
        > span {
          opacity: 1;
        }
      }

      &.red {
        i.icon {
          background-color: ${stylevars.palette.danger.main};
        }
      }

      &.green {
        i.icon {
          background-color: ${stylevars.palette.success.main};
        }
      }

      &.blue {
        i.icon {
          background-color: ${stylevars.palette.info.main};
        }
      }
    }
  }
  
  > .wrapper {
    display: block;
    overflow: hidden;
    height: min(calc(var(--vh,1vh) * 40), 320px);
    @media ${stylevars.breakpoints.phone} {
      height: auto;
    }

    .image {
      display: block;
      width: 100%;
      height: 100%;
      transition: all 0.15s ease-out;
      overflow: hidden;
      margin: 0;
      padding: 0;
      transform: scale(1.1);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        margin: 0;
        padding: 0;
      }
      @media ${stylevars.breakpoints.phone} {
        height: auto;
        img {
          height: auto;
          transition: all 0.15s ease-out;
        }
      }
    }

    .content {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 2rem;
      /* background-color: rgba(0,0,0,0); */
      background-color: rgba(0,0,0,0) !important;
      color: #ffffff;
      transition: all 0.15s ease-out;

      @media ${stylevars.breakpoints.phone} {
        padding: 0;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 3;
        pointer-events: none;
        transition: top 0.15s ease-out, left 0.15s ease-out, right 0.15s ease-out, bottom 0.15s ease-out, border 0.15s ease-out, opacity 0.15s ease-out;
        /* top: 0rem;
        left: 0rem;
        right: 0rem;
        bottom: 0rem;
        border: 1px solid rgba(255,255,255,0); */
        top: 2rem;
        left: 2rem;
        bottom: 2rem;
        right: 2rem;
        background-color: rgba(0,0,0,0.75);
        border: 1px solid rgba(255,255,255,0.25);
        opacity: 0.5;

        @media ${stylevars.breakpoints.phone} {
          display: none;
        }
      }

      > .wrapper {
        width: 100%;
        height: 100%;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all 0.15s ease-out;
        position: relative;
        z-index: 4;
        /* opacity: 0; */
        opacity: 1;
      }
      .title {
        transition: all 0.15s ease-out;
        display: flex;
        justify-content: center;
        align-items: center;
        /* transform: translateY(-1rem); */
        transform: translateY(0rem);
        h2 {
          margin: 0;
          line-height: 1.6;
        }
        .label {
          margin-left: 1rem;
          display: inline-block;
          padding: 0.25rem 0.5rem;
          border: 1px solid ${stylevars.palette.primary.main};
          color: ${stylevars.palette.primary.main};
          font-size: 0.75rem;
          line-height: 1;
          border-radius: 0.25rem;
          text-transform: uppercase;
        }
      }
      .description {
        transition: all 0.15s ease-out;
        color: rgba(255,255,255,0.75);
        /* transform: translateY(1rem); */
        transform: translateY(0rem);
        @media ${stylevars.breakpoints.phone} {
          /* display: none; */
        }
        p {
          margin: 0;
          line-height: 1.6;
        }
      }
      .info {
        border-top: 1px solid rgba(255,255,255,0.25);
        padding: 1rem 0 0 0;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        @media ${stylevars.breakpoints.phone} {
          margin-top: 1rem;
        }
        /* .info-item */
        &-item {
          line-height: 1;
          margin: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @media ${stylevars.breakpoints.phone} {
            margin: 0.5rem;
          }
          .icon {
            margin-right: 0.5rem;
          }
          .text {
            font-size: 1rem;
            > span {
              @media ${stylevars.breakpoints.phone} {
                display: none;
                &.value {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    @media ${stylevars.breakpoints.phone} {
      border: 1px solid ${stylevars.paper.borderColor};
      .content {
        position: relative;
        background-color: rgba(0,0,0,0) !important;
        > .wrapper {
          opacity: 1;
          .title, .description, .info {
            transform: none;
          }
          .title {
            color: ${stylevars.typography.title.color};
            h2 {
              font-size: 1.25rem;
              text-align: left;
            }
          }
          .description {
            color: ${stylevars.typography.subtitle.color};
          }
          .info {
            color: ${stylevars.typography.title.color};
            border-top: 1px solid ${stylevars.paper.borderColor};
          }
        }
      }
    }
  }

  &:hover {
    > .wrapper {
      .image {
        transition: all 0.5s ease-out;
        /* transform: scale(1.1); */
        transform: scale(1);
        @media ${stylevars.breakpoints.phone} {
          transform: none;
          img {
            transition: all 0.5s ease-out;
            transform: scale(1.1);
          }
        }
      }
      .content {
        /* background-color: rgba(0,0,0,0.75); */
        background-color: rgba(0,0,0,0) !important;
        &:before {
          /* top: 2rem;
          left: 2rem;
          bottom: 2rem;
          right: 2rem;
          border: 1px solid rgba(255,255,255,0.25); */
          top: 0rem;
          left: 0rem;
          right: 0rem;
          bottom: 0rem;
          border: 1px solid rgba(255,255,255,0);
          opacity: 0;
        }
        > .wrapper {
          /* opacity: 1; */
          opacity: 0;
        }
        .title {
          transform: translateY(-1rem);
        }
        .description {
          transform: translateY(1rem);
        }
        /* .title, .description {
          transform: translateY(0rem);
        } */
      }
    }
  }
`