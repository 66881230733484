import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { consoleLogger, cleanPrice } from '@Utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// COMPONENTS
import { SFAvatar, UserDisplayName, LikeButton } from '@Common'

// STYLES
import { Container } from './CollectionCardStyles'
import 'react-lazy-load-image-component/src/effects/blur.css';

const CollectionCard = (props) => {
  const {
    itemData,
    onClick
  } = props
  const [ displayData, setDisplayData ] = React.useState()

  const history = useHistory()

  React.useEffect(() => {
    if (itemData) {
      consoleLogger('=========== itemData =============', itemData)
      setDisplayData(itemData)
    }
  }, [itemData])

  const handleClick = (ev) => {
    ev.preventDefault()
    if (onClick) {
      onClick()
    }
    history.push(`/@${displayData.user.username}/collections/${displayData.ID}`)
  }
  
  return (
    <Container>
      {displayData &&
        <div className='item'>
          <Link to={`/@${displayData.user.username}/collections/${displayData.ID}`} className='link' onClick={handleClick}>
            <LazyLoadImage
              src={displayData.cover_image.medium[0]}
              wrapperClassName='image'
              alt={`${displayData.title} cover`}
              effect="blur"
            />
          </Link>
          <div className='details'>
            <h4 className='title'>
              <span>
                {displayData.title}
              </span>
              <LikeButton itemData={itemData} type='collection' />
            </h4>
            {displayData.limited_edition &&
              <div className='field price'>
                <span className='label'>Limited Edition</span>
                <span className='value'></span>
              </div>
            }
            <div className='field creator'>
              <span className='label'>Creator</span>
              <span className='value'>
                <Link to={`/@${displayData.user.username}`} className='valueLink'>
                  <SFAvatar className='avatar' userInfo={displayData.user} />
                  <UserDisplayName user={displayData.user} />
                </Link>
              </span>
            </div>
          </div>
        </div>
      }
    </Container>
  )
}

export default React.memo(CollectionCard)