import React from 'react'
import filesize from 'filesize'
import { consoleLogger } from '@Utils'
import { Button, Dimmer, Loader, Progress } from 'semantic-ui-react'

import { ErrorListContainer } from './ErrorListStyles'

const UploadErrorList = (props) => {
  const {errors} = props
  React.useEffect(() => {
    consoleLogger('===================== ERROR LIST ===============', errors)
  }, [errors]) // eslint-disable-line
  return (
    <>
      <h4>There were photos that were not included in the queue.</h4>
      <ErrorListContainer className='thumbnails'>
        {errors.map((error, index) => (
          <div key={`${error.name}-${error.index}`} className='thumbnail'>
            <div className='thumbnailWrapper'>
              <div className='image'>
                <img
                  src={error.preview}
                  alt={error.file.name}
                  className='thumbnailImage'
                />
              </div>
              <div className='details'>
                <div className='name'>{error.file.name}</div>
                <div className='message'>{error.errors[0].message}</div>
              </div>
            </div>
          </div>
        ))}
      </ErrorListContainer>
    </>
  )
}

export default React.memo(UploadErrorList)
