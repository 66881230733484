import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch } from 'react-redux'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import SignupForm from './SignupForm'
import { Button } from 'semantic-ui-react'

// STYLES
import { Container } from './SignupSelectStyles'

const SignupSelect = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('signup'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Signup - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/signup`} />
      </Helmet>
      <div className='wrapper'>
        <h1 className='title'>Let's get you fired up!</h1>
        <p className='subtitle'>First, we need to know if you are a...</p>
        <div className='buttons'>
          <Button
            as={Link}
            to='/signup/creator'
            className='link'
            content='Creator'
            primary
            size='huge'
          />
          <Button
            as={Link}
            to='/signup/collector'
            className='link'
            content='Collector'
            primary
            basic
            size='huge'
          />
        </div>
      </div>
    </Container>
  )
}

export default React.memo(SignupSelect)
