import React from "react";
import he from "he";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { consoleLogger } from "@Utils";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";

// COMPONENTS
import { Button, Modal } from "semantic-ui-react";

const GalleryItemDelete = (props) => {
  const { itemData, formState } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.Authorization;

  const { userInfo, userKey, userCollections } = useSelector(getUserState);

  const [modalBusy, setModalBusy] = React.useState(false);
  const [openItemDelete, setOpenItemDelete] = React.useState(false);
  const [connectedCollection, setConnectedCollection] = React.useState();
  const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
  const [showSoldWarning, setShowSoldWarning] = React.useState(false);
  const handleCheckDeleteItem = () => {
    // check if item belongs to a collection
    let found = 0;
    let arr = [];
    userCollections.forEach((collection) => {
      const galleryData = collection.gallery_data;
      const item = galleryData.filter(
        (i) => i.id.toString() === itemData.id.toString()
      );
      if (item.length > 0) {
        found += item.length;
        arr.push(collection);
      }
    });
    setConnectedCollection(arr);
    consoleLogger(found);
    if (found > 0) {
      setShowDeleteWarning(true);
    } else {
      // check if it gallery has been sold
      if (Number(itemData.acf.sold_copies) > 0) {
        setShowSoldWarning(true);
      } else {
        handleOpenItemDelete(true);
      }
    }
  };
  const handleOpenItemDelete = (bool) => {
    setOpenItemDelete(bool);
  };

  const handleDeleteGalleryItem = async () => {
    // permanently delete sf-gallery item
    setModalBusy(true);
    let mediaId;
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery/${itemData.id}`,
      headers: {
        Authorization: `${token}`,
      },
      method: "DELETE",
      data: {
        author: userInfo.id,
        user_key: userKey,
      },
    })
      .then((res) => {
        consoleLogger("SF-GALLERY DELETED.... ", res.data);
        // permanently delete attached media
        mediaId = res.data.previous.featured_media;
      })
      .catch((err) => {
        consoleLogger(err);
      });

    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery`,
      headers: {
        Authorization: `${token}`,
      },
      method: "GET",
      params: {
        author: userInfo.id,
      },
    })
      .then((res) => {
        consoleLogger(
          "FETCHING USER GALLERY ========================",
          res.data
        );
        dispatch(userSlice.actions.setUserGallery(res.data));
      })
      .catch((err) => {
        consoleLogger("FETCH USER GALLERY ERROR: ", err);
      });

    // await axios({
    //   baseURL: `${process.env.REACT_APP_API_URL}`,
    //   url: `/wp/v2/media/${mediaId}`,
    //   headers: {
    //     Authorization: `${token}`,
    //   },
    //   method: "delete",
    //   data: {
    //     id: mediaId,
    //     force: true,
    //   },
    // })
    //   .then((res) => {
    //     (async () => {
    //       consoleLogger("ATTACHED MEDIA DELETED.... ", res.data);
    //       history.push(`/admin/gallery`);
    //     })();
    //   })
    //   .catch((err) => {
    //     consoleLogger(err);
    //   });

    history.push(`/admin/gallery`);

    setModalBusy(false);
    setOpenItemDelete(false);
  };

  const handleJumpCollection = () => {
    history.push(`/admin/collections/view/${connectedCollection[0].id}`);
  };

  return (
    <>
      <Button
        type="button"
        negative
        onClick={handleCheckDeleteItem}
        disabled={formState.isSubmitting || formState.isLoading}
        icon="trash"
        labelPosition="right"
        content={<span>Delete Permanently</span>}
      />

      {openItemDelete && (
        <Modal
          size="mini"
          open={openItemDelete}
          dimmer={{
            blurring: true,
          }}
          closeOnDimmerClick={!modalBusy}
          onOpen={() => handleOpenItemDelete(true)}
          onClose={() => handleOpenItemDelete(false)}
        >
          <Modal.Header>Deleting Gallery Item</Modal.Header>
          <Modal.Content scrolling>
            <p>
              You are about to delete{" "}
              <span className="text--bold">
                {he.decode(itemData.post_title)}
              </span>
              . Are you sure you want to continue?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              onClick={() => handleOpenItemDelete(false)}
              disabled={modalBusy}
            >
              No
            </Button>
            <Button
              negative
              onClick={handleDeleteGalleryItem}
              disabled={modalBusy}
              loading={modalBusy}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {showDeleteWarning && (
        <Modal
          size="mini"
          open={showDeleteWarning}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => setShowDeleteWarning(true)}
          onClose={() => setShowDeleteWarning(false)}
        >
          <Modal.Header>Deletion Warning</Modal.Header>
          <Modal.Content scrolling>
            <p>
              Unable to delete{" "}
              <span className="text--bold">
                {he.decode(itemData.post_title)}
              </span>
              . It appears that it belongs to a collection called{" "}
              <strong>{connectedCollection[0].title}</strong>. You need to
              remove it from the collection first before deleting the gallery
              item permanently.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              icon="close"
              labelPosition="right"
              content="Close"
              onClick={() => setShowDeleteWarning(false)}
            />
            <Button
              type="button"
              primary
              icon="arrow right"
              labelPosition="right"
              content={`Jump To That Collection`}
              onClick={handleJumpCollection}
            />
          </Modal.Actions>
        </Modal>
      )}
      {showSoldWarning && (
        <Modal
          size="mini"
          open={showSoldWarning}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => setShowSoldWarning(true)}
          onClose={() => setShowSoldWarning(false)}
        >
          <Modal.Content scrolling>
            <p>
              Unable to delete <strong>SOLD</strong> items.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              icon="close"
              labelPosition="right"
              content="Close"
              onClick={() => setShowSoldWarning(false)}
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default React.memo(GalleryItemDelete);
