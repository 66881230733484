import React from 'react'
import cn from 'classnames'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
// import userSlice, { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'
import { getUserState } from '@Slices/userSlice'
// import gallerySlice, { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import { Button } from 'semantic-ui-react'

// STYLES
import { ToolbarContainer } from './ToolbarStyles'

const AccountToolbar = (props) => {
  const { className } = props
  const { userInfo, userGallery, userCollections } = useSelector(getUserState)
  const { siteSettings, galleryCanArrange, galleryShowUploader } = useSelector(getUIState)
  const [ collectionLimit, setCollectionLimit ] = React.useState()
  const [ reachedLimit, setReachedLimit ] = React.useState(true)
  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    setCollectionLimit(userInfo.acf.subscription_type === 'premium' ? siteSettings.premiumCollectionsLimit : siteSettings.starterCollectionsLimit)
    setReady(true)
  }, []) // eslint-disable-line

  React.useEffect(() => {
    setReachedLimit(userInfo.acf.subscription_type === 'premium' ? false : userCollections.length >= collectionLimit)
  }, [collectionLimit]) // eslint-disable-line

  const [ toolbarShow, setToolbarShow ] = React.useState(false)
  const handleToolbarToggle = () => {
    setToolbarShow(prev => !prev)
  }

  return (
    <ToolbarContainer className={className}>
      <div className='toolbar-column left'>
        <div className='title'>
          <h1>Account</h1>
        </div>
      </div>
    </ToolbarContainer>
  )
}

export default React.memo(AccountToolbar)
