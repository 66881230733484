import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledContainer = styled.div`
  /* width: 100%;
  max-width: 720px; */

  .billingDropdownCombo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 1rem;

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }

    .billingDropdown {
      flex-grow: 1;
      margin: 0;
      @media ${stylevars.breakpoints.phone} {
        padding-right: 0 !important;
      }

      .menu {
        .item {
          &.active {
            color: ${stylevars.palette.primary.main};
            > * {
              font-weight: normal;
            }
            .addressDropdown_label {
              font-weight: bold;
            }
          }
          &.selected {
            background-color: ${stylevars.paper.borderColor};
            &.active {
              color: ${stylevars.typography.title.color};
              background-color: ${stylevars.palette.primary.main};
            }
          }
        }
      }
    }

    .billingDropdownAdd {
      margin-left: 1rem;
      margin-bottom: 0.1rem;
      height: auto;

      @media ${stylevars.breakpoints.phone} {
        margin-top: 0;
        width: 100%;
        /* > .icon {
          margin: 0 !important;
        }
        > span {
          display: none;
        } */
      }
    }

    &.new {
      .billingDropdownAdd {
        margin-top: 0;
      }
    }
  }

  .addressDropdown {
    /* .addressDropdown_wrap */
    &_wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
    }
    /* .addressDropdown_label */
    &_label {
      word-break: normal;
      white-space: nowrap;
      font-weight: bold;
      margin-right: 0.5rem;
    }
    /* .addressDropdown_extra */
    &_extra {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    }
    /* .addressDropdown_default */
    &_default {
      margin-left: 0.5rem;
      letter-spacing: 0.06rem;
      font-size: 0.6rem;
      border-radius: 0.3rem;
      border: 1px solid ${stylevars.palette.primary.main};
      color: ${stylevars.palette.primary.main};
      padding: 0.1rem 0.25rem;
      display: inline-block;
    }
  }

  .addressDisplayText {
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.05);
    line-height: 1.75rem;
    /* .addressDisplayText_name */
    &_name {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    /* .addressDisplayText_buttons */
    &_buttons {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 1rem;
      margin-top: 1rem;

      .ui.button {
        > .icon {
          @media ${stylevars.breakpoints.phone} {
            margin-right: 0 !important;
          }
        }
        > span {
          @media ${stylevars.breakpoints.phone} {
            display: none;
          }
        }
      }
    }
  }

  .addressForm {
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.05);

    /* .addressForm_buttons */
    &_buttons {

      /* .addressForm_buttons_wrap */
      &_wrap {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 1rem !important;
        margin-top: 1rem !important;
      }
    }
  }
`
