import React from 'react'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import currency from 'currency.js'
import { consoleLogger, getWeekNumber, get7Days } from '@Utils'
import { format } from 'date-fns'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Dimmer, Icon, Loader } from 'semantic-ui-react'

const shortNumber = require('short-number')

const WidgetSalesLatest = (props) => {
  const token = localStorage.Authorization
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  const [ tempTotal, setTempTotal ] = React.useState(0)
  const [ totalSales, setTotalSales ] = React.useState(0)
  const [ floatDigit, setFloatDigit ] = React.useState(0)
  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    (async() => {
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/getsales`,
        method: 'GET',
        headers: {
          Authorization:`${token}`,
        },
        params: {
          author: userInfo.id,
          user_key: userKey,
        }
      }).then(res => {
        if (res.data.length > 0) {
          const today = new Date()
          consoleLogger('today ===============', today)
          const tempAmount = currency(res.data.filter(n => {
            const nDays = get7Days()
            const nDate = format(new Date(n.post_date_gmt), 'MM/dd/yyyy')
            consoleLogger('==================== last 7 days ==================', nDays)
            return (nDays.includes(nDate))
          }).map(i => i.acf.total_amount).reduce((prev, next) => Number(prev) + Number(next)), { precision: 2, symbol: '', separator: '' }).format()
          setTempTotal(tempAmount)
          consoleLogger('================= tempAmount ===============', tempAmount)
          const [ tempWhole, tempFloat ] = tempAmount.toString().split('.')
          if (parseInt(tempWhole) > 10000) {
            setTotalSales(shortNumber(parseInt(tempAmount)))
          } else {
            setTotalSales(tempWhole)
          }
          setFloatDigit(tempFloat)
        }
      }).catch(err => {
        consoleLogger(err)
      })
      setReady(true)
    })()
  }, []) // eslint-disable-line

  return (
    <div className='widget'>
      {!ready &&
        <Dimmer inverted active>
          <Loader>Updating...</Loader>
        </Dimmer>
      }
      <div className='widgetWrapper'>
        <div className='label'>Last 7 Days Sales</div>
        <div className='content'>
          <Icon name='calendar outline' color='olive' size='big' />
          <div className='value currency'>
            <span className='symbol'>$</span>
            {tempTotal > 10000 ?
              <>
                <span className='amount'>{totalSales}</span>
              </> :
              <>
                <span className='amount'>
                  <NumberFormat fixedDecimalScale={false} value={totalSales} displayType={'text'} thousandSeparator={true} />
                </span>
                <span className='float'>
                  {`.`}
                  {floatDigit}
                </span>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(WidgetSalesLatest)
