import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { consoleLogger, truncate } from '@Utils'
import NumberFormat from 'react-number-format'
import { format, formatDistanceToNow } from 'date-fns'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import cn from 'classnames'
import he from 'he'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Icon, Button, Modal } from 'semantic-ui-react'

// STYLES
import { ItemContainer } from './ItemStyles'
import 'react-lazy-load-image-component/src/effects/blur.css';

const CollectionItem = (props) => {
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { userInfo, userKey } = useSelector(getUserState)
  const { classname, mode, vendorInfo, noHelmet } = props
  const [ ready, setReady ] = React.useState(false)
  const history = useHistory()

  const handleClick = (ev) => {
    ev.preventDefault()
    if (!noHelmet) {
      if (mode === 'view') {
        history.push(`/@${vendorInfo.username}/collections/${props.ID}`)
      } else {
        history.push(`/admin/collections/view/${props.ID}`)
      }
    }
  }

  React.useEffect(() => {
    if (vendorInfo) {
      setReady(true)
    }
  }, [vendorInfo])
  
  const [ openItemDelete, setOpenItemDelete ] = React.useState(false)
  const handleOpenItemDelete = (bool) => {
    setOpenItemDelete(bool)
  }
  
  const [ isDeleting, setIsDeleting ] = React.useState(false)
  const handleDeleteItem = async (props) => {
    setIsDeleting(true)
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/collections/${props.id}`,
      headers: {
        Authorization:`${token}`,
      },
      method: 'DELETE',
      data: {
        author: userInfo.id,
        user_key: userKey,
      },
    }).then((res) => {
      consoleLogger("COLLECTION DELETED.... ", res.data)
      dispatch(userSlice.actions.setCollections(res.data))
      toast.warn(`Collection item deleted - ${props.title}`, { containerId: 'mainToaster' });
    }).catch(err => {
      consoleLogger(err)
    })
    setOpenItemDelete(false)
  }

  return (
    <ItemContainer className={cn({
      'disabled' : !props.active,
      [`${classname}`] : classname
    })}>
      {ready &&
        <>
          {mode !== 'view' &&
            <div className='itemActions'>
              <Button
                circular
                icon="trash"
                type='button'
                labelPosition="right"
                content={<span>Delete Permanently</span>}
                color="red"
                onClick={() => handleOpenItemDelete(true)}
              />
            </div>
          }
          <Link className='wrapper'
            to={mode === 'view' ? `/@${vendorInfo.username}/collections/${props.ID}` : `/admin/collections/view/${props.ID}`}
            onClick={handleClick}
          >
            <LazyLoadImage
              wrapperClassName='image'
              // afterLoad={(ev) => {
              //   handleAfterLoad(ev)
              // }}
              effect="blur"
              alt={props.title}
              src={props.cover_image.medium[0] ? props.cover_image.medium[0] : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`}
            />
            <div className='content'>
              <div className='wrapper'>
                <div className='title'>
                  <h2>{truncate(he.decode(props.title), 35)}</h2>
                  {props.limited_edition &&
                    <span className='label'>Limited</span>
                  }
                </div>
                <div className='description'>
                  <p>{truncate(he.decode(props.description), 80)}</p>
                </div>
                <div className='info'>
                  <div className='info-item'>
                    <Icon name='images outline' />
                    <div className='text'><NumberFormat decimalScale={0} value={props.gallery_data.length} displayType={'text'} thousandSeparator={true} className='value' /></div>
                  </div>
                  <div className='info-item'>
                    <Icon name='calendar outline' />
                    <div className='text'><span>Date Posted:</span><span className='value'>{format(new Date(props.post_date_gmt.replace(/ /g, "T")), 'MM/dd/yyyy')}</span></div>
                  </div>
                  <div className='info-item'>
                    <Icon name='refresh' />
                    <div className='text'><span>Last Updated:</span><span className='value'>{formatDistanceToNow(new Date(props.post_modified_gmt.replace(/ /g, "T")), {addSuffix: true})}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {openItemDelete &&
            <Modal
              size='mini'
              open={openItemDelete}
              dimmer={{
                  blurring: true
              }}
              onOpen={() => handleOpenItemDelete(true)}
              onClose={() => handleOpenItemDelete(false)}
            >
              <Modal.Header>
                Deleting Collection
              </Modal.Header>
              <Modal.Content scrolling>
                <p>You are about to delete <span className="text--bold">{props.title}</span>. Are you sure you want to continue?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  basic
                  type='button'
                  disabled={isDeleting}
                  onClick={() => handleOpenItemDelete(false)}
                >
                  Cancel
                </Button>
                <Button
                  type='button'
                  content={`Delete`}
                  labelPosition='right'
                  icon={`check`}
                  onClick={() => handleDeleteItem(props)}
                  negative
                  loading={isDeleting}
                  disabled={isDeleting}
                />
              </Modal.Actions>
            </Modal>
          }
        </>
      }
    </ItemContainer>
  )
}

export default React.memo(CollectionItem)
