import React from 'react'
import { Progress } from 'semantic-ui-react'
import cn from 'classnames'

import { StyledPagePreloader } from './GeneralPreloaderStyles'

const PagePreloader = (props) => {
  const { title, children, linear } = props
  return (
    <StyledPagePreloader className={cn({
      'linear': linear
    })}>
      {linear ?
        <Progress size='tiny' color='blue' active percent={100} /> :
        <>
          <img className='logo' src='/logo_light.svg' alt='logo' />
          {title &&
            <div className='title'>{title}</div>
          }
          {children &&
            <div className='content'>
              {children}
            </div>
          }
        </>
      }
    </StyledPagePreloader>
  )
}

export default React.memo(PagePreloader)
