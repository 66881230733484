import React from 'react'
import { useLocation } from 'react-router-dom' // eslint-disable-line
import cn from 'classnames'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { consoleLogger, cleanPrice } from '@Utils'
import shortNumber from 'short-number'
import NumberFormat from 'react-number-format'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

// REDUX
import { useSelector } from 'react-redux'
import {getUIState} from '@Slices/uiSlice'

// COMPONENTS
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Image,
  Label,
  Loader
} from 'semantic-ui-react'
import { SFAvatar, UserDisplayName, LikeButton } from '@Common'

// STYLES
import { StyledContainer } from './HomeSectionStyles'

// install Virtual module
SwiperCore.use([Autoplay, Pagination, Navigation]);

const FeaturedItem = (props) => {
  const { itemData } = props
  const { currency } = useSelector(getUIState)

  const [ displayData, setDisplayData ] = React.useState()
  const [ stillAvailable, setStillAvailable ] = React.useState(true)

  React.useEffect(() => {
    if (itemData) {
      consoleLogger('=========== itemData =============', itemData)
      setDisplayData(itemData)
    }
  }, [itemData])

  React.useEffect(() => {
    (async() => {
      if (displayData) {
        // check if item is limited edition
        if (displayData.acf && displayData.acf.limited_edition) {
          // check if there are available copies left
          consoleLogger((Number(displayData.acf.sold_copies) + 1) >= Number(displayData.acf.number_lots))
          if ((Number(displayData.acf.sold_copies) + 1) >= Number(displayData.acf.number_lots)) {
            setStillAvailable(false)
          }
        }
      }
    })()
  }, [displayData]) // eslint-disable-line

  return (
    <>
      {displayData &&
        <div className='itemWrapper'>
          <div className='item' key={displayData.post_name}>
            <Link to={`/@${displayData.user.username}/gallery/${displayData.ID}`} className='link'>
              <img src={displayData.media.medium} className='image' alt={`${displayData.post_title} cover`} />
            </Link>
            <div className='details'>
              <h4 className='title'>
                <span>
                  {displayData.post_title}
                </span>
                <LikeButton itemData={itemData} type='gallery' />
              </h4>
              {displayData.acf.limited_edition &&
                <div className='field price'>
                  <span className='label'>Limited Edition</span>
                  <span className='value'>
                    {!!stillAvailable ?
                      <div className='copies'>
                        <NumberFormat
                          value={Number(displayData.acf.sold_copies) + 1}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <NumberFormat
                          value={Number(displayData.acf.number_lots)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix='/'
                        />
                      </div> : 
                      <>Sold Out</>
                    }
                  </span>
                </div>
              }
              <div className='field price'>
                <span className='label'>Base Price</span>
                <span className='value'>
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={cleanPrice(displayData.acf.base_price * currency.value)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={currency.symbol}
                  />
                </span>
              </div>
              <div className='field creator'>
                <span className='label'>Creator</span>
                <span className='value'>
                  <Link to={`/@${displayData.user.username}`} className='valueLink'>
                    <SFAvatar className='avatar' userInfo={displayData.user} />
                    <UserDisplayName user={displayData.user} />
                  </Link>
                </span>
              </div>
            </div>
            <div className='viewButton'>
              <Button as={Link} to={`/@${displayData.user.username}/gallery/${displayData.ID}`} content='View Artwork' color='blue' />
            </div>
          </div>
        </div>
      }
    </>
  )
}

const HomeSection = (props) => {
  const {
    limit
  } = props

  const { siteSettings } = useSelector(getUIState)

  const [isReady, setIsReady] = React.useState(false)
  const [items, setItems] = React.useState()
  const [ currentIndex, setCurrentIndex ] = React.useState()
  const [ bgImg, setBgImg ] = React.useState()
  const handleSlideChange = (ev) => {
    setCurrentIndex(ev.realIndex)
  }

  React.useEffect(() => {
    (async () => {
      if (siteSettings && siteSettings.featuredArtworks[0] !== null) {
        setItems(siteSettings.featuredArtworks)
      }
    })()
  }, [siteSettings]) // eslint-disable-line

  React.useEffect(() => {
    if (items) {
      consoleLogger('================= ITEMS', items)
      setIsReady(true)
    }
  }, [items]) // eslint-disable-line

  React.useEffect(() => {
    if (items) {
      consoleLogger(currentIndex, items.filter((i, index) => index === currentIndex)[0].media.thumbnail)
      setBgImg(items.filter((i, index) => index === currentIndex)[0].media.thumbnail)
    }
  }, [currentIndex]) // eslint-disable-line

  const [ swiperRef, setSwiperRef ] = React.useState()
  const handlePrevSlide = () => {
    swiperRef.slidePrev()
  }
  const handleNextSlide = () => {
    swiperRef.slideNext()
  }

  return (
    <StyledContainer>
      {isReady &&
        <>
          <div className='wrapper'>
            <div className='context'>
              <h1><span className='text'>Create<span className='color--red' /></span><span className='text'>Print<span className='color--green' /></span><span className='text'>Sell<span className='color--blue' /></span><span className='text'>Profit<span className='color--yellow' /></span></h1>
              <p>{process.env.REACT_APP_PROJECT_NAME} is a web portfolio print platform for photographers and artists.</p>
              <div className='actions'>
                <Button as={Link} to='/discover' primary basic size='huge' content='Discover' />
                <Button as={Link} to='/create' primary size='huge' content='Create' />
              </div>
            </div>
            <div className='featured'>
              <div className='nav'>
                <div className='navButton prev' onClick={handlePrevSlide}>
                  <Icon name='chevron left' />
                </div>
                <div className='navButton next' onClick={handleNextSlide}>
                  <Icon name='chevron right' />
                </div>
              </div>
              <div className='featuredWrapper'>
                <Swiper
                  loop={true}
                  spaceBetween={30}
                  slidesPerView={1}
                  grabCursor={true}
                  centeredSlides={true}
                  draggable={true}
                  autoplay={{
                    "delay": 5000,
                    "disableOnInteraction": true
                  }}
                  pagination={false}
                  navigation={false}
                  onSlideChange={(ev) => {
                    consoleLogger(ev, ev.realIndex)
                    handleSlideChange(ev)
                  }}
                  onSwiper={setSwiperRef}
                >
                  {items && items.length > 0 &&
                    (items.map(item => (
                      <SwiperSlide key={item.id}>
                        <FeaturedItem itemData={item} />
                      </SwiperSlide>
                    )
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
          {bgImg &&
            <div className='bg'>
              <div className='bgImage' style={{ backgroundImage: `url(${bgImg})`}} />
              <div className='bgOverlay' />
            </div>
          }
        </>
      }
    </StyledContainer>
  )
}

export default React.memo(HomeSection)
