import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { consoleLogger, usePrevious } from "@Utils";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import vendorSlice, { getVendorState } from "@Slices/vendorSlice";

// COMPONENTS
import { Button, Icon } from "semantic-ui-react";
import { Profile, Gallery, Collections, CollectionsView } from "./";
import GeneralPreloader from "@Common/GeneralPreloader";
import SocialMediaLinks from "./SocialMediaLinks";
import { ReactComponent as VerifiedSVG } from "./verified.svg";
import PageNotFound from "@AdminPages/404";

// STYLES
import StyledStoreUserContainer from "./StoreUserStyles";
import slugify from "slugify";

const RenderSubPage = ({ page, customUsername }) => {
  const { vendorInfo } = useSelector(getVendorState);

  switch (page) {
    case "profile":
      return (
        <Profile
          userInfo={vendorInfo}
          noHelmet={customUsername ? true : false}
        />
      );
    case "collections":
      return (
        <Collections noHelmet={customUsername ? true : false} mode="view" />
      );
    case "collections-view":
      return <CollectionsView noHelmet={customUsername ? true : false} />;
    case "collections-view-item":
      return <CollectionsView noHelmet={customUsername ? true : false} />;
    default:
      return <Gallery noHelmet={customUsername ? true : false} />;
  }
};

const StoreUserPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageScrolled } = useSelector(getUIState);
  const { vendorInfo, vendorProducts, vendorCollections } =
    useSelector(getVendorState);
  const [isReady, setIsReady] = React.useState(false);

  // const token = localStorage.Authorization
  const { username, page, postid, gid } = useParams();
  let theUsername = username;
  if (props.customUsername) {
    theUsername = props.customUsername;
  }
  const prevUsername = usePrevious(theUsername);
  const prevPostid = usePrevious(postid);

  const [userPage, setUserPage] = React.useState();
  const [userFound, setUserFound] = React.useState();
  const [userData, setUserData] = React.useState();

  React.useEffect(() => {
    if (!props.customUsername) {
      dispatch(uiSlice.actions.setPageTemplate("store"));
    }
    return () => {
      dispatch(vendorSlice.actions.setVendorInfo());
      dispatch(vendorSlice.actions.setVendorProducts());
      dispatch(vendorSlice.actions.setVendorCollections());
      dispatch(uiSlice.actions.setShowViewer(false));
      dispatch(uiSlice.actions.setMountViewer(false));

      dispatch(uiSlice.actions.setThemeIsDark(false));
      document.body.classList.remove("dimmable", "dimmed", "no-scrolling");
      // document.documentElement.style.setProperty('--sfProfile_bgColor', `rgba(255,255,255,1)`)
      // document.documentElement.style.setProperty('--sfProfile_titleTextColor', `rgba(31,31,31,1)`)
      // document.documentElement.style.setProperty('--sfProfile_socialIconColor', `rgba(255,255,255,1)`)
      // document.documentElement.style.setProperty('--sfProfile_dividerColor', `rgba(224,225,226,1)`)
      // document.documentElement.style.setProperty('--sfProfile_linkTextColor', `rgba(45,45,45,1)`)
      // document.documentElement.style.setProperty('--sfProfile_linkTextColorHover', `rgba(31,31,31,1)`)
      // document.documentElement.style.setProperty('--sfProfile_linkTextColorActive', `rgba(255,186,8,1)`)
      // document.documentElement.style.setProperty('--sfProfile_linkLineColor', `rgba(255,255,255,0)`)
      // document.documentElement.style.setProperty('--sfProfile_linkLineColorHover', `rgba(31,31,31,0.5)`)
      // document.documentElement.style.setProperty('--sfProfile_linkLineColorActive', `rgba(255,186,8,1)`)
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (theUsername) {
        if (prevUsername !== theUsername) {
          consoleLogger("USER STORE ===================", theUsername);
          dispatch(vendorSlice.actions.setVendorProducts([]));
          setIsReady(false);

          // FETCH VENDOR INFO
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/user/${theUsername}`,
            // headers: {
            //   Authorization:`${token}`,
            // },
            method: "GET",
          })
            .then((res) => {
              setUserData(res.data);
              consoleLogger("USER INFO RESPONSE =============", userData);
              dispatch(vendorSlice.actions.setVendorInfo(res.data));
              setUserFound(true);
            })
            .catch((err) => {
              consoleLogger(err);
              setUserFound(false);
              setIsReady(true);
            });
        }
      }
    })();
    // eslint-disable-next-line
  }, [prevUsername, theUsername]); // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (userFound) {
        // FETCH VENDOR GALLERY
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/gallery`,
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
          method: "GET",
          params: {
            // per_page: -1,
            // page: 1,
            // per_page: perPage,
            // page: 1,
            author: userData.id,
            // orderby: 'date_modified',
            // order: 'desc',
            status: ["publish"],
          },
        })
          .then((res) => {
            consoleLogger("GALLERY RESPONSE =============", res.data);
            // get total gallery count
            consoleLogger("Total Gallery: ", res.data.length);
            // setTotalPages(Math.ceil(res.data.length/perPage))
            dispatch(
              vendorSlice.actions.setVendorTotalProducts(res.data.length)
            );
            dispatch(vendorSlice.actions.addVendorProducts(res.data));
          })
          .catch((err) => {
            consoleLogger(err);
          });

        // FETCH VENDOR COLLECTIONS
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/collections`,
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
          method: "GET",
          params: {
            // per_page: -1,
            // page: 1,
            // per_page: perPage,
            // page: 1,
            author: userData.id,
            // orderby: 'date_modified',
            // order: 'desc',
            status: ["publish"],
          },
        })
          .then((res) => {
            consoleLogger("COLLECTIONS RESPONSE =============", res.data);
            // get total gallery count
            consoleLogger("Total Collections: ", res.data.length);
            dispatch(vendorSlice.actions.setVendorCollections(res.data));
          })
          .catch((err) => {
            consoleLogger(err);
          });

        // UPDATE VENDOR PAGEVIEWS
        // update pageview
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/pageview/${theUsername}`,
          // headers: {
          //   Authorization:`${token}`,
          // },
          method: "POST",
        })
          .then((res) => {
            consoleLogger("updated pageview");
          })
          .catch((err) => {
            consoleLogger(err);
          });

        if (!props.customUsername) {
          dispatch(uiSlice.actions.setCurrentPage(userData.name));
          dispatch(uiSlice.actions.setCurrentPageURL());
          dispatch(uiSlice.actions.setCurrentPageTitle());
        }
      }
    })();
    // eslint-disable-next-line
  }, [userFound, userData]);

  React.useEffect(() => {
    if (vendorInfo && vendorProducts && vendorCollections) {
      setIsReady(true);
      consoleLogger("USER STORE FRONT IS READY =======================");
    }
    // eslint-disable-next-line
  }, [vendorInfo, vendorProducts, vendorCollections]);

  React.useEffect(() => {
    consoleLogger(
      `PAGE POSTID GID ====================== ${page} - ${postid} - ${gid} =======================`
    );

    // PROFILE SUB PAGE
    if (page === "profile" && !postid && !gid) {
      consoleLogger("PROFILE SUB PAGE ========================");
      setUserPage("profile");
    }
    // GALLERY SUB PAGE
    if (!page && !postid && !gid) {
      consoleLogger("USER STORE FRONT DEFAULT ========================");
      setUserPage("gallery");
    }
    if (page === "gallery" && !postid && !gid) {
      consoleLogger("GALLERY LIST ========================", postid, gid);
      setUserPage("gallery");
    }
    if (page === "gallery" && postid && !gid) {
      consoleLogger("GALLERY POST ID ========================", postid, gid);
      setUserPage("gallery");
      dispatch(uiSlice.actions.setMountViewer(true));
    }
    // COLLECTION SUB PAGE
    if (page === "collections" && !postid && !gid) {
      consoleLogger("COLLECTION LIST ========================", postid, gid);
      setUserPage("collections");
    }
    if (page === "collections" && postid && !gid) {
      consoleLogger("COLLECTION POST ID ========================", postid);
      setUserPage("collections-view");
    }
    if (page === "collections" && postid && gid) {
      consoleLogger(
        "COLLECTION POST ID AND GALLERY ITEM ID ========================",
        postid,
        gid
      );
      setUserPage("collections-view-item");
      dispatch(uiSlice.actions.setMountViewer(true));
    }
    // eslint-disable-next-line
  }, [postid, gid, page]);

  // const handleToggleUserPage = (ev, { name }) => {
  // ev.preventDefault();
  // const npage = ev.currentTarget.name;
  // setUserPage(npage);
  // if (!props.customUsername) {
  //   history.push(`/@${theUsername}/${npage}`);
  // }
  // setUserPage(name);
  // if (!props.customUsername) {
  //   history.push(`/@${theUsername}/${npage}`);
  // }
  // };

  // React.useEffect(() => {
  //   if (page) {
  //     setUserPage(page);
  //     // } else {
  //     //   if (!props.customUsername) {
  //     //     history.push(`/@${theUsername}`)
  //     //   }
  //   }
  // }, [page]); // eslint-disable-line

  return (
    <>
      {isReady ? (
        <>
          {userFound ? (
            <>
              <StyledStoreUserContainer
                className={cn("profileContainer", {
                  pageScrolled,
                  [`theme-${vendorInfo.acf.layout_style}`]: userFound
                    ? vendorInfo.acf.layout_style
                    : false,
                })}
              >
                <div className={cn("profile")}>
                  <div className="profile-image">
                    <img
                      src={
                        vendorInfo.acf.profile_picture
                          ? vendorInfo.acf.profile_picture
                          : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_photo.jpg`
                      }
                      alt={`${vendorInfo.name} - Avatar`}
                    />
                  </div>
                  <div className="profile-name">
                    <span className="name">{vendorInfo.name}</span>
                    {vendorInfo.acf.subscription_type === "premium" && (
                      <span className="badge">
                        <VerifiedSVG />
                      </span>
                    )}
                  </div>
                  <div
                    className="profile-cover"
                    style={{
                      backgroundImage: vendorInfo.acf.background_image
                        ? `url(${vendorInfo.acf.background_image})`
                        : `url(${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg)`,
                    }}
                  />
                  <SocialMediaLinks />
                </div>
                <div className={cn("toolbar")}>
                  <div className={cn("toolbar-wrapper")}>
                    <Link
                      to={`/@${vendorInfo.username}/profile`}
                      name="profile"
                      // onClick={handleToggleUserPage}
                      className={cn("ui button", {
                        active: userPage.indexOf("profile") > -1,
                      })}
                    >
                      Profile
                    </Link>
                    <Link
                      to={`/@${vendorInfo.username}/gallery`}
                      name="gallery"
                      // onClick={handleToggleUserPage}
                      className={cn("ui button", {
                        active: userPage.indexOf("gallery") > -1,
                      })}
                    >
                      Gallery
                    </Link>
                    {vendorCollections &&
                      vendorCollections.filter((n) => n.active).length > 0 && (
                        <Link
                          to={`/@${vendorInfo.username}/collections`}
                          name="collections"
                          // onClick={handleToggleUserPage}
                          className={cn("ui button", {
                            active: userPage.indexOf("collections") > -1,
                          })}
                        >
                          Collections
                        </Link>
                      )}
                  </div>
                </div>
                <div
                  className={cn("content", {
                    [`content--${userPage}`]: userPage,
                  })}
                >
                  <RenderSubPage
                    page={userPage}
                    customUsername={props.customUsername}
                  />
                </div>
              </StyledStoreUserContainer>
            </>
          ) : (
            <>
              <PageNotFound />
            </>
          )}
        </>
      ) : (
        <GeneralPreloader />
      )}
    </>
  );
};

export default React.memo(StoreUserPage);
