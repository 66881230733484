import React from 'react'
// import axios from 'axios'
// import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  Icon,
  // Table,
  Button,
  // Checkbox,
  // Modal,
  // Grid,
  // Pagination,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  Popup,
  // Search,
  // Dropdown,
} from 'semantic-ui-react'
import {
  TableSearch,
  // TableFilter,
} from '../DataTable'

const ButtonRender = (props) => {
  const {
    action,
    icon,
    text,
    ...buttonProps 
  } = props
  return (
    <Button icon labelPosition='left' {...buttonProps} onClick={action}>
      <Icon name={icon} />
      <span>{text}</span>
    </Button>
  )
}

const TableToolbar = (props) => {
  const {
    info,
    toolbar,
    handleFetch
  } = props

  const [ buttons, setButtons ] = React.useState(toolbar.buttons)

  return (
    <>
      {buttons && buttons.length > 0 &&
        <div className='tableHeaderToolbar'>
          {/* <Popup content={`Refresh Coupons List`} inverted
            trigger={
              <Button basic className='tableRefresher'>
                <Icon name='refresh' />
              </Button>
            }
          /> */}
          {/* <TableSearch info={info} handleFetch={handleFetch} /> */}
          <h3 className='tableHeaderToolbar_label'>{info.plural} List</h3>
          <Button.Group>
            {buttons && buttons.map(button => <ButtonRender {...button} />)}
          </Button.Group>
        </div>
      }
    </>
  )
}

export default React.memo(TableToolbar)
