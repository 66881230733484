import React from 'react'
import axios from 'axios';
import cn from 'classnames'
import seedrandom from 'seedrandom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import { Button, Dropdown, Icon, Modal, Tab } from 'semantic-ui-react';
import { MediaLibrary, MediaUpload } from '.';

// STYLES
import { CoverPhotoContainer, MediaTabContainer } from './CoverPhotoStyles'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
import PhotoUploader from '../../Layout/Components/PhotoUploader';

const CoverPhoto = (props) => {
  const token = localStorage.Authorization
  const { collectionData, handler, disabled } = props
  
  const dispatch = useDispatch()
  const { userInfo, userKey } = useSelector(getUserState)

  const [ modalType, setModalType ] = React.useState()
  const [ ready, setReady ] = React.useState(false)
  // const handleAfterLoad = () => {
  // }
  const [ modalOpen, setModalOpen ] = React.useState(false)
  const handleModalOpen = (bool) => {
    setModalOpen(bool)
    setSelectedCover()
  }

  const [ selectedCover, setSelectedCover ] = React.useState()
  const handleSelectedCover = (data) => {
    setSelectedCover(data)
  }
  const [ processing, setProcessing ] = React.useState(false)
  const saveNewCover = async () => {
    setProcessing(true)
    const newData = new Object()
    newData.user_key = userKey
    if (selectedCover.type === 'upload') {
      consoleLogger('============= CANVAS UPLOAD ==================', selectedCover)
      const base64 = selectedCover.data.getImageScaledToCanvas().toDataURL()
      // const tempCanvas = editor.getImage().toDataURL()
      consoleLogger('================== base64 ================', base64)
      const rng = seedrandom()
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/media`,
        headers: {
          Authorization:`${token}`,
        },
        method: 'POST',
        data: {
          user_key: userKey,
          author: userInfo.id,
          base64: base64,
          title: Math.abs(rng.int32())
        },
      }).then(async (res) => {
        consoleLogger("UPLOAD COLLECTION COVER PHOTO RESPONSE.... ", res.data)
        newData.cover_image = res.data.id
        newData.data = res.data
      }).catch(err => {
        consoleLogger(err)
      })
    } else {
      newData.cover_image = selectedCover.id
      newData.data = selectedCover
    }

    handler(newData.data)
    
    // await axios({
    //   baseURL: process.env.REACT_APP_API_URL,
    //   url: `/stylefolio/v1/collections/${cid}`,
    //   headers: {
    //     Authorization: `${token}`,
    //   },
    //   method: 'POST',
    //   data: newData
    // }).then(res => {
    //   consoleLogger("UPDATE COLLECTION COVER PHOTO RESPONSE ==========", res.data)
    //   toast.success(`Collection updated - ${res.data.title}`, {
    //     containerId: 'mainToaster',
    //     progress: undefined,
    //   });
    //   dispatch(uiSlice.actions.setCurrentPage(`collections`))
    //   dispatch(uiSlice.actions.setCurrentPageURL(`admin/collections`))
    //   dispatch(uiSlice.actions.setCurrentPageTitle(`${res.data.title}`))
    //   dispatch(userSlice.actions.updateUserCollection(res.data))
    // }).catch(err => {
    //   consoleLogger("ERROR: ", err)
    // })

    setProcessing(false)
    handleModalOpen(false)
  }

  const [ imageData, setImageData ] = React.useState()
  React.useEffect(() => {
    if (collectionData) {
      setImageData({
        src: collectionData.cover_image && collectionData.cover_image.id ? collectionData.cover_image.medium[0] : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`,
        alt: collectionData ? collectionData.title : '',
      })
    } else {
      setImageData({
        src: `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`,
        alt: '',
      })
    }
    setReady(true)
  }, [collectionData])

  // consoleLogger('====================================================================== IMAGE DATA')
  // consoleLogger(imageData)

  return (
    <>
      <CoverPhotoContainer>
        {ready &&
          <>
            <div className='overlay'>
              <div className='actions'>
                {/* <Button
                  type='button'
                  icon='close'
                  color='red'
                  basic
                  content='Remove Cover Photo'
                  labelPosition='right'
                  size='large'
                /> */}
                
                <Button.Group color='blue'>
                  <Dropdown
                    button
                    labeled
                    floating
                    className='icon'
                    icon='image outline'
                    text='Change Cover Photo'
                    pointing='bottom'
                    disabled={disabled}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item icon='table' text='Select From Library'
                        onClick={() => {
                          setModalType('library')
                          handleModalOpen(true)
                        }}
                      />
                      <Dropdown.Item icon='upload cloud' text='Upload Photo'
                        onClick={() => {
                          setModalType('upload')
                          handleModalOpen(true)
                        }}
                      />
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Button.Group>
              </div>
            </div>
            <LazyLoadImage
              wrapperClassName='imageWrapper'
              // afterLoad={(ev) => {
              //   handleAfterLoad(ev)
              // }}
              effect="blur"
              alt={imageData.alt}
              src={imageData.src}
            />
          </>
        }
      </CoverPhotoContainer>
      
      {modalOpen &&
        <Modal
          size='large'
          open={modalOpen}
          dimmer={{
              blurring: true
          }}
          onOpen={() => handleModalOpen(true)}
          onClose={() => handleModalOpen(false)}
          className='coverPhotoModal'
        >
          <Modal.Header>
            {modalType === 'library' ? `Select From Library` : `Upload Photo`}
          </Modal.Header>
          <Modal.Content>
            <MediaTabContainer className='mediaTab'>
              <div className='mediaTabContent'>
                { modalType === 'library' ?
                  <MediaLibrary collectionData={collectionData} handleChange={handleSelectedCover} /> :
                  <MediaUpload type='cover' defaultImage={imageData.src} width={700} height={420} className='display_cover'  handleChange={handleSelectedCover} />
                }
              </div>
            </MediaTabContainer>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              icon='close'
              content='Cancel'
              labelPosition='right'
              disabled={processing}
              onClick={() => handleModalOpen(false)}
            />
            {selectedCover &&
              <Button
                positive
                icon={modalType === 'library' ? 'arrow right' : 'upload cloud'}
                content={modalType === 'library' ? 'Select Photo' : 'Upload Photo'}
                labelPosition='right'
                disabled={processing}
                loading={processing}
                onClick={saveNewCover}
              />
            }
          </Modal.Actions>
        </Modal>
      }
    </>
  )
}

export default React.memo(CoverPhoto)
