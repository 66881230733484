import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  height: 100%;
  max-height: calc(calc(var(--vh, 1vh) * 80) - 20rem);
  .wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }
  }

  .title {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .image {
    grid-column-start: 1;
    grid-column-end: 6;
    max-height: calc(calc(var(--vh, 1vh) * 80) - 20rem);
    position: relative;
    display: block;
    line-height: 0;
    margin: 0;
    padding: 0;
    .imageWrapper {
      width: 100%;
      height: 100%;
      display: block;
      line-height: 0;
      margin: 0;
      padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      line-height: 0;
      margin: 0;
      padding: 0;
    }
  }

  .limited {
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 2;
    white-space: nowrap;
    line-height: 1.4;

    background-color: rgba(0,0,0,0.5);
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 0.85rem;
  }

  .details {
    grid-column-start: 6;
    grid-column-end: 9;

    .detailsTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem;
      background-color: rgba(0,0,0,0.05);
      padding: 0.5rem;

      > h2 {
        flex-grow: 1;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      > div {
        margin: 0;
        .dropdown .text {
          min-width: 3.5rem;
        }
      }
    }

    .optionTitle {
      .small {
        display: block;
        line-height: 1;
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
      }
    }
    .optionGroup {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      user-select: none;

      .note {
        margin-top: 0.5rem;
        color: rgba(0,0,0,0.5);
        /* padding: 0 1rem; */
      }
    }

    .segment_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      > .header {
        line-height: 2.2;
        width: 30%;
        margin: 0;
        margin-right: 1rem;
      }
      > .dropdown {
        width: 70%;
      }
    }
  }

  .quantity {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 0.5rem;
    border-radius: 0.25rem;
    user-select: none;

    .sub, .add {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      background-color: ${stylevars.palette.info.main};
      color: ${stylevars.palette.info.contrastText};
      border-radius: 2rem;
      cursor: pointer;

      .icon {
        font-size: 0.75rem;
        height: auto;
        width: auto;
        margin: 0;
        padding: 0;
        line-height: 1;
        &:before {
          height: auto;
          width: auto;
          line-height: 1;
        }
      }
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 2rem;
    }
  }

  .costs {
    padding: 1rem;
    background-color: rgba(0,0,0,0.05);
    border-radius: 0.25rem;
    .costs-item {
      display: flex;
      flex-wrap: nowrap;

      &:not(:last-of-type) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px dashed rgba(0,0,0,0.15);
      }

      &:nth-last-of-type(2) {
        border-bottom: 2px solid rgba(0,0,0,0.15);
      }

      &.total {
      }

      .label {
        width: 30%;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .value {
        flex-grow: 1;
        width: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .shippingValueGroup {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
        }

        .isLocal {
          width: 100%;
          margin-left: 1rem;
          color: rgba(0,0,0,0.5);
        }

        .shippingFee {
          display: block;
        }

        .shippingInfo {
          margin-top: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
        }

        .currentAddress {
          width: auto;
          /* height: 2rem; */
        }

        .shippingInfoActions {
          margin-top: 0.5rem;
        }

        .mailingButton {
          width: auto;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          color: ${stylevars.palette.info.main};
          border: 1px solid ${stylevars.palette.info.main};
          padding: 0.25rem 0.5rem;
          font-size: 0.75rem;
          cursor: pointer;
          height: 2rem;
          border-radius: 0.25rem;
          line-height: 1;
        }
      }
    }
  }
`