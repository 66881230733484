import React from "react";
import cn from "classnames";
import axios from "axios";
import { Link } from "react-router-dom";
import { consoleLogger } from "@Utils";
import { LazyLoadImage } from "react-lazy-load-image-component";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import { Dimmer, Grid, Image, Loader } from "semantic-ui-react";
import { UserCard } from "./";

// STYLES
import { NewCreatorsContainer } from "./AllCreatorsStyles";
import "react-lazy-load-image-component/src/effects/blur.css";

const NewCreators = (props) => {
  const { limit } = props;

  const { siteSettings } = useSelector(getUIState);
  const [isReady, setIsReady] = React.useState(false);
  const [trendingUsers, setTrendingUsers] = React.useState();

  React.useEffect(() => {
    (async () => {
      let tempArray = [];
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}/stylefolio/v1/users/all`,
        // headers: {
        //   Authorization: token,
        // },
        method: "GET",
      })
        .then((res) => {
          setTrendingUsers(res.data);
        })
        .catch((err) => {
          consoleLogger("ERROR ===========", err);
        });
      setIsReady(true);
    })();
  }, []); // eslint-disable-line

  return (
    <NewCreatorsContainer>
      <div className="wrapper">
        <h2 className="sectionTitle">List of All Creators</h2>
        <div className="gridList">
          {!isReady && (
            <Dimmer active inverted>
              <Loader inverted>Loading trending creators...</Loader>
            </Dimmer>
          )}
          {trendingUsers &&
            trendingUsers.map((user) => (
              <UserCard user={user} key={user.username} />
            ))}
        </div>
      </div>
    </NewCreatorsContainer>
  );
};

export default React.memo(NewCreators);
