import React from 'react'
import { Link, useParams, useHistory, useLocation } from "react-router-dom"
import axios from 'axios'
import { consoleLogger, setAuthorizationHeader, useSiteCurrency } from '@Utils'
import jwtDecode from 'jwt-decode'
import cn from 'classnames'
import ReCAPTCHA from "react-google-recaptcha";
// import { Helmet } from 'react-helmet'

// FORMIK
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Grid, Button, Form as SUIForm, Message, Icon, Label } from 'semantic-ui-react'
import {FormBox, StyledCard} from './Styles'

// VALIDATION SCHEMA
Yup.addMethod(Yup.string, 'nospecial', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    value = originalValue.matches(/[^a-zA-Z0-9]/)

    consoleLogger(originalValue, value)
    return value ? value.toString() : ''
  });
});
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Must enter an email')
})

const ResetPasswordForm = (props) => {
  const { className, to, redirect, customer, handleModal, ...restProps } = props
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { forexData } = useSelector(getUIState)
  const { authenticated } = useSelector(getUserState)
  const { path } = useParams()
  const currentPath = location.pathname
  const decodedPath = decodeURIComponent(path)
  const [ signupLink, setSignupLink ] = React.useState('/signup')

  const [ requested, setRequested ] = React.useState(false)
  const [ resetData, setResetData ] = React.useState()
  const handleRequested = (bool) => {
    setRequested(bool)
  }

  const recaptchaRef = React.useRef()

  React.useEffect(() => {
    // dispatch(uiSlice.actions.setPageTemplate('store'))
    // dispatch(uiSlice.actions.setCurrentPage('login'))
    // dispatch(uiSlice.actions.setCurrentPageURL())
    // dispatch(uiSlice.actions.setCurrentPageTitle())

    consoleLogger("decodedPath ===================", decodedPath)
    if (customer) {
      setSignupLink(state => (`${state}/collector`))
    }
    if (handleModal) {
      if (path) {
        setSignupLink(state => (`${state}/redirect/${encodeURIComponent(path)}`))
      } else if (currentPath) {
        if (currentPath.indexOf('/signup') <= -1) {
          setSignupLink(state => (`${state}/redirect/${encodeURIComponent(currentPath)}`))
        }
      }
    }
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('LOGIN PAGE')
    if (authenticated) {
      if (!handleModal) {
        history.push('/admin')
      }
    }
  }, [authenticated]) // eslint-disable-line

  const handleSignupLink = (ev) => {
    ev.preventDefault()
    if (handleModal) {
      handleModal(false)
    }
    history.push(signupLink)
  }

  const [ isHuman, setIsHuman ] = React.useState(false)
  const handleReCaptchaChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue()
    consoleLogger('=================== RECAPTCH CHANGE ======================', recaptchaValue)
    setIsHuman(recaptchaValue ? true : false)
  }

  React.useEffect(() => {
    consoleLogger(isHuman)
  }, [isHuman])

  return (
    <FormBox className={cn('formBoxContainer', {
      [className] : className
    })}>
      <Formik
        initialValues={{
          email: ''
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          (async () => {
            dispatch(userSlice.actions.setAuthenticated(false))
            setSubmitting(true)
            consoleLogger('RESET PASSWORD FORM =====================', data)
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/stylefolio/v1/resetpassword`,
              method: 'POST',
              data: data
            })
            .then(res => {
              consoleLogger('PASSWORD RESET RESPONSE =====================', res.data)
              handleRequested(true)
              setResetData(res.data)
            })
            .catch(err => {
              consoleLogger("LOGIN ERROR: ", err)
              handleRequested(false)
            })
            setSubmitting(false)
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setValues, errors, isValid, dirty}) => (
          <Form className={cn('ui form', {
            [className]: className
          })}>
            <Grid centered padded>
              <Grid.Column className='formBox'>
                <h1>Password Reset</h1>
                {customer && handleModal &&
                  <p>Before you can add things to your cart, you need to login first.</p>
                }
                {!requested ?
                  <>
                    <StyledCard>
                      <StyledCard.Content>
                        <SUIForm.Input name='email' fluid placeholder='Email Address' type='text' label='Email Address'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email}
                          disabled={isSubmitting}
                        />
                        <SUIForm.Field>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Ldr4oMdAAAAAArmiv6Sg99VPHP_wXUcHhmrcNgA"
                            onChange={(ev) => {
                              handleChange(ev)
                              handleReCaptchaChange()
                            }}
                          />
                        </SUIForm.Field>
                      </StyledCard.Content>
                      <StyledCard.Content extra>
                        <div className='ui two buttons'>
                          <Button
                            size='large'
                            type='submit'
                            color='green'
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                            disabled={!(dirty && isValid && isHuman) || isSubmitting}
                          >
                            Request Reset
                          </Button>
                        </div>
                      </StyledCard.Content>
                      <StyledCard.Content extra className='subCard'>
                        <p>Don't have an account yet? <Link to={signupLink} onClick={handleSignupLink}>Signup now!</Link></p>
                      </StyledCard.Content>
                    </StyledCard>
                    {errors && errors.message &&
                      <Message negative>
                        <div dangerouslySetInnerHTML={{ __html: errors.message }} />
                      </Message>
                    }
                  </> : 
                  <>
                    {resetData &&
                      <StyledCard>
                        <StyledCard.Content>
                          <p>We have sent a reset link to your email address (<span className='text--bold'>{resetData.email}</span>). Please check your inbox or in some cases you might want to check your spam folder as well.</p>
                        </StyledCard.Content>
                        <StyledCard.Content extra className='subCard'>
                          <Button as={Link} to='/login' icon='arrow left' labelPosition='left' content='Go back to login page' />
                        </StyledCard.Content>
                      </StyledCard>
                    }
                  </>
                }
              </Grid.Column>
            </Grid>
          </Form>
        )}
      </Formik>
    </FormBox>
  )
}

export default ResetPasswordForm
