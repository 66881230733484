import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { consoleLogger } from "@Utils";

// REDUX
import { useDispatch } from "react-redux";
import userSlice from "@Slices/userSlice";

// COMPONENTS
import RequestForm from "./RequestForm";
import RequestPayment from "./RequestPayment";

const RequestPrintButton = (props) => {
  const { post } = props;

  const dispatch = useDispatch();

  const [requestMode, setRequestMode] = React.useState("order");
  const [requestOpen, setRequestOpen] = React.useState(false);
  const [requestClean, setRequestClean] = React.useState(false);
  const [cartItem, setCartItem] = React.useState();
  const handleRequestClean = (bool) => {
    setRequestClean(bool);
  };
  const handleRequestSample = (bool) => {
    setRequestOpen((prev) => (bool ? bool : !prev));
    if (bool === false) {
      setRequestMode("order");
      setRequestClean(false);
      dispatch(userSlice.actions.setPrintRequest({}));
    }
  };
  const handleCartItem = (item) => {
    setCartItem(item);
  };
  const handleCartItemSubmit = () => {
    consoleLogger("================= CART ITEM SUBMIT", cartItem);
    handleRequestMode("payment");
    // dispatch(cartSlice.actions.addToCart(data))
  };
  const handleRequestMode = (mode) => {
    setRequestMode(mode);
  };

  return (
    <>
      <Button
        icon="print"
        type="button"
        labelPosition="right"
        content={<span>Print Artwork</span>}
        className="request"
        color="blue"
        onClick={handleRequestSample}
      />
      {requestOpen && (
        <Modal
          size={requestMode === "order" ? "large" : "tiny"}
          open={requestOpen}
          dimmer={{
            blurring: true,
          }}
          closeOnDimmerClick={requestMode === "order" ? true : false}
          onOpen={() => handleRequestSample(true)}
          onClose={() => handleRequestSample(false)}
          className="selfPrintModal"
        >
          <Modal.Header>Print Artwork</Modal.Header>
          <Modal.Content scrolling>
            {requestMode === "order" ? (
              <RequestForm
                data={post}
                clean={requestClean}
                handleClean={handleRequestClean}
                handleCartItem={handleCartItem}
              />
            ) : (
              <RequestPayment handleModal={handleRequestSample} />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              icon="close"
              labelPosition="right"
              content="Cancel"
              onClick={() => handleRequestSample(false)}
            />
            {requestMode === "order" ? (
              <Button
                type="button"
                primary
                icon="arrow right"
                labelPosition="right"
                content={`Payment`}
                disabled={!requestClean}
                onClick={handleCartItemSubmit}
              />
            ) : (
              <Button
                type="button"
                icon="arrow left"
                labelPosition="right"
                content="Back"
                onClick={() => setRequestMode("order")}
              />
            )}
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default React.memo(RequestPrintButton);
