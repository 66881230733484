import React from 'react'
import { useParams } from 'react-router-dom'
// import axios from 'axios'
import { Helmet } from 'react-helmet'
import { titleCase } from 'title-case'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector } from 'react-redux'
import { getVendorState } from '@Slices/vendorSlice'

// COMPONENTS
import ReactQuill from 'react-quill'
// import { Button } from 'semantic-ui-react'

// STYLES
import { ProfileContainer } from './ProfileStyles'

const Profile = (props) => {
  const { noHelmet } = props
  const { username, page } = useParams() // eslint-disable-line
  const { vendorInfo } = useSelector(getVendorState)

  return (
    <ProfileContainer>
      {vendorInfo ?
        <>
          <div className='profileContent'>
            <ReactQuill value={vendorInfo.acf.biography} readOnly className='viewOnly' />
          </div>
          {!noHelmet &&
            <Helmet>
              <meta charSet="utf-8" />
              <title>{vendorInfo.name} - {titleCase(page)}</title>
              <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/@${username}/${page}`} />
            </Helmet>
          }
        </> :
        <div>Loading...</div>
      }
    </ProfileContainer>
  )
}

export default React.memo(Profile)
