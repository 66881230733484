import React from 'react'
// import axios from 'axios'
import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  Icon,
  // Table,
  Button,
  // Checkbox,
  // Modal,
  // Grid,
  // Pagination,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  Dropdown,
} from 'semantic-ui-react'

const TableFilter = (props) => {
  const {
    info
  } = props
  const dispatch = useDispatch() // eslint-disable-line
  const { userInfo } = useSelector(getUserState) // eslint-disable-line
  const { uiFilters, uiTables } = useSelector(getUIState) // eslint-disable-line
  const [ tableInfo, setTableInfo ] = React.useState(uiTables.filter(i => i.component === info.namespace)[0]) // eslint-disable-line
  const [ filters, setFilters ] = React.useState(uiFilters.filter(i => i.component === info.namespace).map(n => n.filters)[0]) // eslint-disable-line
  const handleFilterChange = async (value) => {
    consoleLogger(value)
    dispatch(uiSlice.actions.updateUIFilters(
      {
        component: info.namespace,
        filters: filters.map(el => {
          if (el.type === 'item' && el.value === value) {
            // return Object.assign({}, el, {active: !el.active})
            return ({...el, active: !el.active})
          }
          return el
        })
      }
    ))
  }
  const handleFilterReset = () => {
    dispatch(uiSlice.actions.updateUIFilters(
      {
        component: info.namespace,
        filters: filters.map(el => {
          if (el.type === 'item') {
            // return Object.assign({}, el, {active: true})
            return ({...el, active: true})
          }
          return el
        })
      }
    ))
  }

  const FilterItem = (props) => {
    const {
      type
    } = props
    switch(type) {
      case 'header':
        return (
          <Dropdown.Header>{props.text}</Dropdown.Header>
        )
      case 'divider':
        return (
          <Dropdown.Divider />
        )
      default:
        return (
          <Dropdown.Item value={props.value}
            className={cn({
              'active': props.active,
              'reset': props.type === 'reset'
            })}
            onClick={(ev, {value}) => {
              if (props.type === 'reset') {
                handleFilterReset()
              } else {
                handleFilterChange(value)
              }
            }}
          >
            <Icon name={props.icon.name} />
            <div>{props.text}</div>
          </Dropdown.Item>
        )
    }
  }

  React.useEffect(() => {
    (async ()=> {
      setFilters(uiFilters.filter(i => i.component === info.namespace).map(n => n.filters)[0])
    })()
  }, [uiFilters]) // eslint-disable-line

  return (
    <Dropdown
      className='tableFilterDropdown'
      pointing='top right'
      trigger={
        <Button icon
          size='medium'
          labelPosition='left'
          color={uiFilters.filter(i => i.component === info.namespace).map(n => n.filters.filter(e => e.active).length) > 0 ? 'blue' : ''}
        >
          <Icon name='filter' />
          <span>
            Filters: 
            <span className='filterGroup'>
              <span>{uiFilters.filter(i => i.component === info.namespace).map(n => n.filters.filter(e => e.active).length)}</span>
            </span>
          </span>
        </Button>
      }
    >
      <Dropdown.Menu>
        <>
          {filters.map((filter) => (
            <FilterItem {...filter} />
          ))}
        </>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default React.memo(TableFilter)
