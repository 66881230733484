import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// Redux Toolkit
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'

const AuthRoute = ({ component: Component, ...rest }) => {
  const { authenticated, userInfo } = useSelector(getUserState)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticated) {
          return <Redirect to={userInfo.role === 'sf_creator' ? '/admin' : '/admin/orders'} />
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}

export default React.memo(AuthRoute)
