import React from 'react'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// STYLES
import { AvatarContainer } from './AvatarStyles'

const SFAvatar = (props) => {
  const { userInfo, className, reProps, width, height } = props
  const avatarSrc = userInfo.acf.profile_picture ? userInfo.acf.profile_picture : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_photo.jpg`
  const retinaSrc = userInfo.acf.profile_picture ? userInfo.acf.profile_picture : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_photo.jpg`
  const basic = userInfo.acf && userInfo.acf.custom_stylesheet ? false : true
  return (
    <AvatarContainer className={cn('sfAvatar', {
        [`${className}`]: className
      })}
      useProRing={basic ? false : userInfo.acf.subscription_type === 'premium' ? userInfo.acf.custom_stylesheet.useProRing : false}
      bgColor={basic ? '#ffffff' : userInfo.acf.custom_stylesheet.bgColor}
    >
      <LazyLoadImage
        {...reProps}
        srcSet={retinaSrc}
        src={avatarSrc}
      />
    </AvatarContainer>
  )
}

export default SFAvatar
