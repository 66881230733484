import React from 'react'
import { Dropdown, Header, Form as SUIForm } from 'semantic-ui-react'
import ColorPicker from './Components/ColorPicker'
import { debounce } from 'lodash-es'
import { consoleLogger, usePrevious } from '@Utils'

// REDUX
import { useSelector } from 'react-redux' // eslint-disable-line
import { getUserState } from '@Slices/userSlice' // eslint-disable-line

// STYLES
import { stylevars } from '@Common/StyleVars'
import { themeColors } from './ColorTemplates'

const CustomizationForm = (props) => {
  const { dataHandler } = props
  const { userInfo } = useSelector(getUserState)
  const [ customStyles, setCustomStyles ] = React.useState()
  const [ tempStyles, setTempStyles ] = React.useState()
  const [ ready, setReady ] = React.useState(false)
  const [ useProRing, setUseProRing ] = React.useState()
  const handleColorChange = (type, color) => {
      consoleLogger('============ CALLBACK ================', type, color)
      setTempStyles(state => ({
        ...state,
        [type]: color
      }))
    }

  const [ templateValue, setTemplateValue ] = React.useState()
  const templateOptions = themeColors.map((option, index) => ({
    key: index,
    value: option.key,
    text: option.name,
    description: option.type,
  }))
  const handleTemplateChange = (ev, {value}) => {
    const tempTheme = themeColors.filter(i => i.key === value).map(n => n.data)[0]
    consoleLogger('================= handleTemplateChange ==================', value, tempTheme)
    setTemplateValue(themeColors.filter(i => i.key === value).map(n => n.key)[0])
    setCustomStyles(tempTheme)
  }
  const handleProRingChange = () => {
    setUseProRing(prev => !prev)
  }

  React.useEffect(() => {
    consoleLogger('================== PRO RING', useProRing)
    setCustomStyles(state => ({
      ...state,
      useProRing: !!useProRing
    }))
    setTempStyles(state => ({
      ...state,
      useProRing: !!useProRing
    }))
  }, [useProRing]) // eslint-disable-line

  React.useEffect(() => {
    dataHandler(customStyles)
  }, [customStyles]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('=============== CUSTOM STYLES =============', customStyles)
    if (tempStyles) {
      dataHandler(tempStyles)
      Object.entries(tempStyles).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--sfProfile_${key}`, `rgba(${value})`)
        if (key === 'useProRing') {
          if (value === true) {
            document.documentElement.style.setProperty(`--sfProfile_useProRing`, `linear-gradient(55deg, ${stylevars.palette.secondary.main}, ${stylevars.palette.primary.main})`)
          } else {
            document.documentElement.style.setProperty(`--sfProfile_useProRing`, `rgba(${tempStyles.bgColor})`)
          }
        }
      })
    }
  }, [tempStyles]) // eslint-disable-line

  // SET DEFAULTS
  React.useEffect(() => {
    if (userInfo && userInfo.acf.custom_stylesheet) {
      const userStyles = userInfo.acf.custom_stylesheet
      setUseProRing(userStyles.useProRing && userInfo.acf.subscription_type === 'premium' ? userStyles.useProRing : false)
      setCustomStyles({
        bgColor: userStyles.bgColor ? userStyles.bgColor : '255,255,255,1',
        titleTextColor: userStyles.titleTextColor ? userStyles.titleTextColor : '255,255,255,1',
        subtitleTextColor: userStyles.subtitleTextColor ? userStyles.subtitleTextColor : '255,255,255,1',
        socialIconColor: userStyles.socialIconColor ? userStyles.socialIconColor : '255,255,255,1',
        dividerColor: userStyles.dividerColor ? userStyles.dividerColor : '0,0,0,0.15',
        linkTextColor: userStyles.linkTextColor ? userStyles.linkTextColor : '0,0,0,0.5',
        linkTextColorHover: userStyles.linkTextColorHover ? userStyles.linkTextColorHover : '0,0,0,1',
        linkTextColorActive: userStyles.linkTextColorActive ? userStyles.linkTextColorActive : '255,186,8,1',
        linkBGColor: userStyles.linkBGColor ? userStyles.linkBGColor : '255,255,255,1',
        linkBGColorHover: userStyles.linkBGColorHover ? userStyles.linkBGColorHover : '255,255,255,1',
        linkBGColorActive: userStyles.linkBGColorActive ? userStyles.linkBGColorActive : '255,255,255,1',
        linkLineColor: userStyles.linkLineColor ? userStyles.linkLineColor : '240,240,240,0',
        linkLineColorHover: userStyles.linkLineColorHover ? userStyles.linkLineColorHover : '240,240,240,1',
        linkLineColorActive: userStyles.linkLineColorActive ? userStyles.linkLineColorActive : '255,186,8,1',
        galleryTitleColor: userStyles.galleryTitleColor ? userStyles.galleryTitleColor : '255,255,255,1',
        profileTextColor: userStyles.profileTextColor ? userStyles.profileTextColor : '0,0,0,1',
        profileBGColor: userStyles.profileBGColor ? userStyles.profileBGColor : '255,255,255,0.5',
        useProRing: userStyles.useProRing && userInfo.acf.subscription_type === 'premium' ? userStyles.useProRing : false,
      })
      setReady(true)
    }
  }, [userInfo]) // eslint-disable-line
  
  return (
    <>
      {ready &&
        <div>
          <h1 className='title'>Theme Editor</h1>
          <Dropdown
            text='Color Presets'
            floating
            labeled
            button
            closeOnChange
            icon='paint brush'
            className='icon colorPresets'
            options={templateOptions}
            value={templateValue}
            onChange={handleTemplateChange}
            pointing
          />
          <Header as='h4'><span>Avatar</span> <small>PRO</small></Header>
          <div className='formset'>
            <SUIForm.Checkbox name='useProRing' label='Use PRO ring on avatar?' defaultChecked={useProRing} onChange={handleProRingChange} value='yes' disabled={userInfo.acf.subscription_type === 'starter'} />
          </div>
          <Header as='h4'><span>Base</span></Header>
          <div className='formset'>
            <ColorPicker label='Background Color' key={`bgColor-${customStyles.bgColor}`} color={customStyles.bgColor} handler={(color) => handleColorChange('bgColor', color)} />
            <ColorPicker label='Title Color' key={`titleTextColor-${customStyles.titleTextColor}`} color={customStyles.titleTextColor} handler={(color) => handleColorChange('titleTextColor', color)} />
            <ColorPicker label='Social Media Icon' key={`socialIconColor-${customStyles.socialIconColor}`} color={customStyles.socialIconColor} handler={(color) => handleColorChange('socialIconColor', color)} />
            <ColorPicker label='Divider Color' key={`dividerColor-${customStyles.dividerColor}`} color={customStyles.dividerColor} handler={(color) => handleColorChange('dividerColor', color)} />
          </div>
          <Header as='h4'><span>Hyperlinks</span></Header>
          <div className='formset'>
            <ColorPicker label='Text Color' key={`linkTextColor-${customStyles.linkTextColor}`} color={customStyles.linkTextColor} handler={(color) => handleColorChange('linkTextColor', color)} />
            <ColorPicker label='Text Hover Color' key={`linkTextColorHover-${customStyles.linkTextColorHover}`} color={customStyles.linkTextColorHover} handler={(color) => handleColorChange('linkTextColorHover', color)} />
            <ColorPicker label='Text Active Color' key={`linkTextColorActive-${customStyles.linkTextColorActive}`} color={customStyles.linkTextColorActive} handler={(color) => handleColorChange('linkTextColorActive', color)} />
            <ColorPicker label='BG Color' key={`linkBGColor-${customStyles.linkBGColor}`} color={customStyles.linkBGColor} handler={(color) => handleColorChange('linkBGColor', color)} />
            <ColorPicker label='BG Hover Color' key={`linkBGColorHover-${customStyles.linkBGColorHover}`} color={customStyles.linkBGColorHover} handler={(color) => handleColorChange('linkBGColorHover', color)} />
            <ColorPicker label='BG Active Color' key={`linkBGColorActive-${customStyles.linkBGColorActive}`} color={customStyles.linkBGColorActive} handler={(color) => handleColorChange('linkBGColorActive', color)} />
            <ColorPicker label='Underline Color' key={`linkLineColor-${customStyles.linkLineColor}`} color={customStyles.linkLineColor} handler={(color) => handleColorChange('linkLineColor', color)} />
            <ColorPicker label='Underline Hover Color' key={`linkLineColorHover-${customStyles.linkLineColorHover}`} color={customStyles.linkLineColorHover} handler={(color) => handleColorChange('linkLineColorHover', color)} />
            <ColorPicker label='Underline Active Color' key={`linkLineColorActive-${customStyles.linkLineColorActive}`} color={customStyles.linkLineColorActive} handler={(color) => handleColorChange('linkLineColorActive', color)} />
          </div>
          <Header as='h4'><span>Gallery</span></Header>
          <div className='formset'>
            <ColorPicker label='Gallery Title Color' key={`galleryTitleColor-${customStyles.galleryTitleColor}`} color={customStyles.galleryTitleColor} handler={(color) => handleColorChange('galleryTitleColor', color)} />
          </div>
          <Header as='h4'><span>Profile Content</span></Header>
          <div className='formset'>
            <ColorPicker label='Text Color' key={`profileTextColor-${customStyles.profileTextColor}`} color={customStyles.profileTextColor} handler={(color) => handleColorChange('profileTextColor', color)} />
            <ColorPicker label='BG Color' key={`profileBGColor-${customStyles.profileBGColor}`} color={customStyles.profileBGColor} handler={(color) => handleColorChange('profileBGColor', color)} />
          </div>
        </div>
      }
    </>
  )
}

export default React.memo(CustomizationForm)
