import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const ErrorListContainer = styled('div')`
  width: 100%;
  // display: flex;
  // justify-content: flex-start;
  // align-items: stretch;
  // flex-wrap: wrap;
  // margin-bottom: 2rem;

  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat( 1, minmax(120px, 1fr) );
  // grid-auto-rows: calc(177.78px + 0.5rem);
  column-gap: 0.5rem;
  row-gap: 0.5rem;

  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-device-width:736px), (max-width:736px) {
    display: block;
  }
  
  .thumbnail {
    // width: calc(25% - 0.5rem);
    width: calc(100%);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: background-color 0.15s ease-out;
    pointer-events: all;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-device-width:736px), (max-width:736px) {
      width: 100%;
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }

    .thumbnailWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      position: relative;
    }

    .image {
      position: relative;
      margin-right: 0.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: auto !important;

      img {
        width: 40px !important;
        height: 40px !important;
        max-height: 177.78px !important;
        object-fit: cover;
        display: block;
        line-height: 0;
        margin: 0;
        padding: 0;
      }
    }

    .details {
      width: calc(100% - 40px - 1rem);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .name {
        width: calc(100%);
        font-size: 1rem;
        line-height: 1.6;
        margin-bottom: 0.5rem;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
      }

      .size {
        font-size: 0.7rem;
        line-height: 1;
        color: rgba(0, 0, 0, 0.5);
        text-align: left;
      }

      .message {
        font-size: 1rem;
        line-height: 1;
        color: rgba(0, 0, 0, 0.5);
        text-align: left;
      }
    }

    .thumbnailRemove {
      position: absolute;
      top: 0rem;
      right: 0rem;
      width: auto !important;
      height: auto !important;
      padding: 0 !important;
      margin: 0 !important;
      font-size: 1.15rem;
      background-color: transparent;
      color: #ff0000;
    }

    .progressBar {
      position: absolute;
      bottom: -0.5rem;
      left: -0.5rem;
      right: -0.5rem;
      .ui.progress {
        margin: 0;
      }
    }

    .ui.dimmer {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`