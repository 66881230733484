import React from 'react'
import axios from 'axios'
import cn from 'classnames'
import seedrandom from 'seedrandom'
import { consoleLogger } from '@Utils'
import filesize from 'filesize'

// REDUX
import { useSelector, useDispatch } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import { getUIState } from '@Slices/uiSlice' // eslint-disable-line

// COMPONENTS
import {
  Button,
  Grid,
  Modal,
  // Segment,
  // Header,
  Icon,
  // Divider,
  // Label,
  Image,
  Dimmer,
  Loader,
} from 'semantic-ui-react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import Slider, { Range } from 'rc-slider' // eslint-disable-line

// STYLES
import StyledContainer from './PhotoUploaderStyles'
import 'rc-slider/assets/index.css'
import { toast } from 'react-toastify'
const sliderWrapperStyle = {
  width: '100%',
  maxWidth: '220px',
  margin: 'auto',
  paddingTop: '1rem',
}

const PhotoUploader = (props) => {
  const dispatch = useDispatch()
  const { userInfo, userKey } = useSelector(getUserState) // eslint-disable-line
  const { siteSettings } = useSelector(getUIState) // eslint-disable-line
  const {
    className,
    type,
    defaultImage,
    width,
    height
  } = props
  const token = localStorage.Authorization
  const dImage = defaultImage ? defaultImage : type === 'profile' ? '/images/default_photo.jpg' : '/images/default_cover.jpg'
  const handleOpenEditor = () => {
    setUploaderOpen(true)
  }
  const [ imageScale, setImageScale ] = React.useState(1)
  const handleSizeChange = (value) => {
    const adjusted = value + 100
    consoleLogger(adjusted)
    setImageScale((adjusted / 100))
  }
  const [ image, setImage ] = React.useState(dImage)
  const [ rejected, setRejected ] = React.useState()
  const [ uploaderOpen, setUploaderOpen ] = React.useState(false)
  const dropzoneRef = React.useRef()
  const editorRef = React.useRef()
  const [ adjustedWidth, setAdjustedWidth ] = React.useState(width)
  const [ submitting, setSubmitting ] = React.useState(false)

  const handleUpload = async () => {
    if (editorRef) {
      let canvas
      if (type === 'profile') {
        canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      } else {
        canvas = editorRef.current.getImage().toDataURL();
      }
      const rng = seedrandom()
      setSubmitting(true)
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/upload`,
        headers: {
          Authorization:`${token}`,
        },
        method: 'POST',
        data: {
          user_key: userKey,
          author: userInfo.id,
          base64: canvas,
          title: Math.abs(rng.int32())
        },
      }).then(async (res) => {
        consoleLogger("UPLOAD BASE64 RESPONSE.... ", res.data)
        const newData = new Object()
        if (type === 'profile') {
          newData.acf_profile_picture = decodeURI(res.data)
        } else {
          newData.acf_background_image = decodeURI(res.data)
        }
        newData.user_key = userKey
        await axios({
          baseURL: process.env.REACT_APP_API_URL,
          url: `/stylefolio/v1/users/${userInfo.id}`,
          headers: {
            Authorization: `${token}`,
          },
          method: 'POST',
          data: newData
        }).then(res => {
          consoleLogger("USER INFO =====================", res.data)
          dispatch(userSlice.actions.updateUserInfo(res.data))
          setSubmitting(false)
          
          toast.success('You have successfully updated your profile picture.', {
            containerId: 'mainToaster',
            progress: undefined,
          });
          // dispatch(uiSlice.actions.setUIToast({
          //   visible: true,
          //   type: 'positive',
          //   icon: 'info circle',
          //   title: 'Profile Picture - Updated',
          //   message: 'You have successfully updated your profile picture.'
          // }))
          setUploaderOpen(false)
        }).catch(err => {
          consoleLogger("ERROR: ", err)
          setSubmitting(false)
        })
        
      }).catch(err => {
        consoleLogger(err)
      })
    }
  }

  React.useEffect(() => {
    const adjustWidthOnResize = () => {
      if (type === 'cover') {
        setAdjustedWidth(dropzoneRef.current.offsetWidth - 32)
      }
    }
    if (!uploaderOpen) {
      setImageScale(1)
      setHasDropped(false)
    }
    if (dropzoneRef.current) {
      adjustWidthOnResize()
      window.addEventListener('resize', adjustWidthOnResize)
    }

    return(() => {
      setImageScale(1)
      window.removeEventListener('resize', adjustWidthOnResize)
    })
  }, [uploaderOpen, dropzoneRef, type])

  const [ isDragging, setIsDragging ] = React.useState(false)
  const [ hasDropped, setHasDropped ] = React.useState(false)
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    noClick: hasDropped,
    noKeyboard: hasDropped,
    maxSize: siteSettings.uploadFilesizeLimit,
    onDrop: (acceptedFiles, fileRejections) => {
      consoleLogger('acceptedFiles', acceptedFiles.length)
      consoleLogger('fileRejections', fileRejections)
      setRejected(fileRejections)
      if (typeof acceptedFiles[0] !== 'undefined') {
        setImage(acceptedFiles[0]);
        setHasDropped(true)
      }
      setIsDragging(false)
    },
    onDragOver: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    }
  });

  React.useEffect(() => {
    consoleLogger('================= useState - Accepted', image)
  }, [image]) // eslint-disable-line

  React.useState(() => {
    consoleLogger('=============== useState - Rejected', rejected)
  }, [rejected]) // eslint-disable-line

  React.useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    setRejected()
    URL.revokeObjectURL(image.preview)
  }, []) // eslint-disable-line

  return (
    <StyledContainer className={className}>
      <div
        className={cn('uploader_image', {
          [`${type}`]: type
        })}
      >
        <div className='uploader_image_overlay'>
          <span>Change {type === 'profile' ? 'Profile' : 'Cover'} Photo</span>
          <Icon name='cloud upload' />
        </div>
        <Image src={dImage} circular={type === 'profile'} fluid={type === 'cover'} size='small' onClick={handleOpenEditor} />
      </div>
      {uploaderOpen &&
        <Modal
          closeOnDimmerClick={!submitting}
          onClose={() => setUploaderOpen(false)}
          onOpen={() => setUploaderOpen(true)}
          open={uploaderOpen}
          dimmer={{
              blurring: true
          }}
        >
          <Modal.Header>Image Editor</Modal.Header>
          <Modal.Content>
            {submitting &&
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            }
            <StyledContainer>
              <Grid textAlign='center'>
                <Grid.Row>
                  <Grid.Column className='uploader_column'>
                    <div className='uploader_note'>
                      {!hasDropped ?
                        <p><span className='text--bold'>Drag-and-Drop</span> your image or <span className='text--bold'>Click</span> on the box below</p> :
                        <>
                          <p>To replace the image, <span className='text--bold'>Drag-and-Drop</span> your image on the box below</p>
                          <p>Try dragging the image around the box to reposition your {type === 'profile' ? `profile` : `cover`} photo</p>
                        </>
                      }
                    </div>

                    <div {...getRootProps()}
                      ref={dropzoneRef}
                      className={cn('uploaderDropzone', {
                        'dragging': isDragging,
                        'hasDropped': hasDropped
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className='uploaderDropzone_indicator'>You can drop anywhere inside this box...</div>
                      {hasDropped ?
                        <>
                          <AvatarEditor
                            width={adjustedWidth}
                            height={height}
                            image={image}
                            scale={imageScale}
                            border={0}
                            borderRadius={type === 'profile' ? 250 : 0}
                            ref={editorRef}
                          />
                        </> :
                        <Image src={dImage} circular={type === 'profile'} fluid={type === 'cover'} size='medium'
                          className={cn('defaultImage', {
                          })}
                        />
                      }
                    </div>

                    {rejected && rejected.length > 0 &&
                      <div className='error tooLarge'>File size too large. Please limit your image file size to {filesize(siteSettings.uploadFilesizeLimit)}.</div>
                    }

                    {hasDropped &&
                      <div style={sliderWrapperStyle}>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={4}>
                              <p>Zoom</p>
                            </Grid.Column>
                            <Grid.Column width={12}>
                              <Slider defaultValue='0' startPoint={0} onChange={handleSizeChange} min={0} max={200} style={{marginTop: '0.2rem'}} />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </StyledContainer>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              content='Cancel'
              onClick={() => setUploaderOpen(false)}
              disabled={submitting}
            />
            <Button
              content="Upload"
              labelPosition='right'
              icon='checkmark'
              onClick={() => handleUpload()}
              positive
              disabled={!hasDropped}
            />
          </Modal.Actions>
        </Modal>
      }
    </StyledContainer>
  )
}

export default PhotoUploader
