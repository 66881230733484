import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { darken } from 'polished';

export const CustomizationContainer = styled('div')`
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 100) - 60px - 4rem);
  overflow-x: hidden;
  overflow-y: auto;
  @media ${stylevars.breakpoints.phone} {
    /* margin: -1rem;
    width: calc(100% + 2rem);
    height: calc(calc(var(--vh, 1vh) * 100) - 120px); */
  }

  .editorBox {
    position: absolute;
    top: calc(1rem - 2px);
    right: 1rem;
    padding: 1rem;
    width: ${stylevars.sideBar.width};
    height: calc(100% - ${stylevars.navBar.height});
    z-index: 20;
    border-radius: 0.25rem;
    overflow: hidden;
    pointer-events: none;

    &.show {
      overflow: visible;
      box-shadow: 0 0 1rem 0.5rem rgba(0,0,0,0.15);
      .content {
        right: 0;
      }
    }

    .toggle {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 101;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0;
      padding: 0;
      line-height: 1;
      pointer-events: all;
      .icon {
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
        margin: 0;
      }

      &.basic {
        background-color: #fff !important;
      }
    }
    > .content {
      position: absolute;
      top: 0;
      right: -${stylevars.sideBar.width};
      padding: 0rem;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 100;
      overflow-y: auto;
      overflow-x: hidden;
      pointer-events: all;

      .title {
        font-size: 1.25rem;
        padding: 1rem;
        margin: 0;
        border-bottom: 1px solid ${stylevars.paper.borderColor};
      }

      .header {
        margin: 0;
        padding: 0.5rem 1rem;
        background-color: rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 1px solid ${stylevars.paper.borderColor};
        border-bottom: 1px solid ${stylevars.paper.borderColor};

        span {
          flex-grow: 1;
        }

        small {
          font-weight: 400;
          font-size: 0.75rem;
          text-transform: uppercase;
          color: rgba(0,0,0,0.5) !important;
        }
      }
    }
    
    .colorPresets {
      width: calc(100% - 1rem);
      padding: 1rem !important;
      margin: 0.5rem;
      background-color: ${stylevars.palette.info.main};
      color: ${stylevars.palette.info.contrastText};
      &:focus, &:hover {
        color: ${stylevars.palette.info.contrastText};
        background-color: ${darken(0.1, stylevars.palette.info.main)};
      }
      &.active {
        color: ${stylevars.palette.info.contrastText};
        background-color: ${darken(0.1, stylevars.palette.info.main)};
        &:hover {
          color: ${stylevars.palette.info.contrastText};
          background-color: ${darken(0.1, stylevars.palette.info.main)};
        }
      }
    }
    .field {
      padding: 0.5rem 1rem;
      margin: 0 !important;
      label {
      }
      .ui.selection {
        flex-grow: 1;
        margin: 0 !important;
      }
    }
  }
  
  .profileContainer {
    // THEME 2
    &.theme-2 {
      .profile-image {
        bottom: auto;
        top: calc(50% - 2rem);
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .profile-name {
        opacity: 1;
        left: 50%;
        transform: translateX(-50%);
      }
      .profile-social {
        left: 50%;
        transform: translateX(-50%);
      }
      .toolbar-wrapper {
        display: flex;
        justify-content: center;
        margin-left: 0;
        width: calc(100%);
      }
    }
    // THEME 3
    &.theme-3 {
      display: grid;
      grid-template-columns: [profile] ${stylevars.themes.sideBarWidth} [toolbar] 6fr;
      grid-template-rows: [toolbar-row] 41px [content] 6fr [footer] 0;
      .profile {
        grid-column-start: profile;
        grid-column-end: span toolbar;
        grid-row-start: toolbar-row;
        grid-row-end: span footer;
        width: ${stylevars.themes.sideBarWidth};
        height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height} - 4rem);
        top: 0 !important;
        position: fixed;
        top: 0;
      }
      .toolbar {
        grid-column-start: toolbar;
        grid-column-end: span toolbar;
        grid-row-start: toolbar-row;
        grid-row-end: span content;
      }
      > .content {
        grid-column-start: toolbar;
        grid-column-end: span toolbar;
        grid-row-start: content;
        grid-row-end: span footer;
      }
      .profile-cover {
        width: 100%;
        height: 100%;
      }
      .profile-image {
        bottom: auto;
        top: calc(50% - 10rem);
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .profile-name {
        opacity: 1;
        left: 50%;
        bottom: auto;
        top: calc(50%);
        transform: translate(-50%, -50%);
      }
      .profile-social {
        left: 50%;
        bottom: auto;
        top: calc(50% + 2rem);
        transform: translate(-50%, -50%);
      }
      .toolbar-wrapper {
        margin-left: 0;
        width: calc(100%);
        padding: 0 2rem;
      }
    }

    // THEME RESPONSIVE
    &.theme-1, &.theme-2, &.theme-3 {
      @media only screen and (max-device-width:1024px), (max-width: 1024px) {
        display: block !important;
      }
      .profile {
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          top: auto !important;
          width: 100% !important;
          height: 220px !important;
          position: relative !important;
        }
      }
      .profile-cover {
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          height: 220px !important;
        }
      }
      .profile-image {
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          top: calc(50% - 2rem) !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
        }
      }
      .profile-name {
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          top: auto !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
          bottom: 3rem !important;
        }
      }
      .profile-social {
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          top: auto !important;
          bottom: 0rem !important;
        }
      }
      .toolbar-wrapper {
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          display: flex;
          justify-content: center;
          margin-left: 0 !important;
          width: calc(100%) !important;
          padding: 0 2rem !important;
        }
      }
    }
  }
`