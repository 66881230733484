import React from 'react'
import cn from 'classnames'
import ReactQuill from 'react-quill'
import { debounce } from 'lodash'

// REDUX
import { useSelector, useDispatch } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line

// COMPONENTS
import { Button, Dimmer, Divider, Grid, Header, Loader, Modal, Segment } from 'semantic-ui-react'
import ThemeSelection from './Components/ThemeSelection'
import CustomizationModal from './CustomizationModal'

const ProfileDisplay = () => {
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { userInfo, userKey } = useSelector(getUserState)
  // const { pageScrolled } = useSelector(getUIState)
  const [isReady, setIsReady] = React.useState(false)
  const [ customColorOpen, setCustomColorOpen ] = React.useState(false)
  const handleCustomColorOpen = (bool) => {
    setCustomColorOpen(bool)
  }

  React.useEffect(() => {
    setIsReady(true)
  }, []) // eslint-disable-line

  return (
    <>
      {isReady ?
        <div className='profile_theme_selection'>
          <Header as='h3'>
            Theme Selection
          </Header>
          <Divider />
          <ThemeSelection modalHandler={handleCustomColorOpen} />
          <CustomizationModal open={customColorOpen} handler={handleCustomColorOpen} />
        </div> : 
        <>Loading...</>
      }
    </>
  )
}

export default React.memo(ProfileDisplay)
