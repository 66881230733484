import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { LazyLoadImage } from "react-lazy-load-image-component";
import he from "he";
import { sortBy } from "lodash";
import {
  consoleLogger,
  priceFormat,
  cleanPrice,
  getPrintType,
  getPrintMaterial,
  getPrintSize,
  getAspectRatio,
  usePrevious,
  roundTo,
} from "@Utils";
const voucher_codes = require("voucher-code-generator");

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import { getGalleryState } from "@Slices/gallerySlice";
import userSlice, { getUserState } from "@Slices/userSlice";

// COMPONENTS
import CurrencySelection from "@Common/CurrencySelection";
import ShippingAddress from "./ShippingAddress";
import {
  Button,
  Divider,
  Dropdown,
  Header,
  Icon,
  Item,
  Placeholder,
  Segment,
} from "semantic-ui-react";

// STYLES
import { Container } from "./RequestFormStyles";
import "react-lazy-load-image-component/src/effects/blur.css";

const RequestForm = (props) => {
  const { data, clean, handleClean, handleCartItem } = props;

  const dispatch = useDispatch();

  const { userInfo, userGallery, printRequest } = useSelector(getUserState);
  const { gallerySizeTypes, gallerySizes, galleryMaterials } =
    useSelector(getGalleryState);
  const { siteSettings, currency } = useSelector(getUIState); // eslint-disable-line

  const [ready, setReady] = React.useState(false);

  const [isLimited, setIsLimited] = React.useState(false);
  const [limitMax, setLimitMax] = React.useState();
  const [copyCount, setCopyCount] = React.useState();
  const [quantityMax, setQuantityMax] = React.useState();

  const [isShippingLocal, setIsShippingLocal] = React.useState(false);
  const shippingFeeLocal = Number(siteSettings.shippingFeeDomestic);
  const shippingFeeIntl = Number(siteSettings.shippingFeeInternational);
  const [handlingFee, setHandlingFee] = React.useState(
    Number(siteSettings.handlingFee) * Number(currency.value)
  );
  const [mailingAddress, setMailingAddress] = React.useState();
  const handleMailingAddress = (address) => {
    setMailingAddress(address);
  };
  React.useEffect(() => {
    if (userInfo) {
      if (printRequest.address) {
        setMailingAddress(printRequest.address);
      } else {
        setMailingAddress(
          userInfo.acf.same_billing_mailing_address
            ? userInfo.acf.billing_address
            : userInfo.acf.mailing_address
        );
      }
    }
  }, [userInfo]); // eslint-disable-line

  React.useEffect(() => {
    if (mailingAddress) {
      consoleLogger(
        "========================= mailingAddress =======================",
        mailingAddress
      );
    }
  }, [mailingAddress]);

  const [printTypeOptions, setPrintTypeOptions] = React.useState();
  const [sizeOptions, setSizeOptions] = React.useState();
  const [frameOptions, setFrameOptions] = React.useState();
  const [borderOptions, setBorderOptions] = React.useState();
  const [paperOptions, setPaperOptions] = React.useState();

  const [selectedPrintType, setSelectedPrintType] = React.useState(
    Object.keys(printRequest).length !== 0
      ? printRequest.printOut.selectedPrintType
      : ""
  );
  const [selectedSize, setSelectedSize] = React.useState(
    Object.keys(printRequest).length !== 0
      ? printRequest.printOut.selectedSize
      : ""
  );
  const [selectedFrame, setSelectedFrame] = React.useState(
    Object.keys(printRequest).length !== 0
      ? printRequest.printOut.selectedFrame
      : ""
  );
  const [selectedBorder, setSelectedBorder] = React.useState(
    Object.keys(printRequest).length !== 0
      ? printRequest.printOut.selectedBorder
      : ""
  );
  const [selectedPaper, setSelectedPaper] = React.useState(
    Object.keys(printRequest).length !== 0
      ? printRequest.printOut.selectedPaper
      : ""
  );

  const [hasSize, setHasSize] = React.useState();
  const [hasFrame, setHasFrame] = React.useState();
  const [hasBorder, setHasBorder] = React.useState();
  const [hasPaper, setHasPaper] = React.useState();

  const [quantity, setQuantity] = React.useState(
    Object.keys(printRequest).length !== 0 ? printRequest.product.quantity : 1
  );
  const [baseFee, setBaseFee] = React.useState(5);
  const [multiplier, setMultiplier] = React.useState(1);
  const [printCost, setPrintCost] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [subCost, setSubCost] = React.useState(0);
  const [priceModifiers, setPriceModifiers] = React.useState({
    frame: 0,
    border: 0,
    paper: 0,
    adjust: 5,
  });
  const [modifierCost, setModifierCost] = React.useState(0);
  const [shippingFee, setShippingFee] = React.useState(0);
  const [totalCost, setTotalCost] = React.useState(0);

  const [printID, setPrintID] = React.useState(
    voucher_codes.generate({
      prefix: "PRINT-",
      pattern: "################",
      postfix: `-${new Date().getFullYear()}`,
      charset: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    })
  );

  const handleSelectPrintType = (ev, { value }) => {
    console.log("selected print type", value);
    const tempObj = getPrintType(value, gallerySizeTypes);
    setSelectedPrintType(tempObj);

    // RESET
    setHasBorder();
    setSelectedBorder();
    setBorderOptions();

    setHasFrame();
    setSelectedFrame();
    setFrameOptions();

    setHasPaper();
    setSelectedPaper();
    setPaperOptions();
  };
  const handleSelectSize = (ev, { value }) => {
    const tempObj = getPrintSize(value, gallerySizes);
    setSelectedSize(tempObj);
  };
  const handleSelectFrame = (ev, { value }) => {
    const tempObj = getPrintMaterial(value, galleryMaterials);
    setSelectedFrame(tempObj);
  };
  const handleSelectBorder = (ev, { value }) => {
    const tempObj = getPrintMaterial(value, galleryMaterials);
    setSelectedBorder(tempObj);
  };
  const handleSelectPaper = (ev, { value }) => {
    const tempObj = getPrintMaterial(value, galleryMaterials);
    setSelectedPaper(tempObj);
  };

  React.useEffect(() => {
    if (data) {
      setIsLimited(data.acf.limited_edition);
      setLimitMax(
        data.acf.limited_edition ? data.acf.number_lots : 999999999999999999
      );
      setCopyCount(
        data.acf.limited_edition
          ? Number(data.acf.sold_copies) + quantity
          : quantity
      );
      setQuantityMax(
        data.acf.limited_edition
          ? Number(data.acf.number_lots) - Number(data.acf.sold_copies)
          : 1000
      );
    }
  }, [data, quantity]);

  React.useEffect(() => {
    const subscriptionFee =
      userInfo.acf.subscription_type === "premium" ? 0.05 : 0.15;
    // setPrintTypeOptions(data.acf.print_types.map(n => ({key: n.id, text: n.name, value: n.id})))
    // LIMIT TO PRINT-ONLY
    setPrintTypeOptions(
      gallerySizeTypes
        .filter((n) => n.slug === "print-only")
        .map((o) => ({ key: o.id, text: o.name, value: o.id }))
    );
    setSizeOptions(
      sortBy(gallerySizes, (o) => [o.name, o.acf.length])
        .filter(
          (i) => i.acf.aspect_ratio === data.acf.aspect_ratio && i.acf.active
        )
        .map((n) => ({ key: n.id, text: n.name, value: n.id }))
    );

    if (
      mailingAddress &&
      gallerySizeTypes &&
      gallerySizes &&
      galleryMaterials
    ) {
      setReady(true);
    }
  }, [data, gallerySizeTypes, gallerySizes, galleryMaterials, mailingAddress]); // eslint-disable-line

  React.useEffect(() => {
    if (selectedPrintType) {
      consoleLogger("=============== selected Print Type", selectedPrintType);
      setHasSize(true);

      // SET PRINT BASE COST
      setPrintCost(selectedPrintType.acf.fee);

      // CHECK IF PRINT TYPE HAS FRAMES
      if (selectedPrintType.acf.has_frames) {
        setHasFrame({
          label: selectedPrintType.acf.frame_options_label,
          data: selectedPrintType.acf.frame_options,
        });
        setFrameOptions(
          selectedPrintType.acf.frame_options.map((n) => ({
            key: n.term_id,
            text: n.name,
            value: n.term_id,
          }))
        );
      }

      // CHECK IF PRINT TYPE HAS BORDERS
      if (selectedPrintType.acf.has_border) {
        setHasBorder({
          label: selectedPrintType.acf.border_options_label,
          data: selectedPrintType.acf.border_options,
        });
        setBorderOptions(
          selectedPrintType.acf.border_options.map((n) => ({
            key: n.term_id,
            text: n.name,
            value: n.term_id,
          }))
        );
      }

      // CHECK IF PRINT TYPE HAS PAPER
      if (selectedPrintType.acf.has_paper) {
        setHasPaper({
          label: selectedPrintType.acf.paper_options_label,
          data: selectedPrintType.acf.paper_options,
        });
        setPaperOptions(
          selectedPrintType.acf.paper_options.map((n) => ({
            key: n.term_id,
            text: n.name,
            value: n.term_id,
          }))
        );
      }
    }
  }, [selectedPrintType]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("================== MULTIPLIER", multiplier);
  }, [multiplier]); // eslint-disable-line

  // PRICE UPDATE
  React.useEffect(() => {
    if (priceModifiers) {
      const total =
        Number(priceModifiers.border) +
        Number(priceModifiers.frame) +
        Number(priceModifiers.paper + Number(priceModifiers.adjust));
      consoleLogger(total);
      setModifierCost(Number(total));
    }
  }, [priceModifiers]); // eslint-disable-line

  React.useEffect(() => {
    setPrice(
      cleanPrice(
        (Number(baseFee) + Number(printCost) + Number(modifierCost)) *
          Number(multiplier) *
          currency.value
      )
    );
    setSubCost(
      cleanPrice(
        (Number(baseFee) + Number(printCost) + Number(modifierCost)) *
          Number(multiplier) *
          currency.value
      ) * quantity
    );
  }, [baseFee, printCost, modifierCost, multiplier, currency, quantity]); // eslint-disable-line

  React.useEffect(() => {
    const tempShippingFee =
      mailingAddress &&
      mailingAddress.country_code.toLowerCase() ===
        siteSettings.countryOriginCode.toLowerCase()
        ? shippingFeeLocal
        : shippingFeeIntl;

    let tempQuantity = 1;
    if (selectedPrintType) {
      tempQuantity =
        selectedPrintType.slug === "print-only"
          ? Math.ceil(Number(quantity) / 10)
          : quantity;
    }
    setIsShippingLocal(
      mailingAddress &&
        mailingAddress.country_code.toLowerCase() ===
          siteSettings.countryOriginCode.toLowerCase()
    );
    setShippingFee(cleanPrice(tempShippingFee * tempQuantity * currency.value));
    const tempHandlingFee = Number(siteSettings.handlingFee);
    setHandlingFee(cleanPrice(tempHandlingFee * currency.value));
    // eslint-disable-next-line
  }, [
    quantity,
    userInfo,
    siteSettings,
    currency,
    selectedPrintType,
    mailingAddress,
  ]); // eslint-disable-line

  React.useEffect(() => {
    setTotalCost(subCost + shippingFee + handlingFee);
  }, [subCost, shippingFee, handlingFee]); // eslint-disable-line

  // QUANTITY
  const handleAddQuantity = () => {
    setQuantity((prev) =>
      Number(prev) < quantityMax ? Number(prev) + 1 : quantityMax
    );
  };
  const handleSubQuantity = () => {
    setQuantity((prev) => (Number(prev) > 1 ? Number(prev) - 1 : 1));
  };

  // FORM CLEAN
  React.useEffect(() => {
    if (selectedPrintType) {
      if (selectedPrintType.slug === "print-only") {
        handleClean(!!selectedSize && !!selectedPaper);
      } else if (selectedPrintType.slug === "framed") {
        handleClean(
          !!selectedSize &&
            !!selectedPaper &&
            !!selectedFrame &&
            !!selectedBorder
        );
      } else if (selectedPrintType.slug.indexOf("canvas") > -1) {
        handleClean(!!selectedSize && !!selectedFrame);
      } else {
        handleClean(false);
      }
    }
    consoleLogger("XXXXXXXXXXXXXXXXXXXXXX", [
      { size: selectedSize },
      { frame: selectedFrame },
      { border: selectedBorder },
      { paper: selectedPaper },
    ]);

    // UPDATE MODIFIERS
    if (selectedSize) {
      // setMultiplier(((Number(selectedSize.acf.length) + Number(selectedSize.acf.width))/2) / 10)
      const factor =
        (Number(selectedSize.acf.length) + Number(selectedSize.acf.width)) /
        2 /
        10;
      setMultiplier(factor);
    }
    setPriceModifiers((prev) => ({
      ...prev,
      paper: selectedPaper ? Number(selectedPaper.acf.price) : 0,
    }));
    setPriceModifiers((prev) => ({
      ...prev,
      frame: selectedFrame ? Number(selectedFrame.acf.price) : 0,
    }));
    setPriceModifiers((prev) => ({
      ...prev,
      border: selectedBorder ? Number(selectedBorder.acf.price) : 0,
    }));
    // eslint-disable-next-line
  }, [
    selectedPrintType,
    selectedSize,
    selectedFrame,
    selectedBorder,
    selectedPaper,
  ]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("========================= CLEAN", clean);
  }, [clean]); // eslint-disable-line

  // HANDLE CART ITEM
  React.useEffect(() => {
    consoleLogger("--------------------- PRINT REQUEST ID", printID);

    const tempItem = {
      vendor: data.user,
      customer: userInfo,
      address: mailingAddress,
      orderID: printID[0],
      product: {
        id: data.id,
        title: he.decode(data.post_title),
        description: he.decode(data.post_excerpt),
        price: price,
        quantity: quantity,
        data: data,
      },
      printOut: {
        selectedPrintType: selectedPrintType,
        selectedSize: selectedSize,
        selectedPaper: selectedPaper ? selectedPaper : null,
        // selectedFrame: selectedFrame ? selectedFrame : null,
        // selectedBorder: selectedBorder ? selectedBorder : null,
      },
      breakdown: {
        currency: currency,
        subAmount: priceFormat(subCost),
        shippingFee: priceFormat(shippingFee),
        handlingFee: priceFormat(handlingFee),
        totalAmount: priceFormat(totalCost),
        discount: 0,
      },
      media: data.media,
    };
    handleCartItem(tempItem);
    dispatch(userSlice.actions.setPrintRequest(tempItem));
    // eslint-disable-next-line
  }, [
    data,
    quantity,
    userInfo,
    selectedPrintType,
    selectedSize,
    selectedFrame,
    selectedSize,
    selectedFrame,
    selectedBorder,
    selectedPaper,
    subCost,
    totalCost,
    shippingFee,
    handlingFee,
    price,
    currency,
    mailingAddress,
  ]); // eslint-disable-line

  return (
    <Container>
      {ready ? (
        <div className="wrapper">
          <div className="image">
            <LazyLoadImage
              alt={data.post_title}
              srcSet={data.media.full}
              src={data.media.medium}
              effect="blur"
              delayMethod="debounce"
              delayTime={1000}
              visibleByDefault={true}
              wrapperClassName="imageWrapper"
            />
            {isLimited && (
              <div className="limited">
                <span className="text--bold">Limited Edition</span> (Copy{" "}
                {copyCount} of {limitMax})
              </div>
            )}
          </div>
          <div className="details">
            <div className="detailsTitle">
              <Header as="h2">{data.post_title}</Header>
              <CurrencySelection />
            </div>
            <Segment vertical>
              <div className="segment_wrapper">
                <Header className="optionTitle" as="h4">
                  Quantity
                </Header>
                <div className="optionGroup">
                  <div className="quantity">
                    <div className="sub" onClick={handleSubQuantity}>
                      <Icon name="minus" />
                    </div>
                    <div className="number">{quantity}</div>
                    <div className="add" onClick={handleAddQuantity}>
                      <Icon name="add" />
                    </div>
                  </div>
                  {quantity >= quantityMax && (
                    <div className="note">
                      Maximum of {quantityMax} copies based on the limited
                      copies available
                    </div>
                  )}
                </div>
              </div>
            </Segment>
            <Segment vertical>
              <div className="segment_wrapper">
                <Header className="optionTitle" as="h4">
                  Print Type <span className="small"></span>
                </Header>
                <div className="optionGroup">
                  <Dropdown
                    placeholder="Select Print Type"
                    fluid
                    selection
                    options={printTypeOptions}
                    onChange={handleSelectPrintType}
                    value={
                      printRequest && printRequest.printOut.selectedPrintType
                        ? printRequest.printOut.selectedPrintType.id
                        : ""
                    }
                  />
                  {selectedPrintType &&
                    selectedPrintType.slug !== "print-only" && (
                      <div className="note">
                        <span className="text--bold">
                          {selectedPrintType.name}
                        </span>{" "}
                        is only available for creators based on{" "}
                        {siteSettings.countryOriginCode}
                      </div>
                    )}
                </div>
              </div>
            </Segment>
            {hasSize && (
              <Segment vertical>
                <div className="segment_wrapper">
                  <Header className="optionTitle" as="h4">
                    Size <span className="small">inches</span>
                  </Header>
                  <div className="optionGroup">
                    <Dropdown
                      placeholder="Select Size"
                      fluid
                      selection
                      options={sizeOptions}
                      onChange={handleSelectSize}
                      value={
                        printRequest && printRequest.printOut.selectedSize
                          ? printRequest.printOut.selectedSize.id
                          : ""
                      }
                    />
                  </div>
                </div>
              </Segment>
            )}
            {hasPaper && (
              <Segment vertical>
                <div className="segment_wrapper">
                  <Header className="optionTitle" as="h4">
                    Print Material <span className="small"></span>
                  </Header>
                  <div className="optionGroup">
                    <Dropdown
                      placeholder={hasPaper.label}
                      fluid
                      selection
                      options={paperOptions}
                      onChange={handleSelectPaper}
                      value={
                        printRequest && printRequest.printOut.selectedPaper
                          ? printRequest.printOut.selectedPaper.id
                          : ""
                      }
                    />
                  </div>
                </div>
              </Segment>
            )}
            {selectedPrintType && (
              <Segment vertical>
                <div className="costs">
                  <div className="costs-item">
                    <span className="label">Print Cost</span>
                    <span className="value">
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={subCost}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currency.symbol}
                      />
                    </span>
                  </div>
                  <div className="costs-item">
                    <span className="label">Shipping Fee</span>
                    <span className="value">
                      <span className="shippingValueGroup">
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={shippingFee}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={currency.symbol}
                          className="shippingFee"
                        />
                        <span className="isLocal">
                          {isShippingLocal ? "Local" : "International"} Shipping
                        </span>
                      </span>
                      <ShippingAddress
                        address={mailingAddress}
                        handleAddress={handleMailingAddress}
                      />
                    </span>
                  </div>
                  <div className="costs-item">
                    <span className="label">Handling Fee</span>
                    <span className="value">
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={handlingFee}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currency.symbol}
                      />
                    </span>
                  </div>
                  <div className="costs-item total">
                    <span className="label">
                      <Header as="h3">Total Cost</Header>
                    </span>
                    <span className="value">
                      <Header as="h3">
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={totalCost}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={currency.symbol}
                        />
                      </Header>
                    </span>
                  </div>
                </div>
              </Segment>
            )}
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <div className="title">
            <Header as="h4">Loading...</Header>
          </div>
        </div>
      )}
    </Container>
  );
};

export default React.memo(RequestForm);
