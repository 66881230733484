import cn from 'classnames';
import React from 'react'
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie-player'
import { useHistory } from 'react-router';
import { Button, Grid } from 'semantic-ui-react';
import { WelcomeAboard } from './Styles';
const welcomeAnimationJSON = require('./Animations/welcomeAnimation.json');

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line

const SignupWelcomePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { authenticated } = useSelector(getUserState)

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('welcome'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  return (
    <WelcomeAboard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Welcome Aboard! - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/signup`} />
      </Helmet>
      <div className='container'>
        <h1 className='title'>Welcome aboard!</h1>
        <div className='box'>
          <h4>We are happy that you made the decision in joing us here at {process.env.REACT_APP_PROJECT_NAME}. The concept is simple: You create, we print and ship, you profit.</h4>
          <div className='actions'>
            <Button
              icon='arrow right'
              labelPosition='right'
              content='Login and Create your first gallery'
              color='green'
              onClick={() => {
                history.push(authenticated ? '/admin/gallery' : '/login/redirect/%2Fadmin%2Fgallery')
              }}
            />
          </div>
        </div>
        <Lottie
          loop
          animationData={welcomeAnimationJSON}
          play
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    </WelcomeAboard>
  )
}

export default React.memo(SignupWelcomePage)
