import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom' // eslint-disable-line
import NumberFormat from 'react-number-format'
import { findIndex, nth } from 'lodash'
import { Helmet } from 'react-helmet'
import { consoleLogger, priceFormat, maskEmail, containsObject } from '@Utils'
import { currencySymbols } from '@Utils/forexData'
import cn from 'classnames'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice from '@Slices/uiSlice' // eslint-disable-line
import cartSlice, { getCartState } from '@Slices/cartSlice' // eslint-disable-line
import axios from 'axios'
// import { getGalleryState } from '@Slices/gallerySlice' // eslint-disable-line

// COMPONENTS
import {
  Button,
  Header,
  Icon,
  Label,
  Segment,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Item,
  Image,
} from 'semantic-ui-react'
import LoginForm from '../../Pages/Login/LoginForm'

// STYLES
import StyledStoreCartContainer from './StoreCartStyles'

const lookup = require('country-code-lookup')

const OrderStatusPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderid } = useParams() // eslint-disable-line
  const [ isReady, setIsReady ] = React.useState(false)
  const [ orderInfo, setOrderInfo ] = React.useState()
  const [ orderItems, setOrderItems ] = React.useState([])
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  const { checkout } = useSelector(getCartState)

  const statusArray = [
    'PENDING',
    'RECIEVED',
    'PROCESSING',
    'SHIPPED',
    'OUT FOR DELIVERY',
    'RECEIVED',
    'COMPLETED',
    'CANCELLED',
    'REFUNDED',
    'FRAUD'
  ]

  React.useEffect(() => {
    (async() => {
      if (authenticated) {
        let errors = 0
        let tempItems
        let tempOrderInfo
        if (orderid) {
          consoleLogger('=================== START ORDER INFO =======================')

          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/order/${orderid}`,
            method: 'GET',
            params: {
              author: userInfo.id,
              user_key: userKey,
            }
          }).then(res => {
            consoleLogger('ORDER RESPONSE ===========', res.data)
            if (checkout.orderID === orderid) {
              // clear cart storages
              dispatch(cartSlice.actions.setCart([]))
              dispatch(cartSlice.actions.setCoupons([]))
              dispatch(cartSlice.actions.setCheckout({}))
              localStorage.removeItem('cartItems')
            }
            tempOrderInfo = {
              order_id: orderid,
              transaction_id: res.data.acf.transaction_id,
              customer_info: typeof res.data.acf.customer_info === 'string' ? JSON.parse(res.data.acf.customer_info) : res.data.acf.customer_info,
              shipping_info: typeof res.data.acf.shipping_info === 'string' ? JSON.parse(res.data.acf.shipping_info) : res.data.acf.shipping_info,
              payment_status: res.data.acf.payment_status,
              order_status: Number(res.data.acf.order_status),
              amount: typeof res.data.acf.amount === 'string' ? JSON.parse(res.data.acf.amount) : res.data.acf.amount,
              discount: typeof res.data.acf.discount === 'string' ? JSON.parse(res.data.acf.discount) : res.data.acf.discount,
              post_name: res.data.post_name,
            }
            tempItems = typeof res.data.acf.items === 'string' ? JSON.parse(res.data.acf.items) : res.data.acf.items
          }).catch(err => {
            errors++
            consoleLogger(err)
            history.push('/')
          })
          setOrderInfo({...tempOrderInfo, items: tempItems})

          if (errors > 0) {
            history.push('/')
          } else {
            setIsReady(true)
          }
          
        } else {
          history.push('/')
        }

        consoleLogger('============ END ==================')
      } else {
        const redirect = encodeURIComponent(`/orders/${orderid}`)
        history.push(`/login/redirect/${redirect}`)
      }
    })()
  }, [orderid, authenticated]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      consoleLogger('========== orderInfo ============', orderInfo)
      // fetch actual item details
      if (orderInfo && orderInfo.items) {
        const tempItems = orderInfo.items
        let tempResult = []
        consoleLogger('=================== START ITEM ITERATION =======================')
        const promises2 = tempItems.map(async (order) => {
          const [orderVendorSlug, orderItemID, orderItemBasePrice, orderItemQuantity] = order.sku.split("-")
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/gallery/${orderItemID}`,
            method: 'GET',
          }).then(res => {
            consoleLogger('ACTUAL ITEM DETAILS RESPONSE ===========', res.data, order)
            const tempObj = {
              id: res.data.ID,
              ID: res.data.ID,
              name: order.name,
              description: order.description,
              unit_amount: order.unit_amount,
              sku: order.sku,
              tax: order.tax,
              quantity: order.quantity,
              post_author: res.data.post_author,
              author_slug: orderVendorSlug,
              post_name: res.data.post_name,
              media: res.data.media,
            }
            if (!containsObject(tempObj, tempResult)) {
              tempResult.push(tempObj)
            }
          }).catch(err => {
            errors++
            consoleLogger(err)
          })
        })

        await Promise.all(promises2)
        setOrderItems(tempResult)
        consoleLogger('=================== END ITEM ITERATION =======================')
      }
    })()
  }, [orderInfo])

  React.useEffect(() => {
    consoleLogger('========== orderItems ============', orderItems)
  }, [orderItems])

  React.useEffect(() => {
    if (orderid) {
      dispatch(uiSlice.actions.setPageTemplate('checkout'))
      dispatch(uiSlice.actions.setCurrentPage('orders'))
      dispatch(uiSlice.actions.setCurrentPageURL(`orders/${orderid}`))
      dispatch(uiSlice.actions.setCurrentPageTitle(orderid))
    }
  }, []) // eslint-disable-line

  // TODO: MOVE THESE FUNCTIONS TO UTILS
  const getTheVendorSlug = (id) => {
    let slug = 'asdf'
    axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/whois/${id}`,
      method: 'get'
    }).then(res => {
      slug = res.data
    }).catch(err => {
      consoleLogger(err)
    })
    return slug
  }
  // END OF TODO

  return (
    <>
      {!isReady ?
        <Dimmer active inverted>
          <Loader inverted>Loading Order Information...</Loader>
        </Dimmer> : 
        <StyledStoreCartContainer>
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Order ID: {orderid} - {process.env.REACT_APP_PROJECT_NAME}</title>
              <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/cart`} />
            </Helmet>
            <Header as='h2' className='orderStatus_header'>
              <span>Order ID: {orderid}</span>
              <div className='orderStatus_status'>
                <span className='text--bold'>ORDER STATUS:</span>
                <Label size='large' className={cn('orderStatus_label', {
                  [statusArray[orderInfo.order_status]]: statusArray[orderInfo.order_status]
                })}>{statusArray[orderInfo.order_status]}</Label>
              </div>
            </Header>
            <Divider />
            <Segment className='cart'>
              <Grid divided className='cartContainer'>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={10} className='cartItems'>
                    <div className='cartItemsContainer'>
                      <div className='vendorCart'>
                        <Item.Group divided>
                          {orderItems && orderItems.length > 0 && orderItems.map((item, index) => (
                            <Item key={`${index}-${item.sku}`}>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column mobile={16} computer={2}>
                                    <Link to={`/@${item.author_slug}/gallery/${item.id}`}>
                                      <Image size='large' src={item.media.thumbnail} />
                                    </Link>
                                  </Grid.Column>
                                  <Grid.Column mobile={16} computer={6} verticalAlign='middle'>
                                    <Item.Content>
                                      <Header as='h4'>
                                        <Link to={`/@${item.author_slug}/gallery/${item.id}`}>
                                          {item.name}
                                        </Link>
                                      </Header>
                                      <p className='detail'>
                                        {item.description}
                                      </p>
                                    </Item.Content>
                                  </Grid.Column>
                                  <Grid.Column mobile={4} computer={2} verticalAlign='middle' textAlign='center'>
                                    <div className='price'>
                                        <p>
                                          <NumberFormat
                                          value={item.unit_amount.value}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={currencySymbols[item.unit_amount.currency_code]}
                                          decimalScale={2}
                                          fixedDecimalScale
                                        />
                                      </p>
                                      <small>Price</small>
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column mobile={8} computer={4} verticalAlign='middle' textAlign='center' className='quantity'>
                                    <div className='quantityContainer'>
                                      <div className='quantityText readonly'>{item.quantity}x</div>
                                    </div>
                                    <small>Quantity</small>
                                  </Grid.Column>
                                  <Grid.Column mobile={4} computer={2} verticalAlign='middle' textAlign='center'>
                                    <div className='amount'>
                                      <p>
                                          <NumberFormat
                                          value={item.quantity * item.unit_amount.value}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={currencySymbols[item.unit_amount.currency_code]}
                                          decimalScale={2}
                                          fixedDecimalScale
                                        />
                                      </p>
                                      <small>Amount</small>
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Item>
                          ))}
                        </Item.Group>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={6} className='cartSummary'>
                    <div className='cartSummaryContainer'>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column computer={8} tablet={16}>
                            <Header as='h3'>Customer Information</Header>
                            <Divider />
                            <Grid>
                              <Grid.Row>
                                <Grid.Column computer={6}>
                                  <p>Customer Name:</p>
                                </Grid.Column>
                                <Grid.Column computer={10}>
                                  <p>{orderInfo.customer_info.name.surname}, {orderInfo.customer_info.name.given_name}</p>
                                </Grid.Column>
                              </Grid.Row>
                              
                              <Grid.Row>
                                <Grid.Column computer={6}>
                                  <p>Email Address:</p>
                                </Grid.Column>
                                <Grid.Column computer={10}>
                                  <p>{maskEmail(orderInfo.customer_info.email_address)}</p>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                          <Grid.Column computer={8} tablet={16}>
                            <Header as='h3'>Shipping Information</Header>
                            <Divider />
                            <Grid>
                              <Grid.Row>
                                <Grid.Column computer={6}>
                                  <p>Delivery Address:</p>
                                </Grid.Column>
                                <Grid.Column computer={10}>
                                  <p className='address'>
                                    <span className='text--bold'>{orderInfo.shipping_info.name.full_name}</span>
                                    {orderInfo.shipping_info.address.address_line_1 &&
                                      <span>{orderInfo.shipping_info.address.address_line_1}</span>
                                    }
                                    {orderInfo.shipping_info.address.address_line_2 &&
                                      <span>{orderInfo.shipping_info.address.address_line_2}</span>
                                    }
                                    <span>{orderInfo.shipping_info.address.admin_area_2 ? `${orderInfo.shipping_info.address.admin_area_2},` : ``}{orderInfo.shipping_info.address.admin_area_1 ? ` ${orderInfo.shipping_info.address.admin_area_1}` : ``}{orderInfo.shipping_info.address.admin_area_1 || orderInfo.shipping_info.address.admin_area_2 ? ` ${orderInfo.shipping_info.address.postal_code}` : ``}</span>
                                    <span>{lookup.byIso(orderInfo.shipping_info.address.country_code).country}{!orderInfo.shipping_info.address.admin_area_1 && !orderInfo.shipping_info.address.admin_area_2 ? ` ${orderInfo.shipping_info.address.postal_code}` : ``}</span>
                                  </p>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      
                      <Header as='h3'>Order Amount Breakdown</Header>
                      <Divider />
                      <Grid>
                        <Grid.Row>
                          <Grid.Column computer={8}>
                            <p>Transaction ID:</p>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <h4>{orderInfo.transaction_id}</h4>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column computer={8}>
                            <p>Payment Status:</p>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <h4>{orderInfo.payment_status}</h4>
                          </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                          <Grid.Column computer={8}>
                            <p>Order Status:</p>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <h4>{statusArray[orderInfo.order_status]}</h4>
                          </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                          <Grid.Column computer={8}>
                            <h4>Amount:</h4>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <h4>
                              <NumberFormat
                                value={orderInfo.amount.breakdown.item_total.value}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbols[orderInfo.amount.breakdown.item_total.currency_code]}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </h4>
                          </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                          <Grid.Column computer={8}>
                            <h4>Shipping Fee:</h4>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <div className='addNumber'>
                              <span>+</span>
                              <NumberFormat
                                value={orderInfo.amount.breakdown.shipping.value}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbols[orderInfo.amount.breakdown.shipping.currency_code]}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                        
                        {orderInfo.discount &&
                          <Grid.Row>
                            <Grid.Column computer={8}>
                              <h4>Discount:</h4>
                            </Grid.Column>
                            <Grid.Column computer={8}>
                              <div className='subtractNumber'>
                                <span>-</span>
                                <NumberFormat
                                  value={orderInfo.discount.value}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbols[orderInfo.discount.currency_code]}
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        }
                        
                        <Grid.Row>
                          <Grid.Column computer={8}>
                            <Header as='h2'>Total</Header>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <Header as='h2'>
                              <NumberFormat
                                value={orderInfo.amount.value}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbols[orderInfo.amount.currency_code]}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        {/* <Divider />
                        <Grid.Row>
                          <Grid.Column computer={16}>
                            <Button as='a' href='/checkout' fluid positive size='large' icon='arrow right' content='Proceed to Checkout' labelPosition='right' />
                          </Grid.Column>
                        </Grid.Row> */}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        </StyledStoreCartContainer>
      }
    </>
  )
}

export default React.memo(OrderStatusPage)
