import React from 'react'
import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { debounce } from 'lodash'
import { consoleLogger } from '@Utils'

// Redux Toolkit
import { useSelector, useDispatch } from 'react-redux'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getUserState } from '@Slices/userSlice'

import Footer from '@Common/Footer'

const Container = styled.div`
  padding: 0rem;
  /* padding-top: ${stylevars.navBar.height}; */
  padding-left: ${props => props.dash ? stylevars.sideBar.width : '0px'} !important;
  width: calc(var(--vw,1vw) * 100);
  height: calc(calc(var(--vh,1vh) * 100) - ${stylevars.navBar.height});
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background-color: ${stylevars.paper.bgColor};
  @media ${stylevars.breakpoints.phone} {
    padding-left: 0 !important;
  }
  @media ${stylevars.breakpoints.tablet} {
    padding-left: 0 !important;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: ${stylevars.typography.subtitle.color};
    /* background: var(--sfProfile_dividerColor); */
    transition: color 0.2s ease;
  }
  &::-webkit-scrollbar-track {
    background: ${stylevars.paper.borderColor};
    /* background: var(--sfProfile_bgColor); */
    border-radius: 0px;
  }
  
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: ${stylevars.palette.primary.main};
      /* background: var(--sfProfile_titleTextColor); */
    }
  }

  > .container_wrapper {
    min-height: calc(calc(var(--vh,1vh) * 100) - ${stylevars.navBar.height});
    position: relative;
    z-index: 2;
    background-color: var(--sfProfile_bgColor);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .dimmer {
    background-color: rgba(0,0,0,.5);
  }

  .header {
    color: ${stylevars.typography.title.color} !important;
  }

  .subtitle, .caption {
    color: ${stylevars.typography.subtitle.color} !important;
    > * {
      color: inherit;
    }
  }
`

const StyledContainer = ({children, ...rest}) => {
  const { pageTemplate } = useSelector(getUIState)
  const { authenticated } = useSelector(getUserState)
  const dispatch = useDispatch()

  const debouncedScroll = debounce((scrollY) => {
    // const bool = scrollY > stylevars.navBar.heightInt
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const bool = scrollY > (vh * 0.35)
    dispatch(uiSlice.actions.setPageScrolled(bool))
    // consoleLogger("debouncedScroll ============>>>>>>>>", bool)
  }, 50)

  const wrapperRef = React.useRef()
  React.useEffect(() => {
    if (wrapperRef.current) {
      const wrapperObserver = () => {
        const vh = window.innerHeight * 0.01;
        const vw = window.innerWidth * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}px`);

        const scrollbarWidth = window.innerWidth - wrapperRef.current.offsetWidth
        document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
      }

      wrapperObserver()

      window.addEventListener('resize', wrapperObserver)

      return (() => {
        window.removeEventListener('resize', wrapperObserver)
      })
    }
  }, [wrapperRef])

  const handleScroll = (e) => debouncedScroll(e.currentTarget.scrollTop)

  return (
    <Container
      onScroll={handleScroll}
      dash={pageTemplate === 'dash' && authenticated ? true : false}
      {...rest}
    >
      <div ref={wrapperRef} className='container_wrapper'>
        {children}
      </div>
      {pageTemplate !== 'dash' &&
        <Footer />
      }
    </Container>
  )
}

export default React.memo(StyledContainer)