import React from "react";
import { useHistory } from "react-router";
import he from "he";
import { Link } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useRefresh, getResponsiveStyle } from "muuri-react";
import NumberFormat from "react-number-format";
import { consoleLogger, priceFormat } from "@Utils";
import shortNumber from "short-number";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import cartSlice from "@Slices/cartSlice";
import { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import {
  Button,
  Icon,
  Dimmer,
  Loader,
  Popup,
  Modal,
  Placeholder,
} from "semantic-ui-react";
import DeleteButton from "./DeleteButton";
import RequestPrintButton from "./RequestPrintButton";

// STYLES
import { ItemContainer } from "./GalleryItemStyles";
import "react-lazy-load-image-component/src/effects/blur.css";

const Item = (props) => {
  const {
    id,
    post,
    cid,
    mode,
    fetchGallery,
    draggable,
    handleProcess,
    handleSelect,
    saveData,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.Authorization;
  const [ready, setReady] = React.useState(false);
  const { userInfo, userKey, userGallery, userCollections } =
    useSelector(getUserState);
  const { galleryCanArrange } = useSelector(getUIState);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const handleAfterLoad = () => {
    setReady(true);
  };

  // React.useEffect(() => {
  //   if (saveData) {
  //     consoleLogger('=================== SAVE DATA CHANGED ================', saveData)
  //   }
  // }, [saveData])

  const handleShowGalleryToggle = async (id, bool) => {
    let status = bool ? "pending" : "publish";
    consoleLogger("handleShowGalleryToggle =====", id, status, bool);
    setIsProcessing(true);
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery/${id}`,
      headers: {
        Authorization: `${token}`,
      },
      method: "POST",
      data: {
        // status: status,
        categories: bool ? [] : [250],
        show_in_gallery: !bool,
      },
    })
      .then((res) => {
        consoleLogger(
          "handleShowGalleryToggle RESPONSE =============",
          res.data
        );
        dispatch(userSlice.actions.updateUserGallery(res.data));
      })
      .catch((err) => {
        consoleLogger(err);
      });

    setIsProcessing(false);
  };

  const handleCollectionItemRemove = async (gid) => {
    if (mode === "collection") {
      if (handleProcess) {
        handleProcess(true);
      }

      setIsProcessing(true);
      setIsDeleting(true);
      const tempGallery = userCollections
        .filter((n) => n.id.toString() === cid.toString())
        .map((i) => i.gallery_data)[0];
      consoleLogger("============= tempGallery =================", tempGallery);
      const results = tempGallery.filter((n) => n.id !== gid);
      consoleLogger("============= RESULTS =====================", results);

      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/collections/${cid}`,
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        data: {
          author: userInfo.id,
          user_key: userKey,
          gallery_data: results,
        },
      })
        .then((res) => {
          consoleLogger("handleUpdateOrder RESPONSE =============", res.data);
          dispatch(userSlice.actions.updateUserCollection(res.data));
          // handleSetProcess(id, false)

          toast.warning(`Collection content updated.`, {
            containerId: "mainToaster",
            progress: undefined,
          });
        })
        .catch((err) => {
          consoleLogger(err);
          // handleSetProcess(id, false)
        });

      setIsDeleting(false);
      setOpenCollectionModal(false);
      setIsProcessing(false);

      if (handleProcess) {
        handleProcess(false);
      }
    }
  };

  const handleEditItem = () => {
    history.push(`/admin/gallery/view/${post.id}`);
  };

  const handleStatus = async (id, status) => {
    setIsProcessing(true);
    let toggle = status === "publish" ? "pending" : "publish";
    consoleLogger("handleStatus =====", id, status, toggle);
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery/${id}`,
      headers: {
        Authorization: `${token}`,
      },
      method: "POST",
      data: {
        post_status: toggle,
      },
    })
      .then((res) => {
        consoleLogger("handleStatus RESPONSE =============", res.data);
        dispatch(userSlice.actions.updateUserGallery(res.data));
      })
      .catch((err) => {
        consoleLogger(err);
      });
    setIsProcessing(false);
  };

  const [isDeleting, setIsDeleting] = React.useState(false);
  const [openItemDelete, setOpenItemDelete] = React.useState(false);
  const [connectedCollection, setConnectedCollection] = React.useState();
  const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
  const [showSoldWarning, setShowSoldWarning] = React.useState(false);
  const handleCheckDeleteItem = () => {
    // check if item belongs to a collection
    let found = 0;
    let arr = [];
    userCollections.forEach((collection) => {
      const galleryData = collection.gallery_data;
      const item = galleryData.filter(
        (i) => i.id.toString() === post.id.toString()
      );
      if (item.length > 0) {
        found += item.length;
        arr.push(collection);
      }
    });
    setConnectedCollection(arr);
    consoleLogger(found);
    if (found > 0) {
      setShowDeleteWarning(true);
    } else {
      // check if it gallery has been sold
      if (Number(post.acf.sold_copies) > 0) {
        setShowSoldWarning(true);
      } else {
        handleOpenItemDelete(true);
      }
    }
  };
  const handleOpenItemDelete = (bool) => {
    setOpenItemDelete(bool);
  };
  const handleJumpCollection = () => {
    history.push(`/admin/collections/view/${connectedCollection[0].id}`);
  };

  React.useState(() => {
    if (connectedCollection) {
      consoleLogger(
        "===================== connectedCollection ",
        connectedCollection
      );
    }
  }, [connectedCollection]); // eslint-disable-line

  const handleDeleteItem = async (post) => {
    setIsProcessing(true);
    setIsDeleting(true);
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery/${post.id}`,
      headers: {
        Authorization: `${token}`,
      },
      method: "DELETE",
      data: {
        author: userInfo.id,
        user_key: userKey,
      },
    })
      .then((res) => {
        consoleLogger("SF-GALLERY DELETED.... ", res);
      })
      .catch((err) => {
        consoleLogger(err);
      });
    fetchGallery();
    setIsDeleting(false);
    setIsProcessing(false);

    setOpenItemDelete(false);
  };

  const [openCollectionModal, setOpenCollectionModal] = React.useState(false);
  const handleOpenCollectionModal = (bool) => {
    setOpenCollectionModal(bool);
  };

  React.useEffect(() => {
    return () => {
      setReady(false);
    };
  });

  useRefresh([ready, userGallery, userCollections, post]);
  // const responsiveStyle = getResponsiveStyle({
  //   // The Muuri component is virtually divided into 8 columns,
  //   // the width of the item will be 3 columns (minus the margin).
  //   columns: 2 / 8,
  //   // The margin of the item, it can be any CSS values
  //   // valid for the margin expressed in "px" or "%".
  //   // margin: '5%',
  //   // The width/height ratio. If you want to set a static
  //   // height just set the "height" option in px and remove the "ratio".
  //   ratio: 1.75,
  // });

  return (
    <ItemContainer
      key={post.id}
      className={cn("item", {
        arrange: draggable,
        publish: post.post_status === "publish",
      })}
    >
      <div className="item-wrapper">
        <div className="item-content">
          <div className="handle">
            <Icon name="arrows alternate" />
          </div>
          <div className="item-details">
            <Dimmer active={isProcessing} className="preloader">
              <Loader size="massive" />
            </Dimmer>
            <div className="topRight">
              <div className="itemViews">
                <Icon name="eye" />
                <span>{shortNumber(post.acf.pageviews)}</span>
              </div>
              <div className="itemViews">
                <Icon name="like" />
                <span>{shortNumber(post.acf.likes.length)}</span>
              </div>
              {!!post.acf.limited_edition && (
                <div className="limitedEdition">
                  <Icon name="tag" />
                  <span>
                    <NumberFormat
                      value={post.acf.sold_copies}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale={false}
                    />
                    {` of `}
                    <NumberFormat
                      value={post.acf.number_lots}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale={false}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className={cn("showInGallery")}>
              {mode === "selection" && (
                <Popup
                  trigger={
                    <Button
                      circular
                      type="button"
                      icon={
                        saveData.filter(
                          (n) => n.id.toString() === post.id.toString()
                        ).length > 0
                          ? "minus"
                          : "plus"
                      }
                      color={
                        saveData.filter(
                          (n) => n.id.toString() === post.id.toString()
                        ).length > 0
                          ? "red"
                          : "green"
                      }
                      onClick={() => {
                        handleSelect({ id: post.id, collection_order: 0 });
                        // handleOpenCollectionModal(true)
                      }}
                    />
                  }
                  content={
                    saveData.filter(
                      (n) => n.id.toString() === post.id.toString()
                    ).length > 0
                      ? `Remove from collection`
                      : `Add to collection`
                  }
                  position="bottom center"
                  inverted
                />
              )}
              {mode === "collection" && (
                <Popup
                  trigger={
                    <Button
                      circular
                      type="button"
                      icon={"minus"}
                      color={"red"}
                      onClick={() => {
                        handleOpenCollectionModal(true);
                      }}
                    />
                  }
                  content={`Remove from collection`}
                  position="bottom center"
                  inverted
                />
              )}
              {mode === "gallery" && (
                <Popup
                  trigger={
                    <Button
                      circular
                      type="button"
                      icon={post.acf.show_in_gallery ? "minus" : "plus"}
                      color={post.acf.show_in_gallery ? "red" : "green"}
                      onClick={() => {
                        handleShowGalleryToggle(
                          post.id,
                          post.acf.show_in_gallery
                        );
                      }}
                    />
                  }
                  content={
                    post.acf.show_in_gallery
                      ? `Remove from gallery`
                      : `Attach to gallery`
                  }
                  position="bottom center"
                  inverted
                />
              )}
            </div>
            <div className={cn("itemInfo itemActions")}>
              {mode === "gallery" && (
                <>
                  <Button
                    icon="trash"
                    type="button"
                    labelPosition="right"
                    content={<span>Delete Permanently</span>}
                    color="red"
                    onClick={() => handleCheckDeleteItem()}
                  />
                  <Button
                    icon={
                      post.post_status === "publish"
                        ? "lightbulb"
                        : "lightbulb outline"
                    }
                    labelPosition="right"
                    content={
                      <span>
                        Click to{" "}
                        {post.post_status === "publish"
                          ? "Unpublish"
                          : "Publish"}
                      </span>
                    }
                    color={post.post_status === "publish" ? "green" : "red"}
                    onClick={() => handleStatus(post.id, post.post_status)}
                  />
                </>
              )}
              <Button
                icon="pencil"
                type="button"
                labelPosition="right"
                content={<span>Edit</span>}
                color="blue"
                onClick={handleEditItem}
              />
              <RequestPrintButton post={post} />
            </div>
            <div className={cn("itemInfo itemFooter")}>
              <div className="name">{he.decode(post.post_title)}</div>
              <div className="price">
                <NumberFormat
                  value={post.acf.base_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            </div>
          </div>
          <LazyLoadImage
            delayMethod="debounce"
            delayTime={1000}
            visibleByDefault={true}
            placeholder={
              <Placeholder style={{ height: 150, width: 150 }}>
                <Placeholder.Image />
              </Placeholder>
            }
            wrapperClassName="imageWrapper"
            afterLoad={(ev) => {
              handleAfterLoad(ev);
            }}
            effect="blur"
            alt={he.decode(post.post_title)}
            src={post.media.medium}
          />
        </div>
      </div>
      {openItemDelete && (
        <Modal
          size="mini"
          open={openItemDelete}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => handleOpenItemDelete(true)}
          onClose={() => handleOpenItemDelete(false)}
        >
          <Modal.Header>Deleting Gallery Item</Modal.Header>
          <Modal.Content scrolling>
            <p>
              You are about to delete{" "}
              <span className="text--bold">{he.decode(post.post_title)}</span>.
              Are you sure you want to continue?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              type="button"
              disabled={isDeleting}
              onClick={() => handleOpenItemDelete(false)}
            >
              Cancel
            </Button>
            <DeleteButton
              post={post}
              isDeleting={isDeleting}
              handleDeleteItem={handleDeleteItem}
            />
          </Modal.Actions>
        </Modal>
      )}
      {openCollectionModal && (
        <Modal
          size="mini"
          open={openCollectionModal}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => handleOpenCollectionModal(true)}
          onClose={() => handleOpenCollectionModal(false)}
        >
          <Modal.Header>Removing Collection Item</Modal.Header>
          <Modal.Content scrolling>
            <p>
              You are about to remove{" "}
              <span className="text--bold">{he.decode(post.post_title)}</span>{" "}
              from your collection. Are you sure you want to continue?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              disabled={isDeleting}
              onClick={() => handleOpenCollectionModal(false)}
            >
              Cancel
            </Button>
            <DeleteButton
              post={post}
              isDeleting={isDeleting}
              handleDeleteItem={() => handleCollectionItemRemove(post.id)}
            />
          </Modal.Actions>
        </Modal>
      )}
      {showDeleteWarning && (
        <Modal
          size="mini"
          open={showDeleteWarning}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => setShowDeleteWarning(true)}
          onClose={() => setShowDeleteWarning(false)}
        >
          <Modal.Header>Deletion Warning</Modal.Header>
          <Modal.Content scrolling>
            <p>
              Unable to delete{" "}
              <span className="text--bold">{he.decode(post.post_title)}</span>.
              It appears that it belongs to a collection called{" "}
              <strong>{connectedCollection[0].title}</strong>. You need to
              remove it from the collection first before deleting the gallery
              item permanently.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              icon="close"
              labelPosition="right"
              content="Close"
              onClick={() => setShowDeleteWarning(false)}
            />
            <Button
              type="button"
              primary
              icon="arrow right"
              labelPosition="right"
              content={`Jump To That Collection`}
              onClick={handleJumpCollection}
            />
          </Modal.Actions>
        </Modal>
      )}
      {showSoldWarning && (
        <Modal
          size="mini"
          open={showSoldWarning}
          dimmer={{
            blurring: true,
          }}
          onOpen={() => setShowSoldWarning(true)}
          onClose={() => setShowSoldWarning(false)}
        >
          <Modal.Content scrolling>
            <p>
              Unable to delete <strong>SOLD</strong> items.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              basic
              icon="close"
              labelPosition="right"
              content="Close"
              onClick={() => setShowSoldWarning(false)}
            />
          </Modal.Actions>
        </Modal>
      )}
    </ItemContainer>
  );
};

export default React.memo(Item);
