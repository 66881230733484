export const themeColors = [
  {
    name: 'Fresco',
    key: 'themecolor-fresco-light',
    type: 'Light',
    data: {
      bgColor: "255,255,255,1",
      titleTextColor: "255,255,255,1",
      socialIconColor: "255,186,8,1",
      dividerColor: "230,230,230,1",
      linkTextColor: "180,180,180,1",
      linkTextColorHover: "0,0,0,1",
      linkTextColorActive: "255,186,8,1",
      linkBGColor: "255,255,255,1",
      linkBGColorHover: "255,255,255,1",
      linkBGColorActive: "255,255,255,1",
      linkLineColor: "255,255,255,1",
      linkLineColorHover: "230,230,230,1",
      linkLineColorActive: "255,186,8,1",
      galleryTitleColor: "255,255,255,1",
      profileTextColor: "10,10,10,1",
      profileBGColor: "255,255,255,0"
    }
  },
  {
    name: 'Dalaga',
    key: 'themecolor-dalaga-light',
    type: 'Light',
    data: {
      bgColor: "205,240,234,1",
      titleTextColor: "190,174,226,1",
      socialIconColor: "247,219,240,1",
      dividerColor: "0,0,0,0.15",
      linkTextColor: "0,0,0,0.4",
      linkTextColorHover: "190,174,226,1",
      linkTextColorActive: "190,174,226,1",
      linkBGColor: "0,0,0,0",
      linkBGColorHover: "0,0,0,0",
      linkBGColorActive: "0,0,0,0",
      linkLineColor: "0,0,0,0",
      linkLineColorHover: "190,174,226,1",
      linkLineColorActive: "190,174,226,1",
      galleryTitleColor: "247,219,240,1",
      profileBGColor: "190,174,226,1",
      profileTextColor: "0,0,0,0.65"
    }
  },
  {
    name: 'Mirage',
    key: 'themecolor-mirage-light',
    type: 'Light',
    data: {
      bgColor: "248,237,227,1",
      titleTextColor: "248,237,227,1",
      socialIconColor: "121,135,119,1",
      dividerColor: "189,210,182,1",
      linkTextColor: "121,135,119,1",
      linkTextColorHover: "189,210,182,1",
      linkTextColorActive: "248,237,227,1",
      linkBGColor: "248,237,227,1",
      linkBGColorHover: "162,178,159,1",
      linkBGColorActive: "121,135,119,1",
      linkLineColor: "248,237,227,1",
      linkLineColorHover: "162,178,159,1",
      linkLineColorActive: "121,135,119,1",
      galleryTitleColor: "247,219,240,1",
      profileTextColor: "121,135,119,1",
      profileBGColor: "248,237,227,0.3"
    }
  },
  {
    name: 'Bouquet',
    key: 'themecolor-bouquet-light',
    type: 'Light',
    data: {
      bgColor: "237,237,208,1",
      titleTextColor: "144,127,164,1",
      socialIconColor: "237,237,208,1",
      dividerColor: "216,216,190,1",
      linkTextColor: "0,0,0,0.35",
      linkTextColorHover: "0,0,0,0.6",
      linkTextColorActive: "144,127,164,1",
      linkBGColor: "0,0,0,0",
      linkBGColorHover: "0,0,0,0.05",
      linkBGColorActive: "0,0,0,0",
      linkLineColor: "0,0,0,0",
      linkLineColorHover: "0,0,0,0.3",
      linkLineColorActive: "144,127,164,1",
      galleryTitleColor: "237,237,208,1",
      profileBGColor: "0,0,0,0.1",
      profileTextColor: "0,0,0,0.75"
    }
  },
  {
    name: 'Terran',
    key: 'themecolor-terran-dark',
    type: 'Dark',
    data: {
      bgColor: "42,42,35,1",
      titleTextColor: "217,234,199,1",
      subtitleTextColor: "255,255,255,1",
      socialIconColor: "217,234,199,1",
      dividerColor: "217,234,199,0.2",
      linkTextColor: "217,234,199,1",
      linkTextColorHover: "253,253,253,1",
      linkTextColorActive: "206,248,161,1",
      linkBGColor: "255,255,255,0",
      linkBGColorHover: "255,255,255,0",
      linkBGColorActive: "255,255,255,0",
      linkLineColor: "255,255,255,0",
      linkLineColorHover: "255,255,255,1",
      linkLineColorActive: "206,248,161,1",
      galleryTitleColor: "217,234,199,1",
      profileBGColor: "217,234,199,0.05",
      profileTextColor: "217,234,199,1"
    }
  },
  {
    name: 'SynthWave',
    key: 'themecolor-synthwave-dark',
    type: 'Dark',
    data: {
      bgColor: "7,11,14,1",
      titleTextColor: "236,104,220,1",
      subtitleTextColor: "255,255,255,1",
      socialIconColor: "236,104,220,1",
      dividerColor: "33,85,184,0.5",
      linkTextColor: "33,85,184,1",
      linkTextColorHover: "137,167,227,1",
      linkTextColorActive: "119,167,248,1",
      linkBGColor: "255,255,255,0",
      linkBGColorHover: "16,21,42,1",
      linkBGColorActive: "255,255,255,0",
      linkLineColor: "240,240,240,0",
      linkLineColorHover: "33,85,184,1",
      linkLineColorActive: "119,167,248,1",
      galleryTitleColor: "119,167,248,1",
      profileBGColor: "33,85,184,0.1",
      profileTextColor: "119,167,248,1"
    }
  },
  {
    name: 'Fuego',
    key: 'themecolor-fuego-dark',
    type: 'Dark',
    data: {
      bgColor: "0,0,0,1",
      titleTextColor: "255,0,0,1",
      socialIconColor: "255,0,0,1",
      dividerColor: "61,0,0,1",
      linkTextColor: "149,1,1,1",
      linkTextColorHover: "61,0,0,1",
      linkTextColorActive: "255,0,0,1",
      linkBGColor: "0,0,0,0",
      linkBGColorHover: "0,0,0,0",
      linkBGColorActive: "0,0,0,0",
      linkLineColor: "0,0,0,0",
      linkLineColorHover: "61,0,0,1",
      linkLineColorActive: "255,0,0,1",
      galleryTitleColor: "247,219,240,1",
      profileBGColor: "61,0,0,0.5",
      profileTextColor: "194,112,112,1"
    }
  },
  {
    name: 'G-Force',
    key: 'themecolor-gforce-dark',
    type: 'Dark',
    data: {
      bgColor: "8,32,50,1",
      titleTextColor: "255,76,41,1",
      socialIconColor: "255,76,41,1",
      dividerColor: "44,57,75,1",
      linkTextColor: "245,248,250,0.3",
      linkTextColorHover: "255,255,255,1",
      linkTextColorActive: "255,76,41,1",
      linkBGColor: "0,0,0,0",
      linkBGColorHover: "0,0,0,0",
      linkBGColorActive: "0,0,0,0",
      linkLineColor: "0,0,0,0",
      linkLineColorHover: "255,255,255,1",
      linkLineColorActive: "255,76,41,1",
      galleryTitleColor: "255,255,255,1",
      profileBGColor: "8,32,50,1",
      profileTextColor: "245,248,250,0.3"
    }
  }
]