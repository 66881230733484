import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  /* max-height: calc(calc(var(--vh, 1vh) * 100) - 9rem - 100px); */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0rem;
  @media ${stylevars.breakpoints.phone} {
    padding: 0;
  }
`