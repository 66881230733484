import React from 'react'
import { useLocation } from 'react-router-dom' // eslint-disable-line
import axios from 'axios'
import { consoleLogger } from '@Utils'
import shortNumber from 'short-number'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Button, Icon } from 'semantic-ui-react'
import LoginModal from '@AdminPages/Login/LoginModal'

// STYLES
import { Container } from './LikeButtonStyles'

const LikeButton = (props) => {
  const {
    itemData,
    type
  } = props
  const token = localStorage.Authorization
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  const location = useLocation()
  const currentPathname = location.pathname

  const [ ready, setReady ] = React.useState(false)
  const [ displayData, setDisplayData ] = React.useState()
  const [ userLikesIt, setUserLikesIt ] = React.useState(false)

  React.useEffect(() => {
    (async() => {
      consoleLogger('=========== itemData =============', itemData)
      setDisplayData(itemData)
    })()
  }, [itemData]) // eslint-disable-line

  React.useEffect(() => {
    if (displayData) {
      if (userInfo) {
        // check if logged in user likes this item
        let likes = []
        if (type === 'gallery') {
          if (displayData.acf && displayData.acf.likes) {
            likes = displayData.acf.likes
          }
        } else {
          if (displayData && displayData.likes) {
            likes = displayData.likes
          }
        }
        let chk = 0
        if (userInfo && likes.length > 0) {
          likes.forEach(n => {
            if (n.id.toString() === userInfo.id.toString()) {
              chk += 1
            }
          })
        }
        setUserLikesIt(chk > 0)
      }
      setReady(true)
    }
  }, [displayData]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('========== userLikesIt =============', userLikesIt)
  }, [userLikesIt])
  
  const [ processLikes, setProcessLikes ] = React.useState(false)
  const handleLikes = async () => {
    if (authenticated) {
      setProcessLikes(true)
      consoleLogger('handleLikes =============================')
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: type === 'gallery' ? `/stylefolio/v1/like/${displayData.ID}` : `/stylefolio/v1/collection/like/${displayData.ID}`,
        method: 'GET',
        params: {
          userId: userInfo.id,
          userKey: userKey,
        },
        headers: {
          Authorization: `${token}`,
        },
      }).then(res => {
        setDisplayData(res.data)
      }).catch(err => {
        consoleLogger(err)
      })
      setProcessLikes(false)
    } else {
      handleLoginModal(true)
    }
  }
  
  const [loginOpen, setLoginOpen] = React.useState(false)
  const handleLoginModal = (bool) => {
    setLoginOpen(bool)
  }

  return (
    <>
      <Container className='likeButton'>
        {ready ?
          <Button
            type='button'
            icon
            onClick={handleLikes}
            loading={processLikes}
            disabled={processLikes}
          >
            <Icon name={userLikesIt ? 'heart' : 'heart outline'} />
            <span>{shortNumber(type === 'gallery' ? displayData.acf.likes.length : displayData.likes.length)}</span>
          </Button> : 
          <>...</>
        }
      </Container>
      
      {loginOpen &&
        <LoginModal customer open={loginOpen} handler={handleLoginModal} to={currentPathname} />
      }
    </>
  )
}

export default LikeButton
