import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledFooterContainer = styled.div`
  padding: 4rem 0rem 0 0;
  position: sticky;
  z-index: 1;
  bottom: 0;
  background-color: rgba(${stylevars.typography.title.colorRGB}, 1);

  @media ${stylevars.breakpoints.phone} {
    position: relative;
  }

  .section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .section_wrapper {
      width: 100%;
      max-width: 1200px;
      align-items: center; 
      padding: 2rem;
    }

    &.fluid {
      .section_wrapper {
        max-width: 100%;
      }
    }

    .logo {
      max-width: 120px;
      margin-bottom: 1rem;
    }

    h3 {
      color: #fff;
      font-size: 2rem;
      font-weight: 100;
      margin: 0 0 2rem 0;
    }

    p {
      color: #fff;
      &.text {
        > span {
          display: block;
          margin-bottom: 1rem;
        }
        a {
          color: ${stylevars.palette.primary.main};
          border-bottom: 1px solid rgba(${stylevars.palette.primary.rgb},0);
          transition: all 0.15s ease-out;
          &:hover {
            border-bottom: 1px solid rgba(${stylevars.palette.primary.rgb},1);
          }
        }
      }
    }
  }

  .community {
    a {
      margin: 0 0.5rem;
      display: inline-block;
      font-size: 1.5rem;
    }
  }

  .copyright {
    border-top: 1px solid rgba(${stylevars.paper.borderColorRGB}, 0.2);
    display: flex;
    padding: 2rem;
    margin-top: 4rem;
    justify-content: center;
    color: ${stylevars.typography.subtitle.color};
    font-size: 1rem;
  }
`