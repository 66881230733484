import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { consoleLogger, cleanPrice } from '@Utils'
import NumberFormat from 'react-number-format'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// REDUX
import { useSelector } from 'react-redux'
import {getUIState} from '@Slices/uiSlice'

// COMPONENTS
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Image,
  Label,
  Loader
} from 'semantic-ui-react'
import { SFAvatar, UserDisplayName, LikeButton } from '@Common'

// STYLES
import { Container } from './GalleryCardStyles'
import 'react-lazy-load-image-component/src/effects/blur.css';

const GalleryCard = (props) => {
  const {
    itemData,
    onClick
  } = props
  const history = useHistory()
  const { currency } = useSelector(getUIState)

  const [ displayData, setDisplayData ] = React.useState()
  const [ stillAvailable, setStillAvailable ] = React.useState(true)

  React.useEffect(() => {
    if (itemData) {
      consoleLogger('=========== itemData =============', itemData)
      setDisplayData(itemData)
    }
  }, [itemData])

  React.useEffect(() => {
    (async() => {
      if (displayData) {
        // check if item is limited edition
        if (displayData.acf && displayData.acf.limited_edition) {
          // check if there are available copies left
          consoleLogger((Number(displayData.acf.sold_copies) + 1) >= Number(displayData.acf.number_lots))
          if ((Number(displayData.acf.sold_copies) + 1) >= Number(displayData.acf.number_lots)) {
            setStillAvailable(false)
          }
        }
      }
    })()
  }, [displayData]) // eslint-disable-line

  const handleClick = (ev) => {
    ev.preventDefault()
    if (onClick) {
      onClick()
    }
    history.push(`/@${displayData.user.username}/gallery/${displayData.ID}`)
  }

  return (
    <Container>
      {displayData &&
        <div className='item'>
          <Link to={`/@${displayData.user.username}/gallery/${displayData.ID}`} className='link' onClick={handleClick}>
            <LazyLoadImage
              src={displayData.media.medium}
              wrapperClassName='image'
              alt={`${displayData.post_title} cover`}
              effect="blur"
            />
          </Link>
          <div className='details'>
            <h4 className='title'>
              <span>
                {displayData.post_title}
              </span>
              <LikeButton itemData={displayData} type='gallery' />
            </h4>
            {displayData.acf.limited_edition &&
              <div className='field price'>
                <span className='label'>Limited Edition</span>
                <span className='value'>
                  {!!stillAvailable ?
                    <div className='copies'>
                      <NumberFormat
                        value={Number(displayData.acf.sold_copies) + 1}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <NumberFormat
                        value={Number(displayData.acf.number_lots)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix='/'
                      />
                    </div> : 
                    <>Sold Out</>
                  }
                </span>
              </div>
            }
            <div className='field price'>
              <span className='label'>Base Price</span>
              <span className='value'>
                <NumberFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={cleanPrice(displayData.acf.base_price * currency.value)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={currency.symbol}
                />
              </span>
            </div>
            <div className='field creator'>
              <span className='label'>Creator</span>
              <span className='value'>
                <Link to={`/@${displayData.user.username}`} className='valueLink'>
                  <SFAvatar className='avatar' userInfo={displayData.user} />
                  <UserDisplayName user={displayData.user} />
                </Link>
              </span>
            </div>
          </div>
        </div>
      }
    </Container>
  )
}

export default React.memo(GalleryCard)