import React from "react";
import { Link } from "react-router-dom";
// import axios from 'axios'
// import { setAuthorizationHeader } from '@Utils'
// import jwtDecode from 'jwt-decode'
import { useParams } from "react-router-dom";
// import he from 'he'
import NumberFormat from "react-number-format";
// import { findIndex, nth } from 'lodash'
import { Helmet } from "react-helmet";
import { consoleLogger, priceFormat, cleanPrice } from "@Utils";
// import useLocalStorage from 'react-use-localstorage'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from "react-redux";
// import { getVendorState } from '@Slices/vendorSlice'
import { getUserState } from "@Slices/userSlice";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import cartSlice, { getCartState } from "@Slices/cartSlice";
import { getGalleryState } from "@Slices/gallerySlice";

// COMPONENTS
import {
  Header,
  Divider,
  Segment,
  Grid,
  // Item,
  Button,
  // Icon,
  // Input,
  // Image,
  Dimmer,
  Loader,
  // Confirm,
} from "semantic-ui-react";
import CartVendor from "./CartVendor";
import CurrencySelection from "@Common/CurrencySelection";

// STYLES
import StyledStoreCartContainer from "./StoreCartStyles";
import { toast } from "react-toastify";

// VALIDATION SCHEMA
// const validationSchema = Yup.object().shape({
//   couponcode: Yup.string()
//     .max(16, "Must be shorter than 16 characters")
// })

const StoreCartPage = () => {
  // const history = useHistory()
  const dispatch = useDispatch();
  // const { authenticated } = useSelector(getUserState)
  const { path } = useParams();
  // const decodedPath = decodeURIComponent(path)
  // consoleLogger('decodedPath =======================', decodedPath)

  const { carts, coupons } = useSelector(getCartState);
  const { currency, siteSettings } = useSelector(getUIState);
  const { gallerySizeTypes, gallerySizes } = useSelector(getGalleryState);
  const { userGallery } = useSelector(getUserState);

  const shippingFee = siteSettings.shippingFeeDomestic;
  const shippingFeeIntl = siteSettings.shippingFeeInternational;

  const [storageCarts, setStorageCarts] = React.useState(
    localStorage.carts && JSON.parse(localStorage.carts).length > 0
      ? JSON.parse(localStorage.carts)
      : []
  );
  const [isReady, setIsReady] = React.useState(false);
  const [totalDiscount, setTotalDiscount] = React.useState(0);
  const [subtotalAmount, setSubtotalAmount] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalShippingFee, setTotalShippingFee] = React.useState(shippingFee);
  const [totalLoaded, setTotalLoaded] = React.useState(0);
  const handleTotalLoaded = (n) => {
    setTotalLoaded((prev) => prev + n);
  };
  const [cartError, setCartError] = React.useState([]);
  const handleError = (mode, data) => {
    if (mode === "add") {
      if (cartError && cartError.length > 0) {
        if (!cartError.includes(data)) {
          setCartError((prev) => [...prev, data]);
        }
      } else {
        setCartError((prev) => [...prev, data]);
      }
    } else {
      if (cartError.includes(data)) {
        setCartError((prev) => prev.filter((n) => n !== data));
      }
    }
  };
  const [totalItems, setTotalItems] = React.useState(0);
  const handleCartItemCount = (n) => {
    setTotalItems(n);
  };
  const [processing, setProcessing] = React.useState(false);
  const handleProcessing = (bool) => {
    setProcessing(bool);
  };
  React.useEffect(() => {
    consoleLogger("=================== HANDLE CART ERROR =================");
    consoleLogger(cartError);
    consoleLogger("=================== TOTAL CART ITEMS =================");
    consoleLogger(totalItems);
    consoleLogger(
      "=================== TOTAL CART ITEM LOADED ================="
    );
    consoleLogger(totalLoaded);
  }, [cartError, totalItems, totalLoaded]); // eslint-disable-line

  // const [ vendorCoupons, setVendorCoupons ] = React.useState(
  //   localStorage.coupons && JSON.parse(localStorage.coupons).length > 0 ?
  //     JSON.parse(localStorage.coupons) :
  //     localStorage.carts && JSON.parse(localStorage.carts).length > 0 ?
  //       JSON.parse(localStorage.carts).map(vendor => ({
  //         vendorId: vendor.id,
  //         code: '',
  //         type: '',
  //         value: '',
  //         minimum: '',
  //         quantity: '',
  //         processing: false,
  //         verified: false,
  //       })) :
  //       []
  // )
  const [vendorCoupons, setVendorCoupons] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const count = carts
        .map((vendor) => {
          const items = vendor.items;
          return Object.keys(items)
            .map((n) => n.length)
            .reduce((a, b) => a + b, 0);
        })
        .reduce((a, b) => a + b, 0);
      handleCartItemCount(count);
      setIsReady(true);
    })();
  }, []); // eslint-disable-line

  const getVendorName = (id) => {
    return carts.filter((vendor) => vendor.id === id)[0].name;
  };

  const SubTotal = () => {
    return (
      <>
        <Grid.Row className="shipping">
          <Grid.Column computer={8} verticalAlign="middle">
            <p>Sub Total</p>
          </Grid.Column>
          <Grid.Column computer={8}>
            <Header as="h4">
              <NumberFormat
                value={subtotalAmount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency.symbol}
                decimalScale={2}
                fixedDecimalScale
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const Shipping = () => {
    // let shipping = 0
    // storageCarts.forEach(vendor => {
    //   vendor.items.forEach(item => {
    //     shipping += item.quantity * (shippingFee * currency.value)
    //   })
    // })
    const shipping = cleanPrice(
      shippingFee * Math.ceil(itemTotalCount / 10) * currency.value
    );
    setTotalShippingFee(shipping);
    return (
      <>
        <Grid.Row className="shipping">
          <Grid.Column computer={8} verticalAlign="middle">
            <Header as="h4">Estimated Shipping Fee</Header>
          </Grid.Column>
          <Grid.Column computer={8}>
            <Header as="h4">
              <NumberFormat
                value={shipping}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency.symbol}
                decimalScale={2}
                fixedDecimalScale
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const Total = () => {
    return (
      <>
        <Grid.Row className="total">
          <Grid.Column mobile={16} computer={8} verticalAlign="middle">
            <Header as="h2">Estimated Total</Header>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            {totalDiscount > 0 ? (
              <>
                <Header as="h2" className="strike">
                  <NumberFormat
                    value={totalAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency.symbol}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Header>
                <Header as="h2" className="discounted">
                  <NumberFormat
                    value={totalAmount - totalDiscount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency.symbol}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                  <NumberFormat
                    value={totalDiscount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={`You saved ${currency.symbol}`}
                    className="saved"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Header>
              </>
            ) : (
              <Header as="h2">
                <NumberFormat
                  value={totalAmount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currency.symbol}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Header>
            )}
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const [itemTotalCount, setItemTotalCount] = React.useState();
  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate("store"));
    dispatch(uiSlice.actions.setCurrentPage("cart"));
    dispatch(uiSlice.actions.setCurrentPageURL());
    dispatch(uiSlice.actions.setCurrentPageTitle());

    carts.forEach((vendor) => {
      if (vendor.items.length === 0) {
        dispatch(
          cartSlice.actions.removeVendor({ id: vendor.id, slug: vendor.slug })
        );
      }
    });
    setStorageCarts(carts);
  }, [carts]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger(">>>>>>>>>>>>>>>>>>> itemTotalCount", itemTotalCount);
  }, [itemTotalCount]); // eslint-disable-line

  React.useEffect(() => {
    let ntotalDiscount = 0;
    let ntotalAmount = 0;

    storageCarts.forEach((vendor) => {
      const coupon = vendorCoupons.filter((i) => i.vendorId === vendor.id)[0];
      vendor.items.forEach((item) => {
        // ntotalAmount += priceFormat((Number(item.price) * currency.value) * Number(item.quantity))
        const itemPrice = cleanPrice(Number(item.price) * currency.value);
        ntotalAmount += priceFormat(Number(itemPrice) * Number(item.quantity));
      });
    });
    setSubtotalAmount(ntotalAmount);
    ntotalAmount += priceFormat(totalShippingFee);
    setTotalAmount(ntotalAmount);
    setTotalDiscount(ntotalDiscount);
    ntotalDiscount = ntotalDiscount * currency.value;

    dispatch(cartSlice.actions.setCoupons(vendorCoupons));

    dispatch(
      cartSlice.actions.updateCheckout({
        totalAmount: priceFormat(ntotalAmount - ntotalDiscount),
        shippingFee: priceFormat(totalShippingFee),
        discount: priceFormat(ntotalDiscount),
        currency: currency.currency,
      })
    );

    return () => {};
  }, [vendorCoupons, storageCarts, totalAmount, totalShippingFee]); // eslint-disable-line

  React.useEffect(() => {
    if (
      storageCarts &&
      storageCarts.length > 0 &&
      Object.keys(storageCarts).length > 0
    ) {
      setItemTotalCount(
        storageCarts.map((n) =>
          n.items.map((i) => i.quantity).reduce((a, b) => a + b, 0)
        )[0]
      );
    }
  }, [storageCarts]); // eslint-disable-line

  // consoleLogger('vendorCoupons', vendorCoupons)

  return (
    <>
      {!isReady ? (
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      ) : (
        <StyledStoreCartContainer>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Shopping Cart - {process.env.REACT_APP_PROJECT_NAME}</title>
            <link
              rel="canonical"
              href={`${process.env.REACT_APP_DOMAIN}/cart`}
            />
          </Helmet>
          <Header as="h2" className="cartPageTitle">
            <span className="title">Your Shopping Cart</span>
            <span className="extra">
              <span>Select Currency</span>
              <CurrencySelection />
            </span>
          </Header>
          <Divider />
          <Segment className="cart">
            <Grid divided className="cartContainer">
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={10}
                  className="cartItems"
                >
                  <div className="cartItemsContainer">
                    {carts && carts.length > 0 ? (
                      <>
                        {carts.map((vendor, vIndex) => (
                          <CartVendor
                            key={vendor.id}
                            vendor={vendor}
                            vIndex={vIndex}
                            handleCartError={handleError}
                            handleCartItemCount={handleCartItemCount}
                            handleTotalLoaded={(ev) => handleTotalLoaded(ev)}
                            handleProcessing={handleProcessing}
                          />
                        ))}
                      </>
                    ) : (
                      <p>Cart is empty...</p>
                    )}
                  </div>
                </Grid.Column>
                {storageCarts && storageCarts.length > 0 && (
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={6}
                    className="cartSummary"
                  >
                    <div className="cartSummaryContainer">
                      <Header as="h3">Order Summary</Header>
                      <Divider />
                      <Grid>
                        <SubTotal />
                        <Shipping />
                        {/* <Coupon /> */}
                        <Total />
                        <Divider />
                        <Grid.Row>
                          <Grid.Column computer={16}>
                            <Button
                              as={Link}
                              to="/checkout"
                              fluid
                              positive
                              size="large"
                              icon="arrow right"
                              content="Proceed to Checkout"
                              labelPosition="right"
                              disabled={
                                cartError.length > 0 ||
                                totalLoaded < totalItems ||
                                processing
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Segment>
        </StyledStoreCartContainer>
      )}
    </>
  );
};

export default React.memo(StoreCartPage);
