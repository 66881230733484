import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { checkBrightness } from '@Utils'
import { rgba } from 'polished';

export const CollectionsViewContainer = styled('div')`
  > .wrapper {
    position: relative;
    > .header {
      position: relative;
      z-index: 2;
      width: 100%;
      height: calc(min(420px, calc(calc(var(--vh, 1vh) * 50) - 4rem)) / 1.5);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1 {
        position: relative;
        margin: 0;
        width: auto;
        padding: 0 2rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: var(--sfProfile_profileBGColor); */
        
        max-width: 600px;

        &.limited {
          padding-right: 8rem;
        }
        @media ${stylevars.breakpoints.phone} {
          width: 100%;
          /* padding-bottom: 2rem; */
          align-items: flex-start;
        }

        .title {
          display: block;
          width: 100%;
          text-align: center;
          /* overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; */
          font-weight: 200;
          line-height: 1.6;
          font-size: 3rem;
          letter-spacing: -0.2rem;
          color: var(--sfProfile_titleTextColor);
          @media ${stylevars.breakpoints.phone} {
            text-align: left;
            line-height: 1.2;
          }
        }

        .label {
          position: absolute;
          top: 50%;
          right: 2rem;
          transform: translateY(-50%);
          display: inline-block;
          padding: 0.1rem 0.5rem;
          border: 1px solid ${stylevars.palette.primary.main};
          color: ${stylevars.palette.primary.main};
          font-size: 8px;
          line-height: 1.6;
          border-radius: 0.25rem;
          text-transform: uppercase;
          @media ${stylevars.breakpoints.phone} {
            top: 1rem;
            transform: none;
          }
        }
      }

      h4 {
        color: var(--sfProfile_titleTextColor);
        opacity: 0.6;
        margin: 0;
        padding: 0 2rem;
        text-align: center;
        font-size: 1.2rem;
        max-width: 600px;
        font-weight: normal;
        line-height: 1.6;
      }

      .actions {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
      }

      .backButton {
        background-color: ${props => `rgba(${props.sfStyles.linkBGColor})`};
        padding: 0;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--sfProfile_linkTextColor);
        opacity: 1;
        transition: all 0.15s ease-out;
        border-radius: 8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          line-height: 3rem;
        }
        .icon {
          width: 2rem;
          height: 2rem;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin: 0;
          border-radius: 50%;
          border: 1px solid var(--sfProfile_linkTextColor);
          color: var(--sfProfile_linkTextColor);
          transition: all 0.15s ease-out;
          position: absolute;
          left: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          color: var(--sfProfile_linkTextColorActive);
          background-color: ${props => `rgba(${props.sfStyles.linkBGColorHover})`};
          .icon {
            border: 1px solid var(--sfProfile_linkTextColorActive);
            color: var(--sfProfile_linkTextColorActive);
          }
        }
      }
      .likeButton {
        background-color: ${props => `rgba(${props.sfStyles.linkBGColor})`};
        padding: 0;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--sfProfile_linkTextColor);
        opacity: 1;
        transition: all 0.15s ease-out;
        border-radius: 8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1rem;

        &:hover {
          color: var(--sfProfile_linkTextColorActive);
          background-color: ${props => `rgba(${props.sfStyles.linkBGColorHover})`};
          .icon {
            color: var(--sfProfile_linkTextColorActive);
          }
        }
      }
    }
    > .cover {
      position: fixed;
      z-index: 1;
      top: calc(${stylevars.navBar.height} + 40px);
      left: -2rem;
      right: var(--scrollbarWidth);
      /* border-top-left-radius: 2rem;
      border-top-right-radius: 2rem; */
      height: calc(min(420px, calc(calc(var(--vh, 1vh) * 50) - 4rem)));
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: linear-gradient(to top, ${props => rgba(`rgba(${props.sfStyles.bgColor})`, 1)} 0%, ${props => rgba(`rgba(${props.sfStyles.bgColor})`, 0)} 100%);
      }
      .image {
        display: block;
        width: 100%;
        height: 100%;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        /* opacity: 0.5; */
      }
    }
    > .content {
      position: relative;
      z-index: 2;
      margin: 0 2rem;
      padding: 2rem;
      color: var(--sfProfile_profileTextColor);
      background-color: var(--sfProfile_profileBGColor);
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      /* min-height: 800px; */
      min-height: calc(calc(var(--vh, 1vh) * 100) - 280px - ${stylevars.navBar.height});
      /* box-shadow: 0 -0.25rem 1rem 0.1rem rgba(0,0,0,0.25); */

      p {
        line-height: 2;
      }

      @media ${stylevars.breakpoints.phone} {
        margin: 0;
      }
    }
  }
`