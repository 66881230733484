import React from 'react'
import cn from 'classnames'
// import {
//   getMaterialTypeThumnail
// } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getGalleryState } from '@Slices/gallerySlice'
import vendorSlice, { getVendorState } from '@Slices/vendorSlice'
import cartSlice, { getCartState } from '@Slices/cartSlice'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Header, Item } from 'semantic-ui-react'

const ViewerOptionsFrames = (props) => {
  const {
    selectedType,
    selectedFrame,
    handleSelectFrame
  } = props

  const { gallerySizeTypes, galleryMaterials, gallerySizes } = useSelector(getGalleryState) // eslint-disable-line
  const { viewerData, viewerFetching, viewerIsSlider, viewerImageDone, currency } = useSelector(getUIState) // eslint-disable-line

  return (
    <>
      
      <div className='viewerOptionGroup'>
        <Header as='h3' className='viewerHeader selectFrameHeader'>
          {selectedType.acf.frame_options_label}
          {selectedType.slug === 'framed' &&
            <Header.Subheader>High quality polystyrene or alloy materials</Header.Subheader>
          }
          {selectedType.slug === 'framed-canvas' &&
            <Header.Subheader>High quality polystyrene materials</Header.Subheader>
          }
        </Header>

        <Item.Group
          size='large'
          className='selectSize'
          link
        >
        {selectedType.acf.frame_options.map(frm => (
          <Item
            key={frm.slug}
            name={frm.slug}
            className={cn({
              'active': selectedFrame && Number(selectedFrame.term_id) === Number(frm.term_id) ? true : false
            })}
            onClick={() => handleSelectFrame(frm)}
          >
            <Item.Content>
              <Item.Header>{frm.name}</Item.Header>
            </Item.Content>
          </Item>
        ))}
        </Item.Group>
      </div>
    </>
  )
}

export default React.memo(ViewerOptionsFrames)
