import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import cn from "classnames";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import {
  Grid,
  // Button,
  Dimmer,
  Loader,
  Image,
  Button,
} from "semantic-ui-react";
import {
  FeaturedUsers,
  TrendingUsers,
  PopularItems,
  NewCollections,
  NewCreators,
} from "./Components";

// STYLES
import { StyledContainer } from "./DiscoverStyles";
// Import Swiper styles
// import 'swiper/swiper.scss';

const DiscoverPage = () => {
  const dispatch = useDispatch();
  const { siteSettings } = useSelector(getUIState);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate("store"));
    dispatch(uiSlice.actions.setCurrentPage("discover"));
    dispatch(uiSlice.actions.setCurrentPageURL());
    dispatch(uiSlice.actions.setCurrentPageTitle());
    // const token = localStorage.Authorization
    setReady(true);
  }, []); // eslint-disable-line

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Discover - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}/discover`}
        />
      </Helmet>
      {ready ? (
        <>
          {/* <div className='section header'>
            <div className='section_wrapper'>
              <h1>Discover StyleFolio Creators</h1>
            </div>
          </div> */}
          <FeaturedUsers />
          <TrendingUsers limit={9} />
          <PopularItems limit={20} />
          <NewCollections limit={20} />
          <NewCreators limit={9} />
          <div className={cn("viewAllCreators")}>
            <div className={cn("wrapper")}>
              <Button as={Link} to="/creators" size="big" color="blue">
                View All Creators
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>Loading...</>
      )}
    </StyledContainer>
  );
};

export default React.memo(DiscoverPage);
