import React from 'react'
import cn from 'classnames'
import axios from 'axios'
import seedrandom from 'seedrandom'
import { consoleLogger } from '@Utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
// import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Button, Dimmer, Grid, Loader } from 'semantic-ui-react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import Slider, { Range } from 'rc-slider' // eslint-disable-line

// STYLES
import { MediaUploadContainer } from './MediaUploadStyles'
const sliderWrapperStyle = {
  width: '100%',
  maxWidth: '220px',
  margin: 'auto',
  paddingTop: '1rem',
}

const MediaUpload = (props) => {
  const {
    className,
    type,
    defaultImage,
    width,
    height,
    handleChange
  } = props

  const dropzoneRef = React.useRef()
  const editorRef = React.useRef()
  let editor = ''
  const setEditorRef = (el) => {
    editor = el
  }

  const dImage = defaultImage
  const [ imageScale, setImageScale ] = React.useState(1)
  const handleSizeChange = (value) => {
    const adjusted = value + 100
    consoleLogger(adjusted)
    setImageScale((adjusted / 100))
  }
  const [ image, setImage ] = React.useState()
  const [ isDragging, setIsDragging ] = React.useState(false)
  const [ hasDropped, setHasDropped ] = React.useState(false)
  const [ adjustedWidth, setAdjustedWidth ] = React.useState(width)
  const [ submitting, setSubmitting ] = React.useState(false)
  const [ canvas, setCanvas ] = React.useState()
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    noClick: hasDropped,
    noKeyboard: hasDropped,
    onDrop: acceptedFiles => {
      consoleLogger('acceptedFiles', acceptedFiles.length)
      if (typeof acceptedFiles[0] !== 'undefined') {
        setImage(acceptedFiles[0]);
        setHasDropped(true)
      }
      setIsDragging(false)
    },
    onDragOver: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    }
  });

  React.useEffect(() => {
    if (image && hasDropped && editor) {
      consoleLogger('============== EDITOR UPDATE ==============', editor)
      handleChange({
        type: 'upload',
        data: editor
      })
    }
    return(() => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (image) {
        URL.revokeObjectURL(image.preview)
      }
    })
  }, [image, hasDropped, editor]) // eslint-disable-line
  
  React.useEffect(() => {
    const adjustWidthOnResize = () => {
      consoleLogger(dropzoneRef.current.offsetWidth - 32)
      setAdjustedWidth(dropzoneRef.current.offsetWidth - 32)
    }
    if (dropzoneRef.current) {
      adjustWidthOnResize()
      window.addEventListener('resize', adjustWidthOnResize)
    }

    return(() => {
      setImageScale(1)
      window.removeEventListener('resize', adjustWidthOnResize)
    })
  }, [dropzoneRef]) // eslint-disable-line

  React.useEffect(() => {
    setImageScale(1)
    setHasDropped(false)
  }, []) // eslint-disable-line

  return (
    <MediaUploadContainer>
      <div className='uploader_image'>
        <Grid textAlign='center'>
          <Grid.Row>
            <Grid.Column className='uploader_column'>
              <div className='uploader_note'>
                {!hasDropped ?
                  <p><span className='text--bold'>Drag-and-Drop</span> your image or <span className='text--bold'>Click</span> on the box below</p> :
                  <>
                    <p>To replace the image, <span className='text--bold'>Drag-and-Drop</span> your image on the box below</p>
                    <p>Try dragging the image around the box to reposition your {type === 'profile' ? `profile` : `cover`} photo</p>
                  </>
                }
              </div>

              <div {...getRootProps()}
                ref={dropzoneRef}
                className={cn('uploaderDropzone', {
                  'dragging': isDragging,
                  'hasDropped': hasDropped
                })}
              >
                <input {...getInputProps()} />
                <div className='uploaderDropzone_indicator'>You can drop anywhere inside this box...</div>
                {hasDropped ?
                  <AvatarEditor
                    width={adjustedWidth}
                    height={height}
                    image={image}
                    scale={imageScale}
                    border={0}
                    borderRadius={0}
                    ref={setEditorRef}
                  /> :
                  <LazyLoadImage
                    wrapperClassName='defaultImage'
                    // afterLoad={(ev) => {
                    //   handleAfterLoad(ev)
                    // }}
                    effect="blur"
                    // alt={alt}
                    src={dImage}
                  />
                }
              </div>

              {hasDropped &&
                <div style={sliderWrapperStyle}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <p>Zoom</p>
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Slider defaultValue='0' startPoint={0} onChange={handleSizeChange} min={0} max={200} style={{marginTop: '0.2rem'}} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </MediaUploadContainer>
  )
}

export default MediaUpload
