import React from 'react'
import Helmet from 'react-helmet'

// REDUX
import { useDispatch } from 'react-redux'
import uiSlice from '@Slices/uiSlice'

// STYLES
import { StyledContainer } from './Styles'

const CreatorGuidePage = () => {
  const dispatch = useDispatch()
  const [ ready, setReady ] = React.useState(false)
  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('creator guide'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
    // const token = localStorage.Authorization
    setReady(true)
  }, []) // eslint-disable-line

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Creator Guide</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/creator-guide`} />
      </Helmet>
      <div className='container'>
        { ready ?
          <>
            <h1>Creator Guide</h1>
            <p>Coming soon...</p>
          </> : 
          <>Loading...</>
        }
      </div>
    </StyledContainer>
  )
}

export default React.memo(CreatorGuidePage)
