import React from 'react'
import { Link } from 'react-router-dom'
import { orderBy } from 'lodash'
// import cn from 'classnames'
// import axios from 'axios'
import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
import { consoleLogger, maskEmail } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Dimmer, Icon, Label, Loader, Segment } from 'semantic-ui-react'

import shortNumber from 'short-number'

const WidgetPageviews = (props) => {
  const { length } = props
  const token = localStorage.Authorization
  const { userInfo, userGallery } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  const [ galleryItems, setGalleryItems ] = React.useState()
  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    (async() => {
      const sortedGallery = orderBy(userGallery, ['acf.pageviews'], ['desc'])
      setGalleryItems(sortedGallery.slice(0, length))
    })()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("galleryItems ================", galleryItems)
    if (galleryItems) {
      setReady(true)
    }
  }, [galleryItems])

  return (
    <div className='widget popular'>
      {!ready ?
        <Dimmer inverted active>
          <Loader>Updating...</Loader>
        </Dimmer> : 
        <div className='widgetWrapper'>
          <div className='label'>Popular Gallery Item</div>
          <div className='content'>
            <div className='dashboardWidgets col3'>
              {galleryItems.map((item, index) => (
                <Segment basic padded={false} className='galleryItem' key={item.ID}>
                  <Label ribbon color={`blue`}>{index + 1}</Label>
                  <div className='pageviews'>
                    <Icon name='eye' color='white' />
                    <span>{shortNumber(item.acf.pageviews)}</span>
                  </div>
                  <Link to={`/@${userInfo.username}/gallery/${item.ID}`}>
                    <img src={item.media.hd} alt='' />
                  </Link>
                </Segment>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default React.memo(WidgetPageviews)
