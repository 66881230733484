import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  .resultLabel {
    font-size: 1.5rem;
    margin: 1rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${stylevars.paper.borderColor};
  }
  .resultGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(220px, 1fr));
    grid-gap: 2rem;

    @media ${stylevars.breakpoints.phone} {
      grid-template-columns: repeat(1, 1fr);
    }

    .item {
      box-shadow: none !important;
    }
  }
`