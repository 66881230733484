import React from 'react';
import cn from 'classnames';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { consoleLogger, setAuthorizationHeader } from '@Utils';
import jwtDecode from 'jwt-decode';
// import { Helmet } from 'react-helmet'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { getUserState } from '@Slices/userSlice';
import uiSlice, { getUIState } from '@Slices/uiSlice';

// COMPONENTS
import {
  Grid,
  Button,
  Form as SUIForm,
  Message,
  Popup,
  Icon,
  Label,
} from 'semantic-ui-react';
import { FormBox, StyledCard } from './Styles';
import SignupPackageTable from './SignupPackageTable';

// VALIDATION SCHEMA
Yup.addMethod(Yup.string, 'nospecial', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    value = originalValue.matches(/[^a-zA-Z0-9]/);

    consoleLogger(originalValue, value);
    return value ? value.toString() : '';
  });
});
const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Must be at least 2 characters long')
    .max(64, 'Must be shorter than 64 characters')
    .required('Must enter a first name'),
  lastname: Yup.string()
    .min(2, 'Must be at least 2 characters long')
    .max(64, 'Must be shorter than 64 characters')
    .required('Must enter a last name'),
  username: Yup.string()
    .min(5, 'Must be at least 5 characters long')
    .max(16, 'Must be shorter than 16 characters')
    .test('nospecial', 'Special characters are not allowed', (val) => {
      const pattern = /^[a-zA-Z0-9]+$/;
      return pattern.test(val);
    })
    .required('Must enter a username'),
  email: Yup.string().email('Invalid email').required('Must enter an email'),
  password: Yup.string()
    .min(6, 'Must be at least 6 characters long')
    .max(255, 'Must be shorter than 255 characters')
    .required('Must enter a password'),
  password2: Yup.string()
    .min(6, 'Must be at least 6 characters long')
    .max(255, 'Must be shorter than 255 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Must re-type password'),
  biography: Yup.string(),
  agree: Yup.boolean().oneOf(
    [true],
    'You need to agree to the terms and conditions.',
  ),
});

const SignupForm = (props) => {
  const { className, to, customer, ...restProps } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated } = useSelector(getUserState);
  const { siteSettings } = useSelector(getUIState);
  const { path } = useParams();
  const decodedPath = path ? decodeURIComponent(path) : '';
  const [loginLink, setLoginLink] = React.useState('/login');

  const [selectPayment, setSelectPayment] = React.useState(false);
  const [paypalAmount, setPaypalAmount] = React.useState(0);
  const [paypalSubscriptionID, setPaypalSubscriptionID] = React.useState();
  const [formData, setFormData] = React.useState();
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const [showPass, setShowPass] = React.useState(false);
  const handleShowPass = () => {
    setShowPass((prev) => !prev);
  };

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'));
    dispatch(uiSlice.actions.setCurrentPage('signup'));
    dispatch(uiSlice.actions.setCurrentPageURL());
    dispatch(uiSlice.actions.setCurrentPageTitle());
    consoleLogger('decodedPath ================', decodedPath);

    if (customer) {
      setLoginLink((state) => `${state}/collector`);
    }
    if (path) {
      setLoginLink((state) => `${state}/redirect/${path}`);
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('SIGNUP PAGE');
    // if (authenticated) {
    //   history.push('/admin/account')
    // }
  }, [authenticated, history]); // eslint-disable-line

  const handleDifferentLink = (ev) => {
    ev.preventDefault();
    if (customer) {
      history.push('/signup');
    } else {
      history.push('/signup/collector');
    }
  };

  return (
    <FormBox
      className={cn('formBoxContainer', {
        [className]: className,
      })}
    >
      <Formik
        initialValues={{
          username: '',
          password: '',
          email: '',
          firstname: '',
          lastname: '',
          role: 'sf_creator',
          biography: '',
          agree: false,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(data, { setErrors, setSubmitting }) => {
          (async () => {
            // const newData = data
            // newData.jwt_auth_token_before_dispatch =
            setSubmitting(true);
            let token;
            let error = 0;
            let userId;
            let registrationKey;
            const signupData = data;
            consoleLogger(
              'SIGNUP DATA =============================',
              signupData,
            );

            let redirectPath = `/login`;
            let promoPostId;
            let canRegister = true;
            let validPromo = false;
            let skipPayment = false;

            // VALIDATE PROMO CODE
            if (siteSettings.enableSubscriptionPromo) {
              if (signupData.promocode) {
                // VALIDATE PROMO CODE
                await axios({
                  baseURL: `${process.env.REACT_APP_API_URL}`,
                  url: `/stylefolio/v1/promo/validate`,
                  // headers: {
                  //   Authorization: `Bearer ${adminToken}`,
                  // },
                  method: 'POST',
                  data: signupData,
                })
                  .then((res) => {
                    promoPostId = res?.data?.post_id;
                    validPromo = res?.data?.active;
                    skipPayment = true;
                  })
                  .catch((err) => {
                    const errorData = err.response.data;
                    canRegister = false;
                    consoleLogger(err);
                    if (
                      errorData.code === 'promo_code_not_found' ||
                      errorData.code === 'promo_code_invalid'
                    ) {
                      setErrors({ promocode: 'Invalid promo code' });
                    }
                  });
              }
            }

            if (canRegister) {
              // REGISTER NEW USER
              await axios({
                baseURL: `${process.env.REACT_APP_API_URL}`,
                url: `/stylefolio/v1/users`,
                method: 'PUT',
                data: signupData,
              })
                .then((res) => {
                  consoleLogger(
                    'RETURN DATA - SIGNUP USER INFO ========================',
                    res.data,
                  );
                  // const userInfo = res.data
                  // dispatch(userSlice.actions.setUserInfo({...userInfo}))
                  registrationKey = res.data.acf.registration_key;
                  userId = res.data.id;
                  if (!skipPayment) {
                    redirectPath = `/signup/payment/${userId}/${registrationKey}`;
                  }
                })
                .catch((err) => {
                  error += 1;
                  const errorData = err.response.data;
                  consoleLogger('ERROR: ', errorData);
                  dispatch(userSlice.actions.setUserInfo(''));
                  dispatch(userSlice.actions.setAuthenticated(false));
                  if (errorData.code === 'existing_user_login') {
                    setErrors({ username: 'This username is already taken.' });
                  } else if (errorData.code === 'existing_user_email') {
                    setErrors({ email: 'This email is already taken.' });
                  } else {
                    setErrors(errorData);
                  }
                });

              if (error === 0) {
                await axios({
                  baseURL: `${process.env.REACT_APP_API_URL}`,
                  url: `/jwt-auth/v1/token`,
                  method: 'post',
                  data: {
                    username: signupData.username,
                    password: signupData.password,
                  },
                })
                  .then((res) => {
                    const decodedToken = jwtDecode(res.data.token);
                    consoleLogger(
                      'JWT AUTH RESPONSE =====================',
                      res.data,
                      decodedToken,
                    );
                    token = res.data.token;
                  })
                  .catch((err) => {
                    consoleLogger(err);
                  });

                setAuthorizationHeader(token);

                if (validPromo && skipPayment) {
                  await axios({
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    url: `/stylefolio/v1/promo/apply`,
                    method: 'post',
                    data: {
                      ...signupData,
                      promoPostId,
                      userId,
                      registrationKey,
                    },
                  })
                    .then((res) => {})
                    .catch((err) => {
                      consoleLogger(err);
                    });
                }
              }
              history.push(redirectPath);
            }
            setSubmitting(false);
          })();
        }}
      >
        {({
          isSubmitting,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          setValues,
          setFieldValue,
          errors,
          isValid,
          dirty,
          values,
        }) => (
          <Form className="ui form">
            {/* <input type='hidden' name='roles[]' value='author' /> */}
            <Grid
              centered
              padded
            >
              <Grid.Column className="formBox">
                <h1>Creator Signup</h1>
                <p className="notCreatorCustomer">
                  Not a creator but a collector?{' '}
                  <Link to="/signup/collector">Signup here</Link>.
                </p>
                <StyledCard>
                  <StyledCard.Content>
                    <SUIForm.Input
                      name="firstname"
                      fluid
                      placeholder="First Name"
                      type="text"
                      label="First Name *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.firstname}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input
                      name="lastname"
                      fluid
                      placeholder="Last Name"
                      type="text"
                      label="Last Name *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.lastname}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input
                      name="username"
                      fluid
                      placeholder="Username"
                      type="text"
                      label="Username *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.username}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input
                      name="email"
                      fluid
                      placeholder="Email Address"
                      type="email"
                      label="Email Address *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input
                      name="password"
                      fluid
                      iconPosition="right"
                      placeholder="Password"
                      type={showPass ? 'text' : 'password'}
                      label="Password *"
                      icon={
                        <Icon
                          name={showPass ? 'eye slash' : 'eye'}
                          link
                          onClick={handleShowPass}
                        />
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password}
                      disabled={isSubmitting}
                    />
                    <SUIForm.Input
                      name="password2"
                      fluid
                      placeholder="Re-type Password"
                      type="password"
                      label="Re-type Password *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password2}
                      disabled={isSubmitting}
                    />
                    <SUIForm.TextArea
                      name="biography"
                      rows={5}
                      fluid
                      placeholder="Loves dogs, Movie buff, Sci-Fi geek, Photography is life!..."
                      label="Tell us a little about yourself (optional)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.biography}
                      disabled={isSubmitting}
                    />
                    {siteSettings && siteSettings.enableSubscriptionPromo && (
                      <SUIForm.Input
                        name="promocode"
                        fluid
                        placeholder="Promo Code"
                        type="text"
                        label="Promo Code (optional)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.promocode}
                        disabled={isSubmitting}
                      />
                    )}
                    <SUIForm.Field
                      name="agree"
                      error={errors.agree}
                      disabled={isSubmitting}
                    >
                      <label
                        htmlFor="agreeCheckbox"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: 0,
                          lineHeight: 1.6,
                        }}
                      >
                        <input
                          id="agreeCheckbox"
                          type="checkbox"
                          name="agree"
                          value="true"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span style={{ marginLeft: '1rem' }}>
                          I agree to the{' '}
                          <a
                            href="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            terms and conditions
                          </a>
                        </span>
                      </label>
                      {!!errors.agree && (
                        <Label
                          basic
                          color="red"
                          pointing
                        >
                          {errors.agree}
                        </Label>
                      )}
                    </SUIForm.Field>
                  </StyledCard.Content>
                  <StyledCard.Content extra>
                    <div className="ui two buttons">
                      <>
                        <Button
                          size="large"
                          type="submit"
                          positive
                          onClick={() => handleSubmit()}
                          loading={isSubmitting}
                          // disabled={!(dirty && isValid && !isSubmitting)}
                          disabled={!(dirty && !isSubmitting)}
                        >
                          Continue
                        </Button>
                      </>
                    </div>
                  </StyledCard.Content>
                  <StyledCard.Content
                    extra
                    className="subCard"
                  >
                    <p>
                      Already have an account?{' '}
                      <Link to={loginLink}>Login now!</Link>
                    </p>
                  </StyledCard.Content>
                </StyledCard>
                {errors && errors.message && (
                  <Message negative>{errors.message}</Message>
                )}
              </Grid.Column>
            </Grid>
          </Form>
        )}
      </Formik>
    </FormBox>
  );
};

export default React.memo(SignupForm);
