import React from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {Root} from './Styles'
import SignupForm from './SignupForm'

const SignupPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authenticated } = useSelector(getUserState)

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('signup'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('SIGNUP PAGE')
    // if (authenticated) {
    //   history.push('/admin/account')
    // }
  }, [authenticated, history]) // eslint-disable-line

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Creator Signup - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/signup/creator`} />
      </Helmet>
      <SignupForm />
    </Root>
  )
}

export default React.memo(SignupPage)
