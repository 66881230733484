import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CollectionsContainer = styled('div')`
  padding: 2rem;
  width: 100%;
  min-height: 24px;
  position: relative;

  @media ${stylevars.breakpoints.phone} {
    padding: 1rem;
  }
`