import React from 'react'
import { Link, useLocation } from 'react-router-dom' // eslint-disable-line

// Redux Toolkit
import { useSelector, useDispatch } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Header, List, Icon, Image } from 'semantic-ui-react'
import StyledSideBar from './SideBarStyles'

const SideBar = () => {
  // const location = useLocation() 
  const dispatch = useDispatch()
  const { authenticated, userInfo } = useSelector(getUserState)
  const { sideBarShow, currentPage, pageTemplate } = useSelector(getUIState)
  const handleSideBarToggle = () => {
    dispatch(uiSlice.actions.setSideBarShow(false))
  }

  // React.useEffect(() => {
  //   dispatch(uiSlice.actions.setCurrentPage(location.pathname.split('/')[2]))
  //   if (location.pathname.split('/')[3] !== 'page') {
  //     dispatch(uiSlice.actions.setCurrentPageTitle(location.pathname.split('/')[3]))
  //   }

  //   return(() => {
  //     dispatch(uiSlice.actions.setCurrentPageTitle())
  //   })
  // }, [dispatch, location])

  return (
    <>
      {pageTemplate === 'dash' && authenticated &&
          <StyledSideBar className={sideBarShow ? 'show' : ''}
            onClick={handleSideBarToggle}
          >
            <div className='sideBarWrapper'>
              <Header>
                <Link to='/'>
                  <Image src='/logo_dark.svg' />
                </Link>
              </Header>
              <List>

                {userInfo.role === 'sf_creator' &&
                  <>
                    <List.Item as={Link} to='/admin/dashboard'
                        name='dashboard'
                        active={currentPage.indexOf('dashboard') > -1}
                      >
                      <Icon name='dashboard' />
                      <List.Header>Dashboard</List.Header>
                    </List.Item>
                    <List.Item as={Link} to='/admin/orders'
                      name='gallery'
                      active={currentPage.indexOf('orders') > -1}
                    >
                      <Icon name='shopping bag' />
                      <List.Header>Orders</List.Header>
                    </List.Item>
                    <List.Item as={Link} to='/admin/gallery'
                      name='gallery'
                      active={currentPage.indexOf('gallery') > -1}
                    >
                      <Icon name='images' />
                      <List.Header>Gallery</List.Header>
                    </List.Item>
                    <List.Item as={Link} to='/admin/collections'
                      name='collections'
                      active={currentPage.indexOf('collections') > -1}
                    >
                      <Icon name='book' />
                      <List.Header>Collections</List.Header>
                    </List.Item>
                    <List.Item as={Link} to='/admin/layout'
                      name='layout'
                      active={currentPage.indexOf('layout') > -1}
                    >
                      <Icon name='address card outline' />
                      <List.Header>Layout</List.Header>
                    </List.Item>
                  </>
                }
                {userInfo.role === 'sf_customer' &&
                  <>
                    <List.Item as={Link} to='/admin/orders'
                      name='gallery'
                      active={currentPage.indexOf('orders') > -1}
                    >
                      <Icon name='shopping bag' />
                      <List.Header>Orders</List.Header>
                    </List.Item>
                  </>
                }

                <List.Item as={Link} to='/admin/account'
                  name='account'
                  active={currentPage.indexOf('account') > -1}
                >
                  <Icon name='user' />
                  <List.Header>Account</List.Header>
                </List.Item>
              </List>
            </div>
          </StyledSideBar>

        }
    </>
  )
}

export default React.memo(SideBar)
