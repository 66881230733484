import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled.div`
  .certificateWrapper {
    width: 100%;
    height: calc(1200px + 5rem);
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
  }

  .buttonGroup {
    width: 100%;
    text-align: center;
    background-color: rgba(0,0,0,0.15);
    padding: 1rem;
  }
`

export const ModalContainer = styled.div`
  .sigCanvas {
    border: 1px solid rgba(0,0,0,0.5);
    cursor: url('/images/pen.png'), default;
  }
`