import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
// import { stylevars } from '@Common/StyleVars'
import { consoleLogger, setAuthorizationHeader, useSiteCurrency } from "@Utils";
import { sampleForexData } from "@Utils/forexData";
import cn from "classnames";
// import debounce from 'lodash/debounce'
// import useScrollPosition from '@react-hook/window-scroll'
// import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import slugify from "slugify";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import { getVendorState } from "@Slices/vendorSlice";
import cartSlice from "@Slices/cartSlice";

// COMPONENTS
import NavBar from "@Common/NavBar";
import SideBar from "@Common/SideBar";
// import Toast from '@Common/Toast'
import AppRouter from "@Routers/AppRouter";
import WPSettings from "@Redux/WPSettings";
// import CartCheck from '@Routers/CartCheck'
import { GeneralPreloader } from "@Common";
import { ToastContainer } from "react-toastify";

// STYLES
import { stylevars } from "@Common/StyleVars";

import { themeColors } from "@AdminPages/Layout/ColorTemplates";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentPathname = location.pathname;
  const { authenticated, userInfo, userKey } = useSelector(getUserState);
  const {
    pageScrolled,
    pageTemplate,
    currentPage,
    uiReady,
    siteSettings,
    currency,
    forexData,
  } = useSelector(getUIState);
  const { vendorInfo } = useSelector(getVendorState);
  const [isReady, setIsReady] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);

  const rootWrapperRef = React.useRef();
  const [selectedCurrency, setSelectedCurrency] = useSiteCurrency();

  // const paypalInitialOptions = {
  //   "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  //   "currency": "USD",
  //   // "data-client-token": process.env.REACT_APP_PAYPAL_ACCESS_TOKEN,
  //   "data-client-token": process.env.REACT_APP_PAYPAL_SECRET,
  // }

  React.useEffect(() => {
    (async () => {
      let redirect;
      let userInfo;
      let valid = true;
      // If token is present, validate it
      const token = localStorage.Authorization;
      if (token && token.length > 0) {
        const decodedToken = jwtDecode(token);
        consoleLogger("DECODED TOKEN ================ ", decodedToken);
        const exp = decodedToken.exp * 1000;
        if (Date.now() > exp) {
          consoleLogger("token has expired...");
          dispatch(userSlice.actions.setAuthenticated(false));
          localStorage.removeItem("Authorization");
          setIsReady(true);
        } else {
          // validate token
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/jwt-auth/v1/token/validate`,
            headers: {
              Authorization: token,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
          })
            .then((res) => {
              consoleLogger(
                "TOKEN VALIDATION RESPONSE =============",
                res.data
              );
              setAuthorizationHeader(token.split(" ")[1]);
            })
            .catch((err) => {
              consoleLogger("ERROR ===========", err);
              dispatch(userSlice.actions.setAuthenticated(false));
              dispatch(userSlice.actions.setUserKey(""));
              dispatch(userSlice.actions.setUserInfo({}));
            });
        }

        // FETCH USER INFO
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/getuser`,
          headers: {
            Authorization: token,
          },
          method: "GET",
          params: {
            author: decodedToken.data.user.id,
            user_key: decodedToken.data.user_key,
          },
        })
          .then((res) => {
            userInfo = res.data;
            consoleLogger("USER INFO RESPONSE ================", res.data);

            // CHECK IF CREATOR IS SUBSCRIBED OR CUSTOMER IS VERIFIED

            const userRole = res.data.role;
            // IF CUSTOMER
            if (userRole === "sf_customer") {
              // CHECK IF VERIFIED
              if (!res.data.acf.verified) {
                valid = false;
                redirect = `/login/verify/${res.data.ID}/${res.data.acf.registration_key}`;
              }
            }
            // IF CREATOR
            if (userRole === "sf_creator") {
              // CHECK IF SUBSCRIPTION IS PAID
              if (!res.data.acf.subscription_paid) {
                valid = false;
                redirect = `/signup/payment/${res.data.ID}/${res.data.acf.registration_key}`;
              }
              // CHECK IF SUBSCRIPTION IS NOT EXPIRED
              if (res.data.acf.subscription_expired) {
                valid = false;
                redirect = `/signup/payment/${res.data.ID}/${res.data.acf.registration_key}`;
              }
            }

            // CHECK ACCOUNT COMPLETION - PROFILE & COVER PHOTOS
            dispatch(
              userSlice.actions.updateAccountCompletion({
                hasProfilePhoto: !!res.data.acf.profile_picture,
                hasCoverPhoto: !!res.data.acf.background_image,
              })
            );
            // if creator is premium, check if signature is already present
            dispatch(
              userSlice.actions.updateAccountCompletion({
                hasSignature: !!(
                  res.data.acf.subscription_type === "premium" &&
                  res.data.acf.signature_image
                ),
              })
            );
          })
          .catch((err) => {
            consoleLogger("ERROR ===========", err);
            dispatch(userSlice.actions.setAuthenticated(false));
          });

        if (valid) {
          dispatch(userSlice.actions.setUserInfo(userInfo));
          dispatch(userSlice.actions.setAuthenticated(true));
          dispatch(userSlice.actions.setUserKey(decodedToken.data.user_key));
          // FETCH USER COUPONS
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/coupons`,
            method: "GET",
            params: {
              per_page: -1,
              author: decodedToken.data.user.id,
              orderby: "date_modified",
              order: "desc",
              status: ["publish", "pending"],
            },
          })
            .then((res) => {
              consoleLogger(
                "FETCHING USER COUPONS ========================",
                decodedToken.data.user.id,
                res.data
              );
              dispatch(userSlice.actions.setUserCoupons(res.data));
              dispatch(
                uiSlice.actions.updateUITables({
                  component: "user-coupons",
                  totalItems: res.data.length,
                })
              );
              // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', total: parseInt(res.headers['x-wp-total']), pages: parseInt(res.headers['x-wp-totalpages'])}))
            })
            .catch((err) => {
              consoleLogger("ERROR ===========", err);
              dispatch(userSlice.actions.setAuthenticated(false));
            });

          // FETCH USER GALLERY
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/gallery`,
            method: "GET",
            params: {
              author: decodedToken.data.user.id,
              user_key: decodedToken.data.user_key,
            },
          })
            .then((res) => {
              consoleLogger(
                "FETCHING USER GALLERY RESPONSE =============",
                decodedToken.data.user.id,
                res.data
              );
              // setTotalPages(Number(res.headers['x-wp-totalpages']))
              dispatch(userSlice.actions.setUserGallery(res.data));
              dispatch(
                userSlice.actions.setUserGalleryPages(
                  res.headers["x-wp-totalpages"]
                )
              );
              dispatch(
                userSlice.actions.setUserGalleryTotal(res.headers["x-wp-total"])
              );
            })
            .catch((err) => {
              consoleLogger(err);
              // setLoadPage(false)
              // setIsFetching(false)
            });

          // FETCH USER COLLECTIONS
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/collections`,
            method: "GET",
            params: {
              author: decodedToken.data.user.id,
              user_key: decodedToken.data.user_key,
            },
          })
            .then((res) => {
              consoleLogger(
                "FETCHING USER COLLECTIONS RESPONSE =============",
                decodedToken.data.user.id,
                res.data
              );
              dispatch(userSlice.actions.setCollections(res.data));
            })
            .catch((err) => {
              consoleLogger(err);
            });

          // FETCH USER BILLING ADDRESSES
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/address`,
            method: "GET",
            params: {
              author: decodedToken.data.user.id,
              user_key: decodedToken.data.user_key,
            },
          })
            .then((res) => {
              consoleLogger(
                "FETCHING USER BILLING ADDRESSES ========================",
                res.data
              );
              dispatch(userSlice.actions.setUserAddresses(res.data));
              // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', totalItems: res.data.length}))
              // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', total: parseInt(res.headers['x-wp-total']), pages: parseInt(res.headers['x-wp-totalpages'])}))

              // CHECK ACCOUNT COMPLETION - ADDRESS
              dispatch(
                userSlice.actions.updateAccountCompletion({
                  hasAddress: res.data.length > 0,
                })
              );
            })
            .catch((err) => {
              consoleLogger("FETCH USER BILLING ADDRESSES ERROR: ", err);
              // setErrors(err.response.data)
            });

          // FETCH USER BILLING ADDRESSES
          await axios({
            baseURL: `${process.env.REACT_APP_API_URL}`,
            url: `/stylefolio/v1/address`,
            method: "GET",
            params: {
              author: decodedToken.data.user.id,
              user_key: decodedToken.data.user_key,
            },
          })
            .then((res) => {
              consoleLogger(
                "FETCHING USER BILLING ADDRESSES ========================",
                res.data
              );
              dispatch(userSlice.actions.setUserAddresses(res.data));
              // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', totalItems: res.data.length}))
              // dispatch(uiSlice.actions.updateUITables({component: 'user-coupons', total: parseInt(res.headers['x-wp-total']), pages: parseInt(res.headers['x-wp-totalpages'])}))
            })
            .catch((err) => {
              consoleLogger("FETCH USER BILLING ADDRESSES ERROR: ", err);
              // setErrors(err.response.data)
            });
        } else {
          dispatch(userSlice.actions.setAuthenticated(false));
          dispatch(userSlice.actions.setUserKey(""));
          dispatch(userSlice.actions.setUserInfo({}));
          history.push(redirect);
        }
      }

      // PROCESS CART
      // dispatch(cartSlice.actions.setCart())

      // FETCH SITE SETTINGS
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/settings`,
        method: "GET",
      })
        .then((res) => {
          // consoleLogger("MATERIALS RESPONSE ===========", res.data)
          dispatch(uiSlice.actions.setSiteSettings(res.data));
        })
        .catch((err) => {
          consoleLogger(err);
        });

      // setIsValid(valid)
      setIsReady(true);
    })();

    return () => {
      // window.removeEventListener('scroll', windowScroll)
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (siteSettings && siteSettings.baseCurrency) {
        // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //   consoleLogger(sampleForexData)
        //   dispatch(uiSlice.actions.setForexData(sampleForexData))
        // } else {
        //   // FETCH FOREX DATA
        //   await axios({
        //     baseURL: `${process.env.REACT_APP_FOREX_API_URL}`,
        //     url: `/latest.json`,
        //     method: 'GET',
        //     params: {
        //       base: siteSettings.baseCurrency.currency,
        //       app_id: process.env.REACT_APP_FOREX_API_KEY
        //     },
        //   }).then(res => {
        //     consoleLogger('=================== CURRENCY RATE RESPONSE', res.data.rates)
        //     dispatch(uiSlice.actions.setForexData(res.data.rates))
        //   }).catch(err => {
        //     consoleLogger(err)
        //   })
        // }
        if (siteSettings.forexData) {
          dispatch(
            uiSlice.actions.setForexData(
              siteSettings.forexData.conversion_rates
            )
          );
        } else {
          dispatch(uiSlice.actions.setForexData(sampleForexData));
        }
      }
    })();
  }, [siteSettings, authenticated, userInfo]); // eslint-disable-line

  React.useEffect(() => {
    if (forexData) {
      let crncy = selectedCurrency.currency;
      // if (authenticated) {
      //   if (userInfo && userInfo.role === 'sf_creator') {
      //     crncy = 'USD'
      //   }
      // }
      dispatch(
        uiSlice.actions.updateCurrency({
          ...selectedCurrency,
          value:
            crncy === "USD"
              ? 1
              : Number(forexData[crncy]) +
                Number(process.env.REACT_APP_FOREX_API_ADJUSTMENT),
        })
      );
      setSelectedCurrency((prev) => ({
        ...prev,
        value:
          crncy === "USD"
            ? 1
            : Number(forexData[crncy]) +
              Number(process.env.REACT_APP_FOREX_API_ADJUSTMENT),
      }));
    }
  }, [forexData]); // eslint-disable-line

  // React.useEffect(() => {
  //   // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  //   if (rootWrapperRef.current) {
  //     const wrapperObserver = () => {
  //       const vh = window.innerHeight * 0.01;
  //       const vw = window.innerWidth * 0.01;
  //       // Then we set the value in the --vh custom property to the root of the document
  //       document.documentElement.style.setProperty('--vh', `${vh}px`);
  //       document.documentElement.style.setProperty('--vw', `${vw}px`);
  //     }

  //     window.addEventListener('resize', wrapperObserver)
  //   }

  //   return (() => {
  //     window.removeEventListener('resize', wrapperObserver)
  //   })
  // }, [rootWrapperRef]) // eslint-disable-line

  const [classes, setClasses] = React.useState();
  React.useEffect(() => {
    setClasses({
      [pageTemplate]: pageTemplate,
      [slugify(currentPage).toLowerCase()]: currentPage,
      [userInfo ? userInfo.role : ""]: userInfo ? userInfo.role : false,
      pageScrolled,
      [`theme-${vendorInfo ? vendorInfo.acf.layout_style : ""}`]:
        vendorInfo && currentPathname.indexOf("/@") > -1,
      [`user-collections`]:
        vendorInfo &&
        currentPathname.indexOf("/@") > -1 &&
        currentPathname.indexOf("/collections/") > -1,
    });
  }, [
    pageTemplate,
    currentPage,
    userInfo,
    pageScrolled,
    currentPathname,
    vendorInfo,
  ]); // eslint-disable-line

  // VENDOR CUSTOM STYLESHEET
  const [sheets, setSheet] = React.useState();
  React.useEffect(() => {
    setSheet({
      bgColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.bgColor
          ? vendorInfo.acf.custom_stylesheet.bgColor
          : themeColors[0].data.bgColor,
      titleTextColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.titleTextColor
          ? vendorInfo.acf.custom_stylesheet.titleTextColor
          : themeColors[0].data.titleTextColor,
      socialIconColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.socialIconColor
          ? vendorInfo.acf.custom_stylesheet.socialIconColor
          : themeColors[0].data.socialIconColor,
      dividerColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.dividerColor
          ? vendorInfo.acf.custom_stylesheet.dividerColor
          : themeColors[0].data.dividerColor,
      linkTextColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkTextColor
          ? vendorInfo.acf.custom_stylesheet.linkTextColor
          : themeColors[0].data.linkTextColor,
      linkTextColorHover:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkTextColorHover
          ? vendorInfo.acf.custom_stylesheet.linkTextColorHover
          : themeColors[0].data.linkTextColorHover,
      linkTextColorActive:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkTextColorActive
          ? vendorInfo.acf.custom_stylesheet.linkTextColorActive
          : themeColors[0].data.linkTextColorActive,
      linkBGColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkBGColor
          ? vendorInfo.acf.custom_stylesheet.linkBGColor
          : themeColors[0].data.linkBGColor,
      linkBGColorHover:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkBGColorHover
          ? vendorInfo.acf.custom_stylesheet.linkBGColorHover
          : themeColors[0].data.linkBGColorHover,
      linkBGColorActive:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkBGColorActive
          ? vendorInfo.acf.custom_stylesheet.linkBGColorActive
          : themeColors[0].data.linkBGColorActive,
      linkLineColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkLineColor
          ? vendorInfo.acf.custom_stylesheet.linkLineColor
          : themeColors[0].data.linkLineColor,
      linkLineColorHover:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkLineColorHover
          ? vendorInfo.acf.custom_stylesheet.linkLineColorHover
          : themeColors[0].data.linkLineColorHover,
      linkLineColorActive:
        vendorInfo && vendorInfo.acf.custom_stylesheet.linkLineColorActive
          ? vendorInfo.acf.custom_stylesheet.linkLineColorActive
          : themeColors[0].data.linkLineColorActive,
      galleryTitleColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.galleryTitleColor
          ? vendorInfo.acf.custom_stylesheet.galleryTitleColor
          : themeColors[0].data.galleryTitleColor,
      profileTextColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.profileTextColor
          ? vendorInfo.acf.custom_stylesheet.profileTextColor
          : themeColors[0].data.profileTextColor,
      profileBGColor:
        vendorInfo && vendorInfo.acf.custom_stylesheet.profileBGColor
          ? vendorInfo.acf.custom_stylesheet.profileBGColor
          : themeColors[0].data.profileBGColor,
      useProRing:
        vendorInfo &&
        vendorInfo.acf.custom_stylesheet.useProRing &&
        vendorInfo.acf.subscription_type === "premium"
          ? vendorInfo.acf.custom_stylesheet.useProRing
          : false,
    });
  }, [vendorInfo]); // eslint-disable-line

  React.useEffect(() => {
    if (sheets) {
      Object.entries(sheets).forEach(([key, value]) => {
        document.documentElement.style.setProperty(
          `--sfProfile_${key}`,
          `rgba(${value})`
        );
        if (key === "useProRing") {
          if (value === true) {
            document.documentElement.style.setProperty(
              `--sfProfile_useProRing`,
              `linear-gradient(55deg, ${stylevars.palette.secondary.main}, ${stylevars.palette.primary.main})`
            );
          } else {
            document.documentElement.style.setProperty(
              `--sfProfile_useProRing`,
              `rgba(${sheets.bgColor})`
            );
          }
        }
      });
    }
  }, [sheets]); // eslint-disable-line

  return (
    <>
      <div ref={rootWrapperRef} className={cn("rootWrapper", { ...classes })}>
        {isReady ? (
          <>
            <WPSettings />
            {uiReady && (
              <>
                {/* <PayPalScriptProvider deferLoading={true} options={paypalInitialOptions}> */}
                {siteSettings && siteSettings.siteMode === 1 && <NavBar />}
                {authenticated && <SideBar />}
                <AppRouter />
                {/* </PayPalScriptProvider> */}
              </>
            )}
          </>
        ) : (
          <GeneralPreloader />
        )}
      </div>
      {siteSettings && siteSettings.siteMode === 1 && (
        <ToastContainer
          containerId="mainToaster"
          enableMultiContainer={true}
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </>
  );
};

export default React.memo(App);
