import React from 'react'

// REDUX
import { useSelector } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import ThemeItem from './ThemeItem'

const ThemeSelection = (props) => {
  const { modalHandler } = props
  const { userInfo } = useSelector(getUserState)
  const [ themeOptions, setThemeOptions ] = React.useState([
    {
      id: 1,
      key: 'basic',
      name: 'Basic'
    }
  ])
  const [ process, setProcess ] = React.useState(false)
  const handleProcess = (bool) => {
    setProcess(bool)
  }

  React.useEffect(() => {
    if (userInfo.acf.subscription_type === 'premium') {
      setThemeOptions(prev => [
        ...prev,
        {
          id: 2,
          key: 'neat',
          name: 'Neat'
        },
        {
          id: 3,
          key: 'sideburn',
          name: 'Sideburn'
        },
      ])
    }
  }, []) // eslint-disable-line

  return (
    <div className='themeSelection'>
      {process &&
        <Dimmer inverted active>
          <Loader>Updating...</Loader>
        </Dimmer>
      }
      {themeOptions && themeOptions.map(theme => (
        <ThemeItem {...theme} key={theme.key} handleProcess={handleProcess} modalHandler={modalHandler} />
      ))}
    </div>
  )
}

export default React.memo(ThemeSelection)
