import React from 'react'
// import axios from 'axios'
import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  Icon,
  Table,
  // Button,
  // Checkbox,
  // Modal,
  // Grid,
  // Pagination,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  // Dropdown,
} from 'semantic-ui-react'

const TableHeaderCell = (props) => {
  const {
    isEmpty,
    info,
    collapsing,
    textAlign,
    sortable,
    text,
    type,
    meta,
    field,
    ...restProps
  } = props

  const dispatch = useDispatch()
  const { uiTables } = useSelector(getUIState)
  const [ order, setOrder ] = React.useState('desc')

  const handleClick = (mta, val) => {
    if (uiTables.filter(i => i.component === info.namespace)[0].sort === val) {
      setOrder(state => state === 'desc' ? 'asc' : 'desc')
    }
    dispatch(uiSlice.actions.updateUITables({component: info.namespace, sort: val, order: order, meta: !!meta}))
    // consoleLogger(uiTables.filter(i => i.component === info.namespace)[0].sort, mta, val, order)
  }
  React.useEffect(() => {
    dispatch(uiSlice.actions.updateUITables({component: info.namespace, order: order, meta: !!meta}))
  }, [order]) // eslint-disable-line
  React.useEffect(() => {
    // consoleLogger("===================== field = slice ======================", field === uiTables.filter(i => i.component === info.namespace)[0].sort, field, uiTables.filter(i => i.component === info.namespace)[0].sort)
    if (field === uiTables.filter(i => i.component === info.namespace)[0].sort) {
      setOrder(uiTables.filter(i => i.component === info.namespace)[0].order)
    }
  }, []) // eslint-disable-line

  return (
    <Table.HeaderCell
      collapsing={collapsing}
      textAlign={textAlign ? textAlign : 'left'}
      {...restProps}
    >
      {sortable ?
        <div
          className={cn('tableHeaderCell_sort', {
            'sorted': uiTables.filter(i => i.component === info.namespace)[0].sort === field,
            [`${order}`]: order
          })}
          onClick={() => handleClick(meta, field)}
        >
          <span>{text}</span>
          <Icon name={`triangle${order === 'asc' ? ' up' : ' down'}`} />
        </div> : 
        <div className='tableHeaderCell_nonsort'>
          <span>{text}</span>
        </div>
      }
    </Table.HeaderCell>
  )
}

const TableHeader = (props) => {
  const {
    info,
    schema
  } = props
  return (
    <>
      <Table.Header>
        <Table.Row>
          {schema && schema.map(item => (
            <TableHeaderCell info={info} key={item.key} {...item} />
          ))}
        </Table.Row>
      </Table.Header>
    </>
  )
}

export default React.memo(TableHeader)
