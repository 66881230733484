import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const MediaUploadContainer = styled('div')`
  .uploader_image {
    position: relative;
    width: auto;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;

    width: 100%;
    .uploader_image_overlay {
      > span {
        position: absolute;
        bottom: 1rem;
        right: 2rem;
        text-align: center;
        font-size: 0.75rem;
        color: white;
        white-space: nowrap;
      }
    }

    /* .uploader_image_overlay */
    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(${stylevars.palette.info.rgb}, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.15s ease-out;
      pointer-events: none;

      .icon {
        color: #fff;
        font-size: 4rem;
        line-height: 4rem;
      }
    }
    &:hover {
      .uploader_image_overlay {
        opacity: 1;
      }
    }
  }
  .uploader_content {
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .uploader_note {
    margin-bottom: 1rem;

    p {
      margin: 0;
      line-height: 1.6;
    }
  }

  .uploaderDropzone {
    position: relative;
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 1rem;
    border-width: 2px;
    border-style: dashed;
    border-color: rgba(${stylevars.palette.info.rgb}, 0);
    transition: border 0.15s ease-out;
    /* .uploaderDropzone_indicator */
    &_indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
      z-index: 2;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      opacity: 0;
      transition: opacity 0.15s ease-out;
    }

    &:hover {
      border-color: rgba(${stylevars.palette.info.rgb}, 1);
      &.hasDropped {
        border-color: rgba(${stylevars.palette.info.rgb}, 0);
      }
    }
    &.dragging {
      .uploaderDropzone_indicator {
        opacity: 1;
      }
    }
  }

  .uploader_column {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
  }

  .defaultImage {
    margin: auto;
    width: 100% !important;
    height: 420px !important;
    object-fit: cover;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
`