import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CollectionsContainer = styled('div')`
  padding: 0rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(220px, 1fr));
  grid-gap: 0.5rem;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  @media ${stylevars.breakpoints.tablet} {
    grid-template-columns: repeat(1, minmax(220px, 1fr));
  }
`