import React from 'react'

// REDUX
import { useSelector } from 'react-redux'
import { getVendorState } from '@Slices/vendorSlice'

// COMPONENTS
import { Icon } from 'semantic-ui-react'
import { ReactComponent as FoundationLogo } from '@Common/Icons/FoundationLogo.svg'

// STYLES
import { SocialMediaContainer } from './SocialMediaStyles'

const SocialMediaLinks = () => {
  const { vendorInfo } = useSelector(getVendorState)
  return (
    <SocialMediaContainer className='profile-social'>
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.facebook &&
        <a href={`//fb.me/${vendorInfo.acf.social_media.facebook}`} target='_blank' rel="noopener noreferrer" className='link facebook'>
          <Icon name='facebook' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.twitter &&
        <a href={`//twitter.com/${vendorInfo.acf.social_media.twitter}`} target='_blank' rel="noopener noreferrer" className='link twitter'>
          <Icon name='twitter' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.instagram &&
        <a href={`//instagram.com/${vendorInfo.acf.social_media.instagram}/`} target='_blank' rel="noopener noreferrer" className='link instagram'>
          <Icon name='instagram' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.youtube &&
        <a href={`//youtube.com/channel/${vendorInfo.acf.social_media.youtube}`} target='_blank' rel="noopener noreferrer" className='link youtube'>
          <Icon name='youtube' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.behance &&
        <a href={`//behance.net/${vendorInfo.acf.social_media.behance}`} target='_blank' rel="noopener noreferrer" className='link behance'>
          <Icon name='behance' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.dribbble &&
        <a href={`//dribbble.com/${vendorInfo.acf.social_media.dribbble}`} target='_blank' rel="noopener noreferrer" className='link dribbble'>
          <Icon name='dribbble' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.linkedin &&
        <a href={`//linkedin.com/${vendorInfo.acf.social_media.linkedin}`} target='_blank' rel="noopener noreferrer" className='link linkedin'>
          <Icon name='linkedin' size='large' />
        </a>
      }
      {vendorInfo.acf.social_media && vendorInfo.acf.social_media.foundation &&
        <a href={`//foundation.app/${vendorInfo.acf.social_media.foundation}`} target='_blank' rel="noopener noreferrer" className='link customicon foundation'>
          <FoundationLogo />
        </a>
      }
    </SocialMediaContainer>
  )
}

export default React.memo(SocialMediaLinks)
