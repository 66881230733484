import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const GalleryGridFilterContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 1rem;
  margin-bottom: 1rem;

  position: relative;

  &.disabled {
    .filterLabel {
      color: rgba(0,0,0,0.45);
    }
  }

  .filterLabel {
    font-size: 1rem;
    margin: 0;
    margin-right: 1rem !important;
  }

  .filterOption {
  }

  .filterGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:not(:last-of-type) {
      padding-right: 1rem;
      border-right: 1px solid ${stylevars.paper.borderColor};
      margin-right: 1rem;
    }
  }
`