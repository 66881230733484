import React from 'react'
// import { useParams } from 'react-router-dom'
import { MuuriComponent, muuriMap } from 'muuri-react';
import axios from 'axios'
import { debounce } from 'lodash-es';
import { consoleLogger } from '@Utils'
import { toast } from 'react-toastify';

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { GalleryDNDItem } from '.'
import { GalleryItem } from './';

// STYLES
import { GalleryDNDContainer } from './GalleryDNDStyles'
import { Dimmer, Loader } from 'semantic-ui-react';

const GalleryDND = (props) => {
  const token = localStorage.Authorization
  const dispatch = useDispatch()
  const { cid, galleryData, draggable, handleDataValue } = props
  const [ ready, setReady ] = React.useState(false)
  const [ processing, setProcessing ] = React.useState(false)
  const { authenticated, userInfo, userKey, userGallery, userCollections } = useSelector(getUserState)

  const muuriRef = React.useRef(null)

  React.useEffect(() => {
    consoleLogger('================ GALLERY DND DATA ====================')
    consoleLogger(galleryData)
    if (galleryData && galleryData.length > 0) {
      setReady(true)
    }
  }, [galleryData]) // eslint-disable-line

  const handleProcessing = (bool) => {
    setProcessing(bool)
  }

  const handleUpdateOrder = async (results) => {
    consoleLogger("============== handleUpdateOrder ==============")
    consoleLogger(results)
    handleDataValue(results)
  }

  // const galleryItems = galleryData.map(gallery => (
  //   <GalleryDNDItem
  //     key={gallery.slug}
  //     cid={cid}
  //     id={gallery.id}
  //     data={gallery}
  //     handleProcess={handleProcessing}
  //     draggable={draggable}
  //   />
  // ))

  return (
    <GalleryDNDContainer>
      {ready &&
        <div className='galleryDNDWrapper'>
          {processing &&
            <Dimmer inverted active />
          }
          <div className='galleryTarget'>
            <MuuriComponent
              // dragEnabled={true}
              // ref={muuriRef}
              // // dragStartPredicate={{ handle: ".handle" }}
              // dragPlaceholder={{
              //   enabled: true,
              //   createElement: function (item) {
              //     return item.getElement().cloneNode(true);
              //   },
              // }}
              // // dragFixed
              // // instantLayout={true}
              // // id={`collectionGalleryDNDGrid`}
              // gridProps={{className: 'galleryGrid'}}
              // onDragEnd={() => {
              //   // const theGrid = muuriMap.get(`collectionGalleryDNDGrid`)
              //   // consoleLogger(theGrid.getItems().map((item, index) => item._component.props))
              //   // const results = theGrid.getItems().map((item, index) => ({
              //   //   id: Number(item._component.props.post.id),
              //   //   // ...item._component.props.post,
              //   //   collection_order: index + 1,
              //   // }))
              //   const results = muuriRef.current.getItems().map((item, index) => ({
              //       id: Number(item._component.props.post.id),
              //       // ...item._component.props.post,
              //       collection_order: index + 1,
              //     }))
              //   consoleLogger(results)
              //   handleUpdateOrder(results)
              // }}
              // layout={{
              //   fillGaps: true,
              //   horizontal: false,
              //   alignRight: false,
              //   alignBottom: false,
              //   rounding: true
              // }}
              // layoutOnResize={100}
              // layoutDuration={300}
              
              ref={muuriRef}
              gridProps={{className: 'galleryGrid'}}
              onDragEnd={(item) => {
                const results = muuriRef.current.getItems().map((item, index) => ({
                  id: Number(item._component.props.post.id),
                  // ...item._component.props.post,
                  collection_order: index + 1,
                }))
                handleUpdateOrder(results)
                consoleLogger(results)
              }}
              layoutOnInit={true}
              dragEnabled={draggable}
              dragSortHeuristics={{
                sortInterval: 50
              }}
              layoutDuration={150}
              dragRelease={{
                duration: 400,
                easing: "ease-out"
              }}
              dragContainer={document.body}
              dragPlaceholder={{
                enabled: true,
                createElement: function (item) {
                  return item.getElement().cloneNode(true);
                },
              }}
            >
              {galleryData.map(gallery => (
                <GalleryItem
                  key={gallery.id}
                  id={gallery.id}
                  cid={cid}
                  post={gallery}
                  gdata={galleryData}
                  fetchGallery={() => {}}
                  draggable={draggable}
                  handleProcess={handleProcessing}
                  handleDataValue={handleDataValue}
                />
              ))}
            </MuuriComponent>
          </div>
        </div>
      }
    </GalleryDNDContainer>
  )
}

export default React.memo(GalleryDND)