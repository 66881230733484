import React from 'react'
import { Link } from 'react-router-dom'
import { orderBy } from 'lodash'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import currency from 'currency.js'
import { maskEmail } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Dimmer, Icon, Loader } from 'semantic-ui-react'

const shortNumber = require('short-number')

const WidgetEmpty = (props) => {
  const { index } = props
  const token = localStorage.Authorization
  const { userInfo, userGallery } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  const [ galleryItem, setGalleryItem ] = React.useState()
  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    (async() => {
      setReady(true)
    })()
  }, []) // eslint-disable-line

  return (
    <div className='widget popular'>
        <div className='widgetWrapper'>
          <div className='label'>It seems that your gallery is still empty...</div>
        </div>
    </div>
  )
}

export default React.memo(WidgetEmpty)
