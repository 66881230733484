import React from 'react'
import { useHistory } from "react-router-dom"
import cn from 'classnames'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { Button, Checkbox } from 'semantic-ui-react'
// import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getUserState } from '@Slices/userSlice'
// import gallerySlice, { getGalleryState } from '@Slices/gallerySlice'

// STYLES
import { ToolbarContainer } from './ToolbarStyles'

const CollectionsEditToolbar = (props) => {
  const { label, handleBackButton } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { userInfo, userGallery } = useSelector(getUserState)
  const { siteSettings, galleryCanArrange, galleryShowUploader } = useSelector(getUIState)
  const [ uploadLimit, setUploadLimit ] = React.useState()
  const [ ready, setReady ] = React.useState(false)
  const [ reachedLimit, setReachedLimit ] = React.useState(true)
  const handleArrangeToggle = () => {
    dispatch(uiSlice.actions.setGalleryArrange(!galleryCanArrange))
  }
  const setShowGalleryUploader = () => {
    dispatch(uiSlice.actions.setShowGalleryUploader(true))
  }
  const handleOnClick = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      handleBackButton()
    }
  }

  React.useEffect(() => {
    setUploadLimit(userInfo.acf.subscription_type === 'premium' ? siteSettings.premiumLimit : siteSettings.starterLimit)
    setReady(true)
  }, []) // eslint-disable-line

  React.useEffect(() => {
    setReachedLimit(userInfo.acf.subscription_type === 'premium' ? false : userGallery.length >= uploadLimit)
  }, [uploadLimit]) // eslint-disable-line

  const [ toolbarShow, setToolbarShow ] = React.useState(false)
  const handleToolbarToggle = () => {
    setToolbarShow(prev => !prev)
  }

  return (
    <ToolbarContainer>
      {ready &&
        <>
          <div className='toolbar-column left'>
            <div className='title'>
              <Button icon='arrow left' circular className='goBack' basic onClick={handleOnClick} />
              <h1>{label}</h1>
            </div>
          </div>
          <div className={cn('toolbar-column right')}></div>
        </>
      }
    </ToolbarContainer>
  )
}

export default React.memo(CollectionsEditToolbar)
