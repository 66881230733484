import React from 'react'
import { Link, useHistory } from "react-router-dom"
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {Root} from './Styles'
import LoginForm from './LoginForm'

const LoginPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authenticated, userInfo } = useSelector(getUserState)
  // const { path } = useParams()
  // const decodedPath = decodeURIComponent(path)
  // consoleLogger('decodedPath =======================', decodedPath)

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('login'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('LOGIN PAGE')
    if (authenticated) {
      history.push(userInfo.role === 'sf_creator' ? '/admin' : '/admin/orders')
    }
  }, [authenticated, history]) // eslint-disable-line

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/login`} />
      </Helmet>
      <LoginForm />
    </Root>
  )
}

export default React.memo(LoginPage)
