import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars' // eslint-disabble-line

export const StyledContainer = styled.div`
  padding: 2rem;

  .wrapper {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid ${stylevars.paper.borderColor};
  }
`