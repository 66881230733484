import React from 'react'
import axios from 'axios'
import { useHistory, useParams } from "react-router-dom"
import { Helmet } from 'react-helmet'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {Root} from './Styles'
import NewPasswordForm from './NewPasswordForm'
import { GeneralPreloader } from '@Common'

const NewPasswordPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authenticated } = useSelector(getUserState)
  const { userId, key } = useParams()
  const [ ready, setReady ] = React.useState(false)
  const [ userInfo, setUserInfo ] = React.useState()
  // const decodedPath = decodeURIComponent(path)
  // consoleLogger('decodedPath =======================', decodedPath)

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('login'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())
  }, []) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (userId && key) {
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/checkreset`,
          method: 'GET',
          params: {
            id: userId,
            reset_key: key
          }
        }).then(res => {
          consoleLogger('================ CHECK RESET KEY RESPONSE', res.data)
          setUserInfo(res.data)
        }).catch(err => {
          consoleLogger(err)
          history.push('/')
        })
      }
    })()
  }, [userId, key]) // eslint-disable-line

  React.useEffect(() => {
    if (userInfo) {
      setReady(true)
    }
  }, [userInfo]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('LOGIN PAGE')
    if (authenticated) {
      history.push('/admin')
    }
  }, [authenticated]) // eslint-disable-line

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>New Password - {process.env.REACT_APP_PROJECT_NAME}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/login`} />
      </Helmet>
      {ready ?
        <NewPasswordForm userInfo={userInfo} /> : 
        <GeneralPreloader />
      }
    </Root>
  )
}

export default React.memo(NewPasswordPage)
