import React from 'react'

import { DocumentContainer } from './Styles'

const TermsContent = () => {
  return (
    <DocumentContainer>
      <h1>Terms of Service</h1>

      <p>Welcome to {process.env.REACT_APP_PROJECT_NAME}. This Terms of Service govern your use of our Website ({process.env.REACT_APP_DOMAIN}), products, and services (collectively, the “Services”). By using the Services, you agree to be bound by this Terms of Service. If you do not agree or consent to this Terms of Service, you do not have the right to use the Services. This Terms of Service include our Privacy Policy.</p>

      <p>If you are below 18 years old: you must obtain consent from your parent(s) or legal guardian(s), their acceptance of this Terms of Service and their agreement to take responsibility for: (i) your actions; (ii) any charges associated with your use of the Services; and (iii) your acceptance and compliance with this Terms of Service. If you do not have consent from your parent(s) or legal guardian(s), you must stop using the Services.</p>

      <h2 className='ordered'>Website Contents</h2>
      <p>{process.env.REACT_APP_PROJECT_NAME}, or the registered {process.env.REACT_APP_PROJECT_NAME} Creators owns all rights, title and interest in and to content featured or displayed on our Website, including, but not limited to, photography, illustrations, and other two-dimensional images and art, as well as the selection and arrangement of the content and are protected by copyright, trade dress, moral rights, trademark and other laws relating to the protection of intellectual property.</p>

      <h2 className='ordered'>Permitted Use of our Website</h2>
      <p>Browsing of our Website is allowed which includes the inevitable technical reproduction of our Website for the sole purpose such browsing is permitted. Any
      other use of our Website is expressly prohibited unless otherwise stated herein.</p>

      <p>Framing of our Website or any parts thereof is prohibited unless we expressly authorize you to do so in writing.</p>

      <p>You are, expressly prohibited from the following actions which are regarded as misuse of our Website:</p>

      <ul class='list list--roman'>
        <li>Using any data mining applications, robots, crawlers or similar data gathering or extraction methods or tools;</li>
        <li>Manipulating or otherwise display the Website or its content or parts thereof by using framing or similar navigational technology;</li>
        <li>Downloading, reproducing, copying, re-transmitting, imitating and/or distributing our Website or its content as a whole or any parts thereof;</li>
        <li>Reverse engineering, altering or modifying any part of the Website or the content;</li>
        <li>Circumventing, disabling or otherwise interfering with security-related features of the Website or any system resources, services or networks connected to or accessible through the Website;</li>
        <li>Selling, licensing, lease or in any way commercialize the Website or the content without specific written consent from {process.env.REACT_APP_PROJECT_NAME};</li>
        <li>Using Website for other than for its intended purpose; or</li>
        <li>Any other action analogous to the forgoing.</li>
      </ul>

      <h2 className='ordered'>Account Creation</h2>

      <p>{process.env.REACT_APP_PROJECT_NAME} provides a sales platform for photography, illustrations, and other two-dimensional images and art (collectively, “Visual Art”). To gain full access to this service, you must first signup for an account and select a password and username (“User Account”). You may select a username, provided such username is still available and does not violate any third-party rights, laws or regulations. Please choose a secure password and keep this password confidential. We reserve the right to adjust password requirements at any time to meet current security standards. Your User Account becomes effective once you have completed the signup process and we confirm this or activate it. A User refers to someone who has a User Account.</p>

      <p>Your User Account is only valid for you personally. You cannot signup for a third party, transfer it to a third party or allow a third party to use it. You are responsible for all activities that take place in your User Account and must inform us immediately in case of unauthorized access to your account.</p>

      <p>When you signup, you must provide us with accurate, complete, and updated information about yourself at all times so we can get in touch with you when necessary. You will not share your username or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your User Account. If you become aware that your password is or may have been compromised, you are obliged to inform us immediately at <a href="mailto:help@stylefolio.app">help@stylefolio.app</a>. Please note that we may have to reset your password to ensure the security of your User Account.</p>

      <h2 className='ordered'>Creator’s Gallery</h2>

      <p>A User who sells a Visual Art in our Website is referred to as a Creator. To sell a Visual Art, a Creator must upload an image of the Visual Art (“Art Image”) under their User Account, set the name of the Visual Art (“Art Title”), select a print type, and set the prices. A gallery will then be created (“Creator’s Gallery”), and the Art Image will be displayed on the Creator’s Gallery on our Website and other Users can purchase a physical print of the Art Image. The Creator can delete any Art Image from the Creator’s Gallery at any time.</p>

      <p>By uploading the Art Image to your User Account, you agree that your Art Image, Art Title, name and username may be publicly visible on our Website.</p>

      <h2 className='ordered'>Prohibited Content</h2>

      <p>Anything you post, upload, share, store, or otherwise provide through the Services, including photographs, Art Image, illustrations, texts, graphics, and other artwork, is your “Content”.</p>

      <p>You are solely responsible for your Content. Only your original Content can be uploaded to our Website. It must not (i) infringe or violate the intellectual property rights or any other rights of any person or entity, (ii) be harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, explicit, lewd, libelous, abusive, disparaging, or otherwise objectionable, or (iii) violate this Terms of Service, and any applicable local laws and regulations.</p>

      <p>If we receive indications of illegal Content or Art Image or discover violations against the rights of any person or entity, applicable laws or our Terms of Service, we may, at our sole discretion and without notice, delete such Content or Art Image from your User Account.</p>

      <p>If you discover Content on our Website that violates intellectual property rights, privacy or other rights of any person or entity, or violates this Terms of Service, you may report such Content by contact <a href="mailto:help@stylefolio.app">help@stylefolio.app</a>. Your report must contain specific information about the violation to be processed. You will find further information on our Website. Reports with insufficient information may not be answered. Abusive reports or false statements will be legally prosecuted.</p>

      <p>We will review Reports, contact you if we have any questions, and delete any inappropriate Content immediately. We reserve the right to temporarily block and, at our discretion, delete Reported Content. In case of infringements and legal claims we reserve the right to forward your contact information to the claimant to the extent legally permitted.</p>

      <h2 className='ordered'>Grant of Rights</h2>

      <p>With respect to Content that you added to your Creator’s Gallery, you grant {process.env.REACT_APP_PROJECT_NAME} the right to print, manufacture, and ship it, and the right to reproduce, distribute, publicly display and promote such Content.</p>

      <p>You understand and agree that {process.env.REACT_APP_PROJECT_NAME}, in performing the required technical steps to provide the Services to our User, may need to make changes to your Content to conform and adapt those Content to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so. At the same time, you allow us to use your Content free of charge for the promotion of our platform, Services and/or your Content, e.g. on our Website and in internal and external communication.</p>

      <p>You warrant that you have the power and authority to grant all licenses required in this Terms of Service. You agree that the licenses you grant are royalty-free, perpetual, sublicenseable, irrevocable, and worldwide. You understand and agree that it may not be possible to completely delete any submitted content from Stylefolio’s records, and that your Content may remain viewable elsewhere to the extent that they were purchased, copied or stored by other users.</p>

      <h2 className='ordered'>Costs and Pricing</h2>

      <p>{process.env.REACT_APP_PROJECT_NAME} will charge a selling fee equivalent to 5% or 15% (for Starter and Pro Plan Subscription) of the price set by the Creator for the particular Art Image for every sale made.  By default, shipping and delivery costs will be shouldered by the buyer unless the Creator sets a shipping and delivery cost promotion. </p>

      <p>We have the right to limit or cancel quantities of product purchased, and the right to refuse any order. In the event we need to make a change to an order, we will endeavor to notify you through your email address, billing address, and/or phone number provided at the time the order was made.</p>

      <p>Prices displayed on our Website are quoted in United States Dollar, and are subject to change at any time.</p>

      <p>Applicable taxes will be determined by the shipping address of the order and will automatically be added to the order. We will calculate, collect, and remit sales tax where applicable. You are responsible for providing accurate and complete measurements for custom item orders; custom items are not returnable.</p>

      <p>You are responsible to pay all such fees as described on our website in connection with such Services you availed.</p>

      <p>{process.env.REACT_APP_PROJECT_NAME} uses a third-party payment processing companies (“Payment Processor”) to process fees through a payment account linked to your account on the Services for use of the Services, as well as to enable process payments made to Creators. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms of Service. {process.env.REACT_APP_PROJECT_NAME} is not responsible for error by the Payment Processor.</p>

      <p>By choosing to purchase from Creator’s Gallery, you agree to pay through the Payment Processor, all charges at the prices then in effect for any use of such Services in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that it or its Payment Processor makes even if it has already requested or received payment.</p>

      <h2 className='ordered'>Termination</h2>

      <p>You can terminate your User Account at any time through the account settings. Account termination will not affect the availability of some Content uploaded through our Service prior to termination.</p>

      <p>We may terminate or suspend your User Account and your access to the Services if we have reason to believe that your Content or use of our Services violate this Terms of Service. {process.env.REACT_APP_PROJECT_NAME} has the sole right to decide whether you are in violation of any of the restrictions set forth in this Terms of Service.</p>

      <p>Account termination may result in destruction any information associated with your User Account, including your Content.</p>

      <p>This Terms of Service will remain in effect even after your access to the Service is terminated, or your use of the Service ends.</p>

      <h2 className='ordered'>Warranties and Limitations of Liability</h2>

      <p>We make no representations or warranties concerning any content posted by Users through the Services. {process.env.REACT_APP_PROJECT_NAME} is not responsible for the accuracy, copyright compliance, legality, or decency of content posted by Users accessed through the Services. </p>

      <p>We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services</p>

      <p>THE SERVICES AND CONTENT ARE PROVIDED BY STYLEFOLIO (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS AND WITHOUT ANY KIND OF WARRANTY (EXPRESS OR IMPLIED). WE ARE EXPRESSLY DISCLAIMING ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE.</p>

      <p>WE DO NOT GUARANTEE THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) THE SERVICES WILL BE FREE OF VIRUSES OR OTHER HARMFUL MATERIALS; OR (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR EXPECTATIONS. YOU USE THE SERVICES SOLELY AT YOUR OWN RISK. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>

      <p>LIABILITY LIMITS. TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER STYLEFOLIO, NOR OUR EMPLOYEES, LICESORS, SUPPLIERS OR DIRECTORS SHALL BE LIABLE TO YOU FOR ANY LOST PROFITS OR REVENUES, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS. IN NO EVENT SHALL ETSY’S AGGREGATE LIABILITY FOR ANY DAMAGES EXCEED THE GREATER OF ONE HUNDRED ($100) US DOLLARS (USD) OR THE AMOUNT YOU PAID STYLEFOLIO IN THE PAST TWELVE MONTHS. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>

      <p>Indemnity. You shall indemnify and hold {process.env.REACT_APP_PROJECT_NAME}, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of this Terms of Service. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your User Account, provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder.</p>

      <h2 className='ordered'>Other Provisions</h2>

      <p>Amendment. We have the right to modify, amend or update this Terms of Service from time to time. If we make changes, we will publish the updated Terms of Service on our Website and/or email you about the changes. The modification or changes to this Terms of Service will be effective upon publication on our Website, unless otherwise stated. Your continued use of our Services following the change or modification of our Terms of Service constitutes your acceptance to it.</p>

      <p>Completeness. This Terms of Service is the expression of the complete agreement between you and {process.env.REACT_APP_PROJECT_NAME} and supersedes all proposals or prior agreements, oral or written, and all other communications between you and {process.env.REACT_APP_PROJECT_NAME} relating to Services. </p>

      <p>Separability. In case any one or more of the provisions contained in this Terms of Service shall be held invalid, illegal, or unenforceable in any respect, the validity, legality, and enforceability of the remaining provisions contained herein shall not in any way be affected or impaired thereby.</p>

      <p>Non-Waiver. Our failure or delay to insist upon a strict performance of any of the terms, conditions, and covenants hereof, or to exercise any of its rights under this Terms of Service, including its right to terminate it, shall not be deemed a waiver of any right or remedy that we may have, nor shall it be construed as a waiver of any subsequent breach or default of the terms and conditions herein contained, which shall be deemed in full force and effect. No waiver by us shall be deemed to have been made unless expressed in writing.</p>

      <p>Governing Law. This Terms of Service shall be governed, construed and interpreted in accordance with the laws of the Republic of the Philippines.</p>

      <p>Assignment. You may not assign, delegate or transfer this Terms of Service or your rights or obligations hereunder, or your User Account, in any way (by operation of law or otherwise) without Stylefolio’s prior written consent. We may transfer, assign, or delegate this Terms of Service and our rights and obligations without your consent.</p>

      <p>Dispute Resolution. Any dispute, controversy or claim arising out of or relating to this Terms of Service, or the breach, termination, invalidity or interpretation thereof, shall be settled in an amicable manner within sixty (60) calendar days from notice of a dispute. If the dispute is not resolved for any reason within the sixty (60)-calendar day period, then it shall be finally settled by arbitration in accordance with the PDRCI Arbitration Rules in force at the time of the commencement of the arbitration. The seat of arbitration is the Republic of the Philippines, whose laws shall be the law of the arbitration agreement. The language of the arbitration shall be English (Philippines). The venue of all in-person arbitration proceedings shall be in the Republic of the Philippines only.</p>
    </DocumentContainer>
  )
}

export default React.memo(TermsContent)
