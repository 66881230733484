import React from "react";
import { Link } from "react-router-dom";
// import axios from 'axios'
// import { setAuthorizationHeader } from '@Utils'
// import jwtDecode from 'jwt-decode'
import { useParams, useHistory } from "react-router-dom";
// import he from 'he'
import NumberFormat from "react-number-format";
// import { findIndex, nth } from 'lodash'
import { Helmet } from "react-helmet";
import { consoleLogger, priceFormat, cleanPrice } from "@Utils";

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getUserState } from "@Slices/userSlice";
import uiSlice, { getUIState } from "@Slices/uiSlice";
import cartSlice, { getCartState } from "@Slices/cartSlice";
// import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import {
  Header,
  Divider,
  Segment,
  Grid,
  // Search,
  Button,
  // Icon,
  // Input,
  // Image,
  Dimmer,
  Loader,
  // Confirm,
  // Table,
} from "semantic-ui-react";
import LoginForm from "@AdminPages/Login/LoginForm";
import AccountBillingForm from "@AdminPages/Account/AccountBillingForm";
import AccountMailingForm from "@AdminPages/Account/AccountMailingForm";
import ConfirmOrder from "./ConfirmOrder";
import { GeneralPreloader } from "@Common";
import ShippingAddress from "@AdminPages/Gallery/Components/ShippingAddress";

// STYLES
import { CheckoutContainer } from "./CheckoutStyles";

const voucher_codes = require("voucher-code-generator");

const StoreCheckoutPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated, userAddresses, userInfo } = useSelector(getUserState);
  const { currency, siteSettings } = useSelector(getUIState);
  const { step } = useParams();

  const shippingFeeLocal = siteSettings.shippingFeeDomestic;
  const shippingFeeIntl = siteSettings.shippingFeeInternational;

  const { carts, coupons, checkoutStep, paymentType, checkout } =
    useSelector(getCartState);
  // const { gallerySizeTypes, gallerySizes } = useSelector(getGalleryState)

  const [storageCarts, setStorageCarts] = React.useState(
    localStorage.carts && localStorage.carts.length > 0
      ? JSON.parse(localStorage.carts)
      : []
  ); // eslint-disable-line
  const [isUpdating, setIsUpdating] = React.useState(false);
  // const [ confirmOpen, setConfirmOpen ] = React.useState(false)
  // const [ removeItem, setRemoveItem ] = React.useState()
  const [totalDiscount, setTotalDiscount] = React.useState(0);
  const [subtotalAmount, setSubtotalAmount] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  // const [totalShippingFee, setTotalShippingFee] = React.useState(shippingFee);
  const [payNow, setPayNow] = React.useState(false);

  const [isShippingLocal, setIsShippingLocal] = React.useState(false);
  const [shippingFee, setShippingFee] = React.useState(0);
  const [itemTotalCount, setItemTotalCount] = React.useState();
  const [mailingAddress, setMailingAddress] = React.useState(
    Object.keys(checkout.shippingAddress).length > 0
      ? checkout.shippingAddress
      : userInfo.acf.same_billing_mailing_address
      ? userInfo.acf.billing_address
      : userInfo.acf.mailing_address
  );
  const [handlingFee, setHandlingFee] = React.useState(
    Number(siteSettings.handlingFee) * Number(currency.value)
  );

  /* eslint-disable-next-line */
  const [vendorCoupons, setVendorCoupons] = React.useState(
    coupons && coupons.length > 0
      ? coupons
      : storageCarts && storageCarts.length > 0
      ? storageCarts.map((vendor) => ({
          vendorId: vendor.id,
          code: "",
          type: "",
          value: "",
          minimum: "",
          quantity: "",
          processing: false,
          verified: false,
        }))
      : []
  );

  React.useEffect(() => {
    let totalD = 0;
    let nAmount = 0;
    let ntotalAmount = 0;
    storageCarts.forEach((vendor) => {
      vendor.items.forEach((item) => {
        const itemPrice = cleanPrice(Number(item.price) * currency.value);
        nAmount += priceFormat(Number(itemPrice) * Number(item.quantity));
      });
    });
    ntotalAmount = nAmount;
    totalD = totalD * currency.value;
    setSubtotalAmount(nAmount);
    ntotalAmount += priceFormat(shippingFee);
    ntotalAmount += priceFormat(handlingFee);
    setTotalAmount(ntotalAmount);
    setTotalDiscount(totalD);

    dispatch(
      cartSlice.actions.updateCheckout({
        amount: priceFormat(nAmount),
        totalAmount: priceFormat(ntotalAmount - totalD),
        shippingFee: priceFormat(shippingFee),
        handlingFee: priceFormat(handlingFee),
        discount: priceFormat(totalD),
        currency: currency.currency,
      })
    );

    consoleLogger("storageCarts.forEach totalD =================", totalD);
    // eslint-disable-next-line
  }, [vendorCoupons, storageCarts, shippingFee, handlingFee, currency]);

  React.useEffect(() => {
    if (!step) {
      dispatch(cartSlice.actions.updateCheckoutStep("select"));
    } else {
      dispatch(cartSlice.actions.updateCheckoutStep(step));
    }
    consoleLogger("step =====================", step);
  }, [dispatch, step]);

  React.useEffect(() => {
    if (!paymentType) {
      history.push("/checkout");
    }
  }, [history, paymentType]); // eslint-disable-line

  // CHECK IF REDUX CHECKOUT IS EMPTY
  React.useEffect(() => {
    if (!checkout && !checkout.totalAmount) {
      history.push("/cart");
    }

    // eslint-disable-next-line
  }, [checkout]);

  React.useEffect(() => {
    (async () => {
      setIsUpdating(true);

      dispatch(uiSlice.actions.setPageTemplate("checkout"));
      dispatch(uiSlice.actions.setCurrentPage("cart"));
      dispatch(uiSlice.actions.setCurrentPageURL("cart"));
      dispatch(uiSlice.actions.setCurrentPageTitle("checkout"));

      const orderID = voucher_codes.generate({
        prefix: "ORDER-",
        pattern: "################",
        postfix: `-${new Date().getFullYear()}`,
        charset: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      });

      dispatch(
        cartSlice.actions.updateCheckout({
          orderID: orderID[0],
        })
      );

      setIsUpdating(false);
    })();
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (carts && Object.keys(carts).length > 0) {
      setItemTotalCount(
        carts
          .map((n) => n.items.map((i) => i.quantity).reduce((a, b) => a + b))
          .reduce((a, b) => a + b)
      );
    }
  }, [carts]); // eslint-disable-line

  React.useEffect(() => {
    consoleLogger(">>>>>>>>>>>>>>>>>>> itemTotalCount", itemTotalCount);
  }, [itemTotalCount]); // eslint-disable-line

  const getVendorName = (id) => {
    return carts.filter((vendor) => vendor.id === id)[0].name;
  };

  const handleConfirmOrder = (bool) => {
    setPayNow(bool);
  };

  const handleMailingAddress = (address) => {
    setMailingAddress(address);
  };
  React.useEffect(() => {
    if (userInfo) {
      // setMailingAddress(
      //   userInfo.acf.same_billing_mailing_address
      //     ? userInfo.acf.billing_address
      //     : userInfo.acf.mailing_address
      // );

      const tempShippingFee =
        mailingAddress &&
        mailingAddress.country_code.toLowerCase() ===
          siteSettings.countryOriginCode.toLowerCase()
          ? shippingFeeLocal
          : shippingFeeIntl;

      // let tempQuantity = 1;
      // if (selectedPrintType) {
      //   tempQuantity =
      //     selectedPrintType.slug === "print-only"
      //       ? Math.ceil(Number(itemTotalCount) / 10)
      //       : itemTotalCount;
      // }

      // caculate each item for shipping
      let totalPrintOnlyCount = 0;
      let totalPrintOthersCount = 0;
      carts.forEach((vendor) => {
        vendor.items.forEach((item) => {
          if (item.selectedType.slug === "print-only") {
            totalPrintOnlyCount += 1;
          } else {
            totalPrintOthersCount += 1;
          }
        });
      });

      let tempTotalShipping =
        tempShippingFee *
        Math.ceil(Number(totalPrintOnlyCount) / 10) *
        currency.value;
      tempTotalShipping +=
        tempShippingFee * totalPrintOthersCount * currency.value;

      setIsShippingLocal(
        mailingAddress &&
          mailingAddress.country_code.toLowerCase() ===
            siteSettings.countryOriginCode.toLowerCase()
      );
      // setShippingFee(
      //   cleanPrice(tempShippingFee * tempQuantity * currency.value)
      // );
      setShippingFee(tempTotalShipping);
      const tempHandlingFee = Number(siteSettings.handlingFee);
      setHandlingFee(cleanPrice(tempHandlingFee * currency.value));
    }
    // eslint-disable-next-line
  }, [userInfo, mailingAddress, siteSettings]);

  React.useEffect(() => {
    if (mailingAddress) {
      consoleLogger(
        "========================= mailingAddress =======================",
        mailingAddress
      );
      dispatch(
        cartSlice.actions.updateCheckout({
          shippingAddress: mailingAddress,
        })
      );
    }
  }, [mailingAddress]); // eslint-disable-line

  // React.useEffect(() => {
  //   const tempShippingFee =
  //     mailingAddress &&
  //     mailingAddress.country_code.toLowerCase() ===
  //       siteSettings.countryOriginCode.toLowerCase()
  //       ? shippingFeeLocal
  //       : shippingFeeIntl;

  //   let tempQuantity = 1;
  //   if (selectedPrintType) {
  //     tempQuantity =
  //       selectedPrintType.slug === "print-only"
  //         ? Math.ceil(Number(quantity) / 10)
  //         : quantity;
  //   }
  //   setIsShippingLocal(
  //     mailingAddress &&
  //       mailingAddress.country_code.toLowerCase() ===
  //         siteSettings.countryOriginCode.toLowerCase()
  //   );
  //   setShippingFee(cleanPrice(tempShippingFee * tempQuantity * currency.value));
  //   const tempHandlingFee = Number(siteSettings.handlingFee);
  //   setHandlingFee(cleanPrice(tempHandlingFee * currency.value));
  //   // eslint-disable-next-line
  // }, [
  //   quantity,
  //   userInfo,
  //   siteSettings,
  //   currency,
  //   selectedPrintType,
  //   mailingAddress,
  // ]); // eslint-disable-line

  const SubTotal = () => {
    return (
      <>
        <Grid.Row className="subtotla subtotalcheckout">
          <Grid.Column mobile={6} computer={4} verticalAlign="middle">
            <p>Sub Total</p>
          </Grid.Column>
          <Grid.Column mobile={10} computer={12}>
            <Header as="h4">
              <NumberFormat
                value={subtotalAmount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency.symbol}
                decimalScale={2}
                fixedDecimalScale
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const Shipping = () => {
    // let fee = shippingFee;
    // let shipping;
    // (async () => {
    //   // shipping = 0
    //   // check if customer is domestic or international
    //   if (!userInfo.acf.same_billing_mailing_address) {
    //     if (
    //       userInfo.acf.mailing_address.country_code.toLowerCase() !==
    //       siteSettings.countryOriginCode.toLowerCase()
    //     ) {
    //       fee = shippingFeeIntl;
    //     }
    //   } else {
    //     if (
    //       userInfo.acf.billing_address.country_code.toLowerCase() !==
    //       siteSettings.countryOriginCode.toLowerCase()
    //     ) {
    //       fee = shippingFeeIntl;
    //     }
    //   }
    //   // await storageCarts.forEach(vendor => {
    //   //   vendor.items.forEach(item => {
    //   //     shipping += item.quantity * (fee * currency.value)
    //   //   })
    //   // })
    //   shipping = cleanPrice(
    //     fee * Math.ceil(itemTotalCount / 10) * currency.value
    //   );
    // })().then(() => {
    //   setTotalShippingFee(shipping);
    // });
    return (
      <>
        <Grid.Row className="shipping shippingcheckout">
          <Grid.Column mobile={6} computer={4} verticalAlign="middle">
            <Header as="h4">Shipping Fee</Header>
          </Grid.Column>
          <Grid.Column mobile={10} computer={12}>
            <Header as="h4">
              <NumberFormat
                value={shippingFee}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency.symbol}
                decimalScale={2}
                fixedDecimalScale
              />
            </Header>
            {!payNow && (
              <ShippingAddress
                address={mailingAddress}
                handleAddress={handleMailingAddress}
                noCustom
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const HandlingFee = () => {
    return (
      <>
        <Grid.Row className="shipping shippingcheckout">
          <Grid.Column mobile={6} computer={4} verticalAlign="middle">
            <Header as="h4">Handling Fee</Header>
          </Grid.Column>
          <Grid.Column mobile={10} computer={12}>
            <Header as="h4">
              <NumberFormat
                value={handlingFee}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency.symbol}
                decimalScale={2}
                fixedDecimalScale
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const Coupon = () => {
    return (
      <>
        <Grid.Row className="coupon">
          <Grid.Column mobile={16} computer={4} verticalAlign="middle">
            <Header as="h4">Coupon Code</Header>
          </Grid.Column>
          <Grid.Column mobile={16} computer={12} className="couponBox">
            {vendorCoupons &&
            vendorCoupons.filter((i) => i.verified === true).length > 0 ? (
              <>
                {vendorCoupons
                  .filter((i) => i.verified === true)
                  .map((coupon, index) => (
                    <div
                      className="vendorCouponCheckout"
                      key={`${index}-${coupon.code}`}
                    >
                      <span className="ui blue horizontal label">
                        {getVendorName(coupon.vendorId)}
                      </span>
                      <span>{coupon.code}</span>
                      {coupon.type === "amount" ? (
                        <NumberFormat
                          value={coupon.value}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) : (
                        <span>
                          {coupon.value}
                          {coupon.type === "rate" ? "%" : " "}
                        </span>
                      )}
                      <span>OFF</span>
                    </div>
                  ))}
              </>
            ) : (
              <p>None</p>
            )}
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const Total = () => {
    return (
      <>
        <Grid.Row className="total totalcheckout">
          <Grid.Column mobile={6} computer={4} verticalAlign="middle">
            <Header as="h2">Total</Header>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={10} computer={12}>
            {totalDiscount > 0 ? (
              <>
                <Header as="h2" className="strike">
                  <NumberFormat
                    value={totalAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency.symbol}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Header>
                <Header as="h2" className="discounted">
                  <NumberFormat
                    value={totalAmount - totalDiscount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency.symbol}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                  <NumberFormat
                    value={totalDiscount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={`You saved ${currency.symbol}`}
                    className="saved"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Header>
              </>
            ) : (
              <Header as="h2">
                <NumberFormat
                  value={totalAmount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currency.symbol}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Header>
            )}
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  return (
    <>
      {isUpdating ? (
        <GeneralPreloader title="Preparing cart for checkout process..." />
      ) : (
        <>
          <CheckoutContainer>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Checkout - {process.env.REACT_APP_PROJECT_NAME}</title>
              <link
                rel="canonical"
                href={`${process.env.REACT_APP_DOMAIN}/checkout`}
              />
            </Helmet>
            <Header as="h2">Checkout</Header>
            <Divider />
            {/* {paymentType === 'creditcard' &&
              <CheckoutStep step={checkoutStep} />
            } */}
            <Segment className="checkout">
              {storageCarts && storageCarts.length > 0 ? (
                <>
                  {!authenticated ? (
                    <LoginForm
                      className="loginForm"
                      to="/checkout"
                      redirect
                      customer
                    />
                  ) : (
                    <>
                      <Grid divided className="cartContainer">
                        <Grid.Row>
                          <Grid.Column mobile={16} className="cartSummary">
                            <div className="cartSummaryContainer">
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column
                                    computer={16}
                                    className="cartSummaryHeader"
                                  >
                                    <Header as="h3">Order Summary</Header>
                                  </Grid.Column>
                                </Grid.Row>
                                <SubTotal />
                                <Shipping />
                                <HandlingFee />
                                <Total />
                              </Grid>
                              {payNow && <ConfirmOrder />}
                            </div>
                            {!payNow ? (
                              <>
                                <Divider />
                                <Button
                                  color="green"
                                  icon="arrow right"
                                  labelPosition="left"
                                  size="large"
                                  floated="right"
                                  content="Proceed to Payment"
                                  disabled={userAddresses.length === 0}
                                  onClick={() => handleConfirmOrder(true)}
                                />
                                <Button
                                  as={Link}
                                  to="/cart"
                                  size="large"
                                  basic
                                  icon="arrow left"
                                  floated="right"
                                  content="Back to Cart"
                                  labelPosition="left"
                                />
                              </>
                            ) : (
                              <>
                                <Divider />
                                <Button
                                  basic
                                  icon="arrow left"
                                  labelPosition="left"
                                  size="large"
                                  floated="right"
                                  content="Back"
                                  disabled={userAddresses.length === 0}
                                  onClick={() => handleConfirmOrder(false)}
                                />
                              </>
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <div>Cart is empty...</div>
              )}
            </Segment>
          </CheckoutContainer>
        </>
      )}
    </>
  );
};

export default React.memo(StoreCheckoutPage);
