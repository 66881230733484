import React from 'react'
// import axios from 'axios'
// import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  // Icon,
  Table,
  // Button,
  // Checkbox,
  // Modal,
  // Grid,
  // Pagination,
  // Form as SUIForm,
  Dimmer,
  Loader,
  // Popup,
  // Search,
  // Dropdown,
} from 'semantic-ui-react'

import {
  TableToolbar,
  TableHeader,
  TableRow,
  TableFooter,
} from '../DataTable'

// STYLES
import { StyledContainer } from './Styles'

const DataTable = (props) => {
  const {
    className,
    info,
    schema,
    toolbar,
    data,
    handleFetch,
  } = props

  const { userCoupons } = useSelector(getUserState)
  const { uiFilters, uiTables } = useSelector(getUIState)

  const [ searchString, setSearchString ] = React.useState()

  // const [ pageNumber, setPageNumber ] = React.useState(uiTables.filter(i => i.component === info.namespace)[0].currentPage)
  // const [ pageSize, setPageSize ] = React.useState(5)

  // React.useEffect(() => {
  //   setPageNumber(uiTables.filter(i => i.component === info.namespace)[0].currentPage)
  //   setPageSize(uiTables.filter(i => i.component === info.namespace)[0].pageSize)
  // }, [uiTables, userCoupons])

  return (
    <StyledContainer className={className}>
      {!info.isReady &&
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      }

      <TableToolbar
        info={info}
        toolbar={toolbar}
        handleFetch={handleFetch}
      />

      <Table basic='very' selectable>
        <TableHeader info={info} schema={schema} />
        <Table.Body>
          {data && data.length > 0 ?
            <>
              {data.slice((info.pageNumber - 1) * info.pageSize, info.pageNumber * info.pageSize).map(item => (
                <TableRow info={info} key={`${item.post_date}-${item.id}`} item={item} schema={schema} />
              ))}
            </> :
            <Table.Row>
              <Table.Cell textAlign='center' colSpan={schema.length}>
                <span className='tableEmpty'>Table is empty</span>
              </Table.Cell>
            </Table.Row>
          }
        </Table.Body>
      </Table>

      <TableFooter
        info={info}
        handleFetch={handleFetch}
      />

    </StyledContainer>
  )
}

export default React.memo(DataTable)
