import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {useRefresh, getResponsiveStyle} from 'muuri-react';
import he from 'he'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { consoleLogger } from '@Utils'
import shortNumber from 'short-number'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import uiSlice from '@Slices/uiSlice'
import { getVendorState } from '@Slices/vendorSlice'

// COMPONENTS
import { Icon } from 'semantic-ui-react';

const GalleryItem = (props) => {
  const { item, username, noHelmet, mode } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { page, postid, gid } = useParams()
  const { vendorInfo, vendorProducts, vendorProductIndex } = useSelector(getVendorState) // eslint-disable-line
  const [ ready, setReady ] = React.useState(false)

  const handleItemClick = (ev, id) => {
    ev.preventDefault()
    if (mode === 'gallery') {
      dispatch(uiSlice.actions.setMountViewer(true))
      document.body.classList.add('dimmable', 'dimmed', 'no-scrolling')
      history.push(`/@${username}/gallery/${id}`)
    } else if (mode === 'collections') {
      history.push(`/@${username}/collections/${postid}/${id}`)
    }
  }

  const [ resizeValue, setResizeValue ] = React.useState()
  const handleViewportResize = () => {
    const val = Math.random()
    consoleLogger(val)
    setResizeValue(val)
  }
  
  const handleAfterLoad = () => {
    setReady(true)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleViewportResize)
    handleViewportResize()
    return (() => {
      window.removeEventListener('resize', handleViewportResize)
    })
  }, []) // eslint-disable-line

  useRefresh([ready])

  return (
    <>
      <div className="gallery-col">
        <Link
          to={mode === 'gallery' ? `/@${username}/gallery/${item.ID}` : `/@${username}/collections/${postid}/${item.ID}`}
          onClick={(ev) => handleItemClick(ev, item.ID)}
          className='gallery-item'
        >
          <div className='gallery-item-meta'>
            <div className='meta-block'>
              <Icon name='eye' />
              <span>{shortNumber(item.acf.pageviews)}</span>
            </div>
            <div className='meta-block'>
              <Icon name='heart' />
              <span>{shortNumber(item.acf.likes.length)}</span>
            </div>
            {!!item.acf.limited_edition &&
              <div className='meta-block limited-edition-badge'>
                <span>LE</span>
              </div>
            }
          </div>
          <div className='gallery-item-title'>{he.decode(item.post_title)}</div>
          <LazyLoadImage
            delayMethod='debounce'
            delayTime={1000}
            visibleByDefault={true}
            wrapperClassName='imageWrapper'
            afterLoad={handleAfterLoad}
            effect="blur"
            alt={`thumbnail ${he.decode(item.post_title)}`}
            src={item.media.medium}
          />
        </Link>
      </div>
    </>
  )
}

export default GalleryItem
