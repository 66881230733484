import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  .wrapper {
    padding: 0.5rem;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }
  }

  .termsLoader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5rem;
    .ui.loader {
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      width: 1.5rem;
      height: 1.5rem;
      &:before, &:after {
        width: 1.5rem;
        height: 1.5rem;
      }
      &:before {
        border-color: rgba(0,0,0,0.15);
      }
      &:after {
        border-color: rgba(0,0,0,0.5) transparent transparent;
      }
    }
    > span {
      color: rgba(0,0,0,0.5);
      margin-left: 0.5rem;
      line-height: 1.5rem;
    }
  }

  .term-prefix {
    display: inline-block;
    padding: 0.25rem;
    line-height: 1;
      @media ${stylevars.breakpoints.phone} {
        width: 100%;
        display: block;
      }
  }
  .term {
    display: inline-block;
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    line-height: 1;
    background-color: rgba(${stylevars.paper.borderColorRGB}, 0.5);
    color: ${stylevars.typography.title.color};
    border-radius: 0.25rem;
    cursor: default;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
    flex-grow: 1;
    text-align: center;

    &:hover {
      background-color: rgba(${stylevars.paper.borderColorRGB}, 1);
    }
      @media ${stylevars.breakpoints.phone} {
        max-width: none;
        display: inline-block;
      }
  }
  @media ${stylevars.breakpoints.phone} {
  }
`