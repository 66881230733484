import React from 'react'
import { Message } from 'semantic-ui-react'

const ErrorMessage = () => {
  return (
    <>
      <Message
        icon='exclamation triangle'
        header='Warning'
        content='Something is wrong. It seems the vendor forgot to set the price or selectable printing materials.'
      /> 
    </>
  )
}

export default React.memo(ErrorMessage)