import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import cn from "classnames";

// REDUX
import { useSelector } from "react-redux";
import { getUserState } from "@Slices/userSlice";
import { getVendorState } from "@Slices/vendorSlice";
import { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import StyledContainer from "@Routers/AppRouterStyles";
import ProtectedRoute from "@Routers/ProtectedRoute";
import ProtectedCreatorRoute from "@Routers/ProtectedCreatorRoute";
import AuthRoute from "@Routers/AuthRoutes";
import { AccountCompletion } from "@Common";

// PAGES
import {
  LoginPage,
  LoginCustomerPage,
  LoginVerifyPage,
  NewPasswordPage,
  ResetPasswordPage,
} from "@AdminPages/Login";
import {
  SignupSelect,
  SignupPage,
  SignupPaymentPage,
  SignupCustomerPage,
  SignupWelcomePage,
} from "@AdminPages/Signup";
import { CreatePage } from "@AdminPages/Create";
import { SplashPage, MaintenancePage } from "@AdminPages/Splash";
import { HomePage } from "@AdminPages/Home";
import { DiscoverPage, AllCreatorsPage } from "@StorePages/Discover";
import { DashboardPage } from "@AdminPages/Dashboard";
import {
  CollectionsPage,
  CollectionsEditor,
  CollectionsNew,
} from "@AdminPages/Collections";
import { GalleryPage, EditGalleryPage } from "@AdminPages/Gallery";
import { LayoutPage } from "@AdminPages/Layout";
import OrdersPage from "@AdminPages/Orders";
import { AccountPage } from "@AdminPages/Account";
import PageNotFound from "@AdminPages/404";
import { AboutPage } from "@AdminPages/About";
import { CertificatePage } from "@StorePages/Certificate";

// DOCUMENT PAGES
import {
  CreatorGuidePage,
  FAQPage,
  TermsPage,
  PrivacyPolicyPage,
} from "@AdminPages/Documents";

// STORE FRONT PAGES
import { StoreUserPage } from "@StorePages/User";
import {
  StoreCartPage,
  StoreCheckoutPage,
  OrderStatusPage,
} from "@StorePages/Cart";

const AppRouter = () => {
  const { authenticated } = useSelector(getUserState);
  const { vendorInfo } = useSelector(getVendorState);
  const { siteSettings } = useSelector(getUIState);
  const location = useLocation();
  return (
    <StyledContainer
      className={cn("mainContent", {
        [vendorInfo ? `theme-${vendorInfo.acf.layout_style}` : ``]:
          vendorInfo &&
          vendorInfo.acf.layout_style &&
          location.pathname.indexOf("/user/") > -1,
      })}
    >
      <>
        {siteSettings && siteSettings.siteMode === 1 ? (
          <>
            {authenticated && <AccountCompletion />}
            <Switch>
              <Route path="/" component={HomePage} exact />
              <ProtectedRoute path="/admin" component={DashboardPage} exact />
              <Route path="/login" component={LoginPage} exact />
              <Route
                path="/login/collector"
                component={LoginCustomerPage}
                exact
              />
              <Route
                path="/login/verify/:userid/:key"
                component={LoginVerifyPage}
                exact
              />
              <Route
                path="/login/verify/:userid/:key/redirect/:path"
                component={LoginVerifyPage}
                exact
              />
              <Route path="/signup" component={SignupSelect} exact />
              <Route path="/signup/creator" component={SignupPage} exact />
              <Route
                path="/signup/payment/:userid/:key"
                component={SignupPaymentPage}
                exact
              />
              <Route
                path="/signup/collector"
                component={SignupCustomerPage}
                exact
              />
              <Route
                path="/resetpassword"
                component={ResetPasswordPage}
                exact
              />
              <Route path="/welcome" component={SignupWelcomePage} exact />
              <Route
                path="/password/new/:userId/:key"
                component={NewPasswordPage}
                exact
              />
              <AuthRoute
                path="/signup/collector/redirect/:path"
                component={SignupCustomerPage}
                exact
              />
              <AuthRoute
                path="/login/redirect/:path"
                component={LoginPage}
                exact
              />
              <AuthRoute
                path="/login/collector/redirect/:path"
                component={LoginCustomerPage}
                exact
              />
              <AuthRoute
                path="/signup/redirect/:path"
                component={SignupPage}
                exact
              />

              <ProtectedRoute
                path="/admin/dashboard"
                component={DashboardPage}
                exact
              />
              <ProtectedRoute
                path="/admin/collections"
                component={CollectionsPage}
                exact
              />
              <ProtectedRoute
                path="/admin/collections/view/:collectionId"
                component={CollectionsEditor}
                exact
              />
              <ProtectedRoute
                path="/admin/collections/new"
                component={CollectionsNew}
                exact
              />
              <ProtectedRoute
                path="/admin/gallery"
                component={GalleryPage}
                exact
              />
              <ProtectedRoute
                path="/admin/gallery/view/:gallery_id"
                component={EditGalleryPage}
                exact
              />
              <ProtectedRoute
                path="/admin/layout"
                component={LayoutPage}
                exact
              />
              <ProtectedRoute
                path="/admin/layout/:tab"
                component={LayoutPage}
                exact
              />

              <ProtectedRoute
                path="/admin/orders"
                component={OrdersPage}
                exact
              />
              <ProtectedRoute
                path="/admin/orders/:tab"
                component={OrdersPage}
                exact
              />
              <ProtectedRoute
                path="/admin/account"
                component={AccountPage}
                exact
              />
              <ProtectedRoute
                path="/admin/account/:tab"
                component={AccountPage}
                exact
              />
              <ProtectedRoute
                path="/admin/account/:tab/:id"
                component={AccountPage}
                exact
              />

              {/* DOCUMENTS */}
              <Route path="/creator-guide" component={CreatorGuidePage} exact />
              <Route path="/faq" component={FAQPage} exact />
              <Route path="/terms" component={TermsPage} exact />
              <Route path="/privacy" component={PrivacyPolicyPage} exact />
              <Route path="/about" component={AboutPage} exact />

              {/* STORE FRONT */}
              <Route path="/@:username" component={StoreUserPage} exact />
              <Route path="/@:username/:page" component={StoreUserPage} exact />
              <Route
                path="/@:username/:page/:postid"
                component={StoreUserPage}
                exact
              />
              <Route
                path="/@:username/:page/:postid/:gid"
                component={StoreUserPage}
                exact
              />
              <Route path="/cart" component={StoreCartPage} exact />
              <Route path="/checkout" component={StoreCheckoutPage} exact />
              <Route
                path="/checkout/:step"
                component={StoreCheckoutPage}
                exact
              />
              <Route path="/discover" component={DiscoverPage} exact />
              <Route path="/creators" component={AllCreatorsPage} exact />
              <Route path="/create" component={CreatePage} exact />
              <Route
                path="/orders/:orderid"
                component={OrderStatusPage}
                exact
              />
              <Route
                path="/certificate/:key"
                component={CertificatePage}
                exact
              />

              <Route path="*" component={PageNotFound} />
            </Switch>
          </>
        ) : (
          <Switch>
            <Route path="*" component={MaintenancePage} />
          </Switch>
        )}
      </>
    </StyledContainer>
  );
};

export default React.memo(AppRouter);
