import React from 'react'
import { Link, useParams, useHistory, useLocation } from "react-router-dom"
import axios from 'axios'
import { consoleLogger, setAuthorizationHeader } from '@Utils'
import jwtDecode from 'jwt-decode'
import cn from 'classnames'
// import { Helmet } from 'react-helmet'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'
import cartSlice from '@Slices/cartSlice'

// COMPONENTS
import { Grid, Button, Form as SUIForm, Message } from 'semantic-ui-react'
import {FormBox, StyledCard} from './Styles'
import ReactCodeInput from 'react-verification-code-input';
import ThankYou from './CustomerThankYou'

// VALIDATION SCHEMA
Yup.addMethod(Yup.string, 'nospecial', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    value = originalValue.matches(/[^a-zA-Z0-9]/)

    consoleLogger(originalValue, value)
    return value ? value.toString() : ''
  });
});
const validationSchema = Yup.object().shape({
  verification_code: Yup.string()
    .min(8, "Must be at least 8 characters long")
    .max(8, "Must be shorter than 8 characters")
    .test('nospecial', 'Special characters are not allowed', (val) => {
      const pattern = /^[a-zA-Z0-9]+$/
      return (
        pattern.test(val)
      )
    })
    .required('Must enter verification code')
})

const VerifyForm = (props) => {
  const { className, to, redirect, customer, ...restProps } = props
  const history = useHistory()
  const location = useLocation()
  const token = localStorage.Authorization
  const dispatch = useDispatch()
  const { authenticated } = useSelector(getUserState)
  const { path, userid, key } = useParams()
  const decodedPath = decodeURIComponent(path)
  const decodedRedirect = decodeURIComponent(location.pathname)
  consoleLogger('decodedPath =======================', decodedPath, decodedRedirect)
  const [ signupLink, setSignupLink ] = React.useState('/signup/collector')
  const [ ready, setReady ] = React.useState(false)
  const [ errors, setErrors ] = React.useState()
  const [verified, setVerified] = React.useState(false)
  const [ fieldReady, setFieldReady ] = React.useState(false)
  const [ regValid, setRegValid ] = React.useState(false)
  const [ regData, setRegData ] = React.useState()
  const [ loginRedirect, setLoginRedirect ] = React.useState()

  React.useEffect(() => {
    if (path) {
      setLoginRedirect(encodeURIComponent(path))
    }
  }, [path]) //eslint-disable-line

  React.useEffect(() => {
    (async () => {
      dispatch(uiSlice.actions.setPageTemplate('store'))
      dispatch(uiSlice.actions.setCurrentPage('email verification'))
      dispatch(uiSlice.actions.setCurrentPageURL())
      dispatch(uiSlice.actions.setCurrentPageTitle())
      
      if (redirect) {
        setSignupLink(state => (`${state}/redirect${decodedRedirect}`))
      }

      // check if creator is already a subscriber
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/checkreg`,
        // headers: {
        //   Authorization: `${token}`,
        // },
        method: 'POST',
        data: {
          author: userid,
          id: key,
          type: 'customer'
        },
      }).then(res => {
        if (!res.data.verified) {
          setRegValid(true)
          setRegData(res.data)
        } else {
          setErrors({message: 'Registration session is no longer valid.'})
        }
      }).catch(err => {
        const errorData = err.response.data
        consoleLogger(err)
        setErrors(errorData)
      })
      setReady(true)
    })()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('LOGIN PAGE')
    if (authenticated) {
      history.push('/admin')
    }
  }, [authenticated, history]) // eslint-disable-line

  return (
    <FormBox className={cn('formBoxContainer', {
      [className] : className
    })}>
      <Formik
        initialValues={{
          verification_code: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(data, {setSubmitting}) => {
          (async () => {
            let tempUserInfo
            setErrors()
            // const decodedToken = jwtDecode(token)
            // const userId = decodedToken.data.user.id
            // const userKey = decodedToken.data.user_key
            dispatch(userSlice.actions.setAuthenticated(false))
            setSubmitting(true)
            const newData = {
              ...data,
              reg_key: key,
              author: userid,
              // user_key: userKey
            }
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/stylefolio/v1/verify`,
              method: 'POST',
              data: newData,
              // headers: {
              //   Authorization: `${token}`,
              // },
            })
            .then(res => {
              (async() => {
                consoleLogger('VERIFICATION RESPONSE =====================', res.data)
                tempUserInfo = res.data
                dispatch(userSlice.actions.setAuthenticated(false))
                localStorage.removeItem('Authorization')
                localStorage.removeItem('coupons')
                localStorage.removeItem('cartItems')
                dispatch(cartSlice.actions.setCart([]))
                setVerified(true)
              })()
            })
            .catch(err => {
              setSubmitting(false)
              setVerified(false)
              // const errorData = err
              consoleLogger("LOGIN ERROR: ", err.response.data)
              setErrors(err.response.data)
              // if (err.response.data.code === 'incorrect_code') {
              //   setErrors({verification_code: err.response.data.message})
              // } else {
              //   setErrors(err.response.data)
              // }
              
            })
            
            // add to newsletter database
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/newsletter/v2/subscribers`,
              method: 'POST',
              params: {
                client_key: process.env.REACT_APP_WP_NEWSLETTER_KEY,
                client_secret: process.env.REACT_APP_WP_NEWSLETTER_SECRET
              },
              data: {
                "email": tempUserInfo.email,
                "first_name": tempUserInfo.first_name,
                "last_name": tempUserInfo.last_name,
                "lists": [
                  {
                    "id": 1,
                    "value": 0
                  },
                  {
                    "id": 2,
                    "value": 1 // COLLECTOR
                  },
                  {
                    "id": 3,
                    "value": 0
                  },
                  {
                    "id": 4,
                    "value": 0
                  },
                ],
                "status": "confirmed"
              }
            }).then(res => {
              consoleLogger('++++++++++++++++++++++++++ USER added to COLLECTORS newsletter list')
            }).catch(err => {
              consoleLogger('-------------------------- ERROR in adding USER to COLLECTORS newsletter list', err)
            })

            setSubmitting(false)
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setValues, setFieldValue, isValid, dirty}) => (
          <Form className={cn('ui form', {
            [className]: className
          })}>
            <Grid centered padded>
              <Grid.Column className='formBox'>
                {ready ?
                  <>
                    {regValid ?
                      <>
                        {verified ?
                          <>
                            <ThankYou redirect={loginRedirect} />
                          </> : 
                          <>
                            <h1>Email Verification</h1>
                            <StyledCard>
                              <StyledCard.Content>
                                <p>We have sent a verification code to your email address (<span className='text--bold'>{regData.email_placeholder}</span>). Please check your inbox or in some cases you might want to check your spam folder as well.</p>
                                <span className='text--bold'>Verification Code</span>
                                <input type='hidden' name='verification_code' />
                                <ReactCodeInput
                                  className='codebox'
                                  fields={8}
                                  type='text'
                                  onChange={(ev) => {
                                    setFieldValue('verification_code', ev)
                                    consoleLogger(ev)
                                    if (ev.length < 8) {
                                      setFieldReady(false)
                                    }
                                  }}
                                  onComplete={() => setFieldReady(true)}
                                />
                              </StyledCard.Content>
                              <StyledCard.Content extra>
                                <div className='ui two buttons'>
                                  <Button
                                    type='submit'
                                    color='green'
                                    onClick={() => handleSubmit()}
                                    loading={isSubmitting}
                                    disabled={!(fieldReady && !isSubmitting)}
                                  >
                                    Verify
                                  </Button>
                                </div>
                              </StyledCard.Content>
                              <StyledCard.Content extra className='subCard'>
                                <p>Don't have an account yet? <Link to={`/signup`}>Signup now!</Link></p>
                              </StyledCard.Content>
                            </StyledCard>
                            {errors && errors.message &&
                              <Message negative>
                                {errors.message}
                              </Message>
                            }
                          </>
                        }
                      </> :
                      <>
                        <h1>Oh... Snap!</h1>
                        <StyledCard>
                          <StyledCard.Content>
                            <p>Registration session is no longer valid.</p>
                          </StyledCard.Content>
                        </StyledCard>
                      </>
                    }
                  </> :
                  <>
                    <h1>Email Verification</h1>
                    <StyledCard>
                      <StyledCard.Content>
                        <p>Checking...</p>
                      </StyledCard.Content>
                    </StyledCard>
                  </>
                }
              </Grid.Column>
            </Grid>
          </Form>
        )}
      </Formik>
    </FormBox>
  )
}

export default React.memo(VerifyForm)
