import React from 'react'

import { DocumentContainer } from './Styles'

const FAQContent = () => {
  return (
    <DocumentContainer>
      <h1>Frequently Asked Questions</h1>
      <div className='faqs'>
        <div className='item'>
          <p className='question'>Do you need an account for you to buy from your favorite artist?</p>
          <p className='answer'>Yes. So you can track your order in the website.</p>
        </div>
        <div className='item'>
          <p className='question'>How much will it cost to get into the platform?</p>
          <p className='answer'>There is a basic plan that costs $2.99 per month and a Pro plan that costs $9.99 per month.</p>
        </div>
        <div className='item'>
          <p className='question'>How many images would a starter plan have?</p>
          <p className='answer'>A starter plan would allow you to have a maximum of 10 images in your gallery.</p>
        </div>
        <div className='item'>
          <p className='question'>How many images would a pro plan have?</p>
          <p className='answer'>A pro plan would allow you to have a maximum of 100 images in your gallery.</p>
        </div>
        <div className='item'>
          <p className='question'>What's the difference between the starter and the pro plan?</p>
          <p className='answer'>
            <div>Starter Plan: 15% fee, 1 Theme, Gallery with 10 images and 1 Collection.</div>
            <div>Pro Plan: 5% fee, 3 Themes, gallery with 100 images and a 100 Collections &amp; Limited Edition.</div>
          </p>
        </div>
        <div className='item'>
          <p className='question'>What payment platform are we accepting?</p>
          <p className='answer'>We currently accept all major credit card payments through PayPal.</p>
        </div>
        <div className='item'>
          <p className='question'>How big are the maximum files accepted in the gallery?</p>
          <p className='answer'>Ideal size is 2048px 72 dpi on the long side and a maximum file size of 2.5mb</p>
        </div>
        <div className='item'>
          <p className='question'>When and how do we send high resolution files for printing?</p>
          <p className='answer'>When the sale has been received on our system, the StyleFolio team will contact the creator through email and will ask the creator to upload a high resolution copy on a Dropbox account provided by StyleFolio.</p>
        </div>
        {/* <div className='item'>
          <p className='question'>Can we trust the printer partner for the high resolution files safety?</p>
          <p className='answer'>aaaa</p>
        </div> */}
        <div className='item'>
          <p className='question'>When are payouts being sent to the creator?</p>
          <p className='answer'>By default, minimum payout is set to $100. Once this minimum is reached, the creator can request a payout through his/her account page.</p>
        </div>
      </div>
    </DocumentContainer>
  )
}

export default React.memo(FAQContent)
