import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const AvatarContainer = styled('div')`
  position: relative;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  width: 7rem;
  height: 7rem;
  background-color: ${props => props.useProRing ? props.bgColor : stylevars.paper.bgColor};
  background-image: ${props => props.useProRing ? `linear-gradient(55deg, ${stylevars.palette.secondary.main}, ${stylevars.palette.primary.main})` : `linear-gradient(55deg, rgba(${props.bgColor}), rgba(${props.bgColor}))`};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.pro {
      background-image: linear-gradient(55deg, ${stylevars.palette.secondary.main}, ${stylevars.palette.primary.main});
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    width: 92%;
    height: 92%;
    display: block;
    line-height: 0;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    background-color: ${props => props.bgColor};
  }
`