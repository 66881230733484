import React from 'react'
import { stylevars } from '@Common/StyleVars'
import styled from 'styled-components'

const Container = styled.div`
  .tableEmpty {
    font-style: italic;
    color: ${stylevars.typography.extra.color};
  }
  .ui.table {
    thead {
      th {
        padding: 0;
        &:first-of-type {
          .tableHeaderCell_sort, .tableHeaderCell_nonsort {
            padding-left: 0;
          }
        }
      }
    }
  }

  .tableHeaderToolbar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(${stylevars.paper.borderColorRGB}, 0.5);
    padding: 0.5rem;
    margin: -1rem;
    margin-bottom: 1rem;

    @media ${stylevars.breakpoints.phone} {
      width: 100%;
      margin-top: 1rem;
      position: relative;
      top: auto;
      right: auto;
    }

    /* .tableHeaderToolbar_label */
    &_label {
      flex-grow: 1;
      margin: 0 !important;
      padding: 0 !important;
      padding-left: 0.5rem !important;
    }
  }
  .tableHeaderCell_sort {
    cursor: pointer;
    position: relative;
    padding: 0.78571429em 0.78571429em;
    padding-bottom: 0.5em;
    border-bottom: 2px solid rgba(${stylevars.palette.info.rgb}, 0);
    margin-bottom: -2px;
    &:hover {
      color: ${stylevars.palette.info.main};
      > .icon {
        opacity: 1;
      }
    }
    &.sorted {
      border-bottom: 2px solid rgba(${stylevars.palette.info.rgb}, 1);
      > .icon {
        opacity: 1;
      }
    }
    > .icon {
      position: absolute;
      right: 0rem;
      top: 50%;
      opacity: 0;
      width: 1rem;
      height: 1.5rem;
      transform: translateY(-50%);
      color: ${stylevars.palette.info.main};
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .tableHeaderCell_nonsort {
    padding: 0.78571429em 0.78571429em;
  }
  .tableRow {
    position: relative;
  }
  .tableCell {
    &.sorted {
      background-color: rgba(${stylevars.palette.info.rgb}, 0.05) !important;
    }
  }
  .tableFooter {
    border-top: 1px solid ${stylevars.paper.borderColor};
    padding-top: 1rem;
  }
  .tableFilterDropdown {
    > .button {
      margin-right: 0;

      .filterGroup {
        padding-left: 0.5rem;
        > span {
          display: inline-block;
          position: relative;
          padding: 0rem 0.5rem;
          border-radius: 0.15rem;
          background-color: rgba(0, 0, 0, 0.25);
          color: #fff;
          font-size: 0.75rem;
          letter-spacing: 0.05rem;
          text-transform: uppercase;
          &:not(:last-of-type) {
            margin-right: 0.25rem;
          }
        }
      }
      &.blue {
        .filterGroup > span {
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }
    > .dropdown.icon {
      display: none;
    }
  }
  .tableSearchField {
    .icon.close {
      border: none;
      box-shadow: none;
    }
  }

  .maskedString {
    position: relative;
    > span {
      transition: opacity 0.15s ease-out;
    }
    .masked {
      opacity: 1;
    }
    .string {
      position: absolute;
      opacity: 0;
      left: 0;
    }

    &:hover {
      .string {
        opacity: 1;
      }
      .masked {
        opacity: 0;
      }
    }
  }
  .actionButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem;
    padding: 0rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    > i {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      line-height: 1 !important;
      &:before {
        line-height: 1 !important;
      }
    }
  }
`

export const StyledContainer = (props) => {
  const {
    className,
    children,
    ...restProps
  } = props
  return (
    <Container className={className} {...restProps}>
      {children}
    </Container>
  )
}

export default React.memo(StyledContainer)