import React from 'react'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import currency from 'currency.js'
import { maskEmail } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import { Dimmer, Icon, Loader } from 'semantic-ui-react'

const shortNumber = require('short-number')

const WidgetGalleryCount = (props) => {
  const token = localStorage.Authorization
  const { userInfo, userGallery } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  const [ tempTotal, setTempTotal ] = React.useState(0)
  const [ widgetValue, setWidgetValue ] = React.useState(0)
  const [ floatDigit, setFloatDigit ] = React.useState(0)
  const [ ready, setReady ] = React.useState(false)

  React.useEffect(() => {
    (async() => {
      const tempAmount = userGallery.length
      setTempTotal(tempAmount)
      if (tempAmount > 10000) {
        setWidgetValue(shortNumber(tempAmount))
      } else {
        setWidgetValue(tempAmount)
      }
      // check user subscription if premium
      if (userInfo.acf.subscription_type === 'premium') {
        setFloatDigit(siteSettings.premiumLimit)
      } else {
        setFloatDigit(siteSettings.starterLimit)
      }
      setReady(true)
    })()
  }, []) // eslint-disable-line

  return (
    <div className='widget'>
      {!ready &&
        <Dimmer inverted active>
          <Loader>Updating...</Loader>
        </Dimmer>
      }
      <div className='widgetWrapper'>
        <div className='label'>Total Gallery Items</div>
        <div className='content'>
          <Icon name='images' color='blue' size='big' />
          <div className='value'>
            <span className='amount'>
              {tempTotal > 10000 ?
                <>{widgetValue}</> :
                <><NumberFormat fixedDecimalScale={false} value={widgetValue} displayType={'text'} thousandSeparator={true} /></>
              }
            </span>
            <span className='float'>
              {`/`}
              {floatDigit}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(WidgetGalleryCount)
