import React from 'react'
import filesize from 'filesize'
import { Button, Dimmer, Loader, Progress } from 'semantic-ui-react'

import { ThumbContainer } from './UploaderThumbsStyles'

const GalleryUploaderThumbs = (props) => {
  const {files, thumbs, handleFiles, handleThumbs, isUploading, open} = props
  const handleRemove = (index) => {
    handleThumbs(prev => prev.slice(0, index).concat(prev.slice(index + 1, prev.length)))
    handleFiles(prev => prev.slice(0, index).concat(prev.slice(index + 1, prev.length)))
  }
  return (
    <>
      <ThumbContainer className='thumbnails'>
        {thumbs.map((file, index) => (
          <div key={`${file.name}-${file.index}`} className='thumbnail'>
            <div className='thumbnailWrapper'>
              <div className='image'>
                {isUploading && file.uploading &&
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                }
                <img
                  src={file.preview}
                  alt={file.name}
                  className='thumbnailImage'
                />
              </div>
              <div className='details'>
                <div className='name'>{file.name}</div>
                <div className='size'>SIZE: {filesize(file.size)}</div>
              </div>
              {!isUploading &&
                <Button negative size='tiny' circular icon="remove circle" className='thumbnailRemove' onClick={() => handleRemove(index)} />
              }
              {isUploading &&
                <div className='progressBar'>
                  <Progress percent={(file.progress / file.size) * 100} size='tiny' indicating />
                </div>
              }
            </div>
          </div>
        ))}
      </ThumbContainer>
      {!isUploading &&
        <div className='thumbnailsAction'>
          <p>drag and drop or just click the button below</p>
          <Button icon="plus" labelPosition="right" content="Add more" primary className="uploaderButton" onClick={open} />
        </div>
      }
    </>
  )
}

export default React.memo(GalleryUploaderThumbs)
