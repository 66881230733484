import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CollectionsFormContainer = styled('div')`
  position: relative;
  min-height: 240px;

  .section {
    .section-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      @media ${stylevars.breakpoints.phone} {
        display: block;
      }
    }
    .column {
      @media ${stylevars.breakpoints.phone} {
        width: 100%;
      }
    }
  }

  .coverColumn {
    width: 70%;
  }

  .detailsColumn {
    padding: 1rem;
    width: 30%;
    background-color: ${stylevars.paper.bgColor};
    .details {
      width: 100%;
      height: 100%;
    }
    textarea {
      resize: none;
    }
  }

  .formGridRow {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${stylevars.paper.borderColor};

    &:first-of-type {
      padding-top: 1rem;
    }

    &:last-of-type {
      padding-bottom: 1rem;
    }
  }

  .formLabelColumn {
    font-weight: bold;
    &.adjust {
      margin-top: 0.5rem !important;
    }
  }

  > .form {
    > .actions {
      position: sticky !important;
      bottom: 0rem;
      z-index: 10;
      /* left: calc(${stylevars.sideBar.width} + 2rem);
      width: calc(calc(var(--vw, 1vw) * 100) - ${stylevars.sideBar.width} - 5rem + 2px);
      @media ${stylevars.breakpoints.tablet} {
        left: 2rem;
        width: calc(calc(var(--vw, 1vw) * 100) - 5rem + 2px);
      }
      @media ${stylevars.breakpoints.phone} {
        left: 1rem;
        width: calc(calc(var(--vw, 1vw) * 100) - 3rem + 2px);
      } */
      .actionsGroup {
        border-top: 1px solid ${stylevars.paper.borderColor};
        padding: 1rem 0;
        background-color: ${stylevars.paper.bgColor};
        z-index: 1001;
        width: 100%;
        height: ${stylevars.navBar.height};
        text-align: right;
        display: flex;
        flex-wrap: nowrap;
        @media ${stylevars.breakpoints.phone} {
          /* display: block; */
        }
        .productActionNav {
          padding-left: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          /* .productActionNavText */
          &Text {
            font-size: 1rem;
            margin-right: 0.5rem;
            > span {
              font-size: 0.85rem;
              color: rgba(0, 0, 0, 0.5);
            }
          }
          /* .productActionNavGroup */
          &Group {
            .ui.button {
              box-shadow: none;
            }
          }

          @media ${stylevars.breakpoints.phone} {
            padding: 0 1rem;
          }
        }
        .actionButtons {
          padding-right: 1rem;
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @media ${stylevars.breakpoints.phone} {
            padding: 0 1rem;
            /* justify-content: center; */

            .ui.button {
              min-width: auto;
              width: auto;
              padding: 0 !important;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3rem;
              height: 3rem;
              > .icon {
                margin: 0;
                position: relative;
                right: auto;
                top: auto;
                background-color: transparent !important;
              }
              > span {
                display: none;
              }
            }
          }
        }
      }
    }

    .empty {
      padding: 2rem;
      text-align: center;
      font-style: italic;
      color: ${stylevars.typography.subtitle.color};

      > span {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }

  .galleryGridContainer {
    position: relative;
  }
`