import React from 'react'
// import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import he from 'he'
import { format, formatDistanceToNow } from 'date-fns'
import currency from 'currency.js'
import { consoleLogger, MaskCharacter } from '@Utils'
import { currencySymbols } from '@Utils/forexData'

// REDUX
// import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
// import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
// import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import {
  // Icon,
  // Label,
  Table,
  Button,
  Checkbox,
  // Modal,
  // Grid,
  // Pagination,
  // Form as SUIForm,
  Dimmer,
  Loader,
  Progress,
  // Popup,
  // Search,
  // Dropdown,
} from 'semantic-ui-react'

const ActionButton = React.memo(({button, item, handleUpdateRow}) => {
  const {
    component:Component,
    ...restProps
  } = button
  const [ open, setOpen ] = React.useState(false)
  const handleOpen = (bool) => {
    setOpen(bool)
  }
  switch(button.key) {
    case 'confirm':
      return (
        <>
          <Button key={`${item.ID}-${button.key}`} {...restProps} className='actionButton' circular size='mini' compact onClick={() => handleOpen(true)} />
          {open && <Component data={item} handleOpen={handleOpen} handleUpdateRow={handleUpdateRow} />}
        </>
      )
    default:
      return <Button key={`${item.ID}-${button.key}`} {...restProps} className='actionButton' circular size='mini' compact onClick={() => button.action(item)} />
  }
})

const CellContent = React.memo((props) => {
  const {
    info,
    cell,
    item,
    handleUpdateRow,
    isUpdating
  } = props

  switch(cell.type) {
    case 'buttons':
      return (
        <>
          {cell.buttons && cell.buttons.map(button => (
            <ActionButton button={button} item={item} handleUpdateRow={handleUpdateRow} />
          ))}
        </>
      )
    case 'currency':
      return (
        <>
          {currency(cell.meta ? item[cell.meta][cell.field] : item[cell.field], {symbol: '$', precision: 2}).format()}
        </>
      )
    case 'amountvalue':
      return (
        <>
          {currency(cell.meta === 'paypal' ? item.acf.amount.value : item[cell.field], {symbol: currencySymbols[item.acf.amount.currency_code], precision: 2}).format()}
        </>
      )
    case 'discount':
      return (
        <>
          {item.acf.type === 'amount' ?
            <>
              {currency(cell.meta ? item[cell.meta][cell.field] : item[cell.field], {symbol: '$', precision: 2}).format()}
            </> : 
            <span>
              {`${cell.meta ? item[cell.meta][cell.field] : item[cell.field]}%`}
            </span>
          }
        </>
      )
    case 'count':
      return (
        <>
          {item.acf.count}
          {` / `}
          {currency(cell.meta ? item[cell.meta][cell.field] : item[cell.field], {symbol: '', precision: 0}).format()}
        </>
      )
    case 'progress':
      // const count = parseInt(cell.meta ? item[cell.meta][cell.field] : item[cell.field])
      const available = parseInt(cell.meta ? item[cell.meta][cell.field] : item[cell.field])
      const count = Math.floor(Math.random() * (available - 1) + 1)
      const percent = (1 - ((available - count) / available)) * 100
      let colorProps = {}
      if (percent > 50 && percent < 80) {
        colorProps = {warning: true}
      } else if (percent > 80) {
        colorProps = {error: true}
      } else {
        colorProps = {success: true}
      }
      const progLabel = `${currency(count, {symbol: '', precision: 0}).format()} / ${currency(available, {symbol: '', precision: 0}).format()}`
      return (
        <Progress percent={percent} label={progLabel} size='tiny' {...colorProps} />
      )
    case 'checkbox':
      const status = cell.meta ? item[cell.meta][cell.field] : item[cell.field]
      return (
        <>
          {cell.key === 'post_status' ?
            <Checkbox
              toggle
              checked={status === 'publish'}
              onChange={() => {
                handleUpdateRow(true)
                cell.action(item.ID, cell.meta ? item[cell.meta][cell.field] : item[cell.field], handleUpdateRow)
              }}
            /> : 
            <Checkbox
              onChange={() => {
                handleUpdateRow(true)
              }}
            />
          }
        </>
      )
    case 'index':
      return (
        <>
          {isUpdating &&
            <Dimmer inverted active>
              <Loader />
            </Dimmer>
          }
          {cell.meta ? item[cell.meta][cell.field] : item[cell.field]}
        </>
      )
    case 'date-distance':
      const dateDistance = new Date((cell.meta ? item[cell.meta][cell.field] : item[cell.field]) * 1000)
      const formatedDateDistance = formatDistanceToNow(
        new Date(dateDistance),
        {addSuffix: true}
      )
      return (
        <>
          {formatedDateDistance}
        </>
      )
    case 'date':
      const date = new Date((cell.meta ? item[cell.meta][cell.field] : item[cell.field]) * 1000)
      const formatedDate = format(
        new Date(date),
        cell.format
      )
      return (
        <>
          {formatedDate}
        </>
      )
    case 'enum':
      const enumValue = cell.meta ? item[cell.meta][cell.field] : item[cell.field]
      const enumLabel = cell.enum.filter(i => i.key === enumValue).map(n => n.label)
      const enumColor = cell.enum.filter(i => i.key === enumValue).map(n => n.color)
      const EnumComponent = cell.component ? cell.component : 'div'
      return (
        <EnumComponent color={cell.component ? enumColor : null}>{enumLabel}</EnumComponent>
      )
    case 'masked':
      const string = cell.meta === 'acf' ? item[cell.meta][cell.field] : item[cell.field]
      const maskedString = MaskCharacter(string, cell.mask, cell.showLength)
      return (
        <div className='maskedString'>
          <span className='masked'>{maskedString}</span>
          <span className='string'>{string}</span>
        </div>
      )
    default:
      return (
        <>
          {cell.meta ? item[cell.meta][cell.field] : item[cell.field]}
        </>
      )
  }
})

const TableRow = (props) => {
  const {
    info,
    item,
    schema,
  } = props

  // const { uiTables } = useSelector(getUIState)

  // React.useEffect(() => {
  //   consoleLogger('item ==================', item)
  // }, [item])

  const [ isUpdating, setIsUpdating ] = React.useState(false)
  const handleUpdateRow = (bool) => {
    setIsUpdating(bool)
  }
  // const popupTitle = item.post_title ? item.post_title : null
  return (
    <Table.Row className='tableRow'>
      {schema && schema.map(cell => (
        <Table.Cell
          width={cell.width}
          key={cell.key}
          textAlign={cell.textAlign}
          collapsing={cell.collapsing}
        >
          <CellContent info={info} cell={cell} item={item} handleUpdateRow={handleUpdateRow} isUpdating={isUpdating} />
        </Table.Cell>
      ))}
    </Table.Row>
  )
}

export default React.memo(TableRow)
