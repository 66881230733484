import React from 'react'
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { consoleLogger, checkBrightness } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getVendorState } from '@Slices/vendorSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getCartState } from '@Slices/cartSlice'

// COMPONENTS
import { StyledNavBar } from './NavBarStyles'
import {
  Header,
  Menu,
  Button,
  Icon,
} from 'semantic-ui-react'
import { GlobalSearch, ShoppingCartMenu, UserDropdownMenu } from './'

const NavBar = React.memo((props) => {
  const [ storageCartItems, setStorageCartItems ] = React.useState(localStorage.cartItems && localStorage.cartItems.length > 0 ? JSON.parse(localStorage.cartItems).items : [])
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPathname = location.pathname
  const { authenticated, userInfo } = useSelector(getUserState)
  const { vendorInfo } = useSelector(getVendorState)
  const { sideBarShow, pageScrolled, currentPage, currentPageURL, currentPageTitle, pageTemplate, themeIsDark, currency } = useSelector(getUIState)
  const { cartItems, carts } = useSelector(getCartState) // eslint-disable-line

  // const [activeItem, setActiveItem] = React.useState('home')
  const handleBrandingClick = () => {
    history.push('/')
  }
  const [open, setOpen] = React.useState(false)
  const handleModalOpen = (bool) => {
    setOpen(bool)
  }

  const handleSidebarToggle = () => {
    dispatch(uiSlice.actions.setSideBarShow(!sideBarShow))
  }

  // const [ showMenu, setShowMenu ] = React.useState(false)
  // const handleMenuToggle = () => {
  //   consoleLogger("================== handleMenuToggle")
  //   setShowMenu(prev => !prev)
  // }

  React.useEffect(() => {
    // const sss = carts.map(i => i.items.map(item => ({...item})))
    // consoleLogger('>>>>>>>>>>>>>>>>>>>>>', sss)

    let arr = []
    carts.forEach(cart => {
      cart.items.forEach(item => {
        arr.push(item)
      })
    })
    localStorage.setItem('cartItems', JSON.stringify(arr))
    setStorageCartItems(arr)
    
  }, [carts])
  // consoleLogger('storageCartItems ======================', storageCartItems)

  const [ cartOpen, setCartOpen ] = React.useState(false)

  React.useEffect(() => {
    if (vendorInfo) {
      const brightness = checkBrightness(vendorInfo.acf.custom_stylesheet.bgColor)
      consoleLogger('============== brightness ===============', brightness)
      dispatch(uiSlice.actions.setThemeIsDark(brightness < 125))
    }
  }, [vendorInfo]) // eslint-disable-line

  return (
    <>
        <StyledNavBar
          className={cn('mainNavBar', {
            'dark': themeIsDark
          })}
        >
          <Button basic className='sideBarToggle' onClick={handleSidebarToggle}>
            <Icon name='bars' />
          </Button>
          <Menu secondary className='titlebar'>
            <Menu.Item
              name='home'
              onClick={handleBrandingClick}
            >
              <Header>
                <img src='/logo_light.svg' className='logo' />
                <img src='/logo_dark.svg' className='logo logo--dark' />
                <img src='/logo192.svg' className='icon' />
                <img src='/logo192_dark.svg' className='icon icon--dark' />
              </Header>
            </Menu.Item>
          </Menu>
          <div className='pageLabel'>
            <span>
              {currentPageURL ?
                <>
                  <Link to={`/${currentPageURL}`}>{currentPage}</Link>{` / ${currentPageTitle}`}
                </> :
                currentPage
              }
            </span>
          </div>
          <div className={cn('menuWrapper', {
              // 'show': showMenu
            })}>
            {/* <Button icon='bars' circular className='menuToggle' onClick={() => handleMenuToggle()} /> */}
            {/* <div className='menuOverlay' onClick={() => handleMenuToggle()} /> */}
            <Menu secondary position='right'>
              {pageTemplate !== 'checkout' &&
                <>
                  <GlobalSearch />
                  <Menu.Item className='menuItemUser storeButtonsGroup divided hideOnMobile'>
                    <Button as={NavLink} to='/create' basic className='storeButtons'>Create</Button>
                  </Menu.Item>
                  <Menu.Item className='menuItemUser storeButtonsGroup divided hideOnMobile'>
                    <Button as={NavLink} to='/discover' basic className='storeButtons'>Discover</Button>
                  </Menu.Item>
                  <ShoppingCartMenu cartData={storageCartItems} />
                </>
              }
              <UserDropdownMenu />
            </Menu>
          </div>
        </StyledNavBar>
    </>
  )
})

export default React.memo(NavBar)