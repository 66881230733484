import React from 'react'
import cn from 'classnames'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { consoleLogger, setAuthorizationHeader } from '@Utils'
import jwtDecode from 'jwt-decode'
// import { Helmet } from 'react-helmet'
import { PayPalButton } from "react-paypal-button-v2"
import NumberFormat from 'react-number-format'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'

// COMPONENTS
import {
  Grid,
  Button,
  Form as SUIForm,
  Message,
  Modal,
  Icon,
  Divider,
} from 'semantic-ui-react'
import {FormBox, StyledCard} from './Styles'
import SignupPackageTable from './SignupPackageTable'

const PaymentForm = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { authenticated } = useSelector(getUserState)
  const { siteSettings } = useSelector(getUIState)
  const { userid, key } = useParams()
  const [ loginLink, setLoginLink ] = React.useState('/login')
  const [ ready, setReady ] = React.useState(false)

  const [ paypalAmount, setPaypalAmount ] = React.useState(siteSettings.starterPrice)
  const [ subscriptionType, setSubscriptionType ] = React.useState('starter')
  // const [ paypalSubscriptionID, setPaypalSubscriptionID ] = React.useState(siteSettings.starterPaypalID)
  const [ paypalSubscriptionID, setPaypalSubscriptionID ] = React.useState(process.env.REACT_APP_PAYPAL_SUBSCRIPTION_STARTER)
  const [ formSubmitting, setFormSubmitting ] = React.useState(false)
  const [ errors, setErrors ] = React.useState()
  const [ regValid, setRegValid ] = React.useState(false)
  const { className, ...reProps} = props

  const [ compareOpen, setCompareOpen ] = React.useState(false)
  const handleCompareOpen = (bool) => {
    setCompareOpen(bool)
  }
  
  const handleSubscriptionType = (value) => {
    setSubscriptionType(value)
  }
  
  const paypalSubscription = (data, actions) => {
    return actions.subscription.create({
      "plan_id": paypalSubscriptionID,
      "application_context": {
        "shipping_preference":"NO_SHIPPING"
      }
    })
  }

  const paypalOnApprove = async (data, detail) => {
    consoleLogger("Paypal approved")
    consoleLogger("return data ========", data)
    consoleLogger("return detail ========", detail)

    let userInfo
    setFormSubmitting(true)
    // APPROVE NEW USER
    const formData = {
      id: userid,
      registration_key: key,
      subscription_id: data.subscriptionID,
      subscription_paid: true,
      subscription_type: subscriptionType,
      subscription_start: new Date().toISOString(),
      subscription_end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
    }
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/subscription`,
      // headers: {
      //   Authorization: `Bearer ${adminToken}`,
      // },
      method: 'POST',
      data: formData
    })
    .then(res => {
      history.push('/welcome')
      userInfo = res.data
    })
    .catch(err => {
      const errorData = err.response.data
      consoleLogger("ERROR: ", errorData)
      dispatch(userSlice.actions.setUserInfo({}))
      dispatch(userSlice.actions.setAuthenticated(false))
      if (errorData.code === 'existing_user_login') {
        setErrors({username: 'This username is already taken.'})
      } else if (errorData.code === 'existing_user_email') {
        setErrors({email: 'This email is already taken.'})
      } else {
        setErrors(errorData)
      }
    })

    // update newsletter database
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/newsletter/v2/subscribers`,
      method: 'POST',
      params: {
        client_key: process.env.REACT_APP_WP_NEWSLETTER_KEY,
        client_secret: process.env.REACT_APP_WP_NEWSLETTER_SECRET
      },
      data: {
        "email": userInfo.email,
        "first_name": userInfo.first_name,
        "last_name": userInfo.last_name,
        "lists": [
          {
            "id": 1,
            "value": 1 // CREATOR
          },
          {
            "id": 2,
            "value": 0
          },
          {
            "id": 3,
            "value": subscriptionType === 'starter' ? 1 : 0
          },
          {
            "id": 4,
            "value": subscriptionType === 'premium' ? 1 : 0
          },
        ],
        "status": "confirmed"
      }
    }).then(res => {
      consoleLogger('++++++++++++++++++++++++++ USER added to CREATORS newsletter list')
    }).catch(err => {
      consoleLogger('-------------------------- ERROR in adding USER to CREATORS newsletter list', err)
    })

    setFormSubmitting(false)
  }

  const paypalOnError = (err) => {
    consoleLogger("ERROR", err)
    setErrors(err)
  }

  React.useEffect(() => {
    consoleLogger('=====================', paypalAmount, paypalSubscriptionID)
  }, [paypalAmount, paypalSubscriptionID])

  React.useEffect(() => {
    if (subscriptionType === "starter") {
      setPaypalAmount(siteSettings.starterPrice)
      // setPaypalSubscriptionID(siteSettings.starterPaypalID)
      setPaypalSubscriptionID(process.env.REACT_APP_PAYPAL_SUBSCRIPTION_STARTER)
    } else {
      setPaypalAmount(siteSettings.premiumPrice)
      // setPaypalSubscriptionID(siteSettings.premiumPaypalID)
      setPaypalSubscriptionID(process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PREMIUM)
    }
  }, [subscriptionType]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      dispatch(uiSlice.actions.setPageTemplate('store'))
      dispatch(uiSlice.actions.setCurrentPage('payment'))
      dispatch(uiSlice.actions.setCurrentPageURL())
      dispatch(uiSlice.actions.setCurrentPageTitle())

      // check if creator is already a subscriber
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/checkreg`,
        // headers: {
        //   Authorization: `${token}`,
        // },
        method: 'POST',
        data: {
          author: userid,
          id: key,
          type: 'creator'
        },
      }).then(res => {
        if (!res.data.subscription_paid && res.data.subscription_expired) {
          setRegValid(true)
        } else {
          setErrors({message: 'Registration session is no longer valid.'})
        }
      }).catch(err => {
        const errorData = err.response.data ? err.response.data : err
        consoleLogger(err)
        setErrors(errorData)
      })
      setReady(true)
    })()
  }, []) // eslint-disable-line

  return (
    <FormBox className={cn('formBoxContainer', {
      [className] : className,
      'error': !regValid
    })}>
      <div className='ui form'>
        <Grid centered padded>
          <Grid.Column className='formBox'>
            {ready ?
              <>
                {regValid ?
                  <>
                    <h1>Select Subscription</h1>
                    <StyledCard>
                      <StyledCard.Content>
                        <div className='signupSelectRolGrid'>
                          <p className='signupSelectRole'>
                            {`Membership Package `}
                          </p>
                          <div className={cn("membershipToggle", {
                            'active': subscriptionType === 'starter'
                          })}>
                            <div className='membershipToggle_wrapper'>
                              <SUIForm.Radio id='member-starter-radio' name='membership_type' value='starter'
                                checked={subscriptionType === 'starter'}
                                onChange={(ev, {value}) => {
                                  consoleLogger(value)
                                  handleSubscriptionType(value)
                                }}
                                disabled={formSubmitting}
                              />
                              <label for='member-starter-radio'>
                              {`${siteSettings.starterPackageName} `}
                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.starterPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                <span className='permonth'>{`/mo.`}</span>
                              </label>
                            </div>
                            <p>You will pay <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.starterPrice * 12} displayType={'text'} thousandSeparator={true} prefix={'$'} /> for 1 year subscription</p>
                          </div>
                          <div className={cn("membershipToggle", {
                            'active': subscriptionType === 'premium'
                          })}>
                            <div className='membershipToggle_wrapper'>
                              <SUIForm.Radio id='member-premium-radio' name='membership_type' value='premium'
                                checked={subscriptionType === 'premium'}
                                onChange={(ev, {value}) => {
                                  consoleLogger(value)
                                  handleSubscriptionType(value)
                                }}
                                disabled={formSubmitting}
                              />
                              <label for='member-premium-radio'>
                                {`${siteSettings.premiumPackageName} `}
                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.premiumPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                <span className='permonth'>{`/mo.`}</span>
                              </label>
                            </div>
                            <p>You will pay <NumberFormat decimalScale={2} fixedDecimalScale={true} value={siteSettings.premiumPrice * 12} displayType={'text'} thousandSeparator={true} prefix={'$'} /> for 1 year subscription</p>
                          </div>
                        </div>
                        <Modal
                          size='tiny'
                          basic
                          dimmer={{
                              blurring: true
                          }}
                          open={compareOpen}
                          onClose={() => handleCompareOpen(false)}
                          onOpen={() => handleCompareOpen(true)}
                          trigger={
                            <div className='footnote'>
                              <div className='note'>
                                Compare Prices
                                <Icon name="info circle" color="white" />
                              </div>
                            </div>
                          }
                        >
                          <Modal.Content>
                            <SignupPackageTable />
                          </Modal.Content>
                        </Modal>
                        
                      </StyledCard.Content>
                      <StyledCard.Content extra>
                        <div className='paypalSubscriptionButtons'>
                          <PayPalButton
                            options={{
                              vault: true,
                              clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
                            }}
                            vault={true}
                            amount={paypalAmount}
                            currency="USD"
                            createSubscription={paypalSubscription}
                            onApprove={paypalOnApprove}
                            catchError={paypalOnError}
                            onError={paypalOnError}
                            onCancel={paypalOnError}
                          />
                        </div>
                      </StyledCard.Content>
                      <StyledCard.Content extra className='subCard'>
                        <p>Already have an account? <Link to={loginLink}>Login now!</Link></p>
                      </StyledCard.Content>
                    </StyledCard>
                    {errors &&
                      <Message negative>
                        {errors.message}
                      </Message>
                    }
                  </> : 
                  <>
                    <h1>Oh, snap!</h1>
                    <StyledCard>
                      <StyledCard.Content>
                        <p>Something went wrong with the registration process. Please try again later.</p>
                      </StyledCard.Content>
                      <StyledCard.Content extra className='subCard'>
                        <p>If this error keeps showing up, please inform our <Link component='a' to='/support'>technical support</Link> about this.</p>
                      </StyledCard.Content>
                    </StyledCard>
                  </>
                }
              </> : 
              <>
                <h1>Select Subscription</h1>
                <StyledCard>
                  <StyledCard.Content>
                    <p>Checking...</p>
                  </StyledCard.Content>
                </StyledCard>
              </>
            }
          </Grid.Column>
        </Grid>
      </div>
    </FormBox>
  )
}

export default React.memo(PaymentForm)
