import React from 'react'
import axios from 'axios'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch } from 'react-redux'
import gallerySlice from '@Slices/gallerySlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
// import GeneralPreloader from '@Common/GeneralPreloader'

const WPSettings = (props) => {
  const dispatch = useDispatch()
  // const { uiReady } = useSelector(getUIState)
  // const [ isReady, setIsReady ] = React.useState(false)

  // const [ errorCount, setErrorCount ] = React.useState(0)
  
  // FETCH GALLERY SETTINGS: SIZES AND MATERIALS
  const fetchGallerySettings = async () => {
    // consoleLogger('1. fetchGallerySettings start =============================')
    let error = 0

    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/types`,
      method: 'GET',
    }).then((res) => {
      consoleLogger('============================= GALLERY SETTINGS - PRINT TYPES')
        // consoleLogger("SIZE TYPES RESPONSE ===========", res.data)
        dispatch(gallerySlice.actions.setGallerySizeTypes(res.data.sort((a,b) => a.acf.order - b.acf.order)))
    }).catch((err) => {
      consoleLogger(err)
      error++
    })
    
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/sizes`,
      method: 'GET',
    }).then(res => {
      consoleLogger('============================= GALLERY SETTINGS - SIZES')
        // consoleLogger("SIZES RESPONSE ===========", res.data)
        dispatch(gallerySlice.actions.setGallerySizes(res.data.sort((a,b) => a.acf.length - b.acf.length)))
    }).catch(err => {
      consoleLogger(err)
      error++
    })

    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/materials`,
      method: 'GET',
    }).then((res) => {
      consoleLogger('============================= GALLERY SETTINGS - MATERIALS')
        // consoleLogger("MATERIALS RESPONSE ===========", res.data)
        dispatch(gallerySlice.actions.setGalleryMaterials(res.data.sort((a,b) => a.acf.order - b.acf.order)))
    }).catch((err) => {
      consoleLogger(err)
      error++
    })

    return error > 0 ? false : true
  }

  React.useEffect(() => {
    (async() => {
      
      await fetchGallerySettings()

      dispatch(uiSlice.actions.setUIReady(true))
    })()

    return (() => {})

  }, []) // eslint-disable-line

  return props.children
}

export default React.memo(WPSettings)
