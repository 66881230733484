import React from 'react'
import axios from 'axios'
import { orderBy, reverse } from 'lodash'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice' // eslint-disable-line
// import cartSlice, { getCartState } from '@Slices/cartSlice' // eslint-disable-line
// import { getGalleryState } from '@Slices/gallerySlice' // eslint-disable-line

import { DataTable } from '@Components/DataTable'
import OrdersModal from './OrdersModal'

const OrdersTable = (props) => {
  const componentNameSpace = 'user-orders'
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { status } = props
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  const { uiComponentReady, uiTables } = useSelector(getUIState)
  const [ tempData, setTempData ] = React.useState()
  const [ tableData, setTableData ] = React.useState()
  const [ info, setInfo ] = React.useState({
    namespace: componentNameSpace,
    postType: 'sf-orders',
    single: 'Order',
    plural: 'Orders',
    pageSize: uiTables.filter(i => i.component === componentNameSpace)[0].pageSize,
    pageNumber: uiTables.filter(i => i.component === componentNameSpace)[0].pageNumber,
    isReady: uiComponentReady.filter(i => i.component === componentNameSpace).map(n => n.isReady)[0],
  })
  const [ modalOpen, setModalOpen ] = React.useState(false)
  const [ orderViewData, setOrderViewData ] = React.useState()
  const [ isReady, setIsReady ] = React.useState(false) // eslint-disable-line

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleViewOrder = (data) => {
    setModalOpen(true)
    setOrderViewData(data)
  }
  
  const handleFetchData = async () => {
    dispatch(uiSlice.actions.updateUIComponentReady({component: componentNameSpace, isReady: false}))
    const uiTableTarget = uiTables.filter(i => i.component === componentNameSpace)[0]
    let params = {
      // page: uiTableTarget.page,
      // per_page: uiTableTarget.show,
      per_page: -1,
      author: userInfo.id,
      user_key: userKey,
      status: ['publish', 'pending'],
      order: uiTableTarget.order,
    }
    if (uiTableTarget.meta) {
      params.meta_key = uiTableTarget.sort
    }
    params.orderby = uiTableTarget.meta ? 'meta_value' : uiTableTarget.sort
    await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: `/stylefolio/v1/getorders`,
      method: 'get',
      headers: {
        Authorization:`${token}`,
      },
      params: params
    }).then((res) => {
      consoleLogger('FETCHING USER COUPONS ========================', userInfo.id, res.data)
      // dispatch(uiSlice.actions.updateUITablesData({component: componentNameSpace, data: res.data}))
      dispatch(uiSlice.actions.updateUITables({component: componentNameSpace, totalItems: res.data.length}))
      // dispatch(uiSlice.actions.updateUITables({component: info.namespace, totalPages: Math.ceil(res.data.length / pageSize)}))
    }).catch(err => {
      consoleLogger(err)
    })
    dispatch(uiSlice.actions.updateUIComponentReady({component: componentNameSpace, isReady: true}))
  }

  React.useEffect(() => {
    (async () => {
      await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/getorders`,
        method: 'get',
        headers: {
          Authorization:`${token}`,
        },
        params: {
          author: userInfo.id,
          user_key: userKey,
        }
      }).then((res) => {
        consoleLogger('FETCHING USER ORDERS ========================', userInfo.id, res.data)
        setTempData(res.data)
      }).catch(err => {
        consoleLogger(err)
      })

      setIsReady(true)
    })()

  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (tempData) {
      // sorting
      let data = orderBy(tempData, `acf.${uiTables.filter(i => i.component === componentNameSpace)[0].sort}`)
      if (uiTables.filter(i => i.component === componentNameSpace)[0].order === 'desc') {
        data = reverse(data)
      }
      setTableData(data)
      
      dispatch(uiSlice.actions.updateUITables({component: componentNameSpace, totalItems: tempData.length}))
      dispatch(uiSlice.actions.updateUITablesDataItem({component: componentNameSpace, data: tempData}))

      // update info
      setInfo(state => ({
        ...state,
        totalPages: Math.ceil(tempData.length / uiTables.filter(i => i.component === componentNameSpace)[0].pageSize),
        totalItems: tempData.length,
        isReady: uiComponentReady.filter(i => i.component === componentNameSpace).map(n => n.isReady)[0],
      }))
    }
  }, [tempData]) // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (tempData) {
        // sorting
        consoleLogger('====================== SORT ==================', uiTables.filter(i => i.component === componentNameSpace)[0].sort, uiTables.filter(i => i.component === componentNameSpace)[0].order, uiTables.filter(i => i.component === componentNameSpace)[0].meta)
        let data = orderBy(tempData, `${uiTables.filter(i => i.component === componentNameSpace)[0].meta ? 'acf.' : ''}${uiTables.filter(i => i.component === componentNameSpace)[0].sort}`)
        if (uiTables.filter(i => i.component === componentNameSpace)[0].order === 'desc') {
          data = reverse(data)
        }
        await Promise.all(data)
        setTableData(data)

        // update info
        setInfo(state => ({
          ...state,
          pageSize: uiTables.filter(i => i.component === componentNameSpace)[0].pageSize,
          pageNumber: uiTables.filter(i => i.component === componentNameSpace)[0].pageNumber,
          totalPages: Math.ceil(tempData.length / uiTables.filter(i => i.component === componentNameSpace)[0].pageSize),
          totalItems: tempData.length,
          isReady: uiComponentReady.filter(i => i.component === componentNameSpace).map(n => n.isReady)[0],
        }))
      }
    })()
  }, [uiTables]) // eslint-disable-line

  // consoleLogger('================ info ==================', info)

  const toolbar = {
    buttons: [
      // {
      //   key: 'create',
      //   text: 'New Coupon',
      //   icon: 'plus',
      //   color: 'green',
      //   action: () => {}
      // },
    ]
  }

  const schema = [
    {
      key: 'order_id',
      field: 'order_id',
      text: 'Order ID',
      type: 'index',
      meta: 'acf',
      sortable: true,
    },
    {
      key: 'transaction_id',
      field: 'transaction_id',
      text: 'Transcation ID',
      type: 'masked',
      meta: 'acf',
      sortable: true,
      mask: '*',
      showLength: 4,
    },
    {
      key: 'amountvalue',
      field: 'value',
      text: 'Amount',
      type: 'amountvalue',
      meta: 'paypal',
      sortable: true,
    },
    {
      key: 'post_date_gmt_epoch',
      field: 'post_date_gmt_epoch',
      text: 'Post Date',
      type: 'date',
      format: 'yyyy-MM-dd',
      sortable: true,
    },
    {
      key: 'post_modified_gmt_epoch',
      field: 'post_modified_gmt_epoch',
      text: 'Last Updated',
      type: 'date-distance',
      sortable: true,
    },
    {
      key: 'payment_status',
      field: 'payment_status',
      text: 'Payment Status',
      type: 'string',
      meta: 'acf',
      sortable: true,
      collapsing: true,
    },
    {
      key: 'order_status',
      field: 'order_status',
      text: 'Order Status',
      type: 'enum',
      meta: 'acf',
      sortable: true,
      collapsing: true,
      enum: [
        {
          key: '0',
          label: 'PENDING',
        },
        {
          key: '1',
          label: 'RECIEVED',
        },
        {
          key: '2',
          label: 'PROCESSING',
        },
        {
          key: '3',
          label: 'SHIPPED',
        },
        {
          key: '4',
          label: 'OUT FOR DELIVERY',
        },
        {
          key: '5',
          label: 'RECEIVED',
        },
        {
          key: '6',
          label: 'COMPLETED',
        },
        {
          key: '7',
          label: 'CANCELLED',
        },
        {
          key: '8',
          label: 'REFUNDED',
        },
        {
          key: '9',
          label: 'FRAUD',
        },
      ]
    },
    {
      key: 'action',
      field: 'action',
      text: 'Actions',
      collapsing: true,
      textAlign: 'center',
      type: 'buttons',
      buttons: [
        {
          key: 'view',
          text: 'View',
          color: 'blue',
          icon: 'info',
          action: (data) => {
            consoleLogger("================== onclick =====================", data)
            handleViewOrder(data)
          },
        },
      ]
    },
  ]

  return (
    <>
      {isReady ?
        <>
          {modalOpen &&
            <OrdersModal
              open={modalOpen}
              handleOpen={handleModalOpen}
              handleClose={handleModalClose}
              isEdit={false}
              info={info}
              data={orderViewData}
              handleFetchData={() => handleFetchData()}
            />
          }
          <DataTable
            info={info}
            schema={schema}
            toolbar={toolbar}
            data={tableData}
            handleFetchData={() => handleFetchData()}
          />
        </> : 
        <>Loading...</>
      }
    </>
  )
}

export default React.memo(OrdersTable)
