import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledContainer = styled.div`
  width: 100%;
  /* height: calc(calc(min(calc(var(--vh, 1vh) * 90), 720px))); */
  height: calc(calc(var(--vh, 1vh) * 85) - ${stylevars.navBar.height});
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  position: relative;
  /* margin-bottom: 4rem; */

  @media ${stylevars.breakpoints.phone} {
    height: auto;
  }

  > .bg {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .bgImage {
      position: absolute;
      z-index: 2;
      width: calc(100% + 4rem);
      height: calc(100% + 4rem);
      top: -2rem;
      left: -2rem;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.5;
      transition: all 0.15s ease-out;
      filter: blur(2rem);
    }

    .bgOverlay {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${stylevars.typography.title.color};
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }

  .wrapper {
    padding: 0 2rem;
    width: 100%;
    max-width: 1480px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    position: relative;
    z-index: 3;

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }
  }

  .context {
    width: calc(100% - 500px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${stylevars.breakpoints.tablet} {
      width: calc(100% - 320px);
    }
    
    @media ${stylevars.breakpoints.phone} {
      width: 100%;
      display: block;
      margin-bottom: 4rem;
    }

    h1 {
      color: #fff;
      font-size: 4rem;
      .text {
        display: inline-block;
        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }

      [class^='color--'] {
          font-size: 4rem;
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          margin-left: 1rem;
      }
      .color-- {
        &red {
          background-color: #FF0000;
        }
        &green {
          background-color: #0CB30C;
        }
        &blue {
          background-color: #0C32FF;
        }
        &yellow {
          background-color: #FDFA00;
        }
      }
    }
    p {
      color: rgba(255,255,255,0.65);
      font-size: 2rem;
    }

    .actions {
      margin-top: 2rem;
    }
  }
  .featured {
    position: relative;

    .featuredWrapper {
      width: 500px;
      position: relative;
      border-radius: 1rem !important;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      overflow: hidden;
      background-color: #fff;

      @media ${stylevars.breakpoints.tablet} {
        width: 320px;
      }

      @media ${stylevars.breakpoints.phone} {
        width: 100%;
      }

      > .swiper-container {
        /* padding-bottom: 3rem; */
      }

      > .swiper-pagination {
        margin-bottom: -2rem;
      }
    }

    > .nav {
      position: absolute;
      top: calc(420px - 1.5rem);
      left: 50%;
      transform: translateX(-50%);
      z-index: 4;
      border-radius: 4rem;
      overflow: hidden;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

      .navButton {
        cursor: pointer;
        background-color: #fff;
        width: 3rem;
        height: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: all 0.15s ease-out;
        &:hover {
          background-color: ${stylevars.palette.primary.main};
          .icon {
            color: #fff;
          }
        }

        .icon {
          font-size: 1.5rem;
          display: flex;
          padding: 0;
          margin: 0;
          justify-content: center;
          align-items: center;
          transform-origin: center center;
          transition: all 0.15s ease-out;
        }
      }
    }
  }
  .item {
    position: relative;
    transition: all 0.15s ease-out;
  }
  .link {
    overflow: hidden;
    position: relative;
    display: block;
    width: 100%;
    height: 420px;

    .image {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: scale(1);
      transition: transform 0.3s ease-out;
      background-color: rgba(0,0,0,0.05);
    }
  }
  .details {
    padding: 1rem;
    width: calc(100% - 2rem);
    min-height: calc(136px - 2rem);

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      > span {
        flex-grow: 1;
        font-size: 1.25rem;
        font-weight: bold;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 1rem;

        > .limited {
          font-size: 0.75rem;
          display: block;
          color: rgba(0,0,0,0.5);
        }
      }
      .likeButton {
        .button {
          background-color: transparent;
          padding: 0;
          height: 3rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .icon {
            font-size: 1.25rem;
            color: ${stylevars.palette.danger.main};
          }
          span {
            font-size: 1rem;
            margin-left: 0.5rem;
          }
        }
      }
    }

    .field {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }

      .label {
        font-size: 0.85rem;
        color: rgba(0,0,0,0.5);
      }

      .value {
        font-size: 1rem;
        font-weight: 400;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        .valueLink {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: ${stylevars.typography.title.color};
          &:hover {
            color: ${stylevars.palette.info.main};
          }
        }
      }

      &.creator {
        .avatar {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      &.price {
        .value {
          font-weight: bold;
          .copies {
            > span {
              display: inline-block;
              line-height: 1.4;
              &:nth-child(1) {
                color: rgba(0,0,0,0.5);
              }
            }
          }
        }
      }
    }
  }

  .viewButton {
    border-top: 1px solid ${stylevars.paper.borderColor};
    transition: transform 0.15s ease-out 0s;
    transform: translateY(100%);
    padding: 1rem;
    text-align: center;

    .button {
      margin: 0;
      font-size: 1.3rem;
      width: calc(100% - 4rem);
    }
  }

  .swiper-slide-active {
    .viewButton {
      transition: transform 0.15s ease-out 0.3s;
      transform: translateY(0%);
    }
  }
`