import React from "react";
import NumberFormat from "react-number-format";
import axios from "axios";
import he from "he";
import { Link } from "react-router-dom";
import {
  consoleLogger,
  priceFormat,
  cleanPrice,
  getPrintType,
  getPrintSize,
  getPrintMaterial,
} from "@Utils";

// REDUX
import { useDispatch, useSelector } from "react-redux";
// import { getVendorState } from '@Slices/vendorSlice'
import { getUserState } from "@Slices/userSlice";
import { getUIState } from "@Slices/uiSlice";
import cartSlice, { getCartState } from "@Slices/cartSlice";
import { getGalleryState } from "@Slices/gallerySlice";

// COMPONENTS
import {
  Button,
  Confirm,
  Dimmer,
  Grid,
  Header,
  Icon,
  Item,
  Loader,
  Placeholder,
} from "semantic-ui-react";
import CartItemSkeleton from "./CartItemSkeleton";

// STYLES
import { CartItemContainer } from "./CartItemStyles";
import classNames from "classnames";

const CartItem = (props) => {
  const {
    item,
    index,
    vendor,
    vIndex,
    handleCartError,
    handleCartItemCount,
    handleTotalLoaded,
    handleProcessing,
  } = props;
  const dispatch = useDispatch();
  const { currency } = useSelector(getUIState);
  const { galleryMaterials, gallerySizeTypes, gallerySizes } =
    useSelector(getGalleryState);
  const { carts } = useSelector(getCartState);

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [itemData, setItemData] = React.useState();
  const [ready, setReady] = React.useState(false);
  const [reachedLimit, setReachedLimit] = React.useState(false);
  const [error, setError] = React.useState();
  const [processing, setProcessing] = React.useState(false);
  const handleItemProcessing = (bool) => {
    setProcessing(bool);
    handleProcessing(bool);
  };

  const getTypeName = (id) => {
    return gallerySizeTypes.filter((i) => i.id === id).map((o) => o.name);
  };

  const getTypeSize = (id) => {
    return gallerySizes.filter((i) => i.id === id).map((o) => o.acf.size);
  };

  const handleUpdateQuantity = async (action) => {
    consoleLogger("==================== UPDATE QUANTITY ===================");
    consoleLogger(action);
    handleItemProcessing(true);
    const change = action === "increase" ? 1 : -1;
    let temp_sold_copies = Number(itemData.data.acf.sold_copies);
    let temp_number_lots = Number(itemData.data.acf.number_lots);
    let tempLimit = 999999999;
    let tempQuantity = Number(item.quantity) + change;
    if (action === "increase") {
      handleTotalLoaded(-1);
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/gallery/${item.id}`,
        method: "get",
      })
        .then((res) => {
          const tempData = {
            ...item,
            data: res.data,
          };
          consoleLogger("===================== TEMP DATA ===================");
          consoleLogger(tempData);
          setItemData(tempData);
          temp_sold_copies = Number(res.data.acf.sold_copies);
          temp_number_lots = Number(res.data.acf.number_lots);
        })
        .catch((err) => {
          consoleLogger(err);
        });
      // handleTotalLoaded(1)
    }

    if (!!itemData.data.acf.limited_edition) {
      tempLimit = temp_number_lots - (temp_sold_copies + tempQuantity);
    }
    consoleLogger("==================== LIMIT ===================");
    consoleLogger(tempLimit);
    consoleLogger("==================== QUANTITY ===================");
    consoleLogger(tempQuantity);

    if (action === "increase") {
      if (Number(tempLimit) > -1) {
        dispatch(
          cartSlice.actions.increaseQuantity({
            vendor,
            item,
            selectedType: Number(itemData.selectedType),
            selectedSize: itemData.selectedSize,
            price: Number(itemData.price),
          })
        );
      }
    } else {
      if (Number(item.quantity) === 1) {
        handleConfirmOpen();
      } else {
        dispatch(
          cartSlice.actions.reduceQuantity({
            vendor,
            item,
            selectedType: Number(itemData.selectedType),
            selectedSize: itemData.selectedSize,
            quantity: Number(item.quantity),
            price: Number(itemData.price),
          })
        );
      }
    }

    handleItemProcessing(false);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    handleRemoveCartItem();
    setConfirmOpen(false);
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleRemoveCartItem = () => {
    dispatch(
      cartSlice.actions.removeCartItem({ vIndex: vIndex, index: index })
    );
  };

  React.useEffect(() => {
    handleCartError(error ? "add" : "remove", item.id);
  }, [error]); // eslint-disable-line

  // CHECK CART TO VALIDATE PRODUCT AVAILABILITY
  React.useEffect(() => {
    if (carts && itemData) {
      consoleLogger(
        "=================== CART CHANGED ======================",
        item.id
      );
      let tempQuantity = 0;
      carts.forEach((n) => {
        if (n.items && n.items.length > 0) {
          const tempItems = n.items.filter(
            (i) => i.id.toString() === item.id.toString()
          );
          consoleLogger(tempItems);
          tempItems.forEach((o) => {
            consoleLogger(
              "=================== ITEM FOUND ======================"
            );
            consoleLogger(o);
            tempQuantity += Number(o.quantity);
          });
        }
      });
      let tempLimit = 999999999;
      let tempNumberLots = 999999999;
      if (!!itemData.data.acf.limited_edition) {
        tempLimit =
          Number(itemData.data.acf.number_lots) -
          (Number(itemData.data.acf.sold_copies) + tempQuantity);
        tempNumberLots = Number(itemData.data.acf.number_lots);
      }
      consoleLogger(
        "================== ITEM QUANTITY + SOLD COPIES / vs / NUMBER LOTS ==================="
      );
      consoleLogger(
        Number(itemData.data.acf.sold_copies) + tempQuantity,
        tempNumberLots
      );
      setReachedLimit(
        Number(itemData.data.acf.sold_copies) + tempQuantity >= tempNumberLots
      );

      if (tempLimit < 0) {
        setError({
          id: item.id,
          code: "quantity",
          message: "Order quantity is greater than available stock",
        });
      } else {
        setError();
      }
    }
  }, [carts, itemData]); // eslint-disable-line

  React.useEffect(() => {
    if (itemData) {
      consoleLogger(
        "===================== CART ITEM [ DATA ] ==================="
      );
      consoleLogger(itemData);
      handleTotalLoaded(1);
      setReady(true);
    }
  }, [itemData]); // eslint-disable-line

  const fetchItem = React.useCallback(async () => {
    let tempItemData;
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery/${item.id}`,
      method: "get",
    })
      .then((res) => {
        tempItemData = {
          ...item,
          data: res.data,
        };
        consoleLogger(
          "===================== CART ITEM [ ON MOUNT ] - RESPONSE ==================="
        );

        // if product is limited edition
        // check stocks if order quantity is valid
        let tempLimit = 999999999;
        let tempQuantity = Number(item.quantity);
        if (!!res.data.acf.limited_edition) {
          tempLimit =
            Number(res.data.acf.number_lots) -
            (Number(res.data.acf.sold_copies) + tempQuantity);
        }
        setReachedLimit(tempLimit <= 0);

        if (tempLimit < 0) {
          setError({
            id: item.id,
            code: "quantity",
            message: "Order quantity is greater than available stock",
          });
        }
      })
      .catch((err) => {
        consoleLogger(err);
        handleTotalLoaded(-1);
      });

    // ===============================
    // validate cart item
    // ===============================
    // first retrieve vendor info
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/users/${vendor.id}`,
      method: "GET",
    })
      .then((res) => {
        const vendorData = res.data;

        // calculate the price
        let thePrice = 0;
        let theFees = 0;
        const tempTypeObj = getPrintType(
          item.selectedType.id,
          gallerySizeTypes
        );
        const basePrice = Number(tempItemData.data.acf.base_price);
        const tempSizeObj = getPrintSize(item.selectedSize.id, gallerySizes);
        let priceFactor =
          (Number(tempSizeObj.acf.length) + Number(tempSizeObj.acf.width)) / 2;
        if (tempTypeObj.slug === "canvas") {
          priceFactor =
            (Number(tempSizeObj.acf.length) +
              2 +
              (Number(tempSizeObj.acf.width) + 2)) /
            2;
        }
        const baseFee = Number(tempTypeObj.acf.fee);
        const framePrice =
          Number(
            item.selectedFrame
              ? getPrintMaterial(item.selectedFrame.id, galleryMaterials).acf
                  .price
              : 0
          ) * priceFactor;
        const paperPrice =
          Number(
            item.selectedPaper
              ? getPrintMaterial(item.selectedPaper.id, galleryMaterials).acf
                  .price
              : 0
          ) * priceFactor;
        const canvasPrice =
          Number(
            item.selectedCanvas
              ? getPrintMaterial(item.selectedCanvas.id, galleryMaterials).acf
                  .price
              : 0
          ) * priceFactor;
        const borderPrice =
          Number(
            item.selectedBorder
              ? getPrintMaterial(item.selectedBorder.id, galleryMaterials).acf
                  .price
              : 0
          ) * priceFactor;
        thePrice = basePrice;
        theFees = baseFee + framePrice + paperPrice + canvasPrice + borderPrice;
        consoleLogger("THE PRICE ===============", thePrice);

        consoleLogger("UPDATING CART ITEM ==============", item);
        dispatch(
          cartSlice.actions.refreshCartItem({
            id: vendor.id,
            name: vendorData.name,
            slug: vendorData.slug,
            profile_picture: vendorData.acf.profile_picture,
            item: {
              id: tempItemData.data.ID,
              title: he.decode(tempItemData.data.post_title),
              description: he.decode(tempItemData.data.post_excerpt),
              slug: tempItemData.data.post_name,
              vendorId: tempItemData.data.post_author,
              selectedType: item.selectedType,
              selectedSize: item.selectedSize,
              selectedFrame: item.selectedFrame ? item.selectedFrame : null,
              selectedPaper: item.selectedPaper ? item.selectedPaper : null,
              selectedBorder: item.selectedBorder ? item.selectedBorder : null,
              selectedCanvas: item.selectedCanvas ? item.selectedCanvas : null,
              base_price: thePrice,
              print_fees: theFees,
              price: thePrice + theFees,
              media: tempItemData.data.media,
              quantity: item.quantity,
            },
          })
        );
      })
      .catch((err) => {
        consoleLogger(err);
      });

    setItemData(tempItemData);
  }, [item]); // eslint-disable-line

  React.useState(() => {
    if (!ready) {
      consoleLogger(
        "====================== CART ITEM [ ON MOUNT ] =================="
      );
      consoleLogger(item);
      fetchItem();
    }
  }, []); // eslint-disable-line

  return (
    <CartItemContainer>
      {processing && (
        <Dimmer inverted active>
          <Loader>Updating...</Loader>
        </Dimmer>
      )}
      {ready ? (
        <Item
          className={classNames({
            error: error,
          })}
        >
          {error && <div className="errorMessage">{error.message}</div>}
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={2}>
                <Link to={`/@${vendor.slug}/gallery/${item.id}`}>
                  <img className="image" src={item.media.medium} />
                </Link>
              </Grid.Column>
              <Grid.Column mobile={16} computer={6} verticalAlign="middle">
                <Item.Content>
                  <Header as="h4">
                    <Link to={`/@${vendor.slug}/gallery/${item.id}`}>
                      {item.title}
                    </Link>
                  </Header>
                  <p className="detail">
                    Materials &amp; Size:{" "}
                    {`${getTypeName(item.selectedType.id)} - ${getTypeSize(
                      item.selectedSize.id
                    )}`}
                  </p>
                  <p className="extraDetails">
                    {item.selectedFrame && (
                      <span>Frame: {item.selectedFrame.name}</span>
                    )}
                    {item.selectedBorder && (
                      <span>Border: {item.selectedBorder.name}</span>
                    )}
                    {item.selectedPaper && (
                      <span>Print Material: {item.selectedPaper.name}</span>
                    )}
                  </p>
                  <div className="itemActions">
                    <Button
                      negative
                      size="mini"
                      basic
                      onClick={handleConfirmOpen}
                    >
                      <Icon name="trash" />
                      <span>Remove</span>
                    </Button>
                    {/* <Button color='blue' size='mini'>
                      <Icon name='pencil' />
                      <span>Edit</span>
                    </Button> */}
                  </div>
                </Item.Content>
              </Grid.Column>
              <Grid.Column
                mobile={4}
                computer={2}
                verticalAlign="middle"
                textAlign="center"
              >
                <div className="price">
                  <p>
                    <NumberFormat
                      value={cleanPrice(item.price * currency.value)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency.symbol}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </p>
                  <small>Price</small>
                </div>
              </Grid.Column>
              <Grid.Column
                mobile={8}
                computer={4}
                verticalAlign="middle"
                textAlign="center"
                className="quantity"
              >
                <div className="quantityContainer">
                  <Button
                    icon="minus"
                    className="minus"
                    onClick={() => handleUpdateQuantity("decrease")}
                    disabled={processing}
                  />
                  <NumberFormat
                    value={item.quantity}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    className="quantityText"
                    suffix="x"
                  />
                  <Button
                    icon="plus"
                    className="plus"
                    onClick={() => handleUpdateQuantity("increase")}
                    disabled={processing || reachedLimit}
                  />
                </div>
                <small>Quantity</small>
              </Grid.Column>
              <Grid.Column
                mobile={4}
                computer={2}
                verticalAlign="middle"
                textAlign="center"
              >
                <div className="amount">
                  <p>
                    <NumberFormat
                      value={cleanPrice(
                        item.quantity * item.price * currency.value
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency.symbol}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </p>
                  <small>Amount</small>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Item>
      ) : (
        <CartItemSkeleton />
      )}
      {confirmOpen && (
        <Confirm
          open={confirmOpen}
          cancelButton={<Button basic content="Cancel" />}
          confirmButton={
            <Button
              negative
              content="Remove"
              icon="trash"
              labelPosition="right"
            />
          }
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          size="mini"
          content={
            <div className="content">
              Are you sure you want to remove <strong>{item.title}</strong> from
              your cart?
            </div>
          }
        ></Confirm>
      )}
    </CartItemContainer>
  );
};

export default React.memo(CartItem);
