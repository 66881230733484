import React from "react";
import { Link } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";

// COMPONENTS
import { Button, Modal, Grid } from "semantic-ui-react";

// STYLES
import { Container, ModalContainer } from "./ShippingAddressStyles";
import SelectAddress from "./SelectAddress";

const ShippingAddress = (props) => {
  const { address, handleAddress, noCustom } = props;

  const dispatch = useDispatch();
  const { userInfo, userAddresses } = useSelector(getUserState);

  // USER INFO
  const [currentTitle, setCurrentTitle] = React.useState();
  const [currentAddress, setCurrentAddress] = React.useState();
  React.useEffect(() => {
    if (userInfo && address) {
      setCurrentTitle(address.title);
      setCurrentAddress(`${address.city}, ${address.country_code}`);
    }
  }, [address]); // eslint-disable-line

  const [open, setOpen] = React.useState(false);
  const handleModal = (bool) => {
    setOpen((prev) => (bool ? bool : !prev));
  };
  const handleChangeAddress = (val) => {
    handleAddress(val);
    handleModal(false);
  };

  return (
    <Container>
      <div className="shippingInfo">
        <div className="currentAddress">
          Ship to:{" "}
          <div className="currentAddressValue">
            <strong>{currentTitle}</strong> - {currentAddress}
          </div>
        </div>
        <div className="shippingInfoActions">
          <Button
            color="blue"
            basic
            size="tiny"
            content="Change Address"
            onClick={handleModal}
          />
        </div>
      </div>
      <Modal
        size={"tiny"}
        open={open}
        dimmer={{
          blurring: true,
        }}
        onOpen={() => handleModal(true)}
        onClose={() => handleModal(false)}
      >
        <Modal.Header>Change Address</Modal.Header>
        <Modal.Content scrolling>
          <ModalContainer>
            <Grid>
              <Grid.Row className="addresses">
                <Grid.Column mobile={16} computer={16}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16}>
                        {userAddresses && userAddresses.length > 0 && (
                          <SelectAddress
                            handleAddress={handleChangeAddress}
                            noCustom={noCustom}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </ModalContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            basic
            icon="close"
            labelPosition="right"
            content="Cancel"
            onClick={() => handleModal(false)}
          />
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default React.memo(ShippingAddress);
