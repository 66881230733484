import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  .button {
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:hover {
      background-color: transparent;
    }
    .icon {
      font-size: 1.25rem;
      color: ${stylevars.palette.danger.main};
    }
    span {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
`