import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled.div`
  width: 100%;
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
  padding: 4rem 0;
  background-image: url('https://sfv1cdn1.sgp1.digitaloceanspaces.com/generic_bg2.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* width: 100%;
    max-width: min(calc(var(--vw, 1vw) * 80), 420px); */
    width: auto;
    padding: 4rem;
    background-color: ${stylevars.paper.bgColor};
    border-radius: 0.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .title {
    font-weight: 400;
  }

  .text--center {
    text-align: center;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;

    .left, .right {
      width: 50%;
    }
  }

  .formBoxContainer {
    width: 100%;
  }
`