import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
// import { lighten, cssVar } from 'polished';

export const SocialMediaContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 1rem 0;

  .link {
    color: var(--sfProfile_socialIconColor);
    padding: 0rem;
    margin: 0;
    opacity: 1;
    transition: opacity 0.15s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    &.customicon {
      width: 2rem;
      height: auto;
      > svg {
        width: 100%;
        height: auto;
      }
    }

    .icon {
      font-size: 1.25rem;
      display: block;
      margin: 0 !important;
      padding: 0 !important;
    }

    &:hover {
      opacity: 0.5;
    }
  }
`