import React from 'react'
import cn from 'classnames'
import { consoleLogger, usePrevious } from '@Utils'
import axios from 'axios'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import vendorSlice, { getVendorState } from '@Slices/vendorSlice' // eslint-disable-line

// COMPONENTS
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react'
import { StoreUserPage } from '@StorePages/User'
import CustomizationForm from './CustomizationForm'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// STYLES
import { CustomizationContainer } from './CustomizationModalStyles'

const CustomizationModal = (props) => {
  const dispatch = useDispatch()
  const token = localStorage.Authorization
  const { userInfo, userKey } = useSelector(getUserState)
  const { vendorInfo, vendorProducts } = useSelector(getVendorState)
  const {open, handler} = props
  const [ editorToggle, setEditorToggle ] = React.useState(true)
  const [ valid, setValid ] = React.useState(false)
  const handleEditorToggle = () => {
    setEditorToggle(prev => !prev)
  }
  const [ formData, setFormData ] = React.useState()
  const prevData = usePrevious(userInfo.acf.custom_stylesheet)
  const handleCustomizationData = (val) => {
    setFormData(val)
  }
  const [ showMessage, setShowMessage ] = React.useState(false)
  const [ message, setMessage ] = React.useState()
  const handleShowMessage = (bool) => {
    setShowMessage(bool)
  }
  const [ isSubmitting, setIsSubmitting ] = React.useState(false)
  const handleUpdate = async () => {
    setIsSubmitting(true)
    await axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/users/${userInfo.id}`,
      headers: {
        "Authorization": token,
      },
      method: 'POST',
      data: {
        user_key: userKey,
        custom_stylesheet: {...formData}
      }
    }).then(res => {
      (async() => {
        await dispatch(userSlice.actions.setUserInfo(res.data))
        await dispatch(vendorSlice.actions.setVendorInfo(res.data))
        setFormData(res.data.acf.custom_stylesheet)
        toast.success(`Your theme customization has been saved.`, {
          containerId: 'themeToaster',
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })()
    }).catch(err => {
      consoleLogger(err)
    })
    setIsSubmitting(false)
  }

  React.useEffect(() => {
    consoleLogger('===================== PREV DATA ======================', prevData)
    consoleLogger('===================== DATA ======================', formData)
    setValid(JSON.stringify(prevData) !== JSON.stringify(formData))
  }, [prevData, formData]) // eslint-disable-line

  return (
    <>
      <Modal
        size='fullscreen'
        open={open}
        dimmer={{
            blurring: true
        }}
        onOpen={() => handler(true)}
        onClose={() => handler(false)}
        className='sfFullscreen customizationModal'
      >
        {isSubmitting &&
          <Dimmer inverted active>
            <Loader>Updating...</Loader>
          </Dimmer>
        }
        <Modal.Content>
          <ToastContainer containerId={'themeToaster'} closeButton={false} enableMultiContainer={true} />
          <CustomizationContainer className='customizationContainer'>
            {vendorInfo && vendorProducts &&
              <div className={cn('editorBox', {
                'show': editorToggle
              })}>
                <Button
                  circular size='large'
                  color={editorToggle ? '' : 'primary'}
                  basic={editorToggle}
                  className='toggle'
                  icon={editorToggle ? 'arrow right' : 'ellipsis vertical'}
                  onClick={() => handleEditorToggle()}
                />
                <div className={cn('content', {
                    'show': editorToggle
                  })}
                >
                  <CustomizationForm dataHandler={handleCustomizationData} />
                </div>
              </div>
            }
            <StoreUserPage customUsername={userInfo.username} />
          </CustomizationContainer>
        </Modal.Content>
        <Modal.Actions>
          {valid ?
            <>
              <Button icon='close' content='Cancel' labelPosition='right' basic onClick={() => handler(false)}/>
              <Button icon='check' content='Save Settings' labelPosition='right' positive onClick={handleUpdate} />
            </> :
            <>
              <Button icon='close' content='Close' labelPosition='right' color='blue' onClick={() => handler(false)}/>
            </>
          }
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default React.memo(CustomizationModal)
