import React from 'react'
import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import cn from 'classnames'

const Container = styled('div')`
  /* width: 100%; */
  /* max-width: calc(220px - 1rem); */
  width: calc(calc(calc(calc(var(--vw, 1vw) * 100) - ${stylevars.sideBar.width} - 6rem) / 5) - calc(calc(2px + ${stylevars.gallery.gap}) * 2));
  min-height: 100px;
  height: auto;
  margin: ${stylevars.gallery.gap};

  display: flex;
  justify-content: center;
  align-items: center;

  transition: box-shadow 0.15s ease-out;

  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  &.limited {
  }

  .item-wrapper {
    margin: 0;
    position: relative;
  }

  .imageWrapper {
    display: block !important;
  }

  .item-overlay {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    transition: all 0.15s ease-out;

    display: flex;
    justify-content: center;
    align-items: center;

    .handle {
      width: 3rem;
      height: 3rem;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .icon {
      color: ${stylevars.typography.title.color};
      line-height: 1;
      padding: 0;
      margin: 0;
    }
  }

  .item-actions {
    position: absolute;
    z-index: 6;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    transition: all 0.15s ease-out;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(0,0,0,0);

    .button {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      margin: 0 !important;
      line-height: 1;
      transition: all 0.15s ease-out;
      &.edit {
        opacity: 0;
        margin-top: 0.5rem !important;
      }
    }

    .item-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #ffffff;
      text-align: center;
      opacity: 0;
      transition: all 0.15s ease-out;
    }
  }

  .item-limited {
    position: absolute;
    z-index: 7;
    top: 1rem;
    left: 1rem;
    /* width: 2rem;
    height: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${stylevars.palette.primary.main};
    border: 1px solid ${stylevars.palette.primary.main};
    background-color: transparent;
    line-height: 1;
    padding: 0.3rem 0.5rem;
    /* border-radius: 50%; */
    border-radius: 0.25rem;
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &:hover {
    .item-actions {
      opacity: 1;
      background-color: rgba(0,0,0,0.5);
      .button {
        &.edit {
          opacity: 1;
        }
      }
      .item-title {
        opacity: 1;
      }
    }
  }

  &.draggable {
    .item-overlay {
      opacity: 1;
    }
    .item-actions {
      display: none;
    }
  }

  &.muuri-item-dragging {
    z-index: 3;
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5);
  }
  &.muuri-item-releasing {
    z-index: 2;
  }
  &.muuri-item-hidden {
    z-index: 0;
  }
  &.muuri-item-placeholder {
    opacity: 0.25;
  }
`

export const ItemContainer = React.memo((props) => {
  return (
    <Container className={cn(props.className)}>
      {props.children}
    </Container>
  )
})