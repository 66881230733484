import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CollectionsSelectionContainer = styled('div')`
  
  height: calc(calc(var(--vh, 1vh) * 100) - 120px - 3.5rem);
  overflow-y: auto;
  overflow-x: hidden;

  @media ${stylevars.breakpoints.tablet} {
  }

  .item {
    width: calc(16.667% - 11px);
    @media ${stylevars.breakpoints.laptop} {
      width: calc(25% - 10px);
    }

    @media ${stylevars.breakpoints.tablet} {
      width: calc(33.334% - 10px);
    }

    @media ${stylevars.breakpoints.phone} {
      width: calc(100% - 10px);
    }
    img {
      width: 100%;
    }
  }
`