import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import cn from 'classnames'
// import NumberFormat from 'react-number-format'
import { consoleLogger, truncate, countryOptions } from '@Utils'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  Grid,
  Form as SUIForm,
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Modal,
  Dropdown,
  Label,
} from 'semantic-ui-react'

// STYLES
import { StyledContainer } from './AccountBillingFormStyles'
import { toast } from 'react-toastify'

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(4, "Must be at least 4 characters long")
    .max(125, "Must be shorter than 32 characters")
    .required('Must enter a address name'),
  street_address1: Yup.string()
    .min(5, "Must be at least 5 characters long")
    .max(125, "Must be shorter than 125 characters")
    .required('Must enter a street address'),
  street_address2: Yup.string()
    .min(5, "Must be at least 5 characters long")
    .max(125, "Must be shorter than 125 characters"),
  city: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(125, "Must be shorter than 125 characters")
    .required('Must enter a city'),
  state: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(125, "Must be shorter than 125 characters")
    .required('Must enter a state'),
  postalcode: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(12, "Must be shorter than 12 characters")
    .required('Must enter a postal code'),
  country: Yup.string()
    .required('Must select a country'),
  country_code: Yup.string()
    .required('Must select a country'),
})

const AccountMailingForm = () => {
  const dispatch = useDispatch()
  const { userInfo, userAddresses, userKey } = useSelector(getUserState)
  const token = localStorage.Authorization

  const [isReady, setIsReady] = React.useState(false)
  const [billingAddresses, setBillingAddresses] = React.useState([
    {
      active: userInfo.acf.same_billing_mailing_address ? userInfo.acf.same_billing_mailing_address : false,
      key: 'same_billing_mailing_address',
      value: 'same_billing_mailing_address',
      text: 'Same as billing address',
      content: (
        <div className='addressDropdown_wrap'>
          <span className='addressDropdown_label'>{`Same as billing address`}</span>
          {userInfo.acf.same_billing_mailing_address ? <span className='addressDropdown_default'>DEFAULT</span> : ''}
        </div>
      ),
    }])
  const [theBillingAddress, setTheBillingAddress] = React.useState(userInfo.acf.same_billing_mailing_address ? ({slug: "same_billing_mailing_address"}) : userInfo.acf.mailing_address)
  const [editAddress, setEditAddress] = React.useState(false)
  const [newAddress, setNewAddress] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)
  const [removeOpen, setRemoveOpen] = React.useState(false)

  React.useEffect(() => {
    setIsReady(true)
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (userAddresses && userAddresses.length > 0) {
      setBillingAddresses([
        {
          active: userInfo.acf.same_billing_mailing_address,
          key: 'same_billing_mailing_address',
          value: 'same_billing_mailing_address',
          text: 'Same as billing address',
          content: (
            <div className='addressDropdown_wrap'>
              <span className='addressDropdown_label'>{`Same as billing address`}</span>
              {userInfo.acf.same_billing_mailing_address ? <span className='addressDropdown_default'>DEFAULT</span> : ''}
            </div>
          ),
        },
        ...userAddresses.map(item => ({
          active: userInfo.acf.mailing_address && userInfo.acf.mailing_address.id === item.id,
          key: item.slug,
          value: item.slug,
          text: item.title,
          content: (
            <div className='addressDropdown_wrap'>
              <span className='addressDropdown_label'>{item.title}</span>
              <span className='addressDropdown_extra'>{item.street_address1}, {item.city} {item.state}, {item.country}</span>
              {userInfo.acf.mailing_address && userInfo.acf.mailing_address.id === item.id ? <span className='addressDropdown_default'>DEFAULT</span> : ''}
            </div>
          ),
        }))
      ])
      // setTheBillingAddress(userAddresses.filter(i => i.default).map(n => n)[0])
    }
  }, [userInfo, userAddresses]) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger("the billing address ===================", theBillingAddress)
  }, [theBillingAddress])

  const handleToggleRemoveModal = (bool) => {
    setRemoveOpen(bool)
  }

  const handleBillingAddressChange = (value) => {
    consoleLogger("handleBillingAddressChange", value)
    if (value === "same_billing_mailing_address") {
      setTheBillingAddress({slug: "same_billing_mailing_address"})
    } else {
      setTheBillingAddress(userAddresses.filter(i => i.slug === value).map(n => n)[0])
    }
  }

  const handleEditAddressToggle = (bool) => {
    setEditAddress(bool)
    setNewAddress(false)
  }

  const handleNewBillingAddress = () => {
    setNewAddress(true)
    setEditAddress(true)
  }

  const handleSetSameBillingMailing = async (id) => {
    setProcessing(true)
    consoleLogger('handleSetSameBillingMailing --------------------', id)
    await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/address/${id}`,
        method: 'POST',
        headers: {
          Authorization: `${token}`,
        },
        data: {
          author: userInfo.id,
          user_key: userKey,
          default: true,
          type: 'mailing',
          same_billing_mailing_address: true
        }
    }).then(res => {
      dispatch(userSlice.actions.setUserAddresses(res.data.addresses))
      toast.success(`You have set "${res.data.updated.title}" as your default mailing address.`, {
        containerId: 'mainToaster',
        progress: undefined,
      });
      // dispatch(uiSlice.actions.setUIToast({
      //   visible: true,
      //   type: 'positive',
      //   icon: 'info circle',
      //   title: `Default Billing Address`,
      //   message: `You have set "${res.data.updated.title}" as your default billing address.`
      // }))
      setTheBillingAddress({slug: "same_billing_mailing_address"})
      dispatch(userSlice.actions.setSameBillingMailingAddress())
    }).catch(err => {
      consoleLogger(err)
    })
    setProcessing(false)
  }

  const handleSetDefault = async (id) => {
    setProcessing(true)
    consoleLogger('handleSetDefault --------------------', id)
    await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/address/${id}`,
        method: 'POST',
        headers: {
          Authorization: `${token}`,
        },
        data: {
          author: userInfo.id,
          user_key: userKey,
          default: true,
          type: 'mailing'
        }
    }).then(res => {
      dispatch(userSlice.actions.setUserAddresses(res.data.addresses))
      toast.success(`You have set "${res.data.updated.title}" as your default mailing address.`, {
        containerId: 'mainToaster',
        progress: undefined,
      });
      // dispatch(uiSlice.actions.setUIToast({
      //   visible: true,
      //   type: 'positive',
      //   icon: 'info circle',
      //   title: `Default Billing Address`,
      //   message: `You have set "${res.data.updated.title}" as your default billing address.`
      // }))
      setTheBillingAddress(res.data.updated)
      dispatch(userSlice.actions.setDefaultMailingAddress(res.data.updated))
    }).catch(err => {
      consoleLogger(err)
    })
    setProcessing(false)
  }

  const handleRemoveAddress = async (id) => {
    setProcessing(true)
    setRemoveOpen(false)
    consoleLogger('handleRemoveAddress --------------------', id)
    await axios({
        baseURL: process.env.REACT_APP_API_URL,
        url: `/stylefolio/v1/address/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `${token}`,
        },
    }).then(res => {
      dispatch(userSlice.actions.removeUserAddress(res.data))
      
      toast.warn(`You have removed "${res.data.title}" from your billing address records.`, {
        containerId: 'mainToaster',
        progress: undefined,
      });
      // dispatch(uiSlice.actions.setUIToast({
      //   visible: true,
      //   type: 'positive',
      //   icon: 'info circle',
      //   title: `Billing Address Removed`,
      //   message: `You have removed "${res.data.title}" from your billing address records.`
      // }))
    }).catch(err => {
      consoleLogger(err)
    })
    setTheBillingAddress(userAddresses.filter(i => i.default).map(n => n)[0])
    setProcessing(false)
  }

  return (
    <StyledContainer>
      {isReady &&
        <>
          {userInfo.acf &&
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={16}>
                    <div className={cn('billingDropdownCombo', {
                      'new': !billingAddresses
                    })}>
                      {billingAddresses &&
                        <SUIForm.Select name='selected_address' fluid placeholder='Default mailing address' label='Mailing Address *'
                          className='billingDropdown'
                          onChange={(ev, {value}) => {
                            handleBillingAddressChange(value)
                            // setFieldValue('selected_address', value)
                          }}
                          defaultValue={userInfo.acf.same_billing_mailing_address ? 'same_billing_mailing_address' : theBillingAddress ? theBillingAddress.slug : ''}
                          options={billingAddresses}
                          disabled={!(!editAddress && !processing)}
                        />
                      }
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={16}>
                    <>
                      <>
                        {billingAddresses && theBillingAddress && theBillingAddress.slug === "same_billing_mailing_address" ?
                          <>
                            <Button type='button' primary icon='thumbtack' content={<span>Apply</span>} compact
                              onClick={() => handleSetSameBillingMailing(userInfo.acf.billing_address.id)}
                              disabled={userInfo.acf.same_billing_mailing_address}
                            />
                          </> : 
                          <>
                            {theBillingAddress && billingAddresses ?
                              <>
                                <div className={cn('addressDisplayText')}>
                                  {processing &&
                                    <Dimmer inverted active>
                                      <Loader />
                                    </Dimmer>
                                  }
                                  <div className='addressDisplayText_name'>{theBillingAddress.title}</div>
                                  <div className='addressDisplayText_street1'>{theBillingAddress.street_address1}</div>
                                  <div className='addressDisplayText_street2'>{theBillingAddress.street_address2}</div>
                                  <div className='addressDisplayText_citystate'>{theBillingAddress.city}, {theBillingAddress.state} {theBillingAddress.postalcode}</div>
                                  <div className='addressDisplayText_country'>{theBillingAddress.country}</div>
                                  <div className='addressDisplayText_buttons'>
                                    {/* <Button type='button' color='blue' icon='pencil' content={<span>Edit</span>} basic compact
                                      onClick={() => handleEditAddressToggle(true)}
                                    /> */}
                                    <Button type='button' primary icon='thumbtack' content={<span>Set as default mailing address</span>} compact
                                      onClick={() => handleSetDefault(theBillingAddress.id)}
                                      disabled={userInfo.acf.mailing_address && userInfo.acf.mailing_address.id === theBillingAddress.id}
                                    />
                                    {/* {billingAddresses.length > 0 &&
                                      <>
                                        <Button type='button' color='red' icon='trash' content={<span>Remove</span>} compact
                                          onClick={() => handleToggleRemoveModal(true)}
                                          disabled={theBillingAddress.default}
                                        />
                                        {removeOpen &&
                                          <Modal
                                            size='mini'
                                            open={removeOpen}
                                            dimmer={{
                                                blurring: true
                                            }}
                                            onOpen={() => handleToggleRemoveModal(true)}
                                            onClose={() => handleToggleRemoveModal(false)}
                                          >
                                            <Modal.Header>You are about to remove a billing address</Modal.Header>
                                            <Modal.Content>
                                              <p>
                                                <span>Are you sure you want to remove </span>
                                                <span className='text--bold'>{theBillingAddress.title}</span>
                                                <span>?</span>
                                              </p>
                                            </Modal.Content>
                                            <Modal.Actions>
                                              <Button icon='close' content='No' onClick={() => handleToggleRemoveModal(false)} basic />
                                              <Button icon='check' content='Yes' onClick={() => handleRemoveAddress(theBillingAddress.id)} negative />
                                            </Modal.Actions>
                                          </Modal>
                                        }
                                      </>
                                    } */}
                                  </div>
                                </div>
                              </> : 
                              <>asdfasdf</>
                            }
                          </>
                        }
                      </>
                      {editAddress &&
                        <Formik
                          initialValues={{
                            author: userInfo.id,
                            title: newAddress ? '' : theBillingAddress.title,
                            street_address1: newAddress ? '' : theBillingAddress.street_address1,
                            street_address2: newAddress ? '' : theBillingAddress.street_address2 ? theBillingAddress.street_address2 : '',
                            city: newAddress ? '' : theBillingAddress.city,
                            state: newAddress ? '' : theBillingAddress.state,
                            postalcode: newAddress ? '' : theBillingAddress.postalcode,
                            country: newAddress ? '' : theBillingAddress.country,
                            country_code: newAddress ? '' : theBillingAddress.country_code,
                            default: newAddress ? !billingAddresses ? true : false : theBillingAddress.default,
                          }}
                          validateOnChange={false}
                          validateOnBlur={false}
                          enableReinitialize={true}
                          validationSchema={validationSchema}
                          onSubmit={(data, {setErrors, setSubmitting}) => {
                            (async () => {
                              setSubmitting(true)
                              consoleLogger('SUBMITING FORM ===========================', data)

                              await axios({
                                baseURL: process.env.REACT_APP_API_URL,
                                url: `/stylefolio/v1/address${!newAddress ? `/${theBillingAddress.id}` : ''}`,
                                method: newAddress ? 'PUT' : 'POST',
                                headers: {
                                  Authorization: `${token}`,
                                },
                                data: data
                              }).then(res => {
                                let addressTitle = ''
                                if (newAddress) {
                                  addressTitle = res.data.new.title
                                  if (res.data.new.default) {
                                    setTheBillingAddress(res.data.new)
                                  }
                                } else {
                                  addressTitle = res.data.updated.title
                                  setTheBillingAddress(res.data.updated)
                                }
                                dispatch(userSlice.actions.setUserAddresses(res.data.addresses))
                                
                                toast.info(`You have ${newAddress ? 'added' : 'updated'} "${addressTitle}" address.`, {
                                  containerId: 'mainToaster',
                                  progress: undefined,
                                });
                                // dispatch(uiSlice.actions.setUIToast({
                                //   visible: true,
                                //   type: 'positive',
                                //   icon: 'info circle',
                                //   title: newAddress ? 'New Billing Address Added' : 'Billing Address Updated',
                                //   message: `You have ${newAddress ? 'added' : 'updated'} "${addressTitle}" address.`
                                // }))
                              }).catch(err => {
                                // const errorData = err
                                consoleLogger("ERROR: ", err)
                                // setErrors(errorData)
                              })
                              setSubmitting(false)
                            })()
                          }}
                        >
                          {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, values, setFieldValue, errors, isValid, dirty}) => (
                            <Form className='ui form formContainer'>
                              <Modal
                                size='tiny'
                                open={editAddress}
                                dimmer={{
                                    blurring: true
                                }}
                                onOpen={() => handleEditAddressToggle(true)}
                                onClose={() => handleEditAddressToggle(false)}
                              >
                                <Modal.Header>{`${newAddress ? 'New' : 'Edit'} Billing Address`}</Modal.Header>
                                <Modal.Content scrolling>
                                  {isSubmitting &&
                                    <Dimmer inverted active>
                                      <Loader />
                                    </Dimmer>
                                  }
                                  <div className='addressForm'>
                                    <Grid>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input name='title' fluid placeholder='Address Name' type='text' label='Address Name *'
                                            defaultValue={newAddress ? null : theBillingAddress.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.title}
                                            disabled={(isSubmitting)}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Field error={errors.country_code}>
                                            <label>Country *</label>
                                            <Dropdown search selection name='country_code' fluid placeholder='Country' label='Country *'
                                              autoComplete="off"
                                              onChange={(ev, {value}) => {
                                                const cntry = countryOptions.filter(n => n.value === value)[0]
                                                consoleLogger(value, cntry.text)
                                                setFieldValue('country_code', value)
                                                setFieldValue('country', cntry.text)
                                              }}
                                              defaultValue={newAddress ? '' : theBillingAddress.country_code}
                                              options={countryOptions}
                                              disabled={(isSubmitting)}
                                              onFocus={e => { e.target.setAttribute("autocomplete", "nope"); }}
                                            />
                                            {errors.country_code &&
                                              <Label pointing='above' prompt>{errors.country_code}</Label>
                                            }
                                          </SUIForm.Field>
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input name='street_address1' fluid placeholder='Street Address' type='text' label='Street Address *'
                                            defaultValue={newAddress ? null : theBillingAddress.street_address1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.street_address1}
                                            disabled={(isSubmitting)}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input name='street_address2' fluid placeholder='Apartment, suite, building name, etc...' type='text' label='Street Address Line 2 (optional)'
                                            defaultValue={newAddress ? null : theBillingAddress.street_address2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.street_address2}
                                            disabled={(isSubmitting)}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <SUIForm.Input name='city' fluid placeholder='City' type='text' label='City *'
                                            defaultValue={newAddress ? null : theBillingAddress.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.city}
                                            disabled={(isSubmitting)}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={8}>
                                          <SUIForm.Input name='state' fluid placeholder='State' type='text' label='State / Province *'
                                            defaultValue={newAddress ? null : theBillingAddress.state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.state}
                                            disabled={(isSubmitting)}
                                          />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} computer={8}>
                                          <SUIForm.Input name='postalcode' fluid placeholder='Postal Code' type='text' label='Postal / Zip Code *'
                                            defaultValue={newAddress ? null : theBillingAddress.postalcode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.postalcode}
                                            disabled={(isSubmitting)}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                          <Checkbox label='Set as default' toggle fitted
                                            name='activeToggle'
                                            onChange={(ev, {checked}) => {
                                              setFieldValue('default', checked)
                                            }}
                                            defaultChecked={newAddress ? !billingAddresses ? true : false : theBillingAddress.default}
                                            disabled={newAddress ? !billingAddresses ? true : false : theBillingAddress.default}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row className='addressForm_buttons'>
                                        <Grid.Column mobile={16} computer={16}>
                                          <div className='addressForm_buttons_wrap'>
                                          </div>
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  </div>
                                </Modal.Content>
                                <Modal.Actions>
                                  <Button type='button' icon='close' content={<span>Cancel</span>} compact
                                    onClick={() => handleEditAddressToggle(false)}
                                    disabled={isSubmitting}
                                  />
                                  <Button type='submit' color='green' icon='save' content={<span>Save</span>} compact
                                    onClick={handleSubmit}
                                    disabled={!(dirty && !isSubmitting)}
                                  />
                                </Modal.Actions>
                              </Modal>
                            </Form>
                          )}
                        </Formik>
                      }
                    </>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            }
          </>
        }
      </StyledContainer>
  )
}

export default React.memo(AccountMailingForm)
