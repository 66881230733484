import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const UserDisplayNameContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .name {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    margin-right: 0.5rem;
    line-height: 1.6;
  }

  .badge {
    width: 1.35rem;
    height: 1.35rem;
    margin: 0;
    margin-bottom: 0.5rem;

    path {
      fill: ${stylevars.palette.info.main};
    }
  }
`