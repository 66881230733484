import React from 'react'
import cn from 'classnames'
import { ChromePicker } from 'react-color'
import { ColorPickerContainer } from './ColorPickerStyles'
import { consoleLogger } from '@Utils'
import { debounce } from 'lodash-es'

const ColorPicker = (props) => {
  const { color, label, placeholder, handler } = props
  const rgba = props.color.split(',')
  const [ show, setShow ] = React.useState(false)
  const [ colorData, setColorData ] = React.useState({r:rgba[0],g:rgba[1],b:rgba[2],a:rgba[3]})
  const [ colorString, setColorString ] = React.useState(color)
  // const handleShow = (bool) => {
  //   setShow(bool)
  // }
  const handleToggle = () => {
    setShow(prev => !prev)
  }
  const handleChange = (color) => {
    // consoleLogger('=========== color ============', color)
    const rgba = `${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a}`
    setColorData(color.rgb)
    setColorString(rgba)
  }
  React.useEffect(() => {
    consoleLogger('============= COLOR PICKER =============', label, colorString)
    handler(colorString)
  }, [colorString, colorData]) // eslint-disable-line
  return (
    <ColorPickerContainer className={cn({show})}>
      <div className='colorPicker'>
        <div className='wrapper'>
          <div className='label'>{label}</div>
          <div className='box'>
            <div className='color' style={{backgroundColor: `rgba(${colorString})`}} />
          </div>
        </div>
        {show &&
          <>
            <ChromePicker
              className='picker' width='200' color={colorData}
              onChange={handleChange}
            />
          </>
        }
      </div>
      <div className='overlay' onClick={() => handleToggle(true)} />
    </ColorPickerContainer>
  )
}

export default ColorPicker
