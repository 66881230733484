import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const CartItemContainer = styled('div')`
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${stylevars.paper.borderColor};
  }
  .item {
    position: relative;
    padding: 1rem !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0);

    .errorMessage {
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      background-color: ${stylevars.palette.danger.main};
      color: ${stylevars.palette.danger.contrastText};
      padding: 0.5rem;
      line-height: 1;
      font-size: 10px;
    }

    &.error {
      border-color: rgba(${stylevars.palette.danger.rgb}, 1);
      background-color: rgba(${stylevars.palette.danger.rgb}, 0.2);
    }

    &:hover {
      background-color: rgba(${stylevars.paper.borderColorRGB}, 0.25);
    }

    @media ${stylevars.breakpoints.phone} {
      padding: 0 !important;
    }

    .grid {
      width: 100%;
      @media ${stylevars.breakpoints.phone} {
        margin: 0;
      }
      .column {
        @media ${stylevars.breakpoints.phone} {
          margin: 0;
          padding: 0;
        }
      }
    }

    .header {

    }

    .detail {
      margin: 0;
    }

    .extraDetails {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.5);
      margin: 0;
      span {
        display: block;
      }
    }

    .itemActions {
      margin-top: 1rem;
    }

    .placeholder {
      &.header {
        margin: 0;
        margin-bottom: 1rem;
      }
      &.extraDetails {
        margin: 0;
        margin-bottom: 1rem;
      }
    }

    .image {
      width: 100%;
      height: auto;
      @media ${stylevars.breakpoints.phone} {
        margin-bottom: 1rem;
      }
    }

    .content {
      @media ${stylevars.breakpoints.phone} {
        margin-bottom: 2rem;
      }
    }

    .quantity {
      small {
        color: ${stylevars.typography.subtitle.color};
      }
      &Container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 2.5rem;
      }
      @media ${stylevars.breakpoints.phone} {
        margin-right: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .ui.button {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        &.minus {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &.plus {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .quantityText {
        margin: 0;
        font-size: 1rem;
        width: 4rem;
        max-width: none;
        text-align: center;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #E0E1E2;
        border-bottom: 1px solid #E0E1E2;
        &.readonly {
          border: 1px solid #E0E1E2;
        }
      }
    }

    .price, .amount {
      p {
        font-size: 1rem;
        font-weight: normal;
        line-height: 2.5rem;
        margin: 0;
        @media ${stylevars.breakpoints.phone} {
          line-height: 2.5rem;
        }
      }
      small {
        color: ${stylevars.typography.subtitle.color};
      }
    }

    .amount {
      p {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
`