import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Card } from 'semantic-ui-react'

export const Styled404Container = styled.div`
  padding: 0rem;

  .section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .section_wrapper {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    &.fluid {
      .section_wrapper {
        max-width: 100%;
      }
    }

    &.padded {
      padding: 2rem 0;
      .section_wrapper {
        padding: 2rem;
      }
    }
    
    &.hero {
      padding: 2rem;
      min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
      background-color: #f3f3f3;
      background-image: url('/images/404-bg.jpg');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      .section_wrapper {
        align-items: center;
      }

      .hero_context {
        width: 100%;
        max-width: calc(min(calc(var(--vw, 1vw) * 60), 640px));

        img {
          margin-bottom: 1rem;
        }

        h1 {
          font-weight: 900;
          margin: 0 0 1rem 0;
        }

        h3 {
          font-weight: 100;
          margin: 0 0 2rem 0;
        }

        .callToAction {
          text-transform: uppercase;
        }
      }
    }

    &.features {
      .box1 {
        padding: 2rem;
        line-height: 1.6;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .dropzone {
    margin-bottom: 2rem;
    .header {
    }
    &.isDragActive {
      border-color: ${stylevars.palette.success.main};
      background-color: ${stylevars.palette.success.main};
      .header {
        pointer-events: none;
        color: ${stylevars.palette.success.contrastText};
      }
      .inline {
        display: none;
      }
    }
  }
`

export const StyledCard = styled(Card)`
  width: 100% !important;
  margin: 0 !important;
  background-color: rgba(255, 255, 255, 0.85) !important;

  .card_wrapper {
    padding: 2rem;
  }
`