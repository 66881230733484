import React from 'react'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { consoleLogger } from '@Utils'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  Grid,
  Form as SUIForm,
  Icon,
  Button,
} from 'semantic-ui-react'
import { toast } from 'react-toastify';

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(255, "Must be shorter than 255 characters")
    .required('Must enter current password'),
  new_password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(255, "Must be shorter than 255 characters")
    .required('Must a new password'),
  new_password2: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(255, "Must be shorter than 255 characters")
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Must re-enter new password')
})

const PasswordField = (props) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const handleShowPassword = () => {
    consoleLogger('handleShowPassword', showPassword)
    setShowPassword(state => !state)
  }
  return (
    <SUIForm.Input type={showPassword ? 'text' : 'password'}
      icon={<Icon name={`eye ${showPassword ? '' : 'slash'}`} link onClick={handleShowPassword} />}
      {...props}
    />
  )
}

const AccountSecurity = () => {
  const dispatch = useDispatch()
  const { userInfo, userKey } = useSelector(getUserState)
  const token = localStorage.Authorization

  React.useEffect(() => {
    return(() => {})
  }, [])

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          new_password: '',
          new_password2: ''
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          (async () => {
            setSubmitting(true)
            consoleLogger('SUBMITING FORM ===========================', data)
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/stylefolio/v1/password/${userInfo.id}`,
              headers: {
                Authorization: `${token}`,
              },
              method: 'POST',
              data: {
                ...data,
                user_key: userKey
              }
            }).then(res => {
              // const userInfo = res.data
              consoleLogger("USER INFO =====================", userInfo)
              // dispatch(userSlice.actions.setUserInfo({...userInfo}))
              setSubmitting(false)
              
              toast.success('You have successfully updated your password.', {
                containerId: 'mainToaster',
                progress: undefined,
              });
              // dispatch(uiSlice.actions.setUIToast({
              //   visible: true,
              //   type: 'positive',
              //   icon: 'info circle',
              //   title: 'Password Updated',
              //   message: 'You have successfully updated your password.'
              // }))
            }).catch(err => {
              const errorData = err
              consoleLogger("ERROR: ", err)
              setErrors(errorData)
              setSubmitting(false)
              
              toast.warning('Password Update Failed', {
                containerId: 'mainToaster',
                progress: undefined,
              });
              // dispatch(uiSlice.actions.setUIToast({
              //   visible: true,
              //   type: 'negative',
              //   icon: 'warning sign',
              //   title: 'Password Update Failed',
              //   message: `${err}`
              // }))
            })
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, values, setFieldValue, errors, isValid, dirty}) => (
          <>
            <Form className='ui form formContainer'>
              <Grid divided>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={4}>
                    <h3>Account Password</h3>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column mobile={16} computer={16}>
                          <PasswordField name='password' fluid placeholder='Current Password' label='Current Password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.password}
                            disabled={isSubmitting}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column mobile={16} computer={16}>
                          <PasswordField name='new_password' fluid placeholder='New Password' label='New Password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.new_password}
                            disabled={isSubmitting}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column mobile={16} computer={16}>
                          <PasswordField name='new_password2' fluid placeholder='Re-type New Password' label='Re-type New Password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.new_password2}
                            disabled={isSubmitting}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='actions'>
                  <Grid.Column mobile={16}>
                    <Button
                      type='submit'
                      positive
                      onClick={() => handleSubmit()}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default React.memo(AccountSecurity)
