import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { consoleLogger, priceFormat, leadZeros, truncate } from "@Utils";
import axios from "axios";
import { toast } from "react-toastify";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import cartSlice, { getCartState } from "@Slices/cartSlice";
import { getUIState } from "@Slices/uiSlice";

// COMPONENTS
import { Divider, Header, Segment } from "semantic-ui-react";

// STYLES
import { Container } from "./RequestPaymentStyles";

const ToastMessage = (props) => {
  const { title, orderID } = props;

  return (
    <div>
      You have successfully ordered a printed copy of <strong>{title}</strong>{" "}
      with Order ID{" "}
      <Link to={`/admin/orders/${orderID}`}>
        <strong>{orderID}</strong>
      </Link>
    </div>
  );
};

const RequestPayment = (props) => {
  const { handleModal } = props;

  const dispatch = useDispatch();
  const { printRequest } = useSelector(getUserState);
  const { carts } = useSelector(getCartState);
  const token = localStorage.Authorization;

  const [ready, setReady] = React.useState(false);
  const [payer, setPayer] = React.useState();
  const [productName, setProductName] = React.useState();
  const [productDescription, setProductDescription] = React.useState();
  const [purchaseUnits, setPurchaseUnits] = React.useState();
  React.useEffect(() => {
    if (printRequest) {
      const product = printRequest.product;
      const vendor = printRequest.vendor;
      const breakdown = printRequest.breakdown;
      const printOut = printRequest.printOut;
      const currencyCode = breakdown.currency.currency.toUpperCase();
      const customer = printRequest.customer;
      const address = printRequest.address;

      const customerAddress = {
        address_line_1: address.street_address1,
        address_line_2: address.street_address2,
        admin_area_1: address.state,
        admin_area_2: address.city,
        postal_code: address.postalcode,
        country_code: address.country_code,
      };

      setPayer({
        name: {
          given_name: customer.first_name,
          surname: customer.last_name,
        },
        address: customerAddress,
      });

      const tempName = `${product.title} (${printOut.selectedPrintType.name} - ${printOut.selectedSize.name})`;
      const tempProductName = `${vendor.display_name} - ${tempName}`;
      setProductName(tempProductName);

      let tempDescription = `Print Type: ${printOut.selectedPrintType.name} | `;
      tempDescription += `Size: ${printOut.selectedSize.name} | `;
      tempDescription += `Print Material: ${printOut.selectedPaper.name}`;
      setProductDescription(tempDescription);

      setPurchaseUnits([
        {
          reference_id: printRequest.orderID,
          description: "StyleFolio Online Printshop",
          currency_code: currencyCode,
          amount: {
            currency_code: currencyCode,
            value: breakdown.totalAmount,
            breakdown: {
              item_total: {
                currency_code: currencyCode,
                value: breakdown.subAmount,
              },
              shipping: {
                currency_code: currencyCode,
                value: breakdown.shippingFee,
              },
              handling: {
                currency_code: currencyCode,
                value: breakdown.handlingFee,
              },
              discount: {
                currency_code: currencyCode,
                value: breakdown.discount,
              },
            },
          },
          items: [
            {
              name: truncate(tempProductName, 125),
              description: truncate(tempDescription, 125),
              sku: `${vendor.slug}-${product.id}`,
              unit_amount: {
                currency_code: currencyCode,
                value: product.price,
              },
              quantity: product.quantity,
              category: "DIGITAL_GOODS",
            },
          ],
        },
      ]);

      setReady(true);
    }
  }, [printRequest]); // eslint-disable-line

  const handlePaypalApprove = (data, actions) => {
    consoleLogger("================ HANDLE PAYPAL ON APPROVE", data, actions);
    return actions.order.capture().then((details) =>
      (async () => {
        consoleLogger(
          "========================= PAYPAL APPROVE DETAILS",
          details
        );
        await axios({
          baseURL: `${process.env.REACT_APP_API_URL}`,
          url: `/stylefolio/v1/orders/${printRequest.orderID}`,
          method: "PUT",
          headers: {
            Authorization: `${token}`,
          },
          data: {
            selfprint: true,
            author: printRequest.customer.id,
            user_key: printRequest.customer.acf.user_key,
            create_time: details.create_time,
            update_time: details.update_time,
            transaction_id: details.id,
            items: { ...details.purchase_units[0].items },
            cart_data: [
              {
                id: printRequest.vendor.id,
                name: `${printRequest.vendor.first_name} ${printRequest.vendor.last_name}`,
                slug: printRequest.vendor.slug,
                email: printRequest.vendor.email,
                profile_picture: printRequest.vendor.acf.profile_picture,
                subscription_type: printRequest.vendor.acf.subscription_type,
                items: [
                  {
                    id: printRequest.product.id,
                    title: printRequest.product.title,
                    description: printRequest.product.description,
                    slug: printRequest.product.data.post_name,
                    vendorId: printRequest.vendor.id,
                    selectedType: printRequest.printOut.selectedPrintType,
                    selectedSize: printRequest.printOut.selectedSize,
                    selectedFrame: null,
                    selectedPaper: printRequest.printOut.selectedPaper,
                    selectedBorder: null,
                    selectedCanvas: null,
                    base_price: printRequest.product.data.acf.base_price,
                    print_fees: printRequest.product.price,
                    price: printRequest.breakdown.totalAmount,
                    media: printRequest.media,
                    quantity: printRequest.product.quantity,
                  },
                ],
              },
            ],
            customer_info: { ...details.payer },
            // shipping_info: {...details.purchase_units[0].shipping},
            shipping_info: {
              name: {
                full_name: `${printRequest.customer.first_name} ${printRequest.customer.last_name}`,
              },
              address: { ...details.payer.address },
            },
            amount: { ...details.purchase_units[0].amount },
            discount: {
              currency_code: printRequest.breakdown.currency.currency,
              value: priceFormat(printRequest.breakdown.discount),
            },
            payment_status: details.status,
            print_request: {
              selected_size: printRequest.printOut.selectedSize.name,
              selected_paper: printRequest.printOut.selectedPaper.name,
              product_image: printRequest.media.medium,
              item_id: printRequest.product.id,
              item_name: details.purchase_units[0].items[0].name,
              item_description: details.purchase_units[0].items[0].description,
              quantity: printRequest.product.quantity,
              vendor_id: printRequest.vendor.id,
              customer_id: printRequest.customer.id,
            },
          },
        })
          .then((res) => {
            (async () => {
              consoleLogger(res.data);

              // UPDATE USER'S GALLERY DATA
              await axios({
                baseURL: `${process.env.REACT_APP_API_URL}`,
                url: `/stylefolio/v1/gallery`,
                headers: {
                  Authorization: `${token}`,
                },
                method: "GET",
                params: {
                  author: printRequest.customer.id,
                },
              })
                .then((res) => {
                  consoleLogger(
                    "FETCHING USER GALLERY ========================",
                    res.data
                  );
                  dispatch(userSlice.actions.setUserGallery(res.data));
                })
                .catch((err) => {
                  consoleLogger(
                    "FETCH USER GALLERY ERROR: ",
                    err.response.data
                  );
                  setErrors(err.response.data);
                });

              toast(
                <ToastMessage
                  title={printRequest.product.title}
                  orderID={printRequest.orderID}
                />,
                {
                  containerId: "mainToaster",
                  position: "top-center",
                  autoClose: 15000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );

              handleModal(false);
            })();
          })
          .catch((err) => {
            consoleLogger(err);
          });
      })()
    );
  };

  return (
    <Container>
      {ready && (
        <>
          <Segment vertical>
            <Header as="h2">{productName}</Header>
            <p>{productDescription}</p>
            <div className="costs">
              <div className="costs-item">
                <span className="label">Item Cost</span>
                <span className="value">
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={Number(printRequest.product.price)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={printRequest.breakdown.currency.symbol}
                  />
                  <NumberFormat
                    decimalScale={0}
                    fixedDecimalScale={false}
                    value={printRequest.product.quantity}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix="x"
                    className="quantity"
                  />
                </span>
              </div>
              <div className="costs-item">
                <span className="label">Sub Total</span>
                <span className="value">
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={printRequest.breakdown.subAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={printRequest.breakdown.currency.symbol}
                  />
                </span>
              </div>
              <div className="costs-item">
                <span className="label">Shipping Fee</span>
                <span className="value">
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={printRequest.breakdown.shippingFee}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={printRequest.breakdown.currency.symbol}
                    className="shippingFee"
                  />
                  <span className="currentAddress">
                    <span className="currentAddress_label">Ship to:</span>
                    <span className="currentAddress_value">
                      <span className="currentAddress_title">
                        {printRequest.address.title}
                      </span>
                      <span className="currentAddress_address">{` - ${printRequest.address.city}, ${printRequest.address.country_code}`}</span>
                    </span>
                  </span>
                </span>
              </div>
              <div className="costs-item">
                <span className="label">Handling Fee</span>
                <span className="value">
                  <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={printRequest.breakdown.handlingFee}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={printRequest.breakdown.currency.symbol}
                  />
                </span>
              </div>
              <Divider />
              <div className="costs-item">
                <span className="label">
                  <Header as="h3">Total Cost</Header>
                </span>
                <span className="value">
                  <Header as="h3">
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={printRequest.breakdown.totalAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={printRequest.breakdown.currency.symbol}
                    />
                  </Header>
                </span>
              </div>
            </div>
          </Segment>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                payer: payer,
                purchase_units: purchaseUnits,
              });
            }}
            onApprove={handlePaypalApprove}
          />
        </>
      )}
    </Container>
  );
};

export default React.memo(RequestPayment);
