import React from 'react'
import cn from 'classnames'
import {
  getMaterialTypeThumnail
} from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getGalleryState } from '@Slices/gallerySlice'
import vendorSlice, { getVendorState } from '@Slices/vendorSlice'
import cartSlice, { getCartState } from '@Slices/cartSlice'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Header, Icon, Item, Popup } from 'semantic-ui-react'
import { ErrorMessage } from '.'

// STYLES
import { TypesContainer } from './ViewerOptionsTypesStyles'

const ViewerOptionsTypes = (props) => {

  const {
    selectedType,
    handleSelectType
  } = props
  const { gallerySizeTypes, galleryMaterials, gallerySizes } = useSelector(getGalleryState) // eslint-disable-line
  const { viewerData, siteSettings } = useSelector(getUIState) // eslint-disable-line

  return (
    <TypesContainer>
      <div className='viewerOptionGroup'>
        <Header as='h3' className='viewerHeader selectTypeHeader'>
          Select Type
          <Header.Subheader>Choose your medium</Header.Subheader>
        </Header>
        {!selectedType ?
          <ErrorMessage /> :
          <Item.Group
            size='large'
            className='selectType'
            link
          >
            {gallerySizeTypes.filter(n => n.acf.active).map(galleryType => viewerData.acf.print_types.filter(i => i.active).map(type => galleryType.id === type.id ?
              <Item
                key={galleryType.slug}
                typeid={galleryType.id}
                className={cn({
                  'active': Number(selectedType.id) === Number(galleryType.id) ? true : false
                })}
                onClick={() => handleSelectType(galleryType.id)}
              >
                <Item.Image size='small' src={getMaterialTypeThumnail(galleryType.id, gallerySizeTypes)} className='thumbnail' />
                <Item.Content>
                  <Item.Header>{galleryType.name}</Item.Header>
                  <Item.Description>
                    <span className='description'>{galleryType.description}</span>
                    <Popup
                      hideOnScroll
                      trigger={
                        <span className={cn('availability', {
                          'global' : galleryType.acf.available_globally
                        })}>
                          <Icon name={galleryType.acf.available_globally ? 'check' : 'info'} />
                          <span>{galleryType.acf.available_globally ? 'Available globally' : `For ${siteSettings.countryOriginCode} Orders Only`}</span>
                        </span>
                      }
                    >
                      <Popup.Header>Type Availability</Popup.Header>
                      <Popup.Content>
                        {galleryType.acf.available_globally ?
                          <>International customers can order this medium type.</> :
                          <>Only customers from <strong>{siteSettings.countryOriginName}</strong> can order this medium type.</>
                        }
                      </Popup.Content>
                    </Popup>
                  </Item.Description>
                </Item.Content>
              </Item> : 
              null
            ))}
          </Item.Group>
        }
      </div>
    </TypesContainer>
  )
}

export default React.memo(ViewerOptionsTypes)
