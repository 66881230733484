import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const UpgradeModalContainer = styled('div')`

  .wrapper {
    padding: 2rem;
    color: white;
    background-image: linear-gradient(to bottom, rgba(${stylevars.palette.primary.rgb}, 1), rgba(${stylevars.palette.secondary.rgb}, 1));
    text-align: center;

    > p {
      margin: 0;
      padding: 0;
      text-align: center;
    }
    > h2 {
      font-weight: 100;
      font-size: 3rem;
      text-align: center;
      margin: 0;
      margin-bottom: 2rem;
    }
  }

  .paypalButtonWrapper {
    margin-top: 1rem;
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;

    @media ${stylevars.breakpoints.phone} {
      display: block;
    }
  }

  .left {
    padding: 2rem;
    border-right: 1px solid rgba(255,255,255,0.25);

    @media ${stylevars.breakpoints.phone} {
      border: none;
      text-align: center;
    }
  }
  .right {
    padding: 2rem;

    @media ${stylevars.breakpoints.phone} {
      display: flex;
      justify-content: center;
    }
  }

  .annualPrice {
    font-size: 0.85rem;
    text-align: center;
    color: rgba(255,255,255,0.5);
  }

  .price {
    line-height: 1.6;
    .amount {
      font-size: 3rem;
      font-weight: bold;
    }
    .extra {
      font-size: 1.5rem;
      font-weight: lighter;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .customList {
    display: inline-block;
    margin: 0;
    padding: 0;
    min-height: 160px;
    @media ${stylevars.breakpoints.phone} {
      min-height: auto;
    }

    li {
      display: block;
      margin: 1rem 0;
      font-size: 1rem;
      line-height: 1.6;
      list-style: none;
      position: relative;
      padding-left: 2rem;
      cursor: default;
      transition: all 0.15s ease-out;
      > span {
        display: block;
        transition: all 0.15s ease-out;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.5rem;
        height: 1rem;
        width: 1rem;
        border: 1px solid ${stylevars.palette.primary.main};
        border-width: 0.35rem 0.35rem 0 0;
        transform: rotate(45deg);
        transition: all 0.15s ease-out;
      }
      &:hover {
        > span {
          transform: translateX(-0.5rem);
        }
        &:before {
          left: 0.5rem;
          border-width: 0rem;
          background-color: ${stylevars.palette.primary.main};
          transform: rotate(180deg) scale(0.5);
        }
      }
    }

    &.features {
      margin: 0;
      padding: 0;
      li {
        text-align: left;
        padding: 0.5rem 0;
        padding-left: 2rem;
        margin: 0;
        font-size: 1rem;
        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        }
        &:before {
          margin-top: -0.365rem;
          height: 0.75rem;
          width: 0.75rem;
          border: 1px solid ${stylevars.palette.primary.main};
          border-width: 0.25rem 0.25rem 0 0;
        }
        &:hover {
          &:before {
            left: 0rem;
            border-width: 0rem 0rem 0 0;
            border-color: #ffffff;
            background-color: #ffffff;
            border-radius: 50%;
            height: 1rem;
            width: 1rem;
          }
        }
      }
    }
  }
`