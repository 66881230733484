import React from 'react'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import currency from 'currency.js'
import { consoleLogger, maskEmail } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import {
  Dimmer,
  Loader,
  Icon,
  Table,
  Button,
  Popup,
  Modal,
  // Input,
  Form,
} from 'semantic-ui-react'
import AccountPayoutNotice from './AccountPayoutNotice'
import AccountPayoutSettingsForm from './AccountPayoutSettingsForm'

const voucher_codes = require('voucher-code-generator');

const AccountSalesOverview = () => {
  const token = localStorage.Authorization
  const dispatch = useDispatch()
  const componentNameSpace = 'user-sales-overview'
  const { uiComponentReady } = useSelector(getUIState)
  const { authenticated, userInfo, userKey } = useSelector(getUserState)
  const [ isReady, setIsReady ] = React.useState(false)
  const [ pendingBalance, setPendingBalance ] = React.useState(0)
  const [ clearedBalance, setClearedBalance ] = React.useState(0)
  const [ totalPayoutReceived, setTotalPayoutReceived ] = React.useState(0)
  const [ totalPayoutSent, setTotalPayoutSent ] = React.useState(0)
  const [ totalSales, setTotalSales ] = React.useState(0)
  const [ totalBalance, setTotalBalance ] = React.useState(0)

  const fetchSales = async () => {
    let tempPayoutReceived = 0
    let tempPayoutSent = 0
    let tempCleared = 0
    let tempPendingBalance = 0
    // FETCH PAYOUTS
    await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: `/stylefolio/v1/getpayouts`,
      method: 'GET',
      headers: {
        Authorization:`${token}`,
      },
      params: {
        author: userInfo.id,
        user_key: userKey,
      }
    }).then(res => {
      if (res.data.length > 0) {
        if (res.data.filter(n => n.acf.status === "2").length > 0) {
          tempPayoutReceived = res.data.filter(n => n.acf.status === "2").map(i => i.acf.amount).reduce((prev, next) => Number(prev) + Number(next))
        }
        tempPayoutSent = res.data.map(i => i.acf.amount).reduce((prev, next) => Number(prev) + Number(next))
      }
    }).catch(err => {
      consoleLogger(err)
    })

    // FETCH SALES
    await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: `/stylefolio/v1/getsales`,
      method: 'GET',
      headers: {
        Authorization:`${token}`,
      },
      params: {
        author: userInfo.id,
        user_key: userKey,
      }
    }).then(res => {
      if (res.data.length > 0) {
        if (res.data.filter(n => n.acf.status === "0").length > 0) {
          tempPendingBalance = res.data.filter(n => n.acf.status === "0").map(i => i.acf.total_amount).reduce((prev, next) => Number(prev) + Number(next))
        }
        if (res.data.filter(n => n.acf.status === "2").length > 0) {
          tempCleared = res.data.filter(n => n.acf.status === "2").map(i => i.acf.total_amount).reduce((prev, next) => Number(prev) + Number(next))
          if (tempPayoutSent > 0) {
            tempCleared -= tempPayoutSent
          }
        }
        setTotalSales(res.data.map(i => i.acf.total_amount).reduce((prev, next) => Number(prev) + Number(next)))
      }
    }).catch(err => {
      consoleLogger(err)
    })

    setTotalPayoutSent(tempPayoutSent)
    setTotalPayoutReceived(tempPayoutReceived)
    setPendingBalance(tempPendingBalance)
    setClearedBalance(tempCleared)

    return () => {}
  }

  React.useEffect(() => {
    setIsReady(uiComponentReady.filter(i => i.component === componentNameSpace).map(n => n.isReady)[0])
  }, [uiComponentReady])

  React.useEffect(() => {
    (async () => {
      dispatch(uiSlice.actions.updateUIComponentReady({component: componentNameSpace, isReady: false}))

      await fetchSales()

      dispatch(uiSlice.actions.updateUIComponentReady({component: componentNameSpace, isReady: true}))
    })()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    const total = (pendingBalance + clearedBalance)
    setTotalBalance(total)
  }, [pendingBalance, clearedBalance, totalPayoutReceived, totalSales])
  
  const [ settingsOpen, setSettingsOpen ] = React.useState(false)
  const handleOpenPayoutSettings = (bool) => {
    setSettingsOpen(bool)
  }
  const [ noticeOpen, setNoticeOpen ] = React.useState(false)
  const handleOpenNotice = (bool) => {
    setNoticeOpen(bool)
  }
  const payoutMethodLabel = [
    {
      key: "paypal",
      label: "PayPal"
    }
  ]

  return (
    <>
          
      {!isReady &&
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      }

      <Table basic='very'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Pending Balance</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={pendingBalance} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Cleared for Payout</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={clearedBalance} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              <Popup content={`Minimum payout amount is ${currency(userInfo.acf.payout_minimum, {symbol: '$', precision: 2}).format()}`} trigger={<Icon name='info circle' className='cashoutInfoIcon' color='blue' />} />
            </Table.Cell>
          </Table.Row>
          {/* <Table.Row disabled>
            <Table.Cell>Total Payout Received</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={totalPayoutReceived} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Table.Cell>
          </Table.Row> */}
          <Table.Row disabled>
            <Table.Cell>Total Payout Requested</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={totalPayoutSent} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Table.Cell>
          </Table.Row>
          <Table.Row disabled>
            <Table.Cell>Total Overall Sales</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={totalSales} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Table.Cell>
          </Table.Row>
          <Table.Row className='balance'>
            <Table.Cell>Total Balance</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={totalBalance} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button fluid positive className='payoutButton'
        disabled={clearedBalance < 100}
        onClick={() => handleOpenNotice(true)}
      >Claim Payout</Button>

      
      <h3>Payout Settings</h3>
      <Table basic='very'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Default Payout</Table.Cell>
            <Table.Cell textAlign='right'>
              {userInfo.acf.paypal_email && userInfo.acf.payout_method ?
                <>
                  {payoutMethodLabel.filter(i => i.key === userInfo.acf.payout_method).map(n => n.label)} <span className='text--bold'>[{maskEmail(userInfo.acf.paypal_email)}]</span>
                </> : 
                <span className="text--italic">Update your settings</span>
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Minimum Payout</Table.Cell>
            <Table.Cell textAlign='right'>
              <NumberFormat decimalScale={2} fixedDecimalScale={true} value={userInfo.acf.payout_minimum} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button fluid positive
        onClick={() => handleOpenPayoutSettings(true)}
      >
        Update Payout Settings
      </Button>
      { noticeOpen &&
        <AccountPayoutNotice
          componentNameSpace={componentNameSpace}
          open={noticeOpen}
          handler={handleOpenNotice}
          clearedBalance={clearedBalance}
          fetchSales={fetchSales}
        />
      }
      { settingsOpen &&
        <AccountPayoutSettingsForm open={settingsOpen} handler={handleOpenPayoutSettings} />
      }
    </>
  )
}

export default React.memo(AccountSalesOverview)
