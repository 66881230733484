import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
// import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import { takeRight, reverse } from 'lodash'
// import useLocalStorage from 'react-use-localstorage'
import {
  consoleLogger,
} from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
// import { getUIState } from '@Slices/uiSlice'
import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import { Button, Dropdown, Header, Menu, Modal, Icon } from 'semantic-ui-react'
import LoginModal from '@AdminPages/Login/LoginModal'
import SFAvatar from '@Common/Avatar'
import { UpgradePlanButton } from '@AdminPages/Account/Components'
// import CurrencySelection from './CurrencySelection'

// STYLES
import { Container } from './UserDropdownMenuStyles'

const UserDropdownMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const currentPathname = location.pathname
  const { authenticated, userInfo, userGallery } = useSelector(getUserState)
  
  const [open, setOpen] = React.useState(false)
  const handleModalOpen = (bool) => {
    setOpen(bool)
  }

  const handleLogout = () => {
    setOpen(false)
    dispatch(userSlice.actions.setAuthenticated(false))
    dispatch(userSlice.actions.setUserInfo(''))
    dispatch(userSlice.actions.setUserKey(''))
    dispatch(userSlice.actions.setUserAddresses([]))
    localStorage.removeItem('Authorization')
    // localStorage.removeItem('carts')
    localStorage.removeItem('coupons')
    localStorage.removeItem('cartItems')
    dispatch(cartSlice.actions.setCart([]))
    // reset user completion
    dispatch(userSlice.actions.updateAccountCompletion({
      hasAddress: false,
      hasProfilePhoto: false,
      hasCoverPhoto: false,
    }))
  }
  
  const [loginOpen, setLoginOpen] = React.useState(false)
  const handleLoginModal = (bool) => {
    const restrictedPath = ['/login', '/signup', '/resetpassword']
    consoleLogger('---------------------- currentPathname', currentPathname)
    if (restrictedPath.some(path => currentPathname.indexOf(path) > -1)) {
      history.push('/login')
    } else {
      setLoginOpen(bool)
    }
  }
  
  const accountTrigger = (
    <>
      {userInfo &&
        <span className='trigger'>
          {Object.values(userInfo).length >= 1 &&
            <>
              {/* <span className='triggerName'>{userInfo.display_name}</span> */}
              <SFAvatar className='avatar' width='7rem' height='7rem' userInfo={userInfo} />
            </>
          }
        </span>
      }
    </>
  )

  return (
    <Container>
      {authenticated ? 
        <>
          <Menu.Item className='menuItemProfile'>
            <Dropdown
              className='menuDropdown'
              trigger={accountTrigger}
              // options={userInfo.role === 'sf_creator' ? accountCreatorOptions : accountCustomerOptions}
              pointing='top right'
              icon={null}
            >
              <Dropdown.Menu>
                <Dropdown.Header>
                  <p>Welcome back,</p>
                  {userInfo &&
                    <Header as='h3' className='triggerName'>{userInfo.display_name}</Header>
                  }
                  {userInfo && userInfo.acf.subscription_type === 'starter' &&
                    <UpgradePlanButton />
                  }
                </Dropdown.Header>
                <Dropdown.Divider />
                {userInfo && userInfo.role === 'sf_creator' ?
                  <>
                    {userGallery && userGallery.length > 0 &&
                      <>
                        <Dropdown.Item as={Link} to={`/@${userInfo.username}`}>
                          <Icon name='grid layout' size='huge' />
                          <span>View Gallery</span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    }
                    <Dropdown.Item text='My Dashboard' icon='table' as={Link} to='/admin' />
                    <Dropdown.Item text='My Sales' icon='dollar' as={Link} to='/admin/account/sales' />
                    <Dropdown.Item text='My Account' icon='user' as={Link} to='/admin/account' />
                    <Dropdown.Divider />
                    <Dropdown.Item text='Update Profile' icon='cog' as={Link} to='/admin/layout' />
                    <Dropdown.Item text='Setup Gallery' icon='cog' as={Link} to='/admin/gallery' />
                    <Dropdown.Item text='Setup Collections' icon='cog' as={Link} to='/admin/collections' />
                  </> : 
                  <>
                    <Dropdown.Item text='My Orders' icon='file alternate outline' as={Link} to='/admin/orders' />
                    <Dropdown.Item text='My Account' icon='user' as={Link} to='/admin/account' />
                  </>
                }
                <Dropdown.Divider />
                <Dropdown.Item text='Logout' icon='sign out' onClick={() => handleModalOpen(true)} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </> :
        <>
          <Menu.Item className='menuItemUser loginSignupGroup'>
            <Button.Group className='loginSignupButton'>
              <Button onClick={handleLoginModal} positive icon='key' content={<span>Login</span>} />
              <Button.Or />
              <Button as={Link} to='/signup' icon='user' content={<span>Signup</span>} />
            </Button.Group>
          </Menu.Item>
        </>
      }
      {open &&
        <Modal
          size='mini'
          open={open}
          dimmer={{
              blurring: true
          }}
          onOpen={() => handleModalOpen(true)}
          onClose={() => handleModalOpen(false)}
        >
          <Modal.Header>Leaving so soon?</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to logout?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => handleModalOpen(false)} basic>
              No
            </Button>
            <Button onClick={handleLogout} positive>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      }
      {loginOpen &&
        <LoginModal open={loginOpen} handler={handleLoginModal} to={currentPathname} />
      }
    </Container>
  )
}

export default React.memo(UserDropdownMenu)
