import React from 'react'
import axios from 'axios'
import cn from 'classnames'
// import NumberFormat from 'react-number-format'
// import currency from 'currency.js'
// import NumberFormat from 'react-number-format'
// import he from 'he'
// import { format, isFuture, parse, isDate } from 'date-fns'
import { consoleLogger } from '@Utils'
import { useReactToPrint } from 'react-to-print';

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
// import cartSlice, { getCartState } from '@Slices/cartSlice'
// import { getGalleryState } from '@Slices/gallerySlice'

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// COMPONENTS
import {
  // Icon,
  // Table,
  Button,
  Checkbox,
  Modal,
  Grid,
  Label,
  // Form as SUIForm,
  // Dimmer,
  // Loader,
  // Popup,
  // Search,
  // Dropdown,
} from 'semantic-ui-react'

import OrderStatusView from './OrderStatusView'

const OrdersModal = (props) => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(getUserState)
  const {
    info,
    handleOpen,
    handleClose,
    isEdit,
    data,
    open,
    handleFetch,
  } = props

  // consoleLogger(data)

  const printComponentRef = React.useRef(null)

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: `${process.env.REACT_APP_PROJECT_NAME} - ${data.acf.order_id}`
  })

  return (
          <Modal
            onClose={() => handleClose}
            onOpen={() => handleOpen}
            open={open}
            dimmer={{
                blurring: true
            }}
            size='large'
          >
            <>
              <Modal.Content scrolling>
                <Modal.Description>
                  <OrderStatusView ref={printComponentRef} orderid={data.acf.order_id} />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type='button'
                  content='Print'
                  labelPosition='right'
                  icon='print'
                  onClick={handlePrint}
                />
                <Button
                  type='submit'
                  content="Close"
                  labelPosition='right'
                  icon='check'
                  onClick={() => handleClose()}
                  primary
                  // loading={isSubmitting}
                  // disabled={!(dirty && isValid && !isSubmitting)}
                />
              </Modal.Actions>
            </>
          </Modal>
  )
}

export default React.memo(OrdersModal)