import React from "react";
import cn from "classnames";
// import { Link } from 'react-router-dom'
import axios from "axios";
// import { setAuthorizationHeader } from '@Utils'
// import jwtDecode from 'jwt-decode'
import { useHistory } from "react-router-dom";
// import he from 'he'
// import NumberFormat from 'react-number-format'
// import { findIndex, nth } from 'lodash'
// import { PayPalButton } from "react-paypal-button-v2"
// import PaypalExpressBtn from 'react-paypal-express-checkout';
import { consoleLogger, priceFormat, cleanPrice, truncate } from "@Utils";
import { PayPalButtons } from "@paypal/react-paypal-js";

// FORMIK
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getUserState } from "@Slices/userSlice";
import { getUIState } from "@Slices/uiSlice";
import cartSlice, { getCartState } from "@Slices/cartSlice";
// import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import {
  Header,
  Divider,
  // Segment,
  Grid,
  // Search,
  Button,
  // Icon,
  // Input,
  Image,
  // Dimmer,
  // Loader,
  // Confirm,
  // Table,
} from "semantic-ui-react";

// STYLES
import { ConfirmOrderContainer } from "./ConfirmOrderStyles";

const voucher_codes = require("voucher-code-generator");

const SelectPayment = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { userInfo, userKey } = useSelector(getUserState);
  const { siteSettings, currency } = useSelector(getUIState);
  const { carts, coupons, paymentType, checkout } = useSelector(getCartState);

  const token = localStorage.Authorization;

  // const handleNext = () => {
  //   if (paymentType && paymentType ==='paypal') {
  //     history.push('/checkout/billing')
  //   } else {
  //     history.push('/checkout/payment')
  //   }
  // }

  // const handleSelectPayment = (el) => {
  //   dispatch(cartSlice.actions.setPaymentType(el.currentTarget.name))
  // }

  // const client = {
  //   sandbox:    process.env.REACT_APP_PAYPAL_CLIENT_ID,
  //   production: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  // }

  const [ready, setReady] = React.useState(false);
  const [userAddress, setUserAddress] = React.useState();

  // React.useEffect(() => {
  //   if (userInfo) {
  //     if (userInfo.acf.same_billing_mailing_address) {
  //       setUserAddress({
  //         address_line_1: userInfo.acf.billing_address.street_address1,
  //         address_line_2: userInfo.acf.billing_address.street_address2,
  //         admin_area_1: userInfo.acf.billing_address.state,
  //         admin_area_2: userInfo.acf.billing_address.city,
  //         postal_code: userInfo.acf.billing_address.postalcode,
  //         country_code: userInfo.acf.billing_address.country_code,
  //       });
  //     } else {
  //       setUserAddress({
  //         address_line_1: userInfo.acf.mailing_address.street_address1,
  //         address_line_2: userInfo.acf.mailing_address.street_address2,
  //         admin_area_1: userInfo.acf.mailing_address.state,
  //         admin_area_2: userInfo.acf.mailing_address.city,
  //         postal_code: userInfo.acf.mailing_address.postalcode,
  //         country_code: userInfo.acf.mailing_address.country_code,
  //       });
  //     }
  //   }
  // }, [userInfo]); // eslint-disable-line

  React.useEffect(() => {
    if (checkout) {
      setUserAddress({
        address_line_1: checkout.shippingAddress.street_address1,
        address_line_2: checkout.shippingAddress.street_address2,
        admin_area_1: checkout.shippingAddress.state,
        admin_area_2: checkout.shippingAddress.city,
        postal_code: checkout.shippingAddress.postalcode,
        country_code: checkout.shippingAddress.country_code,
      });
    }
    // eslint-disable-next-line
  }, [checkout]);

  React.useEffect(() => {
    if (userAddress) {
      setReady(true);
    }
  }, [userAddress]); // eslint-disable-line

  return (
    <ConfirmOrderContainer>
      {ready && (
        <div className="checkoutContainer">
          <Header as="h3">Select Payment Method</Header>
          <div className="paymentSegment select">
            {carts && checkout && checkout.totalAmount !== 0 ? (
              <>
                <PayPalButtons
                  createOrder={(data, actions) => {
                    consoleLogger(
                      "createOrder DATA ACTIONS ======================",
                      data,
                      actions
                    );
                    const purchases = [];
                    let totalAmount = 0;
                    carts.map((vendor) =>
                      vendor.items.map((item) => {
                        let itemDescription = `Print Type: ${item.selectedType.name} | `;
                        if (item.selectedSize) {
                          itemDescription += `Size: ${item.selectedSize.name} | `;
                        }
                        if (item.selectedFrame) {
                          itemDescription += `Frame: ${item.selectedFrame.name} | `;
                        }
                        if (item.selectedBorder) {
                          itemDescription += `Border: ${item.selectedBorder.name} | `;
                        }
                        if (item.selectedPaper) {
                          itemDescription += `Print Material: ${item.selectedPaper.name} | `;
                        }
                        if (item.selectedCanvas) {
                          itemDescription += `Canvas: ${item.selectedCanvas.name} | `;
                        }
                        const tempName = `${vendor.name} - ${item.title} (${item.selectedType.name} - ${item.selectedSize.name})`;
                        consoleLogger(
                          "################################################",
                          cleanPrice(item.price * currency.value)
                        );
                        const itm = {
                          name: truncate(tempName, 125),
                          description: truncate(itemDescription, 125),
                          sku: `${vendor.slug}-${item.id}-${item.base_price}-${item.quantity}`,
                          unit_amount: {
                            currency_code: checkout.currency.toUpperCase(),
                            value: cleanPrice(item.price * currency.value),
                          },
                          quantity: item.quantity,
                          category: "DIGITAL_GOODS",
                        };
                        consoleLogger("ITEM ===============", itm);
                        purchases.push(itm);
                        // totalAmount += priceFormat((Number(item.price) * Number(item.quantity)) * currency.value)
                        const convertedItemPrice = cleanPrice(
                          Number(item.price) * Number(currency.value)
                        );
                        totalAmount += priceFormat(
                          Number(convertedItemPrice) * Number(item.quantity)
                        );
                      })
                    );
                    consoleLogger(
                      "PURCHASE ITEMS ========================",
                      checkout.orderID,
                      purchases,
                      checkout
                    );
                    return actions.order.create({
                      payer: {
                        name: {
                          given_name: userInfo.first_name,
                          surname: userInfo.last_name,
                        },
                        address: userAddress,
                      },
                      purchase_units: [
                        {
                          reference_id: checkout.orderID,
                          description: "StyleFolio Online Printshop",
                          currency_code: checkout.curreyncy,
                          amount: {
                            currency_code: checkout.currency.toUpperCase(),
                            value: priceFormat(checkout.totalAmount),
                            breakdown: {
                              item_total: {
                                currency_code: checkout.currency.toUpperCase(),
                                value: priceFormat(totalAmount),
                              },
                              shipping: {
                                currency_code: checkout.currency.toUpperCase(),
                                value: priceFormat(checkout.shippingFee),
                              },
                              handling: {
                                currency_code: checkout.currency.toUpperCase(),
                                value: priceFormat(checkout.handlingFee),
                              },
                              discount: {
                                currency_code: checkout.currency.toUpperCase(),
                                value: priceFormat(checkout.discount),
                              },
                            },
                          },
                          items: purchases,
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    let errors = 0;
                    // Capture the funds from the transaction
                    return actions.order.capture().then((details) =>
                      (async () => {
                        // Show a success message to your buyer
                        consoleLogger(
                          "APPROVED =====================",
                          details
                        );
                        // OPTIONAL: Call your server to save the transaction

                        // CREATE ORDER
                        await axios({
                          baseURL: `${process.env.REACT_APP_API_URL}`,
                          url: `/stylefolio/v1/orders/${checkout.orderID}`,
                          method: "PUT",
                          headers: {
                            Authorization: `${token}`,
                          },
                          data: {
                            author: userInfo.id,
                            user_key: userKey,
                            create_time: details.create_time,
                            update_time: details.update_time,
                            transaction_id: details.id,
                            items: {
                              ...details.purchase_units[0].items,
                            },
                            cart_data: carts,
                            customer_info: { ...details.payer },
                            // shipping_info: {...details.purchase_units[0].shipping},
                            shipping_info: {
                              name: {
                                full_name: `${userInfo.first_name} ${userInfo.last_name}`,
                              },
                              address: userAddress,
                            },
                            amount: { ...details.purchase_units[0].amount },
                            discount: {
                              currency_code: checkout.currency.toUpperCase(),
                              value: priceFormat(checkout.discount),
                            },
                            payment_status: details.status,
                          },
                        })
                          .then((res) => {
                            consoleLogger("CREATE ORDER RESPONSE", res.data);
                          })
                          .catch((err) => {
                            consoleLogger(err);
                            errors++;
                          });

                        // CREATE SALES
                        const createSalesPromise = carts.map((vendor) =>
                          vendor.items.map(async (item) => {
                            let itemDescription = `Print Type: ${item.selectedType.name} | `;
                            if (item.selectedSize) {
                              itemDescription += `Size: ${item.selectedSize.name} | `;
                            }
                            if (item.selectedFrame) {
                              itemDescription += `Frame: ${item.selectedFrame.name} | `;
                            }
                            if (item.selectedBorder) {
                              itemDescription += `Border: ${item.selectedBorder.name} | `;
                            }
                            if (item.selectedPaper) {
                              itemDescription += `Print Material: ${item.selectedPaper.name} | `;
                            }
                            if (item.selectedCanvas) {
                              itemDescription += `Canvas: ${item.selectedCanvas.name} | `;
                            }
                            const tempName = `${vendor.name} - ${item.title} (${item.selectedType.name} - ${item.selectedSize.name})`;
                            const salesID = voucher_codes.generate({
                              prefix: "SALES-",
                              pattern: "################",
                              postfix: `-${new Date().getFullYear()}`,
                              charset: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                            })[0];

                            consoleLogger(
                              "SALES ID =====================",
                              salesID
                            );

                            // // GET VENDOR USERKEY
                            // let vendorUserKey
                            // let fee
                            // await axios({
                            //   baseURL: `${process.env.REACT_APP_API_URL}`,
                            //   url: `/stylefolio/v1/userkey/${vendor.id}`,
                            //   method: 'GET',
                            //   headers: {
                            //     Authorization:`${token}`,
                            //   },
                            // }).then(res => {
                            //   vendorUserKey = res.data.user_key
                            //   if (res.data)
                            // }).catch(err => {
                            //   consoleLogger(err)
                            // })

                            // CREATE SALES FOR EACH VENDOR ITEM
                            await axios({
                              baseURL: `${process.env.REACT_APP_API_URL}`,
                              url: `/stylefolio/v1/sales/${salesID}`,
                              method: "PUT",
                              headers: {
                                Authorization: `${token}`,
                              },
                              data: {
                                vendor_id: vendor.id,
                                customer_id: userInfo.id,
                                sales_id: salesID,
                                order_id: checkout.orderID,
                                transaction_id: details.id,
                                vendor_info: {
                                  id: vendor.id,
                                  name: vendor.name,
                                  slug: vendor.slug,
                                  email: vendor.email,
                                  profile_picture: vendor.profile_picture,
                                },
                                customer_info: userInfo,
                                amount: item.base_price,
                                quantity: item.quantity,
                                // total_amount: (Number(item.base_price) ) * Number(item.quantity),
                                // fees: item.print_fees,
                                selected_size: item.selectedSize.name,
                                selected_paper: item.selectedPaper.name,
                                product_image: item.media.medium,
                                item_id: item.id,
                                item_name: tempName,
                                item_description: itemDescription,
                                coupon: coupons
                                  .filter((i) => i.vendorId === vendor.id)
                                  .map((n) => n)[0],
                                transaction_amount: {
                                  ...details.purchase_units[0].amount,
                                },
                                sku: `${vendor.slug}-${item.id}-${item.base_price}-${item.quantity}`,
                              },
                            })
                              .then((res) => {
                                consoleLogger(
                                  "CREATE SALES RESPONSE",
                                  res.data
                                );
                              })
                              .catch((err) => {
                                consoleLogger(err);
                                errors++;
                              });
                          })
                        );

                        await Promise.all(createSalesPromise);

                        if (errors > 0) {
                          history.push(`/`);
                        } else {
                          history.push(`/orders/${checkout.orderID}`);
                        }
                      })()
                    );
                  }}
                />
              </>
            ) : (
              <>Loading...</>
            )}
          </div>
        </div>
      )}
    </ConfirmOrderContainer>
  );
};

export default React.memo(SelectPayment);
