import React from 'react'
import { useHistory } from 'react-router'
import cn from 'classnames'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { Button, Checkbox } from 'semantic-ui-react'
// import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getUserState } from '@Slices/userSlice'
// import gallerySlice, { getGalleryState } from '@Slices/gallerySlice'

// STYLES
import { ToolbarContainer } from './ToolbarStyles'

const Actions = (props) => {
  switch (props.type) {
    case 'checkbox':
      return (
        <Checkbox className={props.className} toggle onChange={props.callback} name={props.name} label={props.label} />
      )
    case 'component':
      return (props.render)
    default:
      return (
        <Button
          color={props.color}
          icon={props.icon}
          content={<span>{props.label}</span>}
          labelPosition="right"
          className={props.className}
          onClick={props.callback}
          disabled={props.disabled}
        />
      )
  }
}

const DashToolbar = (props) => {
  const {
    title,
    subtitle,
    actions,
    showBack,
    disabled,
    noGap,
    className,
    extras
  } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { userInfo, userGallery } = useSelector(getUserState)
  const { siteSettings, galleryCanArrange, galleryShowUploader } = useSelector(getUIState)
  const [ ready, setReady ] = React.useState(true)
  const handleArrangeToggle = () => {
    dispatch(uiSlice.actions.setGalleryArrange(!galleryCanArrange))
  }
  const setShowGalleryUploader = () => {
    dispatch(uiSlice.actions.setShowGalleryUploader(true))
  }

  const [ toolbarShow, setToolbarShow ] = React.useState(false)
  const handleToolbarToggle = () => {
    setToolbarShow(prev => !prev)
  }
  const handleBackClick = () => {
    history.goBack()
  }

  return (
    <ToolbarContainer
      className={cn('dashToolbar', {
        noGap,
        [`${className}`]: className
      })}
     >
      {ready &&
        <>
          <div className='toolbar-column left'>
            <div className='title'>
              {showBack &&
                <Button type='button' icon='arrow left' circular className='goBack' basic onClick={handleBackClick} />
              }
              <h1>{title}</h1>
              <div className='subtitle'>{subtitle}</div>
            </div>
            {actions && actions.length > 0 &&
              <div className={cn('toggler', {
                'show': toolbarShow
              })}>
                <Button type='button' basic icon={`chevron ${toolbarShow ? 'up' : 'down'}`} size='small' onClick={handleToolbarToggle} />
              </div>
            }
          </div>
          <div className={cn('toolbar-column right', {
            'show' : toolbarShow
          })}>
            {actions && actions.length > 0 &&
              <div className='action-group'>
                {actions.map(action => (
                  <Actions {...action} />
                ))}
              </div>
            }
          </div>
        </>
      }
    </ToolbarContainer>
  )
}

export default React.memo(DashToolbar)
