import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`
  .currentAddress {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.25rem;

    .currentAddressValue {
      margin-left: 0.5rem;
      padding: 0.25rem 0.5rem;
      border: 1px solid rgba(0,0,0,0.15);
      background-color: #ffffff;
      border-radius: 0.25rem;
    }
  }
`

export const ModalContainer = styled('div')`
  min-height: 200px;
`