import React from "react";
import styled from "styled-components";
import { stylevars } from "@Common/StyleVars";
import { darken } from "polished";

const StoreUserContainer = styled.div`

  .profile {
    padding: 2rem;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${stylevars.breakpoints.phone} {
      height: 220px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      position: absolute;
      z-index: 2;
      bottom: 0;
      pointer-events: none;
    }

    /* .profile-image */
    &-image {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      bottom: -4rem;
      left: 2rem;
      z-index: 11;
      /* box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15); */
      background-color: var(--sfProfile_bgColor);
      background-image: var(--sfProfile_useProRing);
      transition: all 0.15s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
      
      /* position: sticky;
      top: ${stylevars.navBar.height};
      left: 2rem; */
      img {
        width: calc(94%);
        height: calc(94%);
        border-radius: 50%;
        object-fit: cover;
        display: block;
        line-height: 0;
        margin: 0;
        padding: 0;
      }

      @media ${stylevars.breakpoints.phone} {
        width: 120px;
        height: 120px;
        bottom: auto;
        top: calc(50% - 3rem);
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    /* .profile-name */
    &-name {
      margin: 0;
      position: absolute;
      z-index: 3;
      bottom: 3rem;
      font-size: 2.5rem;
      font-weight: 200 !important;
      left: calc(180px + 4rem);
      color: var(--sfProfile_titleTextColor);
      transition: all 0.15s ease-out;
      line-height: 1;
      padding-right: 2rem;
      width: auto;
      white-space: nowrap;

      .name {
        flex-grow: 0;
      }

      .badge {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
        font-size: 0.75rem;
        vertical-align: super;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 5rem;
        /* fill: var(--sfProfile_titleTextColor); */
        margin-left: 0.5rem;
        line-height: 0.75;
        path {
          fill: var(--sfProfile_titleTextColor);
        }
      }

      @media ${stylevars.breakpoints.phone} {
        font-size: 2rem;
        left: 0;
        /* width: 100%; */
        text-align: center;
      }
    }

    /* .profile-social */
    &-social {
      position: absolute;
      z-index: 3;
      left: calc(180px + 4rem);
      bottom: 0rem;
      justify-content: flex-start;

      @media ${stylevars.breakpoints.phone} {
        left: 50%;
        justify-content: center;
        transform: translateX(-50%);
      }
    }

    /* .profile-cover */
    &-cover {
      position: absolute;
      z-index: 1;
      /* top: ${stylevars.navBar.height}; */
      top: 0;
      left: 0;
      /* width: calc(var(--vw, 1vw) * 100); */
      /* height: calc(min(calc(var(--vh, 1vh) * 40), 320px)); */
      width: 100%;
      height: 320px;
      padding: 0;
      margin: 0;
      display: block;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      @media ${stylevars.breakpoints.phone} {
        height: 220px;
      }
    }
  }

  .toolbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0rem;
    /* background-color: ${stylevars.paper.borderColor}; */
    background-color: var(--sfProfile_bgColor);

    /* .toolbar-wrapper */
    &-wrapper {
      width: calc(100% - calc(180px + 4rem));
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: calc(180px + 4rem);
      border-bottom: 1px solid var(--sfProfile_dividerColor);
      transition: all 0.15s ease-out;

      @media ${stylevars.breakpoints.phone} {
        margin-left: 0;
        width: 100%;
      }

      .button {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .button {
      border: none;
      border-radius: 0;
      box-shadow: none;
      border: none;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: var(--sfProfile_linkLineColor);
      background-color: var(--sfProfile_linkBGColor);
      margin: 0;
      padding: 1rem 2rem;
      padding-bottom: calc(1rem - 2px);
      color: var(--sfProfile_linkTextColor);
      &:hover {
        background-color: var(--sfProfile_linkBGColorHover);
        border-bottom-color: var(--sfProfile_linkLineColorHover);
        color: var(--sfProfile_linkTextColorHover);
      }
      &.active {
        background-color: var(--sfProfile_linkBGColorActive);
        border-bottom-color: var(--sfProfile_linkLineColorActive);
        color: var(--sfProfile_linkTextColorActive);
      }
    }
  }

  > .content {
    padding: 2rem;
    background-color: var(--sfProfile_bgColor);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 320px - ${stylevars.navBar.height} - 3rem);
  }

  .galleryContainer {
    position: relative;
    /* min-height: calc(calc(var(--vh, 1vh) * 100) - 35vh - 50px - 12rem); */
  }

  .gallery {
    padding: 0rem;
    display: flex;
    width: 100%;

    /* .gallery-col */
    &-col {
      margin: 0;
      border: none;
      overflow: hidden;
      box-sizing: border-box;

      &:hover {
        .gallery-item {
          &:before {
            opacity: 1;
          }
          .gallery-item-meta {
            opacity: 1;
          }
          .imageWrapper {
            img {
              transform: scale(1.05);
            }
          }
          .gallery-item-title {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    /* gallery-item */
    &-item {
      display: block;
      padding: 0;
      margin: 0;
      background-clip: padding-box;
      transition: all 0.15s ease-out;
      position: relative;
      z-index: 1;
      transform: scale(1);
      overflow: hidden;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: radial-gradient(closest-corner at center, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
        opacity: 0;
        transition: all 0.15s ease-out;
        pointer-events: none;
        box-sizing: border-box;
      }

      /* .gallery-item-title */
      &-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
        color: var(--sfProfile_galleryTitleColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
        font-weight: normal;
        padding: 2rem;
        line-height: 1.6;
        text-align: center;
        opacity: 0;
        transition: all 0.15s ease-out;
        z-index: 4;
      }

      .placeholder {
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        min-height: 220px;
      }

      .imageWrapper {
        position; relative;
        z-index: 2;
        display: block !important;
        padding: 0;
        margin: 0;
        overflow: hidden;

        img {
          display: block;
          margin: 0;
          transition: all 0.15s ease-out;
          width: calc(100%);
          height: auto;
          display: block;
          line-height: 0;
          transform: scale(1);
        }
      }

      /* .gallery-item-meta */
      &-meta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        padding: 0.5rem;
        opacity: 0.35;
        transition: opacity 0.15s ease-out;
        text-align: right;

        .meta-block {
          display: inline-block;
          font-size: 10px;
          margin: 0 0.5rem;
          color: #fff;

          &.limited-edition-badge {
            > span {
              position: relative;
              display: block;
              padding: 0.25rem;
              margin: 0;
              font-size: 10px;
              font-weight: 700;
              line-height: 1;
              text-transform: uppercase;
              border-width: 1px;
              border-style: solid;
              border-color: ${stylevars.palette.primary.main};
              border-radius: 0.25rem;
              color: ${stylevars.palette.primary.main};
            }
          }
        }
      }
    }
  }

  .loadmore {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.8);
    padding: 0.5rem 1rem;
    text-align: center;
    z-index: 4;
    position: fixed;
    bottom: 0;
    left: 0;
    span {
      color: #fff;
      line-height: 0;
      margin-right: 1rem;
    }
  }

  .endofpage {
    padding: 1rem 2rem;
    border-top: 1px solid var(--sfProfile_dividerColor);
    width: 100%;
    text-align: center;
    color: var(--sfProfile_profileTextColor);
  }

  .galleryGrid {
    margin-bottom: 2rem;
    .gallery-col {
      width: 100%;
      max-width: calc(20% - 0.5rem - 1px);
      min-height: 80px;
      margin: 0.25rem;

      @media ${stylevars.breakpoints.desktop} {
        max-width: calc(20% - 0.5rem - 1px);
      }
      @media ${stylevars.breakpoints.laptop} {
        max-width: calc(25% - 0.5rem - 1px);
      }
      @media ${stylevars.breakpoints.tablet} {
        max-width: calc(33.33% - 0.5rem - 1px);
      }
      @media ${stylevars.breakpoints.phone} {
        max-width: calc(100% - 0.5rem - 1px);
      }

      .gallery-item {
        margin: 0;
        padding: 0;
      }
    }
  }

  .userNotFound {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--vw, 1vw) * 100);
    height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
  }
`;

const StyledStoreUserContainer = ({ children, ...rest }) => {
  return <StoreUserContainer {...rest}>{children}</StoreUserContainer>;
};

export default React.memo(StyledStoreUserContainer);
