import React from "react";
import axios from "axios";
import { Link, useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import he from "he";
import cn from "classnames";
import { toast } from "react-toastify";
// import useKeypress from 'react-use-keypress'
// import { debounce } from 'lodash'
import InnerImageZoom from "react-inner-image-zoom";
import { consoleLogger, stripHTML } from "@Utils";
import shortNumber from "short-number";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import userSlice, { getUserState } from "@Slices/userSlice";
import gallerySlice, { getGalleryState } from "@Slices/gallerySlice"; // eslint-disable-line
import uiSlice from "@Slices/uiSlice";

// FORMIK
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";

// COMPONENTS
import {
  Header,
  Label,
  Divider,
  Segment,
  Icon,
  Grid,
  Table,
  Image,
  Form as SUIForm,
  TextArea,
  Accordion,
  Button,
  Modal,
  List,
  Dimmer,
  Loader,
  Placeholder,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import { DashToolbar, GeneralPreloader } from "@Common";
import { GalleryItemDelete } from "./Components";

// STYLES
import { EditGalleryContainer } from "./EditGalleryStyles";
import EditToolbar from "./Components/EditToolbar";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import RequestPrintButton from "./Components/RequestPrintButton";

// VALIDATION SCHEMA
Yup.addMethod(Yup.string, "nospecial", function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;

    value = originalValue.matches(/[^a-zA-Z0-9]/);

    consoleLogger(originalValue, value);
    return value ? value.toString() : "";
  });
});
const validationSchema = Yup.object().shape({
  post_title: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(255, "Must be shorter than 255 characters")
    .required("Must enter a title"),
  post_excerpt: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .max(255, "Must be shorter than 255 characters"),
  base_price: Yup.number()
    .min(5, "Price must not be less than 5")
    .required("Must enter a base price"),
  aspect_ratio: Yup.string().required("Must select an aspect ratio"),
  camera_sensor: Yup.string().required("Must select a camera sensor"),
  show_in_gallery: Yup.bool(),
  limited_edition: Yup.bool(),
  number_lots: Yup.number()
    .min(1, "Must be at least have 1 copy")
    .when("limited_edition", {
      is: true,
      then: Yup.number().required("Must provide number of copies"),
    }),
});

const EditGalleryPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo, authenticated, userGallery, userCollections } =
    useSelector(getUserState);
  const { gallerySizes, gallerySizeTypes } = useSelector(getGalleryState);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isReady, setIsReady] = React.useState(false);
  const [soldOut, setSoldOut] = React.useState(false);
  const [itemData, setItemData] = React.useState();
  const [itemIndex, setItemIndex] = React.useState();
  const [activeTypes, setActiveTypes] = React.useState(
    gallerySizeTypes
      .filter((i) => i.acf.active)
      .map((type) => ({ ...type, active: false }))
  );
  const { gallery_id } = useParams();
  const [isLimited, setIsLimited] = React.useState(false);

  const token = localStorage.Authorization;

  const aspectRatioOptions = [
    {
      key: "standard-2x3",
      value: "2x3",
      text: "Standard (2:3)",
    },
    {
      key: "graphics-4x3",
      value: "4x3",
      text: "Graphics (4:3)",
    },
    {
      key: "panorama-1x3",
      value: "1x3",
      text: "Panorama (1:3)",
    },
    {
      key: "square-1x1",
      value: "1x1",
      text: "Square (1:1)",
    },
  ];
  const cameraSensorOptions = [
    {
      key: "camera-sensor-apsh",
      value: "apsh",
      text: "APSH - Full Frame Format",
    },
    {
      key: "camera-sensor-apsc",
      value: "apsc",
      text: "APSC - Crop Sensor Format",
    },
    {
      key: "camera-sensor-mf",
      value: "mf",
      text: "MF - Medium Format Sensor",
    },
    {
      key: "camera-sensor-lf",
      value: "lf",
      text: "LF - Large Format Sensor",
    },
    {
      key: "camera-sensor-na",
      value: "na",
      text: "Not Applicable",
    },
  ];

  // React.useEffect(() => {
  // }, []) // eslint-disable-line

  React.useEffect(() => {
    if (activeTypes) {
      consoleLogger(
        "activeTypes =============",
        activeTypes.map((i) => ({ id: i.id, active: i.active }))
      );
    }
    if (itemData) {
      consoleLogger("itemData =============", itemData);
      dispatch(uiSlice.actions.setPageTemplate("dash"));
      dispatch(uiSlice.actions.setCurrentPage(`gallery`));
      dispatch(uiSlice.actions.setCurrentPageURL(`admin/gallery`));
      dispatch(
        uiSlice.actions.setCurrentPageTitle(`${he.decode(itemData.post_title)}`)
      );

      // update tag options
      consoleLogger(
        "xxxxxxxxxxxxxxxxxxxxxxxxxx",
        itemData.acf.tags.map((n) => n)
      );
      const tempTags = itemData.acf.tags.map((n) => ({
        key: n,
        text: n,
        value: n,
      }));
      setTagOptions((prev) => [...tempTags, ...prev]);
    }
  }, [activeTypes, itemData]); // eslint-disable-line

  React.useEffect(() => {
    (async () => {
      if (!authenticated) {
        const encodedPath = encodeURIComponent(
          `/admin/gallery/view/${gallery_id}`
        );
        history.push(`/login/redirect/${encodedPath}`);
      } else {
        if (
          userGallery
            .filter((i) => Number(i.id) === Number(gallery_id))
            .map((n) => n).length > 0
        ) {
          const itemData = userGallery
            .filter((i) => Number(i.id) === Number(gallery_id))
            .map((o) => o)[0];
          setItemData(itemData);
          setIsLimited(!!itemData.acf.limited_edition);
          let idx = 0;
          userGallery.forEach((item, index) => {
            if (Number(item.ID) === Number(gallery_id)) {
              idx = index + 1;
            }
          });
          setItemIndex(idx);
          if (itemData.acf.print_types) {
            const printTypes = itemData.acf.print_types;
            consoleLogger("printTypes ===========", printTypes);
            printTypes.forEach((printType) => {
              setActiveTypes((state) =>
                state.map((type) =>
                  Number(type.id) === Number(printType.id)
                    ? { ...type, active: printType.active }
                    : { ...type }
                )
              );
            });
          }

          // check if item is sold out
          if (itemData.acf.limited_edition) {
            if (itemData.acf.sold_copies >= itemData.acf.number_lots) {
              setSoldOut(true);
            }
          }

          consoleLogger("READY!!!!!!!!!!!!!!!!!!!!!");
          setIsReady(true);
          setIsLoading(false);
        } else {
          history.push(`/`);
        }
      }
    })();

    return () => {
      dispatch(uiSlice.actions.setCurrentPageTitle());
      setActiveTypes(
        gallerySizeTypes.map((type) => ({ ...type, active: false }))
      );
      setItemData();
    };
    // eslint-disable-next-line
  }, [
    userInfo,
    gallerySizes,
    gallerySizeTypes,
    authenticated,
    history,
    gallery_id,
  ]); // eslint-disable-line

  const handleTypeToggle = (e, callback) => {
    const typeid = e.currentTarget.getAttribute("typeid");
    consoleLogger("typeid ================", typeid);
    setActiveTypes((state) =>
      state.map((type) =>
        Number(type.id) === Number(typeid)
          ? { ...type, active: !type.active }
          : { ...type }
      )
    );
    callback();
  };

  // const handlePrevGalleryItem = () => {
  //   userGallery.forEach((item, index) => {
  //     const tIndex = (index += 1);
  //     if (item.ID.toString() === gallery_id.toString()) {
  //       if (index !== 0) {
  //         const prevIndex = tIndex - 2;
  //         // setIsReady(false)
  //         setIsLoading(true);
  //         history.push(`./${userGallery[prevIndex].ID}`);
  //       }
  //     }
  //   });
  // };
  // const handleNextGalleryItem = () => {
  //   userGallery.forEach((item, index) => {
  //     if (item.ID.toString() === gallery_id.toString()) {
  //       if (index !== userGallery.length) {
  //         const nextIndex = index + 1;
  //         // setIsReady(false)
  //         setIsLoading(true);
  //         history.push(`./${userGallery[nextIndex].ID}`);
  //       }
  //     }
  //   });
  // };

  const handleClose = () => {
    history.push(`/admin/gallery`);
  };

  // useKeypress(['Control', 'ArrowLeft', 'ArrowRight'], debounce((event) => {
  //   consoleLogger('event.key', event.key)
  //   if (isReady && !isLoading) {
  //     if (event.key === 'Control' && event.key === 'ArrowLeft') {
  //       if (itemIndex > 1) {
  //         handlePrevGalleryItem()
  //       }
  //     } else if (event.key === 'ArrowRight') {
  //       if (itemIndex !== (userGallery.length)) {
  //         handleNextGalleryItem()
  //       }
  //     }
  //   }
  // }, 500))

  const baseTagOptions = [
    { key: "Aerial", text: "Aerial", value: "Aerial" },
    { key: "Adventure", text: "Adventure", value: "Adventure" },
    { key: "Animal", text: "Animal", value: "Animal" },
    { key: "Architectural", text: "Architectural", value: "Architectural" },
    {
      key: "Astrophotography",
      text: "Astrophotography",
      value: "Astrophotography",
    },
    { key: "Automotive", text: "Automotive", value: "Automotive" },
    {
      key: "Black and White",
      text: "Black and White",
      value: "Black and White",
    },
    { key: "Business", text: "Business", value: "Business" },
    { key: "Candid", text: "Candid", value: "Candid" },
    { key: "Cityscape", text: "Cityscape", value: "Cityscape" },
    { key: "Commercial", text: "Commercial", value: "Commercial" },
    { key: "Composite", text: "Composite", value: "Composite" },
    { key: "Documentary", text: "Documentary", value: "Documentary" },
    { key: "Drone", text: "Drone", value: "Drone" },
    {
      key: "Double-Exposure",
      text: "Double-Exposure",
      value: "Double-Exposure",
    },
    { key: "Editorial", text: "Editorial", value: "Editorial" },
    { key: "Event", text: "Event", value: "Event" },
    { key: "Experimental", text: "Experimental", value: "Experimental" },
    { key: "Family", text: "Family", value: "Family" },
    { key: "Fashion", text: "Fashion", value: "Fashion" },
    { key: "Film", text: "Film", value: "Film" },
    { key: "Fine Art", text: "Fine Art", value: "Fine Art" },
    { key: "Food", text: "Food", value: "Food" },
    { key: "Golden Hour", text: "Golden Hour", value: "Golden Hour" },
    { key: "Graphics", text: "Graphics", value: "Graphics" },
    { key: "Holiday", text: "Holiday", value: "Holiday" },
    { key: "Indoor", text: "Indoor", value: "Indoor" },
    { key: "Infrared", text: "Infrared", value: "Infrared" },
    { key: "Landscape", text: "Landscape", value: "Landscape" },
    { key: "Lifestyle", text: "Lifestyle", value: "Lifestyle" },
    { key: "Long Exposure", text: "Long Exposure", value: "Long Exposure" },
    { key: "Macro", text: "Macro", value: "Macro" },
    { key: "Minimalist", text: "Minimalist", value: "Minimalist" },
    { key: "Night", text: "Night", value: "Night" },
    { key: "Pet", text: "Pet", value: "Pet" },
    {
      key: "Photojournalism",
      text: "Photojournalism",
      value: "Photojournalism",
    },
    { key: "Portrait", text: "Portrait", value: "Portrait" },
    { key: "Product", text: "Product", value: "Product" },
    { key: "Real Estate", text: "Real Estate", value: "Real Estate" },
    { key: "Seascape", text: "Seascape", value: "Seascape" },
    { key: "Social Media", text: "Social Media", value: "Social Media" },
    { key: "Sports", text: "Sports", value: "Sports" },
    { key: "Still-Life", text: "Still-Life", value: "Still-Life" },
    { key: "Surreal", text: "Surreal", value: "Surreal" },
    { key: "Street", text: "Street", value: "Street" },
    { key: "Time-Lapse", text: "Time-Lapse", value: "Time-Lapse" },
    { key: "Travel", text: "Travel", value: "Travel" },
    { key: "Underwater", text: "Underwater", value: "Underwater" },
    {
      key: "Urban Exploration",
      text: "Urban Exploration",
      value: "Urban Exploration",
    },
    { key: "War", text: "War", value: "War" },
    { key: "Wedding", text: "Wedding", value: "Wedding" },
    { key: "Wildlife", text: "Wildlife", value: "Wildlife" },
  ];

  const [tagOptions, setTagOptions] = React.useState(baseTagOptions);
  const [currentTagValues, setCurrentTagValues] = React.useState();
  const handleTagAddition = (e, { value }) => {
    setTagOptions((prevState) => [
      { key: value, text: value, value: value },
      ...prevState,
    ]);
  };

  const handleTagChange = (e, { value }) => {
    consoleLogger("+++ TAG Values Change");
    consoleLogger(value);
    setCurrentTagValues(value);
  };

  React.useEffect(() => {
    if (tagOptions) {
      consoleLogger("ccccccccccccccccccccccccc", tagOptions);
    }
  }, [tagOptions]); // eslint-disable-line

  return (
    <EditGalleryContainer>
      <div>
        <DashToolbar
          title={!isReady ? `...` : itemData.post_title}
          showBack
          actions={[
            {
              type: "component",
              render: <RequestPrintButton post={itemData} />,
            },
          ]}
        />
        {isReady ? (
          <div className="mainSegment">
            <Formik
              enableReinitialize={true}
              initialValues={{
                post_title: itemData.post_title,
                post_excerpt: itemData.post_excerpt,
                tags: itemData.acf.tags,
                limited_edition: itemData.acf.limited_edition,
                auto_unlist: itemData.acf.auto_unlist,
                quantity: itemData.acf.quantity,
                base_price: itemData.acf.base_price,
                formated_base_price: itemData.acf.base_price,
                aspect_ratio: itemData.acf.aspect_ratio,
                camera_sensor: itemData.acf.camera_sensor,
                // print_types: activeTypes,
                statusCheck: itemData.post_status === "publish" ? true : false,
                show_in_gallery: itemData.acf.show_in_gallery,
                number_lots: itemData.acf.number_lots
                  ? itemData.acf.number_lots
                  : 1,
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setErrors, setSubmitting }) => {
                (async () => {
                  data.post_status = data.statusCheck ? "publish" : "pending";
                  data.print_types = activeTypes;
                  setSubmitting(true);
                  consoleLogger("data ===================", data);
                  await axios({
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    url: `/stylefolio/v1/gallery/${gallery_id}`,
                    headers: {
                      Authorization: `${token}`,
                    },
                    method: "post",
                    data,
                  })
                    .then((res) => {
                      consoleLogger("GALLERY RESPONSE ==========", res.data);
                      // toast message
                      toast.success(
                        `Gallery item updated - ${he.decode(
                          res.data.post_title
                        )}`,
                        {
                          containerId: "mainToaster",
                          progress: undefined,
                        }
                      );
                      // dispatch(uiSlice.actions.setUIToast({
                      //   visible: true,
                      //   type: 'positive',
                      //   icon: 'info circle',
                      //   title: `Updated - ${he.decode(res.data.post_title)}`,
                      //   message: `You have successfully updated ${he.decode(res.data.post_title)}`
                      // }))

                      setItemData(res.data);
                      dispatch(uiSlice.actions.setCurrentPage(`gallery`));
                      dispatch(
                        uiSlice.actions.setCurrentPageTitle(
                          `${he.decode(res.data.post_title)}`
                        )
                      );
                      dispatch(userSlice.actions.updateUserGallery(res.data));
                    })
                    .catch((err) => {
                      consoleLogger(err);
                      setErrors(err.response.data);
                    });
                  setSubmitting(false);
                })();
              }}
            >
              {({
                isSubmitting,
                handleBlur,
                handleChange,
                handleReset,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <Form className="ui form">
                  <Grid>
                    <Grid.Column
                      mobile={16}
                      computer={16}
                      className="listImageColumn"
                    >
                      {isLoading && (
                        <Dimmer active inverted>
                          <Loader size="large" inverted>
                            Loading...
                          </Loader>
                        </Dimmer>
                      )}
                      <InnerImageZoom
                        src={itemData.media.medium}
                        zoomSrc={itemData.media.hd}
                        hideCloseButton
                      />
                      <div className="extra">
                        {soldOut && (
                          <div className="extra-item">
                            <Label color="red">
                              <Icon name="bullseye" />
                              <span>Sold Out</span>
                            </Label>
                          </div>
                        )}
                        <div className="extra-item">
                          <Label title={`Viewed ${itemData.acf.pageviews}`}>
                            <Icon name="eye" />
                            <span>{shortNumber(itemData.acf.pageviews)}</span>
                          </Label>
                        </div>
                        <div className="extra-item">
                          <Label title={`Likes ${itemData.acf.likes.length}`}>
                            <Icon name="like" />
                            <span>
                              {shortNumber(itemData.acf.likes.length)}
                            </span>
                          </Label>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      computer={8}
                      className="listContentColumn"
                    >
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          computer={5}
                          className="listContentColumn"
                        >
                          <div className="productDetail">
                            <div className="productDetailHeader">
                              <h3>Product Details</h3>
                              <div className="publishToggle">
                                <span>Published</span>
                                <div className="ui fitted toggle checkbox">
                                  <Field
                                    type="checkbox"
                                    name="statusCheck"
                                    disabled={isSubmitting}
                                  />
                                  <label></label>
                                </div>
                              </div>
                            </div>
                            <Segment attached>
                              {isLoading && (
                                <Dimmer active inverted>
                                  <Loader size="large" inverted>
                                    Loading...
                                  </Loader>
                                </Dimmer>
                              )}
                              <>
                                <Grid
                                  verticalAlign="top"
                                  className="productDetailGrid"
                                >
                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn adjust"
                                    >
                                      Title *
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={16}
                                      table={10}
                                      computer={12}
                                    >
                                      <>
                                        <SUIForm.Field
                                          type="text"
                                          error={errors.i}
                                          disabled={isSubmitting}
                                        >
                                          {isLoading ? (
                                            <input type="text" value="" />
                                          ) : (
                                            <SUIForm.Input
                                              fluid
                                              type="text"
                                              name="post_title"
                                              placeholder="Title of your photo"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              defaultValue={itemData.post_title}
                                            />
                                          )}
                                        </SUIForm.Field>
                                      </>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn adjust"
                                    >
                                      Description
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={16}
                                      table={10}
                                      computer={12}
                                    >
                                      <>
                                        <SUIForm.Field
                                          type="text"
                                          error={errors.post_excerpt}
                                          disabled={isSubmitting}
                                        >
                                          {isLoading ? (
                                            <textarea rows={5} value="" />
                                          ) : (
                                            <TextArea
                                              name="post_excerpt"
                                              rows={5}
                                              placeholder="Tell us more about your product"
                                              defaultValue={
                                                itemData.post_excerpt
                                              }
                                              disabled={isSubmitting}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                          )}
                                          {errors.post_excerpt && (
                                            <Label pointing="above" prompt>
                                              {errors.post_excerpt}
                                            </Label>
                                          )}
                                        </SUIForm.Field>
                                      </>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn adjust"
                                    >
                                      Tags
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={16}
                                      table={10}
                                      computer={12}
                                    >
                                      <input
                                        type="hidden"
                                        name="tags"
                                        defaultValue={itemData.acf.tags}
                                      />
                                      <Dropdown
                                        options={tagOptions}
                                        placeholder="Choose Tags"
                                        search
                                        selection
                                        fluid
                                        multiple
                                        allowAdditions
                                        value={currentTagValues}
                                        defaultValue={itemData.acf.tags}
                                        onAddItem={handleTagAddition}
                                        onChange={(ev, { value }) => {
                                          handleTagChange(ev, { value });
                                          setFieldValue("tags", value);
                                        }}
                                        renderLabel={(label) => ({
                                          content: `${label.text}`,
                                          color: "blue",
                                        })}
                                      />
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn adjust"
                                    >
                                      Aspect Ratio *
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={16}
                                      table={10}
                                      computer={6}
                                    >
                                      <>
                                        <inpu
                                          type="hidden"
                                          name="aspect_ratio"
                                          defaultValue={
                                            itemData.acf.aspect_ratio
                                          }
                                        />
                                        {isLoading ? (
                                          <>
                                            <input type="text" value="" />
                                          </>
                                        ) : (
                                          <SUIForm.Select
                                            fluid
                                            name="aspect_ratio_select"
                                            placeholder="Select Aspect Ratio"
                                            onChange={(ev, { name, value }) => {
                                              handleChange(ev);
                                              setFieldValue(
                                                "aspect_ratio",
                                                value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            defaultValue={
                                              itemData.acf.aspect_ratio
                                            }
                                            options={aspectRatioOptions}
                                            disabled={isSubmitting}
                                            error={errors.aspect_ratio}
                                          />
                                        )}
                                      </>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn adjust"
                                    >
                                      Camera Sensor *
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={16}
                                      table={10}
                                      computer={6}
                                    >
                                      <>
                                        <inpu
                                          type="hidden"
                                          name="camera_sensor"
                                          defaultValue={
                                            itemData.acf.camera_sensor
                                          }
                                        />
                                        {isLoading ? (
                                          <>
                                            <input type="text" value="" />
                                          </>
                                        ) : (
                                          <SUIForm.Select
                                            fluid
                                            name="camera_sensor_select"
                                            placeholder="Select Camera Sensor"
                                            onChange={(ev, { name, value }) => {
                                              handleChange(ev);
                                              setFieldValue(
                                                "camera_sensor",
                                                value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            defaultValue={
                                              itemData.acf.camera_sensor
                                            }
                                            options={cameraSensorOptions}
                                            disabled={isSubmitting}
                                            error={errors.camera_sensor}
                                          />
                                        )}
                                      </>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={8}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn"
                                    >
                                      <span>Attach to gallery?</span>
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={8}
                                      tablet={10}
                                      computer={4}
                                      textAlign="left"
                                      className="formLabelColumn"
                                    >
                                      {!isLoading && (
                                        <div className="ui fitted toggle checkbox">
                                          <Field
                                            type="checkbox"
                                            name="show_in_gallery"
                                            disabled={isSubmitting}
                                            onChange={(ev) => {
                                              consoleLogger(
                                                ev.currentTarget.checked
                                              );
                                              setFieldValue(
                                                "show_in_gallery",
                                                ev.currentTarget.checked
                                              );
                                            }}
                                          />
                                          <label></label>
                                        </div>
                                      )}
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={8}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn adjust"
                                    >
                                      Base Price *
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={8}
                                      tablet={10}
                                      computer={4}
                                    >
                                      <>
                                        <input
                                          type="hidden"
                                          name={`base_price`}
                                          value={itemData.acf.base_price}
                                        />
                                        <SUIForm.Field
                                          type="text"
                                          error={errors.base_price}
                                          disabled={isSubmitting}
                                        >
                                          {isLoading ? (
                                            <input type="text" value="" />
                                          ) : (
                                            <SUIForm.Input
                                              as={NumberFormat}
                                              icon="dollar sign"
                                              iconPosition="left"
                                              name={`formated_base_price`}
                                              decimalScale={2}
                                              fixedDecimalScale
                                              thousandSeparator={true}
                                              prefix="$"
                                              defaultValue={
                                                itemData.acf.base_price
                                                  ? itemData.acf.base_price
                                                  : ""
                                              }
                                              isNumericString={true}
                                              disabled={isSubmitting}
                                              onValueChange={(ev) => {
                                                consoleLogger(ev.floatValue);
                                                setFieldValue(
                                                  `base_price`,
                                                  ev.floatValue
                                                );
                                              }}
                                              onChange={(ev) =>
                                                handleChange(ev)
                                              }
                                            />
                                          )}
                                          {errors.base_price && (
                                            <Label pointing="above" prompt>
                                              {errors.base_price}
                                            </Label>
                                          )}
                                        </SUIForm.Field>
                                      </>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={8}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn"
                                    >
                                      <span>Copies Sold</span>
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={8}
                                      tablet={10}
                                      computer={4}
                                      textAlign="left"
                                      className="formLabelColumn"
                                    >
                                      <NumberFormat
                                        className="displayValue"
                                        value={itemData.acf.sold_copies}
                                        decimalScale={0}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </>
                            </Segment>
                          </div>
                        </Grid.Column>

                        <Grid.Column
                          mobile={16}
                          computer={5}
                          className={cn("listContentColumn proFeatures", {
                            disabled:
                              userInfo.acf.subscription_type !== "premium",
                          })}
                        >
                          <div className="productDetail">
                            <div className="productDetailHeader">
                              <h3>PRO Features</h3>
                            </div>
                            <Segment attached>
                              {isLoading && (
                                <Dimmer active inverted>
                                  <Loader size="large" inverted>
                                    Loading...
                                  </Loader>
                                </Dimmer>
                              )}
                              <>
                                <Grid>
                                  <Grid.Row className="formGridRow">
                                    <Grid.Column
                                      mobile={8}
                                      tablet={6}
                                      computer={4}
                                      className="formLabelColumn"
                                    >
                                      <span>Limited Edition</span>
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={8}
                                      tablet={10}
                                      computer={12}
                                      textAlign="left"
                                      className="formLabelColumn"
                                    >
                                      {!isLoading && (
                                        <div className="ui fitted toggle checkbox">
                                          <Field
                                            type="checkbox"
                                            name="limited_edition"
                                            onChange={(ev) => {
                                              consoleLogger(
                                                ev.currentTarget.checked
                                              );
                                              setFieldValue(
                                                "limited_edition",
                                                ev.currentTarget.checked
                                              );
                                              setIsLimited(
                                                ev.currentTarget.checked
                                              );
                                            }}
                                            disabled={
                                              isSubmitting ||
                                              userInfo.acf.subscription_type !==
                                                "premium"
                                            }
                                          />
                                          <label></label>
                                        </div>
                                      )}
                                    </Grid.Column>
                                  </Grid.Row>
                                  {isLimited && (
                                    <>
                                      <Grid.Row className="formGridRow">
                                        <Grid.Column
                                          mobile={8}
                                          tablet={6}
                                          computer={4}
                                          className="formLabelColumn adjust"
                                        >
                                          Number of Edition *{" "}
                                          <Popup
                                            content="Once you have set the number of editions, please refrain from changing the value."
                                            trigger={
                                              <Icon name="question circle" />
                                            }
                                          />
                                        </Grid.Column>
                                        <Grid.Column
                                          mobile={8}
                                          widthtablet={10}
                                          computer={4}
                                        >
                                          <>
                                            <input
                                              type="hidden"
                                              name={`number_lots`}
                                              value={
                                                itemData.acf.number_lots
                                                  ? itemData.acf.number_lots
                                                  : 1
                                              }
                                            />
                                            <SUIForm.Field
                                              type="text"
                                              error={errors.number_lots}
                                              disabled={isSubmitting}
                                            >
                                              <SUIForm.Input
                                                as={NumberFormat}
                                                icon="dollar sign"
                                                iconPosition="left"
                                                name={`formated_number_lots`}
                                                decimalScale={0}
                                                fixedDecimalScale={false}
                                                thousandSeparator={true}
                                                defaultValue={
                                                  itemData.acf.number_lots
                                                    ? itemData.acf.number_lots
                                                    : 1
                                                }
                                                isNumericString={true}
                                                disabled={isSubmitting}
                                                onValueChange={(ev) => {
                                                  consoleLogger(ev.floatValue);
                                                  setFieldValue(
                                                    `number_lots`,
                                                    ev.floatValue
                                                  );
                                                }}
                                                onBlur={(ev) => handleBlur(ev)}
                                                onChange={(ev) =>
                                                  handleChange(ev)
                                                }
                                              />
                                              {errors.number_lots && (
                                                <Label pointing="above" prompt>
                                                  {errors.number_lots}
                                                </Label>
                                              )}
                                            </SUIForm.Field>
                                          </>
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row className="formGridRow">
                                        <Grid.Column
                                          mobile={8}
                                          tablet={6}
                                          computer={4}
                                          className="formLabelColumn adjust"
                                        >
                                          Copies Available
                                        </Grid.Column>
                                        <Grid.Column
                                          mobile={8}
                                          widthtablet={10}
                                          computer={4}
                                        >
                                          <NumberFormat
                                            className="displayValue"
                                            value={
                                              itemData.acf.number_lots -
                                              itemData.acf.sold_copies
                                            }
                                            decimalScale={0}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                    </>
                                  )}
                                </Grid>
                              </>
                            </Segment>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid.Column>

                    <Grid.Column
                      mobile={16}
                      computer={8}
                      className="listContentColumn"
                    >
                      <div className="productDetail">
                        <div className="productDetailHeader">
                          <h3>Set Print Types</h3>
                        </div>
                        <Segment attached className="printTypeListBox">
                          {isLoading && (
                            <Dimmer active inverted>
                              <Loader size="large" inverted>
                                Loading...
                              </Loader>
                            </Dimmer>
                          )}
                          <FieldArray name="printTypesArray">
                            {({ insert, remove, push }) => (
                              <List divided className="printTypeList">
                                {/* {!isLoading && isReady && */}
                                <>
                                  {activeTypes &&
                                    activeTypes.length > 0 &&
                                    activeTypes.map((type, index) => (
                                      <List.Item key={type.slug}>
                                        <List.Content className="image">
                                          <Image
                                            size="tiny"
                                            src={type.acf.image}
                                          />
                                        </List.Content>
                                        <List.Content className="detail">
                                          <List.Header content={type.name} />
                                          <div className="meta">
                                            <span className="price">
                                              Charged Base Fee:{" "}
                                              <NumberFormat
                                                value={type.acf.fee}
                                                decimalScale={2}
                                                fixedDecimalScale
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                              />
                                            </span>
                                          </div>
                                          <List.Description
                                            content={type.description}
                                          />
                                        </List.Content>
                                        <List.Content className="action">
                                          <div className="ui fitted toggle checkbox">
                                            <input
                                              type="checkbox"
                                              name={`printTypesArray.${index}.active`}
                                              disabled={isSubmitting}
                                              typeid={type.id}
                                              checked={
                                                type.active ? true : false
                                              }
                                              onChange={(ev) => {
                                                consoleLogger(
                                                  "ev ========",
                                                  ev.currentTarget.checked
                                                );
                                                // setFieldValue(`printTypesArray.${index}.active`, ev.currentTarget.checked)
                                                // setFieldValue('print_types', activeTypes)
                                                handleChange(ev);
                                                handleTypeToggle(ev, () => {});
                                              }}
                                            />
                                            <label></label>
                                          </div>
                                        </List.Content>
                                      </List.Item>
                                    ))}
                                </>
                                {/* } */}
                              </List>
                            )}
                          </FieldArray>
                        </Segment>
                      </div>
                      <div className="productDetail">
                        <div className="productDetailHeader">
                          <h3>Hi-Resolution Storage Shared Link</h3>
                        </div>
                        <Segment attached className="printTypeListBox">
                          <div className="hiresStorage">
                            <>
                              <SUIForm.Field
                                type="textarea"
                                error={errors.post_excerpt}
                                disabled={isSubmitting}
                              >
                                {isLoading ? (
                                  <textarea rows={5} value="" />
                                ) : (
                                  <TextArea
                                    name="hires_storage_link"
                                    rows={3}
                                    placeholder='The "Shared Link" of your artwork. (i.e https://www.dropbox.com/s/f6yx3r552i91cf0/mnk_synthwave2.png?dl=0)'
                                    defaultValue={
                                      itemData.acf.hires_storage_link
                                    }
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                )}
                                {errors.post_excerpt && (
                                  <Label pointing="above" prompt>
                                    {errors.post_excerpt}
                                  </Label>
                                )}
                              </SUIForm.Field>
                            </>
                            <p className="hiresStorageHelpText">
                              <strong>NOTE:</strong> Please take time to{" "}
                              <strong>upload the hi-resolution copy</strong> of
                              your masterpiece to{" "}
                              <strong>any file storage platform</strong> (
                              <span className="text--italic text--outlined">
                                Google Drive
                              </span>
                              ,{" "}
                              <span className="text--italic text--outlined">
                                Dropbox
                              </span>
                              ,{" "}
                              <span className="text--italic text--outlined">
                                Microsoft OneDrive
                              </span>
                              ,{" "}
                              <span className="text--italic text--outlined">
                                Apple iCloud Drive
                              </span>
                              , etc.) as we will be needing it to print high
                              quality copies of your artwork once it is sold.
                            </p>
                            <p className="hiresStorageHelpText">
                              Once you've done so, you can{" "}
                              <strong>copy-and-paste the Shared Link</strong> on
                              to the text box provided above.
                            </p>
                          </div>
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={16} className="actions">
                      <div className="actionsGroup">
                        {/* <div className='productActionNav'>
                            <div className='productActionNavText'>{itemIndex}<span> / {userGallery.length}</span></div>
                            <div className='productActionNavGroup'>
                              <Button basic circular size='tiny' type='button' icon='chevron left' onClick={handlePrevGalleryItem}
                                disabled={itemIndex === 1 || isLoading}
                              />
                              <Button basic circular size='tiny' type='button' icon='chevron right' onClick={handleNextGalleryItem}
                                disabled={itemIndex === userGallery.length || isLoading}
                              />
                            </div>
                          </div> */}
                        <div className="actionButtons">
                          {dirty && isValid && (
                            <Button
                              icon="arrow left"
                              content={<span>Cancel</span>}
                              labelPosition="left"
                              basic
                              onClick={handleClose}
                            />
                          )}
                          <GalleryItemDelete
                            itemData={itemData}
                            formState={{ isSubmitting, isLoading }}
                          />
                          {dirty && isValid ? (
                            <>
                              <Button
                                type="submit"
                                positive
                                onClick={() => handleSubmit()}
                                loading={isSubmitting}
                                disabled={
                                  !(dirty && isValid && !isSubmitting) ||
                                  isLoading
                                }
                                icon="save"
                                content={<span>Update</span>}
                                labelPosition="right"
                              />
                            </>
                          ) : (
                            <Button
                              icon="close"
                              content={<span>Close</span>}
                              labelPosition="right"
                              color="blue"
                              onClick={handleClose}
                            />
                          )}
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <GeneralPreloader />
        )}
      </div>
    </EditGalleryContainer>
  );
};

export default React.memo(EditGalleryPage);
