import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { Card } from 'semantic-ui-react'

export const StyledContainer = styled.div`
  background-color: #f3f3f3;
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${stylevars.navBar.height});
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/images/about-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  .container {
    padding: 4rem;
    background-color: rgba(255,255,255,0.75);
    width: 100%;
    max-width: 1200px;
    margin-bottom: 4rem;
    border-radius: 1rem;
  }
`