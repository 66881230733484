import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { consoleLogger } from '@Utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// COMPONENTS
import SFAvatar from '@Common/Avatar'

// STYLES
import { Container } from './UserCardStyles'

const UserCard = (props) => {
  const history = useHistory()
  const {
    user,
    onClick
  } = props

  React.useEffect(() => {
    consoleLogger(user)
  }, []) // eslint-disable-line

  const handleClick = (ev) => {
    ev.preventDefault()
    if (onClick) {
      onClick()
    }
    history.push(`/@${user.username}`)
  }
  
  return (
    <>
      {user && user.ID &&
        <Container>
          <Link to={`/@${user.username}`} className='link' onClick={handleClick}>
            <div className='title'>{user.name}</div>
            <SFAvatar className='avatar' userInfo={user} />
            <LazyLoadImage
              src={user.acf.background_image ? user.acf.background_image : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`}
              wrapperClassName='image'
              alt={`${user.name} cover`}
              effect="blur"
            />
          </Link>
        </Container>
      }
    </>
  )
}

export default React.memo(UserCard)
