import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
// import cn from 'classnames'
import he from 'he'
import { Helmet } from 'react-helmet'
import { titleCase } from 'title-case'
import { consoleLogger } from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import uiSlice, { getUIState } from '@Slices/uiSlice'
import vendorSlice, { getVendorState } from '@Slices/vendorSlice'

// COMPONENTS
import { MuuriComponent } from 'muuri-react';
import Masonry from 'react-masonry-css'
import {
  // Grid,
  Dimmer,
  Loader,
  Button,
  // Icon,
  // Placeholder,
  // Item,
  // Header,
} from 'semantic-ui-react'
import Viewer from './Viewer'
import GalleryItem from './GalleryItem'
import NumberFormat from 'react-number-format'

const Gallery = (props) => {
  const { noHelmet } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { vendorInfo, vendorProducts } = useSelector(getVendorState)
  const { showViewer, viewerData , mountViewer } = useSelector(getUIState)

  // const token = localStorage.Authorization // eslint-disable-line
  const { username, page, postid } = useParams() // eslint-disable-line
  const perPage = 24
  const [ isFetching, setIsFetching ] = React.useState(false)
  const [ totalPages, setTotalPages ] = React.useState(1)
  const [ pageIndex, setPageIndex ] = React.useState(1)
  const [ loadPage, setLoadPage ] = React.useState(true)

  // MASONRY BREAKPOINTS
  const breakpointDivider = Number(vendorInfo.acf.layout_style) === 3 ? 1.75 : 1
  const breakpointColumnsObj = {
    default: Math.ceil(6 / breakpointDivider),
    1280: Math.ceil(5 / breakpointDivider),
    1100: Math.ceil(4 / breakpointDivider),
    800: Math.ceil(3 / breakpointDivider),
    700: Math.ceil(2 / breakpointDivider),
    500: 1
  }

  const loadItems = (index, uid) => {
    const pIndex = index += 1
    setIsFetching(true)
    axios({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/stylefolio/v1/gallery`,
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
      method: 'GET',
      params: {
        // per_page: perPage,
        // page: pIndex,
        author: uid,
        // orderby: 'date_modified_gmt',
        // order: 'desc',
        status: ['publish']
      }

      // baseURL: `${process.env.REACT_APP_API_URL}/wp/v2/sf-gallery?_embed`,
      // method: 'get',
      // params: {
      //   page: pIndex,
      //   per_page: perPage,
      //   author: uid,
      //   orderby: 'date',
      //   order: 'desc',
      //   status: 'publish',
      //   categories: [250]
      // }
    })
      .then((res) => {
        consoleLogger("LOAD ITEMS RESPONSE =============", res)
        setPageIndex(i => i === totalPages ? totalPages : i += 1)
        setIsFetching(false)
        // dispatch(vendorSlice.actions.addVendorProducts(res.data))
        dispatch(vendorSlice.actions.setVendorProducts(res.data))
      })
      .catch((err) => {
        consoleLogger(err)
        setIsFetching(false)
      })
  }

  return (
    <>
      <div className='galleryContainer'>
        { vendorInfo && vendorProducts &&
          <>
            <MuuriComponent
              forceSync={true}
              instantLayout={true}
              gridProps={{className: 'galleryGrid'}}
              layout={{
                fillGaps: true,
                horizontal: false,
                alignRight: false,
                alignBottom: false,
                rounding: true
              }}
              layoutOnResize={100}
              layoutDuration={300}
            >
              {vendorProducts && vendorProducts.length > 0 && vendorProducts.filter(i => i.acf.show_in_gallery).map(item => (
                <GalleryItem 
                  key={`${item.ID}-${item.slug}`}
                  item={item}
                  username={username}
                  mode={noHelmet ? 'preview' : 'gallery'}
                />
              ))}
            </MuuriComponent>
          </>
        }
      </div>
      {(vendorProducts && vendorProducts.length > 0) &&
        <div className='endofpage'>
          {pageIndex !== totalPages ? (
            <>
              <Button
                primary
                onClick={() => loadItems(pageIndex, vendorInfo.id)}
                disabled={isFetching}
                loading={isFetching}
              >
                Load More ({pageIndex} of {totalPages})
              </Button>
            </>
          ) : (
            <>
              {`End of gallery - Total of `}
              <NumberFormat decimalScale={0} value={vendorProducts.filter(i => i.acf.show_in_gallery).length} displayType={'text'} thousandSeparator={true} />
              {` items`}
            </>
          )}
        </div>
      }
      {mountViewer && vendorProducts && vendorProducts.length > 0 && page === 'gallery' ?
        <Viewer /> :
        <>
          {!noHelmet &&
            <Helmet>
              <meta charSet="utf-8" />
              <title>{vendorInfo.name} - {titleCase(page ? page : 'Gallery')}</title>
              <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/@${username}/${page}`} />
            </Helmet>
          }
        </>
      }
    </>
  )
}

export default React.memo(Gallery)
