import React from 'react'

import { StyledGeneralPreloader } from './GeneralPreloaderStyles'

const GeneralPreloader = (props) => {
  const { title, children } = props
  return (
    <StyledGeneralPreloader>
      <img className='logo' src='/logo_light.svg' alt='logo' />
      {title &&
        <div className='title'>{title}</div>
      }
      {children &&
        <div className='content'>
          {children}
        </div>
      }
    </StyledGeneralPreloader>
  )
}

export default React.memo(GeneralPreloader)
