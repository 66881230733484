import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'

// Redux Toolkit
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const location = useLocation()
  const currentPathname = encodeURIComponent(location.pathname)
  const { authenticated } = useSelector(getUserState)
  const encodedPath = encodeURIComponent(`${path}`)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticated) {
          return <Component {...props} />
        } else {
          return <Redirect to={{
              pathname: `/login/redirect/${currentPathname}`
            }}
            />
        }
      }}
    />
  )
}

export default React.memo(ProtectedRoute)
