import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const Container = styled('div')`

  .costs {
    padding: 1rem;
    background-color: rgba(0,0,0,0.05);
    border-radius: 0.25rem;
    .costs-item {
      display: flex;
      flex-wrap: nowrap;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }

      .label {
        width: 30%;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .value {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .shippingFee {
          width: 100%;
          display: block;
        }

        .currentAddress {
          width: auto;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          height: 2rem;
          margin-right: 0.5rem;

          .currentAddress_label {
            display: inline-block;
            margin-right: 0.5rem;
          }
          .currentAddress_value {
            display: inline-block;
            padding: 0.25rem 0.5rem;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,0.15);
            border-radius: 0.25rem;
          }
          .currentAddress_title {
            display: inline-block;
            font-weight: bold;
            margin-right: 0.25rem;
          }
          .currentAddress_address {
            display: inline-block;
          }
        }

        .mailingButton {
          width: auto;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          color: ${stylevars.palette.info.main};
          border: 1px solid ${stylevars.palette.info.main};
          padding: 0.25rem 0.5rem;
          font-size: 0.75rem;
          cursor: pointer;
          height: 2rem;
          border-radius: 0.25rem;
          line-height: 1;
        }
      }
    }
  }

  .quantity {
    margin-left: 0.5rem;
    color: rgba(0,0,0,0.5);
  }

  .currentAddress {
    color: rgba(0,0,0,0.5);
  }
`