import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
// import axios from 'axios'
// import cn from 'classnames'
import { consoleLogger } from '@Utils'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice' // eslint-disable-line
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import {
  Message,
  Tab,
} from 'semantic-ui-react'
import AccountSales from './AccountSales'
import AccountInformation from './AccountInformation'
// import AccountCustomerInformation from './AccountCustomerInformation'
import AccountSecurity from './AccountSecurity'
import AccountCouponsTableTemp from './AccountCouponsTableTemp'
import CertificateGenerator from './CertificateGenerator'
// import AccountBillingForm from './AccountBillingForm'
import { DashToolbar, GeneralPreloader } from '@Common'

// STYLES
import { StyledAccountContainer } from './AccountStyles'

const AccountPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tab, id } = useParams()
  const { authenticated, userInfo, userAddresses, accountCompletion } = useSelector(getUserState)
  const [isReady, setIsReady] = React.useState(false)

  const redirect = encodeURIComponent(`/admin/account`)

  const baseCreatorPanes = [
    {
      menuItem: 'Information',
      render: () => (
        <Tab.Pane>
          <AccountInformation />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Sales',
      render: () => (
        <Tab.Pane>
          <AccountSales />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Password',
      render: () => (
        <Tab.Pane>
          <AccountSecurity />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: 'Coupons',
    //   render: () => (
    //     <Tab.Pane>
    //       <AccountCouponsTableTemp />
    //     </Tab.Pane>
    //   ),
    // },
  ]
  const [ creatorPanes, setCreatorPanes ] = React.useState()

  const customerPanes = [
    {
      menuItem: 'Information',
      render: () => (
        <Tab.Pane>
          <AccountInformation />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Password',
      render: () => (
        <Tab.Pane>
          <AccountSecurity />
        </Tab.Pane>
      ),
    },
  ]

  const [activeIndex, setActiveIndex] = React.useState(0)

  const accountTabSlugs = userInfo ? userInfo.role === 'sf_creator' ? ['information', 'sales', 'password', 'certificate'] : ['information', 'password'] : ``
  
  React.useEffect(() => {
  
    dispatch(uiSlice.actions.setPageTemplate('dash'))
    dispatch(uiSlice.actions.setCurrentPage(`account`))
    dispatch(uiSlice.actions.setCurrentPageURL('admin/account'))
    dispatch(uiSlice.actions.setCurrentPageTitle(tab ? tab : 'information'))
    setIsReady(true)
    // set tab
    setActiveIndex(tab ? accountTabSlugs.indexOf(tab) : 0)

    setCreatorPanes(userInfo && userInfo.acf.subscription_type === 'premium' ?
      ([
        ...baseCreatorPanes,
        {
          menuItem: 'Certificate',
          render: () => (
            <Tab.Pane>
              <CertificateGenerator />
            </Tab.Pane>
          ),
        }
      ]) : 
      ([...baseCreatorPanes])
    )

    return(() => {})
  }, [history, authenticated, redirect, tab, userInfo]) // eslint-disable-line

  React.useEffect(() => {
    if (!authenticated) {
      history.push(`/login/redirect/${redirect}`)
    }
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (creatorPanes) {
      setIsReady(true)
    }
  }, [creatorPanes])

  const handleTabChange = (ev, data) => {
    // consoleLogger(data)
    // const accountTabSlugs = ['sales', 'billing', 'information', 'security']
    history.push(`/admin/account/${accountTabSlugs[data.activeIndex]}`)
  }

  return (
    <StyledAccountContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_PROJECT_NAME} - Account</title>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/admin/account`} />
      </Helmet>
      {isReady && authenticated ?
        <div>
          <DashToolbar title='Account' noGap className='noGap' />
          <div className='content'>
            {isReady ? 
              <Tab className='subPageMenu' menu={{ secondary: true, pointing: true }} panes={userInfo.role === 'sf_creator' ? creatorPanes : customerPanes} onTabChange={handleTabChange} activeIndex={activeIndex} /> : 
              <div>Loading...</div>
            }
          </div>
        </div> : 
        <GeneralPreloader />
      }
    </StyledAccountContainer>
  )
}

export default React.memo(AccountPage)
