import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'
import { rgba } from 'polished';

export const CollectionsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;

  @media ${stylevars.breakpoints.computer} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${stylevars.breakpoints.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${stylevars.breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${stylevars.breakpoints.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
  > .item {
    > .wrapper {
      .content {
        background-color: rgba(0,0,0,0);
        color: var(--sfProfile_profileTextColor);
        &:before {
          border: 1px solid var(--sfProfile_dividerColor);
        }
        .title {
          color: var(--sfProfile_titleTextColor);
          .label {
            border: 1px solid ${stylevars.palette.primary.main};
            color: ${stylevars.palette.primary.main};
          }
        }
        .description {
          color: var(--sfProfile_linkTextColor);
        }
        .info {
          opacity: 0.5;
          border-top: 1px solid var(--sfProfile_dividerColor);
          color: var(--sfProfile_titleTextColor);
        }
      }

      @media ${stylevars.breakpoints.phone} {
        border: 1px solid var(--sfProfile_dividerColor);
        .content {
          background-color: var(--sfProfile_bgColor) !important;
          > .wrapper {
            .title {
              color: ${props => props.themeType === 'dark' ? `var(--sfProfile_titleTextColor)` : `var(--sfProfile_profileTextColor)`};
            }
            .description {
              color: ${props => props.themeType === 'dark' ? `var(--sfProfile_titleTextColor)` : `var(--sfProfile_profileTextColor)`};
            }
            .info {
              color: ${props => props.themeType === 'dark' ? `var(--sfProfile_titleTextColor)` : `var(--sfProfile_profileTextColor)`};
              border-top: 1px solid var(--sfProfile_dividerColor);
            }
          }
        }
      }
    }

    &:hover {
      > .wrapper {
        .content {
          background-color: rgba(0,0,0,0.75);
          &:before {
            border: 1px solid var(--sfProfile_dividerColor);
          }
        }
      }
    }
  }
`