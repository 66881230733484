import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import NumberFormat from 'react-number-format'
import { takeRight, reverse } from 'lodash'
import useLocalStorage from 'react-use-localstorage'
import {
  consoleLogger,
  getTotalCartItems,
  getVendorSlug,
  getTypeName,
  getTypeSize,
  cleanPrice
} from '@Utils'

// REDUX
import { useSelector } from 'react-redux'
import { getUserState } from '@Slices/userSlice'
import { getUIState } from '@Slices/uiSlice'
import { getCartState } from '@Slices/cartSlice'
import { getGalleryState } from '@Slices/gallerySlice'

// COMPONENTS
import { Button, Divider, Dropdown, Header, Icon, Label, Menu } from 'semantic-ui-react'
import CurrencySelection from './CurrencySelection'

// STYLES
import { Container } from './ShoppingCartMenuStyles'

const ShoppingCartMenu = (props) => {
  const {
    cartData
  } = props
  const location = useLocation()
  const currentPathname = location.pathname
  const { authenticated, userInfo } = useSelector(getUserState)
  const { gallerySizeTypes, gallerySizes } = useSelector(getGalleryState)
  const { currency } = useSelector(getUIState)
  const { carts } = useSelector(getCartState) // eslint-disable-line

  const cartTrigger = (
    <span className={cn('trigger', {
      'active': currentPathname.indexOf('/cart') > -1
    })}>
      {/* <span className='triggerName'>Cart</span> */}
      {cartData && cartData.length > 0 &&
        <Label color='green' floating circular>
          {getTotalCartItems(cartData)}
        </Label>
      }
      <Icon name='shopping cart' />
    </span>
  )

  return (
    <Container>
      {/* {userInfo.role !== 'sf_creator' && */}
        <Menu.Item className='menuItemCart'>
          <Dropdown
            // open={cartOpen}
            // onHover={() => setCartOpen(true)}
            // onClick={() => setCartOpen(false)}
            className={cn('cartMenu', {
              'expand': cartData && cartData.length > 0
            })}
            trigger={cartTrigger}
            pointing='top right'
            icon={null}
            fluid
          >
            <Dropdown.Menu>
              {cartData && cartData.length > 0 ?
                <>
                  <Dropdown.Header icon='shopping cart'>
                    <h4>Recently added items</h4>
                  </Dropdown.Header>
                  {reverse(takeRight(cartData, 5)).map((item, index) => (
                    <Dropdown.Item key={`${index}-${item.id}-${item.username}`} as={Link} to={`/@${getVendorSlug(item.vendorId, carts)}/gallery/${item.id}`}>
                      <div className='cartMenuItem'>
                        <img className='image' src={item.media.thumbnail} alt={item.username} />
                        <span className='text'>
                          <h4>{item.title}</h4>
                          <p>{`${getTypeName(item.selectedType.id, gallerySizeTypes)} - ${getTypeSize(item.selectedSize.id, gallerySizes)}`}</p>
                          <small>Quantity: {item.quantity}</small>
                        </span>
                        <span className='ui label price'>
                          <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={cleanPrice(item.price * currency.value)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currency.symbol}
                          />
                        </span>
                        <span className='description'>
                        </span>
                      </div>
                    </Dropdown.Item>
                  ))}
                  {cartData && cartData.length > 5 &&
                    <Divider horizontal className='moreItems'>More Items...</Divider>
                  }
                  <Divider />
                  <Dropdown.Header>
                    <div className='cartMenuActions'>
                      <CurrencySelection />
                      <Button as={Link} positive icon='cart' content='View Cart' labelPosition='right' to='/cart' />
                      {/* <Button as={Link} positive icon='arrow right' content='Checkout' labelPosition='right' to='/checkout' /> */}
                    </div>
                  </Dropdown.Header>
                </> :
                <>
                  <Dropdown.Header>
                    <Header as='h3' className='emptyCart'>
                      <Icon name='shopping cart' />
                      <span>Cart is empty</span>
                    </Header>
                  </Dropdown.Header>
                  <Divider />
                  <Dropdown.Header>
                    <div className='cartMenuActions'>
                      <CurrencySelection />
                      <Button as={Link} positive icon='cart' content='View Cart' labelPosition='right' to='/cart' disabled />
                      {/* <Button as={Link} positive icon='arrow right' content='Checkout' labelPosition='right' to='/checkout' /> */}
                    </div>
                  </Dropdown.Header>
                </>
              }
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      {/* } */}
    </Container>
  )
}

export default React.memo(ShoppingCartMenu)
