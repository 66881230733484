export const sampleForexData = {
  "AED": 3.6731,
  "AFN": 92.931517,
  "ALL": 107.082638,
  "AMD": 475.989839,
  "ANG": 1.800267,
  "AOA": 596.901,
  "ARS": 100.3468,
  "AUD": 1.372655,
  "AWG": 1.80025,
  "AZN": 1.700805,
  "BAM": 1.722204,
  "BBD": 2,
  "BDT": 85.832668,
  "BGN": 1.7226,
  "BHD": 0.377059,
  "BIF": 1988.772651,
  "BMD": 1,
  "BND": 1.356638,
  "BOB": 6.887567,
  "BRL": 5.558099,
  "BSD": 1,
  "BTC": 0.000017767094,
  "BTN": 74.07461,
  "BWP": 11.574981,
  "BYN": 2.465449,
  "BZD": 2.013552,
  "CAD": 1.259224,
  "CDF": 1998.184721,
  "CHF": 0.926058,
  "CLF": 0.030176,
  "CLP": 832.66,
  "CNH": 6.383955,
  "CNY": 6.3819,
  "COP": 3914.234995,
  "CRC": 639.378712,
  "CUC": 1,
  "CUP": 25.75,
  "CVE": 97.5,
  "CZK": 22.263016,
  "DJF": 177.835637,
  "DKK": 6.54685,
  "DOP": 56.508746,
  "DZD": 138.83959,
  "EGP": 15.6952,
  "ERN": 15.000155,
  "ETB": 48.05546,
  "EUR": 0.880354,
  "FJD": 2.10045,
  "FKP": 0.74082,
  "GBP": 0.74082,
  "GEL": 3.125,
  "GGP": 0.74082,
  "GHS": 6.120961,
  "GIP": 0.74082,
  "GMD": 52.4,
  "GNF": 9542.624584,
  "GTQ": 7.728228,
  "GYD": 208.831011,
  "HKD": 7.790792,
  "HNL": 24.085447,
  "HRK": 6.6143,
  "HTG": 98.010432,
  "HUF": 320.327415,
  "IDR": 14230.386982,
  "ILS": 3.085219,
  "IMP": 0.74082,
  "INR": 74.252998,
  "IQD": 1457.436091,
  "IRR": 42250,
  "ISK": 130.83,
  "JEP": 0.74082,
  "JMD": 155.576693,
  "JOD": 0.709,
  "JPY": 114.30516667,
  "KES": 112.15,
  "KGS": 84.793251,
  "KHR": 4063.569252,
  "KMF": 433.000074,
  "KPW": 900,
  "KRW": 1184.779405,
  "KWD": 0.302431,
  "KYD": 0.832471,
  "KZT": 432.834013,
  "LAK": 10697.986026,
  "LBP": 1510.404523,
  "LKR": 201.783119,
  "LRD": 142.99999,
  "LSL": 15.555781,
  "LYD": 4.595322,
  "MAD": 9.189499,
  "MDL": 17.699209,
  "MGA": 3980.672634,
  "MKD": 54.255036,
  "MMK": 1767.59459,
  "MNT": 2859.434306,
  "MOP": 8.013878,
  "MRO": 356.999828,
  "MRU": 36.287733,
  "MUR": 42.920001,
  "MVR": 15.45,
  "MWK": 815.516147,
  "MXN": 20.741868,
  "MYR": 4.1815,
  "MZN": 63.841999,
  "NAD": 15.64,
  "NGN": 410.56,
  "NIO": 35.191404,
  "NOK": 8.800323,
  "NPR": 118.641525,
  "NZD": 1.420386,
  "OMR": 0.384967,
  "PAB": 1,
  "PEN": 4.003681,
  "PGK": 3.552368,
  "PHP": 55.355501,
  "PKR": 173.991342,
  "PLN": 4.113118,
  "PYG": 6854.278176,
  "QAR": 3.641,
  "RON": 4.3578,
  "RSD": 103.525,
  "RUB": 72.865133,
  "RWF": 1018.803013,
  "SAR": 3.75092,
  "SBD": 8.048354,
  "SCR": 13.426637,
  "SDG": 438,
  "SEK": 8.874305,
  "SGD": 1.358232,
  "SHP": 0.74082,
  "SLL": 10952.050043,
  "SOS": 577.89969,
  "SRD": 21.4965,
  "SSP": 130.26,
  "STD": 20926.040504,
  "STN": 21.95,
  "SVC": 8.741211,
  "SYP": 1256.957853,
  "SZL": 15.555115,
  "THB": 32.606,
  "TJS": 11.282828,
  "TMT": 3.5,
  "TND": 2.86275,
  "TOP": 2.260773,
  "TRY": 10.966904,
  "TTD": 6.779288,
  "TWD": 27.7915,
  "TZS": 2302,
  "UAH": 26.476909,
  "UGX": 3576.192052,
  "USD": 1,
  "UYU": 44.126477,
  "UZS": 10756.678206,
  "VES": 4.52315,
  "VND": 22627.308748,
  "VUV": 111.156805,
  "WST": 2.561416,
  "XAF": 577.474492,
  "XAG": 0.04027632,
  "XAU": 0.00053789,
  "XCD": 2.70255,
  "XDR": 0.712734,
  "XOF": 577.474492,
  "XPD": 0.00046683,
  "XPF": 105.054199,
  "XPT": 0.00094251,
  "YER": 250.249998,
  "ZAR": 15.573939,
  "ZMW": 17.596101,
  "ZWL": 322
}

export const currencySymbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
  'SGD': 'S$', // Singapore Dollar
  'AUD': 'AU$' // Australian Dollar
}