import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import axios from 'axios'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { consoleLogger, truncate } from '@Utils'
import striptags from 'striptags'
import he from 'he'
import { debounce } from 'lodash'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {getUIState} from '@Slices/uiSlice'

// COMPONENTS
import {
  // Grid,
  // Button,
  Dimmer,
  Loader,
  Image,
  Button,
} from 'semantic-ui-react'
import SFAvatar from '@Common/Avatar'

// STYLES
import { FeaturedContainer, SliderContainer, PagingContainer } from './FeaturedStyles';
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import 'react-lazy-load-image-component/src/effects/blur.css';

// install Virtual module
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Paging = (props) => {
  const {
    boxDimensions,
    data,
    currentIndex
  } = props
  
  const featuredBoxAvatarSize = (42 - 24)
  const featuredBoxAvatarSizeActive = (220 + 48) / 2

  const pageRef = React.useRef()
  // const [ pagingDimensions, setPagingDimensions ] = React.useState()
  // const pagingResize = debounce(() => {
  //   setPagingDimensions({ width: pageRef.current.offsetWidth, height: pageRef.current.offsetHeight })
  // }, 300)

  // React.useEffect(() => {
  //   if (pageRef.current) {
  //     pagingResize()
  //     window.addEventListener('resize', pagingResize)
  //   }

  //   return (() => {
  //     window.removeEventListener('resize', pagingResize)
  //   })
  // }, []) // eslint-disable-line

  return (
    <PagingContainer
      ref={pageRef}
      style={{ left: ((boxDimensions.width / 2) - featuredBoxAvatarSizeActive) - (currentIndex * featuredBoxAvatarSize) }}
    >
      {data && data.map((user, index) => (
        <div
          key={user.username}
          className={cn('item', {
            'active': index === currentIndex
          })}
        >
          <SFAvatar
             userInfo={user}
            className={cn('avatar')}
          />
        </div>
      ))}
    </PagingContainer>
  )
}

const SliderItem = (props) => {
  const { userData } = props
  const rgba = userData.acf.custom_stylesheet.bgColor.split(',')
  const bgColor1 = `rgba(${rgba[0]},${rgba[1]},${rgba[2]},0.75)`
  const bgColor2 = `rgba(${rgba[0]},${rgba[1]},${rgba[2]},1)`
  const txtColor = `rgba(${userData.acf.custom_stylesheet.profileTextColor})`

  return (
      <SliderContainer
        className='featuredBox_item'
        bgColor1={bgColor1}
        bgColor2={bgColor2}
        txtColor={txtColor}
      >
        <div className='featuredBox_content'>
          <div className='content'>
            <div className='name'>{userData.name}</div>
            <div className='description'>{truncate(striptags(he.decode(userData.acf.biography)), 250)}</div>
            <div className='actions'>
              <Button primary basic as={Link} to={`/@${userData.username}`}>Visit Creator's Gallery</Button>
            </div>
          </div>
        </div>
        <div className='featuredBox_cover'>
          <img
            src={userData.acf.background_image ? userData.acf.background_image : `${process.env.REACT_APP_DIGITALOCEAN_CDN}/default_cover1.jpg`}
            alt={`featured user - ${userData.name} cover`}
          />
        </div>
      </SliderContainer>
  )
}

const FeaturedUsers = () => {
  const { siteSettings } = useSelector(getUIState)
  const [isReady, setIsReady] = React.useState(false)
  const [featuredUsers, setFeaturedUsers] = React.useState()

  const boxRef = React.useRef()
  const [ boxDimensions, setBoxDimensions ] = React.useState({
    width: 0,
    height: 0
  })
  const boxResize = debounce(() => {
    if (boxRef.current) {
      setBoxDimensions({ width: boxRef.current.offsetWidth, height: boxRef.current.offsetHeight })
    }
  }, 300)

  React.useEffect(() => {
    (async () => {
      // let tempArray = []
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}/stylefolio/v1/users`,
        // headers: {
        //   Authorization: token,
        // },
        method: 'POST',
        data: {
          ids: siteSettings.featuredUsers
        }
      }).then(res => {
        setFeaturedUsers(res.data)
      }).catch(err => {
        consoleLogger('ERROR ===========', err)
      })
      setIsReady(true)

      if (boxRef.current) {
        boxResize()
        window.addEventListener('resize', boxResize)
      }
    })()

    return (() => {
      window.removeEventListener('resize', boxResize)
    })
  }, [siteSettings]) // eslint-disable-line

  const [ currentIndex, setCurrentIndex ] = React.useState(0)
  // const pagination = {
  //   "clickable": true
  // }

  return (
    <FeaturedContainer>
      <div className='featuredBox' ref={boxRef}>
          {isReady ?
            <>
              <Paging data={featuredUsers} currentIndex={currentIndex} boxDimensions={boxDimensions} />
              <Swiper
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                grabCursor={true}
                centeredSlides={true}
                draggable={true}
                autoplay={{
                  "delay": 10000,
                  "disableOnInteraction": true
                }}
                navigation={true}
                onSlideChange={(ev) => {
                  consoleLogger(ev, ev.realIndex)
                  setCurrentIndex(ev.realIndex)
                }}
              >
                {featuredUsers && featuredUsers.map((user) => (
                  <SwiperSlide key={user.username}>
                    <SliderItem userData={user} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </> :
            <>
              <Dimmer active inverted>
                <Loader inverted>Loading featured creators...</Loader>
              </Dimmer>
            </>
          }
      </div>
    </FeaturedContainer>
  )
}

export default React.memo(FeaturedUsers)
