import { createSlice } from "@reduxjs/toolkit";
import { consoleLogger } from "@Utils";

const vendor = createSlice({
  name: "vendor",
  initialState: {
    vendorInfo: null,
    vendorProducts: null,
    vendorCollections: null,
    vendorProductIndex: 0,
    vendorTotalProducts: 0,
    vendorSettings: [],
  },
  reducers: {
    setVendorInfo: (state, action) => {
      state.vendorInfo = action.payload;
    },
    setVendorProducts: (state, action) => {
      state.vendorProducts = action.payload;
    },
    updateVendorProducts: (state, action) => {
      state.vendorProducts = state.vendorProducts.map((item) =>
        item.ID === action.payload.ID
          ? { ...item, ...action.payload }
          : { ...item }
      );
    },
    addVendorProducts: (state, action) => {
      const tempArr = [
        ...new Set([...state.vendorProducts, ...action.payload]),
      ];
      state.vendorProducts = [
        ...new Set(tempArr.map((o) => JSON.stringify(o))),
      ].map((s) => JSON.parse(s));
    },
    setVendorProductIndex: (state, action) => {
      state.vendorProductIndex = action.payload;
    },
    updateVendorProductIndex: (state, action) => {
      let idx = 0;
      consoleLogger("!!!!!! REDUX", action.payload);
      state.vendorProducts.forEach((item, index) => {
        if (Number(item.ID) === Number(action.payload)) {
          idx = index + 1;
        }
      });
      state.vendorProductIndex = idx;
    },
    setVendorTotalProducts: (state, action) => {
      state.vendorTotalProducts = action.payload;
    },
    setVendorSettings: (state, action) => {
      state.vendorSettings = action.payload;
    },
    setVendorCollections: (state, action) => {
      state.vendorCollections = action.payload;
    },
    addVendorCollection: (state, action) => {
      state.vendorCollections.push(action.payload);
    },
    updateVendorCollection: (state, action) => {
      consoleLogger("----------------------------", action.payload);
      state.vendorCollections = state.vendorCollections.map((item) =>
        item.id.toString() === action.payload.id.toString()
          ? { ...item, ...action.payload }
          : { ...item }
      );
    },
  },
  devTools: false,
});

export const getVendorState = (state) => state.vendor;

export default vendor;
