import React from 'react'
import cn from 'classnames'
import {
  consoleLogger
} from '@Utils'

// REDUX
import { useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import uiSlice, { getUIState } from '@Slices/uiSlice'
import { getGalleryState } from '@Slices/gallerySlice'
import vendorSlice, { getVendorState } from '@Slices/vendorSlice'
import cartSlice, { getCartState } from '@Slices/cartSlice'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import { Header, Item } from 'semantic-ui-react'
import { ErrorMessage } from '.'

const SizeItem = React.memo((props) => {
  const {
    selectedSize,
    handleSelectSize
  } = props

  return (
    <Item
      name={props.id}
      className={cn({
        'active': selectedSize && Number(selectedSize.id) === Number(props.id) ? true : false
      })}
      onClick={() => handleSelectSize(props)}
    >
      <Item.Content>
        <Item.Header>{props.acf.size}</Item.Header>
      </Item.Content>
    </Item>
  )
})


const RenderSizeItem = React.memo((props) => {
  const {
    selectedType,
    selectedSize,
    handleSelectSize,
    ...reProps
  } = props

  const { gallerySizes } = useSelector(getGalleryState) // eslint-disable-line

  switch (selectedType && selectedType.slug) {
    case 'canvas':
      return Number(reProps.acf.width) >= 12 && Number(reProps.acf.length) >= 12 ? 
        <SizeItem {...reProps} selectedSize={selectedSize} handleSelectSize={handleSelectSize} /> :
        null
    case 'framed-canvas':
      return Number(reProps.acf.width) >= 12 && Number(reProps.acf.length) >= 12 ? 
        <SizeItem {...reProps} selectedSize={selectedSize} handleSelectSize={handleSelectSize} /> :
        null
    default:
      return <SizeItem {...reProps} selectedSize={selectedSize} handleSelectSize={handleSelectSize} />
  }
})

const ViewerOptionsSizes = (props) => {
  const {
    selectedType,
    selectedSize,
    aspectRatio,
    handleSelectSize
  } = props
  const { gallerySizeTypes, galleryMaterials, gallerySizes } = useSelector(getGalleryState) // eslint-disable-line
  const { viewerData, viewerFetching, viewerIsSlider, viewerImageDone, currency } = useSelector(getUIState) // eslint-disable-line

  return (
    <>
      <div className='viewerOptionGroup'>
        <Header as='h3' className='viewerHeader selectSizeHeader'>
          Select Size
          <Header.Subheader>{aspectRatio.text}</Header.Subheader>
        </Header>
        {!selectedSize ?
          <>
            <ErrorMessage />
          </> : 
          <>
            <Item.Group
              size='large'
              className='selectSize'
              link
            >
              {gallerySizes.filter(n => n.acf.active).filter(i => i.acf.aspect_ratio.toString() === aspectRatio.value).map(size => (
                <RenderSizeItem key={size.id} {...size} selectedType={selectedType} selectedSize={selectedSize} handleSelectSize={handleSelectSize} />
              ))}
            </Item.Group>
          </>
        }
      </div>
    </>
  )
}

export default ViewerOptionsSizes
