import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import { consoleLogger } from "@Utils";

const cart = createSlice({
  name: "cart",
  initialState: {
    customerInfo: {},
    carts: localStorage.carts ? JSON.parse(localStorage.carts) : [],
    paymentInfo: null,
    paymentType: "",
    billingInfo: null,
    coupons: localStorage.coupons ? JSON.parse(localStorage.coupons) : [],
    checkoutStep: "select",
    checkout: {
      currency: "USD",
      totalAmount: 0,
      shippingFee: 0,
      orderID: "",
      shippingAddress: [],
      fees: [
        {
          id: 1,
          name: "Developer Fee",
          base: 1,
          rate: 0.03,
          minimum: 20,
          active: true,
        },
      ],
    },
  },
  reducers: {
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    setCart: (state, action) => {
      state.carts = action.payload;
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    addToCart: (state, action) => {
      const vendorIndex = findIndex(state.carts, {
        id: action.payload.id,
        slug: action.payload.slug,
      });

      if (vendorIndex > -1) {
        const itemIndex = findIndex(state.carts[vendorIndex].items, {
          id: action.payload.item.id,
          selectedSize: action.payload.item.selectedSize,
          selectedType: action.payload.item.selectedType,
          selectedFrame: action.payload.item.selectedFrame,
          selectedPaper: action.payload.item.selectedPaper,
          selectedCanvas: action.payload.item.selectedCanvas,
          selectedBorder: action.payload.item.selectedBorder,
        });
        if (itemIndex > -1) {
          state.carts[vendorIndex].items[itemIndex].quantity =
            state.carts[vendorIndex].items[itemIndex].quantity +
            action.payload.item.quantity;
        } else {
          state.carts[vendorIndex].items.push({
            ...action.payload.item,
            quantity: action.payload.item.quantity,
          });
        }
      } else {
        state.carts.push({
          id: action.payload.id,
          name: action.payload.name,
          slug: action.payload.slug,
          email: action.payload.email,
          profile_picture: action.payload.profile_picture,
          subscription_type: action.payload.subscription_type,
          items: [
            {
              ...action.payload.item,
            },
          ],
        });
      }
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    removeVendor: (state, action) => {
      const vendorIndex = findIndex(state.carts, {
        id: action.payload.id,
        slug: action.payload.slug,
      });
      consoleLogger("vendorIndex", vendorIndex);
      if (vendorIndex > -1) {
        state.carts = state.carts
          .slice(0, vendorIndex)
          .concat(state.carts.slice(vendorIndex + 1, state.carts.length));
      }
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    increaseQuantity: (state, action) => {
      const vendorIndex = findIndex(state.carts, {
        id: action.payload.vendor.id,
        slug: action.payload.vendor.slug,
      });
      if (vendorIndex > -1) {
        const itemIndex = findIndex(state.carts[vendorIndex].items, {
          id: action.payload.item.id,
          selectedSize: action.payload.item.selectedSize,
          selectedType: action.payload.item.selectedType,
          selectedFrame: action.payload.item.selectedFrame,
          selectedPaper: action.payload.item.selectedPaper,
          selectedCanvas: action.payload.item.selectedCanvas,
          selectedBorder: action.payload.item.selectedBorder,
        });
        if (itemIndex > -1) {
          consoleLogger("vendorIndex - itemIndex", vendorIndex, itemIndex);
          state.carts[vendorIndex].items[itemIndex].quantity =
            state.carts[vendorIndex].items[itemIndex].quantity + 1;
          localStorage.setItem("carts", JSON.stringify(state.carts));
        }
      }
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    reduceQuantity: (state, action) => {
      const vendorIndex = findIndex(state.carts, {
        id: action.payload.vendor.id,
        slug: action.payload.vendor.slug,
      });
      if (vendorIndex > -1) {
        const itemIndex = findIndex(state.carts[vendorIndex].items, {
          id: action.payload.item.id,
          selectedSize: action.payload.item.selectedSize,
          selectedType: action.payload.item.selectedType,
          selectedFrame: action.payload.item.selectedFrame,
          selectedPaper: action.payload.item.selectedPaper,
          selectedCanvas: action.payload.item.selectedCanvas,
          selectedBorder: action.payload.item.selectedBorder,
        });
        if (itemIndex > -1) {
          consoleLogger("vendorIndex - itemIndex", vendorIndex, itemIndex);
          if (state.carts[vendorIndex].items[itemIndex].quantity > 1) {
            // reduce the quantity
            state.carts[vendorIndex].items[itemIndex].quantity =
              state.carts[vendorIndex].items[itemIndex].quantity - 1;
          } else {
            // if quantity is 0 remove it from vendor cart
            state.carts[vendorIndex].items = state.carts[vendorIndex].items
              .slice(0, itemIndex)
              .concat(
                state.carts[vendorIndex].items.slice(
                  itemIndex + 1,
                  state.carts[vendorIndex].items.length
                )
              );
          }
        }
      }
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    removeCartItem: (state, action) => {
      state.carts[action.payload.vIndex].items = state.carts[
        action.payload.vIndex
      ].items
        .slice(0, action.payload.index)
        .concat(
          state.carts[action.payload.vIndex].items.slice(
            action.payload.index + 1,
            state.carts[action.payload.vIndex].items.length
          )
        );
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    updateCartItem: (state, action) => {
      const tempArr = [...new Set([...state.cartItems, ...action.payload])];
      state.cartItems = [...new Set(tempArr.map((o) => JSON.stringify(o)))].map(
        (s) => JSON.parse(s)
      );
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    refreshCartItem: (state, action) => {
      const vendorIndex = findIndex(state.carts, {
        id: action.payload.id,
        slug: action.payload.slug,
      });
      const itemIndex = findIndex(state.carts[vendorIndex].items, {
        id: action.payload.item.id,
        selectedSize: action.payload.item.selectedSize,
        selectedType: action.payload.item.selectedType,
      });
      state.carts[vendorIndex].items[itemIndex] = action.payload.item;
      // if (vendorIndex > -1) {
      //   const itemIndex = findIndex(state.carts[vendorIndex].items, {id: action.payload.item.id, selectedSize: action.payload.item.selectedSize, selectedType: action.payload.item.selectedType})
      //   if (itemIndex > -1) {
      //     state.carts[vendorIndex].items[itemIndex] = action.payload.item
      //   } else {
      //     state.carts[vendorIndex].items.push({...action.payload.item, quantity: 1})
      //   }
      // } else {
      //   state.carts.push({
      //     id: action.payload.id,
      //     name: action.payload.name,
      //     slug: action.payload.slug,
      //     profile_picture: action.payload.profile_picture,
      //     items: [{
      //         ...action.payload.item,
      //         quantity: 1
      //     }]
      //   })
      // }
      localStorage.setItem("carts", JSON.stringify(state.carts));
    },
    setPaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setPaymentInfo: (state, action) => {
      state.paymentInfo = action.payload;
    },
    setBillingInfo: (state, action) => {
      state.billingInfo = { ...state.billingInfo, ...action.payload };
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
      localStorage.setItem("coupons", JSON.stringify(state.coupons));
    },
    updateCoupons: (state, action) => {
      const vendorIndex = findIndex(state.coupons, {
        vendorId: action.payload.vendorId,
      });
      state.coupons[vendorIndex] = action.payload;
      localStorage.setItem("coupons", JSON.stringify(state.coupons));
    },
    removeCoupons: (state, action) => {
      const vendorIndex = findIndex(state.coupons, {
        vendorId: action.payload.id,
      });
      consoleLogger("vendorIndex", vendorIndex);
      if (vendorIndex > -1) {
        state.coupons = state.coupons
          .slice(0, vendorIndex)
          .concat(state.coupons.slice(vendorIndex + 1, state.coupons.length));
      }
      localStorage.setItem("coupons", JSON.stringify(state.coupons));
    },
    refreshCoupon: (state, action) => {
      const couponIndex = findIndex(state.coupons, {
        vendorId: action.payload.author,
        code: action.payload.acf.code,
      });
      state.coupons[couponIndex] = {
        ...state.coupons[couponIndex],
        type: action.payload.acf.type,
        value: action.payload.acf.value,
        minimum: action.payload.acf.minimum,
        quantity: action.payload.acf.quantity,
      };
      localStorage.setItem("coupons", JSON.stringify(state.coupons));
    },
    updateCheckoutStep: (state, action) => {
      state.checkoutStep = action.payload;
    },
    setCheckout: (state, action) => {
      state.checkout = action.payload;
    },
    updateCheckout: (state, action) => {
      state.checkout = { ...state.checkout, ...action.payload };
    },
  },
  devTools: false,
});

export const getCartState = (state) => state.cart;

export default cart;
