import React from 'react'
import { CollectionItem } from '.'
import { CollectionsContainer } from './CollectionsContainerStyles'

const Collections = (props) => {
  const { collections, vendorInfo, mode, noHelmet } = props
  return (
    <CollectionsContainer>
      {collections && collections.map((collection, index) => (
        <CollectionItem key={collection.id} {...collection} vendorInfo={vendorInfo} mode={mode} noHelmet={noHelmet} />
      ))}
    </CollectionsContainer>
  )
}

export default React.memo(Collections)
