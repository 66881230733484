import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { consoleLogger } from '@Utils'

const ui = createSlice({
  name: 'ui',
  initialState: {
    themeIsDark: false,
    sideBarShow: false,
    currentPage: 'home',
    currentPageURL: '/',
    currentPageTitle: '',
    pageScrolled: false,
    pageTemplate: '',
    showViewer: false,
    mountViewer: false,
    viewerFetching: false,
    viewerData: null,
    viewerUI: {},
    viewerIsSlider: false,
    viewerImageDone: false,
    uiReady: false,
    uiToast: false,
    uiComponentReady: [
      { component: 'user-sales-overview', isReady: true },
      { component: 'user-coupons', isReady: true },
      { component: 'user-orders', isReady: true },
      { component: 'user-sales', isReady: true },
      { component: 'user-payouts', isReady: true },
      { component: 'user-transactions', isReady: true },
      { component: 'user-billing-history', isReady: true },
    ],
    uiFilters: [
      {
        component: 'user-coupons',
        filters: [
          {
            key: 'header1',
            text: 'Filter Status',
            type: 'header',
          },
          {
            key: 'divider1',
            type: 'divider',
          },
          {
            key: 'Active',
            text: 'Active',
            value: 'publish',
            active: true,
            icon: {
              name: 'toggle on',
            },
            type: 'item',
          },
          {
            key: 'Inactive',
            text: 'Inactive',
            value: 'pending',
            active: true,
            icon: {
              name: 'toggle off',
            },
            type: 'item',
          },
          {
            key: 'divider2',
            type: 'divider',
          },
          {
            key: 'Reset Filter',
            text: 'Reset Filter',
            value: 'reset',
            icon: {
              name: 'repeat',
            },
            type: 'reset',
          },
        ]
      },
    ],
    uiTables: [
      {
        component: 'user-coupons',
        pageSize: 15,
        totalItems: 0,
        totalPages: 0,
        pageNumber: 1,
        sort: 'post_modified_gmt_epoch',
        order: 'desc',
        meta: false,
      },
      {
        component: 'user-orders',
        pageSize: 15,
        totalItems: 0,
        totalPages: 0,
        pageNumber: 1,
        sort: 'post_modified_gmt_epoch',
        order: 'desc',
        meta: false,
      },
      {
        component: 'user-sales',
        pageSize: 15,
        totalItems: 0,
        totalPages: 0,
        pageNumber: 1,
        sort: 'post_modified_gmt_epoch',
        order: 'desc',
        meta: false,
      },
      {
        component: 'user-payouts',
        pageSize: 15,
        totalItems: 0,
        totalPages: 0,
        pageNumber: 1,
        sort: 'post_modified_gmt_epoch',
        order: 'desc',
        meta: false,
      },
    ],
    uiTablesData: [
      {
        component: 'user-coupons',
        data: [],
      },
      {
        component: 'user-orders',
        data: [],
      },
      {
        component: 'user-sales',
        data: [],
      },
      {
        component: 'user-payouts',
        data: [],
      },
    ],
    galleryCanArrange: false,
    galleryShowUploader: false,

    siteSettings: {},
    currency: {},
    forexData: {}

  },
  reducers: {
    setThemeIsDark: (state, action) => {
      state.themeIsDark = action.payload
    },
    setSideBarShow: (state, action) => {
      state.sideBarShow = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setCurrentPageURL: (state, action) => {
      state.currentPageURL = action.payload
    },
    setCurrentPageTitle: (state, action) => {
      state.currentPageTitle = action.payload
    },
    setPageScrolled: (state, action) => {
      state.pageScrolled = action.payload
    },
    setPageTemplate: (state, action) => {
      state.pageTemplate = action.payload
    },
    setShowViewer: (state, action) => {
      state.showViewer = action.payload
    },
    setMountViewer: (state, action) => {
      state.mountViewer = action.payload
    },
    setViewerFetching: (state, action) => {
      state.viewerFetching = action.payload
    },
    setViewerImageDone: (state, action) => {
      state.viewerImageDone = action.payload
    },
    setViewerData: (state, action) => {
      state.viewerData = action.payload
    },
    setViewerIsSlider: (state, action) => {
      state.viewerIsSlider = action.payload
    },
    setUIReady: (state, action) => {
      state.uiReady = action.payload
    },
    setUIToast: (state, action) => {
      state.uiToast = ({...state.toast, ...action.payload})
    },
    updateViewerUI: (state, action) => {
      state.viewerUI = ({...state.viewerUI, ...action.payload})
    },
    updateUIComponentReady: (state, action) => {
      state.uiComponentReady = state.uiComponentReady.map(item => item.component === action.payload.component ? ({...item, ...action.payload}) : ({...item}))
      
      // const tempArr = [...new Set([...state.uiComponentReady, ...action.payload])]
      // state.uiComponentReady = [...new Set(tempArr.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
    },
    updateUIFilters: (state, action) => {
      state.uiFilters = state.uiFilters.map(item => item.component === action.payload.component ? ({...item, ...action.payload}) : ({...item}))
    },
    updateUITables: (state, action) => {
      state.uiTables = state.uiTables.map(item => item.component === action.payload.component ? ({...item, ...action.payload}) : ({...item}))
    },
    updateUITablesData: (state, action) => {
      state.uiTablesData = state.uiTablesData.map(item => item.component === action.payload.component ? ({...item, ...action.payload}) : ({...item}))
    },
    updateUITablesDataItem: (state, action) => {
      // const data = state.uiTablesData.filter(item => item.component === action.payload.component)[0].data
      // const couponIndex = findIndex(data, { ID: action.payload.data.ID })
      // // if (couponIndex > -1) {
      //   consoleLogger('updateUITablesDataItem', couponIndex, action.payload)
        state.uiTablesData = state.uiTablesData.map(
          (group) => group.component === action.payload.component ? 
          ({...group, data: group.data.map(
            (item) => item.ID === action.payload.data.ID ?
            ({...item, ...action.payload.data}) : 
            ({...item}))
          }) : 
          ({...group}))
      // }
    },
    removeUITablesDataItem: (state, action) => {
      const data = state.uiTablesData.filter(item => item.component === action.payload.component)[0].data
      const couponIndex = findIndex(data, { ID: action.payload.data.ID })
      if (couponIndex > -1) {
        state.uiTablesData = state.uiTablesData.map(item => item.component === action.payload.component ? ({...item, data: data.slice(0, couponIndex).concat(data.slice(couponIndex+1, data.length))}) : ({...item}))
      }
    },
    setGalleryArrange: (state, action) => {
      state.galleryCanArrange = action.payload
    },
    setShowGalleryUploader: (state, action) => {
      state.galleryShowUploader = action.payload
    },
    setSiteSettings: (state, action) => {
      state.siteSettings = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    },
    updateCurrency: (state, action) => {
      state.currency = {...state.currency, ...action.payload}
    },
    setForexData: (state, action) => {
      state.forexData = action.payload
    },
  },
  devTools: false
})


export const getUIState = (state) => state.ui

export default ui