import React from 'react'
import { Link, useParams, useHistory, useLocation } from "react-router-dom"
import axios from 'axios'
import { consoleLogger, setAuthorizationHeader } from '@Utils'
import jwtDecode from 'jwt-decode'
import cn from 'classnames'
// import { Helmet } from 'react-helmet'

// FORMIK
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { getUserState } from '@Slices/userSlice'
import uiSlice from '@Slices/uiSlice'

// COMPONENTS
import { Grid, Button, Form as SUIForm, Message } from 'semantic-ui-react'
import {FormBox, StyledCard} from './Styles'

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .max(255, "Must be shorter than 255 characters")
    .required("Must enter a password")
})

const NewPasswordForm = (props) => {
  const { className, to, redirect, customer, userInfo, ...restProps } = props
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { authenticated } = useSelector(getUserState)
  const { path } = useParams()
  const currentPath = location.pathname
  const decodedPath = decodeURIComponent(path)
  const [ signupLink, setSignupLink ] = React.useState('/signup')

  React.useEffect(() => {
    dispatch(uiSlice.actions.setPageTemplate('store'))
    dispatch(uiSlice.actions.setCurrentPage('login'))
    dispatch(uiSlice.actions.setCurrentPageURL())
    dispatch(uiSlice.actions.setCurrentPageTitle())

    consoleLogger("decodedPath ===================", decodedPath)
    if (customer) {
      setSignupLink(state => (`${state}/collector`))
    }
    if (path) {
      setSignupLink(state => (`${state}/redirect/${encodeURIComponent(path)}`))
    } else if (currentPath) {
      if (currentPath.indexOf('/signup') <= -1) {
        setSignupLink(state => (`${state}/redirect/${encodeURIComponent(currentPath)}`))
      }
    }
  }, []) // eslint-disable-line

  React.useEffect(() => {
    consoleLogger('LOGIN PAGE')
    if (authenticated) {
      history.push('/admin')
    }
  }, [authenticated]) // eslint-disable-line

  const handleSignupLink = () => {
    history.push(signupLink)
  }

  return (
    <FormBox className={cn('formBoxContainer', {
      [className] : className
    })}>
      <Formik
        initialValues={{
          new_password: ''
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          (async () => {
            dispatch(userSlice.actions.setAuthenticated(false))
            setSubmitting(true)
            // consoleLogger('LOGIN =====================', data)
            await axios({
              baseURL: `${process.env.REACT_APP_API_URL}`,
              url: `/stylefolio/v1/password/${userInfo.id}`,
              method: 'PUT',
              data: {
                ...data,
                reset_key: userInfo.acf.reset_key
              }
            })
            .then(res => {
              history.push('/login')
            })
            .catch(err => {
              setSubmitting(false)
              // const errorData = err
              consoleLogger("LOGIN ERROR: ", err.response.data)
              if (err.response.data.code === '[jwt_auth] incorrect_password' || "[jwt_auth] invalid_username") {
                setErrors({message: 'Incorrect Username/Password'})
              } else {
                setErrors(err.response.data)
              }
            })
          })()
        }}
      >
        {({isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setValues, errors, isValid, dirty}) => (
          <Form className={cn('ui form', {
            [className]: className
          })}>
            <Grid centered padded>
              <Grid.Column className='formBox'>
                <h1>Set New Password</h1>
                {customer &&
                  <p>Before you can add things to your cart, you need to login first.</p>
                }
                <StyledCard>
                  <StyledCard.Content>
                    <SUIForm.Input name='new_password' fluid icon='key' iconPosition='left' placeholder='Your New Password' type='password' label='New Password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password}
                      disabled={isSubmitting}
                    />
                  </StyledCard.Content>
                  <StyledCard.Content extra>
                    <div className='ui two buttons'>
                      <Button
                        type='submit'
                        color='green'
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        disabled={!(dirty && !isSubmitting)}
                      >
                        Save
                      </Button>
                    </div>
                  </StyledCard.Content>
                </StyledCard>
                {errors && errors.message &&
                  <Message negative>
                    <div dangerouslySetInnerHTML={{ __html: errors.message }} />
                  </Message>
                }
              </Grid.Column>
            </Grid>
          </Form>
        )}
      </Formik>
    </FormBox>
  )
}

export default NewPasswordForm
