import styled from "styled-components";
import { stylevars } from '@Common/StyleVars'

export const ToolbarContainer = styled('div')`
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 0rem;
  z-index: 10;
  padding: 0;
  background-color: ${stylevars.paper.bgColor};
  border-bottom: 1px solid ${stylevars.paper.borderColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${stylevars.breakpoints.tablet} {
    width: 100%;
    left: 0;
    justify-content: center;
  }

  @media ${stylevars.breakpoints.phone} {
    display: block;
    padding: 0;
  }

  .toolbar-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: ${stylevars.navBar.height};
    max-height: ${stylevars.navBar.height};
    background-color: ${stylevars.paper.bgColor};

    @media ${stylevars.breakpoints.phone} {
      width: 100%;
      min-height: 4rem;
      display: block;
    }

    &.right {
      margin-bottom: 0;
      border-bottom: none;

      .action-group {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        padding: 0 1rem;
      }
      
      @media ${stylevars.breakpoints.phone} {
        position: relative;
        display: none;
        justify-content: center;
        height: ${stylevars.navBar.height};
        &.show {
          display: flex;
          background-color: rgba(0,0,0,0.075);
        }
      }
    }

    &.left {
      display: flex;
      align-items: center;
      > .title {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 0 1rem;
        h1 {
          line-height: 1.2;
          margin: 0;
          padding: 0;
          margin-right: 1rem;
          font-size: 1.4rem;
        }
      }
      .size {
        line-height: 1rem;
        font-size: 0.8rem;
        color: rgba(0,0,0,0.5);
        .label {
        }
        .value {
          margin-left: 0.5rem;
        }
      }
      .toggler {
        display: none;
        justify-content: center;
        align-items: center;
        width: ${stylevars.navBar.height};
        height: ${stylevars.navBar.height};
        > .ui.button {
          background-color: #ffffff !important;
        }
      }
      @media ${stylevars.breakpoints.phone} {

        .toggler {
          display: flex;
          &.show {
            background-color: rgba(0,0,0,0.075);
          }
        }
      }
    }

    .arrangeToggle {
      margin-right: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid ${stylevars.paper.borderColor};
      
      > label {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:before {
          top: 50%;
          transform: translateY(-50%);
        }
        &:after {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      @media ${stylevars.breakpoints.phone} {
      }
    }
  }
`