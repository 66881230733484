import React from 'react'
import { useLocation } from 'react-router-dom' // eslint-disable-line
import cn from 'classnames'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { consoleLogger, cleanPrice } from '@Utils'
import shortNumber from 'short-number'
import NumberFormat from 'react-number-format'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import uiSlice, {getUIState} from '@Slices/uiSlice'
import { getUserState } from '@Slices/userSlice'

// COMPONENTS
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Image,
  Label,
  Loader
} from 'semantic-ui-react'
import { SFAvatar, UserDisplayName, LikeButton } from '@Common'
import { GalleryCard } from './'

// STYLES
import { PopularItemsContainer } from './PopularItemsStyles'
import 'react-lazy-load-image-component/src/effects/blur.css';

const PopularItems = (props) => {
  const {
    limit
  } = props

  const [isReady, setIsReady] = React.useState(false)
  const [items, setItems] = React.useState()

  React.useEffect(() => {
    (async () => {
      let tempArray = []
      await axios({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        url: `/stylefolio/v1/gallery/popular`,
        // headers: {
        //   Authorization: token,
        // },
        method: 'GET',
        params: {
          length: limit ? limit : 10
        }
      }).then(res => {
        setItems(res.data)
      }).catch(err => {
        consoleLogger('ERROR ===========', err)
      })
      setIsReady(true)
    })()
  }, []) // eslint-disable-line

  return (
    <PopularItemsContainer>
      <div className='wrapper'>
        <h2 className='sectionTitle'>Popular Artworks</h2>
        <div className='gridList'>
          {!isReady &&
            <Dimmer active inverted>
              <Loader inverted>Loading popular items...</Loader>
            </Dimmer>
          }
          {items && items.map(item => (
            <GalleryCard itemData={item} key={item.ID} />
          ))}
        </div>
      </div>
    </PopularItemsContainer>
  )
}

export default React.memo(PopularItems)
