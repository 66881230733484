import styled from 'styled-components'
import { stylevars } from '@Common/StyleVars'

export const StyledNavBar = styled.div`
  min-width: 100%;
  width: calc(var(--vw, 1vw) * 100);
  padding: 0;
  min-height: ${stylevars.navBar.height};
  max-height: ${stylevars.navBar.height};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  transition: all 0.15s ease-out;
  background-color: ${stylevars.navBar.bgColor};
  border-bottom: 1px solid ${stylevars.paper.borderColor};
  /* background-color: var(--sfProfile_bgColor);
  border-bottom: 1px solid var(--sfProfile_dividerColor); */
  

  .sideBarToggle {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    @media ${stylevars.breakpoints.desktop} {
      display: none;
    }
    .icon {
      width: auto;
      height: auto;
      line-height: 1;
      margin: 0 !important;
      font-size: 2rem;
    }
  }

  .titlebar {
    position: relative;
    z-index: 2;

    @media ${stylevars.breakpoints.phone} {
      width: auto !important;
    }

    .item {
      /* width: 166px; */
      /* width: ${stylevars.sideBar.width}; */
      width: auto;
      min-height: ${stylevars.navBar.height};
      display: flex;
      justify-content: flex-start;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      @media ${stylevars.breakpoints.phone} {
        width: 60px;
        justify-content: center;
      }
      .header {
        padding: 0 2rem !important;
        @media ${stylevars.breakpoints.phone} {
          padding: 0 !important;
        }
      }
      img {
        width: auto !important;
        height: 32px !important;
        object-fit: contain;
        object-position: center center;
        margin: 0 !important;

        &.logo {
          &.logo--dark {
            display: none;
          }
          @media ${stylevars.breakpoints.phone} {
            display: none !important;
          }
        }

        &.icon {
          width: 35px !important;
          height: 35px !important;
          display: none;
          @media ${stylevars.breakpoints.phone} {
            display: block !important;
          }
          &.icon--dark {
            display: none !important;
          }
        }
      }
    }
  }

    &.dark {
    .titlebar {
      .item {
        img {
          &.logo {
            display: none;
            &.logo--dark {
              display: block;
              @media ${stylevars.breakpoints.phone} {
                display: none !important;
              }
            }
          }
          &.icon {
            display: none !important;
            &.icon--dark {
              @media ${stylevars.breakpoints.phone} {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }

  .ui {
    &.header {
      margin: 0;
    }
    &.menu {
      margin: 0;
    }
  }

  .pageLabel {
    flex-grow: 1;
    height: ${stylevars.navBar.height};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    > span {
      font-size: 1.25rem;
      font-weight: bold;
      display: inline-block;
      text-transform: capitalize;
      transform: translateY(${stylevars.navBar.height});
      transition: all 0.15s ease-out;
    }
  }

  .menuWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    padding-right: 1rem;

    .menuToggle {
      width: 4rem;
      min-width: 4rem;
      max-width: 4rem;
      height: 4rem;
      min-height: 4rem;
      max-height: 4rem;
      display: none;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0.5rem 0;
      background-color: transparent;
      .icon {
        font-size: 2rem;
        width: 4rem;
        min-width: 4rem;
        max-width: 4rem;
        height: 4rem;
        min-height: 4rem;
        max-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      /* @media ${stylevars.breakpoints.phone} {
        display: flex;
      } */
    }

    .menuOverlay {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
      display: none;
    }

    > .menu {
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      > .item {
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media ${stylevars.breakpoints.phone} {
          /* width: 100%; */
          /* &:not(:last-of-type) {
            border-bottom: 1px solid ${stylevars.paper.borderColor};
          } */
        }
        .storeButtons {
          font-size: 1.25rem !important;
          font-weight: bold !important;
          line-height: 1 !important;
          border: none !important;
          box-shadow: none;
          border-radius: 0;
          border: none;
          background-color: transparent !important;
          color: ${stylevars.typography.subtitle.color} !important;
          padding: 1rem;
          margin: 0;
          &:hover {
            color: ${stylevars.typography.title.color} !important;
          }
          &.active {
            color: ${stylevars.typography.title.color} !important;
          }
        }
      }

      /* @media ${stylevars.breakpoints.phone} {
        position: absolute;
        z-index: 2;
        top: 4rem;
        right: 1rem;
        min-width: 12rem;
        height: auto;
        flex-direction: column;
        background-color: ${stylevars.paper.bgColor};
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        display: none;
        padding-top: 1rem;
      } */
    }

    /* &.show {
      @media ${stylevars.breakpoints.phone} {
        > .menu {
          display: flex;
        }
        .menuOverlay {
          display: block;
        }
      }
    } */

    @media ${stylevars.breakpoints.phone} {
      flex-grow: 1;
    }
  }

  .pageLabel {
    flex-grow: 1;
    height: ${stylevars.navBar.height};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    /* padding-left: calc(${stylevars.sideBar.width} + 1rem); */
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;

    span {
      width: 100%;
      display: block;
      transition: all 0.15s ease-out;
      transform: translateY(${stylevars.navBar.height});

      * {
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @media ${stylevars.breakpoints.phone} {
      /* padding-left: 0rem;
      padding-right: 1rem; */
      display: none;
    }
  }

  .menuDropdown {
    .trigger {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .triggerName {
        margin-right: 1rem;
        font-weight: bold;
        line-height: 2rem;
      }
      .icon {
        font-size: 2rem;
      }
      .avatar {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${stylevars.paper.borderColor};
        position: relative;

        &.pro {
          background-image: linear-gradient(55deg, ${stylevars.palette.secondary.main}, ${stylevars.palette.primary.main});
        }

        img {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          display: block;
          line-height: 0;
          margin: 0;
          padding: 0;
          border-radius: 50%;
        }
      }
    }
    .menu {
      .item {
        width: 100%;
        background-color: ${stylevars.paper.bgColor} !important;
        &.selected {
          background-color: ${stylevars.palette.secondary.main} !important;
          color: ${stylevars.palette.secondary.contrastText} !important;
        }
      }
    }
  }

  .hideOnMobile {
    @media ${stylevars.breakpoints.phone} {
      display: none !important;
    }
  }

`